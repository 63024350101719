import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Step,
    StepLabel,
    StepButton,
    Stepper,
    Typography
} from "@mui/material";
import LoadAddress from './loadAddress';
import StopOff from "./stopOff";
import PickupAndDeliveryInfo from "./pickup_DeliveryInfo";
import LoadDetails from "./loadDetails";
import BillingInformation from "./billingInformation";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import LoadReview from "./review";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { addLoadManagement } from "src/redux/actions/loadManagement";

const NewLoad = (): JSX.Element => {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    const [skipped, setSkipped] = useState(new Set<number>());
    const [load, setLoad] = useState<LoadManagementProps | null>(null);
    const dispatch = useAppDispatch();

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const steps = [
        {
            label: 'Shipper Information',
            isOptional: false,
            component: <LoadAddress key={`l-0`} stepId={0} data={load?.shippingInformation} done={(err, data) => {
                setLoad({
                    ...load,
                    shippingInformation: data
                })
                handleNext()
            }} />
        },
        {
            label: 'Consignee Information',
            isOptional: false,
            component: <LoadAddress key={`l-1`} stepId={1} data={load?.consigneeInformation} done={(err, data) => {
                setLoad({
                    ...load,
                    consigneeInformation: data
                })
                handleNext();
            }} />
        },
        {
            label: 'Add Stop',
            isOptional: true,
            component: <StopOff key={`l-2`} stepId={2} data={load?.stopOffs} done={(err, data) => {
                setLoad({
                    ...load,
                    stopOffs: data as any
                })
                handleNext();
            }} />
        },
        {
            label: 'Pickup/Delivery Details',
            isOptional: false,
            component: <PickupAndDeliveryInfo key={`l-3`} stepId={3} data={load} done={(err, data) => {
                setLoad({
                    ...load,
                    ...data
                });
                handleNext();
            }} />
        },
        {
            label: 'Load Details',
            isOptional: false,
            component: <LoadDetails key={`l-4`} stepId={4} data={load} done={(err, data) => {
                setLoad({
                    ...load,
                    ...data
                });
                handleNext();
            }} />
        },
        {
            label: 'Billing Information',
            isOptional: false,
            component: <BillingInformation key={`l-5`} stepId={5} data={load} done={(err, data) => {
                setLoad({
                    ...load,
                    ...data
                });
                handleNext();
            }} />
        },
        {
            label: 'Review',
            isOptional: false,
            component: <LoadReview key={`l-6`} stepId={6} data={load as any} done={(err, data) => {
                console.log(data);
                saveRecord(data);
            }} />
        },
    ];

    const saveRecord = async (data: LoadManagementProps) => {
        try {

            const payload: any = { ...data };
            if (data.dispatchStatusNotifications) {
                payload.dispatchStatusNotifications = data.dispatchStatusNotifications.join(',') || "";
            }

            if (data && data.shippingInformation && typeof data.shippingInformation.company == 'object') {
                payload.shippingInformation.company = data.shippingInformation.company.company;
            }

            if (data && data.consigneeInformation && typeof data.consigneeInformation.company == 'object') {
                payload.consigneeInformation.company = data.consigneeInformation.company.company;
            }

            if (data && data.billToInformation && typeof data.billToInformation.customer == 'object') {
                payload.billToInformation.company = data.billToInformation.customer?.companyName || null;
            }

            await dispatch(addLoadManagement(payload));
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const isStepOptional = (step: number) => {
        return steps
            .map((value, index) => value.isOptional == true ? index : undefined)
            .filter(index => index == step)
            .length > 0;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    return (
        <React.Fragment>
            <Box sx={{ width: '100%' }}>
                <Card variant="outlined" sx={{ borderRadius: "5px" }}>
                    <CardContent>
                        <Stepper activeStep={activeStep} nonLinear>
                            {steps.map((step, index) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">Optional</Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={step.label} {...stepProps}>
                                        <StepButton color="inherit" onClick={handleStep(index)} disabled={!(completedSteps() >= totalSteps() - 2) && (steps.length - 1 === index)}>
                                            {step.label}
                                            {/* <StepLabel {...labelProps} sx={{ textAlign: "center" }}>{step.label}</StepLabel> */}
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

                                <Box>
                                    {
                                        steps[activeStep].component
                                    }
                                </Box>

                            </React.Fragment>
                        )}
                    </CardContent>
                    <CardActions>
                        {
                            activeStep === steps.length ? (
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {isStepOptional(activeStep) && (
                                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                Skip
                                            </Button>
                                        )}
                                        <Button
                                            type="submit"
                                            form={`load-form-${activeStep}`}
                                        // onClick={handleNext}
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        }
                    </CardActions>
                </Card>

            </Box>
        </React.Fragment>
    )
};


export default NewLoad;