import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import TerminalReadFormContainer from "src/containers/terminals/readForm";
const Terminals = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Terminals" description="Porto System Terminals" />
      <TerminalReadFormContainer />
    </Grid>
  );
};

export default Terminals;
