import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridRowParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectDocuments } from "src/redux/reducers/billing";
import { useTranslation } from "react-i18next";
import { create, update, featureLoad } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import moment from "moment";
import { dateTimeFormat } from "src/utils/constants";
import { useStyles } from "src/styles/formStyles";
import { CircularProgress } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { getWaitingForDocument } from "src/redux/actions/billing";
import AddIcon from "@mui/icons-material/Add";
import { updateLoadStatus } from "src/redux/actions/loadManagement";
import { getAllDocumentTypesRequired } from "src/redux/actions/documentType";
import AddCommonDialog from "./confirmationModel";
import { LoadManagementProps } from "../../interfaces/loadManagement";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function WaitingForDocument() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBillingStatus, setLoadingBillingStatus] =
    useState<boolean>(false);

  const documentList = useAppSelector(selectDocuments);
  const [selectedDoc, setSelectedDoc] = useState<LoadManagementProps | null>(
    null
  );
  const [waitForDocumentData, setWaitForDocumentData] = useState<any>([]);
  const [loadRequiredDocument, setLoadRequiredDocument] = useState<any>([]);

  const [allLoadManagementData, setAllLoadManagementData] = useState<any>([]);
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [openTerminalModal, setOpenTerminalModal] = useState<boolean>(false);

  const user = useAppSelector(selectUser);

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const loadManagementPermissions =
    allUserPermissions && allUserPermissions[featureLoad];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : loadManagementPermissions.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : loadManagementPermissions.findIndex((item) => item === update);

  const handleOpenTerminalModal = (load: LoadManagementProps) => {
    setSelectedDoc(load);
    setOpenTerminalModal(true);
  };
  const handleCloseTerminalModal = () => {
    setOpenTerminalModal(false);
  };

  const getDocumentList = async () => {
    setLoading(true);
    await dispatch(getAllDocumentTypesRequired("load")).then((result: any) => {
      setLoadRequiredDocument(result);
    });
    await dispatch(getWaitingForDocument()).then((result: any) => {
      setWaitForDocumentData(result);
    });
    setLoading(false);
  };
  const updateLoadState = async () => {
    setLoadingBillingStatus(true);
    selectedDoc &&
      selectedDoc?.id &&
      (await dispatch(
        updateLoadStatus(
          { billingStatus: "processingQueue" },
          selectedDoc && Number(selectedDoc?.id)
        )
      ).then(() => {
        handleCloseTerminalModal();
        setLoadingBillingStatus(false);
        getDocumentList();
      }));
  };

  /*  function pageExists(page: any) {
    return allLoadManagementData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  useEffect(() => {
    getDocumentList();
  }, []);

  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (documentList?.data?.length > 0) {
      setAllLoadManagementData({ ...allLoadManagementData, documentList });
    } else {
      setAllLoadManagementData({});
    }
  }, [documentList]);

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<ArrowCircleRightIcon />}
          label="Push"
          key="push"
          onClick={() => {
            handleOpenTerminalModal(params?.row);
          }}
        />,
        <GridActionsCellItem
          icon={<AddIcon />}
          label="documents"
          key="documents"
          onClick={() => {
            navigate(
              `/load-management/load-management/documents/${params.row.id}?entity=${featureLoad}&referenceId=${params.row.id}`
            );
          }}
        />,
      ],
    },
    {
      field: "id",
      headerAlign: "center",
      align: "center",
      headerName: translation("common.id.label"),
      minWidth: 20,
    },
    {
      field: "billto",
      headerAlign: "center",
      align: "center",
      headerName: translation("waitingForDoc.label.billTo"),
      minWidth: 150,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.billto}</span>;
      },
    },
    {
      field: "shipper",
      headerAlign: "center",
      align: "center",
      headerName: translation("waitingForDoc.label.shipper"),
      minWidth: 150,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.shipper}</span>;
      },
    },
    {
      field: "consignee",
      headerAlign: "center",
      align: "center",
      headerName: translation("waitingForDoc.label.consignee"),
      minWidth: 150,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.consignee}</span>;
      },
    },
    {
      field: "terminal",
      headerAlign: "center",
      align: "center",
      headerName: translation("waitingForDoc.label.terminal"),
      minWidth: 150,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.terminal}</span>;
      },
    },
    {
      field: "deliveryDateTime",
      headerAlign: "center",
      align: "center",
      headerName: translation("waitingForDoc.label.deliveryDateTime"),
      minWidth: 150,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.deliveryDateTime
              ? moment(params.row.deliveryDateTime).format(dateTimeFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "missingDoc",
      headerName: translation("waitingForDoc.label.missingDocuments"),
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        const missingArray: any = [];

        loadRequiredDocument?.data.map((doc: any) => {
          const found = params?.row?.document?.some(
            (el: any) => el.documentDefinitionId == doc?.id
          );
          if (!found) missingArray.push(doc);
        });
        return missingArray.map((item: any) => item?.name + ",");
      },
    },
    // {
    //   field: "shipper",
    //   headerName: translation("load.shipperLoadAddress.label"),
    //   minWidth: 200,
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params: GridValueGetterParams) => {
    //     return <span>{params.row?.shippingInformation?.company}</span>;
    //   },
    // },
    // {
    //   field: "consignee",
    //   headerName: translation("load.consigneeLoadAddress.label"),
    //   minWidth: 200,
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params: GridValueGetterParams) => {
    //     return <span>{params.row?.consigneeInformation?.company}</span>;
    //   },
    // },
    // {
    //   field: "containerNumber",
    //   headerName: translation("load.form.containerNumber.label"),
    //   minWidth: 150,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "pickupDateTime",
    //   headerName: translation("load.form.pickupDateTime.label"),
    //   minWidth: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params: GridValueGetterParams) => {
    //     return (
    //       <div>
    //         {params?.row?.pickupDateTime
    //           ? moment(params.row.pickupDateTime).format(dateTimeFormat)
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   field: "deliveryDateTime",
    //   headerName: translation("load.form.deliveryDateTime.label"),
    //   minWidth: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params: GridValueGetterParams) => {
    //     return (
    //       <div>
    //         {params?.row?.deliveryDateTime
    //           ? moment(params.row.deliveryDateTime).format(dateTimeFormat)
    //           : ""}
    //       </div>
    //     );
    //   },
    // },
  ];

  const onRowClick = (params: GridRowParams) => {
    navigate(`/load-management/load-management/details/${params.row.id}`);
  };

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid container style={{ paddingTop: "20px" }}>
      <DataTable
        title={translation("billing.waitingForDocument.title")}
        rows={waitForDocumentData.data}
        columns={columns}
        loading={loading}
        createPermission={createPermission > -1}
        rowsState={rowsState}
        onRowClick={onRowClick}
        setRowsState={setRowsState}
        localPagination={true}
        // pagination={documentList?.data?.pagination}
        data={waitForDocumentData.data}
        setData={waitForDocumentData.data}
      />

      <AddCommonDialog
        handleClose={handleCloseTerminalModal}
        open={openTerminalModal}
        title="Update Load Status"
        data={selectedDoc}
        updateLoadState={updateLoadState}
        loading={loadingBillingStatus}
      />
    </CustomFormGrid>
  );
}
