import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toNumber } from "lodash";
import DataTable from "src/components/dataTable";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { Clipboard } from "ts-react-feather-icons";

import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { featureDrivers, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { dateTimeFormat } from "src/utils/constants";
import { statusTypes } from "src/utils/dropdowns/common";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { InputLabel } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAllOwners } from "src/redux/actions/owner";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import { selectLoadManagements } from "src/redux/reducers/loadManagement";
import { getAllDrivers } from "src/redux/actions/driver";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import { LoadStopProps } from "src/interfaces/loadManagement";
import CustomTextField from "src/components/customElements/customTextField";
import {
  getSearchTablesData,
  getSearchTablesDataWithFilters,
} from "src/redux/actions/search";

interface LoadSummaryProps {
  pageLoading: boolean;
  delayForClear: any;
}
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const LoadSearchForm = ({
  pageLoading,
  delayForClear,
}: LoadSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<any>({});
  const user = useAppSelector(selectUser);
  const terminals = useAppSelector(selectTerminals);

  const loadData = useAppSelector(selectLoadManagement);
  const pickupDateTimeRef = React.useRef<any>();
  const pickupDateTimeEndRef = React.useRef<any>();
  const [deliveryStart, setDeliveryStart] = React.useState<any | null>(null);
  const [deliveryEnd, setDeliveryEnd] = React.useState<any | null>(null);
  const selectPickupDateTimeRef = () => {
    if (pickupDateTimeRef?.current) {
      pickupDateTimeRef?.current?.click();
    }
  };
  const selectPickupDateEndTimeRef = () => {
    if (pickupDateTimeEndRef?.current) {
      pickupDateTimeEndRef?.current?.click();
    }
  };
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [addedByData, setAddedByData] = React.useState<any | null>([]);
  const [terminalData, setTerminalData] = React.useState<any | null>([]);
  const [vehicleData, setVehicleData] = React.useState<any | null>([]);
  const [pickupStart, setPickupStart] = React.useState<any | null>(null);
  const [pickupEnd, setPickupEnd] = React.useState<any | null>(null);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const [birthDateStart, setBirthDateStart] = React.useState<any | null>(null);
  const [birthDateEnd, setBirthDateEnd] = React.useState<any | null>(null);

  const [dotStart, setDOTStart] = React.useState<any | null>(null);
  const [dotEnd, setDOTEnd] = React.useState<any | null>(null);

  const [hireDateStart, setHireDateStart] = React.useState<any | null>(null);
  const [hireDateEnd, setHireDateEnd] = React.useState<any | null>(null);

  const [reviewDateStart, setReviewDateStart] = React.useState<any | null>(
    null
  );
  const [reviewDateEnd, setReviewDateEnd] = React.useState<any | null>(null);

  const [nextReviewDateStart, setNextReviewDateStart] = React.useState<
    any | null
  >(null);

  const [nextReviewDateEnd, setNextReviewDateEnd] = React.useState<any | null>(
    null
  );

  const [mvrDateStart, setMRVDateStart] = React.useState<any | null>(null);

  const [mvrDateEnd, setMRVDateEnd] = React.useState<any | null>(null);

  const [physicalDueDateStart, setPhysicalDueDateStart] = React.useState<
    any | null
  >(null);

  const [physicalDueDateEnd, setPhysicalDueDateEnd] = React.useState<
    any | null
  >(null);

  const [cdlExpirationDateStart, setCDLExpirationDateStart] = React.useState<
    any | null
  >(null);

  const [cdlExpirationDateEnd, setCDLExpirationDateEnd] = React.useState<
    any | null
  >(null);

  const [residentExpirationDateStart, setResidentExpirationDateStart] =
    React.useState<any | null>(null);

  const [residentExpirationDateEnd, setResidentExpirationDateEnd] =
    React.useState<any | null>(null);

  const [physicalSubmittedDateStart, setPhysicalSubmittedDateStart] =
    React.useState<any | null>(null);

  const [physicalSubmittedDateEnd, setPhysicalSubmittedDateEnd] =
    React.useState<any | null>(null);

  const [twicExpirationDateStart, setTwicExpirationDateStart] = React.useState<
    any | null
  >(null);

  const [twicExpirationDateEnd, setTwicExpirationDateEnd] = React.useState<
    any | null
  >(null);

  const [passportExpirationDateStart, setPassportExpirationDateStart] =
    React.useState<any | null>(null);

  const [passportExpirationDateEnd, setPassportExpirationDateEnd] =
    React.useState<any | null>(null);

  // const [, forceUpdate] = React.useState();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  let timer: any;
  const allLoadManagement = useAppSelector(selectLoadManagements);
  const [allLoadManagementData, setAllLoadManagementData] = useState<any>({});
  const [driverSearchedData, setDriverSearchedData] = useState<any>({});
  const [displayTable, setdisplayTable] = useState<boolean>(false);
  console.log(driverSearchedData, "driverSearchedData");
  const boxTheme = useTheme();
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const driverPermissions =
    allUserPermissions && allUserPermissions[featureDrivers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : driverPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : driverPermissions?.findIndex((item) => item === update);
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={<Edit />}
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/drivers/drivers/${params.row.id}`);
        //   }}
        // />,
       /*  <GridActionsCellItem
          icon={<UploadFileIcon />}
          label="documents"
          key="documents"
          onClick={() => {
            navigate(
              `/drivers/drivers/documents/${params.row.id}?entity=${featureDrivers}&referenceId=${params.row.id}`
            );
          }}
        />, */
        // <GridActionsCellItem
        //   icon={<Clipboard />}
        //   label="details"
        //   key="details"
        //   onClick={() => {
        //     navigateToDriversDetail(params.row.id);
        //   }}
        // />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,

      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "firstName",
      headerName: translation("common.firstName.label"),
      minWidth: 150,
    },
    {
      field: "lastName",
      headerName: translation("common.lastName.label"),
      minWidth: 150,
    },
    {
      field: "email",
      headerName: translation("common.email.label"),
      minWidth: 150,
    },
    {
      field: "phone",
      headerName: translation("common.phone.label"),
      minWidth: 150,
    },
    {
      field: "altPhone",
      headerName: translation("owner.altPhone.label"),
      minWidth: 150,
    },
    {
      field: "faxPhone",
      headerName: translation("owner.faxPhone.label"),
      minWidth: 150,
    },
    {
      field: "companyName",
      headerName: translation("owner.company.label"),
      minWidth: 150,
    },

    {
      field: "address",
      headerName: translation("common.address.label"),
      minWidth: 200,
    },
    {
      field: "city",
      headerName: translation("common.city.label"),
      minWidth: 100,
    },
    {
      field: "state",
      headerName: translation("common.state.label"),
      minWidth: 100,
    },
    {
      field: "zip",
      headerName: translation("common.zip.label"),
      minWidth: 50,
    },
  ];

  ///////////////////Birth Date ///////////////////////////////////////
  useEffect(() => {
    if (birthDateStart != null && birthDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["birthDate"] = {
        value: birthDateStart.toISOString() + "," + birthDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [birthDateStart, birthDateEnd]);

  /////////////////// Hire Date ///////////////////////////////////////

  useEffect(() => {
    if (hireDateStart != null && hireDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["hireDate"] = {
        value: hireDateStart.toISOString() + "," + hireDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [hireDateStart, hireDateEnd]);

  /////////////////// review Date ///////////////////////////////////////

  useEffect(() => {
    if (reviewDateStart != null && reviewDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["reviewDate"] = {
        value:
          reviewDateStart.toISOString() + "," + reviewDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [reviewDateStart, reviewDateEnd]);

  /////////////////// nextReviewDate Date ///////////////////////////////////////

  useEffect(() => {
    if (nextReviewDateStart != null && nextReviewDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["nextReviewDate"] = {
        value:
          nextReviewDateStart.toISOString() +
          "," +
          nextReviewDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [nextReviewDateStart, nextReviewDateEnd]);

  /////////////////// MRV Date ///////////////////////////////////////

  useEffect(() => {
    if (mvrDateStart != null && mvrDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["mvrDate"] = {
        value: mvrDateStart.toISOString() + "," + mvrDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [mvrDateStart, mvrDateEnd]);

  /////////////////// physicalDueDate Date ///////////////////////////////////////
  useEffect(() => {
    if (physicalDueDateStart != null && physicalDueDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["physicalDueDate"] = {
        value:
          physicalDueDateStart.toISOString() +
          "," +
          physicalDueDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [physicalDueDateStart, physicalDueDateEnd]);

  /////////////////// physicalSubmittedDate Date ///////////////////////////////////////
  useEffect(() => {
    if (
      physicalSubmittedDateStart != null &&
      physicalSubmittedDateEnd != null
    ) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["physicalSubmittedDate"] = {
        value:
          physicalSubmittedDateStart.toISOString() +
          "," +
          physicalSubmittedDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [physicalSubmittedDateStart, physicalSubmittedDateEnd]);

  /////////////////// CDL Expiration Date ///////////////////////////////////////
  useEffect(() => {
    if (cdlExpirationDateStart != null && cdlExpirationDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["cdlExpirationDate"] = {
        value:
          cdlExpirationDateStart.toISOString() +
          "," +
          cdlExpirationDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [cdlExpirationDateStart, cdlExpirationDateEnd]);

  /////////////////// residentExpirationDate Date ///////////////////////////////////////
  useEffect(() => {
    if (
      residentExpirationDateStart != null &&
      residentExpirationDateEnd != null
    ) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["residentExpirationDate"] = {
        value:
          residentExpirationDateStart.toISOString() +
          "," +
          residentExpirationDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [residentExpirationDateStart, residentExpirationDateEnd]);

  /////////////////// twicExpirationDate Date ///////////////////////////////////////
  useEffect(() => {
    if (twicExpirationDateStart != null && twicExpirationDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["twicExpirationDate"] = {
        value:
          twicExpirationDateStart.toISOString() +
          "," +
          twicExpirationDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [twicExpirationDateStart, twicExpirationDateEnd]);

  /////////////////// passportExpirationDate Date ///////////////////////////////////////
  useEffect(() => {
    if (
      passportExpirationDateStart != null &&
      passportExpirationDateEnd != null
    ) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["passportExpirationDate"] = {
        value:
          passportExpirationDateStart.toISOString() +
          "," +
          passportExpirationDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [passportExpirationDateStart, passportExpirationDateEnd]);

  /*  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (loadSearchedData.length > 0) {
      setAllLoadManagementData({
        ...allLoadManagementData?.allLoadManagement,
        allLoadManagement,
      });
    } else {
      setAllLoadManagementData({});
    }
  }, [allLoadManagement]); */
  useEffect(() => {
    {
      Object.keys(filter).length !== 0 && getDriversListWithFilter();
    }
  }, [rowsState.pageSize, rowsState.page]);

  const getDriversListWithFilter = async () => {
    setLoading(true);

    await dispatch(
      getSearchTablesDataWithFilters(
        "driver",
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
        filter
      )
    ).then((response:any) => {
      setDriverSearchedData(response?.data);
      setdisplayTable(true);
    });
    setLoading(false);
  };

  const handleChangeAutoComplete = async (
    event: any,
    newValue: any,
    setData: any,
    table: string,
    column: string
  ) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getSearchTablesData(table, column, event)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setData(res?.data);
        }
      });
    }
  };
  const onRowClick = (params: GridRowParams) => {
    navigateToDriversDetail(params.row.id);
  };
  const navigateToDriversDetail = (id: number) => {
    navigate(
      `/drivers/drivers/details/${id}?entity=${featureDrivers}&referenceId=${id}`
    );
  };

  const submitSearch = async () => {
    getDriversListWithFilter();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("search.form.search.driver")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              className={classes.formButtonDiscard}
              onClick={() => {
                delayForClear(true);
              }}
            >
              {translation("search.form.clear")}
            </Button>

            <Button
              variant="contained"
              // type="submit"
              onClick={submitSearch}
              className={classes.formButtonSave}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                translation("search.form.search")
              )}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
        {/* //1st row */}

        <CustomFormGrid container style={{ paddingTop: "30px" }}>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="id"
              size="small"
              fullWidth
              value={filter["id"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["id"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["id"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("Driver id")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="firstName"
              size="small"
              fullWidth
              value={filter["firstName"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["firstName"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["firstName"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.firstName.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="lastName"
              size="small"
              fullWidth
              value={filter["lastName"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["lastName"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["lastName"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.lastName.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="cellPhoneNumber"
              size="small"
              fullWidth
              value={filter["cellPhoneNumber"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["cellPhoneNumber"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["cellPhoneNumber"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.phone.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="tenantName"
              size="small"
              fullWidth
              value={filter["tenantName"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["tenantName"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["tenantName"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("owner.company.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="state"
              size="small"
              fullWidth
              value={filter["state"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["state"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["state"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={"State"}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={terminalData ? terminalData : "No Data"}
              value={terminalData?.id}
              getOptionLabel={(option) =>
                option?.title ? option?.title : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["terminal"];
                } else if (newValue?.id) {
                  temp["terminal"] = { value: newValue?.id, filter: "equals" };
                }

                setFilter(temp);
                // field.onChange(newValue);
                // handleSettlementCodeChange(event, newValue)}
              }}
              renderInput={(params: any) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setTerminalData,
                          "terminal",
                          "title"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={translation("owner.homeTerminal.label")}
                  error={false}
                  /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="status"
              label={translation("common.status.label")}
              select
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["status"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["status"];
                }
                setFilter(temp);
              }}
              value={filter["status"]?.value}
            >
              {statusTypes &&
                statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          {/* //2st row */}

          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={vehicleData ? vehicleData : "No Data"}
              // value={terminalData?.id}
              getOptionLabel={(option) =>
                option?.id ? option?.id : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["vehicleLink.vehicle"];
                } else if (newValue?.id) {
                  temp["vehicleLink.vehicle"] = { value: newValue?.id, filter: "equals" };
                }

                setFilter(temp);
                // field.onChange(newValue);
                // handleSettlementCodeChange(event, newValue)}
              }}
              renderInput={(params: any) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setVehicleData,
                          "vehicle",
                          "id"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={"Vehicle ID"}
                  error={false}
                  /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Physical Submitted Due Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="physicalSubmittedDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPhysicalSubmittedDateStart(newValue);
                  }}
                  value={physicalSubmittedDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="physicalSubmittedDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="physicalSubmittedDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPhysicalSubmittedDateEnd(newValue);
                  }}
                  value={physicalSubmittedDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="physicalSubmittedDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* ///////////////////////////////////////////////////////////////// CDL Expiration Start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>CDL Expiration Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="cdlExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setCDLExpirationDateStart(newValue);
                  }}
                  value={cdlExpirationDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="cdlExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="cdlExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setCDLExpirationDateEnd(newValue);
                  }}
                  value={cdlExpirationDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="cdlExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* ///////////////////////////////////////////////////////////////// residentExpirationDate Start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Resident Expiration Date </InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="residentExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setResidentExpirationDateStart(newValue);
                  }}
                  value={residentExpirationDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="residentExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="residentExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setResidentExpirationDateEnd(newValue);
                  }}
                  value={residentExpirationDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="residentExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* ///////////////////////////////////////////////////////////////// twicExpirationDate Start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Twic Expiration Date </InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="twicExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setTwicExpirationDateStart(newValue);
                  }}
                  value={twicExpirationDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="twicExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="twicExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setTwicExpirationDateEnd(newValue);
                  }}
                  value={twicExpirationDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="twicExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Physical Due Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="physicalDueDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPhysicalDueDateStart(newValue);
                  }}
                  value={physicalDueDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="physicalDueDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="physicalDueDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPhysicalDueDateEnd(newValue);
                  }}
                  value={physicalDueDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="physicalDueDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  DOT start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Birth Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.birthDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setBirthDateStart(newValue);
                  }}
                  value={birthDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.birthDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.birthDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setBirthDateEnd(newValue);
                  }}
                  value={birthDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.dot_Date"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Next Review Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="nextReviewDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setNextReviewDateStart(newValue);
                  }}
                  value={nextReviewDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="nextReviewDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="nextReviewDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setNextReviewDateEnd(newValue);
                  }}
                  value={nextReviewDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="nextReviewDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  Hire start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Hire Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="hireDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setHireDateStart(newValue);
                  }}
                  value={hireDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="hireDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="hireDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setHireDateEnd(newValue);
                  }}
                  value={hireDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="hireDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  Review start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Review Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="reviewDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setReviewDateStart(newValue);
                  }}
                  value={reviewDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="reviewDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="reviewDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setReviewDateEnd(newValue);
                  }}
                  value={reviewDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="reviewDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  Next Review start here  ///////////////////////////////////////////////////////} */}



          {/* /////////////////////////////////////////////////////////////////  MRV start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>MRV Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="mvrDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setMRVDateStart(newValue);
                  }}
                  value={mvrDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="mvrDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="mvrDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setMRVDateEnd(newValue);
                  }}
                  value={mvrDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="mvrDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* ///////////////////////////////////////////////////////////////// physicalDueDate Start here  ///////////////////////////////////////////////////////} */}



          {/* ///////////////////////////////////////////////////////////////// physicalSubmittedDate Start here  ///////////////////////////////////////////////////////} */}



          {/* ///////////////////////////////////////////////////////////////// passportExpirationDate Start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Passport Expiration Date </InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="passportExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPassportExpirationDateStart(newValue);
                  }}
                  value={passportExpirationDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="passportExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="passportExpirationDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPassportExpirationDateEnd(newValue);
                  }}
                  value={passportExpirationDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="passportExpirationDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>

        {driverSearchedData?.data?.length > 0 && loading ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "30px" }}
          >
            <CircularProgress />
          </CustomFormGrid>
        ) : driverSearchedData?.data?.length > 0 ? (
          <>
            <CustomFormGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "30px" }}
            >
              <Typography className={classes.loadFormHeadings}>
                {translation("search.form.search.driver.result")}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                title={translation("driver.list.title")}
                rows={driverSearchedData?.data}
                columns={columns}
                loading={loading}
                createPermission={createPermission > -1}
                rowsState={rowsState}
                // onRowClick={onRowClick}
                setRowsState={setRowsState}
                pagination={driverSearchedData.pagination}
                data={driverSearchedData?.data}
                setData={setDriverSearchedData}
                add={true}
                hideBackBar={true}
              />
            </CustomFormGrid>
          </>
        ) : displayTable === true ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "50px" }}
          >
            <Typography variant="h5">No search result found</Typography>
          </CustomFormGrid>
        ) : (
          ""
        )}
      </CustomFormGrid>
    </LocalizationProvider>
  );
};

export default LoadSearchForm;
