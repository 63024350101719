import React from "react";
import { MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import { emailRegEx } from "src/utils/constants";
import CustomTextField from "../customElements/customTextField";
import CustomFormGrid from "../customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { statusTypes } from "src/utils/dropdowns/common";
import { useStyles } from "src/styles/formStyles";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import { driver, admin } from "src/utils/enums";
import { selectUserRoles } from "src/redux/reducers/config";
import { useAppSelector } from "src/hooks/reduxHooks";
interface UserInfoProps {
  control: any;
  errors: { [key: string]: any };
  loading: boolean;
  setValue: any;
  watch: any;
}
const UserInfo = ({
  control,
  errors,
  setValue,
  watch,
}: UserInfoProps): JSX.Element => {
  const params = useParams();
  const classes = useStyles();
  const [translation] = useTranslation<string>("translation");
  const userRoles = useAppSelector(selectUserRoles);

  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="firstName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                variant="outlined"
                fullWidth
                size="small"
                id="firstName"
                label={translation("common.firstName.label")}
                error={Boolean(errors?.firstName)}
                helperText={
                  errors?.firstName && translation("common.firstName.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                variant="outlined"
                size="small"
                fullWidth
                id="lastName"
                label={translation("common.lastName.label")}
                error={Boolean(errors?.lastName)}
                helperText={
                  errors?.lastName && translation("common.lastName.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6} xs={12} md={6} lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              // required: true,
              validate: {
                isValid: () =>
                  watch("phoneNumberValue")
                    ? phone(watch("phoneNumberValue"), {
                        country: watch("phoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                placeholder={translation("common.phone.label")}
                label={translation("common.phone.label")}
                value={watch("phoneNumber")}
                error={Boolean(errors["phoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "phoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["phoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid
          item
          sm={6} xs={12} md={6} lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="altPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("altPhoneNumberValue")
                    ? phone(watch("altPhoneNumberValue"), {
                        country: watch("altPhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                placeholder={translation("common.altPhoneNumber.label")}
                label={translation("common.altPhoneNumber.label")}
                value={watch("altPhoneNumber")}
                error={Boolean(errors["altPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "altPhoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["altPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="status"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
                id="status"
                label={translation("common.status.label")}
                error={Boolean(errors?.status)}
                select
                helperText={
                  errors?.status && translation("common.status.message")
                }
                required
              >
                {statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="roleId"
            control={control}
            defaultValue={""}
            // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                sx={{ textTransform: "capitalize" }}
                {...field}
                fullWidth
                size="small"
                id="roleId"
                label={translation("common.role.label")}
                error={Boolean(errors?.roleId)}
                select
                helperText={
                  errors?.roleId && translation("common.role.message")
                }
                //   required
              >
                {userRoles.map(
                  (item, index) =>
                    item.name.toLocaleLowerCase() !== admin &&
                    item.name.toLocaleLowerCase() !== driver && (
                      <MenuItem
                        value={item.id}
                        key={index}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.name}
                      </MenuItem>
                    )
                )}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="email"
            control={control}
            defaultValue={""}
            rules={{ required: true, pattern: emailRegEx }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                variant="outlined"
                size="small"
                fullWidth
                disabled={Boolean(params?.userId)}
                id="email"
                label={translation("common.email.label")}
                error={Boolean(errors?.email)}
                helperText={
                  errors?.email && translation("common.email.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default UserInfo;
