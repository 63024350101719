import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { UserProps } from "src/interfaces/user";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { NotificationContext } from "src/utils/context";
import { selectUser, selectCompany } from "src/redux/reducers/auth";
import ProfileForm from "src/components/profile";
import CompanyDefaultForm from "src/components/companyForm";
import { sanitizeEntity } from "src/utils/common";
import { updateUser } from "src/redux/actions/auth";
import { CompanyProps } from "src/interfaces/company";
import { updateCompany } from "src/redux/actions/company";
import AccountDataForm from "src/components/accountData";

const SettingsContainer = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const company = useAppSelector(selectCompany);
    const useNotification = useContext(NotificationContext);
    const [loading, setLoading] = useState(false);
    /* UserProps */
    const onProfileFormSubmit = async (data: any) => {
        let objToSubmit: UserProps = { ...data };
        delete objToSubmit["password"];
        delete objToSubmit["email"];
        objToSubmit = sanitizeEntity(objToSubmit);

        if (user?.id) {
            setLoading(true);
            await dispatch(updateUser(objToSubmit, user?.id));
            setLoading(false);
        } else {
            useNotification({
                open: true,
                message: "User not found!",
                severity: "error",
            });
        }
    };

    const onAccountDataFormSubmit = async (data: any) => {
        if (data && data.password && data.confirmPassword) {
            if (data.password !== data.confirmPassword) {
                useNotification({
                    open: true,
                    message: "Not matching password",
                    severity: "error",
                });
                return;
            }

            delete data["email"];
            delete data["confirmPassword"];


            const objToSubmit = sanitizeEntity(data);

            if (user?.id) {
                setLoading(true);
                await dispatch(updateUser(objToSubmit, user?.id));
                setLoading(false);
                return;
            } else {
                useNotification({
                    open: true,
                    message: "User not found!",
                    severity: "error",
                });
                return;
            }
        }
    }

    const onCompanyFormSubmit = async (data: any) => {
        let objToSubmit: CompanyProps = { ...data };
        objToSubmit = sanitizeEntity(objToSubmit);
        objToSubmit["status"] = "active";

        if (company?.id) {
            setLoading(true);
            const response = await dispatch(updateCompany(objToSubmit, company?.id));
            if (response.success) {
                setTimeout(() => navigate("/dashboard"), 500);
            } else {
                setLoading(false);
            }
        } else {
            useNotification({
                open: true,
                message: "Company ID not found!",
                severity: "error",
            });
        }
    };


    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item lg={10} md={10} xs={12}>
                    <CompanyDefaultForm loading={loading} onSubmit={onCompanyFormSubmit} />
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item lg={10} md={10} xs={12}>
                    <ProfileForm loading={loading} onSubmit={onProfileFormSubmit} />
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item lg={10} md={10} xs={12}>
                    <AccountDataForm loading={loading} onSubmit={onAccountDataFormSubmit} />
                </Grid>
            </Grid>
        </>
    );
};
export default SettingsContainer;
