import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { settlementDayTypes } from "src/utils/dropdowns/owner";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useAppSelector } from "src/hooks/reduxHooks";
import { GridAddIcon } from "@mui/x-data-grid";
import { useStyles } from "src/styles/formStyles";
import AddCommonDialog from "src/components/common/addCommonDialog";
import TerminalFormContainer from "src/containers/terminals/form";
interface SettlementInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled: boolean;
  linkingAddress: any;
}
const SettlementInfo = ({
  control,
  errors,
  disabled,
  linkingAddress,
}: SettlementInfoProps): JSX.Element => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const terminals = useAppSelector(selectTerminals);
  const [openTerminalModal, setOpenTerminalModal] = useState<boolean>(false);
  const handleOpenTerminalModal = () => {
    setOpenTerminalModal(true);
  };
  const handleCloseTerminalModal = () => {
    setOpenTerminalModal(false);
  };

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="settlementDay"
            control={control}
            defaultValue={""}
           // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                select
                id="settlementDay"
                label={translation("owner.settlementDay.label")}
                error={Boolean(errors?.settlementDay)}
                helperText={
                  errors?.settlementDay &&
                  translation("owner.settlementDay.message")
                }
              //  required
              >
                {settlementDayTypes?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={10}
          md={6}
          lg={6}
          display="flex"
          justifyContent="space-between"
        >
          <CustomFormGrid
            sx={{
              width: "100%",
              padding: "0px",
            }}
          >
            <Controller
              name="terminal"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  id="terminal"
                  disabled={disabled}
                  select
                  label={translation("owner.homeTerminal.label")}
                  error={Boolean(errors?.terminal)}
                  helperText={
                    errors?.terminal &&
                    translation("owner.homeTerminal.message")
                  }
                  required
                >
                  {terminals?.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.title}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          {linkingAddress ? (
            <CustomFormGrid>
              <GridAddIcon
                className={classes.disableGridAddButton}
              ></GridAddIcon>
            </CustomFormGrid>
          ) : (
            <CustomFormGrid>
              <GridAddIcon
                className={classes.formAddOwnerButton}
                onClick={() => handleOpenTerminalModal()}
              ></GridAddIcon>
            </CustomFormGrid>
          )}
        </CustomFormGrid>
      </CustomFormGrid>
      <AddCommonDialog
        handleClose={handleCloseTerminalModal}
        open={openTerminalModal}
        title="Add Termianl"
        ComponentToRender={
          <TerminalFormContainer handleClose={handleCloseTerminalModal} />
        }
      />
    </div>
  );
};

export default SettlementInfo;
