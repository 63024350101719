import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/reduxHooks";
import {
  selectSecurityGroup,
  selectAllPermissions,
} from "src/redux/reducers/securityGroups";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import CustomCheckbox from "src/components/customElements/customCheckbox";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface SecurityGroupFormProps {
  loading: boolean;
  pageLoading: boolean;
 /*  onSubmit: (data: SecurityGroupProps) => any; */
  onSubmit: (data: any) => any;
}

const SecurityGroupForm = ({
  loading,
  pageLoading,
  onSubmit,
}: SecurityGroupFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const securityGroup = useAppSelector(selectSecurityGroup);
  const allPermissions = useAppSelector(selectAllPermissions);
  const [permissionsArray, setPermissionsArray] = useState<Array<number>>([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("securityGroupPermissions", permissionsArray);
  }, [permissionsArray]);
  const handleSelectPermission = (e: any) => {
    const permissions = [...permissionsArray];
    if (e.target.checked) {
      setPermissionsArray([...permissions, parseInt(e.target.value)]);
    } else {
      const index = permissions.findIndex(
        (item) => item === parseInt(e.target.value)
      );
      if (index > -1) {
        permissions.splice(index, 1);
      }
      setPermissionsArray([...permissions]);
    }
  };
  const handleSelectAll = (e: any, item: any) => {
    let _permissionArray: Array<number> = [...permissionsArray];
    if (e.target.checked) {
      for (const permission of item.data) {
        if (!_permissionArray.includes(permission.id)) {
          _permissionArray.push(permission.id);
        }
      }
    } else {
      for (const permission of item.data) {
        if (_permissionArray.includes(permission.id)) {
          _permissionArray = _permissionArray.filter(
            (item) => item !== permission.id
          );
        }
      }
    }
    setPermissionsArray([..._permissionArray]);
  };

  useEffect(() => {
    if (params?.id && securityGroup) {
      setValue("name", securityGroup.name);
      if (securityGroup.securityGroupPermissions) {
        const _permissionsArray = [];
        for (const permission of securityGroup.securityGroupPermissions) {
          if (typeof permission === "object") {
            _permissionsArray.push(permission.permission.id);
          }
        }
        setPermissionsArray([..._permissionsArray]);
        setValue("securityGroupPermissions", [..._permissionsArray]);
      }
    }
  }, [params?.id, securityGroup]);

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          p: 0,
        }}
      >
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {params?.id
                ? translation("securityGroups.form.title2")
                : translation("securityGroups.form.title1")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <CustomFormGrid container>
                <CustomFormGrid
                  item
                  sx={{
                    padding: "5px",
                  }}
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label={translation("securityGroups.form.name.label")}
                        error={Boolean(errors?.name)}
                        helperText={
                          errors?.name &&
                          translation("securityGroups.form.name.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>

                <CustomFormGrid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ padding: "0px 5px" }}
                >
                  {allPermissions &&
                    allPermissions.map((item, index) => (
                      <CustomFormGrid container key={index} alignItems="center">
                        <CustomFormGrid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="h3"
                            sx={{ padding: "1px", textTransform: "capitalize" }}
                          >
                            <CustomCheckbox
                              value={item?.id}
                              onChange={(e: any) => handleSelectAll(e, item)}
                              checked={permissionsArray.some(
                                (permission) =>
                                  item.data.findIndex(
                                    (action) => action.id === permission
                                  ) >= 0
                              )}
                            />
                            {item.feature}
                          </Typography>
                        </CustomFormGrid>

                        {item.data.map((element, index) => (
                          <CustomFormGrid
                            item
                            xs={12}
                            sm={4}
                            md={2}
                            key={index}
                          >
                            <Controller
                              name="securityGroupPermissions"
                              control={control}
                              defaultValue={""}
                              render={({ field }) => (
                                <FormControlLabel
                                  {...field}
                                  sx={{ padding: "10px" }}
                                  control={
                                    <CustomCheckbox
                                      value={element?.id}
                                      onChange={handleSelectPermission}
                                      checked={permissionsArray.includes(
                                        element.id
                                      )}
                                    />
                                  }
                                  label={element.action}
                                />
                              )}
                            />
                          </CustomFormGrid>
                        ))}
                      </CustomFormGrid>
                    ))}
                </CustomFormGrid>
              </CustomFormGrid>
              <CustomFormGrid container justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.formButton}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : params?.id ? (
                    translation("common.form.update")
                  ) : (
                    translation("common.form.add")
                  )}
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  className={classes.formButton}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.cancel")}
                </Button>
              </CustomFormGrid>
            </form>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SecurityGroupForm;
