import React, { useState } from "react";
import { Icon } from "ts-react-feather-icons";
import {
  Box,
  IconButton,
  Toolbar,
  Menu,
  Typography,
  Avatar,
  Button,
  Tooltip,
  Badge
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { selectUser } from "src/redux/reducers/auth";
import ProfileDropdown from "./profileDropdown";
import LogoIcon from "../logo";
import NotificationDropdown from "./notificationDropdown";

interface HeaderProps {
  customClass?: string;
  isSideBarOpen: boolean;
  sidebarWidth: number;
  openSidebar: () => any;
}

const Header = ({
  customClass,
  isSideBarOpen = true,
  sidebarWidth,
  openSidebar,
}: HeaderProps) => {

  const user = useAppSelector(selectUser);
  const [anchorElProfileMenu, setAnchorElProfileMenu] = useState<null | HTMLElement>(null);
  const [anchorElNotificationMenu, setAnchorElNotificationMenu] = useState<null | HTMLElement>(null);

  const isProfileMenuOpen = Boolean(anchorElProfileMenu);
  const isNotificationMenuOpen = Boolean(anchorElNotificationMenu);




  const handleNotificationBtnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotificationMenu(event.currentTarget);
  }

  const handleProfileBtnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfileMenu(event.currentTarget);
  }

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${sidebarWidth}px)`,
      marginLeft: `${sidebarWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar
      sx={{ boxShadow: "2", backgroundColor: "#fff" }}
      elevation={0}
      className={customClass}
      position="fixed"
      style={{ background: "#fff" }}
      open={isSideBarOpen}
    >
      <Toolbar style={{ minHeight: "60px" }}>
        {!isSideBarOpen &&
          <>
            <Box
              sx={{
                paddingRight: 2
              }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={() => { openSidebar() }}
                size="small"
                sx={{
                  display: {
                    lg: "flex",
                    xs: "none",
                  },
                }}
              >
                <Icon name="menu" />
              </IconButton>

              <IconButton
                size="small"
                color="inherit"
                aria-label="menu"
                onClick={() => { openSidebar() }}
                sx={{
                  display: {
                    lg: "none",
                    xs: "flex",
                  },
                }}
              >
                <Icon name="menu" size="20" />
              </IconButton>
            </Box>

            <LogoIcon />
          </>
        }

        <Box flexGrow={1} />
        <IconButton color="secondary" aria-label="show help" size="small">
          <Icon name="help-circle"></Icon>
        </IconButton>

        <Badge color="secondary" variant="dot" invisible={false}>
          <IconButton
            color="secondary"
            aria-label="show notification"
            size="small"
            sx={{ ml: 2 }}
            onClick={handleNotificationBtnClick}
            aria-controls={isNotificationMenuOpen ? 'notification-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isNotificationMenuOpen ? 'true' : undefined}
          >
            <Icon name="bell"></Icon>
          </IconButton>
        </Badge>
        <NotificationDropdown
          isOpened={isNotificationMenuOpen}
          anchorEl={anchorElNotificationMenu}
          onClose={() => { setAnchorElNotificationMenu(null) }}
        />
        <Box
          sx={{
            width: "1px",
            backgroundColor: "rgba(0,0,0,0.1)",
            height: "25px",
            ml: 2,
            mr: 1,
          }}
        />

        <Tooltip title="Profile Options">
          <Button
            aria-label="menu"
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleProfileBtnClick}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={user?.profilePicture || "http://"}
                alt={user?.firstName?.toLocaleUpperCase()}
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                  },
                  alignItems: "center",
                }}
              >
                <Typography
                  color="textSecondary"
                  variant="h5"
                  fontWeight="400"
                  sx={{ ml: 1 }}
                >
                  Hi,
                </Typography>
                <Typography
                  variant="h5"
                  fontWeight="700"
                  sx={{
                    ml: 1,
                    textTransform: "capitalize",
                  }}
                >
                  {user?.firstName}
                </Typography>
                <Icon name="chevron-down" size="20" />
              </Box>
            </Box>
          </Button>
        </Tooltip>

        <ProfileDropdown
          isOpened={isProfileMenuOpen}
          anchorEl={anchorElProfileMenu}
          onClose={() => { setAnchorElProfileMenu(null) }}
        />

      </Toolbar>
    </AppBar>
  );
};

export default Header;
