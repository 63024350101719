import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CreateLookupContainer from "src/containers/lookup/form";
const LookupForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Lookups" description="Porto Lookups" />
      <CreateLookupContainer />
    </Grid>
  );
};

export default LookupForm;
