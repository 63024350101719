import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {
  Drawer,
  Divider,
  IconButton,
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import LogoIcon from "../logo";
import { SidebarItems } from "./sidebarItems";
import Scrollbar from "src/components/customScroll/scrollbar";
import SideNav from "./sideNav";


interface SidebarProps {
  closeSidebar: () => void;
  isSidebarOpen: boolean;
  sidebarWidth: number;
}

const Sidebar = ({
  closeSidebar,
  isSidebarOpen,
  sidebarWidth,
}: SidebarProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const [selectedItemIndex, setSelectedItemIndex] = useState();

  const { pathname } = useLocation();
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));
  const permissions = useAppSelector(selectUserPermissions);
  const user = useAppSelector(selectUser);

  const handleClick = (index: number) => {
    // if (open === index) {
    //   setOpen((prevopen) => !prevopen);
    // } else {
    //   setOpen(index);
    // }
  };

  const handleItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    console.log(index);
    setSelectedItemIndex(index as any);
  };


  const handleDrawerClose = () => {
    closeSidebar();
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));

  // const SidebarContent = (
  //   <Scrollbar style={{ height: "calc(100vh - 5px)" }}>

  //     <List sx={{ p: 1 }}>
  //       {SidebarItems.map((item, index) => {
  //         // {/********SubHeader**********/}

  //         if (item.subheader) {
  //           return (
  //             <li key={index}>
  //               <Typography
  //                 variant="subtitle2"
  //                 fontWeight="500"
  //                 sx={{ my: 2, mt: 4, opacity: "0.4", marginLeft: "12px" }}
  //               >
  //                 {translation(item.subheader)}
  //               </Typography>
  //             </li>
  //           );
  //         } else if (item.children) {


  //           return (
  //             <React.Fragment key={index}>
  //               <ListItem
  //                 button
  //                 component="li"
  //                 onClick={() => handleClick(index)}
  //                 selected={pathWithoutLastPart.includes(item.href)}
  //                 sx={{
  //                   mb: 1,
  //                   ...(pathWithoutLastPart.includes(item.href) && {
  //                     color: "white",
  //                     backgroundColor: (theme) =>
  //                       `${theme.palette.primary.main}!important`,
  //                   }),
  //                 }}
  //               >
  //                 <ListItemIcon
  //                   sx={{
  //                     ...(pathWithoutLastPart.includes(item.href) && {
  //                       color: "white",
  //                     }),
  //                   }}
  //                 >
  //                   <Icon name={item.icon as IconName} size="20" />
  //                 </ListItemIcon>
  //                 <ListItemText>
  //                   {translation(item.title)}
  //                 </ListItemText>
  //                 {/* {index === open ||
  //                       pathWithoutLastPart.includes(item.href) ? (
  //                       <Icon name="chevron-down" size="16" />
  //                     ) : (
  //                       <Icon name="chevron-right" size="16" />
  //                     )} */}
  //               </ListItem>
  //               <Collapse
  //                 // in={index === open}
  //                 timeout="auto"
  //                 unmountOnExit
  //               >
  //                 <List component="li" disablePadding>
  //                   {item.children.map((child, _index) => {
  //                     const permissionCheck =
  //                       user?.role?.name?.toLocaleLowerCase() ===
  //                       admin ||
  //                       (child.name &&
  //                         permissions[child.name]?.length > 0);

  //                     return (
  //                       permissionCheck && (
  //                         <ListItem
  //                           key={_index}
  //                           button
  //                           component={NavLink}
  //                           to={child.href}
  //                           onClick={closeSidebar}
  //                           selected={pathDirect.includes(child.href)}
  //                           sx={{
  //                             mb: 1,
  //                             ...(pathDirect.includes(child.href) && {
  //                               color: "primary.main",
  //                               backgroundColor:
  //                                 "transparent!important",
  //                             }),
  //                           }}
  //                         >
  //                           <ListItemIcon
  //                             sx={{
  //                               svg: {
  //                                 width: "14px",
  //                                 marginLeft: "3px",
  //                               },
  //                               ...(pathDirect.includes(
  //                                 child.href
  //                               ) && {
  //                                 color: "primary.main",
  //                               }),
  //                             }}
  //                           >
  //                             <Icon
  //                               name={child.icon as IconName}
  //                               size="20"
  //                             />
  //                           </ListItemIcon>
  //                           <ListItemText>
  //                             {translation(child.title)}
  //                           </ListItemText>
  //                         </ListItem>
  //                       )
  //                     );
  //                   })}
  //                 </List>
  //               </Collapse>
  //             </React.Fragment>
  //           );
  //           // {/********If Sub No Menu**********/}
  //         } else {

  //           return (

  //             <List component="li" disablePadding key={index}>
  //               <ListItem
  //                 onClick={() => handleClick(index)}
  //                 button
  //                 component={NavLink}
  //                 to={item.href}
  //                 selected={pathDirect.includes(item.href)}
  //                 sx={{
  //                   mb: 1,
  //                   ...(pathDirect.includes(item.href) && {
  //                     color: "white",
  //                     backgroundColor: (theme) =>
  //                       `${theme.palette.primary.main}!important`,
  //                   }),
  //                 }}
  //               >
  //                 <ListItemIcon
  //                   sx={{
  //                     ...(pathDirect.includes(item.href) && {
  //                       color: "white",
  //                     }),
  //                   }}
  //                 >
  //                   <Icon name={item.icon as IconName} size="20" />
  //                 </ListItemIcon>
  //                 <ListItemText onClick={closeSidebar}>
  //                   {item.title && translation(item.title )}
  //                 </ListItemText>
  //               </ListItem>
  //             </List>

  //           );
  //         }
  //       })}
  //     </List>

  //   </Scrollbar>
  // );

  return (
    <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor="left"
      open={isSidebarOpen}
      variant="persistent"
    >
      <DrawerHeader style={{ minHeight: "60px" }}>
        <LogoIcon />
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Scrollbar style={{ height: "calc(100vh - 5px)", backgroundColor: "#0d4f86", color: "white" }}>
        {

          SidebarItems.map((e, key) =>
            <SideNav
              key={key}
              selected={selectedItemIndex === key}
              onClick={(event) => handleItemClick(event, key)}
              title={e.title || ""}
              name={e.name || ""}
              subItems={e.children || []}
              href={e.href}
              icon={e.icon}
            />)

        }
      </Scrollbar>

    </Drawer>
  );
};

export default Sidebar;
