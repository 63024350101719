import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { NotificationProps } from "src/interfaces/notification";

const initialState: NotificationProps = {
  open: false,
  severity: "success",
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,

  reducers: {
    showNotification: (state, action: PayloadAction<NotificationProps>) => {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    resetNotification: (state) => {
      state.open = false;
      state.message = "";
    },
  },
});

export const { showNotification, resetNotification } = notificationSlice.actions;
export const selectNotification = (state: RootState): NotificationProps =>
  state.notification;

export default notificationSlice.reducer;
