import React from "react";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const CustomField = React.forwardRef<HTMLInputElement, any>((props, ref) => {
  return <Switch ref={ref} {...props} />;
});

CustomField.displayName = "CustomField";

const CustomSwitch = styled(CustomField)(({ theme }) => ({
  "&.MuiSwitch-root": {
    width: "68px",
    height: "49px",
  },
  "&  .MuiButtonBase-root": {
    top: "6px",
    left: "6px",
  },
  "&  .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb": {
    backgroundColor: "primary.main",
  },
  "& .MuiSwitch-thumb": {
    width: "18px",
    height: "18px",
    borderRadius: "6px",
  },

  "& .MuiSwitch-track": {
    backgroundColor: `${
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.09)"
        : "rgba(73,82,88,0.12)"
    }`,
    opacity: 1,
    borderRadius: "5px",
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "primary",
        opacity: 0.18,
      },
    },
  },
}));

export default CustomSwitch;
