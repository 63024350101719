import React from "react";
import { Navigate } from "react-router-dom";
import SecurityGroup from "../pages/securityGroups";
import SecurityGroupForm from "../pages/securityGroups/form";
import SecurityGrouSearch from "src/containers/securityGroups/securityGroupSearch";
import { UserPermissions } from "src/interfaces/user";
import { admin, create, update, featureSecurity } from "src/utils/enums";

export const securityGroupRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const securityGroupPermission =
    allUserPermissions && allUserPermissions[featureSecurity];
  const createPermission =
    role === admin
      ? 0
      : securityGroupPermission?.findIndex((item) => item === create);

  const updatePermission =
    role === admin
      ? 0
      : securityGroupPermission?.findIndex((item) => item === update);

  return [
    {
      path: "/config/security-groups",
      exact: true,
      element:
        role === admin || securityGroupPermission?.length > 0 ? (
          <SecurityGroup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/config/security-groups/create",
      exact: true,
      element:
        createPermission > -1 ? <SecurityGroupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/security-groups/:id",
      exact: true,
      element:
        updatePermission > -1 ? <SecurityGroupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/security-groups/search",
      exact: true,
      element:
        updatePermission > -1 ? <SecurityGrouSearch /> : <Navigate to="/404" />,
    },
  ];
};
