import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { CustomerProps } from "src/interfaces/customer";

export interface CustomerState {
  CustomersList: Array<CustomerProps>;
  singleCustomer: CustomerProps | null;
}

const initialState: CustomerState = {
  CustomersList: [],
  singleCustomer: null,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,

  reducers: {
    setAllCustomers: (state, action: PayloadAction<Array<CustomerProps>>) => {
      state.CustomersList = action.payload;
    },
    setSingleCustomer: (state, action: PayloadAction<CustomerProps>) => {
      state.singleCustomer = action.payload;
    },
    resetCustomer: (state) => {
      state.singleCustomer = null;
    },
    resetCustomerState: (state) => {
      state.singleCustomer = null;
      state.CustomersList = [];
    },
  },
});
export const {
  setAllCustomers,
  setSingleCustomer,
  resetCustomer,
  resetCustomerState,
} = customersSlice.actions;
export const selectCustomers = (state: RootState): any =>
  state.customers.CustomersList;
export const selectCustomer = (state: RootState): CustomerProps | null =>
  state.customers.singleCustomer;
export default customersSlice.reducer;
