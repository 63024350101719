import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Login from "src/components/login";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { login } from "src/redux/actions/auth";
import { getCompany } from "src/redux/actions/company";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { useParams } from "react-router-dom";

const LoginContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(true);
  const classes = useStyles();
  const params = useParams();

  const [companyData, setCompanyData] = useState<any>({});
  useEffect(() => {
    setLoadingCompany(true);
    const fetchData = async () => {
      const result = await dispatch(getCompany(params?.tenant));
      if (result?.success) {
        setCompanyData(result?.data);
        setLoadingCompany(false);
      } else {
        setLoadingCompany(false);

        // navigate(`/404`);
      }
    };
    fetchData();
  }, []);
  /* LoginCredProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await dispatch(login(data));
    if (response.success) {
      navigate("/dashboard");
    } else {
      setLoading(false);
    }
  };
  return loadingCompany &&
    Object.keys(companyData).length === 0 &&
    companyData.constructor === Object ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <Login onSubmit={onSubmit} loading={loading} companyData={companyData} />
  );
};

export default LoginContainer;
