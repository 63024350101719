import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Badge
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { CompanyProps } from "src/interfaces/company";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import StatusBadge from "../common/StatusBadge";
import { pending } from "src/utils/enums";
import ProfilePictureModal from "../common/pictureModal";
import { uploadCompanyLogo } from "src/redux/actions/company";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { typedKeys } from "src/utils/common";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import { selectCompany } from "src/redux/reducers/auth";
import { EditOutlined } from "@mui/icons-material";

interface CompanyFormProps {
  loading: boolean;
  /* onSubmit: (data: CompanyProps) => any; */
  onSubmit: (data: any) => any;
}

const CompanyForm = ({ loading, onSubmit }: CompanyFormProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [translation] = useTranslation("translation");
  const user = useAppSelector(selectUser);
  const company = useAppSelector(selectCompany);
  const [openPictureModal, setOpenPictureModal] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };

  const setFormValues = (key: keyof CompanyProps) => {
    if (company) {
      if (typeof company[key] === "boolean") {
        setValue(key, company[key]);
      } else {
        setValue(key, company[key] ? company[key] : "");
      }
    }
  };
  useEffect(() => {
    if (company) {
      const allAttributes = typedKeys(company);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }
  }, [company]);

  const handleOpenPictureModal = () => {
    setOpenPictureModal(true);
  };
  const handleClosePictureModal = () => {
    setOpenPictureModal(false);
  };
  const handleProfilePictureUpload = async (formData: FormData) => {
    if (user?.tenant?.id) {
      const response = await dispatch(
        uploadCompanyLogo(formData, user?.tenant?.id)
      );
      return response;
    }
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 32,
    height: 32,
    backgroundColor: `${theme.palette.primary.main}`,
    border: `2px solid ${theme.palette.primary.light}`,
  }));

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '10px'
        }}
      >
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {translation("companySettings.title")}
            </Typography>
          </Box>

          {user?.tenant?.status && user?.tenant?.status !== 'active' && <StatusBadge label={user?.tenant?.status} />}
        </Box>
        <Divider />
        <CardContent
          sx={{
            padding: "10px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>

              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={6}
                lg={6}
              >
                <Controller
                  name="name"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="name"
                      disabled={company?.name ? true : false}
                      label={translation("companySettings.name.label")}
                      error={Boolean(errors?.name)}
                      helperText={
                        errors?.name &&
                        translation("companySettings.name.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={6}
                lg={6}
              >
                <Controller
                  name="url"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="url"
                      label={translation("companySettings.url.label")}
                      error={Boolean(errors?.url)}
                      helperText={
                        errors?.url &&
                        translation("companySettings.url.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={4}
                xs={12}
                md={4}
                lg={4}
              >
                <Controller
                  name="city"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="city"
                      label={translation("common.city.label")}
                      error={Boolean(errors?.city)}
                      helperText={
                        errors?.city && translation("common.city.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={4}
                xs={12}
                md={4}
                lg={4}
              >
                <Controller
                  name="state"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="state"
                      label={translation("common.state.label")}
                      error={Boolean(errors?.state)}
                      helperText={
                        errors?.state && translation("common.state.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={4}
                xs={12}
                md={4}
                lg={4}
              >
                <Controller
                  name="zip"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="zip"
                      type="number"
                      inputProps={{ min: 0 }}
                      label={translation("common.zip.label")}
                      error={Boolean(errors?.zip)}
                      helperText={
                        errors?.zip && translation("common.zip.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={8}
                lg={8}
              >
                <Controller
                  name="address"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="address"
                      label={translation("common.address.label")}
                      error={Boolean(errors?.address)}
                      helperText={
                        errors?.address && translation("common.address.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={4}
                lg={4}
                className={classes.phoneField}
              >
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: true,
                    validate: {
                      isValid: () =>
                        watch("phoneValue")
                          ? phone(watch("phoneValue"), {
                            country: watch("phoneCountry"),
                          })?.phoneNumber !== null ||
                          "Enter Valid Phone Number"
                          : true,
                    },
                  }}
                  render={() => (
                    <PhoneInput
                      placeholder={translation("common.phone.label")}
                      value={watch("phone")}
                      error={Boolean(errors["phone"])}
                      onChange={(value: string, data: any) => {
                        onChangePhoneInput(value, data.countryCode, "phone");
                      }}
                      country="us"
                      isValid={(value: any, country: any) => {
                        return errors["phone"]
                          ? validatePhoneNumber(value, country.iso2)
                          : true;
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={8}
                lg={8}
              >
                <Controller
                  name="dispatchFax"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      size="small"
                      id="dispatchFax"
                      label={translation("companySettings.dispatchFax.label")}
                      error={Boolean(errors?.dispatchFax)}
                      helperText={
                        errors?.dispatchFax &&
                        translation("companySettings.dispatchFax.message")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={4}
                lg={4}
                className={classes.phoneField}
              >
                <Controller
                  name="dispatchPhone"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: true,
                    validate: {
                      isValid: () =>
                        watch("dispatchPhoneValue")
                          ? phone(watch("dispatchPhoneValue"), {
                            country: watch("dispatchPhoneCountry"),
                          })?.phoneNumber !== null ||
                          "Enter Valid Phone Number"
                          : true,
                    },
                  }}
                  render={() => (
                    <PhoneInput
                      placeholder={translation(
                        "companySettings.dispatchPhone.label"
                      )}
                      country="us"
                      value={watch("dispatchPhone")}
                      error={Boolean(errors["dispatchPhone"])}
                      onChange={(value: string, data: any) => {
                        onChangePhoneInput(
                          value,
                          data.countryCode,
                          "dispatchPhone"
                        );
                      }}
                      isValid={(value: any, country: any) => {
                        return errors["dispatchPhone"]
                          ? validatePhoneNumber(value, country.iso2)
                          : true;
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="start">
              <Box
                sx={{
                  padding: "5px 10px",
                }}
              >
                <Typography variant="caption" color={"GrayText"} display="block" gutterBottom>
                  Image size must be 1880*294
                </Typography>
                <Badge
                  overlap="rectangular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={handleOpenPictureModal}
                    >
                      <EditOutlined />
                    </SmallAvatar>
                  }
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "485px",
                      height: "74px",
                      border: "solid 2px #d1d1d1",
                      pl:2,
                      pr:2
                    }}
                    src={user?.tenant?.logoURL || "http://"}
                    alt={String(company?.name).toUpperCase()} />
                </Badge>
              </Box>
            </Grid>
            <Grid container justifyContent="end">
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                ) : user?.tenant?.status === pending ? (
                  translation("companySettings.submit.review")
                ) : (
                  translation("companySettings.submit.update")
                )}
              </Button>
            </Grid>
          </form>
        </CardContent>
        <ProfilePictureModal
          handleClose={handleClosePictureModal}
          open={openPictureModal}
          loading={loading}
          handlePictureUpload={handleProfilePictureUpload}
          title={translation("companyLogo.modal.title")}
          pictureURL={
            user?.tenant?.logoURL
              ? user?.tenant?.logoURL.includes("porto-files-upload")
                ? user?.tenant?.logoURL
                : `${process.env.REACT_APP_BASE_URL}/${user?.tenant?.logoURL}`
              : ""
          }
        />
      </Card>
    </div>
  );
};

export default CompanyForm;
