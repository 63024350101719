import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import ProfileForm from "src/components/profile";
import { UserProps } from "src/interfaces/user";
import { updateUser } from "src/redux/actions/auth";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import { NotificationContext } from "src/utils/context";
import { sanitizeEntity } from "src/utils/common";

const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const useNotification = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
/* UserProps */
  const onSubmit = async (data: any) => {
    let objToSubmit: UserProps = { ...data };
    delete objToSubmit["password"];
    delete objToSubmit["email"];
    objToSubmit = sanitizeEntity(objToSubmit);

    if (user?.id) {
      setLoading(true);
      await dispatch(updateUser(objToSubmit, user?.id));
      setLoading(false);
    } else {
      useNotification({
        open: true,
        message: "User ID not found!",
        severity: "error",
      });
    }
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <ProfileForm loading={loading} onSubmit={onSubmit} />
      </Grid>
    </Grid>
  );
};

export default Profile;
