import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { TerminalProps } from "src/interfaces/terminal";

export interface TerminalState {
  terminalsList: Array<TerminalProps>;
  singleTerminal: TerminalProps | null;
}

const initialState: TerminalState = {
  terminalsList: [],
  singleTerminal: null,
};

export const terminalsSlice = createSlice({
  name: "terminals",
  initialState,

  reducers: {
    setAllTerminals: (state, action: any) => {
      state.terminalsList = action.payload;
    },
    setSingleTerminal: (state, action: PayloadAction<TerminalProps>) => {
      state.singleTerminal = action.payload;
    },
    resetTerminal: (state) => {
      state.singleTerminal = null;
    },
    resetTerminalState: (state) => {
      state.singleTerminal = null;
      state.terminalsList = [];
    },
    updateTerminalList: (state, action: PayloadAction<TerminalProps>) => {
      state.terminalsList = [...state.terminalsList, action.payload]
    },
  },
});

export const {
  setAllTerminals,
  setSingleTerminal,
  resetTerminal,
  resetTerminalState,
  updateTerminalList,
} = terminalsSlice.actions;
export const selectTerminals = (state: RootState): Array<TerminalProps> =>
  state.terminals.terminalsList;
export const selectTerminal = (state: RootState): TerminalProps | null =>
  state.terminals.singleTerminal;

export default terminalsSlice.reducer;
