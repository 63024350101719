import React from "react";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import { useStyles } from "src/styles/formStyles";

interface ContactInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: any;
  setValue: any;
}
const ContactInfo = ({
  control,
  errors,
  watch,
  setValue,
}: ContactInfoProps): JSX.Element => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");

  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          className={classes.phoneField}
        >
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: {
                isValid: () =>
                  watch("phoneNumberValue")
                    ? phone(watch("phoneNumberValue"), {
                        country: watch("phoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.phone.label")}
                placeholder={translation("common.phone.label")}
                value={watch("phoneNumber")}
                error={Boolean(errors["phoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "phoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["phoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          className={classes.phoneField}
        >
          <Controller
            name="altPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              //   required: true,
              validate: {
                isValid: () =>
                  watch("phoneNumberValue")
                    ? phone(watch("phoneNumberValue"), {
                        country: watch("phoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid  Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.altPhoneNumber.label")}
                placeholder={translation("common.altPhoneNumber.label")}
                value={watch("altPhoneNumber")}
                error={Boolean(errors["altPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "altPhoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["altPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          className={classes.phoneField}
        >
          <Controller
            name="faxPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              // required: true,
              validate: {
                isValid: () =>
                  watch("faxPhoneNumberValue")
                    ? phone(watch("faxPhoneNumberValue"), {
                        country: watch("faxPhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid  Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.faxPhoneNumber.label")}
                placeholder={translation("common.faxPhoneNumber.label")}
                value={watch("faxPhoneNumber")}
                error={Boolean(errors["faxPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "faxPhoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["faxPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default ContactInfo;
