import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { selectDriver } from "src/redux/reducers/driver";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import ModelInput from "src/components/common/InformationModels/inputModel";
import { Close } from "@mui/icons-material";
import { useStyles } from "src/styles/formStyles";
import CustomGrid from "src/components/customElements/customFormGrid";
import StatusBadge from "src/components/common/StatusBadge";
import { dateFormat } from "src/utils/constants";
import moment from "moment";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";

import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import { getDriverById } from "src/redux/actions/driver";

interface DialogProps {
  open: boolean;
  handleClose: () => void;

  loadId: number;
}

export default function ConfirmationDialog({
  open,
  handleClose,
  loadId,
}: DialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [translation] = useTranslation("translation");
  const [pageLoading, setPageLoading] = useState(false);
  const loadData = useAppSelector(selectLoadManagement);
  const billToSummary = loadData != null && loadData?.billToInformation;


  const getSingleLineItem = async (id: number) => {
    setPageLoading(true);
    await dispatch(getLoadManagementById(id));
    setPageLoading(false);
  };

  useEffect(() => {
    if (loadId) {
      getSingleLineItem(loadId);
    }
  }, [loadId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomFormGrid container className={classes.modelContainer}>
          <CustomFormGrid item xs={11} sm={11} md={11} lg={11}>
            <Typography variant="h6" className={classes.loadFormMainHeadings}>
              {translation("bill.to.title")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item xs={1} sm={1} md={1} lg={1}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomFormGrid>
        </CustomFormGrid>

        <DialogContent sx={{ paddingBottom: "20px",paddingTop:"0px", margin: "0px" }}>
          {pageLoading ? (
            <CustomFormGrid container justifyContent="center" style={{height:"100px"}}>
              <CircularProgress />
            </CustomFormGrid>
          ) : (
            <>
              {billToSummary && (
                <>
                  <CustomFormGrid container  className={classes.fieldModel}>
                    <CustomFormGrid item xs={5.5} sm={5.5} md={5.5} lg={5.5} className={classes.fieldModel}>
                      <ModelInput
                        heading={"ID"}
                        input={billToSummary?.id ? billToSummary?.id : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid item xs={6} sm={6} md={6} lg={6} className={classes.fieldModel}>
                      <ModelInput
                        heading={"Company Name"}
                        input={
                          billToSummary?.customer ? billToSummary?.customer : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid item xs={5.5} sm={5.5} md={5.5} lg={5.5} className={classes.fieldModel}>
                      <ModelInput
                        heading={translation("common.address.label")}
                        input={
                          billToSummary?.address ? billToSummary?.address : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid item xs={6} sm={6} md={6} lg={6} className={classes.fieldModel}>
                      <ModelInput
                        heading={translation("common.zip.label")}
                        input={billToSummary?.zip ? billToSummary?.zip : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid item xs={5.5} sm={5.5} md={5.5} lg={5.5} className={classes.fieldModel}>
                      <ModelInput
                        heading={translation("common.city.label")}
                        input={billToSummary?.city ? billToSummary?.city : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid item xs={6} sm={6} md={6} lg={6} className={classes.fieldModel}>
                      <ModelInput
                        heading={translation("common.state.label")}
                        input={
                          billToSummary?.state ? billToSummary?.state : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid item xs={5.5} sm={5.5} md={5.5} lg={5.5} className={classes.fieldModel}>
                      <ModelInput
                        heading={translation("common.email.label")}
                        input={
                          billToSummary?.email ? billToSummary?.email : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid item xs={6} sm={6} md={6} lg={6} className={classes.fieldModel}>
                      <ModelInput
                        heading={translation("common.phone.label")}
                        input={
                          billToSummary?.contact ? billToSummary?.contact : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid item xs={5.5} sm={5.5} md={5.5} lg={5.5} className={classes.fieldModel}>
                      <ModelInput
                        heading={"Created At"}
                        input={
                          billToSummary?.createdAt
                            ? moment(billToSummary?.createdAt).format(
                                dateFormat
                              )
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid item xs={6} sm={6} md={6} lg={6} className={classes.fieldModel}>
                      <ModelInput
                        heading={"Updated At"}
                        input={
                          billToSummary?.updatedAt
                            ? moment(billToSummary?.updatedAt).format(
                                dateFormat
                              )
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>

                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
