import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import SettingsContainer from "src/containers/settings";
const SettingsPage = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Company" description="Porto Company Information" />
      <SettingsContainer />
    </Grid>
  );
};

export default SettingsPage;
