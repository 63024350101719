import React, { useState } from "react";
import { MenuItem, InputLabel } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import CustomSwitch from "src/components/customElements/customSwitch";
import { selectTerminals } from "src/redux/reducers/terminal";
import { GridAddIcon } from "@mui/x-data-grid";
import { useStyles } from "src/styles/formStyles";
import AddCommonDialog from "src/components/common/addCommonDialog";
import TerminalFormContainer from "src/containers/terminals/form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { TimePicker } from "@mui/lab";
import CustomDateTimePicker from "../customElements/customDatetimePicker";

interface AdditionalInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
}
const AdditionalInfo = ({
  control,
  errors,
  watch,
}: AdditionalInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const terminals = useAppSelector(selectTerminals);
  const classes = useStyles();
  const [openTerminalModal, setOpenTerminalModal] = useState<boolean>(false);
  const handleOpenTerminalModal = () => {
    setOpenTerminalModal(true);
  };
  const handleCloseTerminalModal = () => {
    setOpenTerminalModal(false);
  };
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CustomFormGrid container>
          <CustomFormGrid
            item
            sm={6}
            xs={10}
            md={6}
            lg={12}
            display="flex"
            justifyContent="space-between"
          >
            <CustomFormGrid
              sx={{
                width: "100%",
                padding: "0px",
              }}
            >
              <Controller
                name="terminal"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="terminal"
                    label={translation("customer.form.homeTerminal.label")}
                    error={Boolean(errors?.terminal)}
                    select
                    helperText={
                      errors?.terminal &&
                      translation("customer.form.homeTerminal.message")
                    }
                  >
                    {terminals?.map((item: any, index: any) => (
                      <MenuItem value={item.id} key={index}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid>
              <GridAddIcon
                className={classes.formAddOwnerButton}
                onClick={() => handleOpenTerminalModal()}
              ></GridAddIcon>
            </CustomFormGrid>
          </CustomFormGrid>

          <CustomFormGrid item sm={12}
            xs={12}
            md={12}
            lg={12}>
            <Controller
              name="businessHourStart"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, value } }) => (
                // <TimePicker
                //   {...field}
                //   label={translation("customer.form.businessHour.start")}
                //   renderInput={(params: any) => (
                //     <CustomTextField
                //       {...params}
                //       {...field}
                //       size="small"
                //       id="businessHourStart"
                //       fullWidth
                //       error={Boolean(errors?.businessHourStart)}
                //       helperText={
                //         errors?.businessHourStart &&
                //         translation("customer.form.businessHour.start.message")
                //       }
                //     />
                //   )}
                // />
                <CustomDateTimePicker
                  timeLabel={translation("customer.form.businessHour.start")}
                  dateInput={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12}
            xs={12}
            md={12}
            lg={12}>
            <Controller
              name="businessHourEnd"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, value } }) => (
                // <TimePicker
                //   {...field}
                //   label={translation("customer.form.businessHour.end")}
                //   // disablePast
                //   renderInput={(params: any) => (
                //     <CustomTextField
                //       {...params}
                //       {...field}
                //       size="small"
                //       id="businessHourEnd"
                //       fullWidth
                //       error={Boolean(errors?.businessHourEnd)}
                //       helperText={
                //         errors?.businessHourEnd &&
                //         translation("customer.form.businessHour.end.message")
                //       }
                //     />
                //   )}
                // />

                <CustomDateTimePicker
                  timeLabel={translation("customer.form.businessHour.end")}
                  dateInput={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={4}
            xs={12}
            md={4}
            lg={4}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Controller
              name="factorPayments"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <InputLabel>
                    {translation("customer.form.factorPayments.label")}
                  </InputLabel>
                  <CustomSwitch
                    checked={
                      watch("factorPayments") ? watch("factorPayments") : false
                    }
                    {...field}
                    id="factorPayments"
                  />
                </>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={4}
            xs={12}
            md={4}
            lg={4}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Controller
              name="combineBilling"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <InputLabel>
                    {translation("customer.form.combineBilling.label")}
                  </InputLabel>
                  <CustomSwitch
                    checked={
                      watch("combineBilling") ? watch("combineBilling") : false
                    }
                    {...field}
                    id="combineBilling"
                  />
                </>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={4}
            xs={12}
            md={4}
            lg={4}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Controller
              name="specialtyBilling"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <InputLabel>
                    {translation("customer.form.specialtyBilling.label")}
                  </InputLabel>
                  <CustomSwitch
                    checked={
                      watch("specialtyBilling")
                        ? watch("specialtyBilling")
                        : false
                    }
                    {...field}
                    id="specialtyBilling"
                  />
                </>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Controller
              name="dispatchNote"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  id="dispatchNote"
                  label={translation("customer.form.dispatchNote.label")}
                  error={Boolean(errors?.dispatchNote)}
                  helperText={
                    errors?.dispatchNote &&
                    translation("customer.form.dispatchNote.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>
        <AddCommonDialog
          handleClose={handleCloseTerminalModal}
          open={openTerminalModal}
          title="Add Termianl"
          ComponentToRender={
            <TerminalFormContainer handleClose={handleCloseTerminalModal} />
          }
        />
      </LocalizationProvider>
    </div>
  );
};

export default AdditionalInfo;
