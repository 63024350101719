import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import UserContainer from "src/containers/users";
const Users = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Users" description="Porto Users" />
      <UserContainer />
    </Grid>
  );
};

export default Users;
