import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { useStyles } from "src/styles/formStyles";

interface MobileCheckProps {
  grid1: any;
  grid2: any;
}

const MobileCheck = ({ grid1, grid2 }: MobileCheckProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {isMobile ? (
        <>
          <CustomFormGrid container>{grid1}</CustomFormGrid>
          <CustomFormGrid container>{grid2}</CustomFormGrid>
        </>
      ) : (
        <>
          <CustomFormGrid
            container
            justify="center"
            className={classes.mobileCheckAppBar}
          >
            <Paper> {grid1}</Paper>
          </CustomFormGrid>
          <CustomFormGrid container className={classes.mobileCheckComponent}>
            {grid2}
          </CustomFormGrid>
        </>
      )}
    </>
  );
};

export default MobileCheck;
