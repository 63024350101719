import React, { useEffect, useState } from "react";
import DataTable from "./dataTable";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectDocuments } from "src/redux/reducers/documents";
import { useTranslation } from "react-i18next";
import { getAllDocumentTypes } from "src/redux/actions/documentType";
import Modal from "src/components/common/documentModal";
import {
  getAllDocuments,
  uploadDocument,
  downloadDocument,
  viewDocument,
  verifyDocument,
  deleteDocument,
} from "src/redux/actions/documents";
import { useParams } from "react-router-dom";
import { selectUploadProgress } from "src/redux/reducers/driver";
import { selectUser } from "src/redux/reducers/auth";
import moment from "moment";
import { dateFormat, dateTimeFormat2 } from "src/utils/constants";
import StatusBadge from "src/components/common/StatusBadge";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import { DocumentProps } from "src/interfaces/driver";
import FlakyIcon from "@mui/icons-material/Flaky";
import VerifyDocument from "src/components/common/verifyDocument";
import ConfirmationDialog from "src/components/common/confirmation";
import { VerifyDocumentProps } from "src/interfaces/driver";
import { pending, admin } from "src/utils/enums";
import { useQuery } from "src/hooks/useQuery";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
// import { getUserById } from "src/redux/actions/users";
export default function UploadDocuments() {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const classes = useStyles();
  const params = useParams();

  const user = useAppSelector(selectUser);
  const entity = query.get("entity") ? query.get("entity") : user?.role?.name;
  const referenceId = query.get("referenceId")
    ? query.get("referenceId")
    : `${user?.driver?.id}`;
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const documents = useAppSelector(selectDocuments);
  const progress = useAppSelector(selectUploadProgress);
  const [documentData, setDocumentData] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const [openDocumentModal, setOpenDocumentModal] = useState<boolean>(false);
  const [openVerification, setOpenVerification] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedDoc, setSelectedDoc] = useState<DocumentProps | null>(null);

  const handleOpenDocumentModal = () => {
    setOpenDocumentModal(true);
  };
  const handleCloseDocumentModal = () => {
    setOpenDocumentModal(false);
  };

  const handleOpenVerification = () => {
    setOpenVerification(true);
  };
  const handleCloseVerificationModal = () => {
    setOpenVerification(false);
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    dispatch(getAllDocumentTypes(50, 1));
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    if (params?.loadId) {
      getAllDocument(parseInt(params?.loadId));
      // getUser(parseInt(referenceId));
    }
  }, [params?.loadId, rowsState.pageSize, rowsState.page]);

  const getAllDocument = async (id: number) => {
    if (params?.loadId) {
      setPageLoading(true);
      await dispatch(
        getAllDocuments(
          parseInt(params?.loadId),
          "load",
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1
        )
      );
      setPageLoading(false);
    }
  };

  /*  function pageExists(page: any) {
    return documentData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  }
 */
  useEffect(() => {
    //   if (pageExists(rowsState?.page + 1) == false)
    if (documents?.data?.length > 0) {
      setDocumentData({ ...documentData?.documents, documents });
    } else {
      setDocumentData({});
    }
  }, [documents]);

  const handleDocumentUpload = async (formData: FormData) => {
    setLoading(true);
    const response = await dispatch(uploadDocument(formData));
    setLoading(false);
    return response;
  };
  const handleDownloadFile = (document: DocumentProps) => {
    if (document.path) {
      if (document.id && document.name) {
        dispatch(downloadDocument(document.id, document.name));
      }
    }
  };
  const handleViewFile = (document: DocumentProps) => {
    if (document.path) {
      if (document.id && document.name) {
        dispatch(viewDocument(document.id, document.name));
      }
    }
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      minWidth: 150,
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={<DescriptionIcon />}
        //   label="open"
        //   key="open"
        //   onClick={() => {
        //     handleViewFile(params.row);
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<FileDownloadIcon />}
        //   label="download"
        //   key="download"
        //   onClick={() => {
        //     handleDownloadFile(params.row);
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   label="delete"
        //   key="delete"
        //   onClick={() => {
        //     handleDocumentDelete(params.row);
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<FlakyIcon />}
        //   label="verify"
        //   key="verify"
        //   disabled={
        //     user?.role?.name?.toLocaleLowerCase() !== admin ||
        //     params.row.status !== pending
        //   }
        //   onClick={() => {
        //     handleDocumentVerification(params.row);
        //   }}
        // />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: translation("common.name.label"),
      minWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.name ? params.row.name : ""}</div>;
      },
    },
    {
      field: "documentName",
      headerName: "Document Name",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.documentDefinition?.name
              ? params.row?.documentDefinition?.name
              : ""}
          </div>
        );
      },
    },
    {
      field: "expiry",
      headerName: translation("document.expiry.label"),
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.expiry
              ? moment(params.row.expiry).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "status",
      headerAlign: "center",
      align: "center",
      headerName: translation("common.status.label"),
      minWidth: 100,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "comment",
      headerName: translation("driverVehicle.form.comments.label"),
      minWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.comment ? params?.row?.comment : ""}</div>;
      },
    },
    {
      field: "uploadDate",
      headerName: translation("document.upload.date"),
      minWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.createdAt
              ? moment(params?.row?.createdAt).format(dateTimeFormat2)
              : ""}
          </div>
        );
      },
    },
  ];

  const handleDocumentVerification = (document: DocumentProps) => {
    setSelectedDoc(document);
    handleOpenVerification();
  };
  const handleDocumentDelete = (document: DocumentProps) => {
    setSelectedDoc(document);
    handleOpenDeleteModal();
  };
  const handleVerifyDocument = async (data: VerifyDocumentProps) => {
    setLoading(true);
    if (selectedDoc?.id) {
      await dispatch(verifyDocument(selectedDoc.id, data));
    }
    setSelectedDoc(null);
    handleCloseVerificationModal();
    setLoading(false);
  };
  const handleConfirmDelete = async () => {
    if (selectedDoc?.id) {
      setLoading(true);
      await dispatch(deleteDocument(selectedDoc?.id)).then((result: any) => {
        if (params?.loadId && result) {
          getAllDocument(parseInt(params?.loadId));
          // getUser(parseInt(referenceId));
        }
      });
      handleCloseDeleteModal();
      setSelectedDoc(null);
      setLoading(false);
    }
  };
  return (
    <>
      <DataTable
        title="Documents"
        rows={documentData?.documents?.data}
        columns={columns}
        loading={pageLoading}
        onClickAdd={handleOpenDocumentModal}
        createPermission={true}
        rowsState={rowsState}
        //  onRowClick={onRowClick}
        setRowsState={setRowsState}
        pagination={documents.pagination}
        data={documentData?.documents}
        setData={setDocumentData}
        add={true}
      />
      {params?.loadId && openDocumentModal && (
        <Modal
          handleClose={handleCloseDocumentModal}
          open={openDocumentModal}
          handleDocumentUpload={handleDocumentUpload}
          title={translation("documents.form.title")}
          progress={progress}
          feature={"load"}
          referenceId={params?.loadId}
          getAllDocument={getAllDocument}
        />
      )}
      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("documents.delete.message", {
            name: selectedDoc?.name,
          })}
          title={translation("documents.delete.title")}
        />
      )}
      {openVerification && (
        <VerifyDocument
          handleClose={handleCloseVerificationModal}
          open={openVerification}
          handleVerifyDocument={handleVerifyDocument}
          title={translation("documents.verification.title")}
          loading={loading}
        />
      )}
    </>
  );
}
