import React from "react";
import { Chip } from "@mui/material";

interface BadgeProps {
  label: string;
}

const RoleBadge = ({ label }: BadgeProps): JSX.Element => {
  const getColor = (label: string): string => {
    if (label.toLocaleLowerCase() === "user") {
      return "warning.main";
    } else if (label.toLocaleLowerCase() === "admin") {
      return "primary.main";
    }
    return "error.main";
  };
  return (
    <Chip
      sx={{
        padding: "4px",
        margin: " 0px 4px",

        backgroundColor: getColor(label),
        color: "#fff",
        textTransform: "capitalize",
      }}
      size="small"
      label={label}
    ></Chip>
  );
};

export default RoleBadge;
