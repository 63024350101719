import { AppDispatch } from "../store";
import {
  setAllCustomersBillings,
  setSingleCustomerBilling,
} from "../reducers/customerBilling";
import { CustomerBillingProps } from "src/interfaces/customer";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllCustomersBillings = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/customer-billing`));
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data)) {
      dispatch(setAllCustomersBillings(response?.data?.data));
    }
  }

  return { success: response.success, data: response?.data?.data };
};
export const getCustomerBillingById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/customer-billing/${id}`)
    );
    if (response?.data?.data) {
      dispatch(setSingleCustomerBilling(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const addCustomerBilling =
  (data: CustomerBillingProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/customer-billing`, data));

    if (response?.data?.data) {
      dispatch(setSingleCustomerBilling(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateCustomerBilling =
  (data: CustomerBillingProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/customer-billing/${id}`, data)
    );
    if (response?.data?.data) {
      dispatch(setSingleCustomerBilling(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
