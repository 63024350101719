import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import DocumentTypeContainer from "src/containers/documentTypes";
const DocumentTypes = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Document Types" description="Porto Document Types" />
      <DocumentTypeContainer />
    </Grid>
  );
};

export default DocumentTypes;
