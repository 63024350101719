import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import TerminalForm from "src/components/terminals/form";
import TerminalReadForm from "src/components/terminals/readForm";
import { TerminalProps } from "src/interfaces/terminal";
import {
  getTerminalById,
  addTerminal,
  updateTerminal,
} from "src/redux/actions/terminal";
import { resetTerminal } from "src/redux/reducers/terminal";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { sanitizeEntity } from "src/utils/common";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { featureTerminals } from "src/utils/enums";

interface TerminalFormProps {
  handleClose?: () => any;
}
const TerminalFormContainer = ({
  handleClose,
}: TerminalFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const url = location.pathname.includes("terminals");

  const getLookupsList = async () => {
    setLoading(true);
    await dispatch(getAllLookupsByCategory(featureTerminals, 0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getLookupsList();
  }, []);

  useEffect(() => {
    dispatch(resetTerminal());
  }, []);

  useEffect(() => {
    if (params?.terminalId) {
      getSingleTerminal(parseInt(params?.terminalId));
    }
  }, [params?.terminalId]);
  const getSingleTerminal = async (id: number) => {
    setPageLoading(true);
    await dispatch(getTerminalById(id));
    setPageLoading(false);
  };
  /* TerminalProps */
  const onSubmit = async (data: any) => {
    setLoading(true);

    let objToSubmit: TerminalProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.terminalId) {
      //update
      await dispatch(updateTerminal(objToSubmit, parseInt(params?.terminalId)));
    } else {
      //add

      const terminal = await dispatch(addTerminal(objToSubmit));

      if (url && terminal.success && terminal.data.id) {
        setTimeout(
          //  () => navigate(`/terminals/terminals/${terminal.data.id}`),
          () => navigate("/terminals/terminals"),
          500
        );
      } else if (terminal.success && terminal.data.id && handleClose) {
        handleClose();
      }
    }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <TerminalReadForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
          // handleClose={handleClose}
          // url={url}
        />
      </Grid>
    </Grid>
  );
};

export default TerminalFormContainer;
