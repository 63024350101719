import { AppDispatch } from "../store";
import { setAllCommodities, setSingleCommodity } from "../reducers/commodity";
import { CommodityProps } from "src/interfaces/commodity";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllCommodities = (limit?: number, page?: number, filter?:any) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/commodity`) as any);
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data)) {
      dispatch(setAllCommodities(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};
export const getCommodityById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(getServerData(`/commodity/${id}`) as any);
    if (response?.data?.data) {
      dispatch(setSingleCommodity(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const addCommodity =
  (data: CommodityProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/commodity`, data) as any);

    if (response?.data?.data) {
      dispatch(setSingleCommodity(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateCommodity =
  (data: CommodityProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/commodity/${id}`, data) as any);
    if (response?.data?.data?.commodity) {
      dispatch(setSingleCommodity(response?.data?.data?.commodity));
    }
    return { success: response.success, data: response?.data?.data?.commodity };
  };
