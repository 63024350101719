import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Grid, CircularProgress, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { emailRegEx } from "src/utils/constants";
import { Link } from "react-router-dom";
import logo from "src/assets/img/tms_logo.png";

interface SignupProps {
  loading: boolean;
  /* onSubmit: (data: SignupCredProps) => any; */
  onSubmit: (data: any) => any;
}
const Signup = ({ loading, onSubmit }: SignupProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // useEffect(() => {
  //   const temp = watch("tenantName");
  //   setValue("url", temp.replace(/\s+/g, "-"));
  // }, [watch("tenantName")]);

  return (

    <div className="auth-container">
      <div className="auth-align-middle auth-main">
        <div className="auth-box">
          <div className="top">
            <img src={logo} alt="" />
          </div>
          <Card sx={{ borderRadius: "5px", m: 0 }}>
            <CardContent>
              <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                Create an account
              </Typography>
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      size="small"
                      id="firstName"
                      label={translation("signup.fName.label")}
                      error={Boolean(errors?.firstName)}
                      helperText={
                        errors?.firstName && translation("signup.fName.message")
                      }
                      required
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      id="lastName"
                      label={translation("signup.lName.label")}
                      error={Boolean(errors?.lastName)}
                      helperText={
                        errors?.lastName && translation("signup.lName.message")
                      }
                      required
                    />
                  )}
                />
                <Controller
                  name="tenantName"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      id="tenantName"
                      label={translation("signup.company.label")}
                      error={Boolean(errors?.tenantName)}
                      helperText={
                        errors?.tenantName && translation("signup.company.message")
                      }
                      required
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true, pattern: emailRegEx }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      id="email"
                      label={translation("signup.email.label")}
                      error={Boolean(errors?.email)}
                      helperText={
                        errors?.email && translation("signup.email.message")
                      }
                      required
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      id="password"
                      label={translation("signup.password.label")}
                      type={showPassword ? "text" : "password"}
                      error={Boolean(errors?.password)}
                      helperText={
                        errors?.password && translation("signup.password.message")
                      }
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  id="signup"
                  sx={{ mt: 1 }}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : (
                    translation("signup.submit")
                  )}
                </Button>

              </form>

              <Grid
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", mt: 2 }}
              >
                <Grid item>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Already have an account?&nbsp;
                    <Link to="/auth/login">Login</Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>

  );
};
export default Signup;
