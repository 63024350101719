import React from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailRegEx } from "src/utils/constants";
import { useStyles } from "src/styles/formStyles";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
interface ContactInfoProps {
  control: any;
  errors: { [key: string]: any };
  setValue: any;
  watch: any;
}
const ContactInfo = ({
  control,
  errors,
  watch,
  setValue,
}: ContactInfoProps): JSX.Element => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="email"
            control={control}
            defaultValue={""}
            rules={{ pattern: emailRegEx }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="email"
                label={translation("common.email.label")}
                error={Boolean(errors?.email)}
                helperText={
                  errors?.email && translation("common.email.message")
                }
                //required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="website"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="website"
                label={translation("customer.form.website.label")}
                error={Boolean(errors?.website)}
                helperText={
                  errors?.website &&
                  translation("customer.form.website.message")
                }
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12} xs={12} md={12} lg={12}
          className={classes.phoneField}
        >
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: {
                isValid: () =>
                  watch("phoneNumberValue")
                    ? phone(watch("phoneNumberValue"), {
                        country: watch("phoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.phone.label")}
                placeholder={translation("common.phone.label")}
                value={watch("phoneNumber")}
                error={Boolean(errors["phoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "phoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["phoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12} xs={12} md={12} lg={12}
          className={classes.phoneField}
        >
          <Controller
            name="tollFreePhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              // required: true,
              validate: {
                isValid: () =>
                  watch("tollFreePhoneNumberValue")
                    ? phone(watch("tollFreePhoneNumberValue"), {
                        country: watch("tollFreePhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("customer.form.tollFreePhoneNumber.label")}
                placeholder={translation(
                  "customer.form.tollFreePhoneNumber.label"
                )}
                country="us"
                value={watch("tollFreePhoneNumber")}
                error={Boolean(errors["tollFreePhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "tollFreePhoneNumber"
                  );
                }}
                isValid={(value: any, country: any) => {
                  return errors["tollFreePhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12} xs={12} md={12} lg={12}
          className={classes.phoneField}
        >
          <Controller
            name="faxPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("faxPhoneNumberValue")
                    ? phone(watch("faxPhoneNumberValue"), {
                        country: watch("faxPhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.faxPhoneNumber.label")}
                placeholder={translation("common.faxPhoneNumber.label")}
                value={watch("faxPhoneNumber")}
                error={Boolean(errors["faxPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "faxPhoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["faxPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default ContactInfo;
