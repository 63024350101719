import React from "react";
import LoginContainer from "src/containers/login";
import Head from "src/components/head";

const Login = (): JSX.Element => {
  return (
    <>
      <Head title="Login" description="Login to Porto" />
      <LoginContainer />
    </>
  );
};

export default Login;
