import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import Head from "src/components/head";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import {
  addSettlementGeneralInfo,
  getAllAccountsWithId,
} from "src/redux/actions/settlement";
import { selectAllAccountsWithId } from "src/redux/reducers/settlement";
import { useTranslation } from "react-i18next";
import { featureLineItems, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { Card, CardContent, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomTextField from "src/components/customElements/customTextField";
//import CustomSwitch from "../customElements/customSwitch";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm } from "react-hook-form";
import { InputLabel } from "@mui/material";
import CustomSwitch from "src/components/customElements/customSwitch";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import { GeneralInfoProps } from "src/interfaces/generalInfo";
import Truncate from "react-truncate";
import { selectSingleSettlementInfo } from "src/redux/reducers/settlement";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const Settlements = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const settlementGeneralInfo = useAppSelector(selectSingleSettlementInfo);
  const settlements = useAppSelector(selectAllAccountsWithId);
  const [settlementsData, setSettlementsData] = useState<any>([]);
  const [translation] = useTranslation("translation");
  const [selectedRows, setSelectedRows] = React.useState<any>();
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const user = useAppSelector(selectUser);

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const settlementsPermission =
    allUserPermissions && allUserPermissions[featureLineItems];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementsPermission.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementsPermission.findIndex((item) => item === update);

  const getAllAccountsList = async (id: number) => {
    setLoading(true);
    await dispatch(getAllAccountsWithId(id));
    setLoading(false);
  };

  useEffect(() => {
    if (params.id) {
      getAllAccountsList(parseInt(params?.id));
    }
  }, []);

  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (settlements?.data?.length > 0) {
      setSettlementsData([...settlementsData, settlements]);
    } else {
      setSettlementsData({});
    }
  }, [settlements]);
  // const isFound = );
  useEffect(() => {
    let _total = 0;
    selectedRows?.map((item: any) => {
      settlements?.some((element: any) => {
        if (element.id == item) {
          _total = _total + element.rate;
        }
      });
    });
    setValue("total", _total);
  }, [selectedRows]);

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/settlements/earning-deduction/${params.row.id}`);
          }}
        />,
      ],
    },

    {
      field: "id",
      headerName: translation("common.id.label"),
      headerAlign: "center",
      align: "center",
      minWidth: 50,
    },
    {
      field: "createdAt",
      headerName: translation("settlement.addEarningForm.dateCreatedAt.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.createdAt
              ? moment(params.row.createdAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "load",
      headerName: translation("settlement.load.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.load?.id ? params?.row?.load?.id : ""}</div>;
      },
    },
    {
      field: "settlementCode",
      headerName: translation("settlementCode.list.title"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.settlementCode?.code
              ? params?.row?.settlementCode?.code
              : ""}
          </div>
        );
      },
    },
    {
      field: "internalComment",
      headerName: translation("settlementCode.internal.remarks"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {params?.row?.internalComment}
            </Truncate>
          </div>
        );
      },
    },
    {
      field: "rate",
      headerName: translation("load.form.rate.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "enteredDate",
      headerName: translation("settlement.addEarningForm.date.label"),
      minWidth: 200,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.enteredDate
              ? moment(params.row.enteredDate).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onSubmit = async (data: any) => {
    setLoading(true);
    const objToSubmit: GeneralInfoProps = { ...data };
    objToSubmit.total = objToSubmit.total
      ? objToSubmit.total
      : objToSubmit.total;
    objToSubmit.date = objToSubmit.date ? objToSubmit.date : null;

    objToSubmit.externalComment = objToSubmit.externalComment
      ? objToSubmit.externalComment
      : "";
    objToSubmit.internalComment = objToSubmit.internalComment
      ? objToSubmit.internalComment
      : "";
    objToSubmit.settlement = selectedRows?.length ? selectedRows : [];

    //add
    const info = await dispatch(addSettlementGeneralInfo(objToSubmit));
    if (info.success && info.data.id) {
      navigate(-1);
    }

    setLoading(false);
  };
  return (
    <>
      <Grid item xs={12} lg={12} sm={12}>
        <Head title="Settlements" description="Porto Settlements" />

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography
              variant="h3"
              sx={{
                marginBottom: "0",
                textTransform: "capitalize",
              }}
              gutterBottom
            >
              Pending Settlement Detail
            </Typography>
          </Box>
        </Box>

        <Card
          variant="outlined"
          sx={{
            paddingBottom: "50px",
          }}
        >
          {loading ? (
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "60%" }}
            />
          ) : (
            <>
              <Box
                sx={{
                  display: {
                    sm: "flex",
                    xs: "block",
                  },
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, .08)",

                  backgroundColor: "rgba(0, 0, 0, .03)",
                  borderRadius: 1,
                  padding: "10px",
                }}
              >
                <Typography variant="h3">Line Items</Typography>
              </Box>

              <CustomFormGrid
                item
                lg={12}
                xs={12}
                // style={{ height: "500px" }}
                justifyContent="center"
              >
                <DataTable
                  title={""}
                  rows={settlements}
                  columns={columns}
                  loading={loading}
                  //  onClickAdd={addLineItems}
                  createPermission={createPermission > -1}
                  //  onRowClick={onRowClick}
                  rowsState={rowsState}
                  setRowsState={setRowsState}
                  //  pagination={settlements.pagination}
                  data={settlementsData}
                  setData={setSettlementsData}
                  billing={true}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  disableBackButton={true}
                />
              </CustomFormGrid>

              <Box
                sx={{
                  display: {
                    sm: "flex",
                    xs: "block",
                  },
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, .08)",

                  backgroundColor: "rgba(0, 0, 0, .03)",
                  borderRadius: 1,
                  padding: "10px",
                }}
              >
                <Typography variant="h3">Per Settlement Entries</Typography>
              </Box>

                <CustomFormGrid item xs= {12} sm={12} md={12} lg={12}>
                <Typography style={{paddingTop:"10px", paddingBottom:"10px"}}>
                  There are no recurring earnings or deductions for this account
                </Typography>

                </CustomFormGrid>


                <Box
                sx={{
                  display: {
                    sm: "flex",
                    xs: "block",
                  },
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, .08)",

                  backgroundColor: "rgba(0, 0, 0, .03)",
                  borderRadius: 1,
                  padding: "10px",
                }}
              >
                <Typography variant="h3">General Information</Typography>
                </Box>
                <CustomFormGrid item xs= {12} sm={12} md={12} lg={12}>
                <CustomForm
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CustomFormGrid container>
                    <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
                      <Controller
                        name="total"
                        control={control}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field }) => (
                          <CustomTextField
                            {...field}
                            size="small"
                            fullWidth
                            id="total"
                            label={translation("settlement.form.total.label")}
                            error={Boolean(errors?.code)}
                            helperText={
                              errors?.code &&
                              translation("settlement.form.total.label")
                            }
                            required
                          />
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
                      <Controller
                        name="date"
                        control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            label={translation("settlement.form.date.label")}
                            disablePast
                            value={
                              settlementGeneralInfo &&
                              settlementGeneralInfo.date
                                ? settlementGeneralInfo.date
                                : new Date()
                            }
                            inputFormat="MM/dd/yyyy"
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                {...field}
                                size="small"
                                id="expiry"
                                fullWidth
                                error={Boolean(errors?.date)}
                                helperText={
                                  errors?.date &&
                                  translation("settlement.form.date.label")
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="externalComment"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <CustomTextField
                            {...field}
                            size="small"
                            fullWidth
                            multiline
                            rows={3}
                            id="externalComment"
                            label={translation("settlement.form.comment.label")}
                            error={Boolean(errors?.description)}
                            helperText={
                              errors?.description &&
                              translation("lineItem.form.description.message")
                            }
                          />
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="internalComment"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <CustomTextField
                            {...field}
                            size="small"
                            fullWidth
                            id="internalComment"
                            multiline
                            rows={3}
                            label={translation(
                              "settlement.form.commentInternal.label"
                            )}
                            error={Boolean(errors?.notes)}
                            helperText={
                              errors?.notes &&
                              translation("lineItem.form.lineItemNotes.message")
                            }
                          />
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid
                      item
                      sm={6}
                      xs={12}
                      md={4}
                      lg={4}
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Controller
                        name="sendSummary"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <>
                            <InputLabel>
                              {translation("settlement.form.send.label")}
                            </InputLabel>
                            <CustomSwitch
                              checked={
                                watch("sendSummary")
                                  ? watch("sendSummary")
                                  : false
                              }
                              {...field}
                              id="readyToBill "
                            />
                          </>
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid container justifyContent="center">
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.formButton}
                      >
                        {loading ? (
                          <CircularProgress
                            style={{
                              color: "#fff",
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        ) : (
                          translation("common.form.approve")
                        )}
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        className={classes.formButton}
                        onClick={() => navigate(-1)}
                      >
                        {translation("common.btn.cancel")}
                      </Button>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </LocalizationProvider>
              </CustomForm>

                </CustomFormGrid>

            </>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default Settlements;
