import { AppDispatch } from "../store";
import { setAllLineItems, setSingleLineItem } from "../reducers/lineItems";
import { LineItemProps } from "src/interfaces/lineItems";
import { getServerData, postServerData, patchServerData } from "../api/index";


export const getAllLineItems =
  (limit?: number, page?: number, filter?: any) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/line-item`) as any
    );

    console.log(response?.data?.data);

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data)) {
        dispatch(setAllLineItems(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getLineItemById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(getServerData(`/line-item/${id}`) as any);
    if (response?.data?.data) {
      dispatch(setSingleLineItem(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const addLineItem =
  (data: LineItemProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/line-item`, data) as any);

    if (response?.data?.data) {
      dispatch(setSingleLineItem(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateLineItem =
  (data: LineItemProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/line-item/${id}`, data) as any);
    if (response?.data?.data?.lineItem) {
      dispatch(setSingleLineItem(response?.data?.data?.lineItem));
    }
    return { success: response.success, data: response?.data?.data };
  };
