import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import AssignedTrailerInfo from "src/containers/vehicle/vehicleTrailerLinking";
import AssignedDriverInfo from "src/containers/vehicle/driverLinking";
import VehicleOwnerInfo from "src/containers/vehicle/vehicleOwnerLinking";

import { useParams } from "react-router-dom";
import { selectDriver } from "src/redux/reducers/driver";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import VehicleDocuments from "src/containers/documents";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import { selectVehicle } from "src/redux/reducers/vehicle";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../common/readForms/formHeader";
import FromInput from "../common/readForms/formInput";
import FromInput2 from "../common/readForms/formInput2InLine";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
// import Documents from "./docements";
import { driver as driverEnum } from "src/utils/enums";
import { LoadStopProps } from "src/interfaces/loadManagement";
import TerminalToInfoModel from "src/components/common/InformationModels/terminalToInfoModal";
import OwnerInfoModel from "src/components/common/InformationModels/ownerInfoModel";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";

interface VehicleSummaryProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}

const VehicleSummary = ({
  loading,
  pageLoading,
  onSubmit,
}: VehicleSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const [loadStops, setLoadStops] = useState<Array<LoadStopProps>>([
    // { ...defaultLoadStopObject },
  ]);

  const user = useAppSelector(selectUser);
  // const driver = useAppSelector(selectLoadManagement);
  const vehicle = useAppSelector(selectVehicle);
  const driver = useAppSelector(selectDriver);
  const [selectedData, setSelectedData] = useState<any>();
  const [openTerminalModel, setOpenTerminalModel] = useState<boolean>(false);
  const [openOwnerModel, setOpenOwnerModel] = useState<boolean>(false);

  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const handleOpenTerminalModel = (terminalId: any) => {
    setOpenTerminalModel(true);
    setSelectedData(terminalId);
  };

  const handleCloseTerminalModel = () => {
    setOpenTerminalModel(false);
    setSelectedData(null);
  };
  const handleOpenOwnerModel = (ownerId: any) => {
    setOpenOwnerModel(true);
    setSelectedData(ownerId);
  };

  const handleCloseOwnerModel = () => {
    setOpenOwnerModel(false);
    setSelectedData(null);
  };

  const handleSetMilageValue = async (value: any) => {
    // setValue("freightBill.mileage", value);
  };

  // const loadStopCancelled = vehicle?.loadStops?.every(
  //   (item) => item.dispatchStatus === "cancelled"
  // );
  // const loadStopPlanned = vehicle?.loadStops?.every(
  //   (item) => item.dispatchStatus === "planned"
  // );

  // useEffect(() => {
  //   if (
  //     vehicle != null &&
  //     Object.keys(vehicle).length > 0 &&
  //     vehicle?.loadStops.length > 0
  //   ) {
  //     setLoadStops([...vehicle?.loadStops]);
  //   }
  // }, []);

  return (
    <>
      <Box>
        {pageLoading ? (
          <CustomFormGrid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CustomFormGrid>
              <CircularProgress sx={{ height: "20", width: "20px" }} />
            </CustomFormGrid>
          </CustomFormGrid>
        ) : (
          <>
            <MobileCheck
              grid1={
                <CustomFormGrid container>
                  <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                    <Box className={classes.loadFormTitleContainer}>
                      <Box flexGrow={1} display="flex" alignItems="center">
                        <Button>
                          <ArrowBack onClick={() => navigate(-1)} />
                        </Button>
                        <Typography
                          variant="h6"
                          className={classes.loadFormMainHeadings}
                        >
                          {translation("vehicle.read.form.title")}
                        </Typography>
                      </Box>
                    </Box>
                  </CustomFormGrid>

                  <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                    <CustomFormGrid
                      container
                      className={classes.buttonContainer}
                      justifyContent="flex-end"
                    >
                      <Button
                        color="error"
                        variant="contained"
                        className={classes.formButtonEdit}
                        onClick={() =>
                          navigate(`/vehicle/vehicles/${params?.vehicleId}`)
                        }
                      >
                        EDIT INFORMATION
                      </Button>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              }
              grid2={
                <CustomFormGrid container>
                  <CustomFormGrid items lg={6} md={6} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation("vehicle.information.form.heading")}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.vehicleID.label"
                              )}
                              input={vehicle?.vehicleID}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.form.status.label")}
                              input={vehicle?.status}
                              checkColor={false}
                            />
                          </CustomFormGrid>

                          <Divider variant="middle" />
                          <CustomFormGrid
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleOpenTerminalModel(vehicle?.terminal?.id)
                            }
                          >
                            <FromInput
                              heading={translation(
                                "vehicle.form.terminal.label"
                              )}
                              input={
                                vehicle?.terminal != null
                                  ? vehicle?.terminal?.title
                                  : "--"
                              }
                              checkColor={true}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleOpenOwnerModel(vehicle?.owner?.id)
                            }
                          >
                            <FromInput
                              heading={translation("vehicle.list.owner.label")}
                              input={
                                vehicle?.owner?.firstName
                                  ? vehicle?.owner?.firstName
                                  : vehicle?.owner?.firstName
                              }
                              checkColor={true}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.list.ownerSince.label"
                              )}
                              input={
                                vehicle?.ownerSince != null
                                  ? moment(vehicle?.ownerSince).format(
                                      dateFormat
                                    )
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.form.year.label")}
                              input={
                                vehicle?.year != null
                                  ? moment(vehicle?.year).format(dateFormat)
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.form.make.label")}
                              input={
                                vehicle?.make != null
                                  ? vehicle?.make?.content
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.list.model.label")}
                              input={vehicle?.model ? vehicle?.model : "--"}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.list.type.label")}
                              input={
                                vehicle?.type != null
                                  ? vehicle?.type?.content
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.list.VIN.label")}
                              input={vehicle?.VIN ? vehicle?.VIN : "--"}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.form.color.label")}
                              input={
                                vehicle?.color != null
                                  ? vehicle?.color?.content
                                  : ""
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.fuelCapacity.label"
                              )}
                              input={
                                vehicle?.fuelCapacity
                                  ? vehicle?.fuelCapacity
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.tagNumber.label"
                              )}
                              input={
                                vehicle?.tagNumber ? vehicle?.tagNumber : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.tagState.label"
                              )}
                              input={
                                vehicle?.tagState ? vehicle?.tagState : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.tagExp.message"
                              )}
                              input={
                                vehicle?.tagExpiration != null
                                  ? moment(vehicle?.tagExpiration).format(
                                      dateFormat
                                    )
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.insuranceCarrier.label"
                              )}
                              input={
                                vehicle?.insuranceCarrier
                                  ? vehicle?.insuranceCarrier
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.insuranceExpiration.label"
                              )}
                              input={
                                vehicle?.insuranceExpiration != null
                                  ? moment(vehicle?.insuranceExpiration).format(
                                      dateFormat
                                    )
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.hasTrailer.label"
                              )}
                              input={
                                vehicle?.hasTrailer === true ? "Yes" : "No"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.teamVehicle.label"
                              )}
                              input={
                                vehicle?.teamVehicle === true ? "Yes" : "No"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <CustomFormGrid items lg={6} md={6} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation(
                          "vehicle.form.safetyInformation.label"
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.monthlyMaintenance.label"
                              )}
                              input={
                                vehicle?.vehicleSafety?.monthlyMaintenance !=
                                null
                                  ? moment(
                                      vehicle?.vehicleSafety?.monthlyMaintenance
                                    ).format(dateFormat)
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.annualInspectionLocation.label"
                              )}
                              input={
                                vehicle?.vehicleSafety != null
                                  ? vehicle?.vehicleSafety
                                      ?.annualInspectionLocation
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>

                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.annualInspectionDate.label"
                              )}
                              input={
                                vehicle?.vehicleSafety?.annualInspectionDate !=
                                null
                                  ? moment(
                                      vehicle?.vehicleSafety
                                        ?.annualInspectionDate
                                    ).format(dateFormat)
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.dotDate.label"
                              )}
                              input={
                                vehicle?.vehicleSafety?.dot_Date != null
                                  ? moment(
                                      vehicle?.vehicleSafety?.dot_Date
                                    ).format(dateFormat)
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.lastServiceDate.label"
                              )}
                              input={
                                vehicle?.vehicleSafety?.lastServiceDate != null
                                  ? moment(
                                      vehicle?.vehicleSafety?.lastServiceDate
                                    ).format(dateFormat)
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "vehicle.form.nextServiceDate.label"
                              )}
                              input={
                                vehicle?.vehicleSafety?.nextServiceDate != null
                                  ? moment(
                                      vehicle?.vehicleSafety?.nextServiceDate
                                    ).format(dateFormat)
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>

                  <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                    <VehicleDocuments
                      role="vehicle"
                      roleId={params?.vehicleId}
                    />
                  </CustomFormGrid>

                  <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                    <AssignedTrailerInfo />
                  </CustomFormGrid>

                  <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                    <VehicleOwnerInfo />
                  </CustomFormGrid>

                  <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                    <AssignedDriverInfo />
                  </CustomFormGrid>
                </CustomFormGrid>
              }
            />
          </>
        )}
      </Box>
      {openTerminalModel === true && (
        <TerminalToInfoModel
          open={openTerminalModel}
          handleClose={handleCloseTerminalModel}
          terminalId={selectedData}
        />
      )}

      {openOwnerModel === true && (
        <OwnerInfoModel
          open={openOwnerModel}
          handleClose={handleCloseOwnerModel}
          ownerId={selectedData}
        />
      )}
    </>
  );
};

export default VehicleSummary;
