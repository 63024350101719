import { AppDispatch } from "../store";
import {
  setAllLoadManagements,
  setSingleLoadManagement,
} from "../reducers/loadManagement";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import { getServerData, postServerData, patchServerData } from "../api/index";
import { resetLoadManagementState } from "src/redux/reducers/loadManagement";

export const getAllLoadManagements = (
  limit?: number,
  page?: number,
  directDispatch?: boolean,
  filter?: any
) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(
    getServerData(`/load`) as any
  );

  if (response?.data?.data && directDispatch != true) {
    if (Array.isArray(response?.data?.data)) {
      dispatch(setAllLoadManagements(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};

export const getLoadManagementById = (id: number) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(getServerData(`/load/${id}`) as any);
  if (response?.data?.data) {
    dispatch(setSingleLoadManagement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const getAvailableDriverForLoad = (id: number) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(
    getServerData(`/driver/available-for-load?driver=${id}`) as any
  );

  return { success: response.success, data: response?.data?.data };
};
export const addLoadManagement = (data: LoadManagementProps) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(postServerData(`/load`, data) as any);

  if (response?.data?.data) {
    // dispatch(setSingleLoadManagement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
export const updateLoadManagement = (data: any, id: number) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(patchServerData(`/load/${id}`, data) as any);
  if (response?.data?.data?.load) {
    dispatch(setSingleLoadManagement(response?.data?.data?.load));
  }
  return { success: response.success, data: response?.data?.data };
};

export const updateLoadStatus = (data: any, id: number) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(patchServerData(`/load/status/${id}`, data) as any);
  if (response?.data?.data?.load) {
    dispatch(setSingleLoadManagement(response?.data?.data?.load));
  }
  return { success: response.success, data: response?.data?.data };
};

export const getMilageCalculations = (origin: any, destination: any) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(
    patchServerData(`/load/milage-calculation`, {
      origin: origin,
      destination: destination,
    }) as any
  );

  return { success: response.success, data: response?.data?.data };
};
export const getZipData = (zip: any) => async (dispatch: AppDispatch) => {
  const response = await dispatch(
    patchServerData(`/load/zip-data`, {
      zip: zip,
    }) as any
  );

  return { success: response.success, data: response?.data?.data };
};

export const getAddressData = (input: any) => async (dispatch: AppDispatch) => {
  const response = await dispatch(
    patchServerData(`/load/predictions-data`, {
      input: input,
    }) as any
  );

  return { success: response.success, data: response?.data?.data };
};
export const getAddressDataDetail = (input: any) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(
    patchServerData(`/load/predictions-data-detail`, {
      placeId: input,
    }) as any
  );

  return { success: response.success, data: response?.data?.data };
};

export const addLoadStop = (data: any) => async (dispatch: AppDispatch) => {
  const response = await dispatch(postServerData(`/load-stop`, data) as any);

  if (response?.data?.data) {
    // dispatch(setSingleLoadManagement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const cancelLoad = (data: any) => async (dispatch: AppDispatch) => {
  const response = await dispatch(patchServerData(`/load/cancel`, data) as any);

  return { success: response.success, data: response?.data?.data };
};

/* export const cancelLoad = (data: any) => async (dispatch: AppDispatch) => {
  const response = await dispatch(postServerData(`/load/cancel`, data));

  if (response?.data?.data) {
    // dispatch(setSingleLoadManagement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
}; */

export const updateLoadStop = (data: any, id: any) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(patchServerData(`/load-stop/${id}`, data) as any);

  if (response?.data?.data) {
    // dispatch(setSingleLoadManagement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const resetLoad = () => async (dispatch: AppDispatch) => {
  dispatch(resetLoadManagementState());
};
