import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  // GridRowParams,
} from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";

import { Edit } from "@mui/icons-material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllDrivers } from "src/redux/actions/driver";
import { selectDrivers } from "src/redux/reducers/driver";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureDrivers, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import { Clipboard } from "ts-react-feather-icons";
import { toNumber } from "lodash";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  //  loading: boolean;
}
export default function Drivers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const drivers = useAppSelector(selectDrivers);
  const [driverData, setDriverData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    //  loading: false,
  });
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const driverPermissions =
    allUserPermissions && allUserPermissions[featureDrivers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : driverPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : driverPermissions?.findIndex((item) => item === update);
  const active = location.pathname.includes("active");
  const getDriversList = async () => {
    setLoading(true);
    await dispatch(
      getAllDrivers(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );

    setLoading(false);
  };
  const getDriversListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllDrivers(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          false,
          filter
        )
      ));
  };
  /*  function pageExists(page: any) {
    return driverData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */

  useEffect(() => {
    const filterObject: any = {};
    if (active === true) {
      filterObject.items = [
        {
          columnField: "status",
          operatorValue: "equals",
          value: "active",
        },
      ];
      setFilter(filterObject);
    }
  }, []);
  /*   useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getDriversListWithFilter();
    } else {getDriversList();}
  }, [rowsState.pageSize, rowsState.page]); */

  useEffect(() => {
    if (active === false) {
      if (Object.keys(filter).length !== 0) {
        getDriversListWithFilter();
      } else getDriversList();
    } else if (active === true) {
      if (Object.keys(filter).length !== 0) {
        getDriversListWithFilter();
      }
    }
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    getDriversListWithFilter();
  }, [filter]);
  // useEffect(() => {
  //   setDriverData([drivers, ...driverData]);
  // }, [rowsState.page]);

  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (drivers?.data?.length > 0) {
      setDriverData({ ...driverData?.drivers, drivers });
    } else {
      setDriverData({});
    }
  }, [drivers]);

  const addDriver = () => {
    navigate("/drivers/drivers/create");
  };

  const navigateToDriverDetail = (id: number) => {
    navigate(
      `/drivers/drivers/details/${id}?entity=${featureDrivers}&referenceId=${id}`
    );
  };
  const searchDrivers = () => {
    navigate("/drivers/drivers/search");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );
  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/drivers/drivers/${params.row.id}`);
          }}
        />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/drivers/drivers/documents/${params.row.id}?entity=${featureDrivers}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="Details"
          key="details"
          onClick={() => {
            navigateToDriverDetail(params.row.id);
            // navigate(`/drivers/drivers/details/${params.row.id}`);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "firstName",
      headerName: translation("driver.driverName.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "city",
      headerName: translation("common.city.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "state",
      headerName: translation("common.state.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "dispatcher",
      headerName: translation("driver.common.dispatcher.label"),
      minWidth: 150,
      // filterable: false,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <div> {params.row.dispatcher?.firstName} </div>;
      },
    },
    {
      field: "cellPhoneNumber",
      headerName: translation("common.phone.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "email",
      headerName: translation("common.email.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "CB handle",
      headerName: translation("driver.list.cbHandle.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "cdlState",
      headerName: translation("driver.common.cdlState.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "cdlExpirationDate",
      headerName: translation("driver.list.cdlExp.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.cdlExpirationDate
              ? moment(params.row.cdlExpirationDate).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "physicalDueDate",
      headerName: translation("driver.list.physicalExp.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.physicalDueDate
              ? moment(params.row.physicalDueDate).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "reviewDate",
      headerName: translation("driver.list.annualReview.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.reviewDate
              ? moment(params.row.reviewDate).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "nextReviewDate",
      headerName: translation("driver.list.nextAnnualReview.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.nextReviewDate
              ? moment(params.row.nextReviewDate).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "hireDate",
      headerName: translation("driver.common.hireDate.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.hireDate
              ? moment(params.row.hireDate).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  /*  const onRowClick = (params: GridRowParams) => {
    navigateToDriverDetail(params.row.id);
  }; */

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("driver.list.title")}
        rows={driverData?.drivers?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addDriver}
        onClickSearch={searchDrivers}
        createPermission={createPermission > -1}
        // onRowClick={onRowClick}
        rowsState={rowsState}
        setRowsState={setRowsState}
        pagination={drivers.pagination}
        data={driverData?.drivers}
        setData={setDriverData}
        add={true}
        setFilter={setFilter}
        search={"drivers"}
      />
    </CustomFormGrid>
  );
}
