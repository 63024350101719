import React from "react";
import ForgetPasswordContainer from "src/containers/forgotPassword";
import Head from "src/components/head";

const ForgetPassword = (): JSX.Element => {
  return (
    <>
      <Head title="Forget Password" description="Forget Password " />
      <ForgetPasswordContainer />
    </>
  );
};

export default ForgetPassword;
