import React from "react";
import { Navigate } from "react-router-dom";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import Documents from "../pages/documents";
import Vehicles from "../pages/vehicle";
import VehicleForm from "../pages/vehicle/vehicleForm";
import VehicleSearch from "../containers/vehicle/vehicleSearch";
import VehicleReadForm from "../pages/vehicle/vehicleReadForm";
import { UserPermissions } from "src/interfaces/user";

import {
  admin,
  featureVehicles,
  featureLookups,
  create,
  update,
  find,
} from "src/utils/enums";

export const vehicleRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const vehiclePermissions =
    allUserPermissions && allUserPermissions[featureVehicles];
  const createPermission =
    role === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === create);
  const findPermission =
    role === admin ? 0 : vehiclePermissions?.findIndex((item) => item === find);
  const updatePermission =
    role === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === update);

  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);
  return [
    {
      path: "/vehicle/lookups/",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/vehicle/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/vehicle/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/vehicle/vehicles",
      exact: true,
      element:
        role === admin || vehiclePermissions?.length > 0 ? (
          <Vehicles />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/vehicle/vehicles/create",
      exact: true,
      element: createPermission > -1 ? <VehicleForm /> : <Navigate to="/404" />,
    },
    {
      path: "/vehicle/vehicles/:vehicleId",
      exact: true,
      element: updatePermission > -1 ? <VehicleForm /> : <Navigate to="/404" />,
    },
    // {
    //   path: "/vehicle/vehicles/details/:vehicleId",
    //   exact: true,
    //   element: findPermission > -1 ? <VehicleForm /> : <Navigate to="/404" />,
    // },
    {
      path: "/vehicle/vehicles/details/:vehicleId",
      exact: true,
      element:
        findPermission > -1 ? <VehicleReadForm /> : <Navigate to="/404" />,
    },
    {
      path: "/vehicle/vehicles/documents/:vehicleId",
      exact: true,
      element: updatePermission > -1 ? <Documents /> : <Navigate to="/404" />,
    },
    {
      path: "/vehicle/vehicles/search",
      exact: true,
      element: findPermission > -1 ? <VehicleSearch /> : <Navigate to="/404" />,
    },
  ];
};
