import { Card, CardHeader, CardContent, Grid, Typography, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import ConfirmationDialog from "../common/confirmationDialog";
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment";
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";

const useStyles = makeStyles((theme) => ({
    customPadding: {
        'padding-top': '4px !important'
    },
}));

const highlightContent = {
    backgroundColor: "#e7e7e7c4"
}

const contentTitle = {
    pt: "4px !important",
    pr: "0px",
    pl: "16px"
}

const contentDescription = {
    pt: "4px !important",
    pr: "16px",
    pl: "0px"
}

const cardContent = {
    p: "0",
    pt: "12px"
}

interface LoadReviewProps {
    done(error: any | null, data: LoadManagementProps): any;
    stepId?: number,
    data: LoadManagementProps
}

const LoadReview = ({
    done,
    stepId,
    data
}: LoadReviewProps): JSX.Element => {
    const classes = useStyles();
    const { handleSubmit } = useForm();
    const [submitDialog, setSubmitDialog] = useState(false);
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectUser);
    const onSubmit = () => {
        setSubmitDialog(true);
    }

    return (
        <React.Fragment>
            <form id={`load-form-${stepId}`} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>

                    <Grid item xs={4} sm={8} md={4} className={classes.customPadding}>
                        <Card sx={{ p: 1, borderRadius: "5px" }}>
                            <CardHeader title={"Information"} sx={{ pb: "5px" }} />
                            <Divider light />
                            <CardContent sx={{ ...cardContent }}>

                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Pick Up Date</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <EventIcon sx={{ fontSize: 14 }} />
                                            <Typography fontSize={12} textAlign={"right"}>
                                                {data?.pickupDateTime ? moment(data?.pickupDateTime).format("MM-DD-YYYY") : "N/A"}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <AccessTimeIcon sx={{ fontSize: 14 }} />
                                            <Typography fontSize={12} textAlign={"right"}>
                                                {data?.pickupStart || "N/A"} - {data?.pickupEnd || "N/A"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Delivery Date</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <EventIcon sx={{ fontSize: 14 }} />
                                            <Typography fontSize={12} textAlign={"right"}>
                                                {data?.deliveryDateTime ? moment(data?.deliveryDateTime).format("MM-DD-YYYY") : "N/A"}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <AccessTimeIcon sx={{ fontSize: 14 }} />
                                            <Typography fontSize={12} textAlign={"right"}>
                                                {data?.deliveryStart || "N/A"} - {data?.deliveryEnd || "N/A"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>


                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4} className={classes.customPadding}>
                        <Card sx={{ p: 1, borderRadius: "5px" }}>
                            <CardHeader title={"Shipper"} sx={{ pb: "5px" }} />
                            <Divider light />
                            <CardContent sx={{ ...cardContent }} >
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Company</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.shippingInformation?.company?.company || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600}>Address</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.shippingInformation?.address || "N/A"}
                                            <br />
                                            {data?.shippingInformation?.city?.city || "-"}, {data?.shippingInformation?.state?.name || "-"} {data?.shippingInformation?.zip || "-"}
                                        </Typography>
                                    </Grid>
                                    {
                                        data?.shippingInformation?.phoneNumber &&
                                        <>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                                <Typography fontSize={12} fontWeight={600}>Contact Info</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                                <Typography fontSize={12} textAlign={"right"} >
                                                    {data.shippingInformation?.phoneNumber || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        data?.shippingInformation?.directionNotes &&
                                        <>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentTitle }}>
                                                <Typography fontSize={12} fontWeight={600} >Instructions</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentDescription }}>
                                                <Typography fontSize={12} textAlign={"right"} >
                                                    {data.shippingInformation?.directionNotes || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4} className={classes.customPadding}>
                        <Card sx={{ p: 1, borderRadius: "5px" }}>
                            <CardHeader title={"Consignee"} sx={{ pb: "5px" }} />
                            <Divider light />
                            <CardContent sx={{ ...cardContent }}>
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Company</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.consigneeInformation?.company?.company || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600}>Address</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.consigneeInformation?.address || "N/A"}
                                            <br />
                                            {data?.consigneeInformation?.city?.city || "-"}, {data?.consigneeInformation?.state?.name || "-"} {data?.consigneeInformation?.zip || "-"}
                                        </Typography>
                                    </Grid>
                                    {
                                        data?.consigneeInformation?.phoneNumber &&
                                        <>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                                <Typography fontSize={12} fontWeight={600} >Contact Info</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                                <Typography fontSize={12} textAlign={"right"} >
                                                    {data?.consigneeInformation?.phoneNumber || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }

                                    {
                                        data?.consigneeInformation?.directionNotes &&
                                        <>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentTitle }}>
                                                <Typography fontSize={12} fontWeight={600} >Instructions</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentDescription }}>
                                                <Typography fontSize={12} textAlign={"right"} >
                                                    {data?.consigneeInformation?.directionNotes || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }


                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={4} sm={8} md={4} className={classes.customPadding}>
                        <Card sx={{ p: 1, borderRadius: "5px" }}>
                            <CardHeader title={"Billing Information"} sx={{ pb: "5px" }} />
                            <Divider light />
                            <CardContent sx={{ ...cardContent }}>
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Bill To</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {(data?.billToInformation?.customer as any)?.companyName || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Freight Method</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.freightBill?.lineHaul || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Rate</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentDescription }}>
                                        <Typography fontSize={14} textAlign={"right"} >
                                            {data?.freightBill?.rate}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Mileage</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.freightBill?.mileage}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider variant="middle" sx={{ mt: 1, mb: 1 }} />
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Freight Bill</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.freightBill?.freightBill}
                                        </Typography>
                                    </Grid>
                                    {
                                        data?.freightBill?.otherBill !== undefined && data?.freightBill?.otherBill !== null &&
                                        <>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                                <Typography fontSize={12} fontWeight={600} >Other Charges</Typography>
                                                {
                                                    data?.loadLineItem &&
                                                    <Typography fontSize={12}>
                                                        ({
                                                            (data?.loadLineItem || []).map((e, i) => {
                                                                let title = ""
                                                                if (typeof e.lineItem == "string") {
                                                                    title = e.lineItem;
                                                                } else if (typeof e.lineItem == "object") {
                                                                    title = (e.lineItem as any).title as string;
                                                                }

                                                                if (i == ((data?.loadLineItem?.length || 0) - 1)) {
                                                                    return `${title}`;
                                                                } else {
                                                                    return `${title}, `;
                                                                }
                                                            })

                                                        })
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                                <Typography fontSize={12} textAlign={"right"} >
                                                    {data?.freightBill?.otherBill}
                                                </Typography>
                                            </Grid>
                                        </>

                                    }
                                </Grid>
                                <Divider light sx={{ mt: 1, mb: 1 }} />
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentTitle }}>
                                        <Typography fontSize={14} fontWeight={800} >Total</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentDescription }}>
                                        <Typography fontSize={14} fontWeight={800} textAlign={"right"} >
                                            {data?.freightBill?.grandTotal}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4} className={classes.customPadding}>
                        <Card sx={{ p: 1, borderRadius: "5px" }}>
                            <CardHeader title={"Load Information"} sx={{ pb: "5px" }} />
                            <Divider light />
                            <CardContent sx={{ ...cardContent }} >
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Bill of Lading</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.billOfLading || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Load Size</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.loadSize || "N/A"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Weight (lbs)</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"} >
                                            {data?.weight || "N/A"}
                                        </Typography>
                                    </Grid>
                                    {
                                        data?.hotComments &&
                                        <>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentTitle }}>
                                                <Typography fontSize={12} fontWeight={600} >Hot Comments</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} sx={{ ...highlightContent, ...contentDescription }}>
                                                <Typography fontSize={12} textAlign={"right"} >
                                                    {data?.hotComments || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4} className={classes.customPadding}>
                        <Card sx={{ p: 1, borderRadius: "5px" }}>
                            <CardHeader title={"Internal Contacts"} sx={{ pb: "5px" }} />
                            <Divider light />
                            <CardContent sx={{ ...cardContent }} >
                                <Grid container spacing={2} width={"100%"} margin={0}>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Name</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"}>{user?.firstName}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Address</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"}>{user?.address}</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentTitle }}>
                                        <Typography fontSize={12} fontWeight={600} >Email</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{ ...contentDescription }}>
                                        <Typography fontSize={12} textAlign={"right"}>{user?.email}</Typography>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>

            {
                submitDialog && (
                    <ConfirmationDialog
                        onClose={(info) => {
                            if (info) {
                                setSubmitDialog(false);
                                done(null, data);
                            } else {
                                setSubmitDialog(false);
                            }
                        }}
                        open={submitDialog}
                        title="Submit Load"
                        message="Are you sure? This action will create load in system"
                    />
                )
            }
        </React.Fragment>
    )
};

export default LoadReview;