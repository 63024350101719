import React from "react";
import { Navigate } from "react-router-dom";
import Commodity from "../pages/commodity";
import CommodityForm from "../pages/commodity/commodityForm";
import { UserPermissions } from "src/interfaces/user";
import { admin, create, update,find, featureCommodity } from "src/utils/enums";
import CommoditySearch from "../containers/commodity/commoditySearch";

export const commodityRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const commodityPermissions =
    allUserPermissions && allUserPermissions[featureCommodity];
  const createPermission =
    role === admin
      ? 0
      : commodityPermissions?.findIndex((item) => item === create);
      const findPermission =
      role === admin ? 0 : commodityPermissions?.findIndex((item) => item === find);
  const updatePermission =
    role === admin
      ? 0
      : commodityPermissions?.findIndex((item) => item === update);

  return [
    {
      path: "/config/commodity",
      exact: true,
      element:
        role === admin || commodityPermissions?.length > 0 ? (
          <Commodity />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/config/commodity/create",
      exact: true,
      element:
        createPermission > -1 ? <CommodityForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/commodity/:id",
      exact: true,
      element:
        updatePermission > -1 ? <CommodityForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/commodity/search",
      exact: true,
      element: findPermission > -1 ? <CommoditySearch /> : <Navigate to="/404" />,
    },
  ];
};
