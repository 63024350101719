import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { City, State } from "src/interfaces/shared";

export interface SharedState {
    cityList: Array<City>;
    stateList: Array<State>
    singleState: State | null;
    singleCity: City | null;
}

const initialState: SharedState = {
    cityList: [],
    stateList: [],
    singleState: null,
    singleCity: null
};

export const sharedSlice = createSlice({
    name: "shared",
    initialState,

    reducers: {
        setCityList: (state, action: PayloadAction<City[]>) => {
            state.cityList = action.payload;
        },
        setSingleCity: (state, action: PayloadAction<City>) => {
            state.singleCity = action.payload;
        },
        setStateList: (state, action: PayloadAction<State[]>) => {
            state.stateList = action.payload;
        },
        setSingleState: (state, action: PayloadAction<State>) => {
            state.singleState = action.payload;
        },
        resetCity: (state) => {
            state.singleCity = null;
        },
        resetState: (state) => {
            state.singleState = null;
        },
        resetSharedState: (state) => {
            state.singleCity = null;
            state.singleState = null;
            state.stateList = [];
            state.cityList = [];
        },
    },
});

export const {
    setCityList,
    setSingleCity,
    setSingleState,
    setStateList,
    resetCity,
    resetSharedState,
    resetState
} = sharedSlice.actions;
export const selectCities = (state: RootState): Array<City> =>
    state.shared.cityList;
export const selectStates = (state: RootState): Array<State> =>
    state.shared.stateList;
export const selectCity = (state: RootState): City | null =>
    state.shared.singleCity;
export const selectState = (state: RootState): State | null =>
    state.shared.singleState;

export default sharedSlice.reducer;
