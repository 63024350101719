import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { selectDriver } from "src/redux/reducers/driver";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../common/readForms/formHeader";
import FromInput from "../common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import { selectTerminal } from "src/redux/reducers/terminal";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
// import Documents from "./docements";
import { driver as driverEnum } from "src/utils/enums";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";
import { LoadStopProps } from "src/interfaces/loadManagement";

interface TerminalSummaryProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}

const TerminalSummary = ({
  loading,
  pageLoading,
  onSubmit,
}: TerminalSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const [loadStops, setLoadStops] = useState<Array<LoadStopProps>>([
    // { ...defaultLoadStopObject },
  ]);

  const user = useAppSelector(selectUser);

  const terminal = useAppSelector(selectTerminal);
  const driver = useAppSelector(selectDriver);

  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };
  const handleCloseStopOffFormModal = () => {
    setOpenDispatchFormModal && setOpenDispatchFormModal(null);
  };
  const handleSetMilageValue = async (value: any) => {
    // setValue("freightBill.mileage", value);
  };

  // const loadStopCancelled = driver?.loadStops?.every(
  //   (item) => item.dispatchStatus === "cancelled"
  // );
  // const loadStopPlanned = driver?.loadStops?.every(
  //   (item) => item.dispatchStatus === "planned"
  // );

  // useEffect(() => {
  //   if (
  //     driver != null &&
  //     Object.keys(driver).length > 0 &&
  //     driver?.loadStops.length > 0
  //   ) {
  //     setLoadStops([...driver?.loadStops]);
  //   }
  // }, []);

  return (
    <Box>
      {pageLoading ? (
        <CustomFormGrid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CustomFormGrid>
            <CircularProgress sx={{ height: "20", width: "20px" }} />
          </CustomFormGrid>
        </CustomFormGrid>
      ) : (
        <>
          <MobileCheck
            grid1={
              <CustomFormGrid container>
                <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                  <Box className={classes.loadFormTitleContainer}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      <Button>
                        <ArrowBack onClick={() => navigate(-1)} />
                      </Button>
                      <Typography
                        variant="h6"
                        className={classes.loadFormMainHeadings}
                      >
                        {translation("terminal.information.title")}
                      </Typography>
                    </Box>
                  </Box>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                  <CustomFormGrid
                    container
                    className={classes.buttonContainer}
                    justifyContent="flex-end"
                  >
                    {/*  <Button
                  variant="outlined"
                  className={classes.formButtonDiscard}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.discard")}
                </Button> */}
                    {/*
                {params?.loadId &&
                  (loadStopCancelled === true || loadStopPlanned === true) && (
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonCancel}
                      onClick={() => handleOpenConfirmModel()}
                    >
                      CANCEL LOAD
                    </Button>
                  )} */}

                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonEdit}
                      onClick={() =>
                        navigate(`/terminals/terminals/${params?.terminalId}`)
                      }
                    >
                      EDIT INFORMATION
                    </Button>

                    {/* <Button
                  color="error"
                  variant="contained"
                  className={classes.formButtonDispatch}
                  onClick={() => setOpenDispatchFormModal(0)}
                >
                  DISPATCH
                </Button> */}
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
            grid2={
              <CustomFormGrid container>
                <CustomFormGrid items lg={4} md={4} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation(
                        "terminals.form.terminalInformation.label"
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("terminals.form.name.label")}
                            input={terminal?.title}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "terminals.form.terminalCode.label"
                            )}
                            input={terminal?.terminalCode}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("terminals.form.type.label")}
                            input={
                              terminal?.type != null
                                ? terminal?.type?.content
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.status.label")}
                            input={terminal?.status}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid items lg={4} md={4} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("driver.read.form.title.form3")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.address.label")}
                            input={terminal?.address}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.city.label")}
                            input={terminal?.city}
                            checkColor={false}
                          />
                        </CustomFormGrid>

                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.state.label")}
                            input={terminal?.state}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.zip.label")}
                            input={terminal?.zip}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid items lg={4} md={4} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("common.contactInformation.label")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.phone.label")}
                            input={terminal?.phoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.altPhoneNumber.label")}
                            input={terminal?.altPhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.faxPhoneNumber.label")}
                            input={terminal?.faxPhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>

                <CustomFormGrid container>
                  <CustomFormGrid items lg={4} md={4} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation(
                          "terminals.form.commissioninformation.label"
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.commissionProgram.label"
                              )}
                              input={terminal?.commissionProgram}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.systemTruckCommission.label"
                              )}
                              input={terminal?.systemTruckCommission}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.houseCommission.label"
                              )}
                              input={terminal?.houseCommission}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.terminalTruckCommission.label"
                              )}
                              input={terminal?.terminalTruckCommission}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.calculateCommission.label"
                              )}
                              input={
                                terminal?.calculateCommission === true
                                  ? "Yes"
                                  : "No"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <CustomFormGrid>
                            <FromInput
                              heading={
                                "Share Driver Info with other terminals?"
                              }
                              input={
                                terminal?.shareDriverInfo === true
                                  ? "Yes"
                                  : "No"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.dateOpened.label"
                              )}
                              input={
                                terminal?.dateOpened != null
                                  ? moment(terminal?.dateOpened).format(
                                      dateFormat
                                    )
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "terminals.form.dateClosed.label"
                              )}
                              input={
                                terminal?.dateClosed != null
                                  ? moment(terminal?.dateClosed).format(
                                      dateFormat
                                    )
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
          />
        </>
      )}
      <LoadCancelDialog
        handleClose={handleCloseConfirmModel}
        open={openConfirmModel}
        title="Canel Load"
        loadId={params?.loadId}
      />

      {/* {openDispatchFormModal !== null && openDispatchFormModal == 0 && (
        <AddLoadStopInfoModel
          loadId={params?.loadId}
          handleClose={handleCloseStopOffFormModal}
          open={openDispatchFormModal}
          title="Load Stop Detail"
          // index={index}
          item={driver?.loadStops[0]}
          freightBill={driver?.freightBill}
          buttonText={"update"}
          setOpenStopOffFormModal={setOpenDispatchFormModal}
          dispatchTabOpen={true}
          setLoadStops={setLoadStops}
          loadStops={driver?.loadStops}
          handleSetMilageValue={handleSetMilageValue}
          disabled={false}
        />
      )} */}
    </Box>
  );
};

export default TerminalSummary;
