import React from "react";
import { Card, CardContent, Typography, Box} from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/styles/formStyles";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectDashboard } from "src/redux/reducers/settlement";


const SettlementStatistics = () => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const dashboardData = useAppSelector(selectDashboard);

  return (
    <CustomFormGrid item xs={12} lg={12}>
      <Card
        sx={{
          paddingBottom: "50px",
          margin:"0px",
        }}
      >
        <CardContent
          sx={{
            paddingBottom: "16px !important",
          }}
        >
          <Box
            sx={{
              display: {
                sm: "flex",
                xs: "block",
              },
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0px",
                  fontWeight: 600,
                }}

              >
                Settlement Statistics
              </Typography>
            </Box>
          </Box>

          <CustomFormGrid container style={{marginTop:"35px"}}>
            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.tractor")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
               {dashboardData && dashboardData?.activeVehicleCount ? dashboardData?.activeVehicleCount : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div className={classes.vl}></div>
            </CustomFormGrid>

            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.trailer")}
                <br></br>
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
              {dashboardData && dashboardData?.activeTrailerCount ? dashboardData?.activeTrailerCount : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div className={classes.vl}></div>
            </CustomFormGrid>
            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.driver")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
              {dashboardData && dashboardData?.activeDriverCount ? dashboardData?.activeDriverCount : "0"}
              </Typography>
            </CustomFormGrid>

           {/*  <CustomFormGrid
              xs={3}
              lg={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.reverse")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                0
              </Typography>
                      </CustomFormGrid>


                      <CustomFormGrid xs={1} lg={1}>
                      <div
              className={classes.vl}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            ></div>
            </CustomFormGrid>

            <CustomFormGrid
              xs={3}
              lg={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.loan")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                0
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
                      <div
              className={classes.vl}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            ></div>
            </CustomFormGrid>
            <CustomFormGrid
              xs={3}
              lg={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.pending")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                $0.00
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.pendingDed")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                -
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
                      <div
              className={classes.vl}
            ></div>
            </CustomFormGrid>

            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("settlement.dashboard.pendingPay")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                -
              </Typography>
            </CustomFormGrid> */}
          </CustomFormGrid>

          {/* table container */}
        </CardContent>
      </Card>
    </CustomFormGrid>
  );
};

export default SettlementStatistics;
