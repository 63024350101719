import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import DocumentTypeFormContainer from "src/containers/documentTypes/form";
const DocumentTypeForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Document Type Form" description="Porto Document Type Form" />
      <DocumentTypeFormContainer />
    </Grid>
  );
};

export default DocumentTypeForm;
