import React from "react";
import { MenuItem } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { statusTypes } from "src/utils/dropdowns/common";
import { LookupProps } from "src/interfaces/lookup";
interface TerminalInfoProps {
  control: any;
  terminalTypes: Array<LookupProps> | null;
  errors: { [key: string]: any };
}

const TerminalInfo = ({
  control,
  errors,
  terminalTypes,
}: TerminalInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="title"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
               // margin="normal"
                fullWidth
                id="title"
                label={translation("terminals.form.name.label")}
                error={Boolean(errors?.title)}
                helperText={
                  errors?.title && translation("terminals.form.name.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="terminalCode"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
              //  margin="normal"
                fullWidth
                id="terminalCode"
                label={translation("terminals.form.terminalCode.label")}
                error={Boolean(errors?.terminalCode)}
                helperText={
                  errors?.terminalCode &&
                  translation("terminals.form.terminalCode.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="type"
            control={control}
            defaultValue={""}
           // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
              //  margin="normal"
                id="type"
                label={translation("terminals.form.type.label")}
                error={Boolean(errors?.type)}
                select
                helperText={
                  errors?.type && translation("terminals.form.type.message")
                }
               // required
              >
                {terminalTypes &&
                  terminalTypes.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="status"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
              //  margin="normal"
                id="status"
                label={translation("common.status.label")}
                error={Boolean(errors?.status)}
                select
                helperText={
                  errors?.status && translation("common.status.message")
                }
                required
              >
                {statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default TerminalInfo;
