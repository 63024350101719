import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import AddEarning from "src/components/settlement/form";
import { SettlementProps } from "src/interfaces/settlement";
import {
  addSettlement,
  getSettlementById,
  updateSettlement,
} from "src/redux/actions/settlement";
import { getAllAccounts } from "src/redux/actions/settlement";
import { sanitizeEntity } from "src/utils/common";

import { getAllTrailers } from "src/redux/actions/trailer";
import { getAllDrivers } from "src/redux/actions/driver";
import { getAllVehicles } from "src/redux/actions/vehicle";
import { getAllSettlementCodes } from "src/redux/actions/settlementCodes";
import { useAppDispatch } from "src/hooks/reduxHooks";

const addEarningContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (params?.id) {
      getSingleSettlement(parseInt(params?.id));
    }
  }, [params?.id]);

  const getSingleSettlement = async (id: number) => {
    setPageLoading(true);
    await dispatch(getSettlementById(id));

    setPageLoading(false);
  };

  useEffect(() => {
    dispatch(getAllDrivers(0, 1));
    dispatch(getAllTrailers(0, 1));
    dispatch(getAllVehicles(0, 1));
    dispatch(getAllSettlementCodes(0, 1));
    dispatch(getAllAccounts());
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: SettlementProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.accountType = objToSubmit.accountType
      ? objToSubmit.accountType
      : "";
    objToSubmit.type = objToSubmit.type ? objToSubmit.type : "";
    objToSubmit.account = objToSubmit.account
      ? objToSubmit.account
      : "";
    objToSubmit.recurringSettlementID = objToSubmit.recurringSettlementID
      ? objToSubmit.recurringSettlementID
      : null;
    objToSubmit.invoiceNo = objToSubmit.invoiceNo ? objToSubmit.invoiceNo : null;

    objToSubmit.internalComment = objToSubmit.internalComment
      ? objToSubmit.internalComment
      : "";
    objToSubmit.externalComment = objToSubmit.externalComment
      ? objToSubmit.externalComment
      : "";
    objToSubmit.rate = objToSubmit.rate ? objToSubmit.rate : null;
    objToSubmit.enteredDate = objToSubmit.enteredDate
      ? objToSubmit.enteredDate
      : null;
    objToSubmit.invoiceDate = objToSubmit.invoiceDate
      ? objToSubmit.invoiceDate
      : null;
    objToSubmit.dateToPay = objToSubmit.dateToPay
      ? objToSubmit.dateToPay
      : null;
    objToSubmit.load = objToSubmit.load?.id ? objToSubmit.load?.id : null;
    objToSubmit.driver = objToSubmit.driver ? objToSubmit.driver : null;
    objToSubmit.owner = objToSubmit.owner ? objToSubmit.owner : null;
    objToSubmit.settlementCode = objToSubmit.settlementCode
      ? objToSubmit.settlementCode
      : null;
    objToSubmit.vehicle = objToSubmit.vehicle ? objToSubmit.vehicle : null;
    objToSubmit.trailer = objToSubmit.trailer ? objToSubmit.trailer : null;

    if (params?.id) {
      //  update
      await dispatch(updateSettlement(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const settlement = await dispatch(addSettlement(objToSubmit));
      if (settlement.success && settlement.data.id) {
        setTimeout(
          () =>
          //  navigate(`/settlements/earning-deduction/${settlement.data.id}`),
          navigate("/settlements/dashboard"),
          500
        );
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <AddEarning
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default addEarningContainer;
