export const statusTypes = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];

export const accountType = [
  {
    value: "driver",
    label: "Driver",
  },
  {
    value: "owner",
    label: "Owner",
  },
  {
    value: "terminal",
    label: "Terminal",
  },
  {
    value: "company",
    label: "Company",
  },
];



export const type = [
  {
    value: "earning",
    label: "Earning",
  },
  {
    value: "deduction",
    label: "Deduction",
  },
  {
    value: "reimbursement",
    label: "Reimbursement",
  },
];