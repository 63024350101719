import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { DriverVehicleProps } from "src/interfaces/driver";

export interface DriverVehicleState {
  DriverVehicleList: Array<DriverVehicleProps>;
  singleDriverVehicle: DriverVehicleProps | null;
}

const initialState: DriverVehicleState = {
  DriverVehicleList: [],
  singleDriverVehicle: null,
};

export const driverVehicleSlice = createSlice({
  name: "driver_vehicle",
  initialState,

  reducers: {
    setAllDriverVehicle: (
      state,
      action: PayloadAction<Array<DriverVehicleProps>>
    ) => {
      state.DriverVehicleList = action.payload;
    },
    setSingleDriverVehicle: (
      state,
      action: PayloadAction<DriverVehicleProps>
    ) => {
      state.singleDriverVehicle = action.payload;
    },
    resetDriverVehicle: (state) => {
      state.singleDriverVehicle = null;
    },
    resetDriverVehicleState: (state) => {
      state.singleDriverVehicle = null;
      state.DriverVehicleList = [];
    },
    filterDriverVehicleList: (state, action: PayloadAction<number>) => {
      const _list = state.DriverVehicleList.filter(
        (item) => item.vehicle !== action.payload
      );
      state.DriverVehicleList = [..._list];
    },
    updateDriverVehicleList: (
      state,
      action: PayloadAction<{ data: DriverVehicleProps; id: number }>
    ) => {
      const _list = [...state.DriverVehicleList];
      const index = _list.findIndex(
        (item) => item.vehicle === action.payload.id
      );
      if (index > -1) {
        state.DriverVehicleList[index] = { ...action.payload.data };
      }
    },
  },
});
export const {
  setAllDriverVehicle,
  setSingleDriverVehicle,
  resetDriverVehicle,
  resetDriverVehicleState,
  filterDriverVehicleList,
  updateDriverVehicleList,
} = driverVehicleSlice.actions;
export const selectVehiclesTrailer = (
  state: RootState
): Array<DriverVehicleProps> => state.driverVehicle.DriverVehicleList;
export const selectVehicleTrailer = (
  state: RootState
): DriverVehicleProps | null => state.driverVehicle.singleDriverVehicle;
export default driverVehicleSlice.reducer;
