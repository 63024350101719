import React from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Zoom,
  MenuItem,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/components/customElements/customTextField";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm, Controller } from "react-hook-form";
import { documentStatus } from "src/utils/dropdowns/document";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";

const Transition = React.forwardRef<HTMLInputElement, any>((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

interface DocumentModalProps {
  open: boolean;
  handleClose: () => void;
  /* handleVerifyDocument: (data: VerifyDocumentProps) => any; */
  handleVerifyDocument: (data: any) => any;
  title: string;
  loading: boolean;
}

const DocumentModal = ({
  open,
  handleClose,
  handleVerifyDocument,
  title,
  loading,
}: DocumentModalProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CustomForm
                noValidate
                onSubmit={handleSubmit(handleVerifyDocument)}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <CustomTextField
                              {...field}
                              id="status"
                              fullWidth
                              size="small"
                              select
                              label={translation("common.status.label")}
                              helperText={
                                errors?.status &&
                                translation("common.status.message")
                              }
                              error={Boolean(errors?.status)}
                            >
                              {documentStatus.map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </CustomTextField>
                          </>
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="remarks"
                        control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <CustomTextField
                              {...field}
                              id="remarks"
                              fullWidth
                              size="small"
                              label={translation("common.remarks.label")}
                              helperText={
                                errors?.remarks &&
                                translation("common.remarks.message")
                              }
                              error={Boolean(errors?.remarks)}
                              multiline
                              rows={3}
                            />
                          </>
                        )}
                      />
                    </CustomFormGrid>
                  </Grid>
                </LocalizationProvider>
                <CustomFormGrid
                  container
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.formButton}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ color: "#fff", height: "20px", width: "20px" }}
                      />
                    ) : (
                      translation("common.form.update")
                    )}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    className={classes.formButton}
                    onClick={() => handleClose()}
                  >
                    {translation("common.btn.cancel")}
                  </Button>
                </CustomFormGrid>
              </CustomForm>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentModal;
