import React, { useState } from "react";
import { Grid } from "@mui/material";
import {useLocation } from "react-router-dom";
import AddLookupForm from "src/components/lookup/addLookup";
import { AddLookupProps } from "src/interfaces/lookup";
import {
  addLookupByFeatureKey,
} from "src/redux/actions/lookup";

import { useAppDispatch } from "src/hooks/reduxHooks";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
interface LookupFormProps {
  handleClose: () => any;
  featureKey: string;
  lookupFeatureEnum: any;
  fieldName: any;

  }
  const LookupFormContainer = ({
    handleClose,
    featureKey,
    lookupFeatureEnum,
    fieldName,

  }: LookupFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const path = location.pathname.split("/");
  const [loading, setLoading] = useState(false);

    const getLookupsList = async () => {
      setLoading(true);
      await dispatch(getAllLookupsByCategory(lookupFeatureEnum, 0, 1));
      setLoading(false);
    };
/*LookupProps  */
  const onSubmit = async (data: any) => {
    setLoading(true);

    const objToSubmit: AddLookupProps = { ...data };
    objToSubmit.feature = featureKey ? featureKey : "";
      const lookup = await dispatch(addLookupByFeatureKey(objToSubmit));
      if (lookup.success && lookup.data.id && handleClose && path) {
        handleClose();
        getLookupsList();
      }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <AddLookupForm
          loading={loading}
          onSubmit={onSubmit}
          featureKey={featureKey}
          handleClose={handleClose}
          fieldName={fieldName}
        />
      </Grid>
    </Grid>
  );
};

export default LookupFormContainer;
