import React, { useState } from "react";
import { Typography } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useStyles } from "src/styles/formStyles";
import AddLookupDialog from "src/components/common/addLookupModel";
import AddLookupContainer from "src/containers/lookup/addLookup";
import { GridAddIcon } from "@mui/x-data-grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomCheckbox from "../customElements/customCheckbox";

interface VehicleInfoProps {
  name: string;
  heading: string;
  control: any;
  lookupsArray: any;
  disabled?: boolean;
  featureKey: string;
  lookupFeatureEnum: any;
  linkingAddress: any;
  selectedlookup: Array<number>;
  handleSelectMedical: (e: any) => void;
}
const VehicleInfo = ({
  //  onSubmit,
  name,
  heading,
  control,
  lookupsArray,
  disabled,
  featureKey,
  selectedlookup,
  handleSelectMedical,
  lookupFeatureEnum,
  linkingAddress,
}: VehicleInfoProps): JSX.Element => {
  const classes = useStyles();

  const [openLookupModal, setOpenLookupModal] = useState<boolean>(false);

  const handleOpenLookupModal = () => {
    setOpenLookupModal(true);
  };
  const handleCloseLookupModal = () => {
    setOpenLookupModal(false);
  };

  return (
    <>
      <CustomFormGrid container>

          <CustomFormGrid container style={{paddingLeft:"0px"}}>
            <Typography className={classes.medicalHeading}>{heading}</Typography>
            {linkingAddress ? (
              <GridAddIcon
                className={classes.disableGridAddButton}
              ></GridAddIcon>
            ) : (
              <GridAddIcon
                className={classes.formAddOwnerButton}
                onClick={() => handleOpenLookupModal()}
              ></GridAddIcon>
            )}
          </CustomFormGrid>



        {lookupsArray &&
          lookupsArray.map((item: any, index: any) => (
            <CustomFormGrid key={index} item xs={6} sm={6} md={6} lg={6}>
              <Controller
                name={name}
                control={control}
                defaultValue={""}
                render={() => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          disabled={disabled}
                          value={item?.id}
                          onChange={handleSelectMedical}
                          checked={selectedlookup.includes(item.id)}
                        />
                      }
                      label={item.content}
                    />
                  </FormGroup>
                )}
              />
            </CustomFormGrid>
          ))}
      </CustomFormGrid>

      <AddLookupDialog
        handleClose={handleCloseLookupModal}
        open={openLookupModal}
        title="Add Lookup"
        ComponentToRender={
          <AddLookupContainer
            handleClose={handleCloseLookupModal}
            featureKey={featureKey}
            lookupFeatureEnum={lookupFeatureEnum}
            fieldName={heading}
          />
        }
      />
    </>
  );
};

export default VehicleInfo;
