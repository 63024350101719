import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Close } from "@mui/icons-material";
import CustomGrid from "src/components/customElements/customFormGrid";

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  ComponentToRender?: React.ReactNode;
}

export default function AddCommonDialog({
  open,
  handleClose,
  title,
  ComponentToRender,
}: DialogProps): JSX.Element {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          <CustomGrid
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ textTransform: "uppercase" }}>{title}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomGrid>
        </DialogTitle>
        <DialogContent>{ComponentToRender}</DialogContent>
      </Dialog>
    </div>
  );
}
