import { AppDispatch } from "../store";
import { removeLinkedOwner, addLinkedOwner } from "../reducers/vehicle";
import { removeLinkedVehicle, addLinkedVehicle } from "../reducers/owner";
import { OwnerVehicleProps } from "src/interfaces/owner";
import { postServerData, patchServerData } from "../api/index";

export const addOwnerVehicle =
  (data: OwnerVehicleProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      postServerData(`/owner-vehicle-link`, data)
    );
    if (Array.isArray(response?.data?.data)) {
      dispatch(addLinkedOwner(response?.data?.data[0]));
      dispatch(addLinkedVehicle(response?.data?.data[0]));

      /*   dispatch(addLinkedTrailer(response?.data?.data[0]?.trailer));
      dispatch(addLinkedVehicle(response?.data?.data[0]?.vehicle)); */
      // dispatch(setSingleVehicleTrailer(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const updateOwnerVehicle =
  (data: OwnerVehicleProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/owner-vehicle-link`, data)
    );

    if (response?.data?.data) {
      dispatch(removeLinkedOwner());
      if (typeof data.vehicle === "number") {
        dispatch(removeLinkedVehicle(data?.vehicle));
      }
    }
    return { success: response.success, data: response?.data };
  };
