import { AppDispatch } from "../store";
import { DriverOwnerProps } from "src/interfaces/driver";
import { postServerData, patchServerData } from "../api/index";
import { removeLinkedOwner, addLinkedOwner } from "src/redux/reducers/driver";
import { addLinkedDriver, removeLinkedDriver } from "src/redux/reducers/owner";

export const addDriverOwner =
  (data: DriverOwnerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/owner-driver-link`, data));

    if (Array.isArray(response?.data?.data)) {
      dispatch(addLinkedOwner(response?.data?.data[0]));
      dispatch(addLinkedDriver(response?.data?.data[0]));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const deleteDriverOwnerLink =
  (data: DriverOwnerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/owner-driver-link`, data)
    );

    if (response?.data?.data) {
      if (typeof data.driver === "number") {
        dispatch(removeLinkedDriver(data?.driver));
      }

     dispatch(removeLinkedOwner());
    }
    return { success: response.success, data: response?.data?.data };
  };
