import { AppDispatch } from "../store";
import { setAllUsers, setSingleUser, updateUserList } from "../reducers/users";
import { updateUserSuccess } from "../reducers/auth";
import { UserProps } from "src/interfaces/user";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllUsers = (limit?: number, page?: number,filter?: any) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/user`, ));


  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data?.data)) {
      dispatch(setAllUsers(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};

export const getUserById = (id: number) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/user/${id}`));
  if (response?.data?.data) {
    dispatch(setSingleUser(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const addUser = (data: UserProps) => async (dispatch: AppDispatch) => {
  const response = await dispatch(postServerData(`/user/add`, data));

  if (response?.data?.data) {
    dispatch(updateUserList(response?.data?.data));
    dispatch(setSingleUser(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const updateUser =
  (data: UserProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/user/${id}`, data));

    if (response?.data?.data?.user) {
      dispatch(setSingleUser(response?.data?.data?.user));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const updateUserPermissions =
  (securityGroup: Array<number>, user: number) =>
  async (dispatch: AppDispatch) => {
    const body = {
      securityGroup,
      user,
    };
    const response = await dispatch(
      patchServerData(`/user-security-groups`, body)
    );

    return { success: response.success, data: response?.data?.data };
  };
export const uploadProfilePicture =
  (data: FormData, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/user/profile-picture/${id}`, data)
    );

    if (response?.data?.data?.user) {
      dispatch(updateUserSuccess(response.data.data.user));
    }
    return { success: response.success, data: response?.data?.data };
  };
