import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import TerminalFormContainer from "src/containers/terminals/form";
const Terminals = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Terminals" description="Porto System Terminals" />
      <TerminalFormContainer />
    </Grid>
  );
};

export default Terminals;
