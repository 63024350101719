import {
  featureTerminals,
  featureUsers,
  featureOwners,
  featureDrivers,
  featureLookups,
  featureSettlements,
  featureCustomers,
  featureBilling,
  featureLoad,
  featureSecurity,
  featureVehicles,
  featureTrailers,
  featureLineItems,
  featureDocumentType,
  featureCommodity,
  featureDriverDocument,
} from "src/utils/enums";

export const SidebarItems = [
  {
    title: "sidebar.dashboard",
    icon: "pie_chart",
    href: "/dashboard",
    collapse: true,
    name: "dashboard",
    permissions: [],
  },
  {
    title: "sidebar.load",
    icon: "luggage",
    href: "",
    collapse: true,
    permissions: [],
    children: [
      {
        title: "sidebar.load.add",
        icon: "add",
        href: "/load-management/load-management/create",
        name: featureDrivers,
        permissions: [],

      },

      {
        title: "sidebar.load.search",
        icon: "search",
        href: "/load-management/load-management",
        name: featureOwners,
        permissions: [],

      },
    ],
  },
  {
    title: "sidebar.owner",
    icon: "person",
    href: "",
    collapse: true,
    permissions: [],
    children: [
      {
        title: "sidebar.owner.add",
        icon: "add",
        href: "",
        name: featureDrivers,
        permissions: [],

      },

      {
        title: "sidebar.owner.search",
        icon: "search",
        href: "",
        name: featureOwners,
        permissions: [],

      },
    ],
  },
  {
    title: "sidebar.driver",
    icon: "engineering",
    href: "",
    collapse: true,
    permissions: [],
    children: [
      {
        title: "sidebar.driver.add",
        icon: "add",
        href: "",
        name: featureDrivers,
        permissions: [],

      },

      {
        title: "sidebar.driver.search",
        icon: "search",
        href: "",
        name: featureOwners,
        permissions: [],

      },
    ],
  },
  {
    title: "sidebar.tractor",
    icon: "agriculture",
    href: "",
    collapse: true,
    permissions: [],
    children: [
      {
        title: "sidebar.tractor.add",
        icon: "add",
        href: "",
        name: featureDrivers,
        permissions: [],

      },

      {
        title: "sidebar.tractor.search",
        icon: "search",
        href: "",
        name: featureOwners,
        permissions: [],

      },
    ],
  },
  {
    title: "sidebar.trailer",
    icon: "directions_bus",
    href: "",
    collapse: true,
    permissions: [],
    children: [
      {
        title: "sidebar.trailer.add",
        icon: "add",
        href: "",
        name: featureDrivers,
        permissions: [],

      },

      {
        title: "sidebar.trailer.search",
        icon: "search",
        href: "",
        name: featureOwners,
        permissions: [],

      },
    ],
  },
  {
    title: "sidebar.customer",
    icon: "boy",
    href: "",
    collapse: true,
    permissions: [],
    children: [
      {
        title: "sidebar.customer.add",
        icon: "add",
        href: "",
        name: featureDrivers,
        permissions: [],

      },

      {
        title: "sidebar.customer.search",
        icon: "search",
        href: "",
        name: featureOwners,
        permissions: [],

      },
    ],
  },
  {
    title: "sidebar.settings",
    icon: "settings",
    href: "/settings",
    collapse: true,
    permissions: [],
  },
];

