import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import TrailerForm from "src/components/trailer/form";
import TrailerReadForm from "src/components/trailer/trailerReadForm";
import { TrailerProps } from "src/interfaces/trailer";
import {
  getTrailerById,
  addTrailer,
  updateTrailer,
} from "src/redux/actions/trailer";
import { resetTrailer } from "src/redux/reducers/trailer";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { getAllOwners } from "src/redux/actions/owner";
import { getAllTerminals } from "src/redux/actions/terminal";
import { sanitizeEntity } from "src/utils/common";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { featureTrailers } from "src/utils/enums";

const TrailerReadFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const getLookupsList = async () => {
    setLoading(true);
    await dispatch(getAllLookupsByCategory(featureTrailers, 0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getLookupsList();
  }, []);

  useEffect(() => {
    dispatch(resetTrailer());
    //  dispatch(resetTrailerSafetyState());
    dispatch(getAllOwners(0, 1));
    dispatch(getAllTerminals(0, 1));
  }, []);
  useEffect(() => {
    if (params?.trailerId) {
      getSingleTrailer(parseInt(params?.trailerId));
      //  getSingleTrailerSafety(parseInt(params?.trailerId));
    }
  }, [params?.trailerId]);
  const getSingleTrailer = async (id: number) => {
    setPageLoading(true);
    await dispatch(getTrailerById(id));
    setPageLoading(false);
  };
  /*  const getSingleTrailerSafety = async (id: number) => {
    setPageLoading(true);
    await dispatch(getTrailerSafetyById(id));
    setPageLoading(false);
  }; */

  /* const onSafetySubmit = async (data: TrailerSafetyProps) => {
    setLoading(true);

    let objToSubmit: TrailerSafetyProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.trailerId) {
      //update
      objToSubmit["trailer"] = parseInt(params?.trailerId);
      await dispatch(
        updateTrailerSafety(objToSubmit, parseInt(params?.trailerId))
      );
    }
    setLoading(false);
  }; */
  /* TrailerProps */
  const onSubmit = async (data: any) => {
    setLoading(true);

    let objToSubmit: TrailerProps = { ...data };

    objToSubmit = sanitizeEntity(objToSubmit);
    if (params?.trailerId) {
      //update
      await dispatch(updateTrailer(objToSubmit, parseInt(params?.trailerId)));
    } else {
      //add
      const trailer = await dispatch(addTrailer(objToSubmit));
      if (trailer.success && trailer.data.id) {
        // setTimeout(() => navigate(`/trailer/trailers/${trailer.data.id}`), 500);
        setTimeout(() => navigate(`/trailer/trailers`), 500);
      }
    }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <TrailerReadForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default TrailerReadFormContainer;
