import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  Grid,
  Avatar,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CustomTextField from "src/components/customElements/customTextField";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailRegEx } from "src/utils/constants";
import { UserProps } from "src/interfaces/user";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import StatusBadge from "src/components/common/StatusBadge";
import RoleBadge from "src/components/common/RoleBadge";
import { typedKeys } from "src/utils/common";
import ProfilePictureModal from "src/components/common/pictureModal";
import { uploadProfilePicture } from "src/redux/actions/users";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ResetPasswordContainer from 'src/containers/resetPassword';


const ResetPasswordDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface ResetPasswordDialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function ResetPasswordDialogTitle(props: ResetPasswordDialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface ProfileProps {
  loading: boolean;
  /* onSubmit: (data: UserProps) => any; */
  onSubmit: (data: any) => any;
}

const Profile = ({ loading, onSubmit }: ProfileProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const [openPictureModal, setOpenPictureModal] = useState<boolean>(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = React.useState(false);

  const handleResetPasswordDialogOpen = () => {
    setOpenResetPasswordDialog(true);
  };
  const handleResetPasswordDialogClose = () => {
    setOpenResetPasswordDialog(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };

  const setFormValues = (key: keyof UserProps) => {
    if (user) {
      if (typeof user[key] === "boolean") {
        setValue(key, user[key]);
      } else {
        setValue(key, user[key] ? user[key] : "");
      }
    }
  };

  useEffect(() => {
    if (user) {
      const allAttributes = typedKeys(user);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }
  }, [user]);

  const handleOpenPictureModal = () => {
    setOpenPictureModal(true);
  };
  const handleClosePictureModal = () => {
    setOpenPictureModal(false);
  };
  const handleProfilePictureUpload = async (formData: FormData) => {
    if (user) {
      const response = await dispatch(uploadProfilePicture(formData, user.id));
      return response;
    }
  };

  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          borderRadius: '10px'
        }}
      >
        <Box
          sx={{
            padding: "15px 30px",
          }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {translation("profile.title")}
            </Typography>
          </Box>

          {user?.status && <StatusBadge label={user?.status} />}
          {user?.role?.name && <RoleBadge label={user?.role?.name} />}
        </Box>
        <Divider />
        <CardContent
          sx={{
            padding: "10px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{
                    padding: "5px 20px",
                  }}
                >
                  <Avatar
                    src={
                      user?.profilePicture
                        ? user?.profilePicture.includes("porto-files-upload")
                          ? user?.profilePicture
                          : `${process.env.REACT_APP_BASE_URL}/${user.profilePicture}`
                        : ""
                    }
                    alt={"userimg"}
                    sx={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={handleOpenPictureModal}
                  />
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={6}
                lg={6}
              >
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      fullWidth
                      id="firstName"
                      label={translation("common.firstName.label")}
                      error={Boolean(errors?.firstName)}
                      helperText={
                        errors?.firstName &&
                        translation("common.firstName.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={6}
                lg={6}
              >
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      fullWidth
                      id="lastName"
                      label={translation("common.lastName.label")}
                      error={Boolean(errors?.lastName)}
                      helperText={
                        errors?.lastName &&
                        translation("common.lastName.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={6}
                lg={6}
                className={classes.phoneField}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: true,
                    validate: {
                      isValid: () =>
                        watch("phoneNumberValue")
                          ? phone(watch("phoneNumberValue"), {
                            country: watch("phoneNumberCountry"),
                          })?.phoneNumber !== null ||
                          "Enter Valid Phone Number"
                          : true,
                    },
                  }}
                  render={() => (
                    <PhoneInput
                      label={translation("common.phone.label")}
                      placeholder={translation("common.phone.label")}
                      value={watch("phoneNumber")}
                      error={Boolean(errors["phoneNumber"])}
                      onChange={(value: string, data: any) => {
                        onChangePhoneInput(
                          value,
                          data.countryCode,
                          "phoneNumber"
                        );
                      }}
                      country="us"
                      isValid={(value: any, country: any) => {
                        return errors["phoneNumber"]
                          ? validatePhoneNumber(value, country.iso2)
                          : true;
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={6}
                lg={6}
                className={classes.phoneField}
              >
                <Controller
                  name="altPhoneNumber"
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: true,
                    validate: {
                      isValid: () =>
                        watch("altPhoneNumberValue")
                          ? phone(watch("altPhoneNumberValue"), {
                            country: watch("altPhoneNumberCountry"),
                          })?.phoneNumber !== null ||
                          "Enter Valid Phone Number"
                          : true,
                    },
                  }}
                  render={() => (
                    <PhoneInput
                      label={translation("common.altPhoneNumber.label")}
                      placeholder={translation("common.altPhoneNumber.label")}
                      value={watch("altPhoneNumber")}
                      error={Boolean(errors["altPhoneNumber"])}
                      onChange={(value: string, data: any) => {
                        onChangePhoneInput(
                          value,
                          data.countryCode,
                          "altPhoneNumber"
                        );
                      }}
                      country="us"
                      isValid={(value: any, country: any) => {
                        return errors["altPhoneNumber"]
                          ? validatePhoneNumber(value, country.iso2)
                          : true;
                      }}
                    />
                  )}
                />
              </Grid>


              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={4}
                lg={4}
              >
                <Controller
                  name="zip"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      fullWidth
                      id="zip"
                      type="number"
                      inputProps={{ min: 0 }}
                      label={translation("common.zip.label")}
                      error={Boolean(errors?.zip)}
                      helperText={
                        errors?.zip && translation("common.zip.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={4}
                lg={4}
              >
                <Controller
                  name="city"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      fullWidth
                      id="city"
                      label={translation("common.city.label")}
                      error={Boolean(errors?.city)}
                      helperText={
                        errors?.city && translation("common.city.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={6}
                xs={12}
                md={4}
                lg={4}
              >
                <Controller
                  name="state"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      fullWidth
                      id="state"
                      label={translation("common.state.label")}
                      error={Boolean(errors?.state)}
                      helperText={
                        errors?.state && translation("common.state.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                sx={{
                  padding: "5px",
                }}
                sm={12}
                xs={12}
                md={12}
                lg={12}
              >
                <Controller
                  name="address"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      size="small"
                      fullWidth
                      id="address"
                      multiline
                      rows={3}
                      label={translation("common.address.label")}
                      error={Boolean(errors?.address)}
                      helperText={
                        errors?.address && translation("common.address.message")
                      }
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="end">
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          </form>
        </CardContent>
        <ProfilePictureModal
          handleClose={handleClosePictureModal}
          open={openPictureModal}
          loading={loading}
          handlePictureUpload={handleProfilePictureUpload}
          title={translation("profile.modal.title")}
          pictureURL={
            user?.profilePicture
              ? user?.profilePicture.includes("porto-files-upload")
                ? user?.profilePicture
                : `${process.env.REACT_APP_BASE_URL}/${user.profilePicture}`
              : ""
          }
        />
      </Card>

    </div>
  );
};

export default Profile;
