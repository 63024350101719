import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { useNavigate } from "react-router-dom";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllCustomers } from "src/redux/actions/customer";
import { selectCustomers } from "src/redux/reducers/customer";
import { useTranslation } from "react-i18next";
import { featureCustomers, create, update, find } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { Clipboard } from "ts-react-feather-icons";
import { getGridStringOperators } from "@mui/x-data-grid";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Customers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const customers = useAppSelector(selectCustomers);
  const [customerData, setCustomerData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const user = useAppSelector(selectUser);

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const customerPermissions =
    allUserPermissions && allUserPermissions[featureCustomers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : customerPermissions.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : customerPermissions.findIndex((item) => item === update);
  // const findPermission =
  //   user?.role?.name?.toLocaleLowerCase() === admin
  //     ? 0
  //     : customerPermissions?.findIndex((item) => item === find);

  const getCustomersList = async () => {
    setLoading(true);
    await dispatch(
      getAllCustomers(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getCustomersListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllCustomers(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };

  /*  function pageExists(page: any) {
    return customerData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getCustomersListWithFilter();
    } else {
      getCustomersList();
    }
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    getCustomersListWithFilter();
  }, [filter]);
  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (customers?.data?.length > 0) {
      setCustomerData({ ...customerData?.customers, customers });
    } else {
      setCustomerData({});
    }
  }, [customers]);

  const addCustomer = () => {
    navigate("/customer/customer/create");
  };
  const searchCustomers = () => {
    navigate("/customer/customer/search");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );
  const columns = [
    updatePermission > -1 && {
      field: "actions",
      minWidth: 150,
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={<Edit />}
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/customer/customer/${params.row.id}`);
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/customer/customer/documents/${params.row.id}?entity=${featureCustomers}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<Clipboard />}
        //   label="Details"
        //   key="details"
        //   onClick={() => {
        //     navigate(`/customer/customer/details/${params.row.id}`);
        //     // navigate(`/drivers/drivers/details/${params.row.id}`);
        //   }}
        // />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "code",
      headerName: translation("customer.list.code.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "companyName",
      headerName: translation("customer.list.companyName.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "email",
      headerName: translation("common.email.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "website",
      headerName: translation("customer.list.website.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "phoneNumber",
      headerName: translation("common.phone.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "tollFreePhoneNumber",
      headerName: translation("customer.list.tollFreePhoneNumber.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "faxPhoneNumber",
      headerName: translation("common.faxPhoneNumber.label"),
      minWidth: 150,
      filterable: false,
    },

    {
      field: "businessHour",
      headerName: translation("customer.list.businessHour.label"),
      minWidth: 150,
      filterable: false,
    },

    {
      field: "terminal",
      headerName: translation("customer.list.terminal.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.terminal?.title}</div>;
      },
    },
    {
      field: "dispatchNote",
      headerName: translation("customer.list.dispatchNote.label"),
      minWidth: 250,
      filterable: false,
    },
  ];
  const navigateToCustomerDetail = (id: number) => {
    navigate(
      `/customer/customer/details/${id}?entity=${featureCustomers}&referenceId=${id}`
    );
  };
  const onRowClick = (params: GridRowParams) => {
    navigate(params.row.id);
  };

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("customer.list.title")}
        rows={customerData?.customers?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addCustomer}
        onClickSearch={searchCustomers}
        createPermission={createPermission > -1}
        rowsState={rowsState}
        onRowClick={onRowClick}
        setRowsState={setRowsState}
        pagination={customers.pagination}
        data={customerData?.customers}
        setData={setCustomerData}
        add={true}
        setFilter={setFilter}
        search={"customer"}
      />
    </CustomFormGrid>
  );
}
