import React, { useEffect } from "react";
import DashboardContainer from "src/containers/dashboard";
import Head from "src/components/head";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { showNotification } from "src/redux/reducers/notifications";
import { selectCompany } from "src/redux/reducers/auth";


const Dashboard = () => {

  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);


  useEffect(() => {
    console.log(company)
    if (!company?.isVerified || !company.name || !company.phone || !company.url || !company.city) {
      dispatch(showNotification({
        message: "Your profile is in-complete or not verified, Please complete your profile or contact support for more help.",
        open: true,
        severity: "info"
      }))
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Dashboard" description="Porto dashboard" />
      <DashboardContainer />
    </React.Fragment>
  );
};

export default Dashboard;
