import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import VehicleContainer from "src/containers/vehicle";
const Vehicles = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Vehicles" description="Porto Vehicles" />
      <VehicleContainer />
    </Grid>
  );
};

export default Vehicles;
