import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { VehicleTrailerProps } from "src/interfaces/vehicle";

export interface VehicleTrailerState {
  VehiclesTrailerList: Array<VehicleTrailerProps>;
  singleVehicleTrailer: VehicleTrailerProps | null;
}

const initialState: VehicleTrailerState = {
  VehiclesTrailerList: [],
  singleVehicleTrailer: null,
};

export const vehiclesTrailerSlice = createSlice({
  name: "vehicle_trailer",
  initialState,

  reducers: {
    setAllVehiclesTrailer: (
      state,
      action: PayloadAction<Array<VehicleTrailerProps>>
    ) => {
      state.VehiclesTrailerList = action.payload;
    },
    setSingleVehicleTrailer: (
      state,
      action: PayloadAction<VehicleTrailerProps>
    ) => {
      state.singleVehicleTrailer = action.payload;
    },
    resetVehicleTrailer: (state) => {
      state.singleVehicleTrailer = null;
    },
    resetVehicleTrailerState: (state) => {
      state.singleVehicleTrailer = null;
      state.VehiclesTrailerList = [];
    },
  },
});
export const {
  setAllVehiclesTrailer,
  setSingleVehicleTrailer,
  resetVehicleTrailer,
  resetVehicleTrailerState,
} = vehiclesTrailerSlice.actions;
export const selectVehiclesTrailer = (
  state: RootState
): Array<VehicleTrailerProps> => state.vehiclesTrailer.VehiclesTrailerList;
export const selectVehicleTrailer = (
  state: RootState
): VehicleTrailerProps | null => state.vehiclesTrailer.singleVehicleTrailer;
export default vehiclesTrailerSlice.reducer;
