import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  DriverProps,
  DocumentProps,
  DriverVehicleProps,
//  DriverOwnerProps,
} from "src/interfaces/driver";

export interface DriverState {
  driversList: any;
  availableDrivers: any;
  singleDriver: DriverProps | null;
  documentsList: any;
  progress: number;
  linkedVehicle: DriverVehicleProps | null;
  linkedOwner: any;
}

const initialState: DriverState = {
  driversList: {},
  availableDrivers: {},
  singleDriver: null,
  documentsList: {},
  progress: 0,
  linkedVehicle: null,
  linkedOwner: {},
};

export const driversSlice = createSlice({
  name: "drivers",
  initialState,

  reducers: {
    setAllDrivers: (state, action: PayloadAction<Array<DriverProps>>) => {
      state.driversList = action.payload;
    },
    setSingleDriver: (state, action: PayloadAction<DriverProps>) => {
      state.singleDriver = action.payload;
      state.linkedVehicle =
        action.payload.driverVehicleLink && action.payload.driverVehicleLink[0];
      state.linkedOwner =
        action.payload.ownerDriverLink && action.payload.ownerDriverLink[0];
    },
    resetDriver: (state) => {
      state.singleDriver = null;
    },
    setAllDriverDocuments: (
      state,
      action: PayloadAction<Array<DocumentProps>>
    ) => {
      state.documentsList = action.payload;
    },
    appendDriverDocumentsList: (
      state,
      action: PayloadAction<DocumentProps>
    ) => {
      state.documentsList = [...state.documentsList, action.payload];
      state.progress = 0;
    },
    filterDriverDocumentsList: (state, action: PayloadAction<number>) => {
      const _list = state.documentsList.filter(
        (item: any) => item.id !== action.payload
      );
      state.documentsList = [..._list];
    },
    updateDriverDocumentsList: (
      state,
      action: PayloadAction<{ data: DocumentProps; id: number }>
    ) => {
      const _list = [...state.documentsList];
      const index = _list.findIndex((item) => item.id === action.payload.id);
      if (index > -1) {
        state.documentsList[index] = { ...action.payload.data };
      }
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    resetUploadProgress: (state) => {
      state.progress = 0;
    },
    addLinkedVehicle: (state, action: PayloadAction<DriverVehicleProps>) => {
      state.linkedVehicle = action.payload;
    },
    removeLinkedVehicle: (state) => {
      state.linkedVehicle = null;
    },
    addLinkedOwner: (state, action: PayloadAction<any>) => {
      state.linkedOwner = action.payload;
    },
    removeLinkedOwner: (state) => {
      state.linkedOwner = null;
    },
    setAllAvailableDrivers: (
      state,
      action: PayloadAction<Array<DriverProps>>
    ) => {
      state.availableDrivers = action.payload;
    },
    resetDriverState: (state) => {
      state.singleDriver = null;
      state.driversList = [];
      state.documentsList = [];
      state.availableDrivers = {};
      state.linkedVehicle = null;
      state.linkedOwner = null;
    },
  },
});
export const {
  setAllDrivers,
  setSingleDriver,
  resetDriver,
  resetDriverState,
  setAllDriverDocuments,
  updateDriverDocumentsList,
  appendDriverDocumentsList,
  setUploadProgress,
  filterDriverDocumentsList,
  resetUploadProgress,
  addLinkedVehicle,
  removeLinkedVehicle,
  setAllAvailableDrivers,
  addLinkedOwner,
  removeLinkedOwner,
} = driversSlice.actions;
export const selectDrivers = (state: RootState): any =>
  state.drivers.driversList;
export const selectDriver = (state: RootState): DriverProps | null =>
  state.drivers.singleDriver;
export const selectDocuments = (state: RootState): any =>
  state.drivers.documentsList;
export const selectUploadProgress = (state: RootState): number =>
  state.drivers.progress;
export const selectLinkedVehicle = (
  state: RootState
): DriverVehicleProps | null => state.drivers.linkedVehicle;
export const selectLinkedOwner = (state: RootState):any =>
  state.drivers.linkedOwner;
export const selectAvailableDrivers = (state: RootState): any =>
  state.drivers.availableDrivers;
export default driversSlice.reducer;
