import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { DriverProps, billingInformationProps } from "src/interfaces/driver";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectDriver } from "src/redux/reducers/driver";
import StatusBadge from "src/components/common/StatusBadge";
import { selectTerminals } from "src/redux/reducers/terminal";
import Collapsible from "src/components/collapsibleForm";
import PersonalInformation from "./personalInfo";
import ContactInfo from "./contactInfo";
import DriverData from "./driverData";
import AddressInfo from "src/components/common/address";
import CDLInfo from "./cdlInfo";
import BillingAddress from "../common/billings";
import BillingInformation from "./billingInformation";
import DispatchInfo from "./dispatchInfo";
import AdditionalInfo from "./additionalInfo";
import { typedKeys } from "src/utils/common";
import { UserProps } from "src/interfaces/user";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useNavigate } from "react-router-dom";
import AssignedVehicleInfo from "src/containers/drivers/driver_vehicle_linking";
import DriverOwnerInfo from "src/containers/drivers/driverOwnerLinking";
import DriverDocuments from "src/containers/documents";
import { ArrowBack } from "@mui/icons-material";
import { selectUser } from "src/redux/reducers/auth";
import { driver as driverEnum } from "src/utils/enums";
interface DriverFormProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}
const DriverForm = ({
  loading,
  pageLoading,
  onSubmit,
}: DriverFormProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [translation] = useTranslation("translation");
  const params = useParams();
  const user = useAppSelector(selectUser);

  const driverId =
    user?.role?.name?.toLocaleLowerCase() === driverEnum
      ? `${user?.driver?.id}`
      : params?.driverId;
  const driver = useAppSelector(selectDriver);
  const terminals = useAppSelector(selectTerminals);
  const [driverMedicalArray, setDriverMedicalArray] = useState<Array<number>>(
    []
  );
  const [trailerQualificationArray, setTrailerQualificationArray] = useState<
    Array<number>
  >([]);
  const [dispatcher, setDispatcher] = useState<UserProps | null>(null);
  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "personalInfoPanel",
    "contactInfoPanel",
    "addressInfoPanel",
    "dispatchInfoPanel",
    "additionalInfoPanel",
    "BillingAddress",
    "BillingInformation",
    "cdlInfoPanel",
    "driverVehicle",
    "driverDocuments",
    "driverOwner",
    "errors",
  ]);
  const linkingAddress = location.pathname.includes("details");
  const [disabled, setDisabled] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const handleDispatcherChange = (event: any, newValue: UserProps | null) => {
    setDispatcher(newValue);
    setValue("dispatcher", newValue?.id);
  };

  const setFormValues = (key: keyof DriverProps) => {
    if (driver) {
      if (typeof driver[key] === "boolean") {
        setValue(key, driver[key]);
      } else {
        setValue(key, driver[key] ? driver[key] : "");
      }
    }
  };
  const setBillingInfoValues = (
    object: billingInformationProps,
    name: keyof DriverProps
  ) => {
    if (object != null && Object.keys(object).length != 0) {
      const allAttributes = typedKeys(object);
      for (const attribute of allAttributes) {
        if (typeof object[attribute] === "boolean") {
          setValue(`${name}.${attribute}`, object[attribute]);
        } else {
          setValue(
            `${name}.${attribute}`,
            object[attribute] ? object[attribute] : ""
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!driverId) {
      setValue(
        "terminal",
        user?.tenant?.defaultTerminal ? user?.tenant?.defaultTerminal?.id : ""
      );
    }
  }, []);

  useEffect(() => {
    if (linkingAddress) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [linkingAddress]);

  useEffect(() => {
    if (driverId && driver) {
      const allAttributes = typedKeys(driver);
      for (const attribute of allAttributes) {
        if (attribute === "billingInformation") {
          setBillingInfoValues(driver?.billingInformation, attribute);
        } else if (attribute === "billingAddress") {
          setValue(
            "billingName",
            driver?.billingAddress?.billingName
              ? driver?.billingAddress?.billingName
              : ""
          );
          setValue(
            "billingEmail",
            driver?.billingAddress?.billingEmail
              ? driver?.billingAddress?.billingEmail
              : ""
          );
          setValue(
            "billingPhoneNumber",
            driver?.billingAddress?.billingPhoneNumber
              ? driver?.billingAddress?.billingPhoneNumber
              : ""
          );
          setValue(
            "billingAltPhoneNumber",
            driver?.billingAddress?.billingAltPhoneNumber
              ? driver?.billingAddress?.billingAltPhoneNumber
              : ""
          );
          setValue(
            "billingFaxPhoneNumber",
            driver?.billingAddress?.billingFaxPhoneNumber
              ? driver?.billingAddress?.billingFaxPhoneNumber
              : ""
          );
          setValue(
            "addressBilling",
            driver?.billingAddress?.addressBilling
              ? driver?.billingAddress?.addressBilling
              : ""
          );
          setValue(
            "billingCity",
            driver?.billingAddress?.billingCity
              ? driver?.billingAddress?.billingCity
              : ""
          );
          setValue(
            "billingZip",
            driver?.billingAddress?.billingZip
              ? driver?.billingAddress?.billingZip
              : ""
          );
          setValue(
            "billingState",
            driver?.billingAddress?.billingState
              ? driver?.billingAddress?.billingState
              : ""
          );
        } else if (attribute === "terminal") {
          if (driver?.terminal && typeof driver?.terminal === "object") {
            setValue(
              "terminal",
              driver?.terminal.id ? driver?.terminal.id : ""
            );
          } else {
            setValue("terminal", driver?.terminal ? driver?.terminal : "");
          }
        } else if (attribute === "dispatcher") {
          if (driver?.dispatcher && typeof driver?.dispatcher === "object") {
            setDispatcher(driver?.dispatcher);
            setValue(
              "dispatcher",
              driver?.dispatcher.id ? driver?.dispatcher.id : ""
            );
            setValue(
              "dispatcher",
              driver?.dispatcher ? driver?.dispatcher : ""
            );
          }
        } else if (attribute === "dispatchGroup") {
          if (
            driver?.dispatchGroup &&
            typeof driver?.dispatchGroup === "object"
          ) {
            setValue(
              "dispatchGroup",
              driver?.dispatchGroup.id ? driver?.dispatchGroup.id : ""
            );
          } else {
            setValue(
              "dispatchGroup",
              driver?.dispatchGroup ? driver?.dispatchGroup : ""
            );
          }
        } else if (attribute === "race") {
          if (driver?.race && typeof driver?.race === "object") {
            setValue("race", driver?.race.id ? driver?.race.id : "");
          } else {
            setValue("race", driver?.race ? driver?.race : "");
          }
        } else if (attribute === "driverGroup") {
          if (driver?.driverGroup && typeof driver?.driverGroup === "object") {
            setValue(
              "driverGroup",
              driver?.driverGroup.id ? driver?.driverGroup.id : ""
            );
          } else {
            setValue(
              "driverGroup",
              driver?.driverGroup ? driver?.driverGroup : ""
            );
          }
        } else if (attribute === "eobrType") {
          if (driver?.eobrType && typeof driver?.eobrType === "object") {
            setValue(
              "eobrType",
              driver?.eobrType.id ? driver?.eobrType.id : ""
            );
          } else {
            setValue("eobrType", driver?.eobrType ? driver?.eobrType : "");
          }
        } else if (attribute === "trailerQualification") {
          if (driver.trailerQualification) {
            const trailerQualificationArray = [];
            for (const trailerQualification of driver.trailerQualification) {
              trailerQualificationArray.push(
                trailerQualification.qualification.id
              );
            }

            setTrailerQualificationArray([...trailerQualificationArray]);
            setValue("qualification", [...trailerQualificationArray]);
          }
        } else if (attribute === "driverMedical") {
          if (driver.driverMedical) {
            const _medicalArray = [];
            for (const driverMedical of driver.driverMedical) {
              _medicalArray.push(driverMedical.medical.id);
            }
            setDriverMedicalArray([..._medicalArray]);
            setValue("medical", [..._medicalArray]);
          }
        } else {
          setFormValues(attribute);
        }
      }
    }
  }, [driverId, driver, terminals]);

  useEffect(() => {
    if (watch("isSameBillingAddress")) {
      setValue(
        "billingName",
        watch("firstName") && watch("lastName")
          ? watch("firstName") + " " + watch("lastName")
          : ""
      );
      setValue("billingEmail", watch("email") ? watch("email") : "");
      setValue(
        "billingPhoneNumber",
        watch("cellPhoneNumber") ? watch("cellPhoneNumber") : ""
      );
      setValue(
        "billingAltPhoneNumber",
        watch("homePhoneNumber") ? watch("homePhoneNumber") : ""
      );
      setValue(
        "billingFaxPhoneNumber",
        watch("faxPhoneNumber") ? watch("faxPhoneNumber") : ""
      );
      setValue("addressBilling", watch("address") ? watch("address") : "");
      setValue("billingCity", watch("city") ? watch("city") : "");
      setValue("billingState", watch("state") ? watch("state") : "");
      setValue("billingZip", watch("zip") ? watch("zip") : "");
      delete errors["billingName"];
      delete errors["billingEmail"];
      delete errors["addressBilling"];
      delete errors["billingCity"];
      delete errors["billingZip"];
      delete errors["billingState"];
      delete errors["billingPhoneNumber"];
      delete errors["billingAltPhoneNumber"];
      delete errors["billingFaxPhoneNumber"];
    } else {
      setValue(
        "billingName",
        driver?.billingAddress?.billingName
          ? driver?.billingAddress?.billingName
          : ""
      );
      setValue(
        "billingEmail",
        driver?.billingAddress?.billingEmail
          ? driver?.billingAddress?.billingEmail
          : ""
      );
      setValue(
        "billingPhoneNumber",
        driver?.billingAddress?.billingPhoneNumber
          ? driver?.billingAddress?.billingPhoneNumber
          : ""
      );
      setValue(
        "billingAltPhoneNumber",
        driver?.billingAddress?.billingAltPhoneNumber
          ? driver?.billingAddress?.billingAltPhoneNumber
          : ""
      );
      setValue(
        "billingFaxPhoneNumber",
        driver?.billingAddress?.billingFaxPhoneNumber
          ? driver?.billingAddress?.billingFaxPhoneNumber
          : ""
      );
      setValue(
        "addressBilling",
        driver?.billingAddress?.addressBilling
          ? driver?.billingAddress?.addressBilling
          : ""
      );
      setValue(
        "billingCity",
        driver?.billingAddress?.billingCity
          ? driver?.billingAddress?.billingCity
          : ""
      );
      setValue(
        "billingZip",
        driver?.billingAddress?.billingZip
          ? driver?.billingAddress?.billingZip
          : ""
      );
      setValue(
        "billingState",
        driver?.billingAddress?.billingState
          ? driver?.billingAddress?.billingState
          : ""
      );
    }
  }, [watch("isSameBillingAddress"), driver]);
  useEffect(() => {
    setValue("medical", driverMedicalArray);
  }, [driverMedicalArray]);
  const handleSelectMedical = (e: any) => {
    const medical = [...driverMedicalArray];
    if (e.target.checked) {
      setDriverMedicalArray([...medical, parseInt(e.target.value)]);
    } else {
      const index = medical.findIndex(
        (item) => item === parseInt(e.target.value)
      );
      if (index > -1) {
        medical.splice(index, 1);
      }
      setDriverMedicalArray([...medical]);
    }
  };
  useEffect(() => {
    setValue("qualification", trailerQualificationArray);
  }, [trailerQualificationArray]);

  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);

  const handleSelectTrailerQualifications = (e: any) => {
    const trailer = [...trailerQualificationArray];
    if (e.target.checked) {
      setTrailerQualificationArray([...trailer, parseInt(e.target.value)]);
    } else {
      const index = trailer.findIndex(
        (item) => item === parseInt(e.target.value)
      );
      if (index > -1) {
        trailer.splice(index, 1);
      }
      setTrailerQualificationArray([...trailer]);
    }
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = [
      "personalInfoPanel",
      "contactInfoPanel",
      "addressInfoPanel",
      "dispatchInfoPanel",
      "additionalInfoPanel",
      "BillingAddress",
      "BillingInformation",
      "cdlInfoPanel",
      "driverVehicle",
      "driverDocuments",
      "errors",
    ];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("driver.form.title")}
              </Typography>
              {driver?.status && <StatusBadge label={driver?.status} />}
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : driverId ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() => navigate(-1)}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>

      {pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
        <CustomFormGrid container sx={{ width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
                <CustomFormGrid container>
                  {/*****************Column 1 ****************/}
                  <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={classes.formContainer}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={classes.loadFormHeadings}>
                            {translation("common.personalInformation.label")}
                          </Typography>
                          <PersonalInformation
                            control={control}
                            disabled={disabled}
                            errors={errors}
                            watch={watch}
                          />
                        </CardContent>
                      </Card>
                    </CustomFormGrid>
                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={classes.formContainer}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={classes.loadFormHeadings}>
                            {translation("common.contactInformation.label")}
                          </Typography>
                          <ContactInfo
                            control={control}
                            disabled={disabled}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                          />
                        </CardContent>
                      </Card>
                    </CustomFormGrid>
                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={classes.formContainer}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={classes.loadFormHeadings}>
                            {translation("common.address.label")}
                          </Typography>
                          <AddressInfo
                            control={control}
                            disabled={disabled}
                            errors={errors}
                            setValue={setValue}
                          />
                        </CardContent>
                      </Card>
                    </CustomFormGrid>

                    {watch("companyDriver") && (
                      <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                        <Card className={classes.formContainer}>
                          <CardContent style={{ paddingBottom: "2px" }}>
                            <Typography className={classes.loadFormHeadings}>
                              {translation("common.billing.label")}
                            </Typography>
                            <BillingAddress
                              control={control}
                              disabled={disabled}
                              errors={errors}
                              loading={loading}
                              watch={watch}
                              setValue={setValue}
                            />
                          </CardContent>
                        </Card>
                      </CustomFormGrid>
                    )}

                    {watch("companyDriver") && (
                      <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                        <Card className={classes.formContainer}>
                          <CardContent style={{ paddingBottom: "2px" }}>
                            <Typography className={classes.loadFormHeadings}>
                              {translation("common.billingInfo.label")}
                            </Typography>
                            <BillingInformation
                              control={control}
                              errors={errors}
                              disabled={disabled}
                            />
                          </CardContent>
                        </Card>
                      </CustomFormGrid>
                    )}
                  </CustomFormGrid>

                  {/*****************Column 2 ****************/}
                  <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={classes.formContainer}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={classes.loadFormHeadings}>
                            {translation("driver.cdl.label")}
                          </Typography>
                          <CDLInfo
                            control={control}
                            disabled={disabled}
                            errors={errors}
                            watch={watch}
                          />
                        </CardContent>
                      </Card>
                    </CustomFormGrid>
                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={classes.formContainer}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={classes.loadFormHeadings}>
                            {translation("common.dispatchInformation.label")}
                          </Typography>
                          <DispatchInfo
                            control={control}
                            disabled={disabled}
                            errors={errors}
                            handleDispatcherChange={handleDispatcherChange}
                            dispatcher={dispatcher}
                            linkingAddress={linkingAddress}
                          />
                        </CardContent>
                      </Card>
                    </CustomFormGrid>
                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <Card className={classes.formContainer}>
                        <CardContent style={{ paddingBottom: "2px" }}>
                          <Typography className={classes.loadFormHeadings}>
                            {translation("common.additionalInformation.label")}
                          </Typography>
                          <AdditionalInfo
                            control={control}
                            disabled={disabled}
                            errors={errors}
                            handleSelectMedical={handleSelectMedical}
                            handleSelectTrailerQualifications={
                              handleSelectTrailerQualifications
                            }
                            trailerQualificationArray={
                              trailerQualificationArray
                            }
                            driverMedicalArray={driverMedicalArray}
                          />
                        </CardContent>
                      </Card>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomForm>
              {/*    <CustomFormGrid
                    sx={{ padding: "0px", margin: "0px" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    {linkingAddress && (
                      <Collapsible
                        title={translation("driver.documents.title")}
                        handleChange={handleChange}
                        expanded={expandedPanels.includes("driverDocuments")}
                        id="driverDocuments"
                        ComponentToRender={<DriverDocuments />}
                      />
                    )}
                  </CustomFormGrid> */}
              {/*   <CustomFormGrid
                sx={{ padding: "0px", margin: "0px" }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                {linkingAddress && (
                  <Collapsible
                    title={translation("driverOwner.list.title")}
                    handleChange={handleChange}
                    expanded={expandedPanels.includes("driverOwner")}
                    id="driverOwner"
                    ComponentToRender={<DriverOwnerInfo />}
                  />
                )}
                {linkingAddress && (
                  <Collapsible
                    title={translation("driverVehicle.list.title")}
                    handleChange={handleChange}
                    expanded={expandedPanels.includes("driverVehicle")}
                    id="driverVehicle"
                    ComponentToRender={<AssignedVehicleInfo />}
                  />
                )}
              </CustomFormGrid> */}
            </CustomFormGrid>
          </LocalizationProvider>
        </CustomFormGrid>
      )}
    </div>
  );
};

export default DriverForm;
