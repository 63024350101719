import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Avatar,
  Theme,
  Zoom,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/styles/formStyles";
import CustomFormGrid from "../customElements/customFormGrid";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef<HTMLInputElement, any>((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

interface ProfilePictureModalProps {
  open: boolean;
  handleClose: () => void;
  handlePictureUpload: (file: FormData) => any;
  pictureURL: string;
  title: string;
  loading: boolean;
}

const ProfilePictureModal = ({
  open,
  handleClose,
  handlePictureUpload,
  pictureURL,
  title,
  loading,
}: ProfilePictureModalProps): JSX.Element => {
  const inputFileRef = React.useRef<any>();
  const [translation] = useTranslation("translation");
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const [picturePreview, setPicturePreview] = useState<string>("");
  const [picture, setPicture] = useState<File | null>(null);
  const classes = useStyles();
  useEffect(() => {
    if (!open) {
      setPicturePreview("");
    }
  }, [open]);
  const selectImage = () => {
    if (inputFileRef?.current) {
      inputFileRef?.current?.click();
    }
  };
  const onFileChangeCapture = (e: any) => {
    if (e.target.files[0]) {
      const objectURL = URL.createObjectURL(e.target.files[0]);
      setPicturePreview(objectURL);
      setPicture(e.target.files[0]);
    }
  };
  const handleUploadPicture = async () => {
    if (picture) {
      const formData = new FormData();
      formData.append("file", picture);
      const response = await handlePictureUpload(formData);
      if (response.success) {
        setPicture(null);
        handleClose();
      }
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                padding: "5px 20px",
              }}
            >
              <input
                type="file"
                accept="image/*"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                style={{ display: "none" }}
              />

              <Avatar
                src={
                  picturePreview ? picturePreview : pictureURL ? pictureURL : ""
                }
                alt={"userimg"}
                sx={{
                  width: lgUp ? "400px" : "200px",
                  height: lgUp ? "400px" : "200px",
                  cursor: "pointer",
                }}
                onClick={selectImage}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomFormGrid container display="flex" justifyContent="space-between">
          <CustomFormGrid item>
            <Button onClick={selectImage}>
              {translation("common.btn.choosePicture")}
            </Button>
          </CustomFormGrid>
          {picture && (
            <CustomFormGrid item>
              <Button
                color="primary"
                variant="contained"
                className={classes.formButton}
                onClick={handleUploadPicture}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                ) : (
                  translation("common.form.update")
                )}
              </Button>
              <Button
                color="error"
                variant="contained"
                className={classes.formButton}
                onClick={() => handleClose()}
              >
                {translation("common.btn.cancel")}
              </Button>
            </CustomFormGrid>
          )}
        </CustomFormGrid>
      </DialogActions>
    </Dialog>
  );
};

export default ProfilePictureModal;
