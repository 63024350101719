import React from "react";
import SignupContainer from "src/containers/signup";
import Head from "src/components/head";

const Signup = (): JSX.Element => {
  return (
    <>
      <Head title="Signup" description="get started with Porto" />
      <SignupContainer />
    </>
  );
};

export default Signup;
