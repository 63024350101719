import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import CustomGrid from "src/components/customElements/customFormGrid";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useStyles } from "src/styles/formStyles";

interface FromInputProps {
  heading: any;
  input: any;
  checkColor: boolean;
}

const formInput = ({
  heading,
  input,
  checkColor,
}: FromInputProps): JSX.Element => {
  const [inputColor, setInputColor] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (checkColor === true) {
      setInputColor("#0096FF");
    } else {
      setInputColor("#000000");
    }
  }, []);

  return (
    <CustomGrid
      container
      style={{ padding: "0px", margin: "0px", height: "auto" }}
    >
      <CustomGrid xs={6} sm={6} md={6} lg={6}>
        <Typography
          style={{}}
          className={classes.readFormLabel}
          variant="h6"
          component="h4"
          sx={{ pl: 1 }}
        >
          {heading}
        </Typography>
      </CustomGrid>
      <CustomGrid xs={6} sm={6} md={6} lg={6}>
        <Typography
          className={
            checkColor === true
              ? classes.readFormTextBlue
              : classes.readFormsText
          }
          /*   style={{
            color: inputColor,
            wordWrap: "break-word",
            whiteSpace: "initial",
          }} */
          component="h4"
          variant="h6"
          sx={{ pl: 1 }}
        >
          {input ? input : "---"}
        </Typography>
      </CustomGrid>
    </CustomGrid>
  );
};

export default formInput;
