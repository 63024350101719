import React, { useEffect, useState } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { selectDriver } from "src/redux/reducers/driver";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { DriverVehicleProps } from "src/interfaces/driver";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";
import {
  addDriverVehicle,
  deleteDriverVehicleLink,
} from "src/redux/actions/driver_vehicle";
import ConfirmationDialog from "src/components/common/confirmation";
import { getAllAvailableVehicles } from "src/redux/actions/vehicle";
import DriverVehicleDialogBox from "src/components/common/driverVehicleDialog";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid, IconButton } from "@mui/material";
import { selectLinkedVehicle } from "src/redux/reducers/driver";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import { useStyles } from "src/styles/formStyles";
import { selectUser } from "src/redux/reducers/auth";
import { driver as driverEnum } from "src/utils/enums";
import AssignedLinkingCard from "src/components/common/linkingCards/assignedVehicle";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function DriverVehicle() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const user = useAppSelector(selectUser);
  const driverId =
    user?.role?.name?.toLocaleLowerCase() === driverEnum
      ? `${user?.driver?.id}`
      : params?.driverId;
  const isDriver = user?.role?.name?.toLocaleLowerCase() === driverEnum;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const driver = useAppSelector(selectDriver);
  const linkedVehicle = useAppSelector(selectLinkedVehicle);

  const vehicle =
    linkedVehicle && typeof linkedVehicle.vehicle === "object"
      ? linkedVehicle.vehicle
      : null;
  const [translation] = useTranslation("translation");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const getAvailableVehicleList = async () => {
    setLoading(true);
    const filterObject: any = {};
    filterObject.items = [
      {
        columnField: "status",
        operatorValue: "equals",
        value: "active",
      },
    ];
    await dispatch(getAllAvailableVehicles(0, 1, filterObject));
    setLoading(false);
  };
  useEffect(() => {
    getAvailableVehicleList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleVehicleDelete = () => {
    handleOpenDeleteModal();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (driverId) {
      setLoading(true);
      if (linkedVehicle) {
        const object = {
          vehicle:
            typeof linkedVehicle?.vehicle === "object"
              ? linkedVehicle?.vehicle?.id
                ? linkedVehicle?.vehicle?.id
                : ""
              : linkedVehicle?.vehicle,

          driver: parseInt(driverId),
        };

        await dispatch(deleteDriverVehicleLink(object));
      }
      handleCloseDeleteModal();
      getAvailableVehicleList();
      setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: DriverVehicleProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (driverId) {
      objToSubmit.driver = parseInt(driverId) ? parseInt(driverId) : null;
    }
    objToSubmit.vehicle = objToSubmit.vehicle ? objToSubmit.vehicle : null;
    if (driverId) {
      const driverVehicleLink = await dispatch(addDriverVehicle(objToSubmit));
      if (driverVehicleLink.success && driverVehicleLink.data) {
        handleClose();
        getAvailableVehicleList();
      }
    }

    setLoading(false);
  };
  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "vehicle.vehicleID",
      headerName: translation("vehicle.form.vehicleID.label"),

      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.vehicle === "object" &&
            params?.row?.vehicle?.vehicleID
              ? params?.row?.vehicle?.vehicleID
              : ""}
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {" "}
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/*  <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>
            {!isDriver && !linkedVehicle && (
              <Tooltip
                title={
                  <Typography>
                    {translation("driver.vehicle.assign")}
                  </Typography>
                }
              >
                <Fab
                  color="secondary"
                  sx={{
                    mr: 1,
                    ml: 1,
                  }}
                  size="small"
                  onClick={handleClickOpen}
                >
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      {linkedVehicle ? (
        <Grid container alignItems="flex-end">
          <Grid item xs={12} sm={6} md={1} lg={1}>
            <Grid container justifyContent="center" alignItems="center">
              {!isDriver && (
                <IconButton
                  onClick={() => {
                    handleVehicleDelete();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="h3" className={classes.detailText}>
                {translation("vehicle.form.vehicleID.label")} :
              </Typography>

              <Typography variant="h4" className={classes.detailText}>
                {vehicle?.vehicleID}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="h3" className={classes.detailText}>
                {translation("vehicle.form.model.label")} :
              </Typography>

              <Typography variant="h4" className={classes.detailText}>
                {vehicle?.model}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="h3" className={classes.detailText}>
                {translation("driver.vehicle.assignedOn")} :
              </Typography>

              <Typography variant="h4" className={classes.detailText}>
                {linkedVehicle?.createdAt
                  ? moment(linkedVehicle?.createdAt).format(dateFormat)
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h3" className={classes.detailText}>
            {translation("driver.vehicle.message")}
          </Typography>
        </Grid>
      )} */}
      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedVehicle}
          headerName={"Assigned Vehicle"}
          isDriver={isDriver}
          handleClickOpen={handleClickOpen}
          handleDelete={handleVehicleDelete}
          data={vehicle}
        />
      </CustomFormGrid>
      <DriverVehicleDialogBox
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof linkedVehicle?.vehicle === "object"
                ? `${linkedVehicle?.vehicle?.vehicleID}-${linkedVehicle?.vehicle?.model}-${linkedVehicle?.vehicle?.VIN}`
                : "",
          })}
          title={translation("driverVehicle.delete.title")}
          loading={loading}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={driver?.DriverVehicle ? driver?.DriverVehicle : []}
              columns={columns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              localPagination={true}
            />
          }
          title={translation("vehicle.assignment.logs")}
        />
      )}
    </>
  );
}
