import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import UserForm from "src/components/users/form";
import ReadUserForm from "src/components/users/usersReadForm";
import { UserProps } from "src/interfaces/user";
import {
  getUserById,
  addUser,
  updateUser,
  updateUserPermissions,
} from "src/redux/actions/users";
import { resetUser } from "src/redux/reducers/users";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { getAllSecurityGroups } from "src/redux/actions/securityGroups";
import { sanitizeEntity } from "src/utils/common";
import { getAllRoles } from "src/redux/actions/config";

interface UserFormProps {
  handleClose?: () => any;
}
const CreateUserContainer = ({ handleClose }: UserFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const url = location.pathname.includes("users");

  useEffect(() => {
    dispatch(resetUser());
  }, []);

  useEffect(() => {
    if (params?.userId) {
      getSingleUser(parseInt(params?.userId));
    }
  }, [params?.userId]);
  const getSingleUser = async (id: number) => {
    setPageLoading(true);
    await dispatch(getUserById(id));
    setPageLoading(false);
  };
  const getGroupsList = async () => {
    await dispatch(getAllSecurityGroups());
  };
  useEffect(() => {
    getGroupsList();
  }, []);
  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const updatePermission = async (data: Array<number>) => {
    const objToSubmit: Array<number> = [...data];
    setLoadingPermission(true);
    if (params?.userId) {
      //update
      await dispatch(
        updateUserPermissions(objToSubmit, parseInt(params?.userId))
      );
    }
    setLoadingPermission(false);
  };
  /* UserProps */
  const onSubmit = async (data: any) => {
    let objToSubmit: UserProps = { ...data };
    if (params?.userId) {
      delete objToSubmit["password"];
      delete objToSubmit["email"];
    }
    objToSubmit = sanitizeEntity(objToSubmit);
    setLoading(true);
    if (params?.userId) {
      //update
      await dispatch(updateUser(objToSubmit, parseInt(params?.userId)));
    } else {
      //add
      const user = await dispatch(addUser(objToSubmit));
      if (url && user.success && user.data.id) {
        setTimeout(
          // () => navigate(`/administration/users/${user.data.id}`),
          () => navigate("/administration/users"),
          500
        );
      } else if (user.success && user.data.id && handleClose) {
        handleClose();
      }
    }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <ReadUserForm
          loading={loading}
          // loadingPermission={loadingPermission}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
          // updatePermission={updatePermission}
          // handleClose={handleClose}
          // url={url}
        />
      </Grid>
    </Grid>
  );
}; /*  */

export default CreateUserContainer;
