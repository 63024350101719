import React from "react";
import { Grid} from "@mui/material";
import Head from "src/components/head";
import WaitingForDocumentContainer from "src/containers/billing/waitingForDocument"
const WaitingForDocument = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Billing" description="Porto Billing" />
      <WaitingForDocumentContainer/>
    </Grid>
  );
};

export default WaitingForDocument;
