import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import {
  getAllSettlementListOnStatus,
  updateSettlementStatus,
  saveSettlementPdf,
} from "src/redux/actions/settlement";
import { DocumentProps } from "src/interfaces/driver";

import {
  downloadDocument,
} from "src/redux/actions/driver";
import AddCommonDialog from "src/containers/settlement/confirmationModel";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { selectPrintQueueList } from "src/redux/reducers/settlement";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureSettlements, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import moment from "moment";
import { dateFormat } from "src/utils/constants";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function Terminals() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [printLoading, setPrintLoading] = useState<boolean>(false);
  const [loadingBillingStatus, setLoadingBillingStatus] =
    useState<boolean>(false);

  const printQueueList = useAppSelector(selectPrintQueueList);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const user = useAppSelector(selectUser);
  const classes = useStyles();
  const settlementPermissions =
    allUserPermissions && allUserPermissions[featureSettlements];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementPermissions?.findIndex((item) => item === update);
  const [printQueueData, setPrintQueueData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [selectedDoc, setSelectedDoc] = useState<any | null>(null);
  const [openTerminalModal, setOpenTerminalModal] = useState<boolean>(false);

  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const handleOpenTerminalModal = (data: any) => {
    setSelectedDoc(data);
    setOpenTerminalModal(true);
  };
  const handleCloseTerminalModal = () => {
    setOpenTerminalModal(false);
  };

  const getPrintQueueList = async () => {
    setLoading(true);
    await dispatch(
      getAllSettlementListOnStatus(
        "printQueue",
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };
  function pageExists(page: any) {
    return printQueueData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  }
  const getPrintQueueListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllSettlementListOnStatus(
          "printQueue",
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };

  const handleDownloadFile = (document: DocumentProps) => {
    if (document.path) {
      if (document.id && document.name) {
        dispatch(downloadDocument(document.id, document.name));
      }
    }
  };

  const getUpdatedSettlementStatus = async () => {
    setLoadingBillingStatus(true);
    selectedDoc &&
    selectedDoc?.id &&
    (
      await dispatch(saveSettlementPdf({ data: selectedDoc && Number(selectedDoc?.id) })).then((res:any) => {
       /*  if (res?.data?.settledSettlement?.path) {
          if (res?.data?.settledSettlement?.id && res?.data?.settledSettlement?.name) {
            dispatch(downloadDocument(res?.data?.settledSettlement?.id, res?.data?.settledSettlement?.name));
          }
        } */
      handleDownloadFile(res?.data?.settledSettlement);
      handleCloseTerminalModal();
      setLoadingBillingStatus(false);
      getPrintQueueList();
    }));
  };
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getPrintQueueListWithFilter();
    } else getPrintQueueList();
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    getPrintQueueListWithFilter();
  }, [filter]);
  useEffect(() => {
    //  if (pageExists(rowsState?.page + 1) == false)
    if (printQueueList?.data?.length > 0) {
      setPrintQueueData({ ...printQueueData?.printQueueList, printQueueList });
    } else {
      setPrintQueueData({});
    }
  }, [printQueueList]);

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );



  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<ArrowCircleRightIcon />}
          label="Push"
          key="push"
          onClick={() => {
            handleOpenTerminalModal(params?.row);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "account",
      headerName: translation("settlement.print.account.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <div> {params.row.settlements[0]?.account?.accountName} </div>;
      },
    },
    {
      field: "settledBy",
      headerName: translation("settlement.print.settledBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {" "}
            {params.row.settledBy &&
              params.row.settledBy.firstName +
                " " +
                params.row.settledBy.lastName}{" "}
          </div>
        );
      },
      filterable: false,
    },
    {
      field: "receivedOn",
      headerName: translation("settlement.print.receivedOn.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.printReceivedOn
              ? moment(params.row.printReceivedOn).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "printedOn",
      headerName: translation("settlement.print.printedOn.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.printedOn
              ? moment(params.row.printedOn).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid container style={{paddingTop:"20px"}}>
      <DataTable
        title={translation("settlement.print.queue.title")}
        rows={printQueueData?.printQueueList?.data}
        columns={columns}
        loading={loading}
        createPermission={createPermission > -1}
        rowsState={rowsState}
        setRowsState={setRowsState}
        pagination={printQueueList.pagination}
        data={printQueueData?.terminals}
        setData={setPrintQueueData}
        // billing={true}
        setFilter={setFilter}
      />

      <AddCommonDialog
        handleClose={handleCloseTerminalModal}
        open={openTerminalModal}
        title="Update Settlement Status"
        data={selectedDoc}
        updateSettlementStatus={getUpdatedSettlementStatus}
        loading={loadingBillingStatus}
      />
    </CustomFormGrid>
  );
}
