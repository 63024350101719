import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectPrintList } from "src/redux/reducers/billing";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { create, update, featureLoad } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import moment from "moment";
import { dateTimeFormat } from "src/utils/constants";
import { useStyles } from "src/styles/formStyles";
import { CircularProgress } from "@mui/material";
import {
  getAllLoadsOnStatus,
  updateLoadPrintQueue,
} from "src/redux/actions/billing";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { toNumber } from "lodash";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { getGridStringOperators } from "@mui/x-data-grid";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function FreightBillPrintQueue() {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const printList = useAppSelector(selectPrintList);
  const [selectedRows, setSelectedRows] = React.useState<any>();
  const [filter, setFilter] = useState<any>({});
  const [allLoadManagementData, setAllLoadManagementData] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const user = useAppSelector(selectUser);

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const loadManagementPermissions =
    allUserPermissions && allUserPermissions[featureLoad];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : loadManagementPermissions.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : loadManagementPermissions.findIndex((item) => item === update);

  const getDocumentList = async () => {
    setLoading(true);
    await dispatch(
      getAllLoadsOnStatus(
        "printQueue",
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getDocumentListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&  (await dispatch(
      getAllLoadsOnStatus(
        "printQueue",
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
        filter
      )
    ));
  };

  const getUpdateLoadQueue = async () => {
    setLoading(true);
    await dispatch(
      updateLoadPrintQueue(selectedRows, "factoringInvoices")
    ).then(() => {
      setLoading(false);
      getDocumentList();
      setSelectedRows("");
    });
  };

  /*  function pageExists(page: any) {
    return allLoadManagementData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getDocumentListWithFilter();
    } else {
      getDocumentList();
    }
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    getDocumentListWithFilter();
  }, [filter]);
  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (printList?.data?.length > 0) {
      setAllLoadManagementData({
        ...allLoadManagementData?.printList,
        printList,
      });
    } else {
      setAllLoadManagementData({});
    }
  }, [printList]);

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals"].includes(value)
  );

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (/* params: GridValueGetterParams */) => [
        <GridActionsCellItem
          icon={<ContentCutIcon />}
          label="Edit"
          key="edit"
          /*    onClick={() => {
            navigate(`/config/commodity/${params.row.id}`);
          }} */
        />,
      ],
    },

    {
      field: "id",
      headerAlign: "center",
      align: "center",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "billTo",
      headerName: translation("load.billToInformation.label"),
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.billToInformation?.company}</span>;
      },
    },
    {
      field: "loadStatus",
      headerName: translation("load.form.dispatchStatus.label"),
      minWidth: 200,
      flex: 1,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.loadStatus}</span>;
      },
    },

    {
      field: "billingStatus",
      headerName: translation("load.form.billingStatus.label"),
      minWidth: 200,
      flex: 1,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.billingStatus}</span>;
      },
    },
    {
      field: "shipper",
      headerName: translation("load.shipperLoadAddress.label"),
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.shippingInformation?.company}</span>;
      },
    },
    {
      field: "consignee",
      headerName: translation("load.consigneeLoadAddress.label"),
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.consigneeInformation?.company}</span>;
      },
    },
    {
      field: "containerNumber",
      headerName: translation("load.form.containerNumber.label"),
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "pickupDateTime",
      headerName: translation("load.form.pickupDateTime.label"),
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.pickupDateTime
              ? moment(params.row.pickupDateTime).format(dateTimeFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "deliveryDateTime",
      headerName: translation("load.form.deliveryDateTime.label"),
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.deliveryDateTime
              ? moment(params.row.deliveryDateTime).format(dateTimeFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  return (
    <CustomFormGrid item sm={12} xs={12} lg={12} justifyContent="center" style={{paddingTop:'20px'}}>
      {loading ? (
        <CircularProgress style={{ position: "absolute", left: "60%" }} />
      ) : (
        <>
          <DataTable
            title={translation("billing.list.loadsPrintQueue")}
            rows={allLoadManagementData?.printList?.data}
            columns={columns}
            loading={loading}
            createPermission={createPermission > -1}
            rowsState={rowsState}
            setRowsState={setRowsState}
            pagination={printList?.data?.pagination}
            data={allLoadManagementData?.printList}
            setData={setAllLoadManagementData}
            billing={true}
            // onRowClick={onRowClick}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setFilter={setFilter}
          />
          <div
            style={{ float: "right", marginBottom: "10px", marginTop: "10px" }}
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.formButtonBilling}
              onClick={() => getUpdateLoadQueue()}
            >
              {translation("billing.list.button.label2")}
            </Button>
          </div>
        </>
      )}
    </CustomFormGrid>
  );
}
