import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CreateLoadManagementContainer from "src/containers/loadManagement/form";
const LoadManagementForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Load Management" description="Porto Load Management" />
      <CreateLoadManagementContainer />
    </Grid>
  );
};

export default LoadManagementForm;
