import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import DocumentTypeForm from "src/components/documentTypes/form";
import { DocumentTypeProps } from "src/interfaces/documentType";
import {
  getDocumentTypeById,
  addDocumentType,
  updateDocumentType,
} from "src/redux/actions/documentType";

import { resetDocumentType } from "src/redux/reducers/documentType";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { sanitizeEntity } from "src/utils/common";

const DocumentTypeFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    dispatch(resetDocumentType());
  }, []);
  useEffect(() => {
    if (params.id) {
      getSingleDriver(parseInt(params.id));
    }
  }, [params.id]);
  const getSingleDriver = async (id: number) => {
    setPageLoading(true);
    await dispatch(getDocumentTypeById(id));
    setPageLoading(false);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: DocumentTypeProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit["size"] = objToSubmit["size"]
      ? typeof objToSubmit["size"] === "string"
        ? parseInt(objToSubmit["size"])
        : objToSubmit["size"]
      : null;

    if (params?.id) {
      //update
      await dispatch(updateDocumentType(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const driver = await dispatch(addDocumentType(objToSubmit));
      if (driver.success && driver.data.id) {
        setTimeout(
         // () => navigate(`/config/document-types/${driver.data.id}`),
          () => navigate("/config/document-types"),
          500
        );
      }
    }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <DocumentTypeForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentTypeFormContainer;
