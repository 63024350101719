import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Collapsible from "src/components/collapsibleForm";
import CustomerForm from "./form";
import CustomerBilling from "./customerBilling";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { ArrowBack } from "@mui/icons-material";

interface CustomerFormProps {
  loading: boolean;
  pageLoading: boolean;
 /*  onSubmit: (data: CustomerProps) => any; */
     onSubmit: (data: any) => any;
/*   onCustomerBillingSubmit: (data: CustomerBillingProps) => any; */
  onCustomerBillingSubmit: (data: any) => any;
  handleClose?: () => any;
  url: any;
}
const CustomerIndexForm = ({
  loading,
  pageLoading,
  onSubmit,
  onCustomerBillingSubmit,
  handleClose,
  url,
}: CustomerFormProps): JSX.Element => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [translation] = useTranslation("translation");
  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([]);

  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = ["customerBillingInfoPanel", "personalInfoPanel"];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };

  return (
    <div>

{pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomFormGrid container>
                <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                  {params?.customerId ? (

                        <CustomerForm
                          onSubmit={onSubmit}
                          pageLoading={pageLoading}
                          loading={loading}
                          parentExpandAll={expandAllPanels}
                          handleClose={handleClose}
                        />


                  ) : (
                    <CustomerForm
                      onSubmit={onSubmit}
                      pageLoading={pageLoading}
                      loading={loading}
                      parentExpandAll={expandAllPanels}
                      handleClose={handleClose}
                    />
                  )}
                </CustomFormGrid>
                <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                {params?.customerId && (
                  <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                    <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation(
                          "customer.form.customerBilling.label"
                        )}
                      </Typography>
                      <CustomerBilling
                          loading={loading}
                          onCustomerBillingSubmit={onCustomerBillingSubmit}
                          parentExpandAll={expandAllPanels}
                        />
                    </CardContent>
                  </Card>

                  </CustomFormGrid>



                  )}
                </CustomFormGrid>
              </CustomFormGrid>
            </LocalizationProvider>
          )}


    </div>
  );
};

export default CustomerIndexForm;
