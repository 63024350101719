import { AppDispatch } from "../store";
import { setAllCustomers, setSingleCustomer } from "../reducers/customer";
import { CustomerProps } from "src/interfaces/customer";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllCustomers = (limit?: number, page?: number,filter?: any) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/customer`) as any);
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data?.data)) {
      dispatch(setAllCustomers(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};

export const getAllCustomersByType = (type: number) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/customer?type=${type}`) as any);
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data)) {
      dispatch(setAllCustomers(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};


export const getCustomerById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(getServerData(`/customer/${id}`) as any);
    if (response?.data?.data) {
      dispatch(setSingleCustomer(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const addCustomer =
  (data: CustomerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/customer`, data) as any);

    if (response?.data?.data) {
      dispatch(setSingleCustomer(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateCustomer =
  (data: CustomerProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/customer/${id}`, data) as any);
    if (response?.data?.data?.customer) {
      dispatch(setSingleCustomer(response?.data?.data?.customer));
    }
    return { success: response.success, data: response?.data?.data?.customer };
  };
