import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../../common/readForms/formHeader";
import FromInput from "../../common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat, dateTimeFormat2, timeFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import Documents from "./docements";
import { LoadStopProps } from "src/interfaces/loadManagement";
import BillToInfoModel from "src/components/common/InformationModels/billToInfoModel";
import TerminalToInfoModel from "src/components/common/InformationModels/terminalToInfoModal";
import OrderMakerModal from "src/components/common/InformationModels/orderMakerModal";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";

interface LoadSummaryProps {
  pageLoading: boolean;
}

const LoadSummary = ({ pageLoading }: LoadSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const loadId = params?.loadId;

  const [selectedData, setSelectedData] = useState<any>();
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [openTerminalModel, setOpenTerminalModel] = useState<boolean>(false);
  const [openOrderMakerModel, setOpenOrderMakerModel] =
    useState<boolean>(false);
  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const [loadStops, setLoadStops] = useState<Array<LoadStopProps>>([
    // { ...defaultLoadStopObject },
  ]);

  const user = useAppSelector(selectUser);
  const loadData = useAppSelector(selectLoadManagement);
  const terminalId = loadData?.terminal?.id;

  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };

  const handleCloseStopOffFormModal = () => {
    setOpenDispatchFormModal && setOpenDispatchFormModal(null);
  };
  const handleSetMilageValue = async (value: any) => {
    // setValue("freightBill.mileage", value);
  };

  const loadStopCancelled = loadData?.loadStops?.every(
    (item) => item.dispatchStatus === "cancelled"
  );
  const loadStopPlanned = loadData?.loadStops?.every(
    (item) => item.dispatchStatus === "planned"
  );

  const handleOpenModel = (loadId: any) => {

    setOpenModel(true);
    setSelectedData(loadId);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setSelectedData(null);
  };

  const handleOpenTerminalModel = (terminalId: any) => {

    setOpenTerminalModel(true);
    setSelectedData(terminalId);
  };

  const handleCloseTerminalModel = () => {
    setOpenTerminalModel(false);
    setSelectedData(null);
  };

  const handleOpenOrderMakerModel = (loadId: any) => {
    setOpenOrderMakerModel(true);
    setSelectedData(loadId);
  };

  const handleCloseOrderMakerModel = () => {
    setOpenOrderMakerModel(false);
    setSelectedData(null);
  };

  useEffect(() => {
    // if (
    //   loadData != null &&
    //   Object.keys(loadData).length > 0 &&
    //   loadData?.loadStops.length > 0
    // ) {
    //   setLoadStops([...loadData?.loadStops]);
    // }
  }, []);
  return (
    <Box>
      {pageLoading ? (
        <CustomFormGrid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CustomFormGrid>
            <CircularProgress sx={{ height: "20", width: "20px" }} />
          </CustomFormGrid>
        </CustomFormGrid>
      ) : (
        <>
          <MobileCheck
            grid1={
              <CustomFormGrid container>
                <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                  <Box className={classes.loadFormTitleContainer}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      <Button>
                        <ArrowBack onClick={() => navigate(-1)} />
                      </Button>
                      <Typography
                        variant="h6"
                        className={classes.loadFormMainHeadings}
                      >
                        {translation("load.summary.form.title")}
                      </Typography>
                    </Box>
                  </Box>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                  <CustomFormGrid
                    container
                    className={classes.buttonContainer}
                    justifyContent="flex-end"
                  >
                    {/*  <Button
                  variant="outlined"
                  className={classes.formButtonDiscard}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.discard")}
                </Button> */}

                    {params?.loadId &&
                      (loadStopCancelled === true ||
                        loadStopPlanned === true) && (
                        <Button
                          color="error"
                          variant="contained"
                          className={classes.formButtonCancel}
                          onClick={() => handleOpenConfirmModel()}
                        >
                          CANCEL LOAD
                        </Button>
                      )}

                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonEdit}
                      onClick={() =>
                        navigate(
                          `/load-management/load-management/${params?.loadId}`
                        )
                      }
                    >
                      EDIT INFORMATION
                    </Button>

                    {/* {loadData && loadData?.loadStops?.length > 0 && (
                      <Button
                        color="error"
                        variant="contained"
                        className={classes.formButtonDispatch}
                        onClick={() => setOpenDispatchFormModal(0)}
                      >
                        DISPATCH
                      </Button>
                    )} */}
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
            grid2={
              <CustomFormGrid container>
                <CustomFormGrid
                  items
                  lg={
                    loadData !== null &&
                    loadData !== undefined &&
                    loadData?.stopOffs &&
                    loadData?.stopOffs?.length > 0
                      ? 6
                      : 4
                  }
                  md={
                    loadData !== null &&
                    loadData !== undefined &&
                    loadData?.stopOffs &&
                    loadData?.stopOffs?.length > 0
                      ? 6
                      : 4
                  }
                  xs={12}
                >
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("common.information.label")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.id.label")}
                            input={loadData?.id}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.pickupDate.label")}
                            input={
                              moment(loadData?.pickupDateTime).format(
                                dateFormat
                              ) +
                              (loadData?.pickupStart != null
                                ? "(" +
                                  moment(loadData?.pickupStart).format(
                                    timeFormat
                                  ) +
                                  ")" +
                                  (loadData?.pickupEnd != null
                                    ? "-" +
                                      "(" +
                                      moment(loadData?.pickupEnd).format(
                                        timeFormat
                                      ) +
                                      ")"
                                    : "") +
                                  // @ts-ignore: Unreachable code error
                                  (loadData.pickupServiceLevel?.content
                                    ?.length > 0
                                    ? "(" +
                                      // @ts-ignore: Unreachable code error

                                      loadData?.pickupServiceLevel?.content.toString() +
                                      ")"
                                    : "")

                                : "")
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.deliveryDate.label")}
                            input={
                              moment(loadData?.deliveryDateTime).format(
                                dateFormat
                              ) +
                              (loadData?.deliveryStart != null
                                ? "(" +
                                  moment(loadData?.deliveryStart).format(
                                    timeFormat
                                  ) +
                                  ")" +
                                  (loadData?.deliveryEnd != null
                                    ? "-" +
                                      "(" +
                                      moment(loadData?.deliveryEnd).format(
                                        timeFormat
                                      ) +
                                      ")"
                                    : "") +
                                  // @ts-ignore: Unreachable code error

                                  (loadData?.deliveryServiceLevel?.content
                                    ?.length > 0
                                    ? "(" +
                                      // @ts-ignore: Unreachable code error

                                      loadData?.deliveryServiceLevel?.content.toString() +
                                      ")"
                                    : "")
                                : "")
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.status.label")}
                            input={loadData?.loadStatus}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>

                {loadData !== null &&
                  loadData !== undefined &&
                  loadData?.stopOffs &&
                  loadData?.stopOffs?.length > 0 && (
                    <CustomFormGrid items lg={6} md={6} xs={12}>
                      <CustomFormGrid>
                        <FromHeader title={"Stop Offs"} />
                      </CustomFormGrid>
                      <CustomFormGrid>
                        <Paper
                          elevation={1}
                          style={{
                            width: "100%",
                            maxHeight: "213px",
                            overflow: "auto",
                          }}
                        >
                          {loadData?.stopOffs.map((item: any, index: any) => (
                            <CustomFormGrid key={index}>
                              <Typography
                                style={{ color: "#000" }}
                                variant="h6"
                                component="h4"
                                sx={{ pl: 1, fontWeight: 600 }}
                              >
                                {"StopOff #" + (index + 1)}
                              </Typography>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={"Type"}
                                  input={item?.type ? item?.type : "--"}
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <CustomGrid
                                  container
                                  style={{ padding: "0px", margin: "0px" }}
                                >
                                  <CustomGrid xs={4} sm={4} md={4} lg={4}>
                                    <Typography
                                      className={classes.readFormLabel}
                                      variant="h6"
                                      component="h4"
                                      sx={{ pl: 1 }}
                                    >
                                      {translation("common.address.label")}
                                    </Typography>
                                  </CustomGrid>
                                  <CustomGrid xs={8} sm={8} md={8} lg={8}>
                                    <Typography
                                      // style={{ color: inputColor }}
                                      component="h4"
                                      variant="h6"
                                      sx={{
                                        pl: 1,
                                        wordWrap: "break-word",
                                        whiteSpace: "initial",
                                      }}
                                    >
                                      {item?.address?.address}
                                      {item?.address?.city &&
                                      item?.address?.address
                                        ? ","
                                        : ""}
                                      {item?.address?.city
                                        ? " " + item?.address?.city
                                        : ""}
                                      {item?.address?.state &&
                                      item?.address?.city
                                        ? ","
                                        : ""}
                                      {item?.address?.state
                                        ? " " + item?.address?.state
                                        : ""}
                                      {item?.address?.zip &&
                                      item?.address?.state
                                        ? ","
                                        : ""}
                                      {item?.address?.zip
                                        ? " " + item?.address?.zip
                                        : ""}
                                    </Typography>
                                  </CustomGrid>
                                </CustomGrid>
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={"Arrival Date"}
                                  input={
                                    item?.arrivalDate
                                      ? moment(item?.arrivalDate).format(
                                        dateTimeFormat2
                                        )
                                      : "--"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={"Delivery Date"}
                                  input={
                                    item?.deliveryDate
                                      ? moment(item?.deliveryDate).format(
                                        dateTimeFormat2
                                        )
                                      : "--"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                            </CustomFormGrid>
                          ))}
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  )}
                <CustomFormGrid
                  items
                  lg={
                    loadData !== null &&
                    loadData !== undefined &&
                    loadData?.stopOffs &&
                    loadData?.stopOffs?.length > 0
                      ? 6
                      : 4
                  }
                  md={
                    loadData !== null &&
                    loadData !== undefined &&
                    loadData?.stopOffs &&
                    loadData?.stopOffs?.length > 0
                      ? 6
                      : 4
                  }
                  xs={12}
                >
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("load.shipperLoadAddress.label")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.name.label")}
                            input={loadData?.shippingInformation?.company}
                            checkColor={true}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />

                        <CustomFormGrid>
                          <CustomGrid
                            container
                            style={{ padding: "0px", margin: "0px" }}
                          >
                            <CustomGrid xs={4} sm={4} md={4} lg={4}>
                              <Typography
                                className={classes.readFormLabel}
                                variant="h6"
                                component="h4"
                                sx={{ pl: 1 }}
                              >
                                {translation("common.address.label")}
                              </Typography>
                            </CustomGrid>
                            <CustomGrid xs={8} sm={8} md={8} lg={8}>
                              <Typography
                                // style={{ color: inputColor }}
                                component="h4"
                                variant="h6"
                                sx={{
                                  pl: 1,
                                  wordWrap: "break-word",
                                  whiteSpace: "initial",
                                }}
                              >
                                {loadData?.shippingInformation?.address}
                                {loadData?.shippingInformation?.city &&
                                loadData?.shippingInformation?.address
                                  ? ","
                                  : ""}
                                {loadData?.shippingInformation?.city
                                  ? " " + loadData?.shippingInformation?.city
                                  : ""}
                                {loadData?.shippingInformation?.state &&
                                loadData?.shippingInformation?.city
                                  ? ","
                                  : ""}
                                {loadData?.shippingInformation?.state
                                  ? " " + loadData?.shippingInformation?.state
                                  : ""}
                                {loadData?.shippingInformation?.zip &&
                                loadData?.shippingInformation?.state
                                  ? ","
                                  : ""}
                                {loadData?.shippingInformation?.zip
                                  ? " " + loadData?.shippingInformation?.zip
                                  : ""}
                              </Typography>
                            </CustomGrid>
                          </CustomGrid>
                        </CustomFormGrid>

                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.phone.label")}
                            input={loadData?.shippingInformation?.contact}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>

                <CustomFormGrid
                  items
                  lg={
                    loadData !== null &&
                    loadData !== undefined &&
                    loadData?.stopOffs &&
                    loadData?.stopOffs?.length > 0
                      ? 6
                      : 4
                  }
                  md={
                    loadData !== null &&
                    loadData !== undefined &&
                    loadData?.stopOffs &&
                    loadData?.stopOffs?.length > 0
                      ? 6
                      : 4
                  }
                  xs={12}
                >
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("load.consigneeLoadAddress.label")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.name.label")}
                            input={loadData?.consigneeInformation?.company}
                            checkColor={true}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <CustomGrid
                            container
                            style={{ padding: "0px", margin: "0px" }}
                          >
                            <CustomGrid xs={4} sm={4} md={4} lg={4}>
                              <Typography
                                className={classes.readFormLabel}
                                variant="h6"
                                component="h4"
                                sx={{ pl: 1 }}
                              >
                                {translation("common.address.label")}
                              </Typography>
                            </CustomGrid>
                            <CustomGrid xs={8} sm={8} md={8} lg={8}>
                              <Typography
                                // style={{ color: inputColor }}
                                component="h4"
                                variant="h6"
                                sx={{
                                  pl: 1,
                                  wordWrap: "break-word",
                                  whiteSpace: "initial",
                                }}
                              >
                                {loadData?.consigneeInformation?.address}
                                {loadData?.consigneeInformation?.city &&
                                loadData?.consigneeInformation?.address
                                  ? ","
                                  : ""}
                                {loadData?.consigneeInformation?.city
                                  ? " " + loadData?.consigneeInformation?.city
                                  : ""}
                                {loadData?.consigneeInformation?.state &&
                                loadData?.consigneeInformation?.city
                                  ? ","
                                  : ""}
                                {loadData?.consigneeInformation?.state
                                  ? " " + loadData?.consigneeInformation?.state
                                  : ""}
                                {loadData?.consigneeInformation?.zip &&
                                loadData?.consigneeInformation?.state
                                  ? ","
                                  : ""}
                                {loadData?.consigneeInformation?.zip
                                  ? " " + loadData?.consigneeInformation?.zip
                                  : ""}
                              </Typography>
                            </CustomGrid>
                          </CustomGrid>
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.phone.label")}
                            input={loadData?.consigneeInformation?.contact}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid container>
                  <CustomFormGrid items lg={6} md={4} xs={12}>
                    <CustomFormGrid>
                      <FromHeader title={translation("billInfo.to.title")} />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenModel(loadId)}
                          >
                            <FromInput
                              heading={translation("common.bill.label")}
                              input={loadData?.billToInformation?.customer}
                              checkColor={true}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "load.dispatchPay.freightMethod"
                              )}
                              input={
                                // loadData?.loadStops[0]?.dispatchPay
                                //   ? loadData?.loadStops[0]?.dispatchPay
                                //   : "--"
                                ""
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.form.rate.label")}
                              input={
                                loadData?.freightBill?.rate
                                  ? "$" + loadData?.freightBill?.rate
                                  : "&0.00"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.mileage.title")}
                              input={
                                loadData?.freightBill?.mileage
                                  ? loadData?.freightBill?.mileage
                                  : "0.00"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.form.fuel.label")}
                              input={
                                loadData?.freightBill?.fuel
                                  ? "$" + loadData?.freightBill?.fuel
                                  : "&0.00"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.otherCharges.title")}
                              input={
                                loadData?.freightBill?.otherBill
                                  ? "$" + loadData?.freightBill?.otherBill
                                  : "&0.00"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.dispatchPay.total")}
                              input={
                                loadData?.freightBill?.grandTotal
                                  ? "$" + loadData?.freightBill?.grandTotal
                                  : "&0.00"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>

                  <CustomFormGrid items lg={6} md={4} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation("load.internalContact.title")}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <Typography
                              sx={{ pl: 1, pt: 1, fontWeight: "600" }}
                              variant="h5"
                              component="h3"
                            >
                              {translation("load.orderTaker.title")}
                            </Typography>
                          </CustomFormGrid>
                          <CustomFormGrid
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenOrderMakerModel(loadId)}
                          >
                            <FromInput
                              heading={translation("common.name.label")}
                              input={
                                loadData?.addedBy?.firstName
                                  ? loadData?.addedBy?.firstName
                                  : "--"
                              }
                              checkColor={true}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomGrid>
                            <FromInput
                              heading={translation("common.email.label")}
                              input={
                                loadData?.addedBy?.email
                                  ? loadData?.addedBy?.email
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("common.phone.label")}
                              input={
                                loadData?.addedBy?.phoneNumber
                                  ? loadData?.addedBy?.phoneNumber
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>

                          <Typography
                            sx={{ pl: 1, pt: 1, fontWeight: "600" }}
                            variant="h5"
                            component="h3"
                          >
                            {translation("load.form.terminalInfo.label")}
                          </Typography>
                          <CustomFormGrid
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenTerminalModel(terminalId)}
                          >
                            <FromInput
                              heading={translation("common.name.label")}
                              input={
                                loadData?.terminal?.title
                                  ? loadData?.terminal?.title
                                  : "--"
                              }
                              checkColor={true}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("common.city.label")}
                              input={
                                loadData?.terminal?.city
                                  ? loadData?.terminal?.city
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("common.phone.label")}
                              input={
                                loadData?.terminal?.phoneNumber
                                  ? loadData?.terminal?.phoneNumber
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <CustomFormGrid items lg={6} md={4} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation("load.information.title")}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.billOfLanding.title")}
                              input={
                                loadData?.billOfLading
                                  ? loadData?.billOfLading
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.trailerReq.title")}
                              input={
                                loadData?.trailerRequirement
                                  ? loadData?.trailerRequirement?.content
                                  : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("load.loadSize.title")}
                              input={
                                loadData?.loadSize ? loadData?.loadSize : "--"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.form.length.label")}
                              input={loadData?.length ? loadData?.length : "0"}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("vehicle.form.weight.label")}
                              input={loadData?.weight ? loadData?.weight : "0"}
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>

                  {(loadData?.hotComments?.length != null ||
                    loadData?.externalComments?.length != null ||
                    loadData?.externalComments?.length != null) && (
                    <CustomFormGrid items lg={12} md={12} xs={12}>
                      <CustomFormGrid>
                        <FromHeader
                          title={translation(
                            "ownerTrailer.form.comments.label"
                          )}
                        />
                      </CustomFormGrid>
                      <CustomFormGrid>
                        <CustomFormGrid>
                          <Paper>
                            <CustomFormGrid>
                              <CustomGrid
                                container
                                style={{ padding: "0px", margin: "0px" }}
                              >
                                <CustomGrid xs={4} sm={4} md={2} lg={2}>
                                  <Typography
                                    className={classes.readFormLabel}
                                    variant="h6"
                                    component="h4"
                                    sx={{ pl: 1 }}
                                  >
                                    {translation("load.form.hotComments.label")}
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid xs={8} sm={8} md={10} lg={10}>
                                  <Typography
                                    // style={{ color: inputColor }}
                                    component="h4"
                                    variant="h6"
                                    sx={{
                                      pl: 1,
                                      wordWrap: "break-word",
                                      whiteSpace: "initial",
                                    }}
                                  >
                                    {loadData?.hotComments != null
                                      ? loadData?.hotComments
                                      : "---"}
                                  </Typography>
                                </CustomGrid>
                              </CustomGrid>
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <CustomGrid
                                container
                                style={{ padding: "0px", margin: "0px" }}
                              >
                                <CustomGrid xs={4} sm={4} md={2} lg={2}>
                                  <Typography
                                    className={classes.readFormLabel}
                                    variant="h6"
                                    component="h4"
                                    sx={{ pl: 1 }}
                                  >
                                    {translation(
                                      "load.form.externalComments.label"
                                    )}
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid xs={8} sm={8} md={10} lg={10}>
                                  <Typography
                                    // style={{ color: inputColor }}
                                    component="h4"
                                    variant="h6"
                                    sx={{
                                      pl: 1,
                                      wordWrap: "break-word",
                                      whiteSpace: "initial",
                                    }}
                                  >
                                    {loadData?.internalComments != null
                                      ? loadData?.internalComments
                                      : "---"}
                                  </Typography>
                                </CustomGrid>
                              </CustomGrid>
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <CustomGrid
                                container
                                style={{ padding: "0px", margin: "0px" }}
                              >
                                <CustomGrid xs={4} sm={4} md={2} lg={2}>
                                  <Typography
                                    className={classes.readFormLabel}
                                    variant="h6"
                                    component="h4"
                                    sx={{ pl: 1 }}
                                  >
                                    {translation(
                                      "common.internalComment.label"
                                    )}
                                  </Typography>
                                </CustomGrid>
                                <CustomGrid xs={8} sm={8} md={10} lg={10}>
                                  <Typography
                                    // style={{ color: inputColor }}
                                    component="h4"
                                    variant="h6"
                                    sx={{
                                      pl: 1,
                                      wordWrap: "break-word",
                                      whiteSpace: "initial",
                                    }}
                                  >
                                    {loadData?.externalComments != null
                                      ? loadData?.externalComments
                                      : "---"}
                                  </Typography>
                                </CustomGrid>
                              </CustomGrid>
                            </CustomFormGrid>
                          </Paper>
                        </CustomFormGrid>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  )}
                  <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                    <Documents />
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
          />
        </>
      )}
      <LoadCancelDialog
        handleClose={handleCloseConfirmModel}
        open={openConfirmModel}
        title="Canel Load"
        loadId={params?.loadId}
      />

      {openModel === true && (
        <BillToInfoModel
          open={openModel}
          handleClose={handleCloseModel}
          loadId={selectedData}
        />
      )}

      {openTerminalModel === true && (
        <TerminalToInfoModel
          open={openTerminalModel}
          handleClose={handleCloseTerminalModel}
          terminalId={selectedData}
        />
      )}
      {openOrderMakerModel === true && (
        <OrderMakerModal
          open={openOrderMakerModel}
          handleClose={handleCloseOrderMakerModel}
          loadId={selectedData}
        />
      )}

      {/* {openDispatchFormModal !== null &&
        openDispatchFormModal == 0 &&
        loadData &&
        loadData?.loadStops?.length > 0 && (
          <AddLoadStopInfoModel
            loadId={params?.loadId}
            handleClose={handleCloseStopOffFormModal}
            open={openDispatchFormModal}
            title={translation("load.stopDetail.title")}
            // index={index}
            item={loadData?.loadStops[0]}
            freightBill={loadData?.freightBill}
            buttonText={translation("common.form.update")}
            setOpenStopOffFormModal={setOpenDispatchFormModal}
            dispatchTabOpen={true}
            setLoadStops={setLoadStops}
            loadStops={loadData?.loadStops}
            handleSetMilageValue={handleSetMilageValue}
            disabled={false}
          />
        )} */}
    </Box>
  );
};

export default LoadSummary;
