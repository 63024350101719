import React, { useEffect, useState } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { loadSizes } from "src/utils/dropdowns/load";
import { MenuItem, Divider, Chip } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllLookups, getAllLookupsByCategory } from "src/redux/actions/lookup";
import { getAllCommodities } from "src/redux/actions/commodity";
import { selectCommodities } from "src/redux/reducers/commodity";
import { selectLookups } from "src/redux/reducers/lookup";


interface LoadDimensionsProps {
  done(error: any | null, data: LoadManagementProps): any;
  stepId?: number,
  data?: LoadManagementProps | null
}
const LoadDetails = ({
  done,
  stepId,
  data
}: LoadDimensionsProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const dispatch = useAppDispatch();
  const lookups = useAppSelector(selectLookups);
  const commodityType: Array<any> = useAppSelector(selectCommodities);
  const [trailerRequirement, setTrailerRequirement] = useState<any>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();

  const onSubmit = (data: LoadManagementProps) => {
    console.log("Load Details ===============", data);
    done(null, data);
  }

  useEffect(() => {
    dispatch(getAllLookups());
    dispatch(getAllCommodities());
  }, [])

  useEffect(() => {
    const filtered = commodityType.filter((e: any) => {
      return String(e.commodity).toLowerCase() === "fak"
    });
    if (filtered && filtered.length > 0) {
      setValue("commodityType", filtered[0].id);
    }
  }, [commodityType])

  useEffect(() => {
    const filtered = trailerRequirement.filter((e: any) => {
      return String(e.content).toLowerCase() === "van"
    });
    if (filtered && filtered.length > 0) {
      setValue("trailerRequirement", filtered[0].id);
    }
  }, [trailerRequirement])


  useEffect(() => {
    const filtered = loadSizes.filter((e: any) => {
      return String(e.value).toLowerCase() === "full"
    });
    if (filtered && filtered.length > 0) {
      setValue("loadSize", filtered[0].value);
    }
  }, [loadSizes])


  useEffect(() => {
    if (lookups && lookups.data && lookups.data["trailer-type"]) {
      setTrailerRequirement(lookups.data["trailer-type"]["lookups"]);
    }
  }, [lookups]);

  return (
    <form id={`load-form-${stepId}`} noValidate onSubmit={handleSubmit(onSubmit)}>
      <CustomFormGrid container spacing={2}>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="commodityType"
            control={control}
            defaultValue={data?.commodityType || ""}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="commodityType"
                select
                label={"Commodity Type"}
                error={Boolean(errors?.commodityType)}
                helperText={
                  errors?.commodityType &&
                  "Commodity Type is required"
                }
              >
                {commodityType &&
                  commodityType.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.commodity}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="commodityValue"
            control={control}
            defaultValue={data?.commodityValue || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="commodityValue"
                label={"Commodity Value"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="loadSize"
            control={control}
            defaultValue={data?.loadSize || ""}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="loadSize"
                select
                label={translation("load.form.loadSize.label")}
                error={Boolean(errors?.loadSize)}
                helperText={
                  errors?.loadSize && translation("load.form.loadSize.message")
                }
              >
                {loadSizes &&
                  loadSizes.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="weight"
            control={control}
            defaultValue={data?.weight || ""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                inputProps={{ min: 0 }}
                fullWidth
                type="number"
                id="weight"
                label={"Weight (lbs)"}
                error={Boolean(errors?.weight)}
                required
                helperText={
                  errors?.weight && translation("load.form.weight.message")
                }
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="minimumWeight"
            control={control}
            defaultValue={data?.minimumWeight || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                inputProps={{ min: 0 }}
                fullWidth
                type="number"
                id="minimumWeight"
                label={translation("load.form.minWeight.label")}
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="trailerRequirement"
            control={control}
            defaultValue={data?.trailerRequirement || ""}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="trailerRequirement"
                select
                label={"Trailer Requirement"}
                error={Boolean(errors?.trailerRequirement)}
                helperText={
                  errors?.trailerRequirement &&
                  "Trailer Requirement is required"
                }
              >
                {trailerRequirement &&
                  trailerRequirement.map((item: any, index: number) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="noOfPieces"
            control={control}
            defaultValue={data?.numberOfPieces || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                inputProps={{ min: 0 }}
                type="number"
                id="noOfPieces"
                label={"No Of Pieces"}
              />
            )}
          />
        </CustomFormGrid>
        
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="billingEquipmentNo"
            control={control}
            defaultValue={data?.billingEquipment || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="billingEquipmentNo"
                label={"Billing Equipment#"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="sealNumber"
            control={control}
            defaultValue={data?.sealNumber || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="sealNumber"
                label={"Seal Number"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="containerNumber"
            control={control}
            defaultValue={data?.containerNumber || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="containerNumber"
                label={"Container Number"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="pickupNumber"
            control={control}
            defaultValue={data?.pickupNumber || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="pickupNumber"
                label={"Pickup Number"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="poNumber"
            control={control}
            defaultValue={data?.poNumber || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="poNumber"
                label={"PO Number"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="referenceNumber"
            control={control}
            defaultValue={data?.reference || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="referenceNumber"
                label={"Reference Number"}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={4} xs={12} md={4} lg={4}>
          <Controller
            name="manifest"
            control={control}
            defaultValue={data?.manifest || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="manifest"
                label={"Manifest"}
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={12} md={12} lg={12}>
          <Controller
            name="commodityDescription"
            control={control}
            defaultValue={data?.commodityDescription || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                multiline
                rows={3}
                id="commodityDescription"
                label={"Commodity Description"}
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Divider>
            <Chip label="Load Dimensions" />
          </Divider>
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="length"
            control={control}
            defaultValue={data?.length || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="length"
                label={translation("load.form.loadLength.label")}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="height"
            control={control}
            defaultValue={data?.height || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                inputProps={{ min: 0 }}
                fullWidth
                type="number"
                id="height"
                label={translation("load.form.loadHeight.label")}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="width"
            control={control}
            defaultValue={data?.width || ""}
            render={({ field }) => (
              <CustomTextField
                inputProps={{ min: 0 }}
                {...field}
                size="small"
                fullWidth
                type="number"
                id="width"
                label={translation("load.form.loadWidth.label")}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="volume"
            control={control}
            defaultValue={data?.volume || ""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                inputProps={{ min: 0 }}
                size="small"
                fullWidth
                type="number"
                id="volume"
                label={translation("load.form.loadVolume.label")}
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </form>
  );
};

export default LoadDetails;
