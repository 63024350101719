import { AppDispatch } from "../store";
import {
  setAllLookups,
  setSingleLookup,
  setAllLookupsFeatures,
  setOrderedLookups,
} from "../reducers/lookup";
import { LookupProps ,AddLookupProps} from "src/interfaces/lookup";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllLookups =
  (limit?: number, page?: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/lookup`) as any
    );
    const responseData = response.data;

    if (responseData?.data?.data) {
      dispatch(setAllLookups(responseData?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getAllLookupsByCategory =
  (category: string, limit?: number, page?: number) =>
  async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/lookup/category?category=${category}`) as any
    );
    // const responseData = response.data;

    if (response?.data?.data) {
      dispatch(setAllLookups(response?.data?.data));
    } else {
      dispatch(setAllLookups({}));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getLookupById = (id: number) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/lookup/${id}`) as any);
  if (response?.data?.data) {
    dispatch(setSingleLookup(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
export const addLookup =
  (data: LookupProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/lookup`, data) as any);

    if (response?.data?.data) {
      dispatch(setSingleLookup(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };

  export const addLookupByFeatureKey =
  (data: AddLookupProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/lookup/feature-key`, data) as any);

    if (response?.data?.data) {
      dispatch(setSingleLookup(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateLookup =
  (data: LookupProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/lookup/${id}`, data) as any);
    if (response?.data?.data?.lookup) {
      dispatch(setSingleLookup(response?.data?.data?.lookup));
    }
    return { success: response.success, data: response?.data?.data?.lookup };
  };

export const getAllLookupFeatures =
  (category: string) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/lookup-features/category?category=${category}`) as any
    );
    const responseData = response.data;
    if (responseData?.data?.data) {
      if (Array.isArray(responseData?.data?.data)) {
        dispatch(setAllLookupsFeatures(responseData?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };

export const reorderLookups =
  (id: number, order: number, type: string) =>
  async (dispatch: AppDispatch) => {
    const body = { order };
    const response: any = await dispatch(
      patchServerData(`lookup/reorder/${id}`, body) as any
    );
    const responseData = response.data;

    if (responseData?.data) {
      dispatch(
        setOrderedLookups({
          data: {
            lookups: responseData?.data,
            count: responseData?.data?.length,
          },
          type,
        })
      );
    }
    return { success: response.success, data: response?.data };
  };
