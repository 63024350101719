import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import DriverContainer from "src/containers/drivers";
const Drivers = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Drivers" description="Porto Drivers" />
      <DriverContainer />
    </Grid>
  );
};

export default Drivers;
