import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CustomerForm from "src/components/customer/index";
import { CustomerProps, CustomerBillingProps } from "src/interfaces/customer";
import {
  getCustomerById,
  addCustomer,
  updateCustomer,
} from "src/redux/actions/customer";
import {
  getCustomerBillingById,
  addCustomerBilling,
} from "src/redux/actions/customerBilling";

import { getAllTerminals } from "src/redux/actions/terminal";
import { resetCustomer } from "src/redux/reducers/customer";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { sanitizeEntity } from "src/utils/common";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { featureCustomers } from "src/utils/enums";
import CustomerReadForm from "../../components/customer/customerReadForm";

interface CustomerFormProps {
  handleClose?: () => any;
}
const CustomerFormContainer = ({
  handleClose,
}: CustomerFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const url = location.pathname.includes("customer");

  const getLookupsList = async () => {
    setLoading(true);
    await dispatch(getAllLookupsByCategory(featureCustomers, 0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getLookupsList();
  }, []);

  useEffect(() => {
    dispatch(resetCustomer());
    dispatch(getAllTerminals(0, 1));
  }, []);
  useEffect(() => {
    if (params?.customerId) {
      getSingleCustomer(parseInt(params?.customerId));
      getCustomerBilling(parseInt(params?.customerId));
    }
  }, [params?.customerId]);
  const getSingleCustomer = async (id: number) => {
    setPageLoading(true);
    await dispatch(getCustomerById(id));
    setPageLoading(false);
  };
  const getCustomerBilling = async (id: number) => {
    setPageLoading(true);
    await dispatch(getCustomerBillingById(id));
    setPageLoading(false);
  };
  const onCustomerBillingSubmit = async (data: CustomerBillingProps) => {
    setLoading(true);
    let objToSubmit: CustomerBillingProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.customerId) {
      objToSubmit["customer"] = parseInt(params?.customerId);
      //update
      await dispatch(addCustomerBilling(objToSubmit));
    }
    setLoading(false);
  };
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: CustomerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.customerId) {
      //update
      await dispatch(updateCustomer(objToSubmit, parseInt(params?.customerId)));
    } else {
      //add
      const customer = await dispatch(addCustomer(objToSubmit));
      if (url && customer.success && customer.data.id) {
        setTimeout(
          //   () => navigate(`/customer/customer/${customer.data.id}`),
          () => navigate("/customer/customer"),
          500
        );
      } else if (customer.success && customer.data.id && handleClose) {
        handleClose();
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <CustomerReadForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
          // onCustomerBillingSubmit={onCustomerBillingSubmit}
          // handleClose={handleClose}
          // url={url}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerFormContainer;
