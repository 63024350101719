import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { Edit, Add, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Typography,
  Fab,
  TextField,
  FormControl,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";

import SortIcon from "@mui/icons-material/Sort";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/lookupDataTable";
import { CircularProgress } from "@mui/material";

import { useAppSelector, useAppDispatch } from "src/hooks/reduxHooks";
import {
  selectLookups,
  selectLookupsFeatures,
} from "src/redux/reducers/lookup";
import { useTranslation } from "react-i18next";
import { LookupProps } from "src/interfaces/lookup";
import Table from "src/components/lookup/reorderTable";
import { featureLookups, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import {
  getAllLookupsByCategory,
  getAllLookupFeatures,
} from "src/redux/actions/lookup";
import { useQuery } from "src/hooks/useQuery";
import { toNumber } from "lodash";
import { useStyles } from "src/styles/formStyles";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Lookups() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const query = useQuery();
  const category = query.get("category") ? query.get("category") : "";
  const [sort, setSort] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>(
    "certification-program-name"
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("common");
  const [allLookups, setAllLookups] = useState<Array<LookupProps>>([]);
  const lookupsFeatures = useAppSelector(selectLookupsFeatures);
  const lookups = useAppSelector(selectLookups);

  const user = useAppSelector(selectUser);

  const [lookupData, setLookupData] = useState<any>([]);
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);

  const getLookupsList = async () => {
    setLoading(true);
    // setRowsState && setRowsState((prev: any) => ({ ...prev, loading: true }));

    await dispatch(
      getAllLookupFeatures(selectedCategory ? selectedCategory : "")
    );
    await dispatch(
      getAllLookupsByCategory(
        selectedCategory ? selectedCategory : "",
        0,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
    // setRowsState && setRowsState((prev: any) => ({ ...prev, loading: true }));
  };

  function pageExists(page: any) {
    return lookupData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  }

  useEffect(() => {
    setSelectedType("");
    getLookupsList();
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedType("");
    getLookupsList();
  }, []);

  useEffect(() => {
    if (
      !loading &&
      lookups.data &&
      Object.keys(lookups.data).length > 0 &&
      lookupsFeatures?.length > 0
    ) {
      if (pageExists(rowsState?.page + 1) == false) {
        if (!selectedType) {
          const type: string = Object.keys(lookups.data)[0];
          setSelectedType(type);
          setAllLookups(lookups.data[type]?.lookups);

          // setLookupData([...lookupData, lookups.data[type]?.lookups]);
        } else {
          setAllLookups(lookups.data[selectedType]?.lookups);
          // setLookupData([...lookupData, lookups.data[selectedType]?.lookups]);
        }
        if (lookups.data == {}) {
          setAllLookups([]);
        }
      }
    } else {
      setAllLookups([]);
    }
  }, [lookups, selectedType, lookupsFeatures, loading]);

  /*  useEffect(() => {
    if (
      !loading &&
      Object.keys(lookups).length > 0 &&
      lookupsFeatures?.length > 0
    ) {
      if (!selectedType) {
        const type: string = Object.keys(lookups)[0];
        setSelectedType(type);
        setAllLookups(lookups[type]?.lookups);
      } else {
        setAllLookups(lookups[selectedType]?.lookups);
      }
    }
  }, [lookups, selectedType, lookupsFeatures, loading]); */

  const selectLookupType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedType(e.target.value);
    if (lookups[e.target.value]) {
      setAllLookups(lookups[e.target.value]?.lookups);
    } else {
      setAllLookups([]);
    }
  };

  const selectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(e.target.value);
    /*   if (lookups[e.target.value]) {
      setAllLookups(lookups[e.target.value]?.lookups);
    } else {
      setAllLookups([]);
    } */
  };

  const addLookup = () => {
    navigate(`/config/lookups/create?category=${selectedCategory}`);
  };
  const handleSort = () => {
    setSort(!sort);
  };

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      minWidth: 100,
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(
              `${location.pathname}/${params?.row?.id}?category=${selectedCategory}`
            );
          }}
        />,
      ],
    },
    { field: "id", headerName: translation("common.id.label"), minWidth: 50 },
    {
      field: "order",
      headerName: translation("lookup.order.label"),
      minWidth: 50,
    },
    {
      field: "content",
      headerName: translation("lookup.content.label"),
      flex: 1,
      minWidth: 200,
    },
    {
      field: "feature",
      headerName: translation("lookup.type.label"),
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.feature?.name}</div>;
      },
    },
  ];
  const Header: React.FC = () => {
    return (
      <Grid container alignItems="flex-end" style={{ marginTop: "50px" }}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              onClick={() => navigate("/")}
              sx={{ margin: "2px" }}
            >
              <Button>
                <ArrowBack />
              </Button>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0",
                  textTransform: "capitalize",
                }}
                gutterBottom
              >
                {translation("lookup.list.title")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            {allLookups?.length > 1 && (
              <Box sx={{ margin: "2px" }}>
                <Fab
                  color="secondary"
                  sx={{
                    mr: 1,
                    ml: 1,
                    mb: {
                      xs: 1,
                      sm: 0,
                      lg: 0,
                    },
                  }}
                  size="small"
                  onClick={handleSort}
                >
                  <SortIcon />
                </Fab>
                {createPermission > -1 && (
                  <Fab
                    color="secondary"
                    sx={{
                      mr: 1,
                      ml: 1,
                      mb: {
                        xs: 1,
                        sm: 0,
                        lg: 0,
                      },
                    }}
                    size="small"
                    onClick={addLookup}
                  >
                    <Add />
                  </Fab>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        <CustomFormGrid container justifyContent="center" alignItems="center">
          <CustomFormGrid item xs={6} md={6} lg={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              // sx={{ width: "70%" }}
              id="feature"
              label={"Select Category"}
              select
              onChange={selectCategory}
              value={selectedCategory}
            >
              <MenuItem value={"driver"}>Driver</MenuItem>
              <MenuItem value={"owner"}>Owner</MenuItem>
              <MenuItem value={"vehicle"}>Vehicle</MenuItem>
              <MenuItem value={"trailer"}>Trailer</MenuItem>
              <MenuItem value={"terminal"}>Terminal</MenuItem>
              <MenuItem value={"load"}>Load</MenuItem>
              <MenuItem value={"customer"}>Customer</MenuItem>
              <MenuItem value={"common"}>All</MenuItem>
            </TextField>
          </CustomFormGrid>
          <CustomFormGrid item xs={6} md={6} lg={6}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              //   sx={{ width: "70%" }}
              id="feature"
              label={translation("lookup.feature.label")}
              select
              onChange={selectLookupType}
              value={selectedType}
            >
              {lookupsFeatures.map((item, index) => (
                <MenuItem value={item.key} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </CustomFormGrid>
        </CustomFormGrid>
        <CustomFormGrid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "0px" }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: "0",
              textTransform: "capitalize",
            }}
            gutterBottom
          >
            {translation("lookup.list.detail")}
          </Typography>
        </CustomFormGrid>
      </Grid>
    );
  };

  return (
    <>
      {sort ? (
        <Table
          data={allLookups}
          handleSort={handleSort}
          selectedType={selectedType}
        />
      ) : (
        <>
          {loading ? (
            <div className={classes.loadingStyle}>
              <CircularProgress />
            </div>
          ) : (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <DataTable
                title={translation("lookup.list.title")}
                rows={allLookups}
                columns={columns}
                loading={loading}
                onClickAdd={addLookup}
                ComponentToRender={<Header />}
                rowsState={rowsState}
                setRowsState={setRowsState}
                pagination={lookups.pagination}
                data={lookupData}
                setData={setLookupData}
                localPagination={true}
                add={true}
              />
            </Box>
          )}
          <Grid
            container
            justifyContent="flex-end"
            sx={{ margin: "10px" }}
          ></Grid>
        </>
      )}
    </>
  );
}
