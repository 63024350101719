import React, {useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import CustomForm from "src/components/customElements/customForm";
import { useForm, Controller } from "react-hook-form";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import CustomTextField from "src/components/customElements/customTextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { CustomerProps } from "src/interfaces/customer";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import {cancelLoad} from "src/redux/actions/loadManagement";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { CancelLoadProps } from "src/interfaces/loadManagement";
import { sanitizeEntity } from "src/utils/common";
import { useParams, useNavigate } from "react-router-dom";



 interface DialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
     loadId: any;

}

export default function AddCustomerDialogue({
    open,
    handleClose,
    title,
    loadId,
}: // ComponentToRender,
DialogProps): JSX.Element {
    const classes = useStyles();
    const [translation] = useTranslation("translation");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);


    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
        getValues,
    } = useForm();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };


      const onSubmit = async (data: any) => {
        setLoading(true);
        let objToSubmit: CancelLoadProps = { ...data };
          objToSubmit = sanitizeEntity(objToSubmit);
          objToSubmit.loadId = loadId;
          objToSubmit.password = objToSubmit.password ? objToSubmit.password : "";

          //add
        const response = await dispatch(cancelLoad(objToSubmit));
        if (response.success ) {

           setTimeout(() => navigate("/load-management/load-management"), 500);
         }
        setLoading(false);
      };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle className={classes.loadFormHeadings}>{title}</DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to cancel load : {loadId}? please confirm your password.

              </DialogContent>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>

                  <Controller
              name="password"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  size="small"
                  label={translation("login.password.label")}
                  type={showPassword ? "text" : "password"}
                  error={Boolean(errors?.password)}
                  helperText={
                    errors?.password && translation("login.password.message")
                  }
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

        </DialogContent>
        <DialogActions>
        <CustomFormGrid container justifyContent="center">
            <Button
              color="primary"
              variant="contained"
                              className={classes.formButton}
                              type="submit"
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />
              ) : "Confirm"}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() =>
                handleClose()
              }
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
              </DialogActions>
              </CustomForm>

      </Dialog>
    </div>
  );
}
