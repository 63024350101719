import React from "react";
import { InputLabel, MenuItem } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/lab";
import CustomSwitch from "src/components/customElements/customSwitch";
import { split_commission, simple_commission } from "src/utils/enums";
import { commissionPrograms } from "src/utils/dropdowns/terminals";

interface CommissionInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
}
const CommissionInfo = ({
  control,
  errors,
  watch,
}: CommissionInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="commissionProgram"
            control={control}
            defaultValue={""}
           // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                variant="outlined"
                fullWidth
                id="commissionProgram"
                label={translation("terminals.form.commissionProgram.label")}
                error={Boolean(errors?.commissionProgram)}
                select
                helperText={
                  errors?.commissionProgram &&
                  translation("terminals.form.commissionProgram.message")
                }
             //   required
              >
                {commissionPrograms &&
                  commissionPrograms.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>

         <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="dateOpened"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("terminals.form.dateOpened.label")}
                inputFormat="MM/dd/yyyy"
                disableFuture
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="dateOpened"
                    error={Boolean(errors?.dateOpened)}
                    helperText={
                      errors?.dateOpened &&
                      translation("terminals.form.dateOpened.message")
                    }
                    required
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="dateClosed"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("terminals.form.dateClosed.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    variant="outlined"
                    fullWidth
                    id="dateClosed"
                    error={Boolean(errors?.dateClosed)}
                    helperText={
                      errors?.dateClosed &&
                      translation("terminals.form.dateClosed.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        {watch("commissionProgram") === split_commission && (
          <CustomFormGrid
            item
            sx={{
              padding: "5px",
              display: "flex",
              alignItems: "center",
            }}
            sm={6} xs={12} md={6} lg={6}
          >
            <Controller
              name="systemTruckCommission"
              control={control}
              defaultValue={""}
            //  rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  variant="outlined"
                  sx={{ width: "95%", mr: 2 }}
                  id="systemTruckCommission"
                  label={translation(
                    "terminals.form.systemTruckCommission.label"
                  )}
                  error={Boolean(errors?.systemTruckCommission)}
                  helperText={
                    errors?.systemTruckCommission &&
                    translation("terminals.form.systemTruckCommission.message")
                  }
               //   required
                />
              )}
            />
            %
          </CustomFormGrid>
        )}
        {watch("commissionProgram") === simple_commission && (
          <CustomFormGrid
            item
            sx={{
              padding: "5px",
              display: "flex",
              alignItems: "center",
            }}
            sm={6} xs={12} md={6} lg={6}
          >
            <Controller
              name="houseCommission"
              control={control}
              defaultValue={""}
           //   rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  variant="outlined"
                  sx={{ width: "95%", mr: 2 }}
                  id="houseCommission"
                  label={translation("terminals.form.houseCommission.label")}
                  error={Boolean(errors?.houseCommission)}
                  helperText={
                    errors?.houseCommission &&
                    translation("terminals.form.houseCommission.message")
                  }
                //  required
                />
              )}
            />
            %
          </CustomFormGrid>
        )}
        {watch("commissionProgram") === split_commission && (
          <CustomFormGrid
            item
            sx={{
              padding: "5px",
              display: "flex",
              alignItems: "center",
            }}
            sm={6} xs={12} md={6} lg={6}
          >
            <Controller
              name="terminalTruckCommission"
              control={control}
              defaultValue={""}
            //  rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  variant="outlined"
                  sx={{ width: "95%", mr: 2 }}
                  id="terminalTruckCommission"
                  label={translation(
                    "terminals.form.terminalTruckCommission.label"
                  )}
                  error={Boolean(errors?.terminalTruckCommission)}
                  helperText={
                    errors?.terminalTruckCommission &&
                    translation(
                      "terminals.form.terminalTruckCommission.message"
                    )
                  }
               //   required
                />
              )}
            />
            %
          </CustomFormGrid>
        )}

        <CustomFormGrid
          item
          sm={6} xs={12} md={6} lg={6}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Controller
            name="calculateCommission"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("terminals.form.calculateCommission.label")}
                </InputLabel>
                <CustomSwitch
                  checked={
                    watch("calculateCommission")
                      ? watch("calculateCommission")
                      : false
                  }
                  {...field}
                  id="calculateCommission"
                />
              </>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6} xs={12} md={6} lg={6}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Controller
            name="shareDriverInfo"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("terminals.form.shareDriverInfo.label")}
                </InputLabel>
                <CustomSwitch
                  checked={
                    watch("shareDriverInfo") ? watch("shareDriverInfo") : false
                  }
                  {...field}
                  id="shareDriverInfo"
                />
              </>
            )}
          />
        </CustomFormGrid>


      </CustomFormGrid>
    </div>
  );
};

export default CommissionInfo;
