import React, { useState} from "react";
import {Typography, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
//import LockIcon from "@mui/icons-material/Lock";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StopIcon from "@mui/icons-material/Stop";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
/*import SpeedIcon from "@mui/icons-material/Speed";
 import SearchIcon from "@mui/icons-material/Search";*/
import JumpDialogBox from "src/components/settlement/jumpAccountModel";
import { getAllAccounts } from "src/redux/actions/settlement";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { selectDashboard } from "src/redux/reducers/settlement";
import { useAppSelector } from "src/hooks/reduxHooks";

const Tools = () => {
  const navigate = useNavigate();
  const [translation] = useTranslation("translation");
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector(selectDashboard);

  const handleOpen = () => {
    setOpen(true);
    dispatch(getAllAccounts());
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        <CardContent
          sx={{
            //  paddingBottom: "16px !important",
            justifyContent: "center",
          }}
        >
          <CustomFormGrid item xs={6} lg={6} md={6} sm={6}>
            <Typography className={classes.loadStopCardHeadings}>
              {translation("settlement.dashboard.tools")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid container>
            <CustomFormGrid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex" }}
              onClick={() => {
                handleOpen();
              }}
            >
              <AccountCircleIcon className={classes.settlementIconStyle} />
              <Typography className={classes.settlementTooltext}>
                {translation("settlement.dashboard.account")}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex" }}
              onClick={() => {
                navigate("/settlements/earning-deduction/create");
              }}
            >
              <AddIcon className={classes.settlementIconStyle} />
              <Typography className={classes.settlementTooltext}>
                {translation("settlement.dashboard.addEarning")}
              </Typography>
            </CustomFormGrid>
            {/*  <CustomFormGrid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                style={{ display: "flex" }}
              >
                <LockIcon className={classes.settlementIconStyle} />
                <Typography className={classes.settlementTooltext}>
               {translation("settlement.dashboard.frozen")}
                </Typography>
              </CustomFormGrid> */}
            <CustomFormGrid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex" }}
              onClick={() => {
                navigate("/settlements/settlement-driver");
              }}
            >
              <HomeIcon className={classes.settlementIconStyle} />
              <Typography className={classes.settlementTooltext}>
               { translation("settlement.dashboard.companyDriverSet") + " " + `(${dashboardData?.unsettledDriverCount ?dashboardData?.unsettledDriverCount : 0 })`}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex" }}
              onClick={() => {
                navigate("/settlements/settlement-owner");
              }}
            >
              <AssignmentIndIcon className={classes.settlementIconStyle} />
              <Typography className={classes.settlementTooltext}>
                { translation("settlement.dashboard.owner") + " " + `(${dashboardData?.unsettledOwnerCount ?dashboardData?.unsettledOwnerCount : 0 })`}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex" }}
              onClick={() => {
                navigate("/settlements/settlement-terminal");
              }}
            >
              <StopIcon className={classes.settlementIconStyle} />
              <Typography className={classes.settlementTooltext}>
                {translation("settlement.dashboard.terminal") + " " + `(${dashboardData?.unsettledTerminalCount ?dashboardData?.unsettledTerminalCount : 0 })`}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid
              item
              xs={12}
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex" }}
              onClick={() => {
                navigate("/load-management/load-management/create");
              }}
            >
              <AddCircleOutlinedIcon className={classes.settlementIconStyle} />
              <Typography className={classes.settlementTooltext}>
                {translation("load.add.form.title")}
              </Typography>
            </CustomFormGrid>
            {/*   <CustomFormGrid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                style={{ display: "flex" }}
              >
                <SpeedIcon className={classes.settlementIconStyle} />
                <Typography className={classes.settlementTooltext}>
               {translation("settlement.dashboard.milage")}
                </Typography>
                  </CustomFormGrid>
                  <CustomFormGrid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                style={{ display: "flex" }}
              >
                <SearchIcon className={classes.settlementIconStyle} />
                <Typography className={classes.settlementTooltext}>
               {translation("settlement.dashboard.find")}
                </Typography>
              </CustomFormGrid> */}
          </CustomFormGrid>
        </CardContent>
      </Card>

      <JumpDialogBox open={open} handleClose={handleClose} />
    </>
  );
};

export default Tools;
