import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { selectDriver } from "src/redux/reducers/driver";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import ModelInput from "src/components/common/InformationModels/inputModel";
import { Close } from "@mui/icons-material";
import { useStyles } from "src/styles/formStyles";
import CustomGrid from "src/components/customElements/customFormGrid";
import StatusBadge from "src/components/common/StatusBadge";
import { dateFormat } from "src/utils/constants";
import moment from "moment";
import LoadInformationModel from "src/components/common/InformationModels/loadInfoModel";
import { getOwnerById} from "src/redux/actions/owner";
import { selectOwner } from "src/redux/reducers/owner";

import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import { getDriverById } from "src/redux/actions/driver";

interface DialogProps {
  open: boolean;
  handleClose: () => void;

  ownerId: number;
}

export default function ConfirmationDialog({
  open,
  handleClose,
  ownerId,
}: DialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [translation] = useTranslation("translation");
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [openModel, setOpenModel] = useState<boolean>(false);
  const driver = useAppSelector(selectDriver);
  const owner = useAppSelector(selectOwner);

  const getSingleOwner = async (id: number) => {
    setPageLoading(true);
    await dispatch(getOwnerById(id));
    setPageLoading(false);
  };

  useEffect(() => {
    if (ownerId) {
      getSingleOwner(ownerId);
    }
  }, [ownerId]);


  const handleOpenModel = (loadId: number) => {
    setOpenModel(true);
    setSelectedData(loadId);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
    setSelectedData(null);
  };
  return (
    <div style={{ padding: "20px" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomFormGrid container className={classes.modelContainer}>
          <CustomFormGrid item xs={11} sm={11} md={11} lg={11}>
            <Typography variant="h6" className={classes.loadFormMainHeadings}>
              {translation("owner.form.title1")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item xs={1} sm={1} md={1} lg={1}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomFormGrid>
        </CustomFormGrid>

        <DialogContent
          sx={{ paddingBottom: "20px", paddingTop: "0px", margin: "0px" }}
        >
          {pageLoading ? (
            <CustomFormGrid
              container
              justifyContent="center"
              style={{ height: "100px" }}
            >
              <CircularProgress />
            </CustomFormGrid>
          ) : (
            <>
              {owner && (
                <>
                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={"Owner Id"}
                        input={owner?.id ? owner?.id : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <CustomGrid
                        container
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                              pl: 1,
                              fontWeight: 500,
                              color: "#777E89",
                              fontSize: "14px",
                              lineHeight: "14px",
                            }}
                          >
                            Status
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {owner?.status && (
                              <StatusBadge label={owner?.status} />
                            )}
                          </Typography>
                        </CustomGrid>
                      </CustomGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  {/*Row 2  */}

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.firstName.label")}
                        input={owner?.firstName ? owner?.firstName : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.lastName.label")}
                        input={owner?.lastName ? owner?.lastName : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={3.8}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={"Settlement Day"}
                        input={
                          owner?.settlementDay
                            ?  owner?.settlementDay
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>

                  <Divider variant="middle" />

                  {/* Row 3 */}

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.address.label")}
                        input={owner?.address ? owner?.address : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.city.label")}
                        input={owner?.city ? owner?.city : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      lg={3}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.zip.label")}
                        input={owner?.zip ? owner?.zip : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={2.5}
                      sm={2.5}
                      md={2.5}
                      lg={2.8}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.state.label")}
                        input={owner?.state ? owner?.state : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>

                  <Divider variant="middle" />

                  {/* row 4 */}
                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.email.label")}
                        input={owner?.email ? owner?.email : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("common.phone.label")}
                        input={
                          owner?.phone
                            ? owner?.phone
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />




                  {/* row 6*/}
                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={"Vehicle"}
                        input={
                          owner?.vehicle != null
                            ? owner?.vehicle?.vehicleId
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={translation("driver.form.homeTerminal.label")}
                        input={
                          owner?.terminal != null
                            ? owner?.terminal?.title
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>

                </>
              )}
              {openModel === true && (
                <LoadInformationModel
                  open={openModel}
                  handleClose={handleCloseModel}
                  loadId={selectedData}
                />
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
