import React, { useState, useEffect } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  Autocomplete,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from "@mui/material";
import { stopTypes } from "src/utils/dropdowns/load";
import { selectUsers } from "src/redux/reducers/users";
import { useAppSelector, useAppDispatch } from "src/hooks/reduxHooks";
import { phone } from "phone";
import { validatePhoneNumber } from "src/utils/common";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CustomDateTimePicker from "../customElements/customDatetimePicker";

import {
  StopOff, LoadStopShipperInformation,
} from "src/interfaces/loadManagement";
import "react-phone-input-2/lib/style.css";
import { CustomerProps } from "src/interfaces/customer";
import { LookupProps } from "src/interfaces/lookup";
import { selectLookups } from "src/redux/reducers/lookup";
import { selectCommodities } from "src/redux/reducers/commodity";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { formattedPhoneNumber } from "src/utils/common";
import { useStyles } from "src/styles/formStyles";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeleteIcon from '@mui/icons-material/Delete';

import useMediaQuery from "@mui/material/useMediaQuery";
import { useForm } from "react-hook-form";
// import usStates from "src/utils/dropdowns/usStates";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { selectLoadAddresses } from "src/redux/reducers/loadAddress";
import { getAllLoadAddresses } from "src/redux/actions/loadAddress";
import { getAllCommodities } from "src/redux/actions/commodity";
import { selectStates, selectCity, selectCities } from "src/redux/reducers/shared";
import { getStates, getCityByZipCode, getCitiesByState } from "src/redux/actions/shared";
import { City, State } from "src/interfaces/shared";


const companyAutoCompleteFilter = createFilterOptions<CompanyAutoCompleteProps>();

interface CompanyAutoCompleteProps {
  inputValue?: string;
  company: string;
}

interface StopOffProps {
  done(error: any | null, data: Array<StopOff>): any;
  stepId?: number,
  data?: Array<StopOff> | null
}

const StopOffInfo = ({
  done,
  stepId,
  data,
}: StopOffProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const allLookups = useAppSelector(selectLookups);
  const loadAddresses = useAppSelector(selectLoadAddresses);

  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const commodities = useAppSelector(selectCommodities);
  const [expanded, setExpanded] = useState<string | false>("panel-0");

  const usStates = useAppSelector(selectStates);
  const usCities = useAppSelector(selectCities);
  let city = useAppSelector(selectCity);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();

  const { fields, append, remove } = useFieldArray({ control, name: "stopOffs" });


  useEffect(() => {
    dispatch(getStates());
    dispatch(getAllLoadAddresses());
    dispatch(getAllCommodities());

    if (Array.isArray(data)) {
      data?.forEach(stopOffValue => {
        handleAddStopOff(stopOffValue);
      });
    }


  }, [])

  const onSubmit = (data: any) => {
    console.log("Stop-off---------------", data);
    if (data && data.stopOffs) done(null, data.stopOffs);
  }

  const handleAddStopOff = (data: StopOff | null) => {
    append({
      "type": data && data.type ? data.type : "",
      "serviceLevel": data && data.serviceLevel ? data.serviceLevel : {
        "content": ""
      },
      "arrivalDate": data && data.arrivalDate ? data.arrivalDate : "",
      "deliveryDate": data && data.deliveryDate ? data.deliveryDate : "",
      "scheduledDate": data && data.scheduledDate ? data.scheduledDate : "",
      "reference": data && data.reference ? data.reference : "",
      "pieces": data && data.pieces ? data.pieces : "",
      "weight": data && data.weight ? data.weight : "",
      "address": {
        "company": data && data.address ? data.address.company : {
          "company": "",
        },
        "city": data && data.address ? data.address.city : "",
        "state": data && data.address ? data.address.state : "",
        "zip": data && data.address ? data.address.zip : "",
        "email": data && data.address ? data.address.email : "",
        "phoneNumber": data && data.address ? data.address.phoneNumber : "",
        "faxPhoneNumber": data && data.address ? data.address.faxPhoneNumber : "",
        "address": data && data.address ? data.address.address : "",
        "directionNotes": data && data.address ? data.address.directionNotes : ""
      }
    });
    // addStopOffs(stopOffs + 1);
  }

  const handleExpandedChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const serviceLevels: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["service-level"]?.lookups
      ? allLookups?.data["service-level"]?.lookups
      : [];

  const handleZipChange = async (index: number, zipCode: string) => {

    if (zipCode.length >= 5) {
      console.log("Loading city ==========")
      const foundCity = await dispatch(getCityByZipCode(zipCode));
      city = foundCity.data
      if (city) {
        console.log("Filtering state ==========")
        const selectedState = usStates.filter(e => e.abbreviation == city?.state);
        if (selectedState && selectedState.length > 0) {
          console.log("Filtering cities ==========")
          setValue(`stopOffs[${index}].address.state`, selectedState[0]);
          await dispatch(getCitiesByState(selectedState[0].abbreviation));
          setValue(`stopOffs[${index}].address.city`, city);
        }
      }
    }
  }

  const handleStateChange = async (stateObj: State) => {
    await dispatch(getCitiesByState(stateObj.abbreviation));
  }

  const handleCityChange = async (index: number, cityObj: City) => {
    if (cityObj && cityObj.zip_code) setValue(`stopOffs[${index}].address.zip`, cityObj.zip_code);
  }


  const onChangePhoneInput = (
    value: string,
    country: string,
    key: string,
    shipper: boolean,
    fieldName: string
  ) => {
    const phoneNumber = formattedPhoneNumber(value, country);
    // setValue(`stops[${index}].${key}Value`, value);
    // setValue(`stops[${index}].${key}Country`, country);
    // @ts-ignore: Unreachable code error
    // handleLoadStopChangeForShipper(phoneNumber, fieldName, shipper);
    if (errors && errors?.stops) {
      // delete errors?.stops[index][key];
    }
  };



  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: 'flex-end', mb: 2 }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => { handleAddStopOff(null) }}
        >
          Stop Off
        </Button>
      </Box>
      <form id={`load-form-${stepId}`} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: "flex", justifyContent: 'center', mb: 2 }}>
          <Controller
            name={`noOfStopOffs`}
            control={control}
            defaultValue={fields.length}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                id="noOfStopOffs"
                disabled={true}
                label={`No Of Stop Offs`}
                value={fields.length}
              />
            )}
          />
        </Box>

        {
          fields.map((item: any, index) => {
            return (
              <Accordion
                key={`item-${index}`}
                expanded={expanded === `panel-${index}`}
                onChange={handleExpandedChange(`panel-${index}`)}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography>Stop Off - {index + 1}</Typography>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="primary"
                      sx={{ mr: 2 }}
                      onClick={() => { { remove(index) } }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomFormGrid container>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <CustomFormGrid item sm={12} xs={12} md={6} lg={12}>
                        <Controller
                          name={`stopOffs[${index}].type`}
                          control={control}
                          defaultValue={`${item?.type}`}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              id="type"
                              label={translation("load.form.stopType.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.type)}
                              select
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.type &&
                                translation("load.form.stopType.message")
                              }
                            >
                              {
                                stopTypes.map((item, index) => (
                                  <MenuItem value={item.value} key={index}>
                                    {item.label}
                                  </MenuItem>
                                ))
                              }
                            </CustomTextField>
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].address.company`}
                          control={control}
                          defaultValue={`${item?.address.company}`}
                          rules={{
                            required: true
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              id="stop-off-company-ac"
                              value={value}
                              fullWidth
                              // onChange={(event, newValue) => onChange(newValue)}
                              onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  onChange({
                                    company: newValue
                                  });
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  onChange({
                                    company: newValue.inputValue,
                                  });
                                } else {
                                  onChange(newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = companyAutoCompleteFilter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.company);
                                if (inputValue !== '' && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    company: `Add "${inputValue}"`,
                                  });
                                }

                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              options={loadAddresses}
                              getOptionLabel={(option) => {
                                // // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                // // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.company || "";
                              }}

                              renderOption={(props, option) => <li {...props}>{option.company}</li>}
                              freeSolo
                              renderInput={(params: any) => (
                                <CustomTextField
                                  {...params}
                                  id="stop-off-company-txt"
                                  size="small"
                                  label={"Company Name"}
                                  onChange={onChange}
                                  error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.company)}
                                  helperText={
                                    errors &&
                                    errors?.stopOffs &&
                                    errors?.stopOffs[index]?.address?.company &&
                                    "Company is required."
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={3} xs={12} md={3} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].address.address`}
                          control={control}
                          defaultValue={`${item?.address.address}`}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              id="address"
                              label={translation("common.address.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.address)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.address?.address &&
                                "Address is required."
                              }
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].address.zip`}
                          control={control}
                          defaultValue={`${item?.address.zip}`}
                          rules={{
                            required: true
                          }}
                          render={({ field: { onChange, ...restFields } }) => (
                            <CustomTextField
                              {...restFields}
                              size="small"
                              fullWidth
                              id="zip"
                              type="number"
                              onChange={(e: any) => {
                                onChange(e.target.value);
                                handleZipChange(index, e.target.value);
                              }}
                              inputProps={{ min: 0 }}
                              label={translation("common.zip.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.zip)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.address?.zip &&
                                "Zip is required."
                              }

                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].address.state`}
                          control={control}
                          defaultValue={`${item?.address.state}`}
                          rules={{
                            required: true
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              id="state"
                              fullWidth
                              options={usStates}
                              onChange={(event, inputValue) => {
                                onChange(inputValue)
                                handleStateChange(inputValue);
                              }}
                              value={value}
                              getOptionLabel={(option) => {
                                return option ? `${option.abbreviation} - ${option.name}` : '';
                              }}
                              renderInput={(params: any) => (
                                <CustomTextField
                                  {...params}
                                  id="txt-state"
                                  size="small"
                                  label={`State`}
                                  onChange={onChange}
                                  error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.state)}
                                  helperText={
                                    errors &&
                                    errors?.stopOffs &&
                                    errors?.stopOffs[index]?.address?.state &&
                                    "State is required."
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].address.city`}
                          control={control}
                          defaultValue={`${item?.address.city}`}
                          rules={{
                            required: true
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              fullWidth
                              options={usCities || []}
                              onChange={(event, inputValue) => {
                                onChange(inputValue);
                                handleCityChange(index, inputValue);
                              }}
                              value={value}
                              getOptionLabel={(option) => {
                                return option ? `${option.city} (${option.zip_code})` : '';
                              }}
                              renderInput={(params: any) => (
                                <CustomTextField
                                  {...params}
                                  size="small"
                                  label={`City`}
                                  onChange={onChange}
                                  error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.city)}
                                  helperText={
                                    errors &&
                                    errors?.stopOffs &&
                                    errors?.stopOffs[index]?.address?.city &&
                                    "City is required."
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].address.email`}
                          control={control}
                          defaultValue={`${item?.address.email}`}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              id="email"
                              label={translation("common.email.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.email)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.address?.email &&
                                translation("common.email.message")
                              }
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={6} lg={6} className={classes.phoneField}>
                        <Controller
                          name={`stopOffs[${index}].address.phoneNumber`}
                          control={control}
                          defaultValue={`${item?.address.phoneNumber}`}
                          rules={{
                            validate: {
                              isValid: () => {
                                if (watch(`stopOffs[${index}].address.phoneNumber`)) {
                                  console.log(watch(`stopOffs[${index}].address.phoneNumber`))
                                  return !phone("+" + watch(`stopOffs[${index}].address.phoneNumber`)).isValid ? "Enter valid phone number" : true
                                } else {
                                  return true;
                                }
                              },
                            }
                          }}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              label={translation("common.phone.label")}
                              placeholder={translation("common.phone.label")}
                              value={value}
                              error={Boolean(
                                errors?.stopOffs && errors?.stopOffs && errors?.stopOffs[index]?.address?.phoneNumber
                              )}
                              onChange={onChange}
                              country="us"
                              isValid={(value: any, country: any) => {
                                return errors && errors?.stopOffs && errors?.stopOffs[index]?.address?.phoneNumber
                                  ? validatePhoneNumber(value, country.iso2)
                                  : true;
                              }}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={6} lg={6} className={classes.phoneField}>
                        <Controller
                          name={`stopOffs[${index}].address.faxPhoneNumber`}
                          control={control}
                          defaultValue={`${item?.address.faxPhoneNumber}`}
                          rules={{
                            validate: {
                              isValid: () => {
                                if (watch(`stopOffs[${index}].address.faxPhoneNumber`)) {
                                  console.log(watch(`stopOffs[${index}].address.faxPhoneNumber`))
                                  return !phone("+" + watch(`stopOffs[${index}].address.faxPhoneNumber`)).isValid ? "Enter valid phone number" : true
                                } else {
                                  return true;
                                }
                              },
                            }
                          }}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              label={translation("common.faxPhoneNumber.label")}
                              placeholder={translation("common.faxPhoneNumber.label")}
                              value={value}
                              onChange={onChange}
                              country="us"
                              isValid={(value: any, country: any) => {
                                return errors?.stopOffs && errors?.stopOffs[index]?.address?.faxPhoneNumber
                                  ? validatePhoneNumber(value, country.iso2)
                                  : true;
                              }}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                        <Controller
                          name={`stopOffs[${index}].serviceLevel`}
                          defaultValue={`${item?.serviceLevel}`}
                          control={control}
                          rules={{
                            required: true
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              id="combo-box-demo"
                              value={value}
                              fullWidth
                              options={serviceLevels || []}
                              onChange={(event, inputValue) => { onChange(inputValue) }}
                              getOptionLabel={(option) => option?.content}
                              renderInput={(params: any) => (
                                <CustomTextField
                                  {...params}
                                  id="serviceLevel"
                                  size="small"
                                  onChange={onChange}
                                  label={translation("load.form.serviceLevel.label")}
                                  error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.serviceLevel)}
                                  helperText={
                                    errors &&
                                    errors?.stopOffs &&
                                    errors?.stopOffs[index]?.serviceLevel &&
                                    "Service Level is required."
                                  }
                                  required
                                />
                              )}
                            />
                          )}
                        />

                      </CustomFormGrid>

                      <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].arrivalDate`}
                          control={control}
                          defaultValue={`${item?.arrivalDate}`}
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <CustomDateTimePicker
                              timeLabel="Arrival Time"
                              dateLabel="Arrival Date"
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].deliveryDate`}
                          control={control}
                          defaultValue={item?.deliveryDate}
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <CustomDateTimePicker
                              timeLabel="Delivery Time"
                              dateLabel="Delivery Date"
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].scheduledDate`}
                          control={control}
                          defaultValue={item?.scheduledDate}
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <CustomDateTimePicker
                              timeLabel="Schedule Time"
                              dateLabel="Schedule Date"
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].commodity`}
                          control={control}
                          defaultValue={item?.commodity}
                          rules={{ 
                            required: true 
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              id="combo-box-demo"
                              fullWidth
                              options={commodities}
                              value={value}
                              onChange={(event, inputValue) => { onChange(inputValue) }}
                              getOptionLabel={(option) => option?.commodity}
                              renderInput={(params: any) => (
                                <CustomTextField
                                  {...params}
                                  id="commodity"
                                  size="small"
                                  onChange={onChange}
                                  label={translation("load.form.commodity.label")}
                                  error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.commodity)}
                                  helperText={
                                    errors &&
                                    errors?.stopOffs &&
                                    errors?.stopOffs[index]?.commodity &&
                                    "Commodity is required."
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].reference`}
                          control={control}
                          defaultValue={`${item?.reference}`}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              type="number"
                              inputProps={{ min: 0 }}
                              id="reference"
                              label={translation("load.form.reference.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.reference)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.reference &&
                                translation("load.form.reference.message")
                              }
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                        <Controller
                          name={`stopOffs[${index}].pieces`}
                          control={control}
                          defaultValue={item?.pieces}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              type="number"
                              inputProps={{ min: 0 }}
                              id="pieces"
                              label={translation("load.form.pieces.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.pieces)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.pieces && translation("load.form.pieces.message")
                              }
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                        <Controller
                          name={`stopOffs[${index}].weight`}
                          control={control}
                          defaultValue={`${item?.weight}`}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              type="number"
                              id="weight"
                              label={translation("load.form.weight.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.weight)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.weight && translation("load.form.weight.message")
                              }
                            />
                          )}
                        />
                      </CustomFormGrid>

                      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                        <Controller
                          name={`stopOffs[${index}].address.directionNotes`}
                          control={control}
                          defaultValue={`${item?.address.directionNotes}`}
                          rules={{
                            required: true
                          }}
                          render={({ field }) => (
                            <CustomTextField
                              {...field}
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              id="internalComment"
                              label={translation("common.internalComment.label")}
                              error={Boolean(errors && errors?.stopOffs && errors?.stopOffs[index]?.directionNotes)}
                              helperText={
                                errors &&
                                errors?.stopOffs &&
                                errors?.stopOffs[index]?.directionNotes &&
                                translation("common.internalComment.message")
                              }
                            />
                          )}
                        />
                      </CustomFormGrid>

                    </LocalizationProvider>
                  </CustomFormGrid>
                </AccordionDetails>
              </Accordion>
            )
          })
        }

      </form>


    </React.Fragment>

  );
};

export default StopOffInfo;
