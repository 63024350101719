import React from "react";
import { DatePicker } from "@mui/lab";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface SafetyInfoProps {
  disabled: boolean;
  control: any;
  errors: { [key: string]: any };
  vehicle: any;
}
const SafetyInfo = ({
  disabled,
  control,
  errors,
  vehicle,
}: SafetyInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");

  // const linkingAddress = location.pathname.includes("details");

  /*  const setFormValues = (key: keyof VehicleSafetyProps) => {
    if (vehicleSafety) {
      if (typeof vehicleSafety[key] === "boolean") {
        setValue(key, vehicleSafety[key]);
      } else {
        setValue(key, vehicleSafety[key] ? vehicleSafety[key] : "");
      }
    }
  }; */

  /*  useEffect(() => {
      if (params?.vehicleId && vehicleSafety) {
      const allAttributes = typedKeys(vehicleSafety);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }

    if (params?.vehicleId && vehicleSafety) {
      const allAttributes = typedKeys(vehicleSafety);
      for (const attribute of allAttributes) {
        if (attribute === "monthlyMaintenance") {
          if (typeof vehicleSafety?.monthlyMaintenance === "object") {
            setValue(
              "monthlyMaintenance",
              vehicleSafety?.monthlyMaintenance
                ? vehicleSafety?.monthlyMaintenance
                : ""
            );
          } else {
            setValue(
              "monthlyMaintenance",
              vehicleSafety?.monthlyMaintenance
                ? vehicleSafety?.monthlyMaintenance
                : ""
            );
          }
        } else if (attribute === "lastInspectionLocation") {
          if (typeof vehicleSafety?.lastInspectionLocation === "object") {
            setValue(
              "lastInspectionLocation",
              vehicleSafety?.lastInspectionLocation
                ? vehicleSafety?.lastInspectionLocation
                : ""
            );
          } else {
            setValue(
              "lastInspectionLocation",
              vehicleSafety?.lastInspectionLocation
                ? vehicleSafety?.lastInspectionLocation
                : ""
            );
          }
        } else if (attribute === "lastInspectionDate") {
          if (typeof vehicleSafety?.lastInspectionDate === "object") {
            setValue(
              "lastInspectionDate",
              vehicleSafety?.lastInspectionDate
                ? vehicleSafety?.lastInspectionDate
                : ""
            );
          } else {
            setValue(
              "lastInspectionDate",
              vehicleSafety?.lastInspectionDate
                ? vehicleSafety?.lastInspectionDate
                : ""
            );
          }
        } else if (attribute === "nextInspectionDate") {
          if (typeof vehicleSafety?.nextInspectionDate === "object") {
            setValue(
              "nextInspectionDate",
              vehicleSafety?.nextInspectionDate
                ? vehicleSafety?.nextInspectionDate
                : ""
            );
          } else {
            setValue(
              "nextInspectionDate",
              vehicleSafety?.nextInspectionDate
                ? vehicleSafety?.nextInspectionDate
                : ""
            );
          }
        } else if (attribute === "lastServiceDate") {
          if (typeof vehicleSafety?.lastServiceDate === "object") {
            setValue(
              "lastServiceDate",
              vehicleSafety?.lastServiceDate
                ? vehicleSafety?.lastServiceDate
                : ""
            );
          } else {
            setValue(
              "lastServiceDate",
              vehicleSafety?.lastServiceDate
                ? vehicleSafety?.lastServiceDate
                : ""
            );
          }
        } else if (attribute === "nextServiceDate") {
          if (typeof vehicleSafety?.nextServiceDate === "object") {
            setValue(
              "nextServiceDate",
              vehicleSafety?.nextServiceDate
                ? vehicleSafety?.nextServiceDate
                : ""
            );
          } else {
            setValue(
              "nextServiceDate",
              vehicleSafety?.nextServiceDate
                ? vehicleSafety?.nextServiceDate
                : ""
            );
          }
        } else if (attribute === "comment") {
          if (typeof vehicleSafety?.comment === "object") {
            setValue(
              "comment",
              vehicleSafety?.comment ? vehicleSafety?.comment : ""
            );
          } else {
            setValue(
              "comment",
              vehicleSafety?.comment ? vehicleSafety?.comment : ""
            );
          }
        } else {
          setFormValues(attribute);
        }
      }
    }
  }, [params?.vehicleId, vehicleSafety]);
 */
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="vehicleSafety.monthlyMaintenance"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("vehicle.form.monthlyMaintenance.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="vehicleSafety.monthlyMaintenance"
                    fullWidth
                    error={
                      Boolean(errors?.monthlyMaintenance) ||
                      (vehicle?.vehicleSafety?.monthlyMaintenance != null &&
                        new Date(vehicle?.vehicleSafety?.monthlyMaintenance) <
                          new Date())
                    }
                    helperText={
                      errors?.monthlyMaintenance &&
                      translation("vehicle.form.monthlyMaintenance.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="vehicleSafety.annualInspectionLocation"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                disabled={disabled}
                size="small"
                fullWidth
                id="annualInspectionLocation"
                label={translation(
                  "vehicle.form.annualInspectionLocation.label"
                )}
                error={Boolean(errors?.annualInspectionLocation)}
                helperText={
                  errors?.annualInspectionLocation &&
                  translation("vehicle.form.lastInspectionLocation.message")
                }
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="vehicleSafety.annualInspectionDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("vehicle.form.annualInspectionDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="annualInspectionDate"
                    fullWidth
                    error={
                      Boolean(errors?.annualInspectionDate) ||
                      (vehicle?.vehicleSafety?.annualInspectionDate != null &&
                        new Date(vehicle?.vehicleSafety?.annualInspectionDate) <
                          new Date())
                    }
                    helperText={
                      errors?.annualInspectionDate &&
                      translation("vehicle.form.annualInspectionDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="vehicleSafety.dot_Date"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("vehicle.form.dotDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="dot_Date"
                    fullWidth
                    error={Boolean(errors?.dot_Date)}
                    helperText={
                      errors?.dot_Date &&
                      translation("vehicle.form.dotDate.label.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="vehicleSafety.lastServiceDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("vehicle.form.lastServiceDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="lastServiceDate"
                    fullWidth
                    error={Boolean(errors?.lastServiceDate)}
                    helperText={
                      errors?.lastServiceDate &&
                      translation("vehicle.form.lastServiceDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="vehicleSafety.nextServiceDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("vehicle.form.nextServiceDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="nextServiceDate"
                    fullWidth
                    error={Boolean(errors?.nextServiceDate)}
                    helperText={
                      errors?.nextServiceDate &&
                      translation("vehicle.form.nextServiceDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="vehicleSafety.comment"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="comment"
                multiline
                rows={3}
                disabled={disabled}
                label={translation("vehicle.form.comment.label")}
                error={Boolean(errors?.comment)}
                helperText={
                  errors?.comment && translation("vehicle.form.comment.message")
                }
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>
        {/*  {linkingAddress || (
            <CustomFormGrid container justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.formButton}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                ) : params?.vehicleId ? (
                  translation("common.form.update")
                ) : (
                  translation("common.form.add")
                )}
              </Button>
              <Button
                color="error"
                variant="contained"
                className={classes.formButton}
                onClick={() => navigate(-1)}
              >
                {translation("common.btn.cancel")}
              </Button>
            </CustomFormGrid>
          )} */}
      </CustomFormGrid>
    </div>
  );
};

export default SafetyInfo;
