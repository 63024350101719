import React from "react";
import { Navigate } from "react-router-dom";
import LoadManagement from "../pages/loadManagement";

import LoadManagementForm from "../pages/loadManagement/loadManagementForm";
import LoadDetail from "../containers/loadManagement/loadDetail";
import LoadSearch from "../containers/loadManagement/loadSearch";

import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import Documents from "../pages/documents";
import { UserPermissions } from "src/interfaces/user";

import {
  admin,
  featureLoad,
  featureLookups,
  create,
  update,
  find,
} from "src/utils/enums";

export const loadRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const loadPermissions = allUserPermissions && allUserPermissions[featureLoad];
  const createPermission =
    role === admin ? 0 : loadPermissions?.findIndex((item) => item === create);

  const updatePermission =
    role === admin ? 0 : loadPermissions?.findIndex((item) => item === update);
  const findPermission =
    role === admin ? 0 : loadPermissions?.findIndex((item) => item === find);
  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);
  return [
    {
      path: "/load-management/lookups/",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/load-management/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/load-management/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/load-management/load-management",
      exact: true,
      element:
        role === admin || loadPermissions?.length > 0 ? (
          <LoadManagement />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/load-management/load-management/load/:type",
      exact: true,
      element:
        role === admin || loadPermissions?.length > 0 ? (
          <LoadManagement />
        ) : (
          <Navigate to="/404" />
        ),
    },

    {
      path: "/load-management/load-management/create",
      exact: true,
      element:
        createPermission > -1 ? <LoadManagementForm /> : <Navigate to="/404" />,
    },
    {
      path: "/load-management/load-management/:loadId",
      exact: true,
      element:
        updatePermission > -1 ? <LoadManagementForm /> : <Navigate to="/404" />,
    },
    {
      path: "/load-management/load-management/details/:loadId",
      exact: true,
      element: findPermission > -1 ? <LoadDetail /> : <Navigate to="/404" />,
    },
    {
      path: "/load-management/load-management/search",
      exact: true,
      element: findPermission > -1 ? <LoadSearch /> : <Navigate to="/404" />,
    },
    {
      path: "/load-management/load-management/documents/:loadId",
      exact: true,
      element: updatePermission > -1 ? <Documents /> : <Navigate to="/404" />,
    },
  ];
};
