import React from "react";
import { Chip } from "@mui/material";
import { pending, active, approved, link } from "src/utils/enums";
interface BadgeProps {
  label: string;
}

const StatusBadge = ({ label }: BadgeProps): JSX.Element => {
  const getColor = (label: string): string => {
    if (label?.toLowerCase() === pending) {
      return "warning.main";
    } else if (
      label?.toLowerCase() === active ||
      label?.toLowerCase() === approved ||
      label?.toLowerCase() === link
    ) {
      return "success.main";
    }
    return "error.main";
  };


  const sanitizeLabel = (labelValue: string) => {
    return labelValue.charAt(0).toUpperCase() + labelValue.slice(1);
  }

  return (
    <Chip
      sx={{
        padding: "4px",
        margin: " 0px 4px",
        backgroundColor: getColor(label),
        color: "#fff",
        textTransform: "capitalize",
      }}
      size="small"
      label={sanitizeLabel(label)}
    ></Chip>
  );
};

export default StatusBadge;
