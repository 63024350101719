import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import SettlementCodeFormContainer from "src/containers/settlementCodes/form";
const SettlementCodesForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Settlements" description="Porto Settlements" />
      <SettlementCodeFormContainer/>
    </Grid>
  );
};

export default SettlementCodesForm;