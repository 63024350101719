import React, { useEffect, useState } from "react";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useAppSelector, useAppDispatch } from "src/hooks/reduxHooks";
import { useStyles } from "src/styles/formStyles";
import BillingAddress from "./billingAddress";
import FreightBill from "./freightBill";
import LineItem from "./lineItem";
import { Skeleton } from "@mui/material";
import { Box, Typography, Tab, Tabs, AppBar } from "@mui/material";
import { useForm } from "react-hook-form";
import { perLoad, perMile } from "src/utils/enums";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import { getMilageCalculations } from "src/redux/actions/loadManagement";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

interface BillingInfoProps {
  done(error: any | null, data: LoadManagementProps): any;
  stepId?: number,
  data?: LoadManagementProps | null
}

const BillingInformation = ({
  done,
  stepId,
  data
}: BillingInfoProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [indexValue, setIndexValue] = React.useState(0);
  const [freightExpense, setFreightExpense] = useState(0);
  const [otherExpense, setOtherExpense] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIndexValue(newValue);
  };

  const calculateMiles = async () => {
    setLoading(true);
    const shipper = data?.shippingInformation;
    const consignee = data?.consigneeInformation;

    if (shipper && consignee) {

      if (shipper.zip && consignee.zip) {
        const res = await dispatch(getMilageCalculations(shipper.zip, consignee.zip))
        if (res && res.data) {
          data.freightBill = {
            ...data.freightBill,
            mileage: res.data
          } as any;
        }
        setLoading(false);

      }
    }
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    getValues
  } = useForm();


  const freightLineHaul = watch("freightBill.lineHaul");
  const freightMileage = watch("freightBill.mileage");
  const freightRate = watch("freightBill.rate");

  useEffect(() => {
    calculateMiles();


    if (data?.loadLineItem) {
      calculateOtherExpense(data?.loadLineItem);
    }

    if (data?.freightBill) {
      calculateFreightBill(
        data.freightBill.lineHaul,
        data.freightBill.rate,
        data.freightBill.mileage
      );
    }
  }, []);

  useEffect(() => {
    calculateFreightBill(freightLineHaul, freightRate, freightMileage);

  }, [freightLineHaul, freightMileage, freightRate])

  const onSubmit = (data: LoadManagementProps) => {
    if (data.freightBill) {
      data.freightBill.rate = freightRate || 0;
      data.freightBill.mileage = freightMileage || 0;
      data.freightBill.freightBill = freightExpense || 0 as any;
      data.freightBill.otherBill = otherExpense || 0 as any;
      data.freightBill.grandTotal = ((freightExpense || 0) + (otherExpense || 0)) as any;
    }

    console.log("Billing info", data);
    done(null, data);
  }


  const calculateFreightBill = (lineHaul: string, rate: any, mileage: any) => {
    const miles = 1;
    if (rate && mileage) {
      if (lineHaul == perLoad) {
        setFreightExpense(Number(rate) * miles);
      } else {
        setFreightExpense(Number(mileage) * Number(rate));
      }
    }
  }

  const calculateOtherExpense = (billItems: Array<any>) => {
    let total = 0;
    if (billItems && billItems.length > 0) {
      billItems.forEach((e: any) => {
        if (e.rate && e.quantity) {
          total = total + (Number(e.rate) * Number(e.quantity));
        }

        if (e.extra) {
          total = total + Number(e.extra);
        }
      })
    }
    setOtherExpense(total);
  }

  return (
    <>
      <CustomFormGrid container>
        <CustomFormGrid container className={classes.billinginfoContainer}>
          {/**********Headings ***********/}
          <CustomFormGrid
            container
            justifyContent="center"
            // spacing={3}
            style={{ height: "25px" }}
          >
            <CustomFormGrid item xs={2} md={2} sm={2} lg={2}>
              <Typography className={classes.billinginfoContainerHeadings}>
                Freight Bill
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid item xs={2} md={2} sm={2} lg={2}>
              <Typography className={classes.billinginfoContainerHeadings}>
                Other Bill
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid item xs={2} md={2} sm={2} lg={2}>
              <Typography className={classes.billinginfoContainerHeadings}>
                Grand Total
              </Typography>
            </CustomFormGrid>
          </CustomFormGrid>
          {/**********Amounts ***********/}
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ height: "25px" }}
          >
            <CustomFormGrid item xs={2} md={2} sm={2} lg={2}>
              <Typography
                className={classes.billinginfoContainerText}
              >
                {
                  freightExpense
                }
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={2} md={2} sm={2} lg={2}>
              <Typography
                className={classes.billinginfoContainerText}
              >
                {
                  otherExpense
                }
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={2} md={2} sm={2} lg={2}>
              <Typography
                className={classes.billinginfoContainerText}
              >
                {freightExpense + otherExpense}
              </Typography>
            </CustomFormGrid>
          </CustomFormGrid>
        </CustomFormGrid>

        <Box sx={{ width: "100%", padding: "15px" }}>
          {
            loading ?
              <Skeleton variant="rounded" height={100} />
              :
              <FreightBill
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                data={data?.freightBill}
              />
          }
        </Box>

        <Box sx={{ width: "100%" }}>
          <AppBar
            position="static"
            style={{ boxShadow: "none", marginTop: "25px" }}
          >
            <Tabs
              value={indexValue}
              onChange={handleChange}
              textColor="inherit"
              variant="fullWidth"
              style={{
                backgroundColor: "#ffff",
                color: "#007DFF",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "26px",
                fontFamily: "Roboto",
                letterSpacing: "0.46px",
              }}
            >
              <Tab label="BILLING ADDRESS" {...a11yProps(0)} />
              <Tab label="LINE ITEMS" />
            </Tabs>
          </AppBar>
          <CustomFormGrid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            className={classes.tabPanel}
          >
            <form id={`load-form-${stepId}`} noValidate onSubmit={handleSubmit(onSubmit)}>
              <TabPanel value={indexValue} index={0}>
                <BillingAddress
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  data={data?.billToInformation}
                />
              </TabPanel>
              <TabPanel value={indexValue} index={1}>
                <LineItem
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  data={data?.loadLineItem}
                  onLineItemChange={() => {
                    calculateOtherExpense(getValues("loadLineItem"));
                  }}
                />
              </TabPanel>
            </form>
          </CustomFormGrid>
        </Box>
      </CustomFormGrid>
    </>
  );
};

export default BillingInformation;
