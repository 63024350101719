import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllDocumentTypes } from "src/redux/actions/documentType";
import { selectDocumentTypes } from "src/redux/reducers/documentType";
import { useTranslation } from "react-i18next";
import { featureDocumentType, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Drivers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const documentTypes = useAppSelector(selectDocumentTypes);
  const [translation] = useTranslation("translation");
  const [filter, setFilter] = useState<any>({});
  const [documentTypeData, setDocumentTypeData] = useState<any>({});
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const documentTypePermissions =
    allUserPermissions && allUserPermissions[featureDocumentType];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : documentTypePermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : documentTypePermissions?.findIndex((item) => item === update);

  const getDocumentTypesList = async () => {
    setLoading(true);
    await dispatch(
      getAllDocumentTypes(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };
  const getDocumentTypesListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllDocumentTypes(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getDocumentTypesListWithFilter();
    } else {
      getDocumentTypesList();
    }
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    getDocumentTypesListWithFilter();
  }, [filter]);
  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (documentTypes?.data?.length > 0) {
      setDocumentTypeData({
        ...documentTypeData?.documentTypes,
        documentTypes,
      });
    } else {
      setDocumentTypeData({});
    }
  }, [documentTypes]);

  const addDocumentType = () => {
    navigate("/config/document-types/create");
  };
  const searchDocumentType = () => {
    navigate("/config/document-types/search");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );
  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/config/document-types/${params.row.id}`);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: translation("documentTypes.list.name.label"),
      flex: 1,
      minWidth: 200,
      filterOperators,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isRequired",
      headerName: translation("documentTypes.list.required.label"),
      minWidth: 200,
      filterable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.isRequired === true ? "True" : "False"}</div>;
      },
    },
    {
      field: "entity",
      headerName: translation("documentTypes.list.entity.label"),
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
  ];

  const onRowClick = (params: GridRowParams) => {
    navigate(params.row.id);
  };
  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("documentTypes.list.title")}
        rows={documentTypeData?.documentTypes?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addDocumentType}
        onClickSearch={searchDocumentType}
        createPermission={createPermission > -1}
        onRowClick={onRowClick}
        rowsState={rowsState}
        setRowsState={setRowsState}
        pagination={documentTypes?.pagination}
        data={documentTypeData?.documentTypes}
        setData={setDocumentTypeData}
        add={true}
        setFilter={setFilter}
        search={"documentType"}
      />
    </CustomFormGrid>
  );
}
