import React from "react";
import { MenuItem, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/lab";
import CustomTextField from "src/components/customElements/customTextField";
import CustomSwitch from "src/components/customElements/customSwitch";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { statusTypes } from "src/utils/dropdowns/common";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { genderTypes } from "src/utils/dropdowns/gender";
import AddLookupField from "src/components/common/addLookupFeild";

interface PersonalInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
  disabled: boolean;
}
const PersonalInfo = ({
  control,
  errors,
  watch,
  disabled,
}: PersonalInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  const allLookups = useAppSelector(selectLookups);
  const allRaceTypes: Array<LookupProps> = allLookups?.data?.["race"]?.lookups
    ? allLookups?.data?.["race"]?.lookups
    : [];

  return (
    <CustomFormGrid container>
      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="firstName"
          control={control}
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              size="small"
              disabled={disabled}
              fullWidth
              id="firstName"
              label={translation("common.firstName.label")}
              error={Boolean(errors?.firstName)}
              helperText={
                errors?.firstName && translation("common.firstName.message")
              }
              required
            />
          )}
        />
      </CustomFormGrid>

      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="lastName"
          control={control}
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              size="small"
              disabled={disabled}
              fullWidth
              id="lastName"
              label={translation("common.lastName.label")}
              error={Boolean(errors?.lastName)}
              helperText={
                errors?.lastName && translation("common.lastName.message")
              }
              required
            />
          )}
        />
      </CustomFormGrid>
      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
        <Controller
          name="status"
          control={control}
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              disabled={disabled}
              fullWidth
              size="small"
              id="status"
              label={translation("common.status.label")}
              error={Boolean(errors?.status)}
              select
              helperText={
                errors?.status && translation("common.status.message")
              }
              required
            >
              {statusTypes.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </CustomTextField>
          )}
        />
      </CustomFormGrid>


      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="birthDate"
          control={control}
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <DatePicker
              {...field}
              disabled={disabled}
              label={translation("driver.form.birthdate.label")}
              disableFuture
              inputFormat="MM/dd/yyyy"
              renderInput={(params: any) => (
                <CustomTextField
                  {...params}
                  {...field}
                  size="small"
                  id="birthDate"
                  fullWidth
                  error={Boolean(errors?.birthDate)}
                  helperText={
                    errors?.birthDate &&
                    translation("driver.form.birthdate.message")
                  }
                  required
                />
              )}
            />
          )}
        />
      </CustomFormGrid>
      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="SSN"
          control={control}
          defaultValue={""}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              size="small"
              fullWidth
              disabled={disabled}
              id="SSN"
              label={translation("driver.form.ssn.label")}
              error={Boolean(errors?.SSN)}
              helperText={errors?.SSN && translation("driver.form.ssn.message")}
              required
            ></CustomTextField>
          )}
        />
      </CustomFormGrid>
      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="gender"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <CustomTextField
              {...field}
              size="small"
              disabled={disabled}
              fullWidth
              id="gender"
              select
              label={translation("driver.form.sexType.label")}
              error={Boolean(errors?.gender)}
              helperText={
                errors?.gender && translation("driver.form.sexType.message")
              }
            >
              {genderTypes.map((item, index) => (
                <MenuItem value={item.value} key={index}>
                  {item.label}
                </MenuItem>
              ))}
            </CustomTextField>
          )}
        />
      </CustomFormGrid>
  {/*     <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
        <Controller
          name="race"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <CustomTextField
              {...field}
              size="small"
              disabled={disabled}
              fullWidth
              id="race"
              label={translation("driver.form.raceType.label")}
              error={Boolean(errors?.race)}
              select
              helperText={
                errors?.race && translation("driver.form.raceType.message")
              }
            >
              {allRaceTypes.length > 0 ? (
                allRaceTypes.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No Data</MenuItem>
              )}
            </CustomTextField>
          )}
        />
      </CustomFormGrid> */}

      <CustomFormGrid
            item
            sm={12} xs={12} md={12} lg={6}
            style={{ margin: "0px", padding: "0px" }}
          >
            <AddLookupField
              name={"race"}
              control={control}
              rules={{ required: false }}
              id="race"
              label={translation("driver.form.raceType.label")}
              errors={Boolean(errors?.race)}
              lookupsArray={allRaceTypes}
              linkingAddress={disabled}
              helperText={
                errors?.make && translation("vehicle.form.make.message")
              }
              fieldRequired={false}
              disabled={disabled}
              featureKey="race"
              lookupFeatureEnum={"all"}
            />
          </CustomFormGrid>

      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="passportNumber"
          control={control}
          defaultValue={""}
          // rules={{ required: true }}
          render={({ field }) => (
            <CustomTextField
              disabled={disabled}
              {...field}
              size="small"
              fullWidth
              id="passportNumber"
              label={translation("driver.form.passportNumber.label")}
              error={Boolean(errors?.passportNumber)}
              helperText={
                errors?.passportNumber &&
                translation("driver.form.passportNumber.message")
              }
              //  required
            ></CustomTextField>
          )}
        />
      </CustomFormGrid>
      <CustomFormGrid item sm={12} xs={12} md={12} lg={6}>
        <Controller
          name="passportExpirationDate"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <DatePicker
              {...field}
              disabled={disabled}
              label={translation("driver.form.passportExpDate.label")}
              inputFormat="MM/dd/yyyy"
              renderInput={(params: any) => (
                <CustomTextField
                  {...params}
                  {...field}
                  size="small"
                  fullWidth
                  id="passportExpirationDate"
                  error={Boolean(errors?.passportExpirationDate)}
                  helperText={
                    errors?.passportExpirationDate &&
                    translation("driver.form.passportExpDate.message")
                  }
                />
              )}
            />
          )}
        />
      </CustomFormGrid>
      <CustomFormGrid
        item
        sm={12} xs={12} md={12} lg={6}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Controller
          name="companyDriver"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <>
              <InputLabel>
                {translation("driver.form.companyDriver.title")}
              </InputLabel>
              <CustomSwitch
                disabled={disabled}
                checked={
                  watch("companyDriver") ? watch("companyDriver") : false
                }
                {...field}
                id="companyDriver"
              />
            </>
          )}
        ></Controller>
      </CustomFormGrid>
    </CustomFormGrid>
  );
};

export default PersonalInfo;
