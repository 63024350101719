import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import TrailerFormContainer from "src/containers/trailer/form";
const TrailerForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Trailers" description="Porto Trailers" />
      <TrailerFormContainer />
    </Grid>
  );
};

export default TrailerForm;
