import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllTerminals } from "src/redux/actions/terminal";
import { selectTerminals } from "src/redux/reducers/terminal";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureTerminals, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import { Clipboard } from "ts-react-feather-icons";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function Terminals() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const terminals = useAppSelector(selectTerminals);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const user = useAppSelector(selectUser);
  const classes = useStyles();
  const terminalPermissions =
    allUserPermissions && allUserPermissions[featureTerminals];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === update);
  const [terminalData, setTerminalData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const getTerminalsList = async () => {
    setLoading(true);
    await dispatch(
      getAllTerminals(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };
  /*  function pageExists(page: any) {
    return terminalData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  const getTerminalsListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllTerminals(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getTerminalsListWithFilter();
    } else getTerminalsList();
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    getTerminalsListWithFilter();
  }, [filter]);
  useEffect(() => {
    //  if (pageExists(rowsState?.page + 1) == false)
    if (terminals?.length > 0) {
      setTerminalData({ ...terminalData?.terminals, terminals });
    } else {
      setTerminalData({});
    }
  }, [terminals]);

  const addTerminal = () => {
    navigate("/terminals/terminals/create");
  };
  const searchTerminal = () => {
    navigate("/terminals/terminals/search");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/terminals/terminals/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="Details"
          key="details"
          onClick={() => {
            navigate(`/terminals/terminals/details/${params.row.id}`);
            // navigate(`/drivers/drivers/details/${params.row.id}`);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "title",
      headerName: translation("terminals.form.name.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "terminalCode",
      headerName: translation("terminals.form.terminalCode.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "type",
      headerName: translation("terminals.form.type.label"),
      minWidth: 100,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.type?.content}</div>;
      },
    },
    {
      field: "phoneNumber",
      headerName: translation("common.phone.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "faxPhoneNumber",
      headerName: translation("common.faxPhoneNumber.label"),
      minWidth: 150,
      filterable: false,
    },

    {
      field: "address",
      headerName: translation("common.address.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "city",
      headerName: translation("common.city.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "state",
      headerName: translation("common.state.label"),
      minWidth: 100,
      filterOperators,
    },

    {
      field: "zip",
      headerName: translation("common.zip.label"),
      minWidth: 100,
      filterable: false,
    },
  ];

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("settlement.terminal.account.title")}
        rows={terminalData?.terminals?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addTerminal}
        onClickSearch={searchTerminal}
        createPermission={createPermission > -1}
        rowsState={rowsState}
        setRowsState={setRowsState}
        // pagination={terminals.pagination}
        data={terminalData?.terminals}
        setData={setTerminalData}
        add={true}
        setFilter={setFilter}
        search={"terminal"}
      />
    </CustomFormGrid>
  );
}
