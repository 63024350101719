import * as React from "react";
import {
  DataGrid,
  GridOverlay,
  GridRowParams,
  GridToolbar,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowBack } from "@mui/icons-material";

interface DataTableProps {
  rows: any;
  columns: Array<any>;
  loading: boolean;
  title?: string;
  onClickAdd?: () => any;
  ComponentToRender?: React.ReactNode;
  createPermission?: boolean;
  onRowClick?: (params: GridRowParams) => void;
  setRowsState?: (data: any) => any;
  rowsState?: any;
  pagination?: any;
  data?: any;
  setData?: (data: any) => any;
  localPagination?: boolean;
  add?: boolean;
  billing?: boolean;
  printQueue?: boolean;
  selectedRows?: any;
  setSelectedRows?: any;
  setFilter?: (data: any) => any;
  disableBackButton?: boolean;
}

const DataTable = ({
  rows,
  columns,
  title,
  ComponentToRender,
  onRowClick,
  rowsState,
  setRowsState,
  pagination,
  setData,
  billing,
  localPagination,
  selectedRows,
  setSelectedRows,
  setFilter,
  disableBackButton,
}: DataTableProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const prevSelectionModel = React.useRef<GridSelectionModel>(selectedRows);
  const [height, setHeight] = React.useState<number>(window.innerHeight - 180);
  React.useEffect(() => {
    setHeight(window.innerHeight - 180);
  }, [window.innerHeight]);

  /*  React.useEffect(() => {
    let active = true;

    (async () => {
      //  const newRows = await loadServerRows(page, data);

      if (!active) {
        return;
      }

      setRowsState &&
        setRowsState((prev: any) => ({
          ...prev,
          // loading: false,
          rows: rows,
        }));
      //  setSelectedRows(prevSelectionModel?.current);
    })();

    return () => {
      active = false;
    };
  }, [page, data]); */
  const CustomNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>{translation("common.noRecord.message")}</div>
      </GridOverlay>
    );
  };

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <CircularProgress />
      </GridOverlay>
    );
  }

  const onFilterChange = React.useCallback((filterModel) => {
    setFilter && filterModel.items.length > 0 && setFilter(filterModel);
  }, []);

  React.useEffect(() => {
    if (rows) {
      let active = true;

      (async () => {
        // setRowsState && setRowsState((prev: any) => ({ ...prev, loading: true }));
        setTimeout(() => {}, Math.random() * 200 + 100);
        // const newRows = await loadServerRows(
        //   rowsState.page,
        //   rowsState.pageSize,
        //   rows
        // );

        if (!active) {
          return;
        }

        setRowsState &&
          setRowsState((prev: any) => ({
            ...prev,
            // loading: false,
            rows: rows,
          }));
      })();

      return () => {
        active = false;
      };
    } else {
      setRowsState &&
        setRowsState((prev: any) => ({
          ...prev,
          // loading: false,
          rows: {},
        }));
    }
  }, [rowsState?.page, rowsState?.pageSize, rows]);

  return (
    <div style={{ width: "100%" }}>
      <>
        {ComponentToRender ? (
          ComponentToRender
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Box flexGrow={1} display="flex" alignItems="center">
              {disableBackButton ? (
                <></>
              ) : (
                <Button>
                  <ArrowBack onClick={() => navigate(-1)} />
                </Button>
              )}
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0",
                  textTransform: "capitalize",
                }}
                gutterBottom
              >
                {title}
              </Typography>
            </Box>
           {/*  <Box>
              {createPermission &&
                !vehicle?.vehicleTrailers?.isActive &&
                add === true && (
                  <Fab
                    color="secondary"
                    sx={{
                      mr: 1,
                      mb: {
                        xs: 1,
                        sm: 0,
                        lg: 0,
                      },
                    }}
                    onClick={onClickAdd}
                  >
                    <Add />
                  </Fab>
                )}
            </Box> */}
          </Box>
        )}
        <Box
          sx={{
            overflow: "auto",
            mt: 3,
          }}
        >
          <div
            style={{
              height: height,
              width: "100%",
              textAlign: "center",
            }}
          >
            {localPagination == true ? (
              <DataGrid
                pagination={false}
                {...rowsState}
                columns={[...columns]}
                onRowClick={onRowClick}
                rowsPerPageOptions={[5, 10, 50, 100]}
                onPageSizeChange={(pageSize) => {
                  // setData && setData([]);
                  setRowsState &&
                    setRowsState((prev: any) => ({
                      ...prev,
                      pageSize,
                      page: 0,
                      row: [],
                    }));
                }}
                onPageChange={(page) =>
                  setRowsState &&
                  setRowsState((prev: any) => ({ ...prev, page }))
                }
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            ) : billing === true ? (
              <DataGrid
                rowCount={pagination?.total}
                paginationMode="server"
                pagination
                onPageChange={(page) => {
                  setRowsState &&
                    setRowsState((prev: any) => ({ ...prev, page }));
                  prevSelectionModel.current = selectedRows;
                  setPage(page);
                }}
              //  filterMode="server"
             //   onFilterModelChange={onFilterChange}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectedRows(newSelectionModel);
                }}
                selectionModel={selectedRows}
                {...rowsState}
                columns={[...columns]}
                onPageSizeChange={(pageSize) => {
                  setData && setData([]);
                  setRowsState &&
                    setRowsState((prev: any) => ({
                      ...prev,
                      pageSize,
                      page: 0,
                      row: [],
                    }));
                }}
                rowsPerPageOptions={[5, 10, 50, 100]}
                onRowClick={onRowClick}
                components={
                  setFilter
                    ? {
                        NoRowsOverlay: CustomNoRowsOverlay,
                    //    Toolbar: GridToolbar,
                      }
                    : {
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }
                }
                checkboxSelection={true}
                LoadingOverlay={CustomLoadingOverlay}
              />
            ) : (
              <DataGrid
                rowCount={pagination?.total}
                paginationMode="server"
                pagination
                onPageChange={(page) =>
                  setRowsState &&
                  setRowsState((prev: any) => ({ ...prev, page }))
                }
                {...rowsState}
                columns={[...columns]}
                onPageSizeChange={(pageSize) => {
                  setData && setData([]);
                  setRowsState &&
                    setRowsState((prev: any) => ({
                      ...prev,
                      pageSize,
                      page: 0,
                      row: [],
                    }));
                }}
                rowsPerPageOptions={[5, 10, 50, 100]}
                onRowClick={onRowClick}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  // Toolbar: GridToolbar,
                }}
                LoadingOverlay={CustomLoadingOverlay}
              />
            )}
          </div>
        </Box>
      </>
    </div>
  );
};
export default DataTable;
