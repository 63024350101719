import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
/* import LoadManagementForm from "src/components/loadManagement"; */
import LoadManagementForm from "src/components/load/index";
import NewLoad from "src/components/load/load";

import { LoadManagementProps } from "src/interfaces/loadManagement";
import {
  getLoadManagementById,
  addLoadManagement,
  updateLoadManagement,
} from "src/redux/actions/loadManagement";
import { getAllCustomers } from "src/redux/actions/customer";
import { getAllCommodities } from "src/redux/actions/commodity";
import { getAllTerminals } from "src/redux/actions/terminal";
import { getAllUsers } from "src/redux/actions/users";
import { getAllLineItems } from "src/redux/actions/lineItems";
import { resetLoadManagement } from "src/redux/reducers/loadManagement";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { sanitizeEntity } from "src/utils/common";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { getAllAvailableDrivers } from "src/redux/actions/driver";
import { getAllAvailableTrailers } from "src/redux/actions/trailer";
import { selectUser } from "src/redux/reducers/auth";
import { getAllAvailableVehicles } from "src/redux/actions/vehicle";
import { useAppSelector } from "src/hooks/reduxHooks";

const LoadManagementFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useAppSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const linkingAddress = location.pathname.includes("details");
  const [disabled, setDisabled] = useState(false);
  const [loadStopError, setLoadStopError] = useState(false);

  const getLookupsList = async () => {
    setLoading(true);
    await dispatch(getAllLookupsByCategory("all", 0, 1));
    setLoading(false);
  };
  useEffect(() => {
    if (linkingAddress) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, []);

  useEffect(() => {
    getLookupsList();
  }, []);

  useEffect(() => {
    dispatch(resetLoadManagement());
    dispatch(getAllCustomers(0, 1));
    dispatch(getAllCommodities(0, 1));
    dispatch(getAllTerminals(0, 1));
    dispatch(getAllUsers(0, 1));
    dispatch(getAllLineItems(0, 1));
    dispatch(getAllAvailableDrivers());
    const filterObject: any = {};
    filterObject.items = [
      {
        columnField: "status",
        operatorValue: "equals",
        value: "active",
      },
    ];
    dispatch(getAllAvailableTrailers(0, 1, filterObject));
    dispatch(getAllAvailableVehicles(0, 1, filterObject));
  }, []);
  useEffect(() => {
    if (params?.loadId) {
      getSingleLineItem(parseInt(params?.loadId));
      dispatch(getAllUsers(0, 1));
    }
  }, [params?.loadId]);
  const getSingleLineItem = async (id: number) => {
    setPageLoading(true);
    await dispatch(getLoadManagementById(id));
    setPageLoading(false);
  };

  const onSubmit = async (data: any) => {
    let objToSubmit: LoadManagementProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.addedBy = objToSubmit.addedBy ? objToSubmit.addedBy : user;
    /*   if (!(objToSubmit.loadStops?.length > 0)) {
      setLoadStopError(true);
    } else { */
    setLoadStopError(false);

    setLoading(true);

    if (params?.loadId) {
      //update
      const loadManagementUpdate = await dispatch(
        updateLoadManagement(objToSubmit, parseInt(params?.loadId))
      );
      if (loadManagementUpdate.success && loadManagementUpdate.data.load.id) {
        setTimeout(
          () =>
            navigate(
              `/load-management/load-management/details/${loadManagementUpdate.data.load.id}`
            ),
          300
        );
      }
    } else {
      //add

      const loadManagement = await dispatch(addLoadManagement(objToSubmit));
      if (loadManagement.success && loadManagement.data.id) {
        setTimeout(
          () =>
            navigate(
              `/load-management/load-management/details/${loadManagement.data.id}`
            ),
          300
        );
      }
    }
    setLoading(false);
    //  }
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {/* <LoadManagementForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
          disabled={disabled}
          setLoadStopError={setLoadStopError}
          loadStopError={loadStopError}
        /> */}
        <NewLoad/>
      </Grid>
    </Grid>
  );
};

export default LoadManagementFormContainer;
