import React from "react";
import { InputLabel } from "@mui/material";
import { DatePicker } from "@mui/lab";
import CustomTextField from "src/components/customElements/customTextField";
import CustomSwitch from "src/components/customElements/customSwitch";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface CDLInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
  disabled: boolean;
}
const CDLInfo = ({
  control,
  errors,
  watch,
  disabled,
}: CDLInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="cdlNumber"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="cdlNumber"
                disabled={disabled}
                type="number"
                inputProps={{ min: 0 }}
                label={translation("driver.form.cdlNumber.label")}
                error={Boolean(errors?.cdlNumber)}
                helperText={
                  errors?.cdlNumber &&
                  translation("driver.form.cdlNumber.message")
                }
                required
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="cdlState"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="cdlState"
                label={translation("driver.common.cdlState.label")}
                error={Boolean(errors?.cdlState)}
                helperText={
                  errors?.cdlState &&
                  translation("driver.common.cdlState.message")
                }
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="cdlExpirationDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("driver.form.cdlExpDate.label")}
                //   disablePast
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="cdlExpirationDate"
                    error={Boolean(errors?.cdlExpirationDate)}
                    helperText={
                      errors?.cdlExpirationDate &&
                      translation("driver.form.cdlExpDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={6}
          lg={6}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Controller
            name="cdlMatchesPhysical"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("driver.form.cdlMatchesPhysical.label")}
                </InputLabel>
                <CustomSwitch
                  disabled={disabled}
                  checked={
                    watch("cdlMatchesPhysical")
                      ? watch("cdlMatchesPhysical")
                      : false
                  }
                  {...field}
                  id="cdlMatchesPhysical"
                />
              </>
            )}
          ></Controller>
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default CDLInfo;
