import React from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

interface BillingInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled: boolean;
}

const BillingInfo = ({
  control,
  errors,
  disabled,
}: BillingInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
          <Controller
            name="billingInformation.federalId"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="federalId"
                label={translation("owner.billingInfo.FederalID")}
                error={Boolean(errors?.billingInformation?.federalId)}
                helperText={
                  errors?.billingInformation?.federalId &&
                  translation("owner.billingInfo.FederalID.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
          <Controller
            name="billingInformation.defaultTractorPercentage"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                disabled={disabled}
                fullWidth
                inputProps={{ min: 0 }}
                type="number"
                id="defaultTractorPercentage"
                label={translation("owner.billingInfo.PayPct")}
                error={Boolean(
                  errors?.billingInformation?.defaultTractorPercentage
                )}
                required
                helperText={
                  errors?.billingInformation?.defaultTractorPercentage &&
                  translation("owner.billingInfo.PayPct.message")
                }
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
          <Controller
            name="billingInformation.payPerMile_Loaded"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
                inputProps={{ min: 0 }}
                disabled={disabled}
                id="payPerMile_Loaded"
                label={translation("owner.billingInfo.PayPerMile")}
                error={Boolean(errors?.billingInformation?.payPerMile_Loaded)}
                type="number"
                required
                helperText={
                  errors?.billingInformation?.payPerMile_Loaded &&
                  translation("owner.billingInfo.PayPerMile.message")
                }
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
          <Controller
            name="billingInformation.payPerMile_Empty"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                variant="outlined"
                size="small"
                disabled={disabled}
                fullWidth
                inputProps={{ min: 0 }}
                id="payPerMile_Empty"
                label={translation("owner.billingInfo.Empty")}
                error={Boolean(errors?.billingInformation?.payPerMile_Empty)}
                helperText={
                  errors?.billingInformation?.payPerMile_Empty &&
                  translation("owner.billingInfo.Empty.message")
                }
                required
              />
            )}
          ></Controller>
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default BillingInfo;
