import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  // GridRowParams,
} from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllAccountsOnType } from "src/redux/actions/settlement";
import { selectDriver } from "src/redux/reducers/settlement";
import { useTranslation } from "react-i18next";
import { featureDrivers, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { useStyles } from "src/styles/formStyles";
import PieChartIcon from "@mui/icons-material/PieChart";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  //  loading: boolean;
}
export default function Drivers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const driverAccounts = useAppSelector(selectDriver);
  const [driverAccountsData, setDriverAccountsData] = useState<any>([]);
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    //  loading: false,
  });
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const driverPermissions =
    allUserPermissions && allUserPermissions[featureDrivers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : driverPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : driverPermissions?.findIndex((item) => item === update);

  const getAccountList = async () => {
    setLoading(true);
    await dispatch(
      getAllAccountsOnType(
        "driver"
      )
    );
    setLoading(false);
  };

 /*  const getAccountListWithFilter = async () => {
    await dispatch(
      getAllAccountsOnType(
        "driver")
    );
  }; */
/*   useEffect(() => {
    getAccountListWithFilter();
  }, [filter]); */
  useEffect(() => {
    getAccountList();
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (driverAccounts?.data?.length > 0) {
      setDriverAccountsData([...driverAccountsData, driverAccounts]);
    } else {
      setDriverAccountsData({});
    }
  }, [driverAccounts]);


  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<PieChartIcon />}
          label="Details"
          key="details"
          onClick={() => {
            //  navigateToDriverDetail(params.row.id);
            navigate(`/settlements/settlement-driver/${params?.row?.id}`);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.id
              ? params?.row?.id
              : ""}
          </div>
        );
      },
    },
    {
      field: "accountName",
      headerName: translation("settlement.addEarningForm.accountName.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "preferredDay",
      headerName: translation("settlement.preferredDay.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.preferredDay
              ? params?.row?.preferredDay
              : "None"}
          </div>
        );
      },
    },
    {
      field: "currentBalance",
      headerName: translation("settlement.currentBalance.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  /*  const onRowClick = (params: GridRowParams) => {
    navigateToDriverDetail(params.row.id);
  }; */

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <DataTable
      title={translation("settlement.driver.label")}
      rows={driverAccounts}
      columns={columns}
      loading={loading}
      // onClickAdd={addLineItems}
      createPermission={createPermission > -1}
      // onRowClick={onRowClick}
      rowsState={rowsState}
      setRowsState={setRowsState}
    //  pagination={driverAccounts.pagination}
      data={driverAccountsData}
      setData={setDriverAccountsData}
        add={true}
        localPagination={true}
    //  setFilter={setFilter}

    />
  );
}
