import { AppDispatch } from "../store";
import { OwnerTrailerProps } from "src/interfaces/owner";
import { postServerData, patchServerData } from "../api/index";
import { removeLinkedOwner, addLinkedOwner } from "src/redux/reducers/trailer";
import {
  addLinkedTrailer,
  removeLinkedTrailer,
} from "src/redux/reducers/owner";

export const addOwnerTrailer =
  (data: OwnerTrailerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      postServerData(`/owner-trailer-link`, data)
    );

    if (Array.isArray(response?.data?.data)) {
      dispatch(addLinkedOwner(response?.data?.data[0]));
      dispatch(addLinkedTrailer(response?.data?.data[0]));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const deleteOwnerTrailerLink =
  (data: OwnerTrailerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/owner-trailer-link`, data)
    );
    if (response?.data?.data) {

      if (typeof data.trailer === "number") {
        dispatch(removeLinkedTrailer(data?.trailer));
      }

      dispatch(removeLinkedOwner());
    }
    return { success: response.success, data: response?.data?.data };
  };
