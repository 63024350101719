import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useAppSelector } from "src/hooks/reduxHooks";
import LoopIcon from "@mui/icons-material/Loop";
import DoneIcon from '@mui/icons-material/Done';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { selectMainDashboard } from "src/redux/reducers/settlement";
const AnalyticalCounts = () => {
  const dashboard = useAppSelector(selectMainDashboard);

  const iconContainerStyle: any = {
    textAlign: "center",
    borderRadius: "50%",
    width: "50px",
    height: "50px"
  };

  const cardStyle: any = {
    borderRadius: "8px",
    height: "130px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }


  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Card sx={{ ...cardStyle }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>Available Loads</Typography>
                <Typography variant="h3" fontWeight="700">
                  {dashboard?.customers ? dashboard?.customers : "0"}
                </Typography>
              </CardContent>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", padding: "12px" }}>
              <Box sx={{
                ...iconContainerStyle,
                color: "primary.main",
                backgroundColor: "primary.light",
              }}>
                <DoneIcon
                  style={{ height: "25px", width: "25px", marginTop: "11px" }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={3} lg={3}>
          <Card sx={{ ...cardStyle }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>Active Loads</Typography>
                <Typography variant="h3" fontWeight="700">
                  {dashboard?.customers ? dashboard?.customers : "0"}
                </Typography>
              </CardContent>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", padding: "12px" }}>
              <Box sx={{
                ...iconContainerStyle,
                color: "secondary.main",
                backgroundColor: "secondary.light",
              }}>
                <LoopIcon
                  style={{ height: "25px", width: "25px", marginTop: "11px" }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={3} lg={3}>
          <Card sx={{ ...cardStyle }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>Planned Loads</Typography>
                <Typography variant="h3" fontWeight="700">
                  {dashboard?.customers ? dashboard?.customers : "0"}
                </Typography>
              </CardContent>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", padding: "12px" }}>
              <Box sx={{
                ...iconContainerStyle,
                color: "warning.main",
                backgroundColor: "warning.light",
              }}>
                <PsychologyIcon
                  style={{ height: "25px", width: "25px", marginTop: "11px" }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={3} lg={3}>
          <Card sx={{ ...cardStyle }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography color="textSecondary" variant="h4" gutterBottom>Dispatched Loads</Typography>
                <Typography variant="h3" fontWeight="700">
                  {dashboard?.customers ? dashboard?.customers : "0"}
                </Typography>

              </CardContent>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", padding: "12px" }}>
              <Box sx={{
                ...iconContainerStyle,
                color: "info.main",
                backgroundColor: "info.light",
              }}>
                <LocalShippingIcon
                  style={{ height: "25px", width: "25px", marginTop: "11px" }}
                />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AnalyticalCounts;