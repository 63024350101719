export const pending = "pending";
export const active = "active";
export const link = "link";
export const unlink = "unlink";
export const inActive = "inActive";
export const approved = "approved";
export const rejected = "rejected";
export const split_commission = "Split Commission";
export const simple_commission = "Simple Commission";

//permission features

export const featureTerminals = "terminal";
export const featureUsers = "user";
export const featureOwners = "owner";
export const featureDrivers = "driver";
export const featureVehicles = "vehicle";
export const featureTrailers = "trailer";
export const featureLookups = "lookup";
export const featureSettlements = "settlement";
export const featureCustomers = "customer";
export const featureBilling = "billing";
export const featureLoad = "load";
export const featureSecurity = "security-group";
export const featureDocumentType = "document-types";
export const featureLineItems = "lineItem";
export const featureCommodity = "commodity";
export const featureLoadManagement = "load-management";
export const featureDriverDocument = "driver-document";
//permission actions

export const findOne = "findOne";
export const find = "find";
export const create = "create";
export const remove = "remove";
export const findAll = "findAll";
export const update = "update";

//document types
export const img = "img";
export const pdf = "pdf";
export const xls = "xls";
export const docx = "docx";
export const all = "all";

//roles
export const admin = "admin";
export const driver = "driver";

//line hauls
export const cwt = "cwt";
export const perLoad = "perload";
export const perMile = "permile";
