import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { selectOwner } from "src/redux/reducers/owner";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { selectDriver } from "src/redux/reducers/driver";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../common/readForms/formHeader";
import FromInput from "../common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
// import Documents from "./docements";
import { driver as driverEnum } from "src/utils/enums";
import { LoadStopProps } from "src/interfaces/loadManagement";
import OwnerDriver from "src/containers/owner/driverLinking";
import OwnerTrailer from "src/containers/owner/trailerLinking";
import OwnerDocuments from "src/containers/documents";
import OwnerVehicle from "src/containers/owner/vehicleLinking";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";

interface OwnerSummaryProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}

const OwnerSummary = ({
  loading,
  pageLoading,
  onSubmit,
}: OwnerSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const [loadStops, setLoadStops] = useState<Array<LoadStopProps>>([
    // { ...defaultLoadStopObject },
  ]);

  const user = useAppSelector(selectUser);
  // const driver = useAppSelector(selectLoadManagement);
  const driver = useAppSelector(selectDriver);
  const owner = useAppSelector(selectOwner);

  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };
  const handleCloseStopOffFormModal = () => {
    setOpenDispatchFormModal && setOpenDispatchFormModal(null);
  };
  const handleSetMilageValue = async (value: any) => {
    // setValue("freightBill.mileage", value);
  };

  // const loadStopCancelled = driver?.loadStops?.every(
  //   (item) => item.dispatchStatus === "cancelled"
  // );
  // const loadStopPlanned = driver?.loadStops?.every(
  //   (item) => item.dispatchStatus === "planned"
  // );

  // useEffect(() => {
  //   if (
  //     driver != null &&
  //     Object.keys(driver).length > 0 &&
  //     driver?.loadStops.length > 0
  //   ) {
  //     setLoadStops([...driver?.loadStops]);
  //   }
  // }, []);

  return (
    <Box>
      {pageLoading ? (
        <CustomFormGrid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CustomFormGrid>
            <CircularProgress sx={{ height: "20", width: "20px" }} />
          </CustomFormGrid>
        </CustomFormGrid>
      ) : (
        <>
          <MobileCheck
            grid1={
              <CustomFormGrid container>
                <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                  <Box className={classes.loadFormTitleContainer}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      <Button>
                        <ArrowBack onClick={() => navigate(-1)} />
                      </Button>
                      <Typography
                        variant="h6"
                        className={classes.loadFormMainHeadings}
                      >
                        {translation("owner.list.title")}
                      </Typography>
                    </Box>
                  </Box>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                  <CustomFormGrid
                    container
                    className={classes.buttonContainer}
                    justifyContent="flex-end"
                  >
                    {/*  <Button
                  variant="outlined"
                  className={classes.formButtonDiscard}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.discard")}
                </Button> */}
                    {/*
                {params?.loadId &&
                  (loadStopCancelled === true || loadStopPlanned === true) && (
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonCancel}
                      onClick={() => handleOpenConfirmModel()}
                    >
                      CANCEL LOAD
                    </Button>
                  )} */}

                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonEdit}
                      onClick={() =>
                        navigate(`/drivers/owners/${params?.ownerId}`)
                      }
                    >
                      EDIT INFORMATION
                    </Button>

                    {/* <Button
                  color="error"
                  variant="contained"
                  className={classes.formButtonDispatch}
                  onClick={() => setOpenDispatchFormModal(0)}
                >
                  DISPATCH
                </Button> */}
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
            grid2={
              <CustomFormGrid container>
                <CustomFormGrid items lg={4} md={4} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("common.personalInformation.label")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.firstName.label")}
                            input={owner?.firstName}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.lastName.label")}
                            input={owner?.lastName}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.status.label")}
                            input={owner?.status}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("owner.company.label")}
                            input={owner?.state}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid items lg={4} md={4} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("common.contactInformation.label")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.email.label")}
                            input={owner?.email}
                            checkColor={true}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.phone.label")}
                            input={owner?.phone}
                            checkColor={false}
                          />
                        </CustomFormGrid>

                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.altPhoneNumber.label")}
                            input={owner?.altPhone}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.faxPhoneNumber.label")}
                            input={owner?.faxPhone}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid items lg={4} md={4} xs={12}>
                  <CustomFormGrid>
                    <FromHeader title={translation("common.address.label")} />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.address.label")}
                            input={owner?.address}
                            checkColor={true}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.city.label")}
                            input={owner?.city}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.state.label")}
                            input={owner?.state}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.zip.label")}
                            input={driver?.zip}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid container>
                  <CustomFormGrid items lg={4} md={4} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation("owner.settlementInformation.label")}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("owner.settlementDay.label")}
                              input={owner?.settlementDay}
                              checkColor={true}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("owner.homeTerminal.label")}
                              input={
                                owner?.terminal != null
                                  ? owner?.terminal?.title
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <CustomFormGrid items lg={4} md={4} xs={12}>
                    <CustomFormGrid>
                      <FromHeader
                        title={translation("common.billingInfo.label")}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid>
                      <CustomFormGrid>
                        <Paper elevation={1} sx={{ width: "100%" }}>
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "owner.billingInfo.FederalID"
                              )}
                              input={
                                owner?.billingInformation != null
                                  ? owner?.billingInformation?.federalId
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("owner.billingInfo.PayPct")}
                              input={
                                driver?.billingInformation != null
                                  ? driver?.billingInformation
                                      ?.defaultTractorPercentage
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "owner.billingInfo.PayPerMile"
                              )}
                              input={
                                driver?.billingInformation != null
                                  ? driver?.billingInformation
                                      ?.payPerMile_Loaded
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation("owner.billingInfo.Empty")}
                              input={
                                driver?.billingInformation != null
                                  ? driver?.billingInformation?.payPerMile_Empty
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                          <Divider variant="middle" />
                          <CustomFormGrid>
                            <FromInput
                              heading={translation(
                                "driver.form.preferredSettlement.label"
                              )}
                              input={
                                driver?.billingInformation != null
                                  ? driver?.billingInformation
                                      ?.preferredSettlement
                                  : "---"
                              }
                              checkColor={false}
                            />
                          </CustomFormGrid>
                        </Paper>
                      </CustomFormGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <OwnerDocuments role="owner" roleId={params?.ownerId} />
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <OwnerDriver />
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                  <OwnerTrailer />
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                  <OwnerVehicle />
                </CustomFormGrid>
              </CustomFormGrid>
            }
          />
        </>
      )}
    </Box>
  );
};

export default OwnerSummary;
