import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { UserRole } from "src/interfaces/user";

export interface ConfigState {
  roles: Array<UserRole>;
}

const initialState: ConfigState = {
  roles: [],
};

export const configSlice = createSlice({
  name: "config",
  initialState,

  reducers: {
    setUserRoles: (state, action: PayloadAction<Array<UserRole>>) => {
      state.roles = action.payload;
    },
    resetConfigState: (state) => {
      state.roles = [];
    },
  },
});

export const { setUserRoles, resetConfigState } = configSlice.actions;
export const selectUserRoles = (state: RootState): Array<UserRole> =>
  state.config.roles;

export default configSlice.reducer;
