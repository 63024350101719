import React from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  Button,
  Divider,
  Paper,
  MenuList,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { Icon } from "ts-react-feather-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import { logout } from "src/redux/actions/auth";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

interface ProfileDropdownOpts {
  isOpened: boolean;
  anchorEl: null | HTMLElement
  onClose(): any;
}

const ProfileDropdown = (props: ProfileDropdownOpts) => {
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const goToSettings = () => {
    // handleCloseMenu();
    navigate("/settings");
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleLogout = async () => {
    navigate(`/auth/login/${user?.tenant?.url}`)
    await dispatch(logout());
  };


  return (
    <Menu
      id="profile-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={props.isOpened}
      onClose={handleClose}
      onClick={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          left: "auto !important",
          right: "60px !important",
          top: "42px !important",
        },
        "& .MuiList-padding": {
          p: "0px !important",
        },
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,

          '& .MuiAvatar-root': {
            width: 64,
            height: 64,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 30,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Paper sx={{ width: 320, maxWidth: '100%' }}>

        <Box display="flex" alignItems="center" sx={{ p: 2 }}>
          <Avatar
            src={
              user?.profilePicture
                ? user?.profilePicture.includes("porto-files-upload")
                  ? user?.profilePicture
                  : `${process.env.REACT_APP_BASE_URL}/${user.profilePicture}`
                : ""
            }
            alt={user?.firstName?.toLocaleUpperCase()}
            sx={{
              width: "60px",
              height: "60px",
            }}
          />
          <Box
            sx={{
              ml: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                lineHeight: "1.235",
                textTransform: "capitalize",
              }}
            >
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              fontWeight="400"
              sx={{ textTransform: "capitalize" }}
            >
              {user?.role?.name}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography
                color="textSecondary"
                display="flex"
                alignItems="center"
                sx={{
                  color: (theme) => theme.palette.grey.A200,
                  mr: 1,
                }}
              >
                <Icon name="mail" size="18" />
              </Typography>
              <Typography
                color="textSecondary"
                variant="h6"
                sx={{ textTransform: "lowercase" }}
              >
                {user?.email}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider
          style={{
            marginTop: 0,
            marginBottom: 0,
          }}
        />
        <MenuList>

          <MenuItem onClick={goToSettings}>
            <ListItemIcon>
              <SettingsIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={translation("header.dropdown.setting.title")}
              secondary={translation("header.dropdown.setting.subtitle")}
            />
          </MenuItem>

          <Divider
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          />
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={translation("logout")} />
          </ListItemButton>
        </MenuList>


        {/* <Button
          sx={{
            mt: 2,
            width: "100%",
          }}
          variant="contained"
          color="primary"
          onClick={handleLogout}
        >
          {translation("logout")}
        </Button> */}
      </Paper>
    </Menu>
  );
};

export default ProfileDropdown;
