import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "src/components/forgotPassword";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { forgotPassword } from "src/redux/actions/auth";
import { getAllCompanies } from "src/redux/actions/company";
import { useLocation } from "react-router";

const ForgotPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const { companyData } = location.state as any;
  useEffect(() => {
    dispatch(getAllCompanies());
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const response = await dispatch(forgotPassword(data));
    if (response.success) {
      setTimeout(() => navigate(`/auth/login/${companyData?.name}`), 500);
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <ForgotPassword onSubmit={onSubmit} loading={loading} />
    </>
  );
};

export default ForgotPasswordContainer;
