import { AppDispatch } from "../store";
import { setUserRoles } from "../reducers/config";
import { getServerData } from "../api/index";

export const getAllRoles = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/role`));
  if (response?.data?.data) {
    dispatch(setUserRoles(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
