import { AppDispatch } from "../store";

import {
  setAllWaitingDocuments,
  setAllProcessingDocs,
  setAllPrintDocs,
  setAllFactoringInvoices,
} from "../reducers/billing";
import { getServerData, patchServerData } from "../api/index";

export const getAllLoadsOnStatus =
  (status?: string, limit?: number, page?: number, filter?:any) =>
  async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/load/billing/${status}`) as any
    );

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data?.data)) {
        if (status === "waitingForDocument") {
          dispatch(setAllWaitingDocuments(response?.data?.data));
        }
        if (status === "processingQueue") {
          dispatch(setAllProcessingDocs(response?.data?.data));
        }
        if (status === "printQueue") {
          dispatch(setAllPrintDocs(response?.data?.data));
        }
        if (status === "factoringInvoices") {
          dispatch(setAllFactoringInvoices(response?.data?.data));
        }
      }
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getWaitingForDocument =
  () =>
  async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/load/billing/waiting-for-document`) as any
    );
    const temp = {};
    const loadArray = <any>[];
    response?.data?.data?.map((item: any) => {
      // @ts-ignore: Unreachable code error

      if (temp[item.id] !== undefined) {
        // @ts-ignore: Unreachable code error
        const { documentid, documentDefinitionId, ...item2 } = item;
        // @ts-ignore: Unreachable code error
        if (documentid && documentDefinitionId) {
          // @ts-ignore: Unreachable code error

          temp[item.id]["document"].push({
            documentid,
            documentDefinitionId,
          });
        }
        // @ts-ignore: Unreachable code error
      } else {
        // @ts-ignore: Unreachable code error

        temp[item.id] = {};
        // @ts-ignore: Unreachable code error

        temp[item.id] = item;
        // @ts-ignore: Unreachable code error

        // temp[item.id]["id"] = item.id;

        // @ts-ignore: Unreachable code error

        const { documentid, documentDefinitionId, ...item2 } = item;
        // @ts-ignore: Unreachable code error
        if (documentid && documentDefinitionId) {
          // @ts-ignore: Unreachable code error

          temp[item.id]["document"] = [{ documentid, documentDefinitionId }];
        }
        // @ts-ignore: Unreachable code error
      }
    });
    for (const key of Object.keys(temp)) {
      // @ts-ignore: Unreachable code error
      loadArray.push(temp[key]);
    }

    // if (response?.data?.data) {

    // }
    return { success: response.success, data: loadArray };
  };

export const updateLoadProcessingQueue =
  (data: any, status: string) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/load/billingStatus/${status}`, data) as any
    );

    /*  if (response?.data?.data?.commodity) {
      dispatch(setSingleCommodity(response?.data?.data?.commodity));
    } */
    return { success: response.success, data: response };
  };

export const updateLoadPrintQueue =
  (data: any, status?: string) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/load/billingStatus/${status}`, data) as any
    );
    /*  if (response?.data?.data?.commodity) {
      dispatch(setSingleCommodity(response?.data?.data?.commodity));
    } */
    return { success: response.success, data: response };
  };
