import React, { useEffect, useState } from "react";
import moment from "moment";
import { Autocomplete, Box, InputAdornment } from "@mui/material";
import CustomTextField from "./customTextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createFilterOptions } from '@mui/material/Autocomplete';
import ScheduleIcon from '@mui/icons-material/Schedule';

const autoCompleteFilter = createFilterOptions<string>();

interface DateTimeObj {
    onChange(data: any): any;
    value: any;
    dateLabel?: string;
    timeLabel?: string;
    dateInput?: boolean;
}

const CustomDateTimePicker = ({
    onChange,
    value,
    dateLabel = "",
    timeLabel = "",
    dateInput = true,
}: DateTimeObj): JSX.Element => {

    const [datePart, setDatePart] = useState<string | null>(null);
    const [timePart, setTimePart] = useState<string | null>(null);


    const start: number = new Date().setHours(0, 0, 0, 0);
    const end = moment(new Date().setHours(23, 59, 59, 0));

    const current = moment(start);

    const timelist = [];

    while (current < end) {
        timelist.push(current.format(`HH:mm`));
        current.add(15, 'minutes');
    }

    useEffect(() => {
        if (dateInput) {
            if (value && isDateTimeValid(value)) {
                setDatePart(moment(value).format(`MM/DD/YYYY`));
                setTimePart(moment(value).format(`HH:mm`));
            }
        } else {
            if (value && isTimeValid(value)) {
                setTimePart(value);
            }
        }

    }, [value])

    const handleTimeChange = (inputValue: string | null) => {
        if (inputValue?.length === 3) {
            inputValue = "0" + inputValue;
        }
        if (inputValue?.length == 4) {
            inputValue = inputValue.slice(0, 2) + ":" + inputValue.slice(2);
        }

        if (isTimeValid(inputValue)) {
            setTimePart(inputValue);

            if (dateInput) {
                const finalDTObj = moment(datePart + ' ' + inputValue, 'MM/DD/YYYY HH:mm');

                const finalDT = finalDTObj.format('MM/DD/YYYY HH:mm');

                console.log(`Time change: `, value, finalDT)

                onChange(finalDT);
            } else {
                onChange(inputValue);
            }
        }
    }

    const handleDateChange = (inputValue: string | null) => {
        inputValue = moment(inputValue).format(`MM/DD/YYYY`);
        setDatePart(inputValue);

        const finalDTObj = moment(inputValue + ' ' + timePart, 'MM/DD/YYYY HH:mm');

        const finalDT = finalDTObj.format('MM/DD/YYYY HH:mm');

        console.log(`Date change: `, value, finalDT)

        onChange(finalDT);
    }

    const isTimeValid = (inputValue: string | null) => {
        if (inputValue) {
            const resp = moment(inputValue, 'HH:mm', true).isValid();
            return resp;
        }
        return false;
    }

    const isDateTimeValid = (inputValue: string | null) => {
        if (inputValue) {
            const resp = moment(inputValue, 'MM/DD/YYYY HH:mm', true).isValid();
            return resp;
        }
        return false;
    }


    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                {dateInput &&
                    <Box sx={{ width: "60%", pr: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                //   {...restFields}
                                label={dateLabel}
                                // disablePast
                                value={datePart}
                                onChange={(inputValue) => (handleDateChange(inputValue))}
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params) => (
                                    <CustomTextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                }
                <Box sx={{ width: dateInput ? "40%" : "100%", pl: 1 }}>
                    <Autocomplete
                        id="customtimepicker"
                        value={timePart || ""}
                        fullWidth
                        autoSelect
                        autoHighlight
                        onChange={(event, newValue: any) => {
                            if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                handleTimeChange(newValue.inputValue);
                            } else {
                                handleTimeChange(newValue);
                            }
                        }}

                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        options={timelist}
                        getOptionLabel={(option: any) => {
                            // // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option || "";
                        }}

                        // renderOption={(props, option) => <li {...props}>{option.company}</li>}
                        filterOptions={(options, params) => {
                            const filtered = autoCompleteFilter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push(`${inputValue}`);
                            }

                            return filtered;
                        }}
                        freeSolo
                        disableClearable
                        renderInput={(params) => (
                            <CustomTextField
                                {...params}
                                size="small"
                                label={timeLabel}
                                onChange={onChange}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ScheduleIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>

        </React.Fragment>
    )
}

export default CustomDateTimePicker;