import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { reorderLookups } from "src/redux/actions/lookup";

interface DataTableProps {
  data: Array<any>;
  handleSort: () => any;
  selectedType: string;
}

const ReorderLookups = ({
  data,
  handleSort,
  selectedType,
}: DataTableProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDragEnd = async (result: any) => {
    const { destination, draggableId } = result;
    setLoading(true);
    await dispatch(
      reorderLookups(
        parseInt(draggableId),
        parseInt(destination.index) + 1,
        selectedType
      )
    );
    setLoading(false);
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {loading ? (
        <Grid
          container
          sx={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Droppable droppableId={`table-lookups`}>
          {(provided) => (
            <Table
              aria-label="simple table"
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                mt: 5,
                whiteSpace: "nowrap",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      {translation("common.id.label")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      {translation("lookup.content.label")}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color="textSecondary" variant="h6">
                      {translation("lookup.order.label")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((product, index) => (
                  <Draggable
                    key={product.id}
                    draggableId={`${product.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <TableRow
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            {product.id}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: "600",
                                }}
                              >
                                {product.content}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell>
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "600",
                              }}
                            >
                              {product.order}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
              </TableBody>
            </Table>
          )}
        </Droppable>
      )}
      <Grid container justifyContent="flex-end" sx={{ margin: "10px" }}>
        <Button color="primary" variant="contained" onClick={handleSort}>
          {translation("common.btn.done")}
        </Button>
      </Grid>
    </DragDropContext>
  );
};

export default ReorderLookups;
