import { AppDispatch } from "../store";
import {
  setAllOwners,
  setSingleOwner,
  // updateOwnerList,
  setAllAvailableOwners,
} from "../reducers/owner";
import { OwnerProps } from "src/interfaces/owner";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllOwners =
  (limit?: number, page?: number, filter?: any) =>
    async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/owner`) as any
    );

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data?.data)) {
        dispatch(setAllOwners(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getOwnerById = (id: number) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/owner/${id}`) as any);
  if (response?.data?.data) {
    dispatch(setSingleOwner(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
export const addOwner = (data: OwnerProps) => async (dispatch: AppDispatch) => {
  const response = await dispatch(postServerData(`/owner`, data) as any);

  if (response?.data?.data) {
    // dispatch(updateOwnerList(response?.data?.data));
    dispatch(setSingleOwner(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
export const updateOwner =
  (data: OwnerProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/owner/${id}`, data) as any);
    if (response?.data?.data?.data) {
      dispatch(setSingleOwner(response?.data?.data?.data?.owner));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getAllAvailableOwner = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/owner/available`) as any);
  if (response?.data?.data) {
    dispatch(setAllAvailableOwners(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
