import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { LoadAddress } from "src/interfaces/loadManagement";

export interface LoadManagementState {
  loadAddressList: Array<LoadAddress>;
  singleLoadAddress: LoadAddress | null;
}

const initialState: LoadManagementState = {
  loadAddressList: [],
  singleLoadAddress: null,
};

export const loadAddressSlice = createSlice({
  name: "loadAddress",
  initialState,

  reducers: {
    setAllLoadAddress: (
      state,
      action: PayloadAction<Array<LoadAddress>>
    ) => {
      state.loadAddressList = action.payload;
    },
    setSingleLoadAddress: (
      state,
      action: PayloadAction<LoadAddress>
    ) => {
      state.singleLoadAddress = action.payload;
    },
    removeSingleLoadAddress: (state) => {
      state.singleLoadAddress = null;
    },
    resetLoadAddressState: (state) => {
      state.singleLoadAddress = null;
      state.loadAddressList = [];
    },
  },
});

export const {
  setAllLoadAddress,
  setSingleLoadAddress,
  resetLoadAddressState,
  removeSingleLoadAddress,
} = loadAddressSlice.actions;

export const selectLoadAddresses= (
  state: RootState
): any => state.loadAddress.loadAddressList;

export const selectLoadAddress = (
  state: RootState
): LoadAddress | null => state.loadAddress.singleLoadAddress;

export default loadAddressSlice.reducer;
