import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllSettlementCodes } from "src/redux/actions/settlementCodes";
import { selectSettlementCodes } from "src/redux/reducers/settlementCodes";
import { useTranslation } from "react-i18next";
import { featureCommodity, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function SettlementCodes() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const settlementCodes = useAppSelector(selectSettlementCodes);
  const [filter, setFilter] = useState<any>({});
  const [settlementData, setSettlementData] = useState<any>({});

  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const user = useAppSelector(selectUser);

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const settlementPermissions =
    allUserPermissions && allUserPermissions[featureCommodity];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementPermissions.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementPermissions.findIndex((item) => item === update);
  const getAllSettlementCodesList = async () => {
    setLoading(true);
    await dispatch(
      getAllSettlementCodes(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getAllSettlementCodesListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllSettlementCodes(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };
  useEffect(() => {
    getAllSettlementCodesListWithFilter();
  }, [filter]);
  /*   function pageExists(page: any) {
    return commoditiesData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getAllSettlementCodesListWithFilter();
    } else {
      getAllSettlementCodesList();
    }
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (settlementCodes?.data?.length > 0) {
      setSettlementData({
        ...settlementData?.settlementCodes,
        settlementCodes,
      });
    } else {
      setSettlementData({});
    }
  }, [settlementCodes]);

  const addCommodity = () => {
    navigate("/config/settlement-codes/create");
  };
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );
  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/config/settlement-codes/${params.row.id}`);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      filterOperators,
    },
    {
      field: "code",
      headerName: translation("settlementCode.code.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterOperators,
    },
    {
      field: "availableForDispatch",
      headerName: translation("settlementCode.available.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "type",
      headerName: translation("settlementCode.type.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterOperators,
    },
    {
      field: "defaultAmount",
      headerName: translation("settlementCode.amount.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "systemUseOnly",
      headerName: translation("settlementCode.system.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "title",
      headerName: translation("settlementCode.title.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
    {
      field: "externalDescription",
      headerName: translation("settlementCode.external.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
  ];
  const onRowClick = (params: GridRowParams) => {
    navigate(params.row.id);
  };

  const searchSettlementCodes = () => {
    navigate("/config/settlement-codes/search");
  };

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("settlementCode.list.title")}
        rows={settlementData?.settlementCodes?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addCommodity}
        createPermission={createPermission > -1}
        onRowClick={onRowClick}
        onClickSearch={searchSettlementCodes}
        rowsState={rowsState}
        setRowsState={setRowsState}
        pagination={settlementCodes?.pagination}
        data={settlementData?.settlementCodes}
        setData={setSettlementData}
        add={true}
        setFilter={setFilter}
        search={"settlementCodes"}
      />
    </CustomFormGrid>
  );
}
