import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CustomizerState {
  activeDir: string;
  activeNavbarBg: string;
  activeBg: string;
  activeMode: string;
  activeTheme: string;
  Width: number;
  language: string;
}

const initialState: CustomizerState = {
  activeDir: "ltr",
  activeNavbarBg: "#0b70fb",
  activeBg: "#ffffff",
  activeMode: "light",
  activeTheme: "BLUE_THEME",
  Width: 240,
  language: "en",
};

export const customizerSlice = createSlice({
  name: "customizer",
  initialState,

  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      state.activeTheme = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<string>) => {
      state.activeMode = action.payload;
    },
    setNavbarBg: (state, action: PayloadAction<string>) => {
      state.activeNavbarBg = action.payload;
    },
    setBg: (state, action: PayloadAction<string>) => {
      state.activeBg = action.payload;
    },
    setDir: (state, action: PayloadAction<string>) => {
      state.activeDir = action.payload;
    },
    changeLocale: (state, action: PayloadAction<string>) => {
      localStorage.setItem("locale", action.payload);
      state.language = action.payload;
    },
  },
});

export const {
  setTheme,
  setDarkMode,
  setNavbarBg,
  setBg,
  setDir,
  changeLocale,
} = customizerSlice.actions;

export const selectMode = (state: RootState): string =>
  state.customizer.activeMode;
export const selectTheme = (state: RootState): string =>
  state.customizer.activeTheme;
export const selectNavBg = (state: RootState): string =>
  state.customizer.activeNavbarBg;
export const selectBg = (state: RootState): string => state.customizer.activeBg;
export const selectActiveDir = (state: RootState): string =>
  state.customizer.activeDir;
export const selectCustomizer = (state: RootState): CustomizerState =>
  state.customizer;
export const selectLocale = (state: RootState): string =>
  state.customizer.language;

export default customizerSlice.reducer;
