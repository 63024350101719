import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CreateVehicleContainer from "src/containers/vehicle/form";
const VehicleForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Vehicles" description="Porto Vehicles" />
      <CreateVehicleContainer />
    </Grid>
  );
};

export default VehicleForm;
