import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  Autocomplete,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { SettlementProps } from "src/interfaces/settlement";
import { DriverProps } from "src/interfaces/driver";
import { VehicleProps } from "src/interfaces/vehicle";
import { SettlementCodesProps } from "src/interfaces/settlementCodes";
import { TrailerProps } from "src/interfaces/trailer";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectSettlement } from "src/redux/reducers/settlement";
import { Controller } from "react-hook-form";
import CustomTextField from "src/components/customElements/customTextField";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { typedKeys } from "src/utils/common";
import { ArrowBack } from "@mui/icons-material";
import { accountType } from "../../utils/dropdowns/common";
import { type } from "../../utils/dropdowns/common";

import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { selectSettlementCodes } from "src/redux/reducers/settlementCodes";
import { selectTrailers } from "src/redux/reducers/trailer";
import { selectVehicles } from "src/redux/reducers/vehicle";
import { selectDrivers } from "src/redux/reducers/driver";
import { selectAllAccount } from "src/redux/reducers/settlement";

interface AddEarningFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: LineItemProps) => any; */
  onSubmit: (data: any) => any;
}
const AddEarningForm = ({
  loading,
  pageLoading,
  onSubmit,
}: AddEarningFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  // const lineItem = useAppSelector(selectLineItem);
  const allDrivers = useAppSelector(selectDrivers);
  const allTrailers = useAppSelector(selectTrailers);
  const allVehicles = useAppSelector(selectVehicles);
  const settlement = useAppSelector(selectSettlement);
  const allAccounts = useAppSelector(selectAllAccount );
  const settlementCodes = useAppSelector(selectSettlementCodes);
  const [trailer, setTrailer] = useState<TrailerProps | null>(null);
  const [vehicle, setVehicle] = useState<VehicleProps | null>(null);
  const [driver, setDriver] = useState<DriverProps | null>(null);
  const [account, setAccount] = useState<any | null>(null);
  const [settlementCode, setSettlementCode] =
    useState<SettlementCodesProps | null>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const handleTrailerChange = (event: any, newValue: TrailerProps | null) => {
    setTrailer(newValue);
    setValue("trailer", newValue?.id);
  };
  const handleAccountChange = (event: any, newValue: any | null) => {
    setAccount(newValue);
    setValue("account", newValue?.id);
  };

  const handleVehicleChange = (event: any, newValue: VehicleProps | null) => {
    setVehicle(newValue);
    setValue("vehicle", newValue?.id);
  };
  const handleDriverChange = (event: any, newValue: DriverProps | null) => {
    setDriver(newValue);
    setValue("driver", newValue?.id);
  };
  const handleSettlementCodeChange = (
    event: any,
    newValue: SettlementCodesProps | null
  ) => {

    setSettlementCode(newValue);
    setValue("settlementCode", newValue?.id);
    setValue("rate", newValue?.defaultAmount ? newValue?.defaultAmount : (settlement && settlement.rate) ? settlement?.rate : null);
    delete errors["rate"];
  };

  const setFormValues = (key: keyof SettlementProps) => {
    if (settlement) {
      if (typeof settlement[key] === "boolean") {
        setValue(key, settlement[key]);
      } else {
        setValue(key, settlement[key] ? settlement[key] : "");
      }
    }
  };


  useEffect(() => {
    if (params?.id && settlement) {
      const allAttributes = typedKeys(settlement);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
        if (attribute === "driver") {
          if (settlement?.driver && typeof settlement?.driver === "object") {
            setDriver(settlement?.driver);
            setValue(
              "driver",
              settlement?.driver.id ? settlement?.driver.id : ""
            );
          } else {
            setValue("driver", settlement?.driver ? settlement?.driver : "");
          }
        } else if (attribute === "vehicle") {
          if (settlement?.vehicle && typeof settlement?.vehicle === "object") {
            setVehicle(settlement?.vehicle);
            setValue(
              "vehicle",
              settlement?.vehicle.id ? settlement?.vehicle.id : ""
            );
          } else {
            setValue("vehicle", settlement?.vehicle ? settlement?.vehicle : "");
          }
        }else if (attribute === "account") {
          if (settlement?.account && typeof settlement?.account === "object") {
            setAccount(settlement?.account);
            setValue(
              "account",
              settlement?.account ? settlement?.account : ""
            );
          } else {
            setValue("account", settlement?.account ? settlement?.account : "");
          }
        }
        else if (attribute === "trailer") {
          if (settlement?.trailer && typeof settlement?.trailer === "object") {
            setTrailer(settlement?.trailer);
            setValue(
              "trailer",
              settlement?.trailer?.id ? settlement?.trailer?.id : ""
            );
          } else {
            setValue("trailer", settlement?.trailer ? settlement?.trailer : "");
          }
        } else if (attribute === "settlementCode") {
          if (
            settlement?.settlementCode &&
            typeof settlement?.settlementCode === "object"
          ) {
            setSettlementCode(settlement?.settlementCode);
            setValue(
              "settlementCode",
              settlement?.settlementCode ? settlement?.settlementCode : ""
            );
          } else {
            setValue(
              "settlementCode",
              settlement?.settlementCode ? settlement?.settlementCode : ""
            );
          }
        }
      }
    }
  }, [params?.id, settlement]);

  return (
    <div>
      <Card variant="outlined" className={classes.formContainer}>
        <Box className={classes.formTitleContainer}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography className={classes.formTitle}>
              {translation("settlement.addEarningForm.label")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent className={classes.formContentContainer}>
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
                <CustomFormGrid container>
                  <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                    <Controller
                      name="accountType"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="accountType"
                          label={translation(
                            "settlement.addEarningForm.accountType.label"
                          )}
                          error={Boolean(errors?.accountType)}
                          select
                          helperText={
                            errors?.accountType &&
                            translation(
                              "settlement.addEarningForm.accountType.label"
                            )
                          }
                          required
                          /*  onChange={(e: any) =>
                handleLoadStopChange(e.target.value, index, "type")
              }
              value={item.type} */
                        >
                          {accountType &&
                            accountType.map((item, index) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </CustomTextField>
                      )}
                    />
                  </CustomFormGrid>

                   {/* <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                    <Controller
                      name="accountType"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        id="accountName"
                        label={translation(
                          "settlement.addEarningForm.accountName.label"
                        )}
                        error={Boolean(errors?.accountName)}
                        helperText={
                          errors?.accountName &&
                          translation(
                            "settlement.addEarningForm.accountName.label"
                          )
                        }
                          required

                        >
                          {allAccounts &&
                            allAccounts.map((item :any , index:any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </CustomTextField>
                      )}
                    />
                  </CustomFormGrid> */}
                  <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                      <Controller
                        name="account"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                           {...field}
                            id="combo-box-demo"
                            fullWidth
                            options={allAccounts}
                            onChange={(
                              event: any,
                              newValue: any | null
                            ) => {
                              field.onChange(newValue);
                              handleAccountChange(event, newValue)

                            }}
                            value={account}
                             getOptionLabel={(option) =>
                              option?.accountType +
                              "-" +
                              option?.accountName}
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                id="account"
                                size="small"
                                label={translation(
                                  "settlement.addEarningForm.accountName.label"
                                )}
                                required
                                error={Boolean(errors?.account)}
                               // error={Boolean(errors?.accountType)}
                                helperText={
                                  errors?.account &&
                                  translation(
                                    "settlement.addEarningForm.accountName.label"
                                  ) }/>
                            )} /> )} /> </CustomFormGrid>


                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="enteredDate"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label={translation(
                            "settlement.addEarningForm.date.label"
                          )}
                          disablePast
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params: any) => (
                            <CustomTextField
                              {...params}
                              {...field}
                              size="small"
                              id="enteredDate"
                              fullWidth
                              error={Boolean(errors?.enteredDate)}
                              helperText={
                                errors?.enteredDate &&
                                translation(
                                  "settlement.addEarningForm.date.label"
                                )
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="dateToPay"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label={translation(
                            "settlement.addEarningForm.dateToPay.label"
                          )}
                          disablePast
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params: any) => (
                            <CustomTextField
                              {...params}
                              {...field}
                              size="small"
                              id="dateToPay"
                              fullWidth
                              error={Boolean(errors?.dateToPay)}
                              helperText={
                                errors?.dateToPay &&
                                translation(
                                  "settlement.addEarningForm.dateToPay.label"
                                )
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="load"
                      control={control}
                      defaultValue={""}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="load"
                          label={translation(
                            "settlement.addEarningForm.loadId.label"
                          )}
                          error={Boolean(errors?.load)}
                          helperText={
                            errors?.load &&
                            translation(
                              "settlement.addEarningForm.loadId.label"
                            )
                          }
                          //  required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="invoiceNo"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="invoiceNo"
                          label={translation(
                            "settlement.addEarningForm.invoices.label"
                          )}
                          error={Boolean(errors?.invoiceNo)}
                          helperText={
                            errors?.invoiceNo &&
                            translation(
                              "settlement.addEarningForm.invoices.label"
                            )
                          }
                           required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="invoiceDate"
                      control={control}
                      defaultValue={""}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label={translation(
                            "settlement.addEarningForm.invoicesDate.label"
                          )}
                          disablePast
                          inputFormat="MM/dd/yyyy"
                          renderInput={(params: any) => (
                            <CustomTextField
                              {...params}
                              {...field}
                              size="small"
                              id="invoiceDate"
                              fullWidth
                              error={Boolean(errors?.invoiceDate)}
                              helperText={
                                errors?.invoiceDate &&
                                translation(
                                  "settlement.addEarningForm.invoicesDate.label"
                                )
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </CustomFormGrid>
                  {allDrivers?.data && (
                    <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                      <Controller
                        name="driver"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="combo-box-demo"
                            fullWidth
                            options={allDrivers?.data}
                            onChange={(
                              event: any,
                              newValue: DriverProps | null
                            ) => handleDriverChange(event, newValue)}
                            value={driver}
                            getOptionLabel={(option) => option?.firstName}
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                id="driver"
                                size="small"
                                label={translation(
                                  "settlement.addEarningForm.Driver.label"
                                )}
                                error={Boolean(errors?.driver)}
                                helperText={
                                  errors?.driver &&
                                  translation(
                                    "settlement.addEarningForm.Driver.label"
                                  )
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                  )}
                  {allVehicles?.data && (
                    <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                      <Controller
                        name="vehicle"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="combo-box-demo"
                            fullWidth
                            options={allVehicles?.data}
                            onChange={(
                              event: any,
                              newValue: VehicleProps | null
                            ) => handleVehicleChange(event, newValue)}
                            value={vehicle}
                            getOptionLabel={(option) =>
                              option?.vehicleID +
                              "-" +
                              option?.model +
                              "-" +
                              option?.year
                            }
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                id="vehicle"
                                size="small"
                                label={translation(
                                  "settlement.addEarningForm.Vehicle.label"
                                )}
                                error={Boolean(errors?.vehicle)}
                                helperText={
                                  errors?.vehicle &&
                                  translation(
                                    "settlement.addEarningForm.Vehicle.label"
                                  )
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                  )}
                  {allTrailers?.data && (
                    <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                      <Controller
                        name="trailer"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="combo-box-demo"
                            fullWidth
                            options={allTrailers?.data}
                            onChange={(
                              event: any,
                              newValue: TrailerProps | null
                            ) => handleTrailerChange(event, newValue)}
                            value={trailer}
                            getOptionLabel={(option) =>
                              option?.trailerId +
                              "-" +
                              option?.model +
                              "-" +
                              option?.year
                            }
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                id="trailer"
                                size="small"
                                label={translation(
                                  "settlement.addEarningForm.Trailer.label"
                                )}
                                error={Boolean(errors?.trailer)}
                                helperText={
                                  errors?.trailer &&
                                  translation(
                                    "settlement.addEarningForm.Trailer.label"
                                  )
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                  )}
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="recurringSettlementID"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="recurringSettlementID"
                          type="number"
                          inputProps={{ min: 0 }}
                          label={translation(
                            "settlement.addEarningForm.settlementId.label"
                          )}
                          error={Boolean(errors?.recurringSettlementID)}
                          helperText={
                            errors?.recurringSettlementID &&
                            translation(
                              "settlement.addEarningForm.settlementId.label"
                            )
                          }
                           required
                        />
                      )}
                    />
                  </CustomFormGrid>

                  {settlementCodes?.data && (
                    <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                      <Controller
                        name="settlementCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="combo-box-demo"
                            fullWidth
                            options={settlementCodes?.data}
                            onChange={(
                              event: any,
                              newValue: SettlementCodesProps | null
                            ) => {
                              field.onChange(newValue);
                              handleSettlementCodeChange(event, newValue)}}
                            value={settlementCode}
                            getOptionLabel={(option) => option?.code}
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                id="settlementCode"
                                size="small"
                                label={translation(
                                  "settlement.addEarningForm.settlementCode.label"
                                )}
                                error={Boolean(errors?.settlementCode)}
                                helperText={
                                  errors?.settlementCode &&
                                  translation(
                                    "settlement.addEarningForm.settlementCode.label"
                                  )
                                }
                                required
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                  )}
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="method"
                      control={control}
                      defaultValue={""}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          disabled
                          id="method"
                          label={translation(
                            "settlement.addEarningForm.method.label"
                          )}
                          error={Boolean(errors?.method)}
                          helperText={
                            errors?.method &&
                            translation(
                              "settlement.addEarningForm.method.label"
                            )
                          }
                          //   required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="rate"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                          id="method"
                          inputProps={{ min: 0 }}
                          label={translation("load.form.rate.label")}
                          error={Boolean(errors?.rate)}
                          helperText={
                            errors?.rate && translation("load.form.rate.label")
                          }
                         required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="tpe"
                          label={translation(
                            "settlement.addEarningForm.type.label"
                          )}
                          error={Boolean(errors?.type)}
                          select
                          required
                          helperText={
                            errors?.tpe &&
                            translation("settlement.addEarningForm.type.label")
                          }
                          /*  onChange={(e: any) =>
                handleLoadStopChange(e.target.value, index, "type")
              }
              value={item.type} */
                        >
                          {type &&
                            type.map((item, index) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ))}
                        </CustomTextField>
                      )}
                    />
                  </CustomFormGrid>

                  <CustomFormGrid
                    item
                    sm={12}
                    xs={12}
                    md={9}
                    lg={9}
                  ></CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="externalComment"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          rows={3}
                          id="externalComment"
                          label={translation("settlement.form.comment.label")}
                          error={Boolean(errors?.externalComment)}
                          helperText={
                            errors?.externalComment &&
                            translation("settlement.form.comment.label")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="internalComment"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="internalComment"
                          multiline
                          rows={3}
                          label={translation(
                            "settlement.form.commentInternal.label"
                          )}
                          error={Boolean(errors?.internalComment)}
                          helperText={
                            errors?.internalComment &&
                            translation("settlement.form.commentInternal.label")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid container justifyContent="center">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.formButton}
                      // onClick={handleSubmit(onSubmit)}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : params?.id ? (
                        translation("common.form.update")
                      ) : (
                        translation("common.form.add")
                      )}
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButton}
                      onClick={() => navigate(-1)}
                    >
                      {translation("common.btn.cancel")}
                    </Button>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomForm>
            </LocalizationProvider>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default AddEarningForm;
