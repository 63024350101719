import React, { useEffect, useState } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { selectTrailer } from "src/redux/reducers/trailer";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { OwnerTrailerProps } from "src/interfaces/trailer";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";
import { useStyles } from "src/styles/formStyles";
import ConfirmationDialog from "src/components/common/confirmation";
import DriverOwnerDialogBox from "src/components/common/ownerAddDialog";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid, IconButton, Card } from "@mui/material";
import { selectLinkedOwner } from "src/redux/reducers/trailer";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import { getAllOwners } from "src/redux/actions/owner";
import {
  addOwnerTrailer,
  deleteOwnerTrailerLink,
} from "src/redux/actions/ownerTrailer";
import AssignedLinkingCard from 'src/components/common/linkingCards/assignedOwner';
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function OwnerTrailer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const trailer = useAppSelector(selectTrailer);
  const linkedOwner = useAppSelector(selectLinkedOwner);
  const [translation] = useTranslation("translation");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const owner =
    linkedOwner && typeof linkedOwner.owner === "object"
      ? linkedOwner.owner
      : null;

  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const getOwnerList = async () => {
    setLoading(true);
    await dispatch(getAllOwners(0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getOwnerList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleOwnerDelete = () => {
    handleOpenDeleteModal();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (params?.trailerId) {
      setLoading(true);
      if (linkedOwner) {
        const object = {
          owner:
            typeof linkedOwner?.owner === "object"
              ? linkedOwner?.owner?.id
                ? linkedOwner?.owner?.id
                : ""
              : linkedOwner?.owner,

          trailer: parseInt(params?.trailerId),
        };

        await dispatch(deleteOwnerTrailerLink(object));
      }
      handleCloseDeleteModal();
      setLoading(false);
      getOwnerList();
    }
  };
  /* OwnerTrailerProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: OwnerTrailerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (params?.trailerId) {
      objToSubmit.trailer = parseInt(params?.trailerId)
        ? parseInt(params?.trailerId)
        : null;
    }
    objToSubmit.owner = objToSubmit.owner ? objToSubmit.owner : null;
    if (params?.trailerId) {
      const trailerOwnerLink = await dispatch(addOwnerTrailer(objToSubmit));
      if (trailerOwnerLink.success && trailerOwnerLink.data.id) {
        setTimeout(
          () => navigate(`/drivers/trailer/${params?.trailerId}`),
          500
        );
        getOwnerList();
      }
    }
    handleClose();
    setLoading(false);
  };
  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "owner.id",
      headerName: translation("owner.form.id.label"),

      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.owner === "object" && params?.row?.owner?.id
              ? params?.row?.owner?.id
              : ""}
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {" "}
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];


  return (
    <>
    {/*   <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>
            {!linkedOwner && (
              <Tooltip
                title={
                  <Typography>{translation("driver.owner.assign")}</Typography>
                }
              >
                <Fab
                  color="secondary"
                  sx={{
                    mr: 1,
                    ml: 1,
                  }}
                  size="small"
                  onClick={handleClickOpen}
                >
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      {linkedOwner ? (
        <Card>
          <Grid container alignItems="flex-end">
            <Grid item xs={12} sm={6} md={1} lg={1}>
              <Grid
                container
                alignItems="center"
                className={classes.deleteIconButton}
              >
                <IconButton
                  onClick={() => {
                    handleOwnerDelete();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.detailHeadingText}>
                  {translation("owner.form.id.label")}
                </Typography>

                <Typography className={classes.detailText}>
                  {owner?.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.detailHeadingText}>
                  {translation("owner.form.name.label")}
                </Typography>

                <Typography className={classes.detailText}>
                  {owner?.firstName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography className={classes.detailHeadingText}>
                  {translation("common.list.assignedOn")}
                </Typography>

                <Typography className={classes.detailText}>
                  {linkedOwner?.createdAt
                    ? moment(linkedOwner?.createdAt).format(dateFormat)
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              marginBottom: "0",
              textTransform: "capitalize",
              mr: 1,
              ml: 1,
            }}
          >
            {translation("driver.owner.message")}
          </Typography>
        </Grid>
      )} */}

<CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedOwner}
          headerName={"Assigned Owner"}
          isDriver={false}
          handleClickOpen={handleClickOpen}
          handleDelete={handleOwnerDelete}
          data={ owner} />
      </CustomFormGrid>
    {/*   <DataTable
        title={""}
        rows={linkedOwner?.owner}
        columns={columns}
        loading={loading}
        onClickAdd={handleClickOpen}
        rowsState={rowsState}
        setRowsState={setRowsState}
        add={true}
      />
 */}
      <DriverOwnerDialogBox
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        ownerTitle={translation("ownerTrailer.dialogbox.title")}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof linkedOwner?.owner === "object"
                ? `${linkedOwner?.owner?.firstName} ${linkedOwner?.owner?.lastName}`
                : "",
          })}
          title={translation("trailer.delete.title")}
          loading={loading}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={trailer?.ownerTrailer ? trailer?.ownerTrailer : []}
              columns={columns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              localPagination={true}
            />
          }
          title={translation("owner.assignment.logs")}
        />
      )}
    </>
  );
}
