import { AppDispatch } from "../store";
import {
  setAllSettlement,
  setSingleSettlement,
  setAllOwners,
  setAllDrivers,
  setAllTerminals,
  setSingleSettlementGeneralInfo,
  setAllAccountsWithId,
  setPrintQueueList,
  setCompletedSettlementList,
  setAllAccounts,
  setDashboard,
  setMainDashboard,
} from "../reducers/settlement";
import { GeneralInfoProps } from "src/interfaces/generalInfo";
import { SettlementProps } from "src/interfaces/settlement";
import { getServerData, postServerData, patchServerData } from "../api/index";

/********Api for line items of Driver, owner and terminal  ******/
export const getAllAccountsWithId = (id: number) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(
    getServerData(`/settlement/account/${id}`)
  );
  if (response?.data?.data) {
    dispatch(setAllAccountsWithId(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

/********Api for Driver, owner and terminal pages ******/

/* export const getAllAccountsOnType =
  (type?: string, limit?: number, page?: number, filter?: any) =>
  async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`settlement/account-type/${type}`, )
    );

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data?.data)) {
        if (type === "owner") {
          dispatch(setAllOwners(response?.data?.data));
        } else if (type === "terminal") {
          dispatch(setAllTerminals(response?.data?.data));
        } else {
          dispatch(setAllDrivers(response?.data?.data));
        }
      }
    }
    return { success: response.success, data: response?.data?.data };
    }; */

export const getAllAccountsOnType = (type?: string) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(
    getServerData(`account/unsettled/${type}`)
  );
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data)) {
      if (type === "owner") {
        dispatch(setAllOwners(response?.data?.data));
      } else if (type === "terminal") {
        dispatch(setAllTerminals(response?.data?.data));
      } else {
        dispatch(setAllDrivers(response?.data?.data));
      }
    }
  }
  return { success: response.success, data: response?.data?.data };
};

/********Api for Add earning/Deduction form Account Name field ******/

export const getAllAccounts = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`account`));

  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data)) {
      dispatch(setAllAccounts(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};

export const getAllSettlementListOnStatus = (
  status? :string,
  limit?: number,
  page?: number,
  filter?: any
) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(
    getServerData(`settled-settlement/status/${status}`, )
  );
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data?.data)) {
      if (status === "printQueue") {
        dispatch(setPrintQueueList(response?.data?.data));
      } else if (status === "completed") {
        dispatch(setCompletedSettlementList(response?.data?.data));
      }
    }
  }
  return { success: response.success, data: response?.data?.data };
  };

  export const updateSettlementStatus =
  (data: any, status?: string) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/settled-settlement/status/${status}`, data)
    );
    /*  if (response?.data?.data?.commodity) {
      dispatch(setSingleCommodity(response?.data?.data?.commodity));
    } */
    return { success: response.success, data: response };
    };

    export const saveSettlementPdf =
    (data: any) => async (dispatch: AppDispatch) => {
      const response = await dispatch(
        patchServerData(`settled-settlement/save-pdf`, data)
      );
      /*  if (response?.data?.data?.commodity) {
        dispatch(setSingleCommodity(response?.data?.data?.commodity));
      } */
      return { success: response.success, data: response.data.data };
    };

export const addSettlementGeneralInfo = (data: GeneralInfoProps) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(postServerData(`/settled-settlement`, data));
  if (response?.data?.data) {
    dispatch(setSingleSettlementGeneralInfo(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const getDashboardData = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`settlement/dashboard`));
  if (response?.data?.data) {
    dispatch(setDashboard(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const getMainDashboardData = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`settlement/main-dashboard`));
  if (response?.data?.data) {
    dispatch(setMainDashboard(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const getAllSettlements = (
  limit?: number,
  page?: number,
  filter?: any
) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(
    getServerData(`settlement/`, )
  );

  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data?.data)) {
      dispatch(setAllSettlement(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};

export const addSettlement = (data: SettlementProps) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(postServerData(`/settlement`, data));

  if (response?.data?.data) {
    dispatch(setSingleSettlement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const getSettlementById = (id: number) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(getServerData(`/settlement/${id}`));

  if (response?.data?.data) {
    dispatch(setSingleSettlement(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const updateSettlement = (data: SettlementProps, id: number) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(patchServerData(`/settlement/${id}`, data));

  if (response?.data?.data?.commodity) {
    dispatch(setSingleSettlement(response?.data?.data?.settlementCode));
  }
  return {
    success: response.success,
    data: response?.data?.data?.settlementCode,
  };
};
