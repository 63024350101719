import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import CustomForm from "src/components/customElements/customForm";
import { useStyles } from "src/styles/formStyles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Collapsible from "src/components/collapsibleForm";
import TrailerInformation from "./trailerInfo";
import SafetyInfo from "./safety";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { ArrowBack } from "@mui/icons-material";
import TrailerOwnerInfo from "src/containers/trailer/ownerLinking";
import AssignedVehicleInfo from "src/containers/trailer/vehicleLinking";
import TrailerDocuments from "src/containers/documents";
import { TrailerProps } from "src/interfaces/trailer";
import { selectTrailer } from "src/redux/reducers/trailer";
import { useAppSelector } from "src/hooks/reduxHooks";
import { typedKeys } from "src/utils/common";
import { TrailerSafetyProps } from "src/interfaces/trailer";

interface TrailerFormProps {
  loading: boolean;
  pageLoading: boolean;

  onSubmit: (data: any) => any;
  //  onSafetySubmit: (data: any) => any;
}
const TrailerForm = ({
  loading,
  pageLoading,
  onSubmit,
}: // onSafetySubmit,
TrailerFormProps): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [translation] = useTranslation("translation");
  const linkingAddress = location.pathname.includes("details");
  const [disabled, setDisabled] = useState(false);
  const trailer = useAppSelector(selectTrailer);

  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "trailerInfoPanel",
    "safetyInfoPanel",
    "trailerOwner",
    "vehicleInfo",
    "trailerDocuments",
  ]);

  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = [
      "trailerInfoPanel",
      "safetyInfoPanel",
      "trailerOwner",
      "vehicleInfo",
      "trailerDocuments",
    ];
    setExpandedPanels([..._expandedPanels]);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  useEffect(() => {
    if (linkingAddress) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [linkingAddress]);

  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);

  const setTrailerSafetyFormValues = (
    object: TrailerSafetyProps,
    name: keyof TrailerProps
  ) => {
    const allAttributes = typedKeys(object);
    for (const attribute of allAttributes) {
      if (typeof object[attribute] === "boolean") {
        setValue(`${name}.${attribute}`, object[attribute]);
      } else {
        setValue(
          `${name}.${attribute}`,
          object[attribute] ? object[attribute] : ""
        );
      }
    }
  };

  const setFormValues = (key: keyof TrailerProps) => {
    if (trailer) {
      if (typeof trailer[key] === "boolean") {
        setValue(key, trailer[key]);
      } else {
        setValue(key, trailer[key] ? trailer[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.trailerId && trailer) {
      const allAttributes = typedKeys(trailer);
      for (const attribute of allAttributes) {
        if (attribute === "make") {
          if (typeof trailer?.make === "object") {
            setValue("make", trailer?.make?.id ? trailer?.make?.id : "");
          } else {
            setValue("make", trailer?.make ? trailer?.make : "");
          }
        } else if (attribute === "type") {
          if (typeof trailer?.type === "object") {
            setValue("type", trailer?.type?.id ? trailer?.type?.id : "");
          } else {
            setValue("type", trailer?.type ? trailer?.type : "");
          }
        } else if (attribute === "trailerSafety") {
          setTrailerSafetyFormValues(trailer?.trailerSafety, attribute);
        } else {
          setFormValues(attribute);
        }
      }
    }
  }, [params?.trailerId, trailer]);

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("trailerInfoTable.list.title")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : params?.trailerId ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() => navigate(-1)}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>

      {pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
            <CustomFormGrid container>
              {/*****************Column 1 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={12} md={12}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("vehicle.form.trailerInformation.label")}
                      </Typography>
                      <TrailerInformation
                        control={control}
                        errors={errors}
                        disabled={disabled}
                        trailer={trailer}
                        trailerId={params?.trailerId}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("vehicle.form.safetyInformation.label")}
                      </Typography>
                      <SafetyInfo
                        control={control}
                        errors={errors}
                        disabled={disabled}
                        trailer={trailer}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomForm>

          {/*   <CustomFormGrid
                  sx={{ padding: "0px", margin: "0px" }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {linkingAddress && (
                    <Collapsible
                      title={translation("trailer.documents.title")}
                      handleChange={handleChange}
                      expanded={expandedPanels.includes("trailerDocuments")}
                      id="trailerDocuments"
                      ComponentToRender={<TrailerDocuments />}
                    />
                  )}
                </CustomFormGrid> */}
          {/*   <CustomFormGrid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ padding: "0px", margin: "0px" }}
                >
                  {linkingAddress && (
                    <>
                      <Collapsible
                        title={translation("driverVehicle.list.title")}
                        handleChange={handleChange}
                        expanded={expandedPanels.includes("vehicleInfo")}
                        id="vehicleInfo"
                        ComponentToRender={<AssignedVehicleInfo />}
                      />
                      <Collapsible
                        title={translation("trailerOwner.list.title")}
                        handleChange={handleChange}
                        expanded={expandedPanels.includes("trailerOwner")}
                        id="trailerOwner"
                        ComponentToRender={<TrailerOwnerInfo />}
                      />
                    </>
                  )}
                </CustomFormGrid> */}
        </LocalizationProvider>
      )}
    </div>
  );
};

export default TrailerForm;
