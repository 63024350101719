import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllAccountsOnType } from "src/redux/actions/settlement";
import { selectTerminal } from "src/redux/reducers/settlement";
import { useTranslation } from "react-i18next";
import { featureTerminals, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import PieChartIcon from '@mui/icons-material/PieChart';


interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function Terminals() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const terminals = useAppSelector(selectTerminal);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const user = useAppSelector(selectUser);
  const classes = useStyles();
  const terminalPermissions =
    allUserPermissions && allUserPermissions[featureTerminals];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === update);
  const [terminalData, setTerminalData] = useState<any>([]);
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const getAccountList = async () => {
    setLoading(true);
    await dispatch(
      getAllAccountsOnType(
        "terminal"
      )
    );
    setLoading(false);
  };

 /*  const getAccountListWithFilter = async () => {
    await dispatch(
      getAllAccountsOnType(
        "driver"
      )
    );
  }; */
/* useEffect(() => {
  getAccountListWithFilter();
}, [filter]); */
useEffect(() => {
  getAccountList();
}, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    //  if (pageExists(rowsState?.page + 1) == false)
    if (terminals?.data?.length > 0) {
      setTerminalData([ ...terminalData, terminals ]);
    } else {
      setTerminalData({});
    }
  }, [terminals]);

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerAlign: "center",
      align: "center",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
        icon={<PieChartIcon />}
        label="Details"
        key="details"
        onClick={() => {
        //  navigateToDriverDetail(params.row.id);
       navigate(`/settlements/settlement-terminal/${params?.row?.id}`);
        }}
      />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.id
              ? params?.row?.id
              : ""}
          </div>
        );
      },
    },
    {
      field: "accountName",
      headerName: translation("settlement.addEarningForm.accountName.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "preferredDay",
      headerName: translation("settlement.preferredDay.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.preferredDay
              ? params?.row?.preferredDay
              : "None"}
          </div>
        );
      },
    },
    {
      field: "currentBalance",
      headerName: translation("settlement.currentBalance.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

    return loading ? (
        <div className={classes.loadingStyle}>
            <CircularProgress />
        </div>
    ) : (
        <DataTable
            title={translation("settlement.terminal.account.title")}
            rows={terminals}
            columns={columns}
            loading={loading}
           // onClickAdd={addTerminal}
            createPermission={createPermission > -1}
            rowsState={rowsState}
            setRowsState={setRowsState}
          //  pagination={terminals.pagination}
            data={terminalData}
            setData={setTerminalData}
          add={true}
          localPagination={true}
            //setFilter={setFilter}
        />
    );
}
