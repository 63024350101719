import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toNumber } from "lodash";
import DataTable from "src/components/dataTable";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { LookupProps } from "src/interfaces/lookup";
import { Clipboard } from "ts-react-feather-icons";
import { selectLookups } from "src/redux/reducers/lookup";

import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { featureCustomers, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { dateTimeFormat } from "src/utils/constants";
import { statusTypes } from "src/utils/dropdowns/common";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { InputLabel } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAllOwners } from "src/redux/actions/owner";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { getAllVehicles } from "src/redux/actions/vehicle";
import { getAllCustomers } from "src/redux/actions/customer";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import { selectLoadManagements } from "src/redux/reducers/loadManagement";
import { getAllDrivers } from "src/redux/actions/driver";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import { LoadStopProps } from "src/interfaces/loadManagement";
import CustomTextField from "src/components/customElements/customTextField";
import { getSearchTablesData } from "src/redux/actions/search";
interface LoadSummaryProps {
  pageLoading: boolean;
  delayForClear: any;
}
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const LoadSearchForm = ({
  pageLoading,
  delayForClear,
}: LoadSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<any>({});
  const user = useAppSelector(selectUser);
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);

  const loadData = useAppSelector(selectLoadManagement);
  const pickupDateTimeRef = React.useRef<any>();
  const pickupDateTimeEndRef = React.useRef<any>();
  const [deliveryStart, setDeliveryStart] = React.useState<any | null>(null);
  const [deliveryEnd, setDeliveryEnd] = React.useState<any | null>(null);
  const selectPickupDateTimeRef = () => {
    if (pickupDateTimeRef?.current) {
      pickupDateTimeRef?.current?.click();
    }
  };
  const selectPickupDateEndTimeRef = () => {
    if (pickupDateTimeEndRef?.current) {
      pickupDateTimeEndRef?.current?.click();
    }
  };
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [addedByData, setAddedByData] = React.useState<any | null>([]);
  const [terminalData, setTerminalData] = React.useState<any | null>([]);
  const [pickupStart, setPickupStart] = React.useState<any | null>(null);
  const [pickupEnd, setPickupEnd] = React.useState<any | null>(null);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  // const [, forceUpdate] = React.useState();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  let timer: any;
  const allLoadManagement = useAppSelector(selectLoadManagements);
  const [allLoadManagementData, setAllLoadManagementData] = useState<any>({});
  const [customerSearchedData, setCustomerSearchedData] = useState<any>({});
  const [displayTable, setdisplayTable] = useState<boolean>(false);
  const boxTheme = useTheme();
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const customerPermissions =
    allUserPermissions && allUserPermissions[featureCustomers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : customerPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : customerPermissions?.findIndex((item) => item === update);

  const allCustomerTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["customer-type"]?.lookups
      ? allLookups?.data["customer-type"]?.lookups
      : [];
  // allLookups && allLookups?.data && allLookups?.data["vehicle-type"]?.lookups
  //   ? allLookups?.data["vehicle-type"]?.lookups
  //   : [];

  // const allTractorMake: Array<LookupProps> =
  //   allLookups && allLookups?.data && allLookups?.data["vehicle-make"]?.lookups
  //     ? allLookups?.data["vehicle-make"]?.lookups
  //     : [];
  // const allTractorColors: Array<LookupProps> =
  //   allLookups && allLookups?.data && allLookups?.data["vehicle-color"]?.lookups
  //     ? allLookups?.data["vehicle-color"]?.lookups
  //     : [];
  // const allTractorTypes: Array<LookupProps> =
  //   allLookups && allLookups?.data && allLookups?.data["vehicle-type"]?.lookups
  //     ? allLookups?.data["vehicle-type"]?.lookups
  //     : [];

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      minWidth: 150,
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={<Edit />}
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/customer/customer/${params.row.id}`);
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/customer/customer/documents/${params.row.id}?entity=${featureCustomers}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<Clipboard />}
        //   label="Details"
        //   key="details"
        //   onClick={() => {
        //     navigate(`/customer/customer/details/${params.row.id}`);
        //     // navigate(`/drivers/drivers/details/${params.row.id}`);
        //   }}
        // />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
    },
    {
      field: "code",
      headerName: translation("customer.list.code.label"),
      minWidth: 150,
    },
    {
      field: "companyName",
      headerName: translation("customer.list.companyName.label"),
      minWidth: 200,
    },
    {
      field: "email",
      headerName: translation("common.email.label"),
      minWidth: 200,
    },
    {
      field: "website",
      headerName: translation("customer.list.website.label"),
      minWidth: 200,
    },
    {
      field: "phoneNumber",
      headerName: translation("common.phone.label"),
      minWidth: 150,
    },
    {
      field: "tollFreePhoneNumber",
      headerName: translation("customer.list.tollFreePhoneNumber.label"),
      minWidth: 150,
    },
    {
      field: "faxPhoneNumber",
      headerName: translation("common.faxPhoneNumber.label"),
      minWidth: 150,
    },

    {
      field: "businessHour",
      headerName: translation("customer.list.businessHour.label"),
      minWidth: 150,
    },

    {
      field: "terminal",
      headerName: translation("customer.list.terminal.label"),
      minWidth: 150,

      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.terminal?.title}</div>;
      },
    },
    {
      field: "dispatchNote",
      headerName: translation("customer.list.dispatchNote.label"),
      minWidth: 250,
    },
  ];

  useEffect(() => {
    if (pickupStart != null && pickupEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["pickupDateTime"] = {
        value: pickupStart.toISOString() + "," + pickupEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [pickupStart, pickupEnd]);
  useEffect(() => {
    if (deliveryStart != null && deliveryEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["deliveryDateTime"] = {
        value: deliveryStart.toISOString() + "," + deliveryEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [deliveryStart, deliveryEnd]);

  /*  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (loadSearchedData.length > 0) {
      setAllLoadManagementData({
        ...allLoadManagementData?.allLoadManagement,
        allLoadManagement,
      });
    } else {
      setAllLoadManagementData({});
    }
  }, [allLoadManagement]); */
  useEffect(() => {
    {
      Object.keys(filter).length !== 0 && getCustomerListWithFilter();
    }
  }, [rowsState.pageSize, rowsState.page]);

  const getCustomerListWithFilter = async () => {
    setLoading(true);

    await dispatch(
      getAllCustomers(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
        filter
      )
    ).then((response:any) => {
      setCustomerSearchedData(response?.data);
      setdisplayTable(true);
    });
    setLoading(false);
  };

  const handleChangeAutoComplete = async (
    event: any,
    newValue: any,
    setData: any,
    table: string,
    column: string
  ) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getSearchTablesData(table, column, event)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setData(res?.data);
        }
      });
    }
  };
  const onRowClick = (params: GridRowParams) => {
    navigateToCustomerDetail(params.row.id);
  };
  const navigateToCustomerDetail = (id: number) => {
    navigate(
      `/customer/customer/details/${id}?entity=${featureCustomers}&referenceId=${id}`
    );
  };

  const submitSearch = async () => {
    getCustomerListWithFilter();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("common.search.message")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              className={classes.formButtonDiscard}
              onClick={() => {
                delayForClear(true);
              }}
            >
              {translation("search.form.clear")}
            </Button>

            <Button
              variant="contained"
              // type="submit"
              onClick={submitSearch}
              className={classes.formButtonSave}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                translation("search.form.search")
              )}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
        {/* //1st row */}

        <CustomFormGrid container style={{ paddingTop: "30px" }}>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="id"
              size="small"
              fullWidth
              value={filter["id"]?.value}
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["id"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["id"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.id.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="customerType"
              label={translation("customer.form.type.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (event.target.value != null) {
                  temp["customerType"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["customerType"];
                }
                setFilter(temp);
              }}
              value={filter["customerType"]?.value}
            >
              {allCustomerTypes &&
                allCustomerTypes.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="code"
              size="small"
              fullWidth
              value={filter["code"]?.value}
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["code"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["code"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("customer.form.code.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="companyName"
              size="small"
              fullWidth
              value={filter["companyName"]?.value}
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["companyName"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["companyName"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("customer.form.companyName.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={terminalData ? terminalData : "No Data"}
              // value={terminalData?.id}
              getOptionLabel={(option) =>
                option?.title ? option?.title : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["terminal"];
                } else if (newValue?.id) {
                  temp["terminal"] = { value: newValue?.id, filter: "equals" };
                }

                setFilter(temp);
                // field.onChange(newValue);
                // handleSettlementCodeChange(event, newValue)}
              }}
              renderInput={(params: any) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setTerminalData,
                          "terminal",
                          "title"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={translation("owner.homeTerminal.label")}
                  error={false}
                  /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="city"
              size="small"
              fullWidth
              value={filter["city"]?.value}
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["city"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["city"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.city.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="state"
              size="small"
              fullWidth
              value={filter["state"]?.value}
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["state"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["state"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.state.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="zip"
              size="small"
              fullWidth
              value={filter["zip"]?.value}
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["zip"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["zip"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.zip.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="email"
              size="small"
              fullWidth
              value={filter["email"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["email"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["email"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.email.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="website"
              size="small"
              fullWidth
              value={filter["website"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["website"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["website"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("customer.form.website.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          {/*  <CustomFormGrid item sm={12} xs={12} md={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={addedByData ? addedByData : "No Data"}
              value={addedByData?.id}
              getOptionLabel={(option) =>
                option?.firstName && option?.lastName
                  ? option?.firstName + " " + option?.lastName
                  : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                console.log("newValue", newValue);
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["addedBy"];
                } else if (newValue?.id) {
                  temp["addedBy"] = { value: newValue?.id, filter: "equals" };
                }
                setFilter(temp);

              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setAddedByData,
                          "user",
                          "firstName"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={translation("Added By")}
                  error={false}

                />
              )}
            />
          </CustomFormGrid> */}

          {/* <InputLabel>Insurance Exp. Date</InputLabel>{" "}
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="insuranceExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupStart(newValue);
                  }}
                  value={pickupStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="insuranceExpiration"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="insuranceExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupEnd(newValue);
                  }}
                  value={pickupEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="insuranceExpiration"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <InputLabel>Tag Exp. Date</InputLabel>{" "}
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="tagExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupStart(newValue);
                  }}
                  value={pickupStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="tagExpiration"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="tagExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupEnd(newValue);
                  }}
                  value={pickupEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="tagExpiration"
                    />
                  )}
                />
              )}
            />
                  </CustomFormGrid>*/}
        </CustomFormGrid>
        {customerSearchedData?.data?.length > 0 && loading ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "30px" }}
          >
            <CircularProgress />
          </CustomFormGrid>
        ) : customerSearchedData?.data?.length > 0 ? (
          <>
            <CustomFormGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "30px" }}
            >
              <Typography className={classes.loadFormHeadings}>
                {translation("common.searchedCustomer.message")}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                title={translation("customer.list.title")}
                rows={customerSearchedData?.data}
                columns={columns}
                loading={loading}
                createPermission={createPermission > -1}
                rowsState={rowsState}
                // onRowClick={onRowClick}
                setRowsState={setRowsState}
                pagination={customerSearchedData.pagination}
                data={customerSearchedData?.data}
                setData={setCustomerSearchedData}
                add={true}
                hideBackBar={true}
              />
            </CustomFormGrid>
          </>
        ) : displayTable === true ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "50px" }}
          >
            <Typography variant="h5">No search result found</Typography>
          </CustomFormGrid>
        ) : (
          ""
        )}
      </CustomFormGrid>
    </LocalizationProvider>
  );
};

export default LoadSearchForm;
