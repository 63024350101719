import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import LookupForm from "src/components/lookup/form";
import { LookupProps } from "src/interfaces/lookup";
import {
  getLookupById,
  addLookup,
  updateLookup,
} from "src/redux/actions/lookup";

import { useAppDispatch } from "src/hooks/reduxHooks";
import { useQuery } from "src/hooks/useQuery";
import { getAllLookupFeatures } from "src/redux/actions/lookup";

const LookupFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();
  const path = location.pathname.split("/");
  const category = query.get("category") ? query.get("category") : "";
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      getSingleLookup(parseInt(params.id));
    }
  }, [params?.id]);

  const getSingleLookup = async (id: number) => {
    setPageLoading(true);
    await dispatch(getLookupById(id));
    setPageLoading(false);
  };

  const getLookupFeaturesList = async () => {
    setLoading(true);
    await dispatch(getAllLookupFeatures(category ? category : ""));
    setLoading(false);
  };
  useEffect(() => {
    getLookupFeaturesList();
  }, []);
/*LookupProps  */
  const onSubmit = async (data: any) => {
    setLoading(true);

    const objToSubmit: LookupProps = { ...data };

    if (params?.id) {
      //update

      await dispatch(updateLookup(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const lookup = await dispatch(addLookup(objToSubmit));
      if (lookup.success && lookup.data.id && path) {
        setTimeout(
          () => navigate(`/${path[1]}/lookups?category=${category}`),
          500
        );
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <LookupForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default LookupFormContainer;
