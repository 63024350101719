import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import { Box, Typography, Button } from "@mui/material";
import PendingSettlements from "src/components/billing/pendingSettlements";
/* import SettlementStatistics from "src/components/billing/settlementStatistics";
 */ import PendingStats from "src/components/billing/pendingBillingStats";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import Tools from "src/components/billing/tools";

interface BillingDashboardProps {
  loading: boolean;
}

const BillingDashboard = ({ loading }: BillingDashboardProps): JSX.Element => {
  const navigate = useNavigate();
  const [translation] = useTranslation("translation");

  const classes = useStyles();

  return (
    <Grid container component="main">
      <Head title="Billing" description="Porto Billing" />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <Box flexGrow={1} display="flex" alignItems="center">
          <Button>
            <ArrowBack onClick={() => navigate(-1)} />
          </Button>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "0",
              textTransform: "capitalize",
            }}
            gutterBottom
          >
            {translation("billing.dashboard")}
          </Typography>
        </Box>
      </Box>
      <CustomFormGrid container>
        {loading ? (
          <div className={classes.billingLoadingStyle}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
              <PendingSettlements loading={loading} />

              {/*  <SettlementStatistics /> */}
              <PendingStats />
            </CustomFormGrid>
            <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
              <Tools />
            </CustomFormGrid>
          </>
        )}
      </CustomFormGrid>
    </Grid>
  );
};

export default BillingDashboard;
