import { AppDispatch } from "../store";
import { CompanyProps } from "src/interfaces/company";
import { updateCompanySuccess, setAllCompanies } from "../reducers/auth";
import { patchServerData, getServerData } from "../api/index";

export const updateCompany =
  (data: CompanyProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/tenant/${id}`, data) as any);
    if (response?.data?.data?.company) {
      dispatch(updateCompanySuccess(response?.data?.data.company?.tenant));
    }
    return { success: response.success, data: response?.data?.data?.company };
  };

export const uploadCompanyLogo =
  (data: FormData, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/tenant/logo/${id}`, data) as any
    );

    if (response?.data?.data?.tenant) {
      dispatch(updateCompanySuccess(response.data.data.tenant));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getAllCompanies = () => async (dispatch: AppDispatch) => {
  const response = await dispatch(getServerData(`/tenant`) as any);

  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data)) {
      dispatch(setAllCompanies(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};
export const getCompany = (data: any) => async (dispatch: AppDispatch) => {
  const response = await dispatch(
    getServerData(`auth/validate-url?url=${data}`) as any
  );

  return { success: response.success, data: response?.data?.data };
};
