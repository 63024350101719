import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Grid, Card, CardContent, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { emailRegEx } from "src/utils/constants";
import CustomTextField from "src/components/customElements/customTextField";
import logo from "src/assets/img/tms_logo.png";

interface ForgotPassCompProps {
  loading: boolean;
  /* onSubmit: (data: ForgotPasswordProps) => any; */
  onSubmit: (data: any) => any;
}

const ForgotPassword = ({
  loading,
  onSubmit,
}: ForgotPassCompProps): JSX.Element => {
  const [translation] = useTranslation("translation");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();


  return (
    <div className="auth-container">
      <div className="auth-align-middle auth-main">
        <div className="auth-box">
          <div className="top">
            <img src={logo} alt="" />
          </div>
          <Card sx={{ borderRadius: "5px", m: 0 }}>
            <CardContent>
              <Typography sx={{ fontSize: 16 }} color="text.secondary" mb={3} gutterBottom>
                Recover my password
              </Typography>
              <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                Please enter your email address below to receive instructions for resetting password.
              </Typography>
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true, pattern: emailRegEx }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label={translation("forgotPassword.email.label")}
                      error={Boolean(errors?.email)}
                      helperText={
                        errors?.email && translation("forgotPassword.email.message")
                      }
                      required
                    />
                  )}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  id="forgotPassword"
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : (
                    translation("forgotPassword.submit")
                  )}
                </Button>
              </form>
              <Grid
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: "center", mt: 2 }}
              >
                <Grid item>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Know your password?&nbsp;
                    <Link to="/auth/login">Login</Link>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
