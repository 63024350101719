import React from "react";
import { MenuItem } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { statusTypes } from "src/utils/dropdowns/common";

interface PersonalInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled: boolean;
}
const PersonalInfo = ({
  control,
  errors,
  disabled,
}: PersonalInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="firstName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="firstName"
                label={translation("common.firstName.label")}
                error={Boolean(errors?.firstName)}
                helperText={
                  errors?.firstName && translation("common.firstName.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                disabled={disabled}
                fullWidth
                id="lastName"
                label={translation("common.lastName.label")}
                error={Boolean(errors?.lastName)}
                helperText={
                  errors?.lastName && translation("common.lastName.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="status"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
                disabled={disabled}
                id="status"
                label={translation("common.status.label")}
                error={Boolean(errors?.status)}
                select
                helperText={
                  errors?.status && translation("common.status.message")
                }
                required
              >
                {statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6} xs={12} md={6} lg={6}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Controller
            name="tenantName"
            control={control}
            defaultValue={""}
           // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                variant="outlined"
                size="small"
                disabled={disabled}
                fullWidth
                id="tenantName"
                label={translation("owner.company.label")}
                error={Boolean(errors?.tenantName)}
                helperText={
                  errors?.tenantName && translation("owner.company.message")
                }
             //   required
              />
            )}
          ></Controller>
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default PersonalInfo;
