import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { selectDriver } from "src/redux/reducers/driver";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../common/readForms/formHeader";
import FromInput from "../common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import TrailerOwnerInfo from "src/containers/trailer/ownerLinking";
import AssignedVehicleInfo from "src/containers/trailer/vehicleLinking";
import TrailerDocuments from "src/containers/documents";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import { selectTrailer } from "src/redux/reducers/trailer";
// import Documents from "./docements";
import { driver as driverEnum } from "src/utils/enums";
import { LoadStopProps } from "src/interfaces/loadManagement";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";

interface TrailerSummaryProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}

const TrailerSummary = ({
  loading,
  pageLoading,
  onSubmit,
}: TrailerSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const [loadStops, setLoadStops] = useState<Array<LoadStopProps>>([
    // { ...defaultLoadStopObject },
  ]);

  const user = useAppSelector(selectUser);
  // const driver = useAppSelector(selectLoadManagement);
  const trailer = useAppSelector(selectTrailer);
  const driver = useAppSelector(selectDriver);

  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };
  const handleCloseStopOffFormModal = () => {
    setOpenDispatchFormModal && setOpenDispatchFormModal(null);
  };
  const handleSetMilageValue = async (value: any) => {
    // setValue("freightBill.mileage", value);
  };

  // const loadStopCancelled = trailer?.loadStops?.every(
  //   (item) => item.dispatchStatus === "cancelled"
  // );
  // const loadStopPlanned = trailer?.loadStops?.every(
  //   (item) => item.dispatchStatus === "planned"
  // );

  // useEffect(() => {
  //   if (
  //     trailer != null &&
  //     Object.keys(trailer).length > 0 &&
  //     trailer?.loadStops.length > 0
  //   ) {
  //     setLoadStops([...driver?.loadStops]);
  //   }
  // }, []);

  return (
    <Box>
      {pageLoading ? (
        <CustomFormGrid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CustomFormGrid>
            <CircularProgress sx={{ height: "20", width: "20px" }} />
          </CustomFormGrid>
        </CustomFormGrid>
      ) : (
        <>
          <MobileCheck
            grid1={
              <CustomFormGrid container>
                <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                  <Box className={classes.loadFormTitleContainer}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      <Button>
                        <ArrowBack onClick={() => navigate(-1)} />
                      </Button>
                      <Typography
                        variant="h6"
                        className={classes.loadFormMainHeadings}
                      >
                        {translation("Trailer.read.form.title")}
                      </Typography>
                    </Box>
                  </Box>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                  <CustomFormGrid
                    container
                    className={classes.buttonContainer}
                    justifyContent="flex-end"
                  >
                    {/*  <Button
                  variant="outlined"
                  className={classes.formButtonDiscard}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.discard")}
                </Button> */}
                    {/*
                {params?.loadId &&
                  (loadStopCancelled === true || loadStopPlanned === true) && (
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonCancel}
                      onClick={() => handleOpenConfirmModel()}
                    >
                      CANCEL LOAD
                    </Button>
                  )} */}

                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonEdit}
                      onClick={() =>
                        navigate(`/trailer/trailers/${params?.trailerId}`)
                      }
                    >
                      EDIT INFORMATION
                    </Button>

                    {/* <Button
                  color="error"
                  variant="contained"
                  className={classes.formButtonDispatch}
                  onClick={() => setOpenDispatchFormModal(0)}
                >
                  DISPATCH
                </Button> */}
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
            grid2={
              <CustomFormGrid container>
                <CustomFormGrid items lg={6} md={6} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation(
                        "vehicle.form.trailerInformation.label"
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.trailerId.label"
                            )}
                            input={trailer?.id}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.status.label")}
                            input={trailer?.status}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("vehicle.form.year.label")}
                            input={
                              trailer?.year != null
                                ? moment(trailer?.year).format(dateFormat)
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("vehicle.form.make.label")}
                            input={trailer?.make?.content}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("vehicle.form.type.label")}
                            input={trailer?.type?.content}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("vehicle.form.VIN.label")}
                            input={trailer?.VIN}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("commodity.form.length.label")}
                            input={trailer?.length}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("commodity.form.width.label")}
                            input={trailer?.width}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.deckHeight.label"
                            )}
                            input={trailer?.deckHeight}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("commodity.form.weight.label")}
                            input={trailer?.weight}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.tagNumber.label"
                            )}
                            input={trailer?.tagNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("vehicle.form.tagState.label")}
                            input={trailer?.tagState}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.tagExpiration.label"
                            )}
                            input={
                              trailer?.tagExpiration != null
                                ? moment(trailer?.tagExpiration).format(
                                    dateFormat
                                  )
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid items lg={6} md={6} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("trailer.trailerSafety.title")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.monthlyMaintenance.label"
                            )}
                            input={
                              trailer?.trailerSafety?.monthlyMaintenance != null
                                ? moment(
                                    trailer?.trailerSafety?.monthlyMaintenance
                                  ).format(dateFormat)
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.annualInspectionLocation.label"
                            )}
                            input={
                              trailer?.trailerSafety?.annualInspectionLocation
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.annualInspectionDate.label"
                            )}
                            input={
                              trailer?.trailerSafety?.annualInspectionDate !=
                              null
                                ? moment(
                                    trailer?.trailerSafety?.annualInspectionDate
                                  ).format(dateFormat)
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>

                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("vehicle.form.dotDate.label")}
                            input={
                              trailer?.trailerSafety?.dot_Date != null
                                ? moment(
                                    trailer?.trailerSafety?.dot_Date
                                  ).format(dateFormat)
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.lastServiceDate.label"
                            )}
                            input={
                              trailer?.trailerSafety?.lastServiceDate != null
                                ? moment(
                                    trailer?.trailerSafety?.lastServiceDate
                                  ).format(dateFormat)
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "vehicle.form.nextServiceDate.label"
                            )}
                            input={
                              trailer?.trailerSafety?.nextServiceDate != null
                                ? moment(
                                    trailer?.trailerSafety?.nextServiceDate
                                  ).format(dateFormat)
                                : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.comment.label")}
                            input={trailer?.trailerSafety?.comment}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <TrailerDocuments role="trailer" roleId={params?.trailerId} />
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                  <AssignedVehicleInfo />
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                  <TrailerOwnerInfo />
                </CustomFormGrid>
              </CustomFormGrid>
            }
          />
        </>
      )}
    </Box>
  );
};

export default TrailerSummary;
