import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLookup, selectLookupsFeatures } from "src/redux/reducers/lookup";
import CustomTextField from "src/components/customElements/customTextField";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

interface LookupFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: LookupProps) => any; */
  onSubmit: (data: any) => any;
}

const LookupForm = ({
  loading,
  pageLoading,
  onSubmit,
}: LookupFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const params = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const lookup = useAppSelector(selectLookup);
  const lookupsFeatures = useAppSelector(selectLookupsFeatures);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    if (params?.id && lookup) {
      setValue("content", lookup.content);
      if (typeof lookup.feature === "object") {
        setValue("feature", lookup.feature.id);
      } else {
        setValue("feature", lookup.feature);
      }
    }
  }, [params?.id, lookup]);

  return (
    <div>
      <Card variant="outlined" className={classes.formContainer}>
        <Box className={classes.formTitleContainer}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography className={classes.formTitle}>
              {params?.id
                ? translation("lookup.form.title")
                : translation("lookup.form.title")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent className={classes.lookupFormContentContainer}>
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <CustomForm
              noValidate
              alignItems="center"
              justifyContent="center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container direction="column">
                <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
                  <Controller
                    name="feature"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <CustomTextField
                          {...field}
                          id="feature"
                          fullWidth
                          size="small"
                          select
                          label={translation("lookup.feature.label")}
                          helperText={
                            errors?.feature &&
                            translation("lookup.feature.message")
                          }
                          required
                          error={Boolean(errors?.feature)}
                        >
                          {lookupsFeatures.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </>
                    )}
                  />
                </CustomFormGrid>
                <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
                  <Controller
                    name="content"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <CustomTextField
                          {...field}
                          id="content"
                          label={translation("lookup.content.label")}
                          fullWidth
                          size="small"
                          error={Boolean(errors?.content)}
                          helperText={
                            errors?.content &&
                            translation("lookup.content.message")
                          }
                          required
                        />
                      </>
                    )}
                  />
                </CustomFormGrid>
              </Grid>
              <CustomFormGrid container justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.formButton}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : params?.id ? (
                    translation("common.form.update")
                  ) : (
                    translation("common.form.add")
                  )}
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  className={classes.formButton}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.cancel")}
                </Button>
              </CustomFormGrid>
            </CustomForm>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default LookupForm;
