import { AppDispatch } from "../store";
import { DriverVehicleProps } from "src/interfaces/driver";
import { postServerData, patchServerData } from "../api/index";
import {
  removeLinkedVehicle,
  addLinkedVehicle,
} from "src/redux/reducers/driver";
import {
  addLinkedDriver,
  removeLinkedDriver,
} from "src/redux/reducers/vehicle";

export const addDriverVehicle =
  (data: DriverVehicleProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      postServerData(`/driver-vehicle-link`, data)
    );

    if (Array.isArray(response?.data?.data)) {
      dispatch(addLinkedVehicle(response?.data?.data[0]));
      dispatch(addLinkedDriver(response?.data?.data[0]));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const deleteDriverVehicleLink =
  (data: DriverVehicleProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/driver-vehicle-link`, data)
    );
    if (response?.data?.data) {

      if (typeof data.driver === "number") {
        dispatch(removeLinkedDriver(data?.driver));
      }

      dispatch(removeLinkedVehicle());
    }
    return { success: response.success, data: response?.data?.data };
  };
