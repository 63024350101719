import React, { useEffect, useState } from "react";
import { Grid, Box, Stack } from "@mui/material";
import { getMainDashboardData } from "src/redux/actions/settlement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import AnalyticalCounts from "src/components/dashboard/analyticalCounts";
import Toolbar from "src/components/dashboard/toolbar";


const DashboardContainer = () => {
  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(getMainDashboardData())
  }, []);

  return (
    <>
      {/* <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AnalyticalCounts />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Toolbar />
        </Grid>
      </Grid> */}
      <Stack spacing={1}>
        <AnalyticalCounts />
        <Toolbar />
      </Stack>
    </>
  );
};

export default DashboardContainer;
