import { simple_commission, split_commission } from "../enums";
export const commissionPrograms = [
  { value: split_commission, label: "Split Commission" },
  { value: simple_commission, label: "Simple Commission" },
];

export const terminalTypes = [
  {
    value: "agent-freight",
    label: "Agent-Freight",
  },
  {
    value: "agent-truck",
    label: "Agent-Truck",
  },
  {
    value: "company-store",
    label: "Company Store",
  },
];
