import { AppDispatch } from "../store";
import {
  setAllTrailers,
  setSingleTrailer,
  setAllAvailableTrailers,
} from "../reducers/trailer";
import { TrailerProps } from "src/interfaces/trailer";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllTrailers = (limit?: number, page?: number, filter?:any) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/trailer`, ));
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data?.data)) {
      dispatch(setAllTrailers(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data};
};
export const getTrailerById = (id: number) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/trailer/${id}`));
  if (response?.data?.data) {
    dispatch(setSingleTrailer(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
export const addTrailer =
  (data: TrailerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/trailer`, data));

    if (response?.data?.data) {
      dispatch(setSingleTrailer(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateTrailer =
  (data: TrailerProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/trailer/${id}`, data));

    if (response?.data?.data?.trailer) {
      dispatch(setSingleTrailer(response?.data?.data?.trailer));
    }

    return { success: response.success, data: response?.data };
  };
export const getAllAvailableTrailers = (limit?: number, page?: number, filter?: any) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/trailer/available`, ));
  if (response?.data?.data) {

      dispatch(setAllAvailableTrailers(response?.data?.data?.data));


  }
  return { success: response.success, data: response?.data?.data?.data };
};
