import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import SecurityGroupsContainer from "src/containers/securityGroups";
const SecurityGroups = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="SecurityGroups" description="Porto SecurityGroups" />
      <SecurityGroupsContainer />
    </Grid>
  );
};

export default SecurityGroups;
