import { AppDispatch } from "../store";
import { setUserRoles } from "../reducers/config";
import { getServerData } from "../api/index";

export const getSearchTablesData = (
  table: string,
  columnField: string,
  value: string
) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(
    getServerData(
      `/search?table=${table}&filters[${columnField}][$contains]=${value}`
    ) as any
  );
  if (response?.data?.data?.data) {
    return { success: response.success, data: response?.data?.data?.data };
  }
};

export const getSearchTablesDataWithFilters = (
  table: string,
  limit?: number,
  page?: number,
  filter?: any
) => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(
    getServerData(`/search?table=${table}`) as any
  );
  if (response?.data?.data?.data) {
    return { success: response.success, data: response?.data?.data };
  }
};
