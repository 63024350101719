import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/styles/formStyles";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectDashboard } from "src/redux/reducers/settlement";

const PendingStats = () => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const dashboardData = useAppSelector(selectDashboard);
  return (
    <CustomFormGrid item xs={12} lg={12}>
      <Card

        sx={{
          paddingBottom: "50px",
        }}
      >
        <CardContent
          sx={{
            paddingBottom: "16px !important",
          }}
        >
          <Box
            sx={{
              display: {
                sm: "flex",
                xs: "block",
              },
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0px",
                  fontWeight: 600,
                }}
              >
                Pending Billing Stats
              </Typography>
            </Box>
          </Box>

          <CustomFormGrid container style={{ marginTop: "25px" }}>
            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.load.deliver")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                {dashboardData?.LoadsDeliveredNotBilled
                  ? dashboardData?.LoadsDeliveredNotBilled
                  : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div className={classes.vl}></div>
            </CustomFormGrid>

            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.waiting")}
                <br></br>
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                {dashboardData?.waitingForDocuments
                  ? dashboardData?.waitingForDocuments
                  : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div className={classes.vl}></div>
            </CustomFormGrid>
            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.transfer")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                {dashboardData?.transferToFactoring
                  ? dashboardData?.transferToFactoring
                  : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div className={classes.vl}></div>
            </CustomFormGrid>

            <CustomFormGrid
              xs={3}
              lg={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.transferTo")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                {dashboardData?.transferToQuickbook
                  ? dashboardData?.transferToQuickbook
                  : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div
                className={classes.vl}
                style={{ marginTop: "30px", marginBottom: "30px" }}
              ></div>
            </CustomFormGrid>
            <CustomFormGrid
              xs={3}
              lg={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.pending.freight")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                {dashboardData?.pendingFrightBill
                  ? dashboardData?.pendingFrightBill.length
                  : "0"}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid xs={1} lg={1}>
              <div
                className={classes.vl}
                style={{ marginTop: "30px", marginBottom: "30px" }}
              ></div>
            </CustomFormGrid>


            <CustomFormGrid xs={3} lg={3}  style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.customer")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
              {dashboardData?.activeCustomer
                  ? dashboardData?.activeCustomer
                  : "0"}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid xs={1} lg={1}>
              <div
                className={classes.vl}
                style={{ marginTop: "30px", marginBottom: "30px" }}
              ></div>
            </CustomFormGrid>

            {/*


            <CustomFormGrid
              xs={3}
              lg={3}
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <Typography className={classes.billingDashboardText}>

                {translation("billing.dashboard.queued")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                346
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.rebill")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
                4
              </Typography>
            </CustomFormGrid>


                      <CustomFormGrid xs={3} lg={3}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.suspended")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
               0
              </Typography>
                      </CustomFormGrid>

                      <CustomFormGrid xs={3} lg={3}   style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Typography className={classes.billingDashboardText}>
                {translation("billing.dashboard.contract")}
              </Typography>

              <Typography className={classes.billingDashboardNumber}>
               0
              </Typography>
                      </CustomFormGrid> */}
          </CustomFormGrid>

          {/* table container */}
        </CardContent>
      </Card>
    </CustomFormGrid>
  );
};

export default PendingStats;
