import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllAccountsOnType } from "src/redux/actions/settlement";
import { selectOwner } from "src/redux/reducers/settlement";
import { useTranslation } from "react-i18next";
import { featureOwners, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { useStyles } from "src/styles/formStyles";
import PieChartIcon from "@mui/icons-material/PieChart";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Owners() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const [translation] = useTranslation("translation");
  const owners = useAppSelector(selectOwner);
  const [ownerData, setOwnerData] = useState<any>([]);
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const ownerPermissions =
    allUserPermissions && allUserPermissions[featureOwners];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === update);

  const getAccountList = async () => {
    setLoading(true);
    await dispatch(
      getAllAccountsOnType(
        "owner"
      )
    );
    setLoading(false);
  };

 /*  const getAccountListWithFilter = async () => {
    await dispatch(
      getAllAccountsOnType(
        "owner"
      )
    );
  }; */

  /* function pageExists(page: any) {
    return ownerData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
 /*  useEffect(() => {
    getAccountListWithFilter();
  }, [filter]); */
  useEffect(() => {
    getAccountList();
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (owners?.data?.length > 0) {
      setOwnerData([ ...ownerData, owners ]);
    } else {
      setOwnerData({});
    }
  }, [owners]);
  const addOwner = () => {
    navigate("/drivers/owners/create");
  };

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<PieChartIcon />}
          label="Details"
          key="details"
          onClick={() => {
            //  navigateToDriverDetail(params.row.id);
            navigate(`/settlements/settlement-owner/${params?.row?.id}`);
          }}
        />,
        /*  <GridActionsCellItem
          icon={<UploadFileIcon />}
          label="documents"
          key="documents"
          onClick={() => {
            navigate(
              `/drivers/owners/documents/${params.row.id}?entity=${featureOwners}&referenceId=${params.row.id}`
            );
          }}
        />, */
        /*   <GridActionsCellItem
          icon={<Clipboard />}
          label="details"
          key="details"
          onClick={() => {
            navigateToOwnerDetail(params.row.id);
          }}
        />, */
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.id
              ? params?.row?.id
              : ""}
          </div>
        );
      },
    },
    {
      field: "accountName",
      headerName: translation("settlement.addEarningForm.accountName.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "preferredDay",
      headerName: translation("settlement.preferredDay.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.preferredDay
              ? params?.row?.preferredDay
              : "None"}
          </div>
        );
      },
    },
    {
      field: "currentBalance",
      headerName: translation("settlement.currentBalance.label"),
      minWidth: 200,
      headerAlign: "center",
      align: "center",
    },
  ];


  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <DataTable
      title={translation("settlement.owner.account.title")}
      rows={owners}
      columns={columns}
      loading={loading}
      onClickAdd={addOwner}
      createPermission={createPermission > -1}
      rowsState={rowsState}
      // onRowClick={onRowClick}
      setRowsState={setRowsState}
      //pagination={owners.pagination}
      data={ownerData}
      setData={setOwnerData}
        add={true}
        localPagination={true}
      // setFilter={setFilter}
    />
  );
}
