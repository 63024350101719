import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import TerminalContainer from "src/containers/terminals";
const Terminals = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Terminals" description="Porto Terminals" />
      <TerminalContainer />
    </Grid>
  );
};

export default Terminals;
