import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CommodityFormContainer from "src/containers/commodity";

const Commodity = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Commodity" description="Porto Commodity" />
      <CommodityFormContainer />
    </Grid>
  );
};

export default Commodity;
