export const types = [
    {
      value: "deduction",
      label: "Deduction",
    },
    {
      value: "earning",
      label: "Earning",
    },
    {
        value: "reimbursement",
        label: "Reimbursement",
      },
  ];
  export const settlementCodeCategory = [

    {
      value: "workerComp",
      label: "Worker's Comp",
    },
    {
        value: "loadEarnings",
        label: "Load Earnings",
    },
    {
      value: "operatingDeductions",
      label: "Operating Deductions",
    },
    {
      value: "Garnishments",
      label: "Garnishments",
    },
    {
      value: "reserveDepositWithdrawal",
      label: "Reserve /Deposit Withdrawal",
    },
    {
      value: "advances",
      label: "Advances",
    },
    {
      value: "previousBalance",
      label: "PreviousBalance",
    },

  ];

