import i18next from "i18next";
import { AppDispatch } from "../store";
import API from "src/utils/axiosConfig";
import { showNotification } from "../reducers/notifications";
import { UserProps, UserPermissionProps } from "src/interfaces/user";
import {
  LookupProps,
  ReorderLookupProps,
  AddLookupProps,
} from "src/interfaces/lookup";
import { SecurityGroupProps } from "src/interfaces/securityGroups";
import { CompanyProps } from "src/interfaces/company";
import { TerminalProps } from "src/interfaces/terminal";
import { OwnerProps, OwnerTrailerProps } from "src/interfaces/owner";
import { GeneralInfoProps } from "src/interfaces/generalInfo";
import {
  DriverProps,
  VerifyDocumentProps,
  DriverVehicleProps,
  DriverOwnerProps,
} from "src/interfaces/driver";
import {
  VehicleProps,
  VehicleSafetyProps,
  VehicleTrailerProps,
} from "src/interfaces/vehicle";
import { TrailerProps, TrailerSafetyProps } from "src/interfaces/trailer";
import { CustomerProps, CustomerBillingProps } from "src/interfaces/customer";
import { DocumentTypeProps } from "src/interfaces/documentType";
import { LineItemProps } from "src/interfaces/lineItems";
import { CommodityProps } from "src/interfaces/commodity";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import { SettlementCodesProps } from "src/interfaces/settlementCodes";
import { SettlementProps } from "src/interfaces/settlement";
import { OwnerVehicleProps } from "src/interfaces/owner";
type ServerData =
  | UserProps
  | CompanyProps
  | SecurityGroupProps
  | LookupProps
  | AddLookupProps
  | TerminalProps
  | OwnerProps
  | UserPermissionProps
  | DriverProps
  | VehicleProps
  | VehicleSafetyProps
  | TrailerProps
  | TrailerSafetyProps
  | FormData
  | ReorderLookupProps
  | VerifyDocumentProps
  | CustomerProps
  | CustomerBillingProps
  | DocumentTypeProps
  | LineItemProps
  | CommodityProps
  | LoadManagementProps
  | VehicleTrailerProps
  | DriverVehicleProps
  | DriverOwnerProps
  | OwnerTrailerProps
  | SettlementProps
  | GeneralInfoProps
  | SettlementCodesProps
  | any
  | OwnerVehicleProps;

interface Pagination {
  total?: number;
  page?: number;
  limit?: number;
  filter?: any;
}

export const getServerData =
  (url: string, pagination?: Pagination) =>
    async (dispatch: AppDispatch) => {
      try {
        const token = localStorage.getItem("authToken");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },

          params: {},
        };
        if (pagination) {
          const { page, filter, limit, total } = pagination;

          if (page !== null && total !== null && limit !== null) {
            config.params = {
              page: page,
              limit: limit,
              total: total,
            };
          }

          if (filter?.items?.length > 0) {
            filter.items.map((item: any) => {
              // @ts-ignore: Unreachable code error
              config.params[
                `filters[${item.columnField}][$${item.operatorValue}]`
              ] = item.value;

            });
          } else if (filter && Object.keys(filter).length > 0) {
            Object.entries(filter).forEach(
              ([key, value]) =>
                // @ts-ignore: Unreachable code error
                (config.params[`filters[${key}][$${value.filter}]`] = value.value)
            );

          }

        }
        const response = await API.get(url, config);
        const { status } = response;

        if (response && status == 200) return { success: true, data: response };
        else return { success: false, data: null };

      } catch (error) {
        const _error: any = error;
        dispatch(
          showNotification({
            open: true,
            message: _error?.response?.data?.message
              ? _error?.response?.data?.message
              : i18next.t("messages.server.error"),
            severity: "error",
          })
        );
        return { success: false, data: _error?.response?.data };
      }
    };
export const postServerData =
  (url: string, data: ServerData) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await API.post(url, data, config);
      const responseData: any = response;
      if (responseData) {
        dispatch(
          showNotification({
            open: true,
            message: i18next.t("messages.add.success"),
            severity: "success",
          })
        );
        return { success: true, data: responseData };
      }
      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.server.error"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const patchServerData =
  (url: string, data?: ServerData) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await API.patch(url, data, config);
      const responseData: any = response?.data;
      if (responseData) {
        responseData?.message &&
          dispatch(
            showNotification({
              open: true,
              message: responseData?.message,
              severity: "success",
            })
          );
        return { success: true, data: response };
      }
      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.server.error"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const deleteServerData =
  (url: string) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await API.delete(url, config);
      const responseData: any = response?.data;
      if (responseData) {
        dispatch(
          showNotification({
            open: true,
            message: responseData?.message,
            severity: "success",
          })
        );
        return { success: true, data: response };
      }
      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.server.error"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
