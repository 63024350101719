import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { CommodityProps } from "src/interfaces/commodity";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectCommodity } from "src/redux/reducers/commodity";
import { Controller } from "react-hook-form";
import CustomTextField from "src/components/customElements/customTextField";
import { useStyles } from "src/styles/formStyles";
import { typedKeys } from "src/utils/common";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

interface CommodityFormProps {
  loading: boolean;
  pageLoading: boolean;
  onSubmit: (data: any) => any;
}
const CommodityForm = ({
  loading,
  pageLoading,
  onSubmit,
}: CommodityFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const commodity = useAppSelector(selectCommodity);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const setFormValues = (key: keyof CommodityProps) => {
    if (commodity) {
      if (typeof commodity[key] === "boolean") {
        setValue(key, commodity[key]);
      } else {
        setValue(key, commodity[key] ? commodity[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.id && commodity) {
      const allAttributes = typedKeys(commodity);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }
  }, [params?.id, commodity]);
  return (
    <div>
      <Card variant="outlined" className={classes.formContainer}>
        <Box className={classes.formTitleContainer}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography className={classes.formTitle}>
              {translation("commodity.form.title")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent className={classes.formContentContainer}>
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
                <CustomFormGrid container>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="commodity"
                      control={control}
                      defaultValue={""}
                     // rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="commodity"
                          label={translation("commodity.form.commodity.label")}
                          error={Boolean(errors?.commodity)}
                          helperText={
                            errors?.commodity &&
                            translation("commodity.form.commodity.message")
                          }
                         // required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="weight"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="weight"
                          type="number"
                          inputProps={{ min: 0 }}
                          label={translation("commodity.form.weight.label")}
                          error={Boolean(errors?.weight)}
                          helperText={
                            errors?.weight &&
                            translation("commodity.form.weight.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="length"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                          inputProps={{ min: 0 }}
                          id="length"
                          label={translation("commodity.form.length.label")}
                          error={Boolean(errors?.length)}
                          helperText={
                            errors?.length &&
                            translation("commodity.form.length.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="height"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                          inputProps={{ min: 0 }}
                          id="height"
                          label={translation("commodity.form.height.label")}
                          error={Boolean(errors?.height)}
                          helperText={
                            errors?.height &&
                            translation("commodity.form.height.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
                    <Controller
                      name="width"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          type="number"
                          inputProps={{ min: 0 }}
                          fullWidth
                          id="width"
                          label={translation("commodity.form.width.label")}
                          error={Boolean(errors?.width)}
                          helperText={
                            errors?.width &&
                            translation("commodity.form.width.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
                    <Controller
                      name="volume"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                          inputProps={{ min: 0 }}
                          id="volume"
                          label={translation("commodity.form.volume.label")}
                          error={Boolean(errors?.volume)}
                          helperText={
                            errors?.volume &&
                            translation("commodity.form.volume.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>

                  <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
                    <Controller
                      name="value"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                          inputProps={{ min: 0 }}
                          id="value"
                          label={translation("commodity.form.value.label")}
                          error={Boolean(errors?.value)}
                          helperText={
                            errors?.value &&
                            translation("commodity.form.value.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          rows={3}
                          id="description"
                          label={translation(
                            "commodity.form.description.label"
                          )}
                          error={Boolean(errors?.description)}
                          helperText={
                            errors?.description &&
                            translation("commodity.form.description.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="dispatchNotes"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          rows={3}
                          id="dispatchNotes"
                          label={translation("commodity.form.notes.label")}
                          error={Boolean(errors?.dispatchNotes)}
                          helperText={
                            errors?.dispatchNotes &&
                            translation("commodity.form.notes.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid container justifyContent="center">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.formButton}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : params?.id ? (
                        translation("common.form.update")
                      ) : (
                        translation("common.form.add")
                      )}
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButton}
                      onClick={() => navigate(-1)}
                    >
                      {translation("common.btn.cancel")}
                    </Button>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomForm>
            </LocalizationProvider>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default CommodityForm;
