import React from "react";
import { MenuItem, Grid } from "@mui/material";
import { DatePicker } from "@mui/lab";
import CustomTextField from "../customElements/customTextField";
import CustomFormGrid from "../customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { selectTerminals } from "src/redux/reducers/terminal";
import AddLookupField from "src/components/common/addLookupFeild";
import AddMedicalLookups from "src/components/driver/addMedicalLookups";
interface AdditionalInfoProps {
  control: any;
  errors: { [key: string]: any };
  driverMedicalArray: Array<number>;
  handleSelectMedical: (e: any) => void;
  trailerQualificationArray: Array<number>;
  handleSelectTrailerQualifications: (e: any) => void;
  disabled: boolean;
}
const AdditionalInfo = ({
  control,
  errors,
  driverMedicalArray,
  handleSelectMedical,
  trailerQualificationArray,
  handleSelectTrailerQualifications,
  disabled,
}: AdditionalInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);

  const allEOBRTypes: Array<LookupProps> = allLookups?.data?.["eobr-type"]
    ?.lookups
    ? allLookups?.data?.["eobr-type"]?.lookups
    : [];
  const driverMedical: Array<LookupProps> = allLookups?.data?.["driver-medical"]
    ?.lookups
    ? allLookups?.data?.["driver-medical"]?.lookups
    : [];
  const trailerQualification: Array<LookupProps> = allLookups?.data?.[
    "trailer-type"
  ]?.lookups
    ? allLookups?.data?.["trailer-type"]?.lookups
    : [];
  const allDriverGroups: Array<LookupProps> = allLookups?.data?.["driver-group"]
    ?.lookups
    ? allLookups?.data?.["driver-group"]?.lookups
    : [];

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="terminal"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="terminal"
                label={translation("driver.form.homeTerminal.label")}
                error={Boolean(errors?.terminal)}
                select
                helperText={
                  errors?.terminal &&
                  translation("driver.form.homeTerminal.message")
                }
                required
              >
                {terminals &&
                  terminals?.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.title}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>

        {/*  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="eobrType"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                disabled={disabled}
                size="small"
                fullWidth
                id="eobrType"
                label={translation("driver.form.eobrType.label")}
                error={Boolean(errors?.eobrType)}
                helperText={
                  errors?.eobrType &&
                  translation("driver.form.eobrType.message")
                }
                select
              >
                {allEOBRTypes.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid> */}
        <CustomFormGrid
          item
          sm={12} xs={12} md={6} lg={6}
          style={{ margin: "0px", padding: "0px" }}
        >
          <AddLookupField
            name={"eobrType"}
            control={control}
            rules={{ required: false }}
            id="eobrType"
            label={translation("driver.form.eobrType.label")}
            errors={Boolean(errors?.eobrType)}
            lookupsArray={allEOBRTypes}
            linkingAddress={disabled}
            helperText={
              errors?.eobrType && translation("driver.form.eobrType.message")
            }
            fieldRequired={false}
            disabled={disabled}
            featureKey="eobr-type"
            lookupFeatureEnum={"all"}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="eobrID"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                disabled={disabled}
                {...field}
                size="small"
                fullWidth
                id="eobrID"
                label={translation("driver.form.eobrID.label")}
                error={Boolean(errors?.eobrID)}
                helperText={
                  errors?.eobrID && translation("driver.form.eobrID.message")
                }
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>

        {/*  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="driverGroup"
            control={control}
            defaultValue={""}
            //  rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                disabled={disabled}
                size="small"
                fullWidth
                id="driverGroup"
                label={translation("driver.form.driverGroup.label")}
                error={Boolean(errors?.driverGroup)}
                helperText={
                  errors?.driverGroup &&
                  translation("driver.form.driverGroup.message")
                }
                //   required
                select
              >
                {allDriverGroups.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid> */}
        <CustomFormGrid
          item
          sm={12} xs={12} md={6} lg={6}
          style={{ margin: "0px", padding: "0px" }}
        >
          <AddLookupField
            name={"driverGroup"}
            control={control}
            rules={{ required: false }}
            id="driverGroup"
            label={translation("driver.form.driverGroup.label")}
            errors={Boolean(errors?.driverGroup)}
            lookupsArray={allDriverGroups}
            linkingAddress={disabled}
            helperText={
              errors?.make && translation("vehicle.form.make.message")
            }
            fieldRequired={false}
            disabled={disabled}
            featureKey="driver-group"
            lookupFeatureEnum={"all"}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="hireDate"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("driver.common.hireDate.label")}
                disableFuture
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="hireDate"
                    error={Boolean(errors?.hireDate)}
                    helperText={
                      errors?.hireDate &&
                      translation("driver.common.hireDate.message")
                    }
                    required
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="reviewDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("driver.form.reviewDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="reviewDate"
                    error={Boolean(errors?.reviewDate)}
                    helperText={
                      errors?.reviewDate &&
                      translation("driver.form.reviewDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="nextReviewDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                disablePast
                label={translation("driver.form.nextReviewDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="nextReviewDate"
                    error={Boolean(errors?.nextReviewDate)}
                    helperText={
                      errors?.nextReviewDate &&
                      translation("driver.form.nextReviewDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="mvrDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("driver.form.mvrDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="mvrDate"
                    error={Boolean(errors?.mvrDate)}
                    helperText={
                      errors?.mvrDate &&
                      translation("driver.form.mvrDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="physicalDueDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                label={translation("driver.form.physicalDueDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="physicalDueDate"
                    error={Boolean(errors?.physicalDueDate)}
                    helperText={
                      errors?.physicalDueDate &&
                      translation("driver.form.physicalDueDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="physicalSubmittedDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disableFuture
                disabled={disabled}
                label={translation("driver.form.physicalSubmittedDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="physicalSubmittedDate"
                    error={Boolean(errors?.physicalSubmittedDate)}
                    helperText={
                      errors?.physicalSubmittedDate &&
                      translation("driver.form.physicalSubmittedDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="residentExpirationDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                disablePast
                label={translation("driver.form.residentExpDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="residentExpirationDate"
                    error={Boolean(errors?.residentExpirationDate)}
                    helperText={
                      errors?.residentExpirationDate &&
                      translation("driver.form.residentExpDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Controller
            name="twicExpirationDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                disabled={disabled}
                disablePast
                label={translation("driver.form.twicExpDate.label")}
                inputFormat="MM/dd/yyyy"
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    fullWidth
                    id="twicExpirationDate"
                    error={Boolean(errors?.twicExpirationDate)}
                    helperText={
                      errors?.twicExpirationDate &&
                      translation("driver.form.twicExpDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        {/*   <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Grid container>
            <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
              <Typography className={classes.formTitle}>
                {translation("driver.form.medical.title")}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={8} lg={8}>
              <Controller
                name="medical"
                control={control}
                defaultValue={""}
                render={() => (
                  <FormGroup>
                    {driverMedical &&
                      driverMedical.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <CustomCheckbox
                              disabled={disabled}
                              value={item?.id}
                              onChange={handleSelectMedical}
                              checked={driverMedicalArray.includes(item.id)}
                            />
                          }
                          label={item.content}
                        />
                      ))}
                  </FormGroup>
                )}
              />
            </CustomFormGrid>
          </Grid>
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
          <Grid container>
            <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
              <Typography className={classes.formTitle}>
                {translation("trailer.qualification.title")}
              </Typography>
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={8} lg={8}>
              <Controller
                name="qualification"
                control={control}
                defaultValue={""}
                render={() => (
                  <FormGroup>
                    {trailerQualification &&
                      trailerQualification.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <CustomCheckbox
                              disabled={disabled}
                              value={item?.id}
                              onChange={handleSelectTrailerQualifications}
                              checked={trailerQualificationArray.includes(
                                item.id
                              )}
                            />
                          }
                          label={item.content}
                        />
                      ))}
                  </FormGroup>
                )}
              />
            </CustomFormGrid>
          </Grid>
        </CustomFormGrid> */}
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Grid container>
            <AddMedicalLookups
              heading={translation("driver.form.medical.title")}
              linkingAddress={disabled}
              name={"medical"}
              control={control}
              lookupsArray={driverMedical}
              disabled={disabled}
              handleSelectMedical={handleSelectMedical}
              featureKey={"driver-medical"}
              lookupFeatureEnum={"all"}
              selectedlookup={driverMedicalArray}
            />
          </Grid>
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Grid container>
            <AddMedicalLookups
              heading={translation("trailer.qualification.title")}
              linkingAddress={disabled}
              name={"qualification"}
              control={control}
              lookupsArray={trailerQualification}
              disabled={disabled}
              handleSelectMedical={handleSelectTrailerQualifications}
              featureKey={"trailer-type"}
              lookupFeatureEnum={"all"}
              selectedlookup={trailerQualificationArray}
            />
          </Grid>
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default AdditionalInfo;
