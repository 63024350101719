import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../../components/common/readForms/formHeader";
import FromInput from "../../components/common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import LoadSearchForm from "src/components/searchForms/loadSearchForm";
import { getAllAvailableVehicles } from "src/redux/actions/vehicle";
import { getAllAvailableDrivers } from "src/redux/actions/driver";
import { getAllAvailableTrailers } from "src/redux/actions/trailer";
import { getAllUsers } from "src/redux/actions/users";

const loadSearch = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const user = useAppSelector(selectUser);
  const loadData = useAppSelector(selectLoadManagement);

  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const delayForClear = async () => {
    setPageLoading(true);

    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  };

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };
  /*  const handleCloseStopOffFormModal = () => {
    setOpenDispatchFormModal && setOpenDispatchFormModal(null);
  }; */

  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {pageLoading ? (
          <div className={classes.loadingStyle}>
            <CircularProgress />
          </div>
        ) : (
          <LoadSearchForm
            pageLoading={pageLoading}
            delayForClear={delayForClear}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default loadSearch;
