import React from "react";
import { Navigate } from "react-router-dom";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import Drivers from "../pages/drivers";
import DriverForm from "../pages/drivers/driversForm";
import VehicleForm from "../pages/vehicle/vehicleForm";
import OwnersForm from "../pages/owners/ownerForm";
import TrailerForm from "../pages/trailer/form";
import TerminalForm from "../pages/terminals/form";
import LoadManagementForm from "../pages/loadManagement/loadManagementForm";
import LoadManagement from "../pages/loadManagement";
import Owners from "../pages/owners";
import Customers from "../pages/customers";
import CustomersForm from "../pages/customers/customersForm";
import Trailers from "../pages/trailer";
import Terminals from "../pages/terminals";
import Vehicles from "../pages/vehicle";
import Documents from "../pages/documents";
import { UserPermissions } from "src/interfaces/user";
import {
  admin,
  featureDrivers,
  featureVehicles,
  featureOwners,
  featureTrailers,
  featureLookups,
  featureLoad,
  featureTerminals,
  featureCustomers,
  create,
  update,
  find,
} from "src/utils/enums";

export const navBarRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const customerPermission =
    allUserPermissions && allUserPermissions[featureCustomers];
  const createPermissionCustomer =
    role === admin
      ? 0
      : customerPermission?.findIndex((item) => item === create);
  const driverPermission =
    allUserPermissions && allUserPermissions[featureDrivers];
  const vehiclePermissions =
    allUserPermissions && allUserPermissions[featureVehicles];
  const ownerPermission =
    allUserPermissions && allUserPermissions[featureOwners];
  const createPermissionOwner =
    role === admin ? 0 : ownerPermission?.findIndex((item) => item === create);
  const createPermissionDriver =
    role === admin ? 0 : driverPermission?.findIndex((item) => item === create);
  const createPermissionVehicle =
    role === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === create);
  const trailerPermission =
    allUserPermissions && allUserPermissions[featureTrailers];
  const createPermissionTrailer =
    role === admin
      ? 0
      : trailerPermission?.findIndex((item) => item === create);

  const terminalPermissions =
    allUserPermissions && allUserPermissions[featureTerminals];
  const createPermissionTerminal =
    role === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === create);

  const loadPermissions = allUserPermissions && allUserPermissions[featureLoad];
  const createPermissionLoad =
    role === admin ? 0 : loadPermissions?.findIndex((item) => item === create);

  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);
  return [
    {
      path: "/drivers/drivers/create",
      exact: true,
      element:
        createPermissionDriver > -1 ? <DriverForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/drivers",
      exact: true,
      element:
        role === admin || driverPermission?.length > 0 ? (
          <Drivers />
        ) : (
          <Navigate to="/404" />
        ),
    },



    {
      path: "/vehicle/vehicles/create",
      exact: true,
      element:
        createPermissionVehicle > -1 ? <VehicleForm /> : <Navigate to="/404" />,
    },
    {
      path: "/vehicle/vehicles",
      exact: true,
      element:
        role === admin || vehiclePermissions?.length > 0 ? (
          <Vehicles />
        ) : (
          <Navigate to="/404" />
        ),
    },




    {
      path: "/drivers/owners/create",
      exact: true,
      element:
        createPermissionOwner > -1 ? <OwnersForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/owners",
      exact: true,
      element:
        role === admin || ownerPermission?.length > 0 ? (
          <Owners />
        ) : (
          <Navigate to="/404" />
        ),
    },



    {
      path: "/trailer/trailers/create",
      exact: true,
      element:
        createPermissionTrailer > -1 ? <TrailerForm /> : <Navigate to="/404" />,
    },
    {
      path: "/trailer/trailers",
      exact: true,
      element:
        role === admin || trailerPermission?.length > 0 ? (
          <Trailers />
        ) : (
          <Navigate to="/404" />
        ),
    },



    {
      path: "/terminals/terminals/create",
      exact: true,
      element:
        createPermissionTerminal > -1 ? (
          <TerminalForm />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/terminals/terminals",
      exact: true,
      element:
        role === admin || terminalPermissions?.length > 0 ? (
          <Terminals />
        ) : (
          <Navigate to="/404" />
        ),
    },




    {
      path: "/load-management/load-management",
      exact: true,
      element:
        role === admin || loadPermissions?.length > 0 ? (
          <LoadManagement />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/load-management/load-management/create",
      exact: true,
      element:
        createPermissionLoad > -1 ? <LoadManagementForm /> : <Navigate to="/404" />,
    },



    {
      path: "/customer/customer",
      exact: true,
      element:
        role === admin || customerPermission?.length > 0 ? (
          <Customers />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/customer/customer/create",
      exact: true,
      element:
        createPermissionCustomer > -1 ? <CustomersForm /> : <Navigate to="/404" />,
    },
  ];
};
