import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { selectDriver } from "src/redux/reducers/driver";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { selectUser } from "src/redux/reducers/users";
import { useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import FromHeader from "../common/readForms/formHeader";
import FromInput from "../common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import moment from "moment";
import { driver as driverEnum } from "src/utils/enums";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";

interface UserSummaryProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}
const UserSummary = ({
  loading,
  pageLoading,
  onSubmit,
}: UserSummaryProps): JSX.Element => {
  const params = useParams();
  const user = useAppSelector(selectUser);
  const driver = useAppSelector(selectDriver);
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  return (
    <Box>
      {pageLoading ? (
        <CustomFormGrid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <CustomFormGrid>
            <CircularProgress sx={{ height: "20", width: "20px" }} />
          </CustomFormGrid>
        </CustomFormGrid>
      ) : (
        <>
          <MobileCheck
            grid1={
              <CustomFormGrid container>
                <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                  <Box className={classes.loadFormTitleContainer}>
                    <Box flexGrow={1} display="flex" alignItems="center">
                      <Button>
                        <ArrowBack onClick={() => navigate(-1)} />
                      </Button>
                      <Typography
                        variant="h6"
                        className={classes.loadFormMainHeadings}
                      >
                        {translation("users.userInfo.label")}
                      </Typography>
                    </Box>
                  </Box>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                  <CustomFormGrid
                    container
                    className={classes.buttonContainer}
                    justifyContent="flex-end"
                  >
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButtonEdit}
                      onClick={() =>
                        navigate(`/administration/users/${params?.userId}`)
                      }
                    >
                      EDIT INFORMATION
                    </Button>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
            grid2={
              <CustomFormGrid container>
                <CustomFormGrid items lg={6} md={6} xs={12}>
                  <CustomFormGrid>
                    <FromHeader title={translation("users.userInfo.label")} />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.form.firstName")}
                            input={user?.firstName}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.form.lastName")}
                            input={user?.lastName}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.phone.label")}
                            input={user?.phoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.altPhoneNumber.label")}
                            input={user?.altPhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.status.label")}
                            input={user?.status}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.role.label")}
                            input={
                              user?.role != null ? user?.role?.name : "---"
                            }
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation("common.email.label")}
                            input={user?.email}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
                <CustomFormGrid items lg={6} md={6} xs={12}>
                  <CustomFormGrid>
                    <FromHeader
                      title={translation("common.form.permissions")}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid>
                    <CustomFormGrid>
                      <Paper elevation={1} sx={{ width: "100%" }}>
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "driver.read.form.title.form2.title1"
                            )}
                            input={driver?.email}
                            checkColor={true}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "driver.read.form.title.form2.title2"
                            )}
                            input={driver?.cellPhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>

                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "driver.read.form.title.form2.title3"
                            )}
                            input={driver?.homePhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "driver.read.form.title.form2.title4"
                            )}
                            input={driver?.faxPhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                        <Divider variant="middle" />
                        <CustomFormGrid>
                          <FromInput
                            heading={translation(
                              "driver.read.form.title.form2.title5"
                            )}
                            input={driver?.pagerPhoneNumber}
                            checkColor={false}
                          />
                        </CustomFormGrid>
                      </Paper>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            }
          />
        </>
      )}
    </Box>
  );
};

export default UserSummary;
