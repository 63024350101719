import React from "react";
import { Grid} from "@mui/material";
import Head from "src/components/head";
import FreightBillProcessingQueueContainer from "src/containers/billing/freightBillProcessingQueue"
const FreightBillProcessingQueue = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Billing" description="Porto Billing" />
      <FreightBillProcessingQueueContainer/>
    </Grid>
  );
};

export default FreightBillProcessingQueue;
