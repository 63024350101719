import React, { useEffect, useState } from "react";
import { Icon, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
// import { Icon, IconName } from "ts-react-feather-icons";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink } from "react-router-dom";

interface Item {
    title: string;
    name: string;
    icon: string;
    href: string;
    permissions?: Array<string>;
    subItems: Array<SubItem>;
    selected: boolean;
    onClick(event: any): any;
}

interface SubItem {
    title: string;
    name: string;
    href: string;
    icon?: string;
    permissions?: Array<string>;
}

const sideNav = (item: Item) => {

    const [translation] = useTranslation("translation");
    const [subItemSelectedIndex, setsubItemSelectedIndex] = useState();

    const { subItems } = item;
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
        setOpen((prev) => !prev);
        item.onClick(event);
    };


    const handleListItemClick = (
        event: any,
        index: number,
    ) => {
        setsubItemSelectedIndex(index as any);
    };


    return item.href ?

        (
            <ListItemButton
                selected={item.selected}
                onClick={(event: any) => { item.onClick(event) }}
                component={NavLink}
                to={item.href} >
                <ListItemIcon>
                    {
                        item.icon && <Icon style={{color: "white"}}>{item.icon}</Icon>
                    }
                </ListItemIcon>
                <ListItemText primary={translation(item.title)} />
            </ListItemButton>
        ) : (
            <React.Fragment>
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        {
                            item.icon && <Icon style={{color: "white"}}>{item.icon}</Icon>
                        }
                    </ListItemIcon>
                    <ListItemText primary={translation(item.title)} />
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ ml: 4 }}>
                    <List component="div" disablePadding>
                        {subItems.map((child, key: any) => (
                            <ListItemButton
                                key={key}
                                selected={subItemSelectedIndex === key}
                                onClick={(event: any) => handleListItemClick(event, key)}
                                component={NavLink}
                                to={child.href}
                            >
                                <ListItemIcon>
                                    {
                                        child.icon && <Icon style={{color: "white"}}>{child.icon}</Icon>
                                    }
                                </ListItemIcon>
                                <ListItemText primary={translation(child.title)} />

                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
};

export default sideNav;