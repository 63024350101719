import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { DocumentTypeProps } from "src/interfaces/documentType";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectDocumentType } from "src/redux/reducers/documentType";
import DocumentTypeInformation from "./typeInfo";
import { typedKeys } from "src/utils/common";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
interface DocumentTypeFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: DocumentTypeProps) => any; */
  onSubmit: (data: any) => any;
}
const DocumentTypeForm = ({
  loading,
  pageLoading,
  onSubmit,
}: DocumentTypeFormProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [translation] = useTranslation("translation");
  const params = useParams();
  const documentType = useAppSelector(selectDocumentType);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const setFormValues = (key: keyof DocumentTypeProps) => {
    if (documentType) {
      if (typeof documentType[key] === "boolean") {
        setValue(key, documentType[key]);
      } else {
        setValue(key, documentType[key] ? documentType[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.id && documentType) {
      const allAttributes = typedKeys(documentType);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }
  }, [params?.id, documentType]);

  return (
    <div>
      <Card variant="outlined" className={classes.formContainer}>
        <Box className={classes.formTitleContainer}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography className={classes.formTitle}>
              {translation("documentType.form.title")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent className={classes.formContentContainer}>
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
                <DocumentTypeInformation
                  control={control}
                  errors={errors}
                  watch={watch}
                />
                <CustomFormGrid container justifyContent="center">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.formButton}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ color: "#fff", height: "20px", width: "20px" }}
                      />
                    ) : params?.id ? (
                      translation("common.form.update")
                    ) : (
                      translation("common.form.add")
                    )}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    className={classes.formButton}
                    onClick={() => navigate(-1)}
                  >
                    {translation("common.btn.cancel")}
                  </Button>
                </CustomFormGrid>
              </CustomForm>
            </LocalizationProvider>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default DocumentTypeForm;
