import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { UserProps } from "src/interfaces/user";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/users";
import Collapsible from "src/components/collapsibleForm";
import CustomForm from "src/components/customElements/customForm";
import StatusBadge from "src/components/common/StatusBadge";
import RoleBadge from "src/components/common/RoleBadge";
import { typedKeys } from "src/utils/common";
import PermissionsInfo from "./permissions";
import UserInfoPanel from "./userInfoPanel";
import { selectGroupedSecurityGroup } from "src/redux/reducers/securityGroups";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { ArrowBack } from "@mui/icons-material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";

interface UserFormProps {
  loading: boolean;
  loadingPermission: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: UserProps) => any; */
  onSubmit: (data: any) => any;
  updatePermission: (data: Array<number>) => any;
  handleClose?: () => any;
  url: any;
}

const UserForm = ({
  loading,
  loadingPermission,
  pageLoading, 
  onSubmit,
  updatePermission,
  handleClose,
  url,
}: UserFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const params = useParams();
  const classes = useStyles();

  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  const allSecurityGroups = useAppSelector(selectGroupedSecurityGroup);

  const [securityGroupArray, setSecurityGroupArray] = useState<Array<number>>(
    []
  );
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "userInfoPanel",
    "permissionsInfoPanel",
  ]);
  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);

  const handleSelectSecurityGroup = (e: any) => {
    const securityGroups = [...securityGroupArray];
    if (e.target.checked) {
      setSecurityGroupArray([...securityGroups, parseInt(e.target.value)]);
    } else {
      const index = securityGroups.findIndex(
        (item) => item === parseInt(e.target.value)
      );
      if (index > -1) {
        securityGroups.splice(index, 1);
      }
      setSecurityGroupArray([...securityGroups]);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  // const setPhoneNumber = () => {};

  const setFormValues = (key: keyof UserProps) => {
    if (user) {
      if (typeof user[key] === "boolean") {
        setValue(key, user[key]);
      } else {
        setValue(key, user[key] ? user[key] : "");
      }
    }
  };

  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);
  useEffect(() => {
    if (params?.userId && user) {
      const allAttributes = typedKeys(user);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
        if (attribute === "userSecurityGroups") {
          if (user?.userSecurityGroups) {
            const _securityGroupArray = [];
            for (const securityGroups of user.userSecurityGroups) {
              if (typeof securityGroups === "object") {
                _securityGroupArray.push(securityGroups.securityGroup.id);
              }
            }

            setSecurityGroupArray([..._securityGroupArray]);
            // setValue("securityGroup", [..._securityGroupArray]);
          }
        }
      }
    }
  }, [params?.userId, user]);

  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = ["userInfoPanel", "permissionsInfoPanel"];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };

  /* const handleFormSubmission = (data: UserProps) => {
    onSubmit(data);
  }; */
  const handleFormSubmission = (data: any) => {
    onSubmit(data);
  };

  const handleCancel = () => {
    if (params?.userId) {
      () => navigate(-1);
    } else if (handleClose) {
      handleClose();
    }
  };

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {params?.userId
                  ? translation("users.form.title2")
                  : translation("users.form.title1")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(handleFormSubmission)}
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                    justifyContent: "center",
                  }}
                />
              ) : params?.userId ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={handleCancel}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>

      {pageLoading ? (
        <CustomFormGrid container justifyContent= "center">

<CircularProgress />
        </CustomFormGrid>

      ) : (
        <CustomForm noValidate onSubmit={handleSubmit(handleFormSubmission)}>
          {params?.userId && allSecurityGroups?.length > 0 ? (
            <UserInfoPanel
              control={control}
              errors={errors}
              loading={loading}
              handleChange={handleChange}
              pageLoading={pageLoading}
              onSubmit={onSubmit}
              parentExpandAll={expandAllPanels}
              watch={watch}
              setValue={setValue}
              handleClose={handleClose}
            />
          ) : (
            <UserInfoPanel
              control={control}
              errors={errors}
              loading={loading}
              handleChange={handleChange}
              pageLoading={pageLoading}
              onSubmit={onSubmit}
              parentExpandAll={expandAllPanels}
              watch={watch}
              setValue={setValue}
              handleClose={handleClose}
            />
          )}
            {params?.userId && allSecurityGroups?.length > 0 ? (

          <PermissionsInfo
            control={control}
            errors={errors}
            handleSelectSecurityGroup={handleSelectSecurityGroup}
            updatePermission={updatePermission}
            loadingPermission={loadingPermission}
            securityGroupArray={securityGroupArray}
            parentExpandAll={expandAllPanels}
          />

          ) : (
                <></>
              )}
        </CustomForm>
      )}
    </div>
  );
};

export default UserForm;
