import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import SecurityGroupsFormContainer from "src/containers/securityGroups/form";
const SecurityGroups = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Security Groups" description="Porto Security Groups" />
      <SecurityGroupsFormContainer />
    </Grid>
  );
};

export default SecurityGroups;
