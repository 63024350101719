import React from "react";
import { Card, CardContent, Typography, Box, Divider } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/styles/formStyles";
import { selectDashboard } from "src/redux/reducers/settlement";
import { useAppSelector } from "src/hooks/reduxHooks";

interface PendingSettlementsProps {
  loading: boolean;
}

const PendingSettlements = ({ loading }: PendingSettlementsProps) => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const dashboardData = useAppSelector(selectDashboard);
  return (
    <CustomFormGrid item xs={12} lg={12}>
      <Card
        sx={{
          marginTop: "0px",
          height: "480px",
        }}
      >
        <CardContent
          sx={{
            //  paddingBottom: "16px !important",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: {
                sm: "flex",
                xs: "block",
              },
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0",
                  fontWeight: 600,
                }}
                gutterBottom
              >
                {translation("billing.dashboard.pendingSettlement")}
              </Typography>
            </Box>
          </Box>

          <>
            <CustomFormGrid container style={{ marginTop: "35px" }}>
              <CustomFormGrid xs={3} lg={4} style={{ paddingLeft: "25px" }}>
                <Typography className={classes.billingDashboardText}>
                  {translation("billing.dashboard.pending")}
                </Typography>

                <Typography className={classes.billingDashboardNumber}>
                  -
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={1}>
                <div className={classes.vl}></div>
              </CustomFormGrid>

              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardText}>
                  {translation("billing.dashboard.ded")}
                  <br></br>
                </Typography>

                <Typography className={classes.billingDashboardNumber}>
                  -
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={1}>
                <div className={classes.vl}></div>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardText}>
                  {translation("billing.dashboard.adjustment")}
                </Typography>

                <Typography className={classes.billingDashboardNumber}>
                  -
                </Typography>
              </CustomFormGrid>
            </CustomFormGrid>

            {/* table container */}
            <CustomFormGrid
              xs={12}
              lg={12}
              style={{ paddingLeft: "20px", borderRadius: "5px" }}
            >
              <CustomFormGrid container>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingDashboardHeadings}>
                    {translation("billing.dashboard.pendingSettlement")}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={2} lg={2}>
                  <Typography
                    className={classes.billingDashboardHeadingsNumber}
                  >
                    #
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingDashboardHeadings}>
                    {translation("billing.dashboard.positiveSubTotals")}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingDashboardHeadings}>
                    {translation("billing.dashboard.negativeSubTotals")}
                  </Typography>
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider />

              <CustomFormGrid container>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {translation("billing.dashboard.terminal")}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={2} lg={2}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.terminalCount
                      ? dashboardData?.terminalCount
                      : "0"}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.positiveSetTotalTerminal
                      ? "$" + dashboardData?.positiveSetTotalTerminal
                      : "$0.00"}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.negativeSetTotalTerminal
                      ? "$" + dashboardData?.negativeSetTotalTerminal
                      : "$0.00"}
                  </Typography>
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider />

              <CustomFormGrid container>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {translation("billing.dashboard.companyDriver")}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={2} lg={2}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.companyDriverCount
                      ? dashboardData?.companyDriverCount
                      : "0"}{" "}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.positiveSetTotalDriver
                      ? "$" + dashboardData?.positiveSetTotalDriver
                      : "$0.00"}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.negativeSetTotalDriver
                      ? "$" + dashboardData?.negativeSetTotalDriver
                      : "$0.00"}
                  </Typography>
                </CustomFormGrid>
              </CustomFormGrid>

              <Divider />

              <CustomFormGrid container>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {translation("billing.dashboard.ownerOperator")}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={2} lg={2}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.ownerOperatorCount
                      ? dashboardData?.ownerOperatorCount
                      : "0"}{" "}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.positiveSetTotalOwner
                      ? "$" + dashboardData?.positiveSetTotalOwner
                      : "$0.00"}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingTableText}>
                    {dashboardData?.negativeSetTotalOwner
                      ? "$" + dashboardData?.negativeSetTotalOwner
                      : "$0.00"}
                  </Typography>
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider />

              <CustomFormGrid container>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingDashboardHeadings}>
                    {translation("billing.dashboard.total")}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={2} lg={2}>
                  <Typography
                    className={classes.billingDashboardHeadingsNumber}
                  >
                    {dashboardData?.companyDriverCount +
                      dashboardData?.ownerOperatorCount +
                      dashboardData?.terminalCount}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingDashboardHeadings}>
                    {"$" +
                      (dashboardData?.positiveSetTotalDriver +
                        dashboardData?.positiveSetTotalOwner +
                        dashboardData?.positiveSetTotalTerminal)}
                  </Typography>
                </CustomFormGrid>
                <CustomFormGrid xs={3} lg={3}>
                  <Typography className={classes.billingDashboardHeadings}>
                    {"$" +
                      (dashboardData?.negativeSetTotalDriver +
                        dashboardData?.negativeSetTotalOwner +
                        dashboardData?.negativeSetTotalTerminal)}{" "}
                  </Typography>
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </>
        </CardContent>
      </Card>
    </CustomFormGrid>
  );
};

export default PendingSettlements;
