import {
  featureDrivers,
  featureVehicles,
  featureOwners,
  featureCustomers,
  featureTrailers,
  featureLoad,
  img,
  pdf,
  xls,
  docx,
  all,
} from "../enums";
export const types = [
  {
    value: img,
    label: "Image",
  },
  {
    value: pdf,
    label: "PDF",
  },
  {
    value: xls,
    label: "XLS",
  },
  {
    value: docx,
    label: "DOCX",
  },
  {
    value: all,
    label: "All",
  },
];
export const entities = [
  {
    value: featureDrivers,
    label: "Driver",
  },
  {
    value: featureVehicles,
    label: "Vehicles",
  },
  {
    value: featureTrailers,
    label: "Trailers",
  },
  {
    value: featureOwners,
    label: "Owners",
  },
  {
    value: featureCustomers,
    label: "Customers",
  },
  {
    value: featureLoad,
    label: "Load",
  },
];
