import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toNumber } from "lodash";
import DataTable from "src/components/dataTable";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { LookupProps } from "src/interfaces/lookup";
import { Clipboard } from "ts-react-feather-icons";
import { getAllLineItems } from "src/redux/actions/lineItems";
import { selectLookups } from "src/redux/reducers/lookup";

import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { featureLineItems, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { dateTimeFormat } from "src/utils/constants";
import { statusTypes } from "src/utils/dropdowns/common";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { InputLabel } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAllOwners } from "src/redux/actions/owner";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { getAllVehicles } from "src/redux/actions/vehicle";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import { selectLoadManagements } from "src/redux/reducers/loadManagement";
import { getAllDrivers } from "src/redux/actions/driver";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import { LoadStopProps } from "src/interfaces/loadManagement";
import CustomTextField from "src/components/customElements/customTextField";
import { getSearchTablesData } from "src/redux/actions/search";
interface LoadSummaryProps {
  pageLoading: boolean;
  delayForClear: any;
}
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const LoadSearchForm = ({
  pageLoading,
  delayForClear,
}: LoadSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<any>({});
  const user = useAppSelector(selectUser);
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);

  const loadData = useAppSelector(selectLoadManagement);
  const pickupDateTimeRef = React.useRef<any>();
  const pickupDateTimeEndRef = React.useRef<any>();
  const [deliveryStart, setDeliveryStart] = React.useState<any | null>(null);
  const [deliveryEnd, setDeliveryEnd] = React.useState<any | null>(null);
  const selectPickupDateTimeRef = () => {
    if (pickupDateTimeRef?.current) {
      pickupDateTimeRef?.current?.click();
    }
  };
  const selectPickupDateEndTimeRef = () => {
    if (pickupDateTimeEndRef?.current) {
      pickupDateTimeEndRef?.current?.click();
    }
  };
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [addedByData, setAddedByData] = React.useState<any | null>([]);
  const [terminalData, setTerminalData] = React.useState<any | null>([]);
  const [pickupStart, setPickupStart] = React.useState<any | null>(null);
  const [pickupEnd, setPickupEnd] = React.useState<any | null>(null);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  // const [, forceUpdate] = React.useState();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  let timer: any;
  const allLoadManagement = useAppSelector(selectLoadManagements);
  const [allLoadManagementData, setAllLoadManagementData] = useState<any>({});
  const [lineItemSearchedData, setLineItemSearchedData] = useState<any>({});
  const [displayTable, setdisplayTable] = useState<boolean>(false);
  console.log(lineItemSearchedData, "lineItemSearchedData");
  const boxTheme = useTheme();
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const lineItemPermissions =
    allUserPermissions && allUserPermissions[featureLineItems];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : lineItemPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : lineItemPermissions?.findIndex((item) => item === update);

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={<Edit />}
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/config/line-item/${params.row.id}`);
        //   }}
        // />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,

    },
    {
      field: "code",
      headerName: translation("lineItem.list.code.label"),
      minWidth: 150,

    },
    {
      field: "title",
      headerName: translation("lineItem.list.title.label"),
      minWidth: 150,

    },
    {
      field: "rate",
      headerName: translation("lineItem.list.defaultRate.label"),
      minWidth: 150,

    },
    {
      field: "GL_account",
      headerName: translation("lineItem.list.quickBooksGlAccount.label"),
      minWidth: 200,

    },
    {
      field: "notes",
      headerName: translation("lineItem.list.lineItemNotes.label"),
      minWidth: 200,

    },
    {
      field: "description",
      headerName: translation("lineItem.list.description.label"),
      flex: 1,

      minWidth: 250,
      renderCell: (params: GridValueGetterParams) => {
        return <div style={{ flexGrow: 1 }}>{params.row.description}</div>;
      },
    },
  ];


  useEffect(() => {
    if (pickupStart != null && pickupEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["pickupDateTime"] = {
        value: pickupStart.toISOString() + "," + pickupEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [pickupStart, pickupEnd]);
  useEffect(() => {
    if (deliveryStart != null && deliveryEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["deliveryDateTime"] = {
        value: deliveryStart.toISOString() + "," + deliveryEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [deliveryStart, deliveryEnd]);

  /*  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (loadSearchedData.length > 0) {
      setAllLoadManagementData({
        ...allLoadManagementData?.allLoadManagement,
        allLoadManagement,
      });
    } else {
      setAllLoadManagementData({});
    }
  }, [allLoadManagement]); */
  useEffect(() => {
   {Object.keys(filter).length !== 0 && getLineItemListWithFilter();}
  }, [rowsState.pageSize, rowsState.page]);

  const getLineItemListWithFilter = async () => {
    setLoading(true);
      await dispatch(
        getAllLineItems(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ).then((response:any) => {
        setLineItemSearchedData(response?.data);
        setdisplayTable(true);

      });
    setLoading(false);
  };

  const handleChangeAutoComplete = async (
    event: any,
    newValue: any,
    setData: any,
    table: string,
    column: string
  ) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getSearchTablesData(table, column, event)).then((res:any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setData(res?.data);
        }
      });
    }
  };
  const onRowClick = (params: GridRowParams) => {
    navigateToLineItemDetail(params.row.id);
  };
  const navigateToLineItemDetail = (id: number) => {
    navigate(
      `/drivers/drivers/details/${id}?entity=${featureLineItems}&referenceId=${id}`
    );
  };

  const submitSearch = async () => {
    getLineItemListWithFilter();
  };

  console.log(filter, "filter");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("lineItems.search")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              className={classes.formButtonDiscard}
              onClick={() => {
                delayForClear(true);
              }}
            >
              {translation("search.form.clear")}
            </Button>

            <Button
              variant="contained"
              // type="submit"
              onClick={submitSearch}
              className={classes.formButtonSave}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                translation("search.form.search")
              )}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
        {/* //1st row */}

        <CustomFormGrid container style={{ paddingTop: "30px" }}>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="id"
              size="small"
              fullWidth
              value={filter["id"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["id"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["id"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.id.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="code"
              size="small"
              fullWidth
              value={filter["code"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["code"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["code"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("customer.form.code.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="title"
              size="small"
              fullWidth
              value={filter["title"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["title"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["title"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("lineItem.form.title.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="rate"
              size="small"
              fullWidth
              value={filter["rate"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["rate"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["rate"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("lineItem.form.defaultRate.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="GL_account"
              size="small"
              fullWidth
              value={filter["GL_account"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["GL_account"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["GL_account"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("lineItem.form.quickBooksGlAccount.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="description"
              size="small"
              fullWidth
              value={filter["description"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["description"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["description"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("lineItem.list.description.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="notes"
              size="small"
              fullWidth
              value={filter["notes"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["notes"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["notes"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("lineItem.list.lineItemNotes.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>

          {/*  <CustomFormGrid item sm={12} xs={12} md={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={addedByData ? addedByData : "No Data"}
              value={addedByData?.id}
              getOptionLabel={(option) =>
                option?.firstName && option?.lastName
                  ? option?.firstName + " " + option?.lastName
                  : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                console.log("newValue", newValue);
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["addedBy"];
                } else if (newValue?.id) {
                  temp["addedBy"] = { value: newValue?.id, filter: "equals" };
                }
                setFilter(temp);

              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setAddedByData,
                          "user",
                          "firstName"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={translation("Added By")}
                  error={false}

                />
              )}
            />
          </CustomFormGrid> */}

          {/* <InputLabel>Insurance Exp. Date</InputLabel>{" "}
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="insuranceExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupStart(newValue);
                  }}
                  value={pickupStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="insuranceExpiration"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="insuranceExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupEnd(newValue);
                  }}
                  value={pickupEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="insuranceExpiration"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <InputLabel>Tag Exp. Date</InputLabel>{" "}
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="tagExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupStart(newValue);
                  }}
                  value={pickupStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="tagExpiration"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="tagExpiration"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setPickupEnd(newValue);
                  }}
                  value={pickupEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="tagExpiration"
                    />
                  )}
                />
              )}
            />
                  </CustomFormGrid>*/}
        </CustomFormGrid>
        {lineItemSearchedData?.data?.length > 0 && loading ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "30px" }}
          >
            <CircularProgress />
          </CustomFormGrid>
        ) : lineItemSearchedData?.data?.length > 0 ? (
          <>
            <CustomFormGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "30px" }}
            >
              <Typography className={classes.loadFormHeadings}>
                {translation("lineItems.searched")}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                title={translation("lineItems.searched.list")}
                rows={lineItemSearchedData?.data}
                columns={columns}
                loading={loading}
                createPermission={createPermission > -1}
                rowsState={rowsState}
                // onRowClick={onRowClick}
                setRowsState={setRowsState}
                pagination={lineItemSearchedData.pagination}
                data={lineItemSearchedData?.data}
                setData={setLineItemSearchedData}
                add={true}
                hideBackBar={true}
              />
            </CustomFormGrid>
          </>
        ) : displayTable === true ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "50px" }}
          >
            <Typography variant="h5">No search result found</Typography>
          </CustomFormGrid>
        ) : (
          ""
        )}
      </CustomFormGrid>
    </LocalizationProvider>
  );
};

export default LoadSearchForm;
