import { Box, Grid, Button, Typography, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import AgricultureIcon from '@mui/icons-material/Agriculture';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BoyIcon from '@mui/icons-material/Boy';
import LuggageIcon from '@mui/icons-material/Luggage';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';

import React from "react";

const toolbarPaperProps = {
    sx: {
        width: '13%',
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.25))',
        mt: 1,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },

    },
}



const Toolbar = () => {

    const [loadAnchorEl, setLoadAnchorEl] = React.useState<null | HTMLElement>(null);
    const [ownerAnchorEl, setOwnerAnchorEl] = React.useState<null | HTMLElement>(null);
    const [driverAnchorEl, setDriverAnchorEl] = React.useState<null | HTMLElement>(null);
    const [tractorAnchorEl, setTractorAnchorEl] = React.useState<null | HTMLElement>(null);
    const [trailerAnchorEl, setTrailerAnchorEl] = React.useState<null | HTMLElement>(null);
    const [customerAnchorEl, setCustomerAnchorEl] = React.useState<null | HTMLElement>(null);


    const loadOpen = Boolean(loadAnchorEl);
    const ownerOpen = Boolean(ownerAnchorEl);
    const driverOpen = Boolean(driverAnchorEl);
    const tractorOpen = Boolean(tractorAnchorEl);
    const trailerOpen = Boolean(trailerAnchorEl);
    const customerOpen = Boolean(customerAnchorEl);

    const handleLoadClick = (event: React.MouseEvent<HTMLElement>) => {
        setLoadAnchorEl(event.currentTarget);
    };
    const handleLoadMenuClose = () => {
        setLoadAnchorEl(null);
    };

    const handleOwnerClick = (event: React.MouseEvent<HTMLElement>) => {
        setOwnerAnchorEl(event.currentTarget);
    };
    const handleOwnerMenuClose = () => {
        setOwnerAnchorEl(null);
    };

    const handleDriverClick = (event: React.MouseEvent<HTMLElement>) => {
        setDriverAnchorEl(event.currentTarget);
    };
    const handleDriverMenuClose = () => {
        setDriverAnchorEl(null);
    };

    const handleTractorClick = (event: React.MouseEvent<HTMLElement>) => {
        setTractorAnchorEl(event.currentTarget);
    };
    const handleTractorMenuClose = () => {
        setTractorAnchorEl(null);
    };

    const handleTrailerClick = (event: React.MouseEvent<HTMLElement>) => {
        setTrailerAnchorEl(event.currentTarget);
    };
    const handleTrailerMenuClose = () => {
        setTrailerAnchorEl(null);
    };

    const handleCustomerClick = (event: React.MouseEvent<HTMLElement>) => {
        setCustomerAnchorEl(event.currentTarget);
    };
    const handleCustomerMenuClose = () => {
        setCustomerAnchorEl(null);
    };

    return (
        <>
            <Box>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <React.Fragment>
                            <Button
                                id="load-button"
                                fullWidth
                                aria-controls={loadOpen ? 'load-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={loadOpen ? 'true' : undefined}
                                onClick={handleLoadClick}
                                variant="outlined"
                                color="secondary"
                                startIcon={<LuggageIcon />}
                            >
                                Load
                            </Button>
                            <Menu
                                id="load-menu"
                                aria-labelledby="load-button"
                                anchorEl={loadAnchorEl}
                                open={loadOpen}
                                onClose={handleLoadMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginTop: "3px",
                                }}
                                PaperProps={{ ...toolbarPaperProps }}
                            >

                                <MenuItem onClick={handleLoadMenuClose}>
                                    <ListItemIcon>
                                        <AddIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>
                                            Add
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleLoadMenuClose}>
                                    <ListItemIcon>
                                        <SearchIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>
                                            Search
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <React.Fragment>
                            <Button
                                id="owner-button"
                                fullWidth
                                aria-controls={ownerOpen ? 'owner-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={ownerOpen ? 'true' : undefined}
                                onClick={handleOwnerClick}
                                variant="outlined"
                                color="secondary"
                                startIcon={<PersonIcon />}>
                                Owner
                            </Button>
                            <Menu
                                id="owner-menu"
                                aria-labelledby="owner-button"
                                anchorEl={ownerAnchorEl}
                                open={ownerOpen}
                                onClose={handleOwnerMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginTop: "3px",
                                }}
                                PaperProps={{ ...toolbarPaperProps }}
                            >

                                <MenuItem onClick={handleOwnerMenuClose}>
                                    <ListItemIcon>
                                        <AddIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Add</Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleOwnerMenuClose}>
                                    <ListItemIcon>
                                        <SearchIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Search</Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <React.Fragment>
                            <Button
                                id="driver-button"
                                fullWidth
                                aria-controls={driverOpen ? 'driver-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={driverOpen ? 'true' : undefined}
                                onClick={handleDriverClick}
                                variant="outlined"
                                color="secondary"
                                startIcon={<EngineeringIcon />}>
                                Driver
                            </Button>
                            <Menu
                                id="driver-menu"
                                aria-labelledby="driver-button"
                                anchorEl={driverAnchorEl}
                                open={driverOpen}
                                onClose={handleDriverMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginTop: "3px",
                                }}
                                PaperProps={{ ...toolbarPaperProps }}
                            >

                                <MenuItem onClick={handleDriverMenuClose}>
                                    <ListItemIcon>
                                        <AddIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Add</Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleDriverMenuClose}>
                                    <ListItemIcon>
                                        <SearchIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Search</Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <React.Fragment>
                            <Button
                                id="tractor-button"
                                fullWidth
                                aria-controls={tractorOpen ? 'tractor-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={tractorOpen ? 'true' : undefined}
                                onClick={handleTractorClick}
                                variant="outlined"
                                color="secondary"
                                startIcon={<AgricultureIcon />}>
                                Tractor
                            </Button>
                            <Menu
                                id="tractor-menu"
                                aria-labelledby="tractor-button"
                                anchorEl={tractorAnchorEl}
                                open={tractorOpen}
                                onClose={handleTractorMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginTop: "3px",
                                }}
                                PaperProps={{ ...toolbarPaperProps }}
                            >

                                <MenuItem onClick={handleTractorMenuClose}>
                                    <ListItemIcon>
                                        <AddIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Add</Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleTractorMenuClose}>
                                    <ListItemIcon>
                                        <SearchIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Search</Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <React.Fragment>
                            <Button
                                id="trailer-button"
                                fullWidth
                                aria-controls={trailerOpen ? 'trailer-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={trailerOpen ? 'true' : undefined}
                                onClick={handleTrailerClick}
                                variant="outlined"
                                color="secondary"
                                startIcon={<DirectionsBusFilledIcon />}>
                                Trailer
                            </Button>
                            <Menu
                                id="trailer-menu"
                                aria-labelledby="trailer-button"
                                anchorEl={trailerAnchorEl}
                                open={trailerOpen}
                                onClose={handleTrailerMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginTop: "3px",
                                }}
                                PaperProps={{ ...toolbarPaperProps }}
                            >

                                <MenuItem onClick={handleTrailerMenuClose}>
                                    <ListItemIcon>
                                        <AddIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Add</Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleTrailerMenuClose}>
                                    <ListItemIcon>
                                        <SearchIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Search</Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <React.Fragment>
                            <Button
                                id="customer-button"
                                fullWidth
                                aria-controls={customerOpen ? 'customer-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={customerOpen ? 'true' : undefined}
                                onClick={handleCustomerClick}
                                variant="outlined"
                                color="secondary"
                                startIcon={<BoyIcon />}>
                                Customer
                            </Button>
                            <Menu
                                id="customer-menu"
                                aria-labelledby="customer-button"
                                anchorEl={customerAnchorEl}
                                open={customerOpen}
                                onClose={handleCustomerMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    marginTop: "3px",
                                }}
                                PaperProps={{ ...toolbarPaperProps }}
                            >

                                <MenuItem onClick={handleCustomerMenuClose}>
                                    <ListItemIcon>
                                        <AddIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Add</Typography>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={handleCustomerMenuClose}>
                                    <ListItemIcon>
                                        <SearchIcon fontSize={"inherit"} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={14}>Search</Typography>
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Toolbar;