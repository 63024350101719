import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import TrailerContainer from "src/containers/trailer";
const Trailers = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Trailers" description="Porto Trailers" />
      <TrailerContainer />
    </Grid>
  );
};

export default Trailers;
