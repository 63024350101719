import i18next from "i18next";
import { AppDispatch } from "../store";
import {
  setAllDocuments,
  updateDocumentsList,
 // appendDocumentsList,
  setUploadProgress,
//  filterDocumentsList,
} from "../reducers/documents";
import { VerifyDocumentProps } from "src/interfaces/documents";
import { getServerData, patchServerData, deleteServerData } from "../api/index";
import API from "src/utils/axiosConfig";
import { showNotification } from "../reducers/notifications";

export const getAllDocuments =
  (id: number, entity: string, limit?: number, page?: number) =>
  async (dispatch: AppDispatch) => {
    const response = await dispatch(
      getServerData(
        `/document/entity?entity=${entity}&referenceId=${id}`
      ) as any
    );
    if (response?.data?.data) {

      if (Array.isArray(response?.data?.data?.data)) {
        dispatch(setAllDocuments(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };

export const uploadDocument =
  (data: FormData) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = (loaded / total) * 100;
          dispatch(setUploadProgress(percent));
        },
      };

      const response = await API.post("/document", data, config);

      const responseData: any = response;
      if (responseData) {
        if (responseData?.data) {
          // dispatch(appendDocumentsList(responseData?.data));
        }
        return { success: true, data: responseData };
      }
      return { success: false, data: null };
    } catch (error) {

      const _error: any = error;
      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.server.error"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const downloadDocument =
  (id: number, name: string) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");

      API.get(`/document/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
        .then((response: any) => {
          const file = new Blob([response.data], {
            type: "application/octet-stream",
          });

          const fileURL = URL.createObjectURL(file);

          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("download", `${name}`);
          document.body.appendChild(link);
          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.server.error"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const viewDocument =
  (id: number, name: string) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      API.get(`/document/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
        .then((response: any) => {
          const fullName = name.split(".");
          const ext = fullName[fullName.length - 1];
          if (ext === "pdf") {
            const file = new Blob([response.data], {
              type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else if (ext === "png") {
            const file = new Blob([response.data], {
              type: "image/png",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else if (ext === "jpeg" || ext === "jpg") {
            const file = new Blob([response.data], {
              type: "image/jpeg",
            });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            const file = new Blob([response.data], {
              type: "application/octet-stream",
            });

            const fileURL = URL.createObjectURL(file);

            const link = document.createElement("a");
            link.href = fileURL;
            link.setAttribute("download", `${name}`);
            document.body.appendChild(link);
            link.click();
            if (link.parentNode) {
              link.parentNode.removeChild(link);
            }
          }
        })
        .catch((error) => {
          const _error: any = error;
          dispatch(
            showNotification({
              open: true,
              message: _error?.response?.data?.message
                ? _error?.response?.data?.message
                : i18next.t("messages.server.error"),
              severity: "error",
            })
          );
        });
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.server.error"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const verifyDocument =
  (id: number, data: VerifyDocumentProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`document/approve-reject/${id}`, data) as any
    );
    if (response?.data?.data) {
      dispatch(updateDocumentsList({ data: response?.data?.data, id }));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const deleteDocument = (id: number) => async (dispatch: AppDispatch) => {
  const response = await dispatch(deleteServerData(`document/${id}`) as any);
  if (response?.data?.data) {
    // dispatch(filterDocumentsList(id));
  }
  return { success: response.success, data: response?.data?.data };
};
