import { perMile, perLoad, cwt } from "../enums";
export const loadSizes = [
  {
    label: "LTL",
    value: "LTL",
  },
  {
    label: "Full",
    value: "Full",
  },
];

export const commodityType = [
  {
    label: "FAK",
    value: "fak",
  }
];

export const trailerRequirement = [
  {
    label: "Flatbed",
    value: "flatbet",
  },
  {
    label: "Power Only",
    value: "powerOnly",
  },
  {
    label: "Reefer",
    value: "reefer",
  },
  {
    label: "Stepdeck",
    value: "stepdeck",
  },
  {
    label: "Van",
    value: "van",
  },
]

export const stopTypes = [
  {
    label: "PU",
    value: "PU",
  },
  {
    label: "SO",
    value: "SO",
  },
];
export const lineHauls = [
  {
    label: "Per Mile",
    value: perMile,
  },
  {
    label: "Per Load",
    value: perLoad,
  }
];

export const dispatchType = [
  {
    value: "originalDispatch",
    label: "Original Dispatch",
  },
  {
    value: "additionalTruck",
    label: "Additional Truck",
  },
  {
    value: "rePower",
    label: "Re Power",
  },
];
export const dispatchWho = [
  {
    value: "ownerOperator",
    label: "Owner Operator",
  },
  {
    value: "companyDriver",
    label: "company driver",
  },
];

export const dispatchStatus = [
  {
    value: "planned",
    label: "Planned",
  },
  {
    value: "dispatched",
    label: "Dispatched",
  },
  {
    value: "atShipper",
    label: "At Shipper",
  },
  {
    value: "loaded",
    label: "Loaded",
  },
  {
    value: "atConsignee",
    label: "At Consignee",
  },
  {
    value: "delivered",
    label: "Delivered",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
];
export const loadStatus = [
  {
    value: "",
    label: "- Select -",
  },
  {
    value: "planned",
    label: "Planned",
  },
  {
    value: "inProgress",
    label: "In Progress",
  },

  {
    value: "delivered",
    label: "Delivered",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "billed",
    label: "Billed",
  },
];

export const DispatchPay = [
  {
    value: "flatRate",
    label: "Flat Rate",
  },
  {
    value: "perHour",
    label: "Per Hour",
  },
  {
    value: "perMile",
    label: "Per Mile",
  },
  {
    value: "percentOfFreight",
    label: "Percent Of Freight",
  },
  {
    value: "percentOfGross",
    label: "Percent Of Gross",
  },
];

export const DispatchPayDriver = [
  {
    value: "accountSettings",
    label: "Account Settings",
  },
  {
    value: "flatRate",
    label: "Flat Rate",
  },
];
