import { approved, rejected } from "../enums";
export const documentStatus = [
  {
    value: approved,
    label: "Approve",
  },
  {
    value: rejected,
    label: "Reject",
  },
];
