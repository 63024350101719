import _ from "lodash";
import { createTheme } from "@mui/material/styles";
import shadows from "./shadows";
import typography from "./typography";
import components from "./override";

const SidebarWidth = 265;
const TopbarHeight = 70;

const customThemeOptions = {
  palette: {
    primary: {
      main: "#6c9a40",
      light: "#d8e9c9",
      dark: "#587e34",
    },
    secondary: {
      main: "#1e4db7",
      light: "#ddebff",
      dark: "#173f98",
    },

    success: {
      main: "#00c292",
      light: "#ebfaf2",
      dark: "#00964b",
      contrastText: "#ffffff",
    },
    danger: {
      main: "#e46a76",
      light: "#fdf3f5",
    },
    info: {
      main: "#0bb2fb",
      light: "#e6f7ff",
    },
    error: {
      main: "#e46a76",
      light: "#fdf3f5",
      dark: "#e45a68",
    },
    warning: {
      main: "#d7a229",
      light: "#f3e3bf",
      dark: "#c19124",
      contrastText: "#ffffff",
    },
    text: {
      secondary: "#777e89",
      danger: "#fc4b6c",
    },
    grey: {
      A100: "#ecf0f2",
      A200: "#99abb4",
      A400: "#767e89",
      A700: "#e6f4ff",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "rgba(0, 0, 0, 0.03)",
    },
  },

  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      color: "#949db2",
      "@media(min-width:1280px)": {
        minHeight: TopbarHeight,
        padding: "0 30px",
      },
      "@media(max-width:1280px)": {
        minHeight: "64px",
      },
    },
  },
  status: {
    danger: "#e53e3e",
  },
  components,
  typography,
  shadows,
};

// export const BuildTheme = () => {
//   const theme = createTheme(baseTheme);
//   return theme;
// };

const CustomTheme = createTheme(customThemeOptions);

export {
  CustomTheme,
  TopbarHeight,
  SidebarWidth,
};
