import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import SettlementTerminal from "src/containers/settlement/settlementTerminal"
const Settlements = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Settlements" description="Porto Settlements" />
      <SettlementTerminal/>
    </Grid>
  );
};

export default Settlements;