import React, { useState } from "react";
import {Grid, Autocomplete } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { selectUsers, selectUser } from "src/redux/reducers/users";
import { UserProps } from "src/interfaces/user";
import AddCommonDialog from "src/components/common/addCommonDialog";
import UserFormContainer from "src/containers/users/formContainer";
import { GridAddIcon } from "@mui/x-data-grid";
import { useStyles } from "src/styles/formStyles";
import { driver } from "src/utils/enums";
import AddLookupField from "src/components/common/addLookupFeild";

interface DispatchInfoProps {
  control: any;
  errors: { [key: string]: any };
  handleDispatcherChange: (event: any, newValue: UserProps | null) => any;
  dispatcher: UserProps | null;
  disabled: boolean;
  linkingAddress: any;
}
const DispatchInfo = ({
  control,
  errors,
  handleDispatcherChange,
  dispatcher,
  disabled,
  linkingAddress,
}: DispatchInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const users = useAppSelector(selectUsers);

  const user = useAppSelector(selectUser);
  const allLookups = useAppSelector(selectLookups);
  const classes = useStyles();
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  const allDispatchGroups: Array<LookupProps> = allLookups?.data?.[
    "dispatch-group"
  ]?.lookups
    ? allLookups?.data?.["dispatch-group"]?.lookups
    : [];
  const handleOpenUserModal = () => {
    setOpenUserModal(true);
  };
  const handleCloseUserModal = () => {
    setOpenUserModal(false);
  };
  return (
    <div>
      <Grid container>
     {/*    <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
          <Controller
            name="dispatchGroup"
            control={control}
            defaultValue={""}
            //  rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="dispatchGroup"
                label={translation("driver.form.dispatchGroup.label")}
                error={Boolean(errors?.dispatchGroup)}
                helperText={
                  errors?.dispatchGroup &&
                  translation("driver.form.dispatchGroup.message")
                }
                //  required
                select
              >
                {allDispatchGroups.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid> */}
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12} allDispatchGroups>
        <AddLookupField
              name={"dispatchGroup"}
              control={control}
              rules={{ required: false }}
              id="dispatchGroup"
              label={translation("driver.form.dispatchGroup.label")}
              errors={Boolean(errors?.dispatchGroup)}
              lookupsArray={allDispatchGroups}
              linkingAddress={disabled}
              helperText={
                errors?.dispatchGroup &&
                  translation("driver.form.dispatchGroup.message")
              }
              fieldRequired={false}
              disabled={disabled}
              featureKey="dispatch-group"
              lookupFeatureEnum={"all"}
            />
        </CustomFormGrid>
        {user?.role?.name === driver ? (
          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="space-between"
          >
            <CustomFormGrid
              sx={{
                width: "100%",
                padding: "0px",
              }}
            >
              <Controller
                name="dispatcher"
                control={control}
                defaultValue={""}
                render={() => (
                  <Autocomplete
                    options={users?.data}
                    disabled={disabled}
                    onChange={handleDispatcherChange}
                    value={dispatcher}
                    getOptionLabel={(option) => option.firstName}
                    renderInput={(params: any) => (
                      <CustomTextField
                        {...params}
                        fullWidth
                        id="dispatcher"
                        size="small"
                        label={translation("driver.common.dispatcher.label")}
                        error={Boolean(errors?.dispatcher)}
                        helperText={
                          errors?.dispatcher &&
                          translation("driver.common.dispatcher.message")
                        }
                      />
                    )}
                  />
                )}
              />
            </CustomFormGrid>
          </CustomFormGrid>
        ) : (
          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="space-between"
          >
            <CustomFormGrid
              sx={{
                width: "100%",
                padding: "0px",
              }}
            >
              <Controller
                name="dispatcher"
                control={control}
                defaultValue={""}
                render={() => (
                  <Autocomplete
                    options={users?.data}
                    disabled={disabled}
                    onChange={handleDispatcherChange}
                    value={dispatcher}
                    getOptionLabel={(option) => option.firstName}
                    renderInput={(params: any) => (
                      <CustomTextField
                        {...params}
                        fullWidth
                        id="dispatcher"
                        size="small"
                        label={translation("driver.common.dispatcher.label")}
                        error={Boolean(errors?.dispatcher)}
                        helperText={
                          errors?.dispatcher &&
                          translation("driver.common.dispatcher.message")
                        }
                      />
                    )}
                  />
                )}
              />
            </CustomFormGrid>
            {linkingAddress ? (
              <CustomFormGrid>
                <GridAddIcon
                  className={classes.disableGridAddButton}
                ></GridAddIcon>
              </CustomFormGrid>
            ) : (
              <CustomFormGrid>
                <GridAddIcon
                  className={classes.formAddOwnerButton}
                  onClick={() => handleOpenUserModal()}
                ></GridAddIcon>
              </CustomFormGrid>
            )}
          </CustomFormGrid>
        )}
      </Grid>
      <AddCommonDialog
        handleClose={handleCloseUserModal}
        open={openUserModal}
        title="Add User"
        ComponentToRender={
          <UserFormContainer handleClose={handleCloseUserModal} />
        }
      />
    </div>
  );
};

export default DispatchInfo;
