import React, { useState } from "react";
import {
  Box,
  Container,
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import { SidebarWidth } from "src/theme";


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(8),
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  paddingBottom: theme.spacing(8),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${SidebarWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const FullLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <>
      <Header
        sidebarWidth={SidebarWidth}
        isSideBarOpen={isSidebarOpen}
        openSidebar={() => { console.log("called"); setSidebarOpen(true) }}
      />

      <Sidebar
        sidebarWidth={SidebarWidth}
        isSidebarOpen={isSidebarOpen}
        closeSidebar={() => { setSidebarOpen(false) }}
      />

      <Main open={isSidebarOpen} sx={{backgroundColor: "#f2f2f3", width: `calc(100vh - ${SidebarWidth}px)`}}>
        <Container sx={{ minHeight: "calc(100vh - 170px)", padding: "0" }}>
          <Outlet />
        </Container>
      </Main>
    </>
  );
};

export default FullLayout;
