import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CustomGrid from "src/components/customElements/customFormGrid";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { Fab, Grid, IconButton, Divider } from "@mui/material";
import { Add, ListAlt } from "@mui/icons-material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import { useStyles } from "src/styles/formStyles";

interface FromTitleProps {
  handleClickOpen: any;
  handleOpenLogsModal: any;
  linkedData: any;
  handleDelete: any;
  headerName: any;
}

const VehicleCard = ({
  handleClickOpen,
  handleOpenLogsModal,
  linkedData,
  headerName,
  handleDelete,
}: FromTitleProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();

  return (
    <CustomFormGrid container>
      <CustomFormGrid
        container
        style={{
          backgroundColor: "#000",
          borderRadius: "5px",
          padding: 0,
          margin: 0,
        }}
      >
        <CustomFormGrid item xs={10} sm={10} md={10} lg={10}>
          <Box>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Typography
                variant="h6"
                style={{ color: "#fff", fontSize: "16px", fontWeight: 600 }}
                component="h4"
                sx={{ p: 1, pl: 1 }}
              >
                {headerName}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={2} sm={2} md={2} lg={2}>
          <CustomFormGrid
            container
            justifyContent="space-between"
            style={{ padding: "5px" }}
          >
            <Tooltip
              title={
                <Typography>{translation("driver.owner.assign")}</Typography>
              }
            >
              <Add
                onClick={handleClickOpen}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </Tooltip>
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <ListAlt
                onClick={handleOpenLogsModal}
                style={{ color: "#fff", cursor: "pointer" }}
              />
            </Tooltip>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>
      <CustomFormGrid xs={12} sm={12} md={12} lg={12}>
        <Paper elevation={1} sx={{ width: "100%" }}>
          {linkedData?.length > 0 ? (
            linkedData?.map((item: any) => (
              <>
                <CustomFormGrid container key={item.id} style={{ padding: 0 }}>
                  <>
                    <CustomFormGrid xs={11} sm={11} md={11} lg={11}>
                      <CustomFormGrid container style={{ paddingLeft: "5px" }}>
                        <CustomFormGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{
                              fontSize: "16px",
                              lineHeight: "24px",
                              fontWeight: 400,
                            }}
                          >
                            {item?.vehicle?.vehicleID
                              ? item?.vehicle?.vehicleID
                              : "--"}
                          </Typography>
                        </CustomFormGrid>
                        <CustomFormGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: 400,
                            }}
                            className={classes.linkingCardText}
                          >
                            {translation("vehicle.form.model.label") +
                              " " +
                              (item?.vehicle?.model
                                ? "#" + item?.vehicle?.model
                                : "--") +
                              " " +
                              translation("vehicle.form.VIN.label") +
                              " " +
                              (item?.vehicle?.VIN
                                ? "#" + item?.vehicle?.VIN
                                : "--")}
                          </Typography>
                        </CustomFormGrid>
                      </CustomFormGrid>
                    </CustomFormGrid>
                    <CustomFormGrid
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{ textAlign: "center", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={() => {
                          handleDelete(item?.vehicle);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CustomFormGrid>
                  </>
                </CustomFormGrid>
                <Divider variant="middle" />
              </>
            ))
          ) : (
            <CustomFormGrid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ width: "100%", height: "85px" }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  textAlign: "center",
                  paddingTop: "30px",
                }}
                className={classes.linkingCardText}
              >
                No Assigned Vehicle
              </Typography>
            </CustomFormGrid>
          )}
        </Paper>
      </CustomFormGrid>
    </CustomFormGrid>
  );
};

export default VehicleCard;
