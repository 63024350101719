import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { UserProps } from "src/interfaces/user";

export interface UserState {
  usersList: any;
  singleUser: UserProps | null;
}

const initialState: UserState = {
  usersList: {},
  singleUser: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,

  reducers: {
    setAllUsers: (state, action: PayloadAction<Array<UserProps>>) => {
      state.usersList = action.payload;
    },
    setSingleUser: (state, action: PayloadAction<UserProps>) => {
      state.singleUser = action.payload;
    },
    resetUser: (state) => {
      state.singleUser = null;
    },
    resetUserState: (state) => {
      state.singleUser = null;
      state.usersList = [];
    },
    updateUserList: (state, action: PayloadAction<Array<UserProps>>) => {
      state.usersList = action.payload;
    },
  },
});

export const {
  setAllUsers,
  setSingleUser,
  resetUser,
  resetUserState,
  updateUserList,
} = usersSlice.actions;
export const selectUsers = (state: RootState):  any =>
  state.users.usersList;
export const selectUser = (state: RootState): UserProps | null =>
  state.users.singleUser;

export default usersSlice.reducer;
