import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ResetPassword from "src/components/resetPassword";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { resetPassword } from "src/redux/actions/auth";
import { useQuery } from "src/hooks/useQuery";
import { NotificationContext } from "src/utils/context";

const ResetPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const useNotification = useContext(NotificationContext);
  /* ResetPasswordProps */
  const onSubmit = async (data: any) => {
    if (query.get("token")) {
      setLoading(true);
      const response = await dispatch(
        resetPassword(data, query.get("token") as string)
      );
      if (response.success) {
        response.data &&
          setTimeout(
            () => navigate(`/auth/login/${response?.data?.user?.tenant?.name}`),
            500
          );
      } else {
        setLoading(false);
      }
    } else {
      useNotification({
        open: true,
        message: "Token is required!",
        severity: "error",
      });
    }
  };

  return (
    <>
      <ResetPassword onSubmit={onSubmit} loading={loading} />
    </>
  );
};

export default ResetPasswordContainer;
