import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { TrailerSafetyProps } from "src/interfaces/trailer";

export interface TrailerSafetyState {
  TrailersSafetyList: Array<TrailerSafetyProps>;
  singleTrailerSafety: TrailerSafetyProps | null;
}

const initialState: TrailerSafetyState = {
  TrailersSafetyList: [],
  singleTrailerSafety: null,
};

export const trailersSafetySlice = createSlice({
  name: "trailersSafety",
  initialState,

  reducers: {
    setAllTrailersSafety: (
      state,
      action: PayloadAction<Array<TrailerSafetyProps>>
    ) => {
      state.TrailersSafetyList = action.payload;
    },
    setSingleTrailerSafety: (
      state,
      action: PayloadAction<TrailerSafetyProps>
    ) => {
      state.singleTrailerSafety = action.payload;
    },
    resetTrailerSafety: (state) => {
      state.singleTrailerSafety = null;
    },
    resetTrailerSafetyState: (state) => {
      state.singleTrailerSafety = null;
      state.TrailersSafetyList = [];
    },
  },
});
export const {
  setAllTrailersSafety,
  setSingleTrailerSafety,
  resetTrailerSafety,
  resetTrailerSafetyState,
} = trailersSafetySlice.actions;
export const selectTrailersSafety = (
  state: RootState
): Array<TrailerSafetyProps> => state.trailerSafety.TrailersSafetyList;
export const selectTrailerSafety = (
  state: RootState
): TrailerSafetyProps | null => state.trailerSafety.singleTrailerSafety;
export default trailersSafetySlice.reducer;
