import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllOwners } from "src/redux/actions/owner";
import { selectOwners } from "src/redux/reducers/owner";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureOwners, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { Clipboard } from "ts-react-feather-icons";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toNumber } from "lodash";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Owners() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const owners = useAppSelector(selectOwners);
  const [translation] = useTranslation("translation");
  const [ownerData, setOwnerData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const ownerPermissions =
    allUserPermissions && allUserPermissions[featureOwners];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === update);

  const getOwnersList = async () => {
    setLoading(true);
    await dispatch(
      getAllOwners(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getOwnersListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllOwners(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };

  /* function pageExists(page: any) {
    return ownerData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getOwnersListWithFilter();
    } else {
      getOwnersList();
    }
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    getOwnersListWithFilter();
  }, [filter]);
  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (owners?.data?.length > 0) {
      setOwnerData({ ...ownerData?.owners, owners });
    } else {
      setOwnerData({});
    }
  }, [owners]);
  const addOwner = () => {
    navigate("/drivers/owners/create");
  };
  const searchOwner = () => {
    navigate("/drivers/owners/search");
  };
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/drivers/owners/${params.row.id}`);
          }}
        />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/drivers/owners/documents/${params.row.id}?entity=${featureOwners}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="details"
          key="details"
          onClick={() => {
            navigateToOwnerDetail(params.row.id);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "firstName",
      headerName: translation("common.firstName.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "lastName",
      headerName: translation("common.lastName.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "email",
      headerName: translation("common.email.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "phone",
      headerName: translation("common.phone.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "altPhone",
      headerName: translation("owner.altPhone.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "faxPhone",
      headerName: translation("owner.faxPhone.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "companyName",
      headerName: translation("owner.company.label"),
      minWidth: 150,
      filterOperators,
    },

    {
      field: "address",
      headerName: translation("common.address.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "city",
      headerName: translation("common.city.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "state",
      headerName: translation("common.state.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "zip",
      headerName: translation("common.zip.label"),
      minWidth: 50,
      filterable: false,
    },
  ];
  const onRowClick = (params: GridRowParams) => {
    navigateToOwnerDetail(params.row.id);
  };
  const navigateToOwnerDetail = (id: number) => {
    navigate(
      `/drivers/owners/details/${id}?entity=${featureOwners}&referenceId=${id}`
    );
  };

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("owner.list.title")}
        rows={ownerData?.owners?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addOwner}
        onClickSearch={searchOwner}
        createPermission={createPermission > -1}
        rowsState={rowsState}
        onRowClick={onRowClick}
        setRowsState={setRowsState}
        pagination={owners.pagination}
        data={ownerData?.owners}
        setData={setOwnerData}
        add={true}
        setFilter={setFilter}
        search={"owner"}
      />
    </CustomFormGrid>
  );
}
