import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { CommodityProps } from "src/interfaces/commodity";


export interface CommodityState {
  CommoditiesList: any;
  singleCommodity: CommodityProps | null;
}

const initialState: CommodityState = {
  CommoditiesList: {},
  singleCommodity: null,
};

export const commoditiesSlice = createSlice({
  name: "commodity",
  initialState,

  reducers: {
    setAllCommodities: (
      state,
      action: PayloadAction<Array<CommodityProps>>
    ) => {
      state.CommoditiesList = action.payload;
    },
    setSingleCommodity: (state, action: PayloadAction<CommodityProps>) => {
      state.singleCommodity = action.payload;
    },
    resetCommodity: (state) => {
      state.singleCommodity = null;
    },
    resetCommodityState: (state) => {
      state.singleCommodity = null;
      state.CommoditiesList = [];
    },
  },
});
export const {
  setAllCommodities,
  setSingleCommodity,
  resetCommodity,
  resetCommodityState,
} = commoditiesSlice.actions;
export const selectCommodities = (state: RootState):any =>
  state.commodity.CommoditiesList;
export const selectCommodity = (state: RootState): CommodityProps | null =>
  state.commodity.singleCommodity;
export default commoditiesSlice.reducer;
