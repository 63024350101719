import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/index";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllUsers } from "src/redux/actions/users";
import { selectUsers } from "src/redux/reducers/users";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureUsers, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import { Clipboard } from "ts-react-feather-icons";
import CustomFormGrid from "src/components/customElements/customFormGrid";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Users() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const users = useAppSelector(selectUsers);
  const classes = useStyles();
  const user = useAppSelector(selectUser);
  const [userData, setUserData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const userPermissions =
    allUserPermissions && allUserPermissions[featureUsers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : userPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : userPermissions?.findIndex((item) => item === update);

  const getUsersList = async () => {
    setLoading(true);
    await dispatch(
      getAllUsers(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getUsersListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllUsers(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };

  /*   function pageExists(page: any) {
    return userData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getUsersListWithFilter();
    } else getUsersList();
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    getUsersListWithFilter();
  }, [filter]);

  useEffect(() => {
    //if (pageExists(rowsState?.page + 1) == false)
    if (users?.data?.length > 0) {
      setUserData({ ...userData?.users, users });
    } else {
      setUserData({});
    }
  }, [users]);

  const addUser = () => {
    navigate("/administration/users/create");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/administration/users/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="Details"
          key="details"
          onClick={() => {
            navigate(`/administration/users/details/${params.row.id}`);
            // navigate(`/drivers/drivers/details/${params.row.id}`);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterable: false,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 100,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "role",
      headerName: translation("common.role.label"),
      minWidth: 150,
      filterable: false,

      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.role?.name}</div>;
      },
    },
    {
      field: "firstName",
      headerName: translation("common.firstName.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "lastName",
      headerName: translation("common.lastName.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "email",
      headerName: translation("common.email.label"),
      flex: 1,
      minWidth: 250,
      filterOperators,
    },
    {
      field: "phoneNumber",
      headerName: translation("common.phone.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "altPhoneNumber",
      headerName: translation("owner.altPhone.label"),
      minWidth: 150,
      filterable: false,
    },
  ];

  const onRowClick = (params: GridRowParams) => {
    navigate(params.row.id);
  };
  const searchUsers = () => {
    navigate("/administration/users/search");
  };

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("users.list.title")}
        rows={userData?.users?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addUser}
        createPermission={createPermission > -1}
        rowsState={rowsState}
        onRowClick={onRowClick}
        onClickSearch={searchUsers}
        setRowsState={setRowsState}
        pagination={users?.pagination}
        data={userData?.users}
        setData={setUserData}
        add={true}
        setFilter={setFilter}
        search={"user"}
      />
    </CustomFormGrid>
  );
}
