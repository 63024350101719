import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toNumber } from "lodash";
import DataTable from "src/components/dataTable";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { Clipboard } from "ts-react-feather-icons";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { featureOwners, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { dateTimeFormat } from "src/utils/constants";
import { statusTypes } from "src/utils/dropdowns/common";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectLookups } from "src/redux/reducers/lookup";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import { getAllTerminals } from "src/redux/actions/terminal";
import Tooltip from "@mui/material/Tooltip";
import { LookupProps } from "src/interfaces/lookup";
import { featureTerminals } from "src/utils/enums";
import { LoadStopProps } from "src/interfaces/loadManagement";
import CustomTextField from "src/components/customElements/customTextField";
import { getSearchTablesData } from "src/redux/actions/search";
import { commissionPrograms } from "src/utils/dropdowns/terminals";
import { InputLabel } from "@mui/material";

interface LoadSummaryProps {
  pageLoading: boolean;
  delayForClear: any;
}
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const TerminalSearchForm = ({
  pageLoading,
  delayForClear,
}: LoadSummaryProps): JSX.Element => {
  const params = useParams();
  const boxTheme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const [filter, setFilter] = useState<any>({});
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [dateOpened, setDateOpened] = React.useState<any | null>(null);
  const [dateClosed, setDateClosed] = React.useState<any | null>(null);
  const [addedByData, setAddedByData] = React.useState<any | null>([]);
  const [terminalData, setTerminalData] = React.useState<any | null>([]);
  const [loading, setLoading] = useState(false);
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const user = useAppSelector(selectUser);
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);
  const loadData = useAppSelector(selectLoadManagement);
  const pickupDateTimeRef = React.useRef<any>();
  const pickupDateTimeEndRef = React.useRef<any>();
  const [deliveryStart, setDeliveryStart] = React.useState<any | null>(null);
  const [deliveryEnd, setDeliveryEnd] = React.useState<any | null>(null);
  const [terminalSearchedData, setTerminalSearchedData] = useState<any>({});
  const [displayTable, setdisplayTable] = useState<boolean>(false);

  const selectPickupDateTimeRef = () => {
    if (pickupDateTimeRef?.current) {
      pickupDateTimeRef?.current?.click();
    }
  };
  const selectPickupDateEndTimeRef = () => {
    if (pickupDateTimeEndRef?.current) {
      pickupDateTimeEndRef?.current?.click();
    }
  };
  // const [, forceUpdate] = React.useState();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  let timer: any;

  const terminalTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["terminal-type"]?.lookups
      ? allLookups?.data["terminal-type"]?.lookups
      : [];

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const ownerPermissions =
    allUserPermissions && allUserPermissions[featureOwners];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === update);
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={<Edit />}
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/terminals/terminals/${params.row.id}`);
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<Clipboard />}
        //   label="Details"
        //   key="details"
        //   onClick={() => {
        //     navigate(`/terminals/terminals/details/${params.row.id}`);
        //     // navigate(`/drivers/drivers/details/${params.row.id}`);
        //   }}
        // />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 50,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,

      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "title",
      headerName: translation("terminals.form.name.label"),
      minWidth: 150,
    },
    {
      field: "terminalCode",
      headerName: translation("terminals.form.terminalCode.label"),
      minWidth: 50,
    },
    {
      field: "type",
      headerName: translation("terminals.form.type.label"),
      minWidth: 100,

      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.type?.content}</div>;
      },
    },
    {
      field: "phoneNumber",
      headerName: translation("common.phone.label"),
      minWidth: 150,
    },
    {
      field: "faxPhoneNumber",
      headerName: translation("common.faxPhoneNumber.label"),
      minWidth: 150,
    },

    {
      field: "address",
      headerName: translation("common.address.label"),
      minWidth: 150,
    },
    {
      field: "city",
      headerName: translation("common.city.label"),
      minWidth: 100,
    },
    {
      field: "state",
      headerName: translation("common.state.label"),
      minWidth: 100,
    },

    {
      field: "zip",
      headerName: translation("common.zip.label"),
      minWidth: 100,
    },
  ];

  useEffect(() => {
    if (dateOpened != null && dateClosed != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["dateOpened"] = {
        value: dateOpened.toISOString() + "," + dateClosed.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [dateClosed, dateOpened]);
  useEffect(() => {
    if (deliveryStart != null && deliveryEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["deliveryDateTime"] = {
        value: deliveryStart.toISOString() + "," + deliveryEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [deliveryStart, deliveryEnd]);

  useEffect(() => {
    {
      Object.keys(filter).length !== 0 && getTerminalsListWithFilter();
    }
  }, [rowsState.pageSize, rowsState.page]);

  const getTerminalsListWithFilter = async () => {
    setLoading(true);

      await dispatch(
        getAllTerminals(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ).then((response:any) => {
        setTerminalSearchedData(response?.data);
        setdisplayTable(true);

      });
    setLoading(false);
  };
  const handleChangeAutoComplete = async (
    event: any,
    newValue: any,
    setData: any,
    table: string,
    column: string
  ) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getSearchTablesData(table, column, event)).then((res:any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setData(res?.data);
        }
      });
    }
  };

  const submitSearch = async () => {
    setSearchLoading(true);
    await getTerminalsListWithFilter();
    setSearchLoading(false);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("search.form.search.terminal")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              className={classes.formButtonDiscard}
              onClick={() => {
                delayForClear(true);
              }}
            >
              {translation("search.form.clear")}
            </Button>

            <Button
              variant="contained"
              // type="submit"
              onClick={submitSearch}
              className={classes.formButtonSave}
            >
              {searchLoading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                translation("search.form.search")
              )}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
        {/* //1st row */}

        <CustomFormGrid container style={{ paddingTop: "30px" }}>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="title"
              size="small"
              fullWidth
              value={filter["title"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["title"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["title"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("terminals.form.name.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="terminalCode"
              size="small"
              fullWidth
              value={filter["terminalCode"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["terminalCode"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["terminalCode"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("terminals.form.terminalCode.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="type"
              label={translation("vehicle.form.type.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                console.log(event.target.value, "newValue");

                if (event.target.value != null) {
                  temp["type"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["type"];
                }
                setFilter(temp);
              }}
              value={filter["type"]?.value}
            >
              {terminalTypes &&
                terminalTypes.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="status"
              label={translation("common.status.label")}
              select
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["status"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["status"];
                }
                setFilter(temp);
              }}
              value={filter["status"]?.value}
            >
              {statusTypes &&
                statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="commissionProgram"
              label={translation("terminals.form.commissionProgram.label")}
              select
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["commissionProgram"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["commissionProgram"];
                }
                setFilter(temp);
              }}
              value={filter["commissionProgram"]?.value}
            >
              {commissionPrograms &&
                commissionPrograms.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="terminalTruckCommission"
              size="small"
              fullWidth
              value={filter["terminalTruckCommission"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["terminalTruckCommission"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["terminalTruckCommission"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation(
                "terminals.form.terminalTruckCommission.label"
              )}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="houseCommission"
              size="small"
              fullWidth
              value={filter["houseCommission"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["houseCommission"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["houseCommission"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("terminals.form.houseCommission.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>

          <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="city"
              size="small"
              fullWidth
              value={filter["city"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["city"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["city"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.city.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="state"
              size="small"
              fullWidth
              value={filter["state"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["state"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["state"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("common.state.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
            <Controller
              name="dateOpened"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDateOpened(newValue);
                  }}
                  value={dateOpened}
                  label={translation("terminals.form.dateOpened.label")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="dateOpened"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>To</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
            <Controller
              name="dateClosed"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDateClosed(newValue);
                  }}
                  value={dateClosed}
                  label={translation("terminals.form.dateClosed.label")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="dateClosed"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>

        {terminalSearchedData?.data?.length > 0 && loading ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "30px" }}
          >
            <CircularProgress />
          </CustomFormGrid>
        ) : terminalSearchedData?.data?.length > 0 ? (
          <>
            <CustomFormGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "30px" }}
            >
              <Typography className={classes.loadFormHeadings}>
                {translation("search.form.search.terminal.result")}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                title={translation("owner.list.title")}
                rows={terminalSearchedData?.data}
                columns={columns}
                loading={loading}
                createPermission={createPermission > -1}
                rowsState={rowsState}
                // onRowClick={onRowClick}
                setRowsState={setRowsState}
                pagination={terminalSearchedData.pagination}
                data={terminalSearchedData?.data}
                setData={setTerminalSearchedData}
                add={true}
                hideBackBar={true}
              />
            </CustomFormGrid>
          </>
        ) : displayTable === true ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "50px" }}
          >
            <Typography variant="h5">No search result found</Typography>
          </CustomFormGrid>
        ) : (
          ""
        )}
      </CustomFormGrid>
    </LocalizationProvider>
  );
};

export default TerminalSearchForm;
