import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Collapsible from "src/components/collapsibleForm";
import VehicleInformation from "./vehicleInfo";
import SafetyInfo from "./safety";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import AssignedTrailerInfo from "src/containers/vehicle/vehicleTrailerLinking";
import { selectVehicle } from "src/redux/reducers/vehicle";
import { useAppSelector } from "src/hooks/reduxHooks";
import { ArrowBack } from "@mui/icons-material";
import VehicleDocuments from "src/containers/documents";
import { typedKeys } from "src/utils/common";
import { VehicleSafetyProps } from "src/interfaces/vehicle";
import { VehicleProps } from "src/interfaces/vehicle";
import { selectUser } from "src/redux/reducers/auth";
import AssignedDriverInfo from "src/containers/vehicle/driverLinking";
import VehicleOwnerInfo from "src/containers/vehicle/vehicleOwnerLinking";

interface VehicleFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: VehicleProps) => any; */
  onSubmit: (data: any) => any;
  // onSafetySubmit: (data: any) => any;
}
const VehicleForm = ({
  loading,
  pageLoading,
  onSubmit,
}: // onSafetySubmit,
VehicleFormProps): JSX.Element => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [translation] = useTranslation("translation");
  const params = useParams();
  const vehicle = useAppSelector(selectVehicle);
  const user = useAppSelector(selectUser);

  const linkingAddress = location.pathname.includes("details");
  const [disabled, setDisabled] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "vehicleInfoPanel",
    "safetyInfoPanel",
    "trailerInfoTable",
    "vehicleOwner",
    "vehicleDocuments",
    "driverVehicle",
  ]);
  useEffect(() => {
    if (linkingAddress) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAll();
    }
  }, [Object.keys(errors)]);
  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAll = () => {
    setExpandAllPanels(true);
    const _expandedPanels = [
      "vehicleInfoPanel",
      "safetyInfoPanel",
      "trailerInfoTable",
      "vehicleOwner",
      "vehicleDocuments",
      "driverVehicle",
    ];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAll = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };

  const setVehicleSafetyFormValues = (
    object: VehicleSafetyProps,
    name: keyof VehicleProps
  ) => {
    const allAttributes = typedKeys(object);
    for (const attribute of allAttributes) {
      if (typeof object[attribute] === "boolean") {
        setValue(`${name}.${attribute}`, object[attribute]);
      } else {
        setValue(
          `${name}.${attribute}`,
          object[attribute] ? object[attribute] : ""
        );
      }
    }
  };

  const setFormValues = (key: keyof VehicleProps) => {
    if (vehicle) {
      if (typeof vehicle[key] === "boolean") {
        setValue(key, vehicle[key]);
      } else {
        setValue(key, vehicle[key] ? vehicle[key] : "");
      }
    }
  };

  useEffect(() => {
    if (!params?.vehicleId) {
      setValue(
        "terminal",
        user?.tenant?.defaultTerminal ? user?.tenant?.defaultTerminal?.id : ""
      );
      setValue(
        "owner",
        user?.tenant?.defaultOwner ? user?.tenant?.defaultOwner?.id : ""
      );
    }
  }, []);

  useEffect(() => {
    if (params?.vehicleId && vehicle) {
      const allAttributes = typedKeys(vehicle);

      for (const attribute of allAttributes) {
        if (attribute === "make") {
          if (typeof vehicle?.make === "object") {
            setValue("make", vehicle?.make?.id ? vehicle?.make?.id : "");
          } else {
            setValue("make", vehicle?.make ? vehicle?.make : "");
          }
        } else if (attribute === "color") {
          if (typeof vehicle?.color === "object") {
            setValue("color", vehicle?.color?.id ? vehicle?.color?.id : "");
          } else {
            setValue("color", vehicle?.color ? vehicle?.color : "");
          }
        } else if (attribute === "type") {
          if (typeof vehicle?.type === "object") {
            setValue("type", vehicle?.type?.id ? vehicle?.type?.id : "");
          } else {
            setValue("type", vehicle?.type ? vehicle?.type : "");
          }
        } else if (attribute === "owner") {
          if (typeof vehicle?.owner === "object") {
            setValue("owner", vehicle?.owner?.id ? vehicle?.owner?.id : "");
          } else {
            setValue("owner", vehicle?.owner ? vehicle?.owner : "");
          }
        } else if (attribute === "terminal") {
          if (typeof vehicle?.terminal === "object") {
            setValue(
              "terminal",
              vehicle?.terminal?.id ? vehicle?.terminal?.id : ""
            );
          } else {
            setValue("terminal", vehicle?.terminal ? vehicle?.terminal : "");
          }
        } else if (attribute === "vehicleSafety") {
          setVehicleSafetyFormValues(vehicle?.vehicleSafety, attribute);
        } else {
          setFormValues(attribute);
        }
      }
    }
  }, [params?.vehicleId, vehicle]);
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("vehicle.form.vehicleInformation.label")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : params?.vehicleId ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() => navigate(-1)}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>

      {pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomForm noValidate>
            <CustomFormGrid container>
              {/*****************Column 1 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={12} md={12}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("vehicle.form.vehicleInformation.label")}
                      </Typography>
                      <VehicleInformation
                        //  onSubmit={onSubmit}
                        disabled={disabled}
                        control={control}
                        errors={errors}
                        watch={watch}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("vehicle.form.safetyInformation.label")}
                      </Typography>
                      <SafetyInfo
                        disabled={disabled}
                        control={control}
                        errors={errors}
                        vehicle={vehicle}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomForm>

          {/*  <CustomFormGrid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ padding: "0px", margin: "0px" }}
                >
                  {linkingAddress && (
                    <Collapsible
                      title={translation("vehicle.documents.title")}
                      handleChange={handleChange}
                      expanded={expandedPanels.includes("vehicleDocuments")}
                      id="vehicleDocuments"
                      ComponentToRender={<VehicleDocuments />}
                    />
                  )}
                </CustomFormGrid>
                <CustomFormGrid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ padding: "0px", margin: "0px" }}
                >
                  {vehicle?.hasTrailer && linkingAddress && (
                    <Collapsible
                      title={translation("trailerInfoTable.list.title")}
                      handleChange={handleChange}
                      expanded={expandedPanels.includes("trailerInfoTable")}
                      id="trailerInfoTable"
                      ComponentToRender={<AssignedTrailerInfo />}
                    />
                  )}
                </CustomFormGrid>

                <CustomFormGrid
                  sx={{ padding: "0px", margin: "0px" }}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {linkingAddress && (
                    <>
                      <Collapsible
                        title={translation("vehicleDriver.list.title")}
                        handleChange={handleChange}
                        expanded={expandedPanels.includes("driverVehicle")}
                        id="driverVehicle"
                        ComponentToRender={<AssignedDriverInfo />}
                      />
                     <Collapsible
                        title={translation("trailerOwner.list.title")}
                        handleChange={handleChange}
                        expanded={expandedPanels.includes("vehicleOwner")}
                        id="vehicleOwner"
                        ComponentToRender={<VehicleOwnerInfo />}
                      />
                    </>
                  )}
                </CustomFormGrid> */}
        </LocalizationProvider>
      )}
    </div>
  );
};

export default VehicleForm;
