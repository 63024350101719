import React, { useEffect, useState } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { useStyles } from "src/styles/formStyles";
import { phone } from "phone";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import { selectLoadAddresses } from "src/redux/reducers/loadAddress";
import { getAllLoadAddresses } from "src/redux/actions/loadAddress";
import AddCommonDialog from "src/components/common/addCommonDialog";
import { Box, Button, Autocomplete, MenuItem } from "@mui/material";
import { useAppDispatch } from "src/hooks/reduxHooks";
import CustomerForm from "src/containers/customers/form";
import { getAllCustomersByType } from "src/redux/actions/customer";
import { selectCustomers } from "src/redux/reducers/customer";
import { selectStates, selectCity, selectCities } from "src/redux/reducers/shared";
import { getStates, getCityByZipCode, getCitiesByState } from "src/redux/actions/shared";
import { City, State } from "src/interfaces/shared";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { BillToInformation } from "src/interfaces/loadManagement";
import { selectLookups } from "src/redux/reducers/lookup";
import { getAllLookups } from "src/redux/actions/lookup";

import { LookupProps } from "src/interfaces/lookup";
import { CustomerProps } from "src/interfaces/customer";

const customerAutoCompleteFilter = createFilterOptions<CustomerAutoCompleteProps>();

interface CustomerAutoCompleteProps {
    inputValue?: string;
    companyName: string;
}

interface BillingAddressProps {
    errors: any,
    control: any,
    setValue: any,
    watch: any
    data?: BillToInformation | null
}

const BillingAddress = ({
    errors,
    control,
    setValue,
    watch,
    data
}: BillingAddressProps): JSX.Element => {

    const [openAddCustomerModal, setOpenAddCustomerModal] = useState<boolean>(false);
    const [showCustomerAllFields, setShowCustomerAllFields] = useState<boolean>(false);
    const [selectedCustomerType, selectCustomerType] = useState<any>("");

    const loadAddresses = useAppSelector(selectLoadAddresses);
    const usStates = useAppSelector(selectStates);
    const usCities = useAppSelector(selectCities);
    let city = useAppSelector(selectCity);
    const allLookups = useAppSelector(selectLookups);
    const customers = useAppSelector(selectCustomers);

    const dispatch = useAppDispatch();
    const classes = useStyles();
    const [translation] = useTranslation("translation");

    useEffect(() => {
        dispatch(getStates());
        dispatch(getAllLookups());
        dispatch(getAllLoadAddresses());
    }, [])

    const onChangePhoneInput = (value: string, country: string, key: string) => {
        setValue(`${`billToInformation`}.${key}`, formattedPhoneNumber(value, country));
        setValue(`${`billToInformation`}.${key}Value`, value);
        setValue(`${`billToInformation`}.${key}Country`, country);
        if (errors && errors[`billToInformation`]) {
            delete errors[`billToInformation`][key];
        }
    };

    const handleOpenAddCustomerModal = () => {
        setOpenAddCustomerModal(true);
        setShowCustomerAllFields(true);
    };
    const handleCloseAddCustomerModal = () => {
        setOpenAddCustomerModal(false);
        dispatch(getAllCustomersByType(selectedCustomerType));
    };

    const handleZipChange = async (zipCode: string) => {

        if (zipCode.length >= 5) {
            console.log("Loading city ==========")
            const foundCity = await dispatch(getCityByZipCode(zipCode));
            city = foundCity.data
            if (city) {
                console.log("Filtering state ==========")
                const selectedState = usStates.filter(e => e.abbreviation == city?.state);
                if (selectedState && selectedState.length > 0) {
                    setValue(`billToInformation.state`, selectedState[0]);
                    await dispatch(getCitiesByState(selectedState[0].abbreviation));
                    setValue(`billToInformation.city`, city);
                }
            }
        }
    }

    const handleStateChange = async (stateObj: State) => {
        await dispatch(getCitiesByState(stateObj.abbreviation));
    }

    const handleCityChange = async (cityObj: City) => {
        if (cityObj && cityObj.zip_code) setValue('billToInformation.zip', cityObj.zip_code);
    }

    const handleCustomerTypeChange = (type: any) => {
        dispatch(getAllCustomersByType(type));
        selectCustomerType(type)
    }

    const handleCustomerChange = (value: CustomerProps) => {
        setValue("billToInformation.address", value?.address || "");
        setValue("billToInformation.zip", value?.zip || "");
        if (value?.zip) handleZipChange(value.zip);
        setValue("billToInformation.company", value?.companyName || "");
        setValue("billToInformation.phoneNumber", value?.phoneNumber || "");
        setValue("billToInformation.email", value?.email || "");

    }

    const customerTypes: Array<LookupProps> = allLookups?.data?.["customer-type"]
        ?.lookups
        ? allLookups?.data?.["customer-type"]?.lookups
        : [];

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
                <Button variant="contained" onClick={() => { handleOpenAddCustomerModal() }}>Add Customer</Button>
            </Box>
            {/* <form noValidate onSubmit={handleSubmit(onSubmit)}> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                    name={`billToInformation.company`}
                    control={control}
                    defaultValue={data?.company || ""}
                    rules={{
                        required: true
                    }}
                    render={({ field }) => (
                        <input type='hidden' {...field}/>
                    )}
                />

                <CustomFormGrid container spacing={2}>
                    <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
                        <Controller
                            name="billToInformation.customerType"
                            control={control}
                            defaultValue={data?.customerType || ""}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, ...restFields} }) => (
                                <CustomTextField
                                    {...restFields}
                                    size="small"
                                    fullWidth
                                    id="customerType"
                                    select
                                    label={"Customer Type"}
                                    error={Boolean(errors?.customerType)}
                                    helperText={
                                        errors?.customerType &&
                                        "Customer Type is required"
                                    }
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onChange(event.target.value);
                                        handleCustomerTypeChange(event.target.value);
                                    }}
                                >
                                    {customerTypes &&
                                        customerTypes.map((item, index) => (
                                            <MenuItem value={item.id} key={index}>
                                                {item.content}
                                            </MenuItem>
                                        ))}
                                </CustomTextField>
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
                        <Controller
                            name={`billToInformation.customer`}
                            defaultValue={data && data.customer ? data.customer : ""}
                            control={control}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    id="customer"
                                    value={value}
                                    fullWidth
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        onChange(newValue);
                                        handleCustomerChange(newValue);
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={customers}
                                    getOptionLabel={(option) => option.companyName || ""}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            id="customer-txt"
                                            size="small"
                                            label={"Customer Name"}
                                            error={Boolean(errors?.billToInformation?.customer)}
                                            helperText={
                                                errors?.billToInformation?.customer &&
                                                "Customer is required"
                                            }
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
                        <Controller
                            name={`billToInformation.hours`}
                            control={control}
                            defaultValue={data?.hours}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    label={"Hours"}
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                        <Controller
                            name={`billToInformation.address`}
                            control={control}
                            defaultValue={data?.address || ""}
                            rules={{
                                required: true
                            }}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    label={"Address"}
                                    error={Boolean(errors[`billToInformation`]?.address)}
                                    helperText={
                                        errors?.billToInformation?.address &&
                                        "Address is required"
                                    }
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
                        <Controller
                            name={`billToInformation.zip`}
                            control={control}
                            defaultValue={data?.zip || ""}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, ...restFields } }) => (
                                <CustomTextField
                                    {...restFields}
                                    size="small"
                                    fullWidth
                                    onChange={(event: any) => {
                                        onChange(event.target.value)
                                        handleZipChange(event.target.value);
                                    }}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                    label={"Zip"}
                                    error={Boolean(errors[`billToInformation`]?.zip)}
                                    helperText={
                                        errors?.billToInformation?.zip &&
                                        "Zip is required"
                                    }
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
                        <Controller
                            name={`billToInformation.state`}
                            control={control}
                            defaultValue={data && data.state ? data.state : ""}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    id="state"
                                    fullWidth
                                    options={usStates || []}
                                    onChange={(event, inputValue) => {
                                        onChange(inputValue);
                                        handleStateChange(inputValue);
                                    }}
                                    value={value}
                                    getOptionLabel={(option) => {
                                        return option ? `${option.abbreviation} - ${option.name}` : '';
                                    }}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            id="txt-state"
                                            size="small"
                                            label={`State`}
                                            onChange={onChange}
                                            error={Boolean(errors?.billToInformation?.state)}
                                            helperText={
                                                errors?.billToInformation?.state &&
                                                "State is required"
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
                        <Controller
                            name={`billToInformation.city`}
                            control={control}
                            defaultValue={data && data.city ? data.city : ""}
                            rules={{
                                required: true
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    id="city"
                                    fullWidth
                                    options={usCities || []}
                                    onChange={(event, inputValue) => {
                                        onChange(inputValue);
                                        handleCityChange(inputValue);
                                    }}
                                    value={value}
                                    getOptionLabel={(option) => {
                                        return option ? `${option.city} (${option.zip_code})` : '';
                                    }}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            id="txt-city"
                                            size="small"
                                            label={`City`}
                                            onChange={onChange}
                                            error={Boolean(errors?.billToInformation?.city)}
                                            helperText={
                                                errors?.billToInformation?.state &&
                                                "City is required"
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={6} xs={12} md={6} lg={6} className={classes.phoneField} >
                        <Controller
                            name={`billToInformation.phoneNumber`}
                            control={control}
                            defaultValue={data?.phoneNumber}
                            rules={{
                                validate: {
                                    isValid: () => {
                                        if (watch(`billToInformation.phoneNumber`)) {
                                            return !phone("+" + watch(`billToInformation.phoneNumber`)).isValid ? "Enter valid phone number" : true
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            }}
                            render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    label={translation("common.phone.label")}
                                    placeholder={translation("common.phone.label")}
                                    value={value}
                                    error={Boolean(errors[`billToInformation`]?.phoneNumber)}
                                    onChange={onChange}
                                    country="us"
                                    isValid={(value: any, country: any) => {
                                        return errors[`billToInformation`]?.phoneNumber
                                            ? validatePhoneNumber(value, country.iso2)
                                            : true;
                                    }}
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={6} xs={12} md={6} lg={6} className={classes.phoneField} >
                        <Controller
                            name={`billToInformation.faxPhoneNumber`}
                            control={control}
                            defaultValue={data?.faxPhoneNumber}
                            rules={{
                                validate: {
                                    isValid: () => {
                                        if (watch(`billToInformation.faxPhoneNumber`)) {
                                            return !phone("+" + watch(`billToInformation.faxPhoneNumber`)).isValid ? "Enter valid phone number" : true
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            }}
                            render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                    label={translation("common.faxPhoneNumber.label")}
                                    placeholder={translation("common.faxPhoneNumber.label")}
                                    value={value}
                                    error={Boolean(errors[`billToInformation`]?.faxPhoneNumber)}
                                    onChange={onChange}
                                    country="us"
                                    isValid={(value: any, country: any) => {
                                        return errors[`billToInformation`]?.faxPhoneNumber
                                            ? validatePhoneNumber(value, country.iso2)
                                            : true;
                                    }}
                                />
                            )}
                        />
                    </CustomFormGrid>

                    <CustomFormGrid item sm={6} xs={12} md={12} lg={12}>
                        <Controller
                            name={`billToInformation.email`}
                            control={control}
                            defaultValue={data?.email}
                            render={({ field }) => (
                                <CustomTextField
                                    {...field}
                                    size="small"
                                    fullWidth
                                    id="email"
                                    label={"Email"}
                                />
                            )}
                        />
                    </CustomFormGrid>
                </CustomFormGrid>
            </LocalizationProvider>

            {/* </form> */}
            <AddCommonDialog
                handleClose={handleCloseAddCustomerModal}
                open={openAddCustomerModal}
                title="Add Customer"
                ComponentToRender={
                    <CustomerForm handleClose={handleCloseAddCustomerModal} />
                }
            />
        </React.Fragment>
    );
};
export default BillingAddress;
