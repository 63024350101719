import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import OwnerReadContainer from "src/containers/owner/ownerReadContainer";
const OwnersReadForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Owners" description="Porto Owners" />
      <OwnerReadContainer />
    </Grid>
  );
};

export default OwnersReadForm;
