import React from "react";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { statusTypes } from "src/utils/dropdowns/common";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import AddLookupField from "src/components/common/addLookupFeild";
import { featureTrailers } from "src/utils/enums";

interface TrailerInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled: boolean;
  trailer: any;
  trailerId: any;
}
const TrailerInfo = ({
  disabled,
  control,
  errors,
  trailer,
  trailerId,
}: TrailerInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  const allLookups = useAppSelector(selectLookups);

  const allTrailerTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["trailer-type"]?.lookups
      ? allLookups?.data["trailer-type"]?.lookups
      : [];

  const allTrailerMake: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["trailer-make"]?.lookups
      ? allLookups?.data["trailer-make"]?.lookups
      : [];

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid container>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="trailerId"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="trailerId"
                  label={translation("vehicle.form.trailerId.label")}
                  error={Boolean(errors?.trailerId)}
                  helperText={
                    errors?.trailerId &&
                    translation("vehicle.form.trailerId.message")
                  }
                  required
                />
              )}
            />
          </CustomFormGrid>
          {/* <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="category"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    disabled={disabled}

                    fullWidth
                    id="category"
                    select
                    label={translation("vehicle.form.category.label")}
                    error={Boolean(errors?.category)}
                    helperText={
                      errors?.category &&
                      translation("vehicle.form.category.message")
                    }
                  >
                    {vehicleTypes.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              />
            </CustomFormGrid> */}
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="status"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  fullWidth
                  size="small"
                  disabled={disabled}
                  id="status"
                  label={translation("vehicle.form.status.label")}
                  error={Boolean(errors?.status)}
                  select
                  helperText={
                    errors?.status && translation("vehicle.form.status.message")
                  }
                  required
                >
                  {statusTypes.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="year"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  views={["year"]}
                  disabled={disabled}
                  label={translation("vehicle.form.year.label")}
                  inputFormat="yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      id="year"
                      fullWidth
                      error={Boolean(errors?.year)}
                      helperText={
                        errors?.year && translation("vehicle.form.year.message")
                      }
                      required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          {/*  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="make"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  select
                  id="make"
                  label={translation("vehicle.form.make.label")}
                  error={Boolean(errors?.make)}
                  helperText={
                    errors?.make && translation("vehicle.form.make.message")
                  }
                  required
                >
                  {allTrailerMake.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid> */}
          <CustomFormGrid
            item
            sm={6}
            xs={12}
            md={3}
            lg={3}
            style={{ margin: "0px", padding: "0px" }}
          >
            <AddLookupField
              name={"make"}
              control={control}
              rules={{ required: true }}
              id="make"
              label={translation("vehicle.form.make.label")}
              errors={Boolean(errors?.make)}
              lookupsArray={allTrailerMake}
              linkingAddress={disabled}
              helperText={
                errors?.make && translation("vehicle.form.make.message")
              }
              fieldRequired={true}
              disabled={disabled}
              featureKey="trailer-make"
              lookupFeatureEnum={featureTrailers}
            />
          </CustomFormGrid>
          {/*  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="model"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="model"
                  label={translation("vehicle.form.model.label")}
                  error={Boolean(errors?.model)}
                  helperText={
                    errors?.model && translation("vehicle.form.model.message")
                  }
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="type"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  select
                  id="type"
                  label={translation("vehicle.form.type.label")}
                  error={Boolean(errors?.type)}
                  helperText={
                    errors?.type && translation("vehicle.form.type.message")
                  }
                  required
                >
                  {allTrailerTypes.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid> */}
          <CustomFormGrid
            item
            sm={6}
            xs={12}
            md={3}
            lg={3}
            style={{ margin: "0px", padding: "0px" }}
          >
            <AddLookupField
              name={"type"}
              control={control}
              rules={{ required: true }}
              id="type"
              label={translation("vehicle.form.type.label")}
              errors={Boolean(errors?.type)}
              lookupsArray={allTrailerTypes}
              linkingAddress={disabled}
              helperText={
                errors?.type && translation("vehicle.form.type.message")
              }
              fieldRequired={true}
              disabled={disabled}
              featureKey="trailer-type"
              lookupFeatureEnum={featureTrailers}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="VIN"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="VIN"
                  label={translation("vehicle.form.VIN.label")}
                  error={Boolean(errors?.VIN)}
                  helperText={
                    errors?.VIN && translation("vehicle.form.VIN.message")
                  }
                  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="length"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="length"
                  type="number"
                  inputProps={{ min: 0 }}
                  label={translation("vehicle.form.length.label")}
                  error={Boolean(errors?.length)}
                  helperText={
                    errors?.length && translation("vehicle.form.length.message")
                  }
                  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="width"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="width"
                  type="number"
                  inputProps={{ min: 0 }}
                  label={translation("vehicle.form.width.label")}
                  error={Boolean(errors?.width)}
                  helperText={
                    errors?.width && translation("vehicle.form.width.message")
                  }
                  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="deckHeight"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="deckHeight"
                  type="number"
                  inputProps={{ min: 0 }}
                  label={translation("vehicle.form.deckHeight.label")}
                  error={Boolean(errors?.deckHeight)}
                  helperText={
                    errors?.deckHeight &&
                    translation("vehicle.form.deckHeight.message")
                  }
                  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="weight"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="weight"
                  type="number"
                  inputProps={{ min: 0 }}
                  label={translation("vehicle.form.weight.label")}
                  error={Boolean(errors?.weight)}
                  helperText={
                    errors?.weight && translation("vehicle.form.weight.message")
                  }
                  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="tagNumber"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={
                    (trailerId && trailer?.tagNumber) || disabled ? true : false
                  }
                  fullWidth
                  id="tagNumber"
                  // type="number"
                  //  inputProps={{ min: 0 }}
                  label={translation("trailer.form.tagNumber.label")}
                  error={Boolean(errors?.tagNumber)}
                  helperText={
                    errors?.tagNumber &&
                    translation("trailer.form.tagNumber.message")
                  }
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={6}>
            <Controller
              name="tagState"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={
                    (trailerId && trailer?.tagState) || disabled ? true : false
                  }
                  fullWidth
                  // select
                  id="tagState"
                  label={translation("trailer.form.tagState.label")}
                  error={Boolean(errors?.tagState)}
                  helperText={
                    errors?.tagState &&
                    translation("trailer.form.tagState.message")
                  }
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={6}>
            <Controller
              name="tagIssueDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label={translation("trailer.form.tagExpiration.label")}
                  inputFormat="MM/dd/yyyy"
                  disabled={
                    (trailerId && trailer?.tagIssueDate) || disabled
                      ? true
                      : false
                  }
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      id="tagIssueDate"
                      disabled={
                        trailerId && trailer?.tagIssueDate ? true : false
                      }
                      fullWidth
                      error={Boolean(errors?.tagIssueDate)}
                      helperText={
                        errors?.tagIssueDate &&
                        translation("trailer.form.tagExpiration.message")
                      }
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          {/*      <CustomFormGrid
          item
          xs={12}
          style={{
            alignItems: "center",
            display: "flex",
            padding: "0px 2px",
          }}
        >
          <Controller
            name="permanentTag"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
                <InputLabel>
                  {translation("vehicle.form.PermanentTag")}
                </InputLabel>
              </>
            )}
          />
        </CustomFormGrid> */}
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default TrailerInfo;
