import React from "react";
import { Navigate } from "react-router-dom";
import DocumentType from "../pages/documentTypes";
import DocumentTypeForm from "../pages/documentTypes/form";
import DocumentTypesearchForm from "src/containers/documentTypes/documentTypeSearch";
import { UserPermissions } from "src/interfaces/user";
import { admin, create, update, featureDocumentType } from "src/utils/enums";

export const documentTypeRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const documentTypePermissions =
    allUserPermissions && allUserPermissions[featureDocumentType];
  const createPermission =
    role === admin
      ? 0
      : documentTypePermissions?.findIndex((item) => item === create);

  const updatePermission =
    role === admin
      ? 0
      : documentTypePermissions?.findIndex((item) => item === update);

  return [
    {
      path: "/config/document-types",
      exact: true,
      element:
        role === admin || documentTypePermissions?.length > 0 ? (
          <DocumentType />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/config/document-types/create",
      exact: true,
      element:
        createPermission > -1 ? <DocumentTypeForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/document-types/:id",
      exact: true,
      element:
        updatePermission > -1 ? <DocumentTypeForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/document-types/search",
      exact: true,
      element:
        updatePermission > -1 ? (
          <DocumentTypesearchForm />
        ) : (
          <Navigate to="/404" />
        ),
    },
  ];
};
