import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider } from "@mui/material";

interface DialogProps {
    open: boolean;
    onClose: (info?: boolean) => void;
    title: string;
    message: string;
    loading?: boolean;
}

export default function ConfirmationDialog({
    open,
    onClose,
    title,
    message,
    loading,
}: DialogProps): JSX.Element {


    const handleClose = () => {
        onClose()
    }

    const handleConfirm = () => {
        onClose(true);
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                keepMounted
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        {loading ? (
                            <CircularProgress style={{ height: "20px", width: "20px" }} />
                        ) : (
                            "Yes"
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
