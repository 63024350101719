import React from "react";

import { Link } from "react-router-dom";
import logo from "src/assets/img/tms_logo.png";

const LogoIcon = () => {
  return (
    <Link
      style={{
        paddingTop: "12px",
        textDecoration: "none",
      }}
      to="/"
    >
      <img src={logo} alt="porto" width={180} />
    </Link>
  );
};

export default LogoIcon;
