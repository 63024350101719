import React, { useEffect, useState } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import {
  Typography,
  Fab,
  Grid,
  IconButton,
  Tooltip,
  Card,
} from "@mui/material";
import { Add, ListAlt } from "@mui/icons-material";
import DataTable from "src/components/dataTable/linkingTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllAvailableVehiclesForTrailer } from "src/redux/actions/vehicle";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { VehicleTrailerProps } from "src/interfaces/vehicle";
import { VehicleProps } from "src/interfaces/vehicle";
import { useStyles } from "src/styles/formStyles";
import { sanitizeEntity } from "src/utils/common";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialogue from "src/components/common/confirmation";
import {
  addVehicleTrailer,
  updateVehicleTrailer,
} from "src/redux/actions/vehicle_trailer";
import { useParams } from "react-router-dom";
import { selectTrailer } from "src/redux/reducers/trailer";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import TrailerVehicleLinkModal from "src/components/trailer/vehicleLinkingModal";
import { selectLinkedVehicle } from "src/redux/reducers/trailer";
import LogsModal from "src/components/common/linkLogs";
import AssignedLinkingCard from "src/components/common/linkingCards/assignedVehicle";
import CustomFormGrid from "src/components/customElements/customFormGrid";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function TrailerInfo() {
  const [translation] = useTranslation("translation");
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const params = useParams();
  const trailer = useAppSelector(selectTrailer);
  const linkedVehicle = useAppSelector(selectLinkedVehicle);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [vehicle, setVehicle] = useState<VehicleProps | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const getVehiclesList = async () => {
    setLoading(true);
    const filterObject: any = {};
    filterObject.items = [
      {
        columnField: "status",
        operatorValue: "equals",
        value: "active",
      },
    ];
    await dispatch(getAllAvailableVehiclesForTrailer(0, 1, filterObject));
    setLoading(false);
  };
  useEffect(() => {
    getVehiclesList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleConfirmDelete = async () => {
    if (params?.trailerId) {
      setLoading(true);
      if (vehicle && vehicle.id) {
        const object = {
          vehicle: vehicle.id,
          trailer: parseInt(params?.trailerId),
        };
        await dispatch(updateVehicleTrailer(object));
      }
      handleCloseDeleteModal();
      setLoading(false);
      getVehiclesList();
    }
  };
  const handleTrailerDelete = () => {
    setVehicle(linkedVehicle);
    handleOpenDeleteModal();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*VehicleTrailerProps  */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: VehicleTrailerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.trailerId) {
      objToSubmit.trailer = parseInt(params?.trailerId)
        ? parseInt(params?.trailerId)
        : null;
      const vehicleTrailerLink = await dispatch(addVehicleTrailer(objToSubmit));
      if (vehicleTrailerLink.success && vehicleTrailerLink.data) {
        handleClose();
        getVehiclesList();
      }
    }

    setLoading(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };

  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "trailer.trailerId",
      headerName: translation("vehicle.form.vehicleID.label"),

      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.vehicle === "object" &&
            params?.row?.vehicle?.vehicleID
              ? params?.row?.vehicle?.vehicleID
              : ""}
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("driver.vehicleDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {" "}
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      {/*       <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>
            {!linkedVehicle && (
              <Tooltip
                title={
                  <Typography>
                    {translation("vehicle.trailer.assign")}
                  </Typography>
                }
              >
                <Fab
                  color="secondary"
                  sx={{
                    mr: 1,
                    ml: 1,
                  }}
                  size="small"
                  onClick={handleClickOpen}
                >
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      {linkedVehicle ? (
        <Card>
          <Grid container alignItems="flex-end">
            <Grid xs={12} md={6} lg={1} className={classes.deleteIconButton}>
              <IconButton
                onClick={() => {
                  handleTrailerDelete();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Typography className={classes.detailHeadingText}>
                  {translation("vehicle.form.vehicleID.label")}
                </Typography>

                <Typography className={classes.detailText}>
                  {linkedVehicle?.vehicleID}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Typography className={classes.detailHeadingText}>
                  {translation("vehicle.form.model.label")}
                </Typography>

                <Typography className={classes.detailText}>
                  {linkedVehicle?.model}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Typography className={classes.detailHeadingText}>
                  {translation("vehicle.form.VIN.label")}
                </Typography>

                <Typography className={classes.detailText}>
                  {linkedVehicle?.VIN}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h3" className={classes.detailText}>
            {translation("driver.vehicle.message")}
          </Typography>
        </Grid>
      )} */}

      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedVehicle}
          headerName={"Assigned Vehicle"}
          isDriver={false}
          handleClickOpen={handleClickOpen}
          handleDelete={handleTrailerDelete}
          data={linkedVehicle}
        />
      </CustomFormGrid>

      <TrailerVehicleLinkModal
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialogue
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("trailerVehicle.delete.message", {
            name:
              typeof linkedVehicle === "object"
                ? `${linkedVehicle?.vehicleID}-${linkedVehicle?.model}-${linkedVehicle?.VIN}`
                : "",
          })}
          title={translation("vehicle.delete.title")}
        />
      )}

      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={trailer?.trailerVehicles ? trailer?.trailerVehicles : []}
              columns={columns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              localPagination={true}
            />
          }
          title={translation("trailer.assignment.logs")}
        />
      )}
    </>
  );
}
