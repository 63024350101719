import { AppDispatch } from "../store";
import {
  setAllDocumentTypes,
  setSingleDocumentType,
} from "../reducers/documentType";
import { DocumentTypeProps } from "src/interfaces/documentType";
import { getServerData, postServerData, patchServerData } from "../api/index";
export const getAllDocumentTypes =
  (limit?: number, page?: number, filter?: any) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/document-definition`) as any
    );

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data?.data)) {
        dispatch(setAllDocumentTypes(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };
export const getAllDocumentTypesRequired =
  (entity?: string) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/document-definition/required/${entity}`) as any
    );

    // if (response?.data?.data) {
    //   if (Array.isArray(response?.data?.data?.data)) {
    //     dispatch(setAllDocumentTypes(response?.data?.data));
    //   }
    // }
    return { success: response.success, data: response?.data?.data };
  };
export const getDocumentTypeById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/document-definition/${id}`) as any
    );
    if (response?.data?.data) {
      dispatch(setSingleDocumentType(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const addDocumentType =
  (data: DocumentTypeProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      postServerData(`/document-definition`, data) as any
    );

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data?.data)) {
        dispatch(setSingleDocumentType(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateDocumentType =
  (data: DocumentTypeProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/document-definition/${id}`, data) as any
    );
    if (response?.data?.data?.document) {
      dispatch(setSingleDocumentType(response?.data?.data?.document));
    }
    return { success: response.success, data: response?.data?.data };
  };
