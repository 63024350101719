import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { selectDriver } from "src/redux/reducers/driver";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import FromHeader from "../common/readForms/formHeader";
import FromInput from "../common/readForms/formInput";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
// import Documents from "./docements";
import { driver as driverEnum } from "src/utils/enums";
import { LoadStopProps } from "src/interfaces/loadManagement";
import DriverDocuments from "src/containers/documents";
import DriverOwnerInfo from "src/containers/drivers/driverOwnerLinking";
import AssignedVehicleInfo from "src/containers/drivers/driver_vehicle_linking";
import TerminalToInfoModel from "src/components/common/InformationModels/terminalToInfoModal";
import MobileCheck from "src/components/common/mobileCheck/mobileCheck";
interface DriverSummaryProps {
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: DriverProps) => any; */
  onSubmit: (data: any) => any;
}

const DriverSummary = ({
  loading,
  pageLoading,
  onSubmit,
}: DriverSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [openConfirmModel, setOpenConfirmModel] = useState<boolean>(false);
  const [openDispatchFormModal, setOpenDispatchFormModal] = useState<any>(null);
  const [loadStops, setLoadStops] = useState<Array<LoadStopProps>>([
    // { ...defaultLoadStopObject },
  ]);
  const [selectedData, setSelectedData] = useState<any>();
  const [openTerminalModel, setOpenTerminalModel] = useState<boolean>(false);

  const user = useAppSelector(selectUser);
  // const driver = useAppSelector(selectLoadManagement);
  const driver = useAppSelector(selectDriver);
  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();

  const handleOpenTerminalModel = (terminalId: any) => {
    setOpenTerminalModel(true);
    setSelectedData(terminalId);
  };

  const handleCloseTerminalModel = () => {
    setOpenTerminalModel(false);
    setSelectedData(null);
  };

  return (
    <>
      <Box>
        {pageLoading ? (
          <CustomFormGrid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <CustomFormGrid>
              <CircularProgress sx={{ height: "20", width: "20px" }} />
            </CustomFormGrid>
          </CustomFormGrid>
        ) : (
          <>
            <MobileCheck
              grid1={
                <CustomFormGrid container>
                  <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
                    <Box className={classes.loadFormTitleContainer}>
                      <Box flexGrow={1} display="flex" alignItems="center">
                        <Button>
                          <ArrowBack onClick={() => navigate(-1)} />
                        </Button>
                        <Typography
                          variant="h6"
                          className={classes.loadFormMainHeadings}
                        >
                          {translation("driver.read.form.title")}
                        </Typography>
                      </Box>
                    </Box>
                  </CustomFormGrid>

                  <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
                    <CustomFormGrid
                      container
                      className={classes.buttonContainer}
                      justifyContent="flex-end"
                    >
                      <Button
                        color="error"
                        variant="contained"
                        className={classes.formButtonEdit}
                        onClick={() =>
                          navigate(`/drivers/drivers/${params?.driverId}`)
                        }
                      >
                        EDIT INFORMATION
                      </Button>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              }
              grid2={
                <>
                  <CustomFormGrid container>
                    <CustomFormGrid items lg={4} md={4} xs={12}>
                      <CustomFormGrid>
                        <FromHeader
                          title={translation("driver.read.form.title.form1")}
                        />
                      </CustomFormGrid>
                      <CustomFormGrid>
                        <CustomFormGrid>
                          <Paper elevation={1} sx={{ width: "100%" }}>
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title1"
                                )}
                                input={driver?.firstName}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title2"
                                )}
                                input={driver?.lastName}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title3"
                                )}
                                input={driver?.status}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title4"
                                )}
                                input={
                                  driver?.companyDriver === true
                                    ? "Company Driver"
                                    : "Owner Operator"
                                }
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title5"
                                )}
                                input={
                                  driver?.birthDate != null
                                    ? moment(driver?.birthDate).format(
                                        dateFormat
                                      )
                                    : "---"
                                }
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title6"
                                )}
                                input={driver?.SSN}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title7"
                                )}
                                input={driver?.gender}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title8"
                                )}
                                input={
                                  driver?.race != null
                                    ? driver?.race?.content
                                    : "---"
                                }
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title9"
                                )}
                                input={driver?.passportNumber}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form1.title10"
                                )}
                                input={
                                  driver?.passportExpirationDate != null
                                    ? moment(
                                        driver?.passportExpirationDate
                                      ).format(dateFormat)
                                    : "---"
                                }
                                checkColor={false}
                              />
                            </CustomFormGrid>
                          </Paper>
                        </CustomFormGrid>
                      </CustomFormGrid>
                    </CustomFormGrid>
                    <CustomFormGrid items lg={4} md={4} xs={12}>
                      <CustomFormGrid>
                        <FromHeader
                          title={translation("driver.read.form.title.form2")}
                        />
                      </CustomFormGrid>
                      <CustomFormGrid>
                        <CustomFormGrid>
                          <Paper elevation={1} sx={{ width: "100%" }}>
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form2.title1"
                                )}
                                input={driver?.email}
                                checkColor={true}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form2.title2"
                                )}
                                input={driver?.cellPhoneNumber}
                                checkColor={false}
                              />
                            </CustomFormGrid>

                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form2.title3"
                                )}
                                input={driver?.homePhoneNumber}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form2.title4"
                                )}
                                input={driver?.faxPhoneNumber}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form2.title5"
                                )}
                                input={driver?.pagerPhoneNumber}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                          </Paper>
                        </CustomFormGrid>
                      </CustomFormGrid>
                    </CustomFormGrid>
                    <CustomFormGrid items lg={4} md={4} xs={12}>
                      <CustomFormGrid>
                        <FromHeader
                          title={translation("driver.read.form.title.form3")}
                        />
                      </CustomFormGrid>
                      <CustomFormGrid>
                        <CustomFormGrid>
                          <Paper elevation={1} sx={{ width: "100%" }}>
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form3.title1"
                                )}
                                input={driver?.address}
                                checkColor={true}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form3.title2"
                                )}
                                input={driver?.city}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form3.title3"
                                )}
                                input={driver?.state}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                            <Divider variant="middle" />
                            <CustomFormGrid>
                              <FromInput
                                heading={translation(
                                  "driver.read.form.title.form3.title4"
                                )}
                                input={driver?.zip}
                                checkColor={false}
                              />
                            </CustomFormGrid>
                          </Paper>
                        </CustomFormGrid>
                      </CustomFormGrid>
                    </CustomFormGrid>
                    <CustomFormGrid container>
                      <CustomFormGrid items lg={4} md={4} xs={12}>
                        <CustomFormGrid>
                          <FromHeader
                            title={translation("driver.read.form.title.form4")}
                          />
                        </CustomFormGrid>
                        <CustomFormGrid>
                          <CustomFormGrid>
                            <Paper elevation={1} sx={{ width: "100%" }}>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form4.title1"
                                  )}
                                  input={driver?.cdlMatchesPhysical}
                                  checkColor={true}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form4.title2"
                                  )}
                                  input={driver?.cdlNumber}
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form4.title3"
                                  )}
                                  input={driver?.cdlState}
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form4.title4"
                                  )}
                                  input={
                                    driver?.cdlExpirationDate != null
                                      ? moment(
                                          driver?.cdlExpirationDate
                                        ).format(dateFormat)
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                            </Paper>
                          </CustomFormGrid>
                        </CustomFormGrid>
                      </CustomFormGrid>
                      <CustomFormGrid items lg={4} md={4} xs={12}>
                        <CustomFormGrid>
                          <FromHeader
                            title={translation("driver.read.form.title.form5")}
                          />
                        </CustomFormGrid>
                        <CustomFormGrid>
                          <CustomFormGrid>
                            <Paper elevation={1} sx={{ width: "100%" }}>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form5.title1"
                                  )}
                                  input={
                                    driver?.driverGroup != null
                                      ? driver?.driverGroup.content
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form5.title2"
                                  )}
                                  input={
                                    driver?.dispatchGroup != null
                                      ? driver?.dispatchGroup.content
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                            </Paper>
                          </CustomFormGrid>
                        </CustomFormGrid>
                      </CustomFormGrid>
                      <CustomFormGrid items lg={4} md={4} xs={12}>
                        <CustomFormGrid>
                          <FromHeader
                            title={translation("driver.read.form.title.form6")}
                          />
                        </CustomFormGrid>
                        <CustomFormGrid>
                          <CustomFormGrid>
                            <Paper elevation={1} sx={{ width: "100%" }}>
                              <CustomFormGrid
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenTerminalModel(driver?.terminal?.id)
                                }
                              >
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title1"
                                  )}
                                  input={
                                    driver?.terminal != null
                                      ? driver?.terminal?.title
                                      : "---"
                                  }
                                  checkColor={true}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title2"
                                  )}
                                  input={
                                    driver?.eobrType != null
                                      ? driver?.eobrType?.content
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title3"
                                  )}
                                  input={
                                    driver?.eobrID != null
                                      ? driver?.eobrID
                                      : "--"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title4"
                                  )}
                                  input={
                                    driver?.driverGroup != null
                                      ? driver?.driverGroup?.content
                                      : "--"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <Divider variant="middle" />
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title5"
                                  )}
                                  input={
                                    driver?.hireDate != null
                                      ? moment(driver?.hireDate).format(
                                          dateFormat
                                        )
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title6"
                                  )}
                                  input={
                                    driver?.reviewDate != null
                                      ? moment(driver?.reviewDate).format(
                                          dateFormat
                                        )
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title7"
                                  )}
                                  input={
                                    driver?.nextReviewDate != null
                                      ? moment(driver?.nextReviewDate).format(
                                          dateFormat
                                        )
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title8"
                                  )}
                                  input={
                                    driver?.mvrDate != null
                                      ? moment(driver?.mvrDate).format(
                                          dateFormat
                                        )
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title9"
                                  )}
                                  input={
                                    driver?.physicalDueDate != null
                                      ? moment(driver?.physicalDueDate).format(
                                          dateFormat
                                        )
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title10"
                                  )}
                                  input={
                                    driver?.physicalSubmittedDate != null
                                      ? moment(
                                          driver?.physicalSubmittedDate
                                        ).format(dateFormat)
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title11"
                                  )}
                                  input={
                                    driver?.residentExpirationDate != null
                                      ? moment(
                                          driver?.residentExpirationDate
                                        ).format(dateFormat)
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                              <CustomFormGrid>
                                <FromInput
                                  heading={translation(
                                    "driver.read.form.title.form6.title12"
                                  )}
                                  input={
                                    driver?.twicExpirationDate != null
                                      ? moment(
                                          driver?.twicExpirationDate
                                        ).format(dateFormat)
                                      : "---"
                                  }
                                  checkColor={false}
                                />
                              </CustomFormGrid>
                            </Paper>
                          </CustomFormGrid>
                        </CustomFormGrid>
                      </CustomFormGrid>
                    </CustomFormGrid>

                    <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                      <DriverDocuments
                        role="driver"
                        roleId={params?.driverId}
                      />
                    </CustomFormGrid>

                    <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                      <DriverOwnerInfo />
                    </CustomFormGrid>

                    <CustomFormGrid item xs={12} sm={12} md={6} lg={6}>
                      <AssignedVehicleInfo />
                    </CustomFormGrid>
                  </CustomFormGrid>
                </>
              }
            />
          </>
        )}
      </Box>
      {openTerminalModel === true && (
        <TerminalToInfoModel
          open={openTerminalModel}
          handleClose={handleCloseTerminalModel}
          terminalId={selectedData}
        />
      )}
    </>
  );
};

export default DriverSummary;
