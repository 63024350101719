import React from "react";
import ResetPasswordContainer from "src/containers/resetPassword";
import Head from "src/components/head";

const ResetPassword = (): JSX.Element => {
  return (
    <>
      <Head title="Reset Password" description="Porto Reset Password" />
      <ResetPasswordContainer />
    </>
  );
};

export default ResetPassword;
