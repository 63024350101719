import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Zoom,
  MenuItem,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DescriptionIcon from "@mui/icons-material/Description";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import { useTranslation } from "react-i18next";
import CustomTextField from "src/components/customElements/customTextField";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm, Controller } from "react-hook-form";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectDocumentTypes } from "src/redux/reducers/documentType";
import { img, pdf, xls, docx } from "src/utils/enums";
import { useStyles } from "src/styles/formStyles";

import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const Transition = React.forwardRef<HTMLInputElement, any>((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

interface DocumentModalProps {
  open: boolean;
  handleClose: () => void;
  handleDocumentUpload: (file: FormData) => any;
  title: string;
  progress: number;
  feature: string;
  referenceId: string;
  getAllDocument?: (data: any) => any;
}

const DocumentModal = ({
  open,
  handleClose,
  handleDocumentUpload,
  title,
  progress,
  feature,
  referenceId,
  getAllDocument,
}: DocumentModalProps): JSX.Element => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm();

  const inputFileRef = React.useRef<any>();
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const documentTypes = useAppSelector(selectDocumentTypes).data?.filter(
    (item: any) => item.entity === feature
  );
  const [document, setDocument] = useState<File | null>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [documentType, setDocumentType] = useState<any>(null);
  const documentDetails = documentTypes?.filter(
    (item: any) => item.id === watch("type")
  );

  useEffect(() => {
    if (!open) {
      setDocument(null);
    }
  }, [open]);
  useEffect(() => {
    if (watch("type")) {
      const _selectedType = documentTypes.filter(
        (item: any) => item.id === watch("type")
      );
      if (_selectedType?.length > 0) {
        setSelectedType(_selectedType[0]);
        if (_selectedType[0]?.type === img) {
          setDocumentType("image/*");
        } else if (_selectedType[0]?.type === xls) {
          setDocumentType(".xls, .xlsx, .xlsm");
        } else if (_selectedType[0]?.type === docx) {
          setDocumentType("application/msword");
        } else if (_selectedType[0]?.type === pdf) {
          setDocumentType("application/pdf");
        } else {
          setDocumentType(
            "image/*,application/pdf,application/msword,.xls, .xlsx, .xlsm"
          );
        }
      }
    }
  }, [watch("type")]);

  const selectImage = () => {
    if (inputFileRef?.current) {
      inputFileRef?.current?.click();
    }
  };
  const onFileChangeCapture = (e: any) => {
    if (e.target.files[0]) {
      setDocument(e.target.files[0]);
    }
  };
  const handleUploadDocument = async () => {
    if (document) {
      const formData = new FormData();
      formData.append("name", document.name);
      formData.append("path", document);
      formData.append("entity", feature);
      formData.append("documentDefinition", watch("type"));
      formData.append("referenceId", referenceId);
      formData.append("comment", watch("comment"));
      if (selectedType?.hasExpiry) {
        formData.append("expiry", watch("expiry"));
      }
      const response = await handleDocumentUpload(formData);
      handleClose();
      if (response.success) {
        setDocument(null);

        getAllDocument && getAllDocument(referenceId);
      }
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CustomForm>
                {" "}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <>
                            <CustomTextField
                              {...field}
                              id="type"
                              fullWidth
                              size="small"
                              margin="normal"
                              select
                              label={translation("document.type.label")}
                              helperText={
                                errors?.type &&
                                translation("document.type.message")
                              }
                              error={Boolean(errors?.type)}
                            >
                              {documentTypes.map((item: any, index: any) => (
                                <MenuItem value={item.id} key={index}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </CustomTextField>
                          </>
                        )}
                      />
                    </CustomFormGrid>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Controller
              name="comment"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  id="comment"
                  label={translation("driverVehicle.form.comments.label")}
                  error={Boolean(errors?.comments)}
                  helperText={
                    errors?.comments &&
                    translation("driverVehicle.form.comments.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      {documentDetails.map((item: any, index: any) => (
                        <CustomFormGrid container key={index}>
                          <CustomFormGrid
                            item
                            sm={4}
                            xs={4}
                            md={4}
                            lg={4}
                            key={index}
                            value={item.id}
                          >
                            {`${translation("document.fileSize.label")} : ${
                              item.size
                            } MB`}
                          </CustomFormGrid>
                          <CustomFormGrid
                            item
                            sm={4}
                            xs={4}
                            md={4}
                            lg={4}
                            key={index}
                            value={item.id}
                          >
                            {`${translation("document.fileType.label")} : ${
                              item.type
                            } `}
                          </CustomFormGrid>
                          <CustomFormGrid
                            item
                            sm={4}
                            xs={4}
                            md={4}
                            lg={4}
                            key={index}
                            value={item.id}
                          >
                            {`${translation("document.hasExpiry.label")} : ${
                              item.hasExpiry ? "Yes" : "No"
                            }`}
                          </CustomFormGrid>
                        </CustomFormGrid>
                      ))}
                    </CustomFormGrid>
                    {selectedType?.hasExpiry && (
                      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                        <Controller
                          name="expiry"
                          control={control}
                          defaultValue={""}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label={translation("document.expiry.label")}
                              disablePast
                              inputFormat="MM/dd/yyyy"
                              renderInput={(params: any) => (
                                <CustomTextField
                                  {...params}
                                  {...field}
                                  size="small"
                                  id="expiry"
                                  fullWidth
                                  error={Boolean(errors?.expiry)}
                                  helperText={
                                    errors?.expiry &&
                                    translation("document.expiry.message")
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </CustomFormGrid>
                    )}
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      {selectedType && (
                        <Grid container alignItems="center">
                          <input
                            type="file"
                            accept={documentType}
                            ref={inputFileRef}
                            onChangeCapture={onFileChangeCapture}
                            style={{ display: "none" }}
                          />
                          <Button variant="contained" onClick={selectImage}>
                            {translation("common.btn.chooseDocument")}
                          </Button>
                          {document && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                ml: "20px",
                              }}
                            >
                              <DescriptionIcon />
                              <Typography
                                sx={{
                                  ml: "5px",
                                }}
                              >
                                {document?.name}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      )}
                    </CustomFormGrid>
                  </Grid>
                </LocalizationProvider>
              </CustomForm>
            </Box>
          </Grid>

          {progress ? (
            <Grid item xs={12} md={12} lg={12}>
              <LinearProgressWithLabel value={progress} />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <CustomFormGrid container justifyContent="center">
          {document && watch("type") && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.formButton}
              onClick={handleUploadDocument}
            >
              {translation("common.btn.upload")}
            </Button>
          )}
          <Button
            color="error"
            variant="contained"
            className={classes.formButton}
            onClick={handleClose}
          >
            {translation("common.btn.cancel")}
          </Button>
        </CustomFormGrid>

        {/*  <ButtonGroup
          variant="contained"
          color="secondary"
          aria-label="outlined primary button group"
          fullWidth
        >
          {document && watch("type") && (
            <Button onClick={handleUploadDocument}>
              {translation("common.btn.upload")}
            </Button>
          )}
          <Button onClick={handleClose}>
            {translation("common.btn.cancel")}
          </Button>
        </ButtonGroup> */}
      </DialogActions>
    </Dialog>
  );
};

export default DocumentModal;
