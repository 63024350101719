import React from "react";
import { Navigate } from "react-router-dom";
import Users from "../pages/users";
import UserForm from "../pages/users/userForm";
import ReadUserForm from "../pages/users/readUserForm";
import UserSearch from "../containers/users/userSearch";

import { UserPermissions } from "src/interfaces/user";
import { admin, create, find, update, featureUsers } from "src/utils/enums";

export const userRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const userPermissions =
    allUserPermissions && allUserPermissions[featureUsers];
  const createPermission =
    role === admin ? 0 : userPermissions?.findIndex((item) => item === create);

  const updatePermission =
    role === admin ? 0 : userPermissions?.findIndex((item) => item === update);

  const findPermission =
    role === admin ? 0 : userPermissions?.findIndex((item) => item === find);

  return [
    {
      path: "/administration/users",
      exact: true,
      element:
        role === admin || userPermissions?.length > 0 ? (
          <Users />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/administration/users/create",
      exact: true,
      element: createPermission > -1 ? <UserForm /> : <Navigate to="/404" />,
    },
    {
      path: "/administration/users/:userId",
      exact: true,
      element: updatePermission > -1 ? <UserForm /> : <Navigate to="/404" />,
    },
    {
      path: "/administration/users/details/:userId",
      exact: true,
      element: findPermission > -1 ? <ReadUserForm /> : <Navigate to="/404" />,
    },
    {
      path: "/administration/users/search",
      exact: true,
      element: findPermission > -1 ? <UserSearch /> : <Navigate to="/404" />,
    },
  ];
};
