import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable/billingDataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllSettlementListOnStatus, updateSettlementStatus,
} from "src/redux/actions/settlement";
import { selectCompletedSettlementList } from "src/redux/reducers/settlement";
import { useTranslation } from "react-i18next";
import { featureSettlements, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { toNumber } from "lodash";
import { Button } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function Terminals() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [printLoading, setPrintLoading] = useState<boolean>(false);
  const completedSettlementList = useAppSelector(selectCompletedSettlementList);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const user = useAppSelector(selectUser);
  const classes = useStyles();
  const settlementPermissions =
    allUserPermissions && allUserPermissions[featureSettlements];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : settlementPermissions?.findIndex((item) => item === update);
  const [completedSettlementsData, setCompletedSettlementsData] = useState<any>(
    {}
  );
  const [filter, setFilter] = useState<any>({});
  const [selectedRows, setSelectedRows] = React.useState<any>();

  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const getPrintQueueList = async () => {
    setLoading(true);
    await dispatch(
      getAllSettlementListOnStatus(
        "completed",
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };
  function pageExists(page: any) {
    return completedSettlementsData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  }
  const getPrintQueueListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllSettlementListOnStatus(
          "completed",
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };
  const getUpdatedSettlementStatus = async () => {
    setPrintLoading(true);
    await dispatch(updateSettlementStatus(selectedRows, "printQueue")).then(
      () => {
        setPrintLoading(false);
        getPrintQueueList();
        setSelectedRows("");
      }
    );
  };
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getPrintQueueListWithFilter();
    } else getPrintQueueList();
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    getPrintQueueListWithFilter();
  }, [filter]);
  useEffect(() => {
    //  if (pageExists(rowsState?.page + 1) == false)
    if (completedSettlementList?.data?.length > 0) {
      setCompletedSettlementsData({
        ...completedSettlementsData?.completedSettlementList,
        completedSettlementList,
      });
    } else {
      setCompletedSettlementsData({});
    }
  }, [completedSettlementList]);

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );

  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "account",
      headerName: translation("settlement.print.account.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <div> {params.row.settlements[0]?.account?.accountName} </div>;
      },
    },
    {
      field: "settledBy",
      headerName: translation("settlement.print.settledBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {" "}
            {params.row.settledBy &&
              params.row.settledBy.firstName +
                " " +
                params.row.settledBy.lastName}{" "}
          </div>
        );
      },
      filterable: false,
    },
    {
      field: "receivedOn",
      headerName: translation("settlement.print.receivedOn.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.printReceivedOn
              ? moment(params.row.printReceivedOn).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "printedOn",
      headerName: translation("settlement.print.printedOn.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.printedOn
              ? moment(params.row.printedOn).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  return  (
    <CustomFormGrid item sm={12} xs={12} lg={12} justifyContent="center" style={{paddingTop:"20px"}}>
      {loading ? (
        <CircularProgress style={{ position: "absolute", left: "60%" }} />
      ) : (
        <>
    <DataTable
      title={translation("settlement.completed.queue.title")}
      rows={completedSettlementsData?.completedSettlementList?.data}
      columns={columns}
      loading={loading}
      createPermission={createPermission > -1}
      rowsState={rowsState}
      setRowsState={setRowsState}
      pagination={completedSettlementList.pagination}
      data={completedSettlementsData?.terminals}
      setData={setCompletedSettlementsData}
      billing={true}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setFilter={setFilter}
      />
      <div
        style={{ float: "right", marginBottom: "10px", marginTop: "10px" }}
      >
        <Button
          color="primary"
          variant="contained"
                          className={classes.formButtonBilling}
                          disabled={selectedRows && selectedRows.length > 0 ? false : true}
          onClick={() => getUpdatedSettlementStatus()}
        >
          {printLoading ? (
            <CircularProgress  style={{ color: "#fff", height: "20px", width: "20px" }} />
          ) : (
            translation("billing.list.button.label4")
          )}
        </Button>
      </div>
    </>
  )}
</CustomFormGrid>
);
}
