import { AppDispatch } from "../store";
import {
  setAllTerminals,
  setSingleTerminal,
  //  updateTerminalList,
} from "../reducers/terminal";
import { TerminalProps } from "src/interfaces/terminal";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllTerminals =
  (limit?: number, page?: number, filter?: any) => async (dispatch: AppDispatch) => {

    const response: any = await dispatch(
      getServerData(`/terminal`) as any
    );

    if (response?.data?.data) {

      if (Array.isArray(response?.data?.data)) {
        dispatch(setAllTerminals(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };

export const getTerminalById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(getServerData(`/terminal/${id}`) as any);
    if (response?.data?.data) {
      dispatch(setSingleTerminal(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const addTerminal =
  (data: TerminalProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/terminal`, data) as any);

    if (response?.data?.data) {
      //  dispatch(updateTerminalList(response?.data?.data));
      dispatch(setSingleTerminal(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const updateTerminal =
  (data: TerminalProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(patchServerData(`/terminal/${id}`, data) as any);
    if (response?.data?.data?.terminal) {
      dispatch(setSingleTerminal(response?.data?.data?.terminal));
    }
    return { success: response.success, data: response?.data?.data };
  };
