import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { selectDriver } from "src/redux/reducers/driver";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import ModelInput from "src/components/common/InformationModels/inputModel";
import { Close } from "@mui/icons-material";
import { useStyles } from "src/styles/formStyles";
import CustomGrid from "src/components/customElements/customFormGrid";
import StatusBadge from "src/components/common/StatusBadge";
import { dateFormat } from "src/utils/constants";
import moment from "moment";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";

import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import { getDriverById } from "src/redux/actions/driver";

interface DialogProps {
  open: boolean;
  handleClose: () => void;

  loadId: number;
}

export default function ConfirmationDialog({
  open,
  handleClose,
  loadId,
}: DialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [translation] = useTranslation("translation");
  const [pageLoading, setPageLoading] = useState(false);
  const loadData = useAppSelector(selectLoadManagement);
  const billToSummary = loadData != null && loadData?.billToInformation;

  const getSingleLineItem = async (id: number) => {
    setPageLoading(true);
    await dispatch(getLoadManagementById(id));
    setPageLoading(false);
  };

  useEffect(() => {
    if (loadId) {
      getSingleLineItem(loadId);
    }
  }, [loadId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomFormGrid container className={classes.modelContainer}>
          <CustomFormGrid item xs={11} sm={11} md={11} lg={11}>
            <Typography variant="h6" className={classes.loadFormMainHeadings}>
              {translation("load.loadInfo.label")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item xs={1} sm={1} md={1} lg={1}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomFormGrid>
        </CustomFormGrid>

        <DialogContent sx={{ paddingBottom: "20px",paddingTop:"0px", margin: "0px" }}>
          {pageLoading ? (
            <CustomFormGrid container justifyContent="center" style={{height:"100px"}}>
              <CircularProgress />
            </CustomFormGrid>
          ) : (
            <>
              {loadData && (
                  <>

              <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid item xs={2.8} sm={2.8} md={2.8} lg={2.8} className={classes.fieldModel}>
                  <ModelInput
                    heading={"LoadID"}
                    input={loadData?.id ? loadData?.id : "--"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid item xs={2.8} sm={2.8} md={2.8} lg={2.8} className={classes.fieldModel}>
                  <ModelInput
                    heading={translation("load.form.pickupDateTime.label")}
                    input={loadData?.pickupDateTime ?  moment(loadData?.pickupDateTime).format(
                      dateFormat
                    ) :"--"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid item xs={2.8} sm={2.8} md={2.8} lg={2.8} className={classes.fieldModel}>
                  <ModelInput
                    heading={translation("load.form.deliveryDateTime.label")}
                    input={loadData?.deliveryDateTime ?  moment(loadData?.deliveryDateTime).format(dateFormat) :"--"}
                    checkColor={false}
                  />
                      </CustomFormGrid>
                      <div className={classes.vlModel}></div>
                <CustomFormGrid item xs={2.8} sm={2.8} md={2.8} lg={2.8} className={classes.fieldModel}>
                  <ModelInput
                    heading={translation("load.loadStatus.label")}
                    input={loadData?.loadStatus ? loadData?.loadStatus  :"--"}
                    checkColor={false}
                  />
                      </CustomFormGrid>

                    </CustomFormGrid>
                    <Divider variant="middle"/>
                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                      <CustomGrid
                        container
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                              pl: 1,
                              fontWeight: 500,
                              color: "#777E89",
                              fontSize: "14px",
                              lineHeight: "14px",
                            }}
                          >
                            Shipper
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.shippingInformation?.company}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.shippingInformation?.email}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.shippingInformation?.address}
                            {loadData?.shippingInformation?.city &&
                            loadData?.shippingInformation?.address
                              ? ","
                              : ""}
                            {loadData?.shippingInformation?.city
                              ? " " + loadData?.shippingInformation?.city
                              : ""}
                            {loadData?.shippingInformation?.state &&
                            loadData?.shippingInformation?.city
                              ? ","
                              : ""}
                            {loadData?.shippingInformation?.state
                              ? " " + loadData?.shippingInformation?.state
                              : ""}
                            {loadData?.shippingInformation?.zip &&
                            loadData?.shippingInformation?.state
                              ? ","
                              : ""}
                            {loadData?.shippingInformation?.zip
                              ? " " + loadData?.shippingInformation?.zip
                              : ""}
                          </Typography>
                        </CustomGrid>
                      </CustomGrid>
                    </CustomFormGrid>
                    <div className={classes.vlModelLoad}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <CustomGrid
                        container
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                              pl: 1,
                              fontWeight: 500,
                              color: "#777E89",
                              fontSize: "14px",
                              lineHeight: "14px",
                            }}
                          >
                            Consignee
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.consigneeInformation?.company}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.consigneeInformation?.email}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.consigneeInformation?.address}
                            {loadData?.consigneeInformation?.city &&
                            loadData?.consigneeInformation?.address
                              ? ","
                              : ""}
                            {loadData?.consigneeInformation?.city
                              ? " " + loadData?.consigneeInformation?.city
                              : ""}
                            {loadData?.consigneeInformation?.state &&
                            loadData?.consigneeInformation?.city
                              ? ","
                              : ""}
                            {loadData?.consigneeInformation?.state
                              ? " " + loadData?.consigneeInformation?.state
                              : ""}
                            {loadData?.consigneeInformation?.zip &&
                            loadData?.consigneeInformation?.state
                              ? ","
                              : ""}
                            {loadData?.consigneeInformation?.zip
                              ? " " + loadData?.consigneeInformation?.zip
                              : ""}
                          </Typography>
                        </CustomGrid>
                      </CustomGrid>
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={translation("load.billOfLanding.label")}
                        input={
                          loadData?.billOfLading
                            ? loadData?.billOfLading
                            : "--"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={"Trailer Req"}
                        input={ loadData?.trailerRequirement
                          ? loadData?.trailerRequirement?.content
                          : "--"}
                        checkColor={false}
                      />
                      </CustomFormGrid>
                      <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={3.8}
                      sm={3.8}
                      md={3.8}
                      lg={3.8}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={translation("load.form.loadSize.label")}
                        input={loadData?.loadSize ? loadData?.loadSize : "--"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={translation("load.form.loadLength.label")}
                        input={loadData?.length ? loadData?.length : "0"}
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                     <ModelInput
                        heading={translation("commodity.form.weight.label")}
                        input={
                          loadData?.weight ? loadData?.weight : "0"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid item xs={4} sm={4} md={4} lg={4} className={classes.fieldModel}>
                  <ModelInput
                    heading={translation("load.form.terminal.label")}
                    input={ loadData?.terminal?.title
                      ? loadData?.terminal?.title
                      : "--"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid item xs={4} sm={4} md={2.8} lg={4} className={classes.fieldModel}>
                  <ModelInput
                    heading={"Terminal City"}
                    input={loadData?.terminal?.city
                      ? loadData?.terminal?.city
                      : "--"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid item xs={3.8} sm={3.8} md={3.8} lg={3.8} className={classes.fieldModel}>
                  <ModelInput
                    heading={"Terminal Ph#"}
                    input={loadData?.terminal?.phoneNumber
                      ? loadData?.terminal?.phoneNumber
                      : "--"}
                    checkColor={false}
                  />
                      </CustomFormGrid>
                    </CustomFormGrid>

                    <Divider variant = "middle"/>
                    <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                      <CustomGrid
                        container
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                              pl: 1,
                              fontWeight: 500,
                              color: "#777E89",
                              fontSize: "14px",
                              lineHeight: "14px",
                            }}
                          >
                            Bill To Information
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12} style={{marginTop:"5px"}}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.billToInformation?.customer}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.billToInformation?.email}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.billToInformation?.address}
                            {loadData?.billToInformation?.city &&
                            loadData?.billToInformation?.address
                              ? ","
                              : ""}
                            {loadData?.billToInformation?.city
                              ? " " + loadData?.billToInformation?.city
                              : ""}
                            {loadData?.billToInformation?.state &&
                            loadData?.billToInformation?.city
                              ? ","
                              : ""}
                            {loadData?.billToInformation?.state
                              ? " " + loadData?.billToInformation?.state
                              : ""}
                            {loadData?.billToInformation?.zip &&
                            loadData?.billToInformation?.state
                              ? ","
                              : ""}
                            {loadData?.billToInformation?.zip
                              ? " " + loadData?.billToInformation?.zip
                              : ""}
                          </Typography>
                        </CustomGrid>
                      </CustomGrid>
                    </CustomFormGrid>
                    <div className={classes.vlModelLoad}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <CustomGrid
                        container
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          margin: "0px",
                        }}
                      >
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            variant="h6"
                            component="h4"
                            sx={{
                              pl: 1,
                              fontWeight: 500,
                              color: "#777E89",
                              fontSize: "14px",
                              lineHeight: "14px",
                            }}
                          >
                            Order Taker
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12} style={{marginTop:"5px"}}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            {loadData?.addedBy?.email
                              ? loadData?.addedBy?.email
                              : "--"}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{ color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                          >
                            { loadData?.addedBy?.phoneNumber
                              ? loadData?.addedBy?.phoneNumber
                              : "--"}
                          </Typography>
                        </CustomGrid>
                        <CustomGrid xs={12} sm={12} md={12} lg={12}>
                          <Typography
                            style={{color: "#000" }}
                            component="h4"
                            variant="h6"
                            sx={{
                              pl: 1,
                              mb: 0,
                              fontSize: " 16px",
                              lineHeight: "19px",
                            }}
                            >
                               { loadData?.addedBy?.address
                              ? loadData?.addedBy?.address
                              : "--"}

                          </Typography>
                        </CustomGrid>
                      </CustomGrid>
                    </CustomFormGrid>
                    </CustomFormGrid>
                    <Divider variant = "middle"/>

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={"Freight Method"}
                        input={
                          // loadData?.loadStops[0]?.dispatchPay
                          //     ? loadData?.loadStops[0]?.dispatchPay
                          //     : "--"
                          ""
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={"Rate"}
                        input={
                          loadData?.freightBill?.rate
                              ? "$" + loadData?.freightBill?.rate
                              : "&0.00"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={"Mileage"}
                        input={
                          loadData?.freightBill?.mileage
                          ? "$" + loadData?.freightBill?.mileage
                          : "&0.00"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={"Fuel"}
                        input={
                          loadData?.freightBill?.fuel
                              ? "$" + loadData?.freightBill?.fuel
                              : "&0.00"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    </CustomFormGrid>

                  <Divider variant="middle" />

                  <CustomFormGrid container className={classes.fieldModel}>
                    <CustomFormGrid
                      item
                      xs={5.5}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      className={classes.fieldModel}
                    >
                       <ModelInput
                        heading={"Other Charges"}
                        input={
                          loadData?.freightBill?.otherBill
                          ? "$" + loadData?.freightBill?.otherBill
                          : "&0.00"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                    <div className={classes.vlModel}></div>
                    <CustomFormGrid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      className={classes.fieldModel}
                    >
                      <ModelInput
                        heading={"Total"}
                        input={
                          loadData?.freightBill?.grandTotal
                          ? "$" + loadData?.freightBill?.grandTotal
                          : "&0.00"
                        }
                        checkColor={false}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
