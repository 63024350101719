import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { VehicleProps, DocumentProps } from "src/interfaces/vehicle";
import { TrailerProps } from "src/interfaces/trailer";
import { DriverVehicleProps } from "src/interfaces/driver";
import { OwnerVehicleProps } from "src/interfaces/owner";
export interface VehicleState {
  VehiclesList: any;
  singleVehicle: VehicleProps | null;
  availableVehiclesList: Array<VehicleProps>;
  availableVehiclesForTrailer: Array<VehicleProps>;
  documentsList: Array<DocumentProps>;
  progress: number;
  linkedDrivers: Array<DriverVehicleProps>;
  linkedTrailer: TrailerProps | null;
  linkedOwner: any;
}

const initialState: VehicleState = {
  VehiclesList: {},
  singleVehicle: null,
  documentsList: [],
  availableVehiclesList: [],
  availableVehiclesForTrailer: [],
  progress: 0,
  linkedTrailer: null,
  linkedDrivers: [],
  linkedOwner: {},
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,

  reducers: {
    setAllVehicles: (state, action: PayloadAction<Array<VehicleProps>>) => {
      state.VehiclesList = action.payload;
    },
    setAllAvailableVehicles: (
      state,
      action: PayloadAction<Array<VehicleProps>>
    ) => {
      state.availableVehiclesList = action.payload;
    },
    setAllAvailableVehiclesForTrailer: (
      state,
      action: PayloadAction<Array<VehicleProps>>
    ) => {
      state.availableVehiclesForTrailer = action.payload;
    },
    setSingleVehicle: (state, action: PayloadAction<VehicleProps>) => {

      state.singleVehicle = action.payload;
      state.linkedTrailer = action.payload.trailer;
      state.linkedDrivers = action.payload.driverVehicleLink;
      state.linkedOwner = action.payload.ownerVehicleLink &&  action.payload.ownerVehicleLink[0];

    },
    resetVehicle: (state) => {
      state.singleVehicle = null;
    },

    setAllVehicleDocuments: (
      state,
      action: PayloadAction<Array<DocumentProps>>
    ) => {
      state.documentsList = action.payload;
    },
    appendVehicleDocumentsList: (
      state,
      action: PayloadAction<DocumentProps>
    ) => {
      state.documentsList = [...state.documentsList, action.payload];
      state.progress = 0;
    },
    filterVehicleDocumentsList: (state, action: PayloadAction<number>) => {
      const _list = state.documentsList.filter(
        (item) => item.id !== action.payload
      );
      state.documentsList = [..._list];
    },
    updateVehicleDocumentsList: (
      state,
      action: PayloadAction<{ data: DocumentProps; id: number }>
    ) => {
      const _list = [...state.documentsList];
      const index = _list.findIndex((item) => item.id === action.payload.id);
      if (index > -1) {
        state.documentsList[index] = { ...action.payload.data };
      }
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    resetUploadProgress: (state) => {
      state.progress = 0;
    },
    addLinkedTrailer: (state, action: PayloadAction<TrailerProps>) => {
      state.linkedTrailer = action.payload;
    },
    removeLinkedTrailer: (state) => {
      state.linkedTrailer = null;
    },
    addLinkedDriver: (state, action: PayloadAction<DriverVehicleProps>) => {
      state.linkedDrivers = [...state.linkedDrivers, action.payload];
    },
    removeLinkedDriver: (state, action: PayloadAction<number>) => {
     /*  console.log({
        state,
        driversList: state.linkedDrivers,
        driversLists: [...state.linkedDrivers],
        payload: action.payload,
      }); */

      state.linkedDrivers = state.linkedDrivers.filter(
        (item: any) =>
          item !== null &&
          item.driver !== null &&
          typeof item.driver === "object" &&
          item.driver.id !== action.payload
      );
    },
    addLinkedOwner: (state, action: PayloadAction<OwnerVehicleProps>) => {
      state.linkedOwner = action.payload;
    },

    removeLinkedOwner: (state) => {
      state.linkedOwner = null;
    },

    resetVehicleState: (state) => {
      state.singleVehicle = null;
      state.VehiclesList = [];
      state.progress = 0;
      state.documentsList = [];
      state.linkedTrailer = null;
      state.linkedDrivers = [];
      state.linkedOwner = null;
    },
  },
});
export const {
  setAllVehicles,
  setSingleVehicle,
  resetVehicle,
  resetVehicleState,
  setAllVehicleDocuments,
  updateVehicleDocumentsList,
  appendVehicleDocumentsList,
  setUploadProgress,
  filterVehicleDocumentsList,
  resetUploadProgress,
  setAllAvailableVehicles,
  removeLinkedDriver,
  addLinkedDriver,
  addLinkedTrailer,
  removeLinkedTrailer,
  addLinkedOwner,
  removeLinkedOwner,
  setAllAvailableVehiclesForTrailer,
} = vehiclesSlice.actions;
export const selectVehicles = (state: RootState):any =>
  state.vehicles.VehiclesList;
export const selectAllAvailableVehicles = (
  state: RootState
): Array<VehicleProps> => state.vehicles.availableVehiclesList;
export const selectAvailableVehiclesForTrailer = (
  state: RootState
): Array<VehicleProps> => state.vehicles.availableVehiclesForTrailer;

export const selectVehicle = (state: RootState): VehicleProps | null =>
  state.vehicles.singleVehicle;
export const selectDocuments = (state: RootState): Array<DocumentProps> =>
  state.vehicles.documentsList;
export const selectUploadProgress = (state: RootState): number =>
  state.vehicles.progress;
export const selectLinkedDrivers = (
  state: RootState
): Array<DriverVehicleProps> => state.vehicles.linkedDrivers;
export const selectLinkedTrailer = (state: RootState): TrailerProps | null =>
  state.vehicles.linkedTrailer;
  export const selectLinkedOwner = (state: RootState):any =>
  state.vehicles.linkedOwner;
export default vehiclesSlice.reducer;
