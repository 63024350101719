import React from "react";
import { Navigate } from "react-router-dom";
import Customers from "../pages/customers";
import CustomersForm from "../pages/customers/customersForm";
import CustomersReadForm from "../pages/customers/customersReadForm";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import Documents from "../pages/documents";
import CustomerSearch from "../containers/customers/customerSearch";
// import DetailForm from "../components/customer/cutomerReadForm";
import { UserPermissions } from "src/interfaces/user";
import {
  admin,
  create,
  update,
  find,
  featureCustomers,
  featureLookups,
} from "src/utils/enums";

export const customerRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const customerPermission =
    allUserPermissions && allUserPermissions[featureCustomers];
  const createPermission =
    role === admin
      ? 0
      : customerPermission?.findIndex((item) => item === create);

  const updatePermission =
    role === admin
      ? 0
      : customerPermission?.findIndex((item) => item === update);

  const findPermission =
    role === admin ? 0 : customerPermission?.findIndex((item) => item === find);

  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);

  return [
    {
      path: "/customer/lookups/",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/customer/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/customer/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/customer/customer",
      exact: true,
      element:
        role === admin || customerPermission?.length > 0 ? (
          <Customers />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/customer/customer/create",
      exact: true,
      element:
        createPermission > -1 ? <CustomersForm /> : <Navigate to="/404" />,
    },
    {
      path: "/customer/customer/:customerId",
      exact: true,
      element:
        updatePermission > -1 ? <CustomersForm /> : <Navigate to="/404" />,
    },
    {
      path: "/customer/customer/documents/:customerId",
      exact: true,
      element: findPermission ? <Documents /> : <Navigate to="/404" />,
    },
    {
      path: "/customer/customer/details/:customerId",
      exact: true,
      element:
        findPermission > -1 ? <CustomersReadForm /> : <Navigate to="/404" />,
    },
    {
      path: "/customer/customer/search",
      exact: true,
      element:
        findPermission > -1 ? <CustomerSearch /> : <Navigate to="/404" />,
    },
  ];
};
