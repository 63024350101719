import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import TrailerReadFormContainer from "src/containers/trailer/readForm";
const TrailerForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Trailers" description="Porto Trailers" />
      <TrailerReadFormContainer />
    </Grid>
  );
};

export default TrailerForm;
