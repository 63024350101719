import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

//import ShipperConsigneeDetails from "../common/stopOffs";
import ShipperConsigneeDetails from "src/components/load/dispatch/loadStopAddressDetail";
import { CustomerProps } from "src/interfaces/customer";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { useForm } from "react-hook-form";
import {
  getLoadManagementById,
  getMilageCalculations,
  getZipData,
  getAvailableDriverForLoad,
} from "src/redux/actions/loadManagement";

import {
  LoadStopProps,
  LoadStopShipperInformation,
} from "src/interfaces/loadManagement";
import { useStyles } from "src/styles/formStyles";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import LoadStopDispatchDetails from "src/components/load/dispatch/loadStopDispatchDetails";
import { useTranslation } from "react-i18next";
import { addLoadStop, updateLoadStop } from "src/redux/actions/loadManagement";
import { Divider, Typography, Button } from "@mui/material";

interface DialogProps {
  loadId: any;

  open: boolean;
  handleClose: () => void;
  title: string;
  buttonText: string;
  loadStops?: Array<LoadStopProps>;
  setLoadStops?: (index: any) => void;
  disabled: boolean;
  index?: number;
  item: LoadStopProps | any;

  freightBill: any;

  setOpenStopOffFormModal?: (index: any) => void;
  handleSetMilageValue?: (index: any) => void;

  dispatchTabOpen?: boolean;
  // ComponentToRender?: React.ReactNode;
}

export default function LoadStopInfo({
  loadId,
  loadStops,
  setLoadStops,
  handleSetMilageValue,
  open,
  handleClose,
  title,
  disabled,
  index,
  item,
  freightBill,
  buttonText,
  setOpenStopOffFormModal,
  dispatchTabOpen,
}: // ComponentToRender,
DialogProps): JSX.Element {
  const classes = useStyles();
  const params = useParams();
  const [translation] = useTranslation("translation");
  const [openTerminalModal, setOpenTerminalModal] = useState<boolean>(false);
  const [openConsigneeModel, setOpenConsineeModel] = useState<boolean>(false);
  const [loadStopItem, setLoadStopItem] = useState<LoadStopProps>(item);
  const [driverArray, setDriverArray] = useState<Array<any>>([]);
  const [trailer, setTrailer] = useState<any>({});
  const [vehicle, setVehicle] = useState<any>({});
  const [showCustomerAllFields, setShowCustomerAllFields] = useState<boolean>(
    false
  );
  const [showAddressDetails, setShowAddressDetails] = useState<boolean>(true);
  const [showDispatchDetails, setShowDispatchDetails] = useState<boolean>(
    false
  );
  const dispatch = useAppDispatch();
  const [loadStoploading, setLoadStopLoading] = useState(false);
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    setLoadStopItem(item);
  }, [item]);

  useEffect(() => {
    if (dispatchTabOpen) {
      setShowDispatchDetails(true);
      setShowAddressDetails(false);
    } else {
      setShowDispatchDetails(false);
      setShowAddressDetails(true);
    }
  }, [dispatchTabOpen]);

  const handleShowAddressDetails = () => {
    setShowAddressDetails(true);
    setShowDispatchDetails(false);
  };
  const handleShowDispatchDetails = () => {
    setShowAddressDetails(false);
    setShowDispatchDetails(true);
  };
  const handleLoadStopChange = (value: any, key: keyof LoadStopProps) => {
    const newItem = Object.assign({}, loadStopItem);

    if (key == "companyDriver") {
      setVehicle({});
      setTrailer({});
      newItem["vehicle"] = null;
      newItem["trailer"] = null;
      newItem["driver"] = null;
    }
    // @ts-ignore: Unreachable code error

    newItem[key] = value;

    setLoadStopItem(newItem);
  };
  const handleLoadStopChangeMilage = (
    value: any,
    key: keyof LoadStopProps,
    value1: any,
    key1: keyof LoadStopProps
  ) => {
    const newItem = Object.assign({}, loadStopItem);

    // @ts-ignore: Unreachable code error

    newItem[key] = value;
    newItem[key1] = value1;
    setLoadStopItem(newItem);
  };

  const submitLoadStop = async () => {
    setLoadStopLoading(true);
    const _newItem = Object.assign({}, loadStopItem);

    _newItem["loadId"] = loadId;

    if (_newItem.id) {
      const response = await dispatch(updateLoadStop(_newItem, _newItem?.id));
      if (response.success && params?.loadId) {
        // getSingleLineItem(parseInt(params?.loadId)),
        dispatch(getLoadManagementById(parseInt(loadId)));
      }
      if (
        loadStops &&
        setLoadStops &&
        index !== undefined &&
        response?.success
      ) {
        const _loadStops = [...loadStops];

        _loadStops[index] = { ..._newItem };
        setLoadStops([..._loadStops]);
      }
    } else {
      await dispatch(addLoadStop(_newItem));
      if (_newItem?.splitType && loadId) {
        loadId && (await dispatch(getLoadManagementById(+loadId)));
        setOpenStopOffFormModal && setOpenStopOffFormModal(null);
      }
    }

    setOpenStopOffFormModal && setOpenStopOffFormModal(null);
    setLoadStopLoading(false);

    // delete item.serviceLevel;
  };
  const handleLoadStopChangeForShipper = async (
    value: number | null | string | any,
    key: keyof LoadStopShipperInformation,
    shipper: boolean,
    autofill?: boolean
  ) => {
    const newItem = Object.assign({}, loadStopItem);
    newItem["consigneeAddress"] = loadStopItem["consigneeAddress"];
    newItem["shipperAddress"] = loadStopItem["shipperAddress"];

    if (shipper === true) {
      if (
        key === "zip" &&
        value != null &&
        Object.keys(value).length != 0 &&
        newItem?.consigneeAddress?.zip &&
        newItem?.consigneeAddress?.zip != null &&
        !newItem?.id &&
        index == 0 &&
        handleSetMilageValue
      ) {
        await dispatch(
          getMilageCalculations(value, newItem?.consigneeAddress?.zip)
        ).then((res:any) => {
          // @ts-ignore: Unreachable code error

          handleSetMilageValue && res?.data && handleSetMilageValue(res?.data);
        });
      }

      if (
        key === "zip" &&
        value != null &&
        Object.keys(value).length != 0 &&
        value?.length == 5 &&
        !autofill
      ) {
        await dispatch(getZipData(value)).then((res: any) => {
          // @ts-ignore: Unreachable code error
          newItem.shipperAddress["city"] = res?.data?.city;
          // @ts-ignore: Unreachable code error

          newItem.shipperAddress["state"] = res?.data?.state;
          // res?.data && setValue("freightBill.mileage", res?.data);
        });
      }
      // @ts-ignore: Unreachable code error
      newItem["shipperAddress"][key] = value;
      // @ts-ignore: Unreachable code error
      setLoadStopItem(newItem);
    } else {
      if (
        key === "zip" &&
        value != null &&
        Object.keys(value).length != 0 &&
        newItem?.shipperAddress?.zip &&
        newItem?.shipperAddress?.zip != null &&
        !newItem?.id &&
        index == 0 &&
        handleSetMilageValue
      ) {
        await dispatch(
          getMilageCalculations(newItem?.shipperAddress?.zip, value)
        ).then((res: any) => {
          // @ts-ignore: Unreachable code error

          handleSetMilageValue && res?.data && handleSetMilageValue(res?.data);
        });
      }
      if (
        key === "zip" &&
        value != null &&
        Object.keys(value).length != 0 &&
        value?.length == 5 &&
        !autofill
      ) {
        await dispatch(getZipData(value)).then((res: any) => {
          // @ts-ignore: Unreachable code error
          newItem.consigneeAddress["city"] = res?.data?.city;
          // @ts-ignore: Unreachable code error

          newItem.consigneeAddress["state"] = res?.data?.state;
        });
      }
      // @ts-ignore: Unreachable code error
      newItem["consigneeAddress"][key] = value;
      setLoadStopItem(newItem);
    }
  };
  const handleSelectCustomer = (
    event: any,
    newValue: CustomerProps | null,
    shipper: boolean
  ) => {
    // @ts-ignore: Unreachable code error
    handleLoadStopChangeForShipper(
      newValue?.address ? newValue.address : "",
      "address",
      shipper
    );

    handleLoadStopChangeForShipper(
      newValue?.zip ? newValue.zip : "",
      "zip",
      shipper,
      true
    );
    handleLoadStopChangeForShipper(
      newValue?.phoneNumber ? newValue.phoneNumber : "",
      "phoneNumber",
      shipper
    );
    handleLoadStopChangeForShipper(
      newValue?.faxPhoneNumber ? newValue.faxPhoneNumber : "",
      "faxPhoneNumber",
      shipper
    );
    handleLoadStopChangeForShipper(
      newValue?.email ? newValue.email : "",
      "email",
      shipper
    );
    handleLoadStopChangeForShipper(
      newValue?.state ? newValue.state : "",
      "state",
      shipper
    );
    handleLoadStopChangeForShipper(
      newValue?.city ? newValue.city : "",
      "city",
      shipper
    );
    handleLoadStopChangeForShipper(
      newValue?.companyName ? newValue.companyName : "",
      "company",
      shipper
    );
    handleLoadStopChangeForShipper(
      newValue?.id ? newValue : "",
      "customer",
      shipper
    );
    // @ts-ignore: Unreachable code error
  };
  const getAvailableDriver = async (id: number) => {
    await dispatch(getAvailableDriverForLoad(id)).then((res:any) => {
      setDriverArray([res?.data]);
    });
  };

  const handleSelectStopOffDriver = (
    value: any | null,
    key: keyof LoadStopProps
  ) => {
    const newItem = Object.assign({}, loadStopItem);

    if (value != null && Object.keys(value).length !== 0) {
      getAvailableDriver(parseInt(value?.id));
    }

    if (
      value?.driverVehicleLink?.length > 0 &&
      value?.driverVehicleLink[0]?.vehicle
    ) {
      setVehicle(value?.driverVehicleLink[0]?.vehicle);
      // @ts-ignore: Unreachable code error
      newItem["vehicle"] = value?.driverVehicleLink[0]?.vehicle;
    }
    if (
      value?.driverVehicleLink?.length > 0 &&
      value?.driverVehicleLink[0]?.vehicle?.trailer
    ) {
      setTrailer(value?.driverVehicleLink[0]?.vehicle?.trailer);
      // @ts-ignore: Unreachable code error
      newItem["trailer"] = value?.driverVehicleLink[0]?.vehicle?.trailer;
    }

    // @ts-ignore: Unreachable code error
    newItem[key] = value;
    setLoadStopItem(newItem);
  };
  const handleSelectStopOffVehicleTrailer = (
    value: any,
    key: keyof LoadStopProps
  ) => {
    const newItem = Object.assign({}, loadStopItem);
    // @ts-ignore: Unreachable code error

    newItem[key] = value;
    setLoadStopItem(newItem);
  };
  return (
    <div>
      <Dialog
        open={open !== null ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className={classes.loadFormHeadings}>{title}</DialogTitle>
        <Divider />

        {/* <CustomFormGrid container className={classes.loadStopTabContainer}>
          <CustomFormGrid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            onClick={() => handleShowAddressDetails()}
          >
            <Typography
              className={
                showAddressDetails === false
                  ? classes.loadStopTabHeadings
                  : classes.loadStopTabHeadingsSelected
              }
            >
              {translation("loadStop.address.label")}
            </Typography>
            {showAddressDetails === true && (
              <div
                style={{ background: "rgba(0, 125, 255, 1)", height: "1px" }}
              ></div>
            )}
          </CustomFormGrid>
          <CustomFormGrid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            onClick={() => handleShowDispatchDetails()}
          >
            <Typography
              className={
                showDispatchDetails === false
                  ? classes.loadStopTabHeadings
                  : classes.loadStopTabHeadingsSelected
              }
            >
              {translation("loadStop.other.label")}
            </Typography>
            {showDispatchDetails === true && (
              <div
                style={{ background: "rgba(0, 125, 255, 1)", height: "1px" }}
              ></div>
            )}
          </CustomFormGrid>
        </CustomFormGrid> */}

        <DialogContent>
          <CustomFormGrid container justifyContent="center">
            {/* {showAddressDetails === true && (
              <CustomFormGrid item xs={12} md={12} sm={12} lg={12}>
                <ShipperConsigneeDetails
                  control={control}
                  errors={errors}
                  watch={watch}
                  item={loadStopItem}
                  handleLoadStopChange={handleLoadStopChange}
                  handleLoadStopChangeMilage={handleLoadStopChangeMilage}
                  handleSelectCustomer={handleSelectCustomer}
                  setValue={setValue}
                  handleLoadStopChangeForShipper={
                    handleLoadStopChangeForShipper
                  }
                  buttonText={buttonText}
                  openTerminalModal={openTerminalModal}
                  setOpenTerminalModal={setOpenTerminalModal}
                  setOpenConsineeModel={setOpenConsineeModel}
                  openConsigneeModel={openConsigneeModel}
                  setShowCustomerAllFields={setShowCustomerAllFields}
                  showCustomerAllFields={showCustomerAllFields}
                  disabled={disabled}
                />
              </CustomFormGrid>
            )} */}

            <CustomFormGrid item xs={12} md={12} sm={12} lg={12}>
              <LoadStopDispatchDetails
                control={control}
                errors={errors}
                watch={watch}
                item={loadStopItem}
                handleLoadStopChange={handleLoadStopChange}
                setValue={setValue}
                handleSelectStopOffDriver={handleSelectStopOffDriver}
                handleSelectStopOffTrailer={handleSelectStopOffVehicleTrailer}
                handleSelectStopOffVehicle={handleSelectStopOffVehicleTrailer}
                freightBill={freightBill}
                setOpenStopOffFormModal={setOpenStopOffFormModal}
                driverArray={driverArray}
                vehicle={vehicle}
                trailer={trailer}
                disabled={disabled}
                handleLoadStopChangeMilage={handleLoadStopChangeMilage}
                handleSelectCustomer={handleSelectCustomer}
                handleLoadStopChangeForShipper={handleLoadStopChangeForShipper}
                buttonText={buttonText}
                openTerminalModal={openTerminalModal}
                setOpenTerminalModal={setOpenTerminalModal}
                setOpenConsineeModel={setOpenConsineeModel}
                openConsigneeModel={openConsigneeModel}
                setShowCustomerAllFields={setShowCustomerAllFields}
                showCustomerAllFields={showCustomerAllFields}
              />
            </CustomFormGrid>
          </CustomFormGrid>
        </DialogContent>

        <DialogActions>
          <CustomFormGrid container justifyContent="center">
            <Button
              color="primary"
              variant="contained"
              disabled={disabled}
              onClick={() => {
                if (item.id) {
                  submitLoadStop();
                } else {
                  if (item?.splitType) {
                    submitLoadStop();
                  } else {
                    const _newItem = Object.assign({}, loadStopItem);

                    _newItem["loadId"] = loadId;
                    if (loadStops && setLoadStops && index !== undefined) {
                      const _loadStops = [...loadStops];
                      // @ts-ignore: Unreachable code error
                      _loadStops[index] = { ..._newItem };
                      setLoadStops([..._loadStops]);
                    }
                    setOpenStopOffFormModal && setOpenStopOffFormModal(null);
                  }
                }
              }}
              className={classes.formButton}
            >
              {loadStoploading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />
              ) : item.id ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() =>
                setOpenStopOffFormModal && setOpenStopOffFormModal(null)
              }
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
