import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { LoadManagementProps } from "src/interfaces/loadManagement";

export interface LoadManagementState {
  loadManagementsList:any;
  singleLoadManagement: LoadManagementProps | null;
}

const initialState: LoadManagementState = {
  loadManagementsList: {},
  singleLoadManagement: null,
};

export const loadManagementsSlice = createSlice({
  name: "loadManagement",
  initialState,

  reducers: {
    setAllLoadManagements: (
      state,
      action: PayloadAction<Array<LoadManagementProps>>
    ) => {
      state.loadManagementsList = action.payload;
    },
    setSingleLoadManagement: (
      state,
      action: PayloadAction<LoadManagementProps>
    ) => {
      state.singleLoadManagement = action.payload;
    },
    resetLoadManagement: (state) => {
      state.singleLoadManagement = null;
    },
    resetLoadManagementState: (state) => {

      state.singleLoadManagement = null;
      state.loadManagementsList = {};

    },
  },
});
export const {
  setAllLoadManagements,
  setSingleLoadManagement,
  resetLoadManagement,
  resetLoadManagementState,
} = loadManagementsSlice.actions;
export const selectLoadManagements = (
  state: RootState
): any => state.loadManagement.loadManagementsList;
export const selectLoadManagement = (
  state: RootState
): LoadManagementProps | null => state.loadManagement.singleLoadManagement;
export default loadManagementsSlice.reducer;
