import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
//import { LoadManagementProps } from "src/interfaces/loadManagement";


export interface DocumentState {
  documentList: any;
  processingList: any;
  printList: any;
  factoringInvoices: any;
}

 const initialState: DocumentState = {
   documentList: {},
   processingList: {},
   printList: {},
   factoringInvoices: {},
};

export const waitingForDocument = createSlice({
  name: "waitingForDocument",
  initialState,

  reducers: {

    setAllWaitingDocuments: (state, action: PayloadAction<Array<any>>) => {
      state.documentList = {...action.payload};
    },
    setAllProcessingDocs: (state, action: PayloadAction<Array<any>>) => {
      state.processingList = action.payload;
    },
    setAllPrintDocs : (state, action: PayloadAction<Array<any>>) => {
      state.printList = action.payload;
    },
    setAllFactoringInvoices : (state, action: PayloadAction<Array<any>>) => {
      state.factoringInvoices = action.payload;
    },
  },
});
export const {
  setAllWaitingDocuments,
  setAllProcessingDocs,
  setAllPrintDocs,
  setAllFactoringInvoices,
} = waitingForDocument.actions;
export const selectDocuments = (state: RootState): any =>
  state.waitingForDocument.documentList;
  export const selectProcessingList = (state: RootState): any =>
  state.waitingForDocument.processingList;
  export const selectPrintList = (state: RootState): any =>
    state.waitingForDocument.printList;
    export const selectFactoringInvoices = (state: RootState): any =>
    state.waitingForDocument.factoringInvoices;
export default waitingForDocument.reducer;
