import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Signup from "src/components/signup";
import { signup } from "src/redux/actions/auth";
import { useAppDispatch } from "src/hooks/reduxHooks";

const SignupContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
/*SignupCredProps  */
  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await dispatch(signup(data));
    if (response.success) {
      navigate("/dashboard");
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Signup onSubmit={onSubmit} loading={loading} />
    </>
  );
};
export default SignupContainer;
