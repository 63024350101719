import React, { useState } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Autocomplete, Typography } from "@mui/material";
import { selectAvailableDrivers } from "src/redux/reducers/driver";
import { selectAvailableTrailers } from "src/redux/reducers/trailer";
import { MobileDateTimePicker } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import { selectAllAvailableVehicles } from "src/redux/reducers/vehicle";
import { selectUsers } from "src/redux/reducers/users";
import moment from "moment";
import { yearFormat } from "src/utils/constants";
import { selectUser } from "src/redux/reducers/auth";
import ShipperConsigneeDetails from "src/components/load/dispatch/loadStopAddressDetail";
import { useAppSelector } from "src/hooks/reduxHooks";
import { stopTypes } from "src/utils/dropdowns/load";
import { dispatchType } from "src/utils/dropdowns/load";
import { dispatchWho } from "src/utils/dropdowns/load";
import { dispatchStatus } from "src/utils/dropdowns/load";
import { DispatchPay } from "src/utils/dropdowns/load";
import { DispatchPayDriver } from "src/utils/dropdowns/load";
import { MenuItem } from "@mui/material";
import { InputLabel } from "@mui/material";
import CustomSwitch from "../../customElements/customSwitch";
import {
  LoadStopProps,
  LoadStopShipperInformation,
} from "src/interfaces/loadManagement";
import { LookupProps } from "src/interfaces/lookup";
import { CustomerProps } from "src/interfaces/customer";

import { selectLookups } from "src/redux/reducers/lookup";
import { selectCommodities } from "src/redux/reducers/commodity";
import "react-phone-input-2/lib/style.css";
import { useStyles } from "src/styles/formStyles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorIcon from "@mui/icons-material/Error";
interface StopOffsProps {
  control: any;
  errors: { [key: string]: any };
  buttonText: string;
  watch: (key: string) => any;
  item: LoadStopProps;
  setValue: any;
  handleLoadStopChange: (value: any, key: keyof LoadStopProps) => void;

  handleSelectStopOffDriver: (
    value: number | null | string,
    key: keyof LoadStopProps
  ) => any;
  freightBill: any;
  // handleAddLoadStop: (index?: number) => void;
  showAllFields?: boolean;
  setOpenStopOffFormModal?: (index: any) => void;
  driverArray: any;
  vehicle: any;
  trailer: any;
  handleSelectStopOffVehicle: (value: any, key: keyof LoadStopProps) => any;
  handleSelectStopOffTrailer: (value: any, key: keyof LoadStopProps) => any;
  disabled: boolean;

  handleLoadStopChangeMilage: (
    value: any,
    key: keyof LoadStopProps,
    value1: any,
    key1: keyof LoadStopProps
  ) => void;

  handleLoadStopChangeForShipper: (
    value: string,
    key: keyof LoadStopShipperInformation,
    shipper: boolean
  ) => void;
  handleSelectCustomer: (
    event: any,
    newValue: CustomerProps | null,
    shipper: boolean
  ) => any;
  // handleAddLoadStop: (index?: number) => void;
  openConsigneeModel?: boolean;
  openTerminalModal?: boolean;
  showCustomerAllFields?: boolean;
  setOpenConsineeModel?: (index: any) => void;
  setOpenTerminalModal?: (index: any) => void;
  setShowCustomerAllFields?: (index: any) => void;
}

const StopOffs = ({
  control,
  errors,
  handleLoadStopChange,
  watch,
  handleSelectStopOffDriver,
  handleSelectStopOffVehicle,
  handleSelectStopOffTrailer,
  freightBill,
  driverArray,
  vehicle,
  trailer,
  setValue,
  handleLoadStopChangeMilage,
  item,
  disabled,
  setOpenConsineeModel,
  openConsigneeModel,
  setOpenTerminalModal,
  openTerminalModal,
  setShowCustomerAllFields,
  showCustomerAllFields,
  handleSelectCustomer,
  handleLoadStopChangeForShipper,
  buttonText,
}: //handleAddLoadStop,
StopOffsProps): JSX.Element => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const allLookups = useAppSelector(selectLookups);
  const user = useAppSelector(selectUser);

  const availableDrivers = useAppSelector(selectAvailableDrivers);
  const availableVehicles = useAppSelector(selectAllAvailableVehicles);
  const availableTrailers = useAppSelector(selectAvailableTrailers);
  const dispatcher = useAppSelector(selectUsers);
  const dispatchDateRef = React.useRef<any>();
  const [totalDispatchPay, setTotalDispatchPay] = React.useState<number>(0);
  const [totalfreightPay, setTotalfreightPay] = React.useState<number>(0);
  const [totalLoadPay, setTotalLoadPay] = React.useState<number>(0);
  const [totalFlatRate, setTotalFlatRate] = React.useState<number>(0);
  const [grossProfit, setGrossProfit] = React.useState<number>(0);
  const [grossProfitPercentage, setGrossProfitPercentage] = React.useState<
    number | any
  >(0);

  const isMobile = useMediaQuery("(max-width:650px)");

  const selectdispatchDate = () => {
    if (dispatchDateRef?.current) {
      dispatchDateRef?.current?.click();
    }
  };

  /* const serviceLevels: Array<LookupProps> =
    allLookups && allLookups?.data["service-level"]?.lookups
      ? allLookups?.data["service-level"]?.lookups
      : []; */
  const commodities = useAppSelector(selectCommodities);

  /*   const onChangePhoneInput = (
    value: string,
    country: string,
    key: string,
    index: number,
    shipper: boolean,
    fieldName: string
  ) => {
    const phoneNumber = formattedPhoneNumber(value, country);
    setValue(`stops[${index}].${key}Value`, value);
    setValue(`stops[${index}].${key}Country`, country);
    // @ts-ignore: Unreachable code error
    handleLoadStopChangeForShipper(phoneNumber, index, fieldName, shipper);
    if (errors && errors?.stops) {
      delete errors?.stops[index][key];
    }
  }; */

  React.useEffect(() => {
    if (item.companyDriver === true) {
      if (item.dispatchPay == "flatRate") {
        if (item?.freight && item?.fuel) {
          setTotalDispatchPay(Number(item?.freight) + Number(item?.fuel));
        } else if (item?.freight) {
          setTotalDispatchPay(Number(item?.freight));
        } else if (item?.fuel) {
          setTotalDispatchPay(Number(item?.fuel));
        }
      } else if (item.dispatchPay == "perHour") {
        if (item.rate && item.quantity) {
          setTotalDispatchPay(Number(item?.rate) * Number(item?.quantity));
        }
      } else if (item.dispatchPay == "perMile") {
        if (item.rate && item.quantity) {
          setTotalDispatchPay(Number(item?.rate) * Number(item?.quantity));
        }
      } else if (item.dispatchPay == "percentOfFreight") {
        if (item.percent) {
          setTotalDispatchPay(
            (Number(item?.percent) / 100) * Number(freightBill?.freightBill)
          );
          setTotalfreightPay(
            (Number(item?.percent) / 100) * Number(freightBill?.freightBill)
          );
        }
      } else {
        setTotalfreightPay(0);
        setTotalDispatchPay(0);
      }
    } else {
      if (item.dispatchPay == "flatRate") {
        if (item?.flat) {
          setTotalFlatRate(Number(item?.flat));
        }
      }
    }
  }, [
    item.freight,
    item.fuel,
    item.rate,
    item.flat,
    item.quantity,
    item.percent,
    freightBill,
    item.dispatchPay,
    item.companyDriver,
  ]);

  React.useEffect(() => {
    if (item.companyDriver === true) {
      if (
        item.dispatchPay === "flatRate" ||
        item.dispatchPay === "perHour" ||
        (item.dispatchPay == "perMile" && totalDispatchPay > 0)
      ) {
        setTotalLoadPay(totalDispatchPay);

        const grossProfitTotal =
          Number(freightBill?.grandTotal) - Number(totalDispatchPay);

        setGrossProfit(grossProfitTotal);

        const grossPercentage = (
          ((Number(freightBill?.grandTotal) - Number(totalDispatchPay)) /
            Number(freightBill?.grandTotal)) *
          100
        ).toFixed(2);
        setGrossProfitPercentage(grossPercentage);
      } else if (item.dispatchPay === "percentOfFreight") {
        if (freightBill?.fuel) {
          const totalPayPerOfFreight =
            Number(totalfreightPay) + Number(freightBill?.fuel);
          setTotalLoadPay(totalPayPerOfFreight);
          const grossProfitTotal =
            Number(freightBill?.grandTotal) - Number(totalPayPerOfFreight);
          setGrossProfit(grossProfitTotal);
          const grossPercentage = (
            ((Number(freightBill?.grandTotal) - Number(totalLoadPay)) /
              Number(freightBill?.grandTotal)) *
            100
          ).toFixed(2);
          setGrossProfitPercentage(grossPercentage);
        } else {
          const grossProfitTotal =
            Number(freightBill?.grandTotal) - Number(totalDispatchPay);

          setGrossProfit(grossProfitTotal);
          const grossPercentage = (
            ((Number(freightBill?.grandTotal) - Number(totalDispatchPay)) /
              Number(freightBill?.grandTotal)) *
            100
          ).toFixed(2);
          setGrossProfitPercentage(grossPercentage);
        }
      } else {
        setTotalLoadPay(0);
        setTotalLoadPay(totalfreightPay);
      }
    } else {
      if (item.dispatchPay === "flatRate") {
        setTotalLoadPay(totalFlatRate);
        const grossProfitTotal =
          Number(freightBill?.grandTotal) - Number(totalFlatRate);

        setGrossProfit(grossProfitTotal);
        const grossPercentage = (
          ((Number(freightBill?.grandTotal) - Number(totalFlatRate)) /
            Number(freightBill?.grandTotal)) *
          100
        ).toFixed(2);
        setGrossProfitPercentage(grossPercentage);
      }
    }
  }, [totalfreightPay, totalDispatchPay, totalFlatRate]);

  return (
    <CustomFormGrid
      container
      spacing={isMobile ? 0 : 2}
      style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px" }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/*********Dispatch Details******* */}

        <CustomFormGrid container spacing={2}>
          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.loadStop.dispatchDetails")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="dispatchStatus"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  id="dispatchStatus"
                  label={translation("load.form.dispatchStatus.label")}
                  error={Boolean(errors?.stopType)}
                  select
                  disabled={disabled}
                  // disabled={item.dispatchStatus === "planned" ? true : false}
                  helperText={
                    errors?.dispatchStatus &&
                    translation("load.form.dispatchStatus.message")
                  }
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "dispatchStatus")
                  }
                  value={item.dispatchStatus}
                >
                  {dispatchStatus &&
                    dispatchStatus.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="dispatchType"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="dispatchType"
                  label={translation("load.form.dispatchType.label")}
                  error={Boolean(errors?.stopType)}
                  select
                  helperText={
                    errors?.dispatchType &&
                    translation("load.form.dispatchType.message")
                  }
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "dispatchType")
                  }
                  value={item.dispatchType}
                >
                  {dispatchType &&
                    dispatchType.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="companyDriver"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="companyDriver"
                  label={translation("load.form.dispatchWho.label")}
                  error={Boolean(errors?.stopType)}
                  select
                  helperText={
                    errors?.dispatchType &&
                    translation("load.form.dispatchType.message")
                  }
                  onChange={(e: any) => {
                    handleLoadStopChange(
                      e.target.value == "companyDriver" ? false : true,
                      "companyDriver"
                    );
                  }}
                  value={
                    item.companyDriver === true
                      ? "ownerOperator"
                      : "companyDriver"
                  }
                >
                  {dispatchWho &&
                    dispatchWho.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="driver"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="combo-box-demo"
                  fullWidth
                  disabled={disabled}
                  options={availableDrivers?.filter(
                    (value: any) => value?.companyDriver != item?.companyDriver
                  )}
                  onChange={(event: any, newValue) =>
                    handleSelectStopOffDriver(newValue, "driver")
                  }
                  defaultValue={item.driver}
                  getOptionLabel={(option) => option?.firstName}
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      id="driver"
                      size="small"
                      label={translation("load.loadStop.driver.label")}
                      error={Boolean(errors?.customer)}
                      helperText={
                        errors?.customer && translation("load.search.message")
                      }
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
            <Controller
              name="vehicle"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disabled={disabled}
                  id="combo-box-demo"
                  fullWidth
                  options={availableVehicles}
                  onChange={(event: any, newValue) =>
                    handleSelectStopOffVehicle(newValue, "vehicle")
                  }
                  // defaultValue={item.vehicle}
                  value={
                    Object.keys(vehicle)?.length !== 0 ? vehicle : item.vehicle
                  }
                  getOptionLabel={(option) =>
                    option?.vehicleID +
                    "-" +
                    (option?.model != null ? option?.model + "-" : "") +
                    moment(option?.year).format(yearFormat)
                  }
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      id="driver"
                      size="small"
                      label={translation("load.loadStop.vehicle.label")}
                      error={Boolean(errors?.vehicle)}
                      helperText={
                        errors?.vehicle && translation("load.search.message")
                      }
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
            <Controller
              name="trailer"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disabled={disabled}
                  id="combo-box-demo"
                  fullWidth
                  options={availableTrailers}
                  onChange={(event: any, newValue) =>
                    handleSelectStopOffTrailer(newValue, "trailer")
                  }
                  //   defaultValue={item.trailer}
                  value={
                    Object.keys(trailer)?.length !== 0 ? trailer : item?.trailer
                  }
                  getOptionLabel={(option) =>
                    option?.trailerId +
                    "-" +
                    option?.VIN +
                    "-" +
                    moment(option?.year).format(yearFormat)
                  }
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      id="trailer"
                      size="small"
                      label={translation("load.loadStop.trailer.label")}
                      error={Boolean(errors?.customer)}
                      helperText={
                        errors?.customer && translation("load.search.message")
                      }
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {driverArray[0]?.length > 0 &&
            driverArray?.map((load: any, index: any) => (
              <CustomFormGrid key={index} item sm={12} xs={12} md={12} lg={12}>
                <CustomFormGrid
                  container
                  className={classes.errorMessageContainer}
                >
                  <ErrorIcon className={classes.errorIcon} />
                  <CustomFormGrid item xs={8} md={8} lg={8} sm={8}>
                    <Typography className={classes.errorMessageBold}>
                      We Found something you should be aware of:
                    </Typography>
                    <Typography className={classes.errorMessage}>
                      Driver is currently dispatched on load ID:{" "}
                      {`${load[0]?.loadId}`}
                    </Typography>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomFormGrid>
            ))}

          <CustomFormGrid item sm={12} xs={12} md={3} lg={6}>
            <Controller
              name="dispatcher"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disabled={disabled}
                  id="combo-box-demo"
                  fullWidth
                  options={dispatcher?.data}
                  onChange={(event: any, newValue) =>
                    handleSelectStopOffDriver(newValue, "dispatcher")
                  }
                  defaultValue={item?.dispatcher ? item?.dispatcher : user}
                  getOptionLabel={(option) => option?.firstName}
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      id="dispatcher"
                      size="small"
                      label={translation("load.loadStop.dispatcher.label")}
                      error={Boolean(errors?.customer)}
                      helperText={
                        errors?.customer && translation("load.search.message")
                      }
                      required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={6}>
            <Controller
              name="dispatchDate"
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <MobileDateTimePicker
                  {...field}
                  label={translation("load.form.dispatchDate.label")}
                  // disablePast
                  disabled={disabled}
                  inputFormat="MM/dd/yyyy hh:mm a"
                  value={item.dispatchDate ? item.dispatchDate : new Date()}
                  onChange={(e: any) => {
                    handleLoadStopChange(e, "dispatchDate");
                  }}
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      id="dispatchDate"
                      fullWidth
                      error={Boolean(errors?.dispatchDate)}
                      inputRef={dispatchDateRef}
                      helperText={
                        errors?.dispatchDate &&
                        translation("load.form.dispatchDate.message")
                      }
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={selectdispatchDate}>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>

        <CustomFormGrid container spacing={2}>
          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={3}
            lg={6}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Controller
              name="dropTrailer"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <InputLabel>
                    {translation("load.form.dropThisTrailer.label")}
                  </InputLabel>
                  <CustomSwitch
                    checked={
                      watch("dropTrailer") ? watch("dropTrailer") : false
                    }
                    disabled={disabled}
                    {...field}
                    id="dropTrailer "
                  />
                </>
              )}
            />
          </CustomFormGrid>
          <ShipperConsigneeDetails
            control={control}
            errors={errors}
            watch={watch}
            item={item}
            handleLoadStopChange={handleLoadStopChange}
            handleLoadStopChangeMilage={handleLoadStopChangeMilage}
            handleSelectCustomer={handleSelectCustomer}
            setValue={setValue}
            handleLoadStopChangeForShipper={handleLoadStopChangeForShipper}
            buttonText={buttonText}
            openTerminalModal={openTerminalModal}
            setOpenTerminalModal={setOpenTerminalModal}
            setOpenConsineeModel={setOpenConsineeModel}
            openConsigneeModel={openConsigneeModel}
            setShowCustomerAllFields={setShowCustomerAllFields}
            showCustomerAllFields={showCustomerAllFields}
            disabled={disabled}
          />

          {/* ********************************Shipper Address ***************/}

          {/*********Miles******* */}
          {/*  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.loadStop.miles")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="fuelMiles"
              control={control}
              defaultValue={""}
              // rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  value={item.fuelMiles}
                  id="fuelMiles"
                  label={translation("load.form.fuelMiles.label")}
                  error={Boolean(errors?.weight)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "fuelMiles")
                  }
                  required
                  helperText={
                    errors?.weight && translation("load.form.fuelMiles.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="moveMiles"
              control={control}
              defaultValue={""}
              // rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  value={item.moveMiles}
                  id="moveMiles"
                  label={translation("load.form.moveMiles.label")}
                  error={Boolean(errors?.weight)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "moveMiles")
                  }
                  required
                  helperText={
                    errors?.weight && translation("load.form.moveMiles.message")
                  }
                />
              )}
            />
          </CustomFormGrid> */}

          {/******************************Other************************** */}

          {/*  <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="stopType"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="stopType"
                  label={translation("load.form.stopType.label")}
                  error={Boolean(errors?.stopType)}
                  select
                  helperText={
                    errors?.stopType &&
                    translation("load.form.stopType.message")
                  }
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "type")
                  }
                  value={item.type}
                >
                  {stopTypes &&
                    stopTypes.map((item, index) => (
                      <MenuItem value={item.value} key={index}>
                        {item.label}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid> */}
          {/* <CustomFormGrid
        item
        sm={12}
        xs={12}
        md={3}
        lg={3}
        className={classes.phoneField}
      >
        <Controller
          name={`stops[${index}].contact`}
          control={control}
          defaultValue=""
          rules={{
            validate: {
              isValid: () =>
                watch(`stops[${index}].contactValue`)
                  ? phone(watch(`stops[${index}].contactValue`), {
                      country: watch(`stops[${index}].contactCountry`),
                    })?.phoneNumber !== null || "Enter Valid Phone Number"
                  : true,
            },
          }}
          render={() => (
            <PhoneInput
              placeholder={translation("common.contact.label")}
              value={item.contact ? item.contact : ""}
              error={Boolean(errors?.stops && errors?.stops[index]?.contact)}
              onChange={(value: string, data: any) => {
                onChangePhoneInput(value, data.countryCode, `contact`, index);
              }}
              isValid={(value: any, country: any) => {
                return errors?.stops && errors?.stops[index]?.contact
                  ? validatePhoneNumber(value, country.iso2)
                  : true;
              }}
            />
          )}
        />
      </CustomFormGrid> */}
          {/*   <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="serviceLevel"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="serviceLevel"
                  value={item.serviceLevel}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "serviceLevel")
                  }
                  label={translation("load.form.serviceLevel.label")}
                  error={Boolean(errors?.serviceLevel)}
                  helperText={
                    errors?.serviceLevel &&
                    translation("load.form.serviceLevel.message")
                  }
                  select
                >
                  {serviceLevels &&
                    serviceLevels.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="commodity"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="commodity"
                  value={item.commodity}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "commodity")
                  }
                  label={translation("load.form.commodity.label")}
                  error={Boolean(errors?.commodity)}
                  helperText={
                    errors?.commodity &&
                    translation("load.form.commodity.message")
                  }
                  select
                >
                  {commodities?.data?.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.commodity}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="reference"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  type="number"
                  inputProps={{ min: 0 }}
                  id="reference"
                  value={item.reference}
                  label={translation("load.form.reference.label")}
                  error={Boolean(errors?.reference)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "reference")
                  }
                  helperText={
                    errors?.reference &&
                    translation("load.form.reference.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="pieces"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  inputProps={{ min: 0 }}
                  id="pieces"
                  value={item.pieces}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "pieces")
                  }
                  label={translation("load.form.pieces.label")}
                  error={Boolean(errors?.pieces)}
                  helperText={
                    errors?.pieces && translation("load.form.pieces.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="weight"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  disabled={disabled}
                  size="small"
                  fullWidth
                  type="number"
                  value={item.weight}
                  id="weight"
                  label={translation("load.form.weight.label")}
                  error={Boolean(errors?.weight)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "weight")
                  }
                  helperText={
                    errors?.weight && translation("load.form.weight.message")
                  }
                />
              )}
            />
          </CustomFormGrid> */}
          {/* <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
        <Typography>{translation("load.loadStop.dispatchPay")}</Typography>
      </CustomFormGrid>
      <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
        <Controller
          name="total"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <CustomTextField
              {...field}
              size="small"
              fullWidth
              type="number"
              inputProps={{ min: 0 }}
              // value={item.dispatchTotalPay}
              id="total"
              label={translation("common.totalPay.label")}
              // error={Boolean(errors?.dispatchTotalPay)}
              // onChange={(e: any) =>
              //   handleLoadStopChange(e.target.value,  "dispatchTotalPay")
              // }
              // helperText={
              //   errors?.dispatchTotalPay &&
              //   translation("common.totalPay.message")
              // }
            />
          )}
        />
      </CustomFormGrid> */}
          {/* ********************************Driver/Dispatcher ***************/}

          {/*     <CustomFormGrid
        item
        sm={12}
        xs={12}
        md={3}
        lg={3}
        className={classes.phoneField}
      >
        <Controller
          name={`stops[${index}].phoneNumber`}
          control={control}
          defaultValue=""
          rules={{
            validate: {
              isValid: () =>
                watch(`stops[${index}].phoneNumberValue`)
                  ? phone(watch(`stops[${index}].phoneNumberValue`), {
                      country: watch(`stops[${index}].phoneNumberCountry`),
                    })?.phoneNumber !== null || "Enter Valid Phone Number"
                  : true,
            },
          }}
          render={() => (
            <PhoneInput
              placeholder={translation("common.phone.label")}
              value={item?.shipperAddress?.phoneNumber ? item?.shipperAddress?.phoneNumber : ""}
              error={Boolean(
                errors?.stops && errors?.stops[index]?.phoneNumber
              )}
              onChange={(value: string, data: any) => {
                onChangePhoneInput(
                  value,
                  data.countryCode,
                  `phoneNumber`,
                  index
                );
              }}
              isValid={(value: any, country: any) => {
                return errors?.stops && errors?.stops[index]?.phoneNumber
                  ? validatePhoneNumber(value, country.iso2)
                  : true;
              }}
            />
          )}
        />
      </CustomFormGrid> */}

          {/* <CustomFormGrid
        item
        sm={12}
        xs={12}
        md={3}
        lg={3}
        className={classes.phoneField}
      >
        <Controller
          name={`stops[${index}].faxPhoneNumber`}
          control={control}
          defaultValue=""
          rules={{
            validate: {
              isValid: () =>
                watch(`stops[${index}].faxPhoneNumberValue`)
                  ? phone(watch(`stops[${index}].faxPhoneNumberValue`), {
                      country: watch(`stops[${index}].faxPhoneNumberCountry`),
                    })?.phoneNumber !== null || "Enter Valid Phone Number"
                  : true,
            },
          }}
          render={() => (
            <PhoneInput
              placeholder={translation("common.faxPhoneNumber.label")}
              value={item.shipperAddress.faxPhoneNumber}
              error={Boolean(
                errors?.stops && errors?.stops[index]?.faxPhoneNumber
              )}
              onChange={(value: string, data: any) => {
                onChangePhoneInput(
                  value,
                  data.countryCode,
                  `faxPhoneNumber`,
                  index
                );
              }}
              isValid={(value: any, country: any) => {
                return errors?.stops && errors?.stops[index]?.faxPhoneNumber
                  ? validatePhoneNumber(value, country.iso2)
                  : true;
              }}
            />
          )}
        />
      </CustomFormGrid> */}

          {/************ Dispatch Pay ***********/}

          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.form.dispatchPay.label")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="dispatchPay"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="dispatchPay"
                  //  defaultValue={"flatRate"}
                  label={translation("load.form.dispatchPay.label")}
                  error={Boolean(errors?.stopType)}
                  select
                  helperText={
                    errors?.DispatchPay &&
                    translation("load.form.dispatchPay.message")
                  }
                  onChange={(e: any) => {
                    handleLoadStopChange(e.target.value, "dispatchPay");
                  }}
                  value={item.dispatchPay}
                >
                  {item.companyDriver === true && DispatchPay
                    ? DispatchPay.map((item: any, index: any) => (
                        <MenuItem value={item.value} key={index}>
                          {item.label}
                        </MenuItem>
                      ))
                    : DispatchPayDriver.map((item: any, index: any) => (
                        <MenuItem value={item.value} key={index}>
                          {item.label}
                        </MenuItem>
                      ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>

          {item.dispatchPay === "flatRate" && item.companyDriver === true && (
            <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
              <Controller
                name="freight"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    disabled={disabled}
                    type="number"
                    inputProps={{ min: 0 }}
                    value={item.freight}
                    id="freight"
                    label={translation("load.dispatchPay.freight")}
                    error={Boolean(errors?.freight)}
                    onChange={(e: any) =>
                      handleLoadStopChange(e.target.value, "freight")
                    }
                    helperText={
                      errors?.freight && translation("common.totalPay.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
          )}

          {item.dispatchPay === "flatRate" && item.companyDriver === true && (
            <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
              <Controller
                name="fuel"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0 }}
                    value={item.fuel}
                    disabled={disabled}
                    id="fuel"
                    label={translation("load.dispatchPay.fuel")}
                    error={Boolean(errors?.freight)}
                    onChange={(e: any) =>
                      handleLoadStopChange(e.target.value, "fuel")
                    }
                    helperText={
                      errors?.fuel && translation("common.totalPay.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
          )}
          {(item.dispatchPay == "perMile" || item.dispatchPay == "perHour") &&
            item.companyDriver === true && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="rate"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      disabled={disabled}
                      fullWidth
                      type="number"
                      inputProps={{ min: 0 }}
                      value={item.rate}
                      id="rate"
                      label={translation("load.dispatchPay.rate")}
                      error={Boolean(errors?.rate)}
                      onChange={(e: any) =>
                        handleLoadStopChange(e.target.value, "rate")
                      }
                      helperText={
                        errors?.total && translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}
          {(item.dispatchPay == "perMile" || item.dispatchPay == "perHour") &&
            item.companyDriver === true && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="quantity"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      disabled={disabled}
                      size="small"
                      fullWidth
                      type="number"
                      inputProps={{ min: 0 }}
                      value={item.quantity}
                      id="quantity"
                      label={translation("load.dispatchPay.quantity")}
                      error={Boolean(errors?.quantity)}
                      onChange={(e: any) =>
                        handleLoadStopChange(e.target.value, "quantity")
                      }
                      helperText={
                        errors?.quantity &&
                        translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}
          {(item.dispatchPay == "flatRate" ||
            item.dispatchPay == "perHour" ||
            item.dispatchPay == "perMile") &&
            item.companyDriver === true && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="total"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      fullWidth
                      type="number"
                      disabled={true}
                      inputProps={{ min: 0 }}
                      value={totalDispatchPay}
                      id="total"
                      label={translation("load.dispatchPay.total")}
                      error={Boolean(errors?.freight)}
                      // onChange={(e: any) => {
                      //   if (item.dispatchPay == "flatRate"&&item.fuel&&item.freight) {
                      //     const total = item?.freight
                      //       ? item.freight
                      //       : 0 + item && item?.fuel
                      //       ? item.fuel
                      //       : 0;
                      //     handleLoadStopChange(total, index, "total");
                      //   }
                      // }}
                      helperText={
                        errors?.total && translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}
          {(item.dispatchPay == "percentOfFreight" ||
            item.dispatchPay == "percentOfGross") &&
            item.companyDriver === true && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="percent"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      fullWidth
                      disabled={disabled}
                      type="number"
                      inputProps={{ min: 0 }}
                      value={item.percent}
                      id="percent"
                      label={translation("load.dispatchPay.percent")}
                      error={Boolean(errors?.percent)}
                      onChange={(e: any) =>
                        handleLoadStopChange(e.target.value, "percent")
                      }
                      helperText={
                        errors?.percent &&
                        translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}
          {(item.dispatchPay == "percentOfFreight" ||
            item.dispatchPay == "percentOfGross") &&
            item.companyDriver === true && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="freightPay"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      fullWidth
                      disabled={true}
                      type="number"
                      inputProps={{ min: 0 }}
                      value={totalfreightPay}
                      id="freightPay"
                      label={translation("load.dispatchPay.freightPay")}
                      error={Boolean(errors?.freightPay)}
                      onChange={(e: any) =>
                        handleLoadStopChange(e.target.value, "freightPay")
                      }
                      helperText={
                        errors?.freightPay &&
                        translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}
          {(item.dispatchPay == "percentOfFreight" ||
            item.dispatchPay == "percentOfGross") &&
            item.companyDriver === true && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="fuelOther"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      fullWidth
                      type="number"
                      disabled={true}
                      inputProps={{ min: 0 }}
                      value={Number(freightBill?.fuel)}
                      id="fuelOther"
                      label={translation("load.dispatchPay.fuelOther")}
                      error={Boolean(errors?.fuelOther)}
                      onChange={(e: any) =>
                        handleLoadStopChange(e.target.value, "fuelOther")
                      }
                      helperText={
                        errors?.fuelOther &&
                        translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}

          {item.dispatchPay == "accountSettings" &&
            item.companyDriver == false && (
              <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                <Typography className={classes.companyDriverMessage}>
                  {translation("load.companyDriver.message")}
                </Typography>
              </CustomFormGrid>
            )}

          {item.dispatchPay == "flatRate" &&
            (item.companyDriver == null || item.companyDriver == false) && (
              <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
                <Controller
                  name="flat"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      fullWidth
                      disabled={disabled}
                      type="number"
                      inputProps={{ min: 0 }}
                      value={item.flat}
                      id="flat"
                      label={translation("load.dispatchPay.flat")}
                      error={Boolean(errors?.flat)}
                      onChange={(e: any) =>
                        handleLoadStopChange(e.target.value, "flat")
                      }
                      helperText={
                        errors?.flat && translation("common.totalPay.message")
                      }
                    />
                  )}
                />
              </CustomFormGrid>
            )}

          {/************ Load Billing ***********/}

          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.form.loadBilling.label")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="freight"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  type="number"
                  disabled={true}
                  inputProps={{ min: 0 }}
                  value={
                    freightBill?.freightBill
                      ? Number(freightBill?.freightBill)
                      : 0
                  }
                  id="freight"
                  label={translation("load.dispatchPay.freight")}
                  error={Boolean(errors?.freight)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "freight")
                  }
                  helperText={
                    errors?.freight && translation("common.totalPay.message")
                  }
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="fuel"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  type="number"
                  disabled={true}
                  inputProps={{ min: 0 }}
                  value={freightBill?.fuel ? Number(freightBill?.fuel) : 0}
                  id="fuel"
                  label={translation("load.dispatchPay.fuel")}
                  error={Boolean(errors?.freight)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "fuel")
                  }
                  helperText={
                    errors?.fuel && translation("common.totalPay.message")
                  }
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="other"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  type="number"
                  disabled={true}
                  inputProps={{ min: 0 }}
                  value={
                    freightBill?.otherBill ? Number(freightBill?.otherBill) : 0
                  }
                  id="other"
                  label={translation("load.dispatchPay.other")}
                  error={Boolean(errors?.other)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "other")
                  }
                  helperText={
                    errors?.other && translation("common.totalPay.message")
                  }
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="total"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  type="number"
                  disabled={true}
                  inputProps={{ min: 0 }}
                  value={
                    freightBill?.grandTotal
                      ? Number(freightBill?.grandTotal)
                      : 0
                  }
                  id="total"
                  label={translation("load.dispatchPay.total")}
                  error={Boolean(errors?.freight)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "total")
                  }
                  helperText={
                    errors?.total && translation("common.totalPay.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          {/************ Load Pay ***********/}

          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.loadStop.loadPay")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Controller
              name="dispatchTotalPay"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  type="number"
                  inputProps={{ min: 0 }}
                  value={totalDispatchPay ? totalDispatchPay : totalLoadPay}
                  disabled={true}
                  id="dispatchTotalPay"
                  label={translation("common.totalPay.label")}
                  error={Boolean(errors?.dispatchTotalPay)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "dispatchTotalPay")
                  }
                  helperText={
                    errors?.dispatchTotalPay &&
                    translation("common.totalPay.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          {/**************Gross profit ****************/}

          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.loadStop.profit")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.grossProfitText}>
              {(grossProfit ? "$" + grossProfit : "$0.00") +
                " , " +
                (isNaN(grossProfitPercentage)
                  ? "0%"
                  : grossProfitPercentage + "%")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.loadStop.comment")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="internalComment"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={2}
                  disabled={disabled}
                  value={item.internalComment}
                  id="internalComment"
                  label={translation("common.internalComment.label")}
                  error={Boolean(errors?.internalComment)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "internalComment")
                  }
                  helperText={
                    errors?.internalComment &&
                    translation("common.internalComment.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <Controller
              name="dispatchComment"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={2}
                  disabled={disabled}
                  value={item.dispatchComment}
                  id="dispatchComment"
                  label={translation("common.dispatchComment.label")}
                  error={Boolean(errors?.dispatchComment)}
                  onChange={(e: any) =>
                    handleLoadStopChange(e.target.value, "dispatchComment")
                  }
                  helperText={
                    errors?.dispatchComment &&
                    translation("common.dispatchComment.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>
      </LocalizationProvider>
    </CustomFormGrid>
  );
};

export default StopOffs;
