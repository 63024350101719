import React, { useEffect, useState } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { selectDriver } from "src/redux/reducers/driver";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { DriverOwnerProps } from "src/interfaces/driver";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";
import {
  addDriverOwner,
  deleteDriverOwnerLink,
} from "src/redux/actions/driverOwner";
import ConfirmationDialog from "src/components/common/confirmation";
import DriverOwnerDialogBox from "src/components/common/ownerAddDialog";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid, IconButton } from "@mui/material";
import { selectLinkedOwner } from "src/redux/reducers/driver";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import { getAllOwners } from "src/redux/actions/owner";
import { selectUser } from "src/redux/reducers/auth";
import { driver as driverEnum } from "src/utils/enums";
import AssignedLinkingCard from 'src/components/common/linkingCards/assignedOwner';
import CustomFormGrid from "src/components/customElements/customFormGrid";




interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function DriverOwner() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const user = useAppSelector(selectUser);
  const driverId =
    user?.role?.name?.toLocaleLowerCase() === driverEnum
      ? `${user?.driver?.id}`
      : params?.driverId;
  const isDriver = user?.role?.name?.toLocaleLowerCase() === driverEnum;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const driver = useAppSelector(selectDriver);
  const linkedOwner = useAppSelector(selectLinkedOwner);
  const [translation] = useTranslation("translation");
  const [open, setOpen] = React.useState(false);
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const owner =
    linkedOwner && typeof linkedOwner.owner === "object"
      ? linkedOwner.owner
      : null;
  const getOwnerList = async () => {
    setLoading(true);
    await dispatch(getAllOwners(0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getOwnerList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleOwnerDelete = () => {
    handleOpenDeleteModal();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {

    if (driverId) {
      setLoadingDelete(true);
      if (linkedOwner) {
        const object = {
          owner:
            typeof linkedOwner?.owner === "object"
              ? linkedOwner?.owner?.id
                ? linkedOwner?.owner?.id
                : ""
              : linkedOwner?.owner,

          driver: parseInt(driverId),
        };

        await dispatch(deleteDriverOwnerLink(object));
      }
      setLoadingDelete(false);
      handleCloseDeleteModal();
      getOwnerList();

    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: DriverOwnerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (driverId) {
      objToSubmit.driver = parseInt(driverId) ? parseInt(driverId) : null;
    }
    objToSubmit.owner = objToSubmit.owner ? objToSubmit.owner : null;
    if (driverId) {
      const driverOwnerLink = await dispatch(addDriverOwner(objToSubmit));
      if (driverOwnerLink.success && driverOwnerLink.data.id) {
        setTimeout(() => navigate(`/drivers/drivers/details/${driverId}`), 500);
        getOwnerList();
      }
    }
    handleClose();
    setLoading(false);
    getOwnerList();
  };
  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "owner.id",
      headerName: translation("owner.form.id.label"),

      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.owner === "object" && params?.row?.owner?.id
              ? params?.row?.owner?.id
              : ""}
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {" "}
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>

      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedOwner}
          headerName={"Assigned Owner"}
          isDriver={isDriver}
          handleClickOpen={handleClickOpen}
          handleDelete={handleOwnerDelete}
          data={ owner} />
      </CustomFormGrid>

      <DriverOwnerDialogBox
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        ownerTitle={translation("ownerDriver.dialogbox.title")}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof linkedOwner?.owner === "object"
                ? `${linkedOwner?.owner?.firstName} ${linkedOwner?.owner?.lastName}`
                : "",
          })}
          title={translation("driverOwner.delete.title")}
          loading={loadingDelete}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={driver?.OwnerDriver ? driver?.OwnerDriver : []}
              columns={columns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              localPagination={true}
            />
          }
          title={translation("owner.assignment.logs")}
        />
      )}
    </>
  );
}
