import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
// import { MuiEvent } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllVehicles } from "src/redux/actions/vehicle";
import { selectVehicles } from "src/redux/reducers/vehicle";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureVehicles, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import moment from "moment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { dateFormat } from "src/utils/constants";
import { Clipboard } from "ts-react-feather-icons";
import { toNumber } from "lodash";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Tooltip from "@mui/material/Tooltip";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Vehicles() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const vehicles = useAppSelector(selectVehicles);
  const classes = useStyles();
  const [vehicleData, setVehicleData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const vehiclePermissions =
    allUserPermissions && allUserPermissions[featureVehicles];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === update);

  const getVehiclesList = async () => {
    setLoading(true);
    await dispatch(
      getAllVehicles(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getVehiclesListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllVehicles(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };
  /*  function pageExists(page: any) {
    return vehicleData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getVehiclesListWithFilter();
    } else {
      getVehiclesList();
    }
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    getVehiclesListWithFilter();
  }, [filter]);

  useEffect(() => {
    //  if (pageExists(rowsState?.page + 1) == false)
    if (vehicles?.data?.length > 0) {
      setVehicleData({ ...vehicleData?.vehicles, vehicles });
    } else {
      setVehicleData({});
    }
  }, [vehicles]);
  const addVehicle = () => {
    navigate("/vehicle/vehicles/create");
  };
  const searchVehicles = () => {
    navigate("/vehicle/vehicles/search");
  };
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );
  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip
              title={
                (params.row?.vehicleSafety[0]?.monthlyMaintenance != null &&
                  new Date(params?.row?.vehicleSafety[0]?.monthlyMaintenance) <
                    new Date()) ||
                (params?.row?.vehicleSafety[0]?.annualInspectionDate != null &&
                  new Date(
                    params?.row?.vehicleSafety[0]?.annualInspectionDate
                  ) < new Date())
                  ? "Action Required"
                  : "Edit"
              }
            >
              <Edit />
            </Tooltip>
          }
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/vehicle/vehicles/${params.row.id}`);
          }}
          className={
            (params.row?.vehicleSafety[0]?.monthlyMaintenance != null &&
              new Date(params?.row?.vehicleSafety[0]?.monthlyMaintenance) <
                new Date()) ||
            (params?.row?.vehicleSafety[0]?.annualInspectionDate != null &&
              new Date(params?.row?.vehicleSafety[0]?.annualInspectionDate) <
                new Date())
              ? classes.editIconError
              : classes.edit
          }
        />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/vehicle/vehicles/documents/${params.row.id}?entity=${featureVehicles}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="details"
          key="details"
          onClick={() => {
            navigateToVehicleDetail(params.row.id);
          }}
        />,
      ],
    },

    {
      field: "vehicleID",
      headerName: translation("common.id.label"),
      minWidth: 50,
      filterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    /* {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 150,
       filterOperators,
    }, */
    {
      field: "owner",
      headerName: translation("vehicle.list.owner.label"),
      minWidth: 200,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.owner?.firstName}</div>;
      },
    },
    {
      field: "category",
      headerName: translation("vehicle.list.category.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "model",
      headerName: translation("vehicle.list.model.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "make",
      headerName: translation("vehicle.list.make.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.make?.content}</div>;
      },
    },
    {
      field: "type",
      headerName: translation("vehicle.list.type.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.type?.content}</div>;
      },
    },
    {
      field: "ownerSince",
      headerName: translation("vehicle.list.ownerSince.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.ownerSince
              ? new Date(params?.row?.ownerSince).getFullYear()
              : ""}
          </div>
        );
      },
    },
    {
      field: "VIN",
      headerName: translation("vehicle.list.VIN.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "tagNumber",
      headerName: translation("vehicle.list.tagNumber.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "tagState",
      headerName: translation("vehicle.list.tagState.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "fuelCapacity",
      headerName: translation("vehicle.list.fuelCapacity.label"),
      minWidth: 150,
      filterable: false,
    },
    {
      field: "insuranceCarrier",
      headerName: translation("vehicle.list.insuranceCarrier.label"),
      minWidth: 150,
      filterOperators,
    },
    {
      field: "insuranceExpiration",
      headerName: translation("vehicle.list.insuranceExpiration.label"),
      minWidth: 200,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params.row.insuranceExpiration
              ? moment(params.row.insuranceExpiration).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];
  const onRowClick = (params: GridRowParams) => {
    navigateToVehicleDetail(params.row.id);
  };

  const navigateToVehicleDetail = (id: number) => {
    navigate(
      `/vehicle/vehicles/details/${id}?entity=${featureVehicles}&referenceId=${id}`
    );
  };
  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <CustomFormGrid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ paddingTop: "20px" }}
      >
        <DataTable
          title={translation("vehicle.list.title")}
          rows={vehicleData?.vehicles?.data}
          columns={columns}
          loading={loading}
          onClickAdd={addVehicle}
          onClickSearch={searchVehicles}
          createPermission={createPermission > -1}
          onRowClick={onRowClick}
          rowsState={rowsState}
          setRowsState={setRowsState}
          pagination={vehicles.pagination}
          data={vehicleData?.vehicles}
          setData={setVehicleData}
          add={true}
          setFilter={setFilter}
          search={"vehicles"}
        />
      </CustomFormGrid>
    </>
  );
}
