import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { SettlementCodesProps } from "src/interfaces/settlementCodes";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectSettlementCode } from "src/redux/reducers/settlementCodes";
import { Controller } from "react-hook-form";
import CustomTextField from "src/components/customElements/customTextField";
import { useStyles } from "src/styles/formStyles";
import { typedKeys } from "src/utils/common";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { types } from "src/utils/dropdowns/settlementCode";
import { settlementCodeCategory } from "src/utils/dropdowns/settlementCode";
import { MenuItem } from "@mui/material";
import { InputLabel } from "@mui/material";
import CustomSwitch from "../customElements/customSwitch";

interface SettlementCodesFromProps {
  loading: boolean;
  pageLoading: boolean;
  onSubmit: (data: any) => any;
}
const SettlementCodesFrom = ({
  loading,
  pageLoading,
  onSubmit,
}: SettlementCodesFromProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  //const commodity = useAppSelector(selectCommodity);
  const singleSettlementCode = useAppSelector(selectSettlementCode);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const setFormValues = (key: keyof SettlementCodesProps) => {
      if (singleSettlementCode) {
      if (typeof singleSettlementCode[key] === "boolean") {
        setValue(key, singleSettlementCode[key]);
      } else {
        setValue(key, singleSettlementCode[key] ? singleSettlementCode[key] : "");
      }
    }
  };

    useEffect(() => {
    if (params?.id && singleSettlementCode) {
      const allAttributes = typedKeys(singleSettlementCode);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }
  }, [params?.id, singleSettlementCode]);

  return (
    <div>
      <Card variant="outlined" className={classes.formContainer}>
        <Box className={classes.formTitleContainer}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography className={classes.formTitle}>
              {translation("settlementCode.form.label")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent className={classes.formContentContainer}>
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
                <CustomFormGrid container>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={4}>
                    <Controller
                      name="code"
                      control={control}
                      defaultValue={""}
                       rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="code"
                          label={translation("settlementCode.code.label")}
                          error={Boolean(errors?.code)}
                          helperText={
                            errors?.code &&
                            translation("settlementCode.code.message")
                          }
                           required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={4}>
                    <Controller
                      name="internalDescription"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="internalDescription"
                          label={translation("settlementCode.internal.label")}
                          error={Boolean(errors?.internalDescription)}
                          helperText={
                            errors?.internalDescription &&
                            translation("settlementCode.internal.message")
                          }
                          required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={4}>
                    <Controller
                      name="type"
                      control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="type"
                          label={translation("settlementCode.type.label")}
                          error={Boolean(errors?.type)}
                          helperText={
                            errors?.type &&
                            translation("settlementCode.type.message")
                          }
                          select
                          required
                        >
                          {types.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={6}>
                    <Controller
                      name="defaultAmount"
                      control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                          inputProps={{ min: 0 }}
                          id="defaultAmount"
                          label={translation(
                            "settlementCode.amount.form.label"
                          )}
                          error={Boolean(errors?.defaultAmount)}
                          helperText={
                            errors?.defaultAmount &&
                            translation("settlementCode.amount.form.message")
                          }
                          required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={6}>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="type"
                          label={translation("settlementCode.category.label")}
                          error={Boolean(errors?.category)}
                          helperText={
                            errors?.category &&
                            translation("settlementCode.category.label")
                          }
                          select
                        >
                          {settlementCodeCategory.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="externalDescription"
                      control={control}
                        defaultValue={""}
                        rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          rows={1}
                          id="externalDescription"
                          label={translation(
                            "settlementCode.external.label"
                          )}
                          required
                          error={Boolean(errors?.externalDescription)}
                          helperText={
                            errors?.externalDescription &&
                            translation("settlementCode.external.label")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="notes"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          rows={1}
                          id="notes"
                          label={translation("settlementCode.notes.label")}
                          error={Boolean(errors?.notes)}
                          helperText={
                            errors?.notes &&
                            translation("settlementCode.notes.label")
                          }
                        />
                      )}
                    />
                    </CustomFormGrid>
                    <CustomFormGrid
                    item
                    sm={6}
                    xs={12}
                    md={3}
                    lg={4}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Controller
                      name="templateYTD"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <InputLabel>
                            {translation("settlementCode.template-YTD.label")}
                          </InputLabel>
                          <CustomSwitch
                            checked={
                              watch("templateYTD")
                                ? watch("templateYTD")
                                : false
                            }
                            {...field}
                            id="templateYTD"
                          />
                        </>
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid
                    item
                    sm={6}
                    xs={12}
                    md={3}
                    lg={4}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Controller
                      name="availableForDispatch"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <InputLabel>
                            {translation("settlementCode.available.label")}
                          </InputLabel>
                          <CustomSwitch
                            checked={
                              watch("availableForDispatch")
                                ? watch("availableForDispatch")
                                : false
                            }
                            {...field}
                            id="availableForDispatch"
                          />
                        </>
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid
                    item
                    sm={6}
                    xs={12}
                    md={3}
                    lg={4}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Controller
                      name="requireCaponRecurring"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <InputLabel>
                            {translation("settlementCode.recurring.label")}
                          </InputLabel>
                          <CustomSwitch
                            checked={
                              watch("requireCaponRecurring") ? watch("requireCaponRecurring") : false
                            }
                            {...field}
                            id="requireCaponRecurring"
                          />
                        </>
                      )}
                    />
                    </CustomFormGrid>
                    <CustomFormGrid
                    item
                    sm={6}
                    xs={12}
                    md={3}
                    lg={4}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Controller
                      name="excludeFromLoadPayCalculation"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <InputLabel>
                            {translation("settlementCode.exclude.label")}
                          </InputLabel>
                          <CustomSwitch
                            checked={
                              watch("excludeFromLoadPayCalculation") ? watch("excludeFromLoadPayCalculation") : false
                            }
                            {...field}
                            id="excludeFromLoadPayCalculation"
                          />
                        </>
                      )}
                    />
                    </CustomFormGrid>
                    <CustomFormGrid
                    item
                    sm={6}
                    xs={12}
                    md={3}
                    lg={4}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Controller
                      name="systemUseOnly"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <InputLabel>
                            {translation("settlementCode.system.label")}
                          </InputLabel>
                          <CustomSwitch
                            checked={
                              watch("systemUseOnly") ? watch("systemUseOnly") : false
                            }
                            {...field}
                            id="systemUseOnly"
                          />
                        </>
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid container justifyContent="center">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.formButton}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : params?.id ? (
                        translation("common.form.update")
                      ) : (
                        translation("common.form.add")
                      )}
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButton}
                      onClick={() => navigate(-1)}
                    >
                      {translation("common.btn.cancel")}
                    </Button>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomForm>
            </LocalizationProvider>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SettlementCodesFrom;
