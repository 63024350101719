import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import SecurityGroupForm from "src/components/securityGroups/form";
import { SecurityGroupProps } from "src/interfaces/securityGroups";
import {
  getSecurityGroupById,
  addSecurityGroup,
  updateSecurityGroup,
  getAllPermissions,
} from "src/redux/actions/securityGroups";
import { resetSecurityGroup } from "src/redux/reducers/securityGroups";

import { useAppDispatch } from "src/hooks/reduxHooks";

const SecurityGroupFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading] = useState(false);

  useEffect(() => {
    dispatch(resetSecurityGroup());
  }, []);

  const getPermissionsList = async () => {
    await dispatch(getAllPermissions());
  };
  useEffect(() => {
    getPermissionsList();
  }, []);
  const getSecurityGroup = async (id: number) => {
    await dispatch(getSecurityGroupById(id));
  };
  useEffect(() => {
    if (params.id) {
      getSecurityGroup(parseInt(params.id));
    }
  }, [params?.id]);
  /* SecurityGroupProps */
  const onSubmit = async (data:any ) => {
    setLoading(true);

    const objToSubmit: SecurityGroupProps = { ...data };

    if (params?.id) {
      //update
      await dispatch(updateSecurityGroup(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const securityGroup = await dispatch(addSecurityGroup(objToSubmit));
      if (securityGroup.success && securityGroup.data.id) {
        setTimeout(
          //() => navigate(`/config/security-groups/${securityGroup.data.id}`),
          () => navigate("/config/security-groups"),
          500
        );
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <SecurityGroupForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default SecurityGroupFormContainer;
