import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CommodityForm from "src/components/commodity/form";
import { CommodityProps } from "src/interfaces/commodity";
import {
  getCommodityById,
  addCommodity,
  updateCommodity,
} from "src/redux/actions/commodity";

import { resetCommodity } from "src/redux/reducers/commodity";
import { useAppDispatch } from "src/hooks/reduxHooks";

const CommodityFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    dispatch(resetCommodity());
  }, []);
  useEffect(() => {
    if (params?.id) {
      getSingleCommodity(parseInt(params?.id));
    }
  }, [params?.id]);
  const getSingleCommodity = async (id: number) => {
    setPageLoading(true);
    await dispatch(getCommodityById(id));
    setPageLoading(false);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const objToSubmit: CommodityProps = { ...data };
    objToSubmit.length = objToSubmit.length ? objToSubmit.length : null;
    objToSubmit.width = objToSubmit.width ? objToSubmit.width : null;
    objToSubmit.height = objToSubmit.height ? objToSubmit.height : null;
    objToSubmit.volume = objToSubmit.volume ? objToSubmit.volume : null;
    objToSubmit.weight = objToSubmit.weight ? objToSubmit.weight : null;
    objToSubmit.value = objToSubmit.value ? objToSubmit.value : null;
    objToSubmit.description = objToSubmit.description
      ? objToSubmit.description
      : "";

    objToSubmit.dispatchNotes = objToSubmit.dispatchNotes
      ? objToSubmit.dispatchNotes
      : "";

    if (params?.id) {
      //update
      await dispatch(updateCommodity(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const lineItem = await dispatch(addCommodity(objToSubmit));
      if (lineItem.success && lineItem.data.id) {
        setTimeout(
          () => navigate("/config/commodity"),
        //  () => navigate(`/config/commodity/${lineItem.data.id}`),
          500
        );
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <CommodityForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CommodityFormContainer;
