import React, { useState } from "react";
import { useAppSelector } from "src/hooks/reduxHooks";
import { useTranslation } from "react-i18next";
import CustomForm from "src/components/customElements/customForm";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import CustomTextField from "src/components/customElements/customTextField";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useStyles } from "src/styles/formStyles";
import { CircularProgress } from "@mui/material";
import { selectAvailableTrailers } from "src/redux/reducers/trailer";

interface VehicleTrailerFormProps {
  open: boolean;
  fullWidth: boolean;
  handleClose: () => void;
 /*  onSubmit: (data: VehicleTrailerProps) => any; */
  onSubmit: (data: any) => any;
  loading?: boolean;
}
const VehicleTrailer = ({
  open,
  onSubmit,
  handleClose,
  fullWidth,
  loading,
}: VehicleTrailerFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const availableTrailers = useAppSelector(selectAvailableTrailers);
  const [trailer, setTrailer] = useState<any | null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const handleTrailerChange = (event: any, newValue: any | null) => {
    setTrailer(newValue);
    setValue("trailer", newValue?.id);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={fullWidth}
    >
      <DialogTitle id="responsive-dialog-title">
        {translation("trailer.dialougebox.title")}
      </DialogTitle>
      <DialogContent>
        <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Controller
              name={"trailer"}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="combo-box-demo"
                  fullWidth
                  options={availableTrailers}
                  onChange={(event: any, newValue: any | null) =>
                    handleTrailerChange(event, newValue)
                  }
                  getOptionLabel={(option) =>
                    `${option?.trailerId?.toString()}-${option?.model?.toString()}-${option?.VIN?.toString()}`
                  }
                  value={trailer}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      id="trailer"
                      size="small"
                      label={translation("vehicleTrailer.search.label")}
                      error={Boolean(errors?.trailer)}
                      helperText={
                        errors?.trailer &&
                        translation("vehicleTrailer.search.message")
                      }
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Controller
              name="comments"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  id="comments"
                  label={translation("vehicleTrailer.form.comments.label")}
                  error={Boolean(errors?.comments)}
                  helperText={
                    errors?.comments &&
                    translation("vehicleTrailer.form.comments.message")
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid container justifyContent="center">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{ height: "20px", width: "20px", color: "#fff" }}
                />
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() => handleClose()}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomForm>
      </DialogContent>
    </Dialog>
  );
};
export default VehicleTrailer;
