import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import LineItemsForm from "src/components/lineItems/form";
import { LineItemProps } from "src/interfaces/lineItems";
import {
  getLineItemById,
  addLineItem,
  updateLineItem,
} from "src/redux/actions/lineItems";

import { resetLineItem } from "src/redux/reducers/lineItems";
import { useAppDispatch } from "src/hooks/reduxHooks";

const LineItemsFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    dispatch(resetLineItem());
  }, []);
  useEffect(() => {
    if (params?.id) {
      getSingleLineItem(parseInt(params?.id));
    }
  }, [params?.id]);
  const getSingleLineItem = async (id: number) => {
    setPageLoading(true);
    await dispatch(getLineItemById(id));
    setPageLoading(false);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const objToSubmit: LineItemProps = { ...data };
    objToSubmit.GL_account = objToSubmit.GL_account
      ? objToSubmit.GL_account
      : "";
    objToSubmit.description = objToSubmit.description
      ? objToSubmit.description
      : "";

    objToSubmit.notes = objToSubmit.notes ? objToSubmit.notes : "";
    objToSubmit.isSystemUseOnly = objToSubmit.isSystemUseOnly
      ? objToSubmit.isSystemUseOnly
      : false;

    if (params?.id) {
      //update
      await dispatch(updateLineItem(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const lineItem = await dispatch(addLineItem(objToSubmit));
      if (lineItem.success && lineItem.data.id) {
        setTimeout(
         // () => navigate(`/config/line-item/${lineItem.data.id}`),
          () => navigate("/config/line-item"),
          500
        );
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <LineItemsForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default LineItemsFormContainer;
