import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    padding: 0,
    margin: "0!important",
    borderRadius: "10px",
  },
  loadFormContainer: {
    padding: 0,
    marginTop: "10px",
    marginRight: "0!important",
    marginLeft: "0!important",
    borderRadius: "5px",
  },
  formTitleContainer: {
    padding: "15px 30px",
    display: "flex",
    alignItems: "center",
  },
  loadFormTitleContainer: {
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
  },
  formTitle: {
    fontSize: "20px",
    fontWeight: 500,
  },
  loadFormTitle: {
    fontSize: "20px",
    fontWeight: 500,
    marginTop: "8px",
  },
  sectionTitleContainer: {
    paddingTop: "5px",
    display: "flex",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "80px",
  },
  medicalHeading: {
    fontSize: "20px",
    fontWeight: 600,
  },
  formContentContainer: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
  },
  lookupFormContentContainer: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    maxWidth: "500px",
    margin: "auto",
    alignItems: "center",
  },
  formButton: {
    width: "150px",
    margin: "2px",
  },
  formButtonBilling: {
    width: "180px",
    margin: "2px",
  },
  formAddOwnerButton: {
    width: "30px",
    cursor: "pointer",
    // margin: "2px",
    "& .css-izms9o-MuiGrid-root": {
      padding: "5px 0px",
      cursor: "pointer",
    },
  },
  formAddLookUpButton: {
    width: "30px",
    cursor: "pointer",
    // margin: "2px",
    "& .css-izms9o-MuiGrid-root": {
      padding: "5px 0px",
      cursor: "pointer",
    },
  },
  formErrorMessage: {
    fontSize: "12px",
    color: "#FF0000",
  },
  phoneInputContainer: {
    width: "100%!important",
    color: theme.palette.mode === "dark" ? "white" : "#1c2025",
    backgroundColor:
      theme.palette.mode === "dark" ? "#1c2025!important" : "white!important",
  },
  phoneDropDownContainer: {
    color: theme.palette.mode === "dark" ? "white" : "#1c2025",
    backgroundColor:
      theme.palette.mode === "dark" ? "#1c2025!important" : "white!important",
  },
  containerClass: {
    color: theme.palette.mode === "dark" ? "white" : "#1c2025",
    backgroundColor:
      theme.palette.mode === "dark" ? "#1c2025!important" : "white!important",
  },
  searchClass: {
    color: theme.palette.mode === "dark" ? "white" : "#1c2025",
    backgroundColor:
      theme.palette.mode === "dark" ? "#1c2025!important" : "white!important",
  },
  buttonClass: {
    color: theme.palette.mode === "dark" ? "white" : "#1c2025",
    backgroundColor:
      theme.palette.mode === "dark" ? "#1c2025!important" : "white!important",
    "&:hover": {
      color: theme.palette.mode === "dark" ? "white" : "#1c2025",
      backgroundColor:
        theme.palette.mode === "dark" ? "#1c2025!important" : "white!important",
    },
  },

  phoneField: {
    "& .react-tel-input .form-control": {
      height: "40px",
      width: "241px",
    },
    "& .PhoneInputCountrySelect": {
      width: "100% !important",
    },
    "& .PhoneInputCountryIconImg": {
      width: "100%",
      height: "100%",
    },
    "& .react-tel-input .special-label": {
      display: "block",
      position: "absolute",
      zIndex: 1,
      fontSize: "13px",
      fontWeight: 400,
      background: "#fff",
      whiteSpace: "nowrap",
      color: "#777e89",
      lineHeight: "1.4375em",
    },
    "& .react-tel-input .flag-dropdown.open .selected-flag": {
      backgroundColor:
        theme.palette.mode === "dark" && "rgba(255,255,255, 0.1);",
    },
    "& .react-tel-input .flag-dropdown .selected-flag:hover": {
      backgroundColor:
        theme.palette.mode === "dark" && "rgba(255,255,255, 0.1);",
    },

    "& .react-tel-input .country-list .country.highlight": {
      backgroundColor:
        theme.palette.mode === "dark" && "rgba(255,255,255, 0.1);",
    },
    "& .react-tel-input .country-list .country:hover": {
      backgroundColor:
        theme.palette.mode === "dark" && "rgba(255,255,255, 0.1);",
    },
    "& .PhoneInputInput": {
      height: "35px",
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "14px",
    padding: "1px",
    margin: "1px",
    textTransform: "capitalize",
  },
  disableGridAddButton: {
    width: "30px",
    // margin: "2px",
    pointerEvents: "none",
    opacity: 0.4,
  },

  detailText: {
    marginBottom: 5,
    textTransform: "capitalize",
    marginRight: 1,
    marginLeft: 1,
    fontSize: "16px",
  },
  detailHeadingText: {
    marginBottom: 10,
    marginTop: 10,
    textTransform: "capitalize",
    marginRight: 1,
    marginLeft: 1,
    fontSize: "16px",
    fontWeight: "600",
  },
  deleteIconButton: {
    marginBottom: 30,
  },
  loadingStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  billingLoadingStyle: {
    position: "absolute",
    top: "50%",
    left: "60%",
  },
  loadingStyleLoad: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  dashboardLoading: {
    position: "absolute",
    left: "60%",
  },
  companyDriverMessage: {
    fontSize: "12px",
    padding: "1px",
    margin: "1px",
    color: "#FC0505",
  },
  addLoadStopMessage: {
    fontSize: "12px",
    padding: "1px",
    margin: "1px",
    color: "#545353",
  },
  billingDashboardText: {
    fontSize: "12px",
    padding: "1px",
    margin: "1px",
    fontWeight: 600,
  },
  billingDashboardNumber: {
    fontSize: "12px",
    padding: "1px",
    margin: "1px",
  },
  billingDashboardHeadings: {
    padding: "1px",
    margin: "1px",
    fontWeight: 600,
    fontSize: "14px",
    textAlign: "center",
  },
  billingTableText: {
    padding: "1px",
    margin: "1px",
    fontSize: "14px",
    textAlign: "center",
  },
  settlementTooltext: {
    padding: "1px",
    margin: "1px",
    fontSize: "14px",
    textAlign: "center",
    marginLeft: "10px",
    cursor: "pointer",
  },
  billingDashboardHeadingsNumber: {
    padding: "1px",
    margin: "1px",
    fontWeight: 600,
    textAlign: "center",
  },
  vl: {
    borderLeft: "2px solid #F0F0F0",
    height: "50px",
  },
  vlModel: {
    borderLeft: "1px solid #E0E0E0",
    height: "60px",
    marginTop: "5px",
  },
  vlModelLoad: {
    borderLeft: "1px solid #E0E0E0",
    height: "120px",
    marginTop: "5px",
  },

  addLoadStopSection: {
    border: "1px dashed #D9D9D9  ",
    width: "100%",
    height: "100px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "10px",
  },
  addLoadStopSectionWithoutBorder: {
    width: "100%",
    height: "150px",
  },
  loadStopSectionCard: {
    width: "100%",
    //  height: "215px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "20px",
    boxShadow: "rgba(0, 0, 0, 0.09)",
    "& .css-1odwj8l-MuiPaper-root-MuiCard-root": {
      margin: "0px",
    },
  },
  loadFormHeadings: {
    fontWeight: 700,
    fontSize: "20px",
    // fontFamily:"Roboto",
    // fontStyle:"normal",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    float: "left",
  },

  loadFormHeadingsEdit: {
    fontWeight: 700,
    fontSize: "16px",
    // fontFamily:"Roboto",
    // fontStyle:"normal",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    cursor: "pointer",

    "&:hover": {
      color: "rgba(0, 125, 255, 1)",
    },
  },
  customerCardHeading: {
    color: theme.palette.mode === "dark" ? "black" : "white",
  },

  dispatchAddressTableHeadings: {
    fontWeight: 700,
    // fontFamily:"Roboto",
    // fontStyle:"normal",
    letterSpacing: "0.15px",
    color: "black",
  },
  dispatchAddressTableHeadingsOrigin: {
    fontWeight: 700,
    // fontFamily:"Roboto",
    // fontStyle:"normal",
    letterSpacing: "0.15px",
    paddingLeft: "10px",
    color: "black",
  },
  dispatchAddressTable: {
    border: "1px solid rgba(0, 0, 0, 0.09)",
    padding: "0px",
    marginTop: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "10px",
  },
  dispatchAddressTableHead: {
    backgroundColor: theme.palette.mode === "dark" ? "#F5F5F5" : "#EDF4FF",
    borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
  },
  loadFormHeadingsMobile: {
    fontWeight: 600,
    marginTop: "15px",
    float: "left",
    fontSize: "14px",
  },
  loadStopCardHeadings: {
    fontWeight: 700,
    // marginTop: "15px",
    float: "left",
    //  paddingLeft: "18px",
    fontSize: "20px",
    lineHeight: "32px",
  },
  loadFormMainHeadings: {
    fontWeight: 700,
    fontSize: "20px",
    //   fontFamily:"Roboto",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    // marginTop: "15px",
    float: "left",
    paddingLeft: "5px",
  },
  loadInformationHeadings: {
    fontWeight: 700,
    fontSize: "24px",
    // fontFamily:"Roboto",
    lineHeight: "133.4%",
    letterSpacing: "0.15px",
    marginTop: "15px",
    float: "left",
    //fontStyle:"normal",
    paddingLeft: "5px",
  },
  billinginfoContainer: {
    width: "100%",
    height: "150px",
    backgroundColor: theme.palette.mode === "dark" ? "#F5F5F5" : "#EDF4FF",
    marginTop: "10px",
    borderRadius: "5px",
    paddingBottom: "20px",
  },
  customerInfoContainer: {
    width: "100%",
    height: "160px",
    backgroundColor: theme.palette.mode === "dark" ? "#F5F5F5" : "#EDF4FF",

    //marginTop: "10px",
    borderRadius: "5px",
    padding: "10px",
    // marginRight:"11px",
    // marginLeft:"14px",
  },
  customerInfoContainerMobile: {
    width: "100%",
    //  height: "150px",
    backgroundColor: theme.palette.mode === "dark" ? "#F5F5F5" : "#EDF4FF",
    borderRadius: "5px",
    padding: "10px",
    // marginRight:"11px",
    // marginLeft:"5px",
  },
  customerInfoContainerLoadStop: {
    width: "100%",
    //  height: "150px",
    backgroundColor: "#EDF4FF",
    padding: "20px",
    borderRadius: "5px",
  },
  billinginfoContainerHeadings: {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "bold",
    fontStyle: "normal",
    lineHeight: "12px",
    letterSpacing: "0.15px",
    textAlign: "center",
    padding: "10px",
    borderBottom: "solid 1px"
  },
  billinginfoContainerText: {
    padding: "8px",
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "bold",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  billinginfoContainerTextMobile: {
    fontSize: "12px",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    // fontFamily:"Roboto",
    //  paddingBottom:"px",
  },
  billinginfoContainerTextDisabled: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: "160%",
    letterSpacing: "0.15px",
    color: "#777e89",
    // fontFamily:"Roboto",
    //  paddingBottom:"px",
  },
  addressText: {
    fontSize: "14px",
    //fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "150%",
    fontWeight: 400,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginLeft: "5px",
    textAlign: "left",
  },
  addressTextMobile: {
    fontSize: "12px",
    //fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "120%",
    fontWeight: 400,
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginLeft: "2px",
    textAlign: "left",
  },
  addressTextDisabled: {
    fontSize: "16px",
    //fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "150%",
    fontWeight: 400,
    letterSpacing: "0.15px",
    color: "#777e89",
    marginLeft: "5px",
    textAlign: "left",
  },
  grossProfitText: {
    fontSize: "16px",
    //fontFamily: "Roboto",
    fontStyle: "normal",
    lineHeight: "150%",
    fontWeight: 400,
    letterSpacing: "0.15px",
    color: "#777e89",
    marginLeft: "5px",
    textAlign: "left",
  },
  iconStyle: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "medium",
    marginTop: "3px",
  },
  settlementIconStyle: {
    // color: "rgba(0, 0, 0, 0.54)",
    fontSize: "medium",
    marginTop: "3px",
    cursor: "pointer",
  },
  editIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    //marginTop: "12px",
    float: "right",
    //   marginRight:"15px",
  },
  editIconMobile: {
    color: "#545353",
    //  marginTop: "12px",
    float: "right",
    //  marginRight:"2px",
    justifyContent: "center",
    alignItem: "center",
  },
  iconStyleCircle: {
    color: "#007DFF",
    fontSize: "medium",
    marginTop: "3px",
  },
  iconStyleTriangle: {
    color: "rgba(33, 150, 243, 1)",
    fontSize: "large",
    marginTop: "3px",
  },
  iconDisabled: {
    fontSize: "medium",
    marginTop: "3px",
    color: "#777e89",
  },
  iconStyleSquare: {
    color: "#008000",
    fontSize: "medium",
    marginTop: "3px",
  },
  buttonContainer: {
    paddingRight: "18px",
  },
  formCancelButton: {
    width: "150px",
    margin: "2px",
    border: "1px solid #545353",
  },

  errorMessageContainer: {
    display: "flex",
    backgroundColor: "#FEEFB3",
    borderRadius: "5px",
    height: "50px",
    //  margin:"5px",
  },
  errorMessageBold: {
    fontSize: "12px",
    fontWeight: 600,
  },
  errorMessage: {
    fontSize: "12px",
    margin: "0px",
    padding: "0px",
  },
  errorIcon: {
    color: "#FC0505",
    marginTop: "10px",
    marginRight: "10px",
    paddingLeft: "5px",
  },
  edit: {
    color: theme.palette.mode === "dark" ? "#F5F5F5" : "#545353",
  },
  editIconError: {
    color: "#FC0505",
  },
  tooltipErrorText: {
    color: "#FC0505",
    backgroundColor: "#fff",
  },
  tooltipText: {
    color: "#545353",
    backgroundColor: "#0000",
  },
  addIconDiv: {
    backgroundColor: "#007DFF",
    width: "100%",
    height: "40px",
    textAlign: "center",
    justifyContent: "center",
    alignItem: "center",
    paddingTop: "8px",
    float: "right",
    padding: "0px",
    cursor: "pointer",
    borderRadius: "5px",
  },
  formButtonDiscard: {
    width: "93px",
    height: "36px",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    marginRight: "5px",
    // fontFamily: "Roboto",
    color: theme.palette.mode === "dark" ? "white" : "rgba(0, 0, 0, 0.54)",
    borderColor:
      theme.palette.mode === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
    "&:hover": {
      color: theme.palette.mode === "dark" ? "white" : "rgba(0, 0, 0, 0.54)",
      borderColor:
        theme.palette.mode === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
    },
  },
  mobileCheckAppBar: {
    background: theme.palette.mode === "dark" ? "#1c2025!important" : "#fff",
    overflow: "hidden",
    boxShadow: "0px 7px 30px 0px rgb(113 122 131 / 11%)",
    //  zIndex: 2,
    display: "block",
    position: "fixed",
    whiteSpace: "nowrap",
    zIndex: "auto",
    padding: 0,
  },

  mobileCheckComponent: {
    paddingTop: "60px",
    zIndex: "auto",
    whiteSpace: "nowrap",
  },

  formButtonCancel: {
    // width: "120px",
    height: "36px",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    marginRight: "5px",
    // fontFamily: "Roboto",
    color: "#fff",

    "&:hover": {
      color: "#fff",
    },
  },
  formButtonEdit: {
    // width: "120px",
    height: "36px",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    marginRight: "5px",
    color: "#fff",

    // fontFamily: "Roboto",
    backgroundColor: "#007DFF",
    "&:hover": {
      backgroundColor: "#007DFF",
      color: "#fff",
    },
  },
  formButtonDispatch: {
    // width: "120px",
    height: "36px",
    borderRadius: "4px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 500,
    marginRight: "5px",
    color: "#fff",

    // fontFamily: "Roboto",
    backgroundColor: "#fec90f",
    "&:hover": {
      backgroundColor: "#fec90f",
      color: "#fff",
    },
  },
  formButtonSave: {
    width: "165px",
    height: "36px",
    borderRadius: "4px",
    fontSize: "14px",
    // fontWeight:500,
    // fontFamily: "Roboto",
    lineHeight: "24px",
    backgroundColor: "#007DFF",
    "&:hover": {
      backgroundColor: "#007DFF",
    },
  },
  addStopOffButtonStyle: {
    fontSize: "12px",
    lineHeight: "24px",
    color: "rgba(0, 125, 255, 1)",
    fontWeight: 500,
  },
  tabPanel: {
    padding: "0px !important",
    margin: "0px !important",
    "& css-19kzrtu": {
      padding: "0px !important",
    },
  },
  loadStopTabContainer: {
    backgroundColor: "#ffff",
    textAlign: "center",
    justifyContent: "center",
    //  height:"70px",
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "0px",
  },
  loadStopTabHeadingsSelected: {
    color: "#007DFF",
    fontSize: "15px",
    lineHeight: "26px",
    fontFamily: "Roboto",
    letterSpacing: "0.46px",
    fontWeight: 500,
    cursor: "pointer",
  },
  underlineBorder: {
    background: "rgba(0, 125, 255, 1)",
    paddingTop: "5px",
  },
  loadStopTabHeadings: {
    color: "#7FBDFD",
    fontSize: "15px",
    lineHeight: "26px",
    fontFamily: "Roboto",
    letterSpacing: "0.46px",
    fontWeight: 500,
    cursor: "pointer",
  },
  modelContainer: {
    padding: "16px",
  },
  fieldModel: {
    padding: "2px",
    margin: 0,
  },
  dashboardLoadHeading: {
    fontSize: "20px",
    lineHeight: "32px",
    letterSpacing: "0.15px",
    fontWeight: 700,
    paddingBottom: "24px",
  },
  readFormsText: {
    color:
      theme.palette.mode === "dark" ? "white!important" : "#1c2025!important",
    wordWrap: "break-word",
    whiteSpace: "initial",
  },
  readFormTextBlue: {
    color: "#0096FF",
    wordWrap: "break-word",
    whiteSpace: "initial",
  },
  readFormLabel: {
    color: theme.palette.mode === "dark" ? "E8E8E8" : "#848884",
    overflowWrap: "break-word",
    whiteSpace: "initial",
    wordWrap: "break-word",
  },
  linkingCardText: {
    color: theme.palette.mode === "dark" ? "E8E8E8" : "#848884",
  },
}));
