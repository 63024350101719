import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toNumber } from "lodash";
import DataTable from "src/components/dataTable";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { LookupProps } from "src/interfaces/lookup";
import { Clipboard } from "ts-react-feather-icons";
import { selectLookups } from "src/redux/reducers/lookup";

import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { featureVehicles, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { dateTimeFormat } from "src/utils/constants";
import { statusTypes } from "src/utils/dropdowns/common";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import CustomGrid from "src/components/customElements/customFormGrid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { InputLabel } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getAllOwners } from "src/redux/actions/owner";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { getAllVehicles } from "src/redux/actions/vehicle";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { dateFormat } from "src/utils/constants";
import LoadCancelDialog from "src/components/load/loadCancelModel";
import moment from "moment";
import { selectLoadManagements } from "src/redux/reducers/loadManagement";
import { getAllDrivers } from "src/redux/actions/driver";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import { LoadStopProps } from "src/interfaces/loadManagement";
import CustomTextField from "src/components/customElements/customTextField";
import {
  getSearchTablesData,
  getSearchTablesDataWithFilters,
} from "src/redux/actions/search";
import Tooltip from "@mui/material/Tooltip";

interface LoadSummaryProps {
  pageLoading: boolean;
  delayForClear: any;
}
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const LoadSearchForm = ({
  pageLoading,
  delayForClear,
}: LoadSummaryProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<any>({});
  const user = useAppSelector(selectUser);
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);

  const loadData = useAppSelector(selectLoadManagement);
  const pickupDateTimeRef = React.useRef<any>();
  const pickupDateTimeEndRef = React.useRef<any>();
  const [deliveryStart, setDeliveryStart] = React.useState<any | null>(null);
  const [deliveryEnd, setDeliveryEnd] = React.useState<any | null>(null);
  const selectPickupDateTimeRef = () => {
    if (pickupDateTimeRef?.current) {
      pickupDateTimeRef?.current?.click();
    }
  };
  const selectPickupDateEndTimeRef = () => {
    if (pickupDateTimeEndRef?.current) {
      pickupDateTimeEndRef?.current?.click();
    }
  };
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [addedByData, setAddedByData] = React.useState<any | null>([]);
  const [terminalData, setTerminalData] = React.useState<any | null>([]);

  const [annualInspectionStart, setAnnualInspectionStart] = React.useState<
    any | null
  >(null);
  const [annualInspectionEnd, setAnnualInspectionEnd] = React.useState<
    any | null
  >(null);

  const [dotStart, setDOTStart] = React.useState<any | null>(null);
  const [dotEnd, setDOTEnd] = React.useState<any | null>(null);

  const [lastServiceDateStart, setLastServiceDateStart] = React.useState<
    any | null
  >(null);
  const [lastServiceDateEnd, setLastServiceDateEnd] = React.useState<
    any | null
  >(null);

  const [nextServiceDateStart, setNextServiceDateStart] = React.useState<
    any | null
  >(null);
  const [nextServiceDateEnd, setNextServiceDateEnd] = React.useState<
    any | null
  >(null);

  const [monthlyMaintenanceStart, setMonthlyMaintenanceStart] = React.useState<
    any | null
  >(null);
  const [monthlyMaintenanceEnd, setMonthlyMaintenanceEnd] = React.useState<
    any | null
  >(null);

  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  // const [, forceUpdate] = React.useState();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  let timer: any;
  const allLoadManagement = useAppSelector(selectLoadManagements);
  const [allLoadManagementData, setAllLoadManagementData] = useState<any>({});
  const [vehicleSearchedData, setVehicleSearchedData] = useState<any>({});
  const [displayTable, setdisplayTable] = useState<boolean>(false);
  console.log(vehicleSearchedData, "vehicleSearchedData");
  const boxTheme = useTheme();
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const vehiclePermissions =
    allUserPermissions && allUserPermissions[featureVehicles];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === update);

  allLookups && allLookups?.data && allLookups?.data["vehicle-type"]?.lookups
    ? allLookups?.data["vehicle-type"]?.lookups
    : [];

  const allTractorMake: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["vehicle-make"]?.lookups
      ? allLookups?.data["vehicle-make"]?.lookups
      : [];
  const allTractorColors: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["vehicle-color"]?.lookups
      ? allLookups?.data["vehicle-color"]?.lookups
      : [];
  const allTractorTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["vehicle-type"]?.lookups
      ? allLookups?.data["vehicle-type"]?.lookups
      : [];

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 150,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={
        //     <Tooltip
        //       title={
        //         (params.row?.vehicleSafety[0]?.monthlyMaintenance != null &&
        //           new Date(params?.row?.vehicleSafety[0]?.monthlyMaintenance) <
        //             new Date()) ||
        //         (params?.row?.vehicleSafety[0]?.annualInspectionDate != null &&
        //           new Date(
        //             params?.row?.vehicleSafety[0]?.annualInspectionDate
        //           ) < new Date())
        //           ? "Action Required"
        //           : "Edit"
        //       }
        //     >
        //       <Edit />
        //     </Tooltip>
        //   }
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/vehicle/vehicles/${params.row.id}`);
        //   }}
        //   className={
        //     (params.row?.vehicleSafety[0]?.monthlyMaintenance != null &&
        //       new Date(params?.row?.vehicleSafety[0]?.monthlyMaintenance) <
        //         new Date()) ||
        //     (params?.row?.vehicleSafety[0]?.annualInspectionDate != null &&
        //       new Date(params?.row?.vehicleSafety[0]?.annualInspectionDate) <
        //         new Date())
        //       ? classes.editIconError
        //       : classes.edit
        //   }
        // />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/vehicle/vehicles/documents/${params.row.id}?entity=${featureVehicles}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        // <GridActionsCellItem
        //   icon={<Clipboard />}
        //   label="details"
        //   key="details"
        //   onClick={() => {
        //     navigateToVehicleDetail(params.row.id);
        //   }}
        // />,
      ],
    },

    {
      field: "vehicleID",
      headerName: translation("common.id.label"),
      minWidth: 50,

    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,

      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    /* {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 150,
       filterOperators,
    }, */
    {
      field: "owner",
      headerName: translation("vehicle.list.owner.label"),
      minWidth: 200,

      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.owner?.firstName}</div>;
      },
    },
    {
      field: "category",
      headerName: translation("vehicle.list.category.label"),
      minWidth: 100,
    },
    {
      field: "model",
      headerName: translation("vehicle.list.model.label"),
      minWidth: 150,

    },
    {
      field: "make",
      headerName: translation("vehicle.list.make.label"),
      minWidth: 150,

      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.make?.content}</div>;
      },
    },
    {
      field: "type",
      headerName: translation("vehicle.list.type.label"),
      minWidth: 150,

      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.type?.content}</div>;
      },
    },
    {
      field: "ownerSince",
      headerName: translation("vehicle.list.ownerSince.label"),
      minWidth: 150,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.ownerSince
              ? new Date(params?.row?.ownerSince).getFullYear()
              : ""}
          </div>
        );
      },
    },
    {
      field: "VIN",
      headerName: translation("vehicle.list.VIN.label"),
      minWidth: 150,

    },
    {
      field: "tagNumber",
      headerName: translation("vehicle.list.tagNumber.label"),
      minWidth: 150,

    },
    {
      field: "tagState",
      headerName: translation("vehicle.list.tagState.label"),
      minWidth: 150,

    },
    {
      field: "fuelCapacity",
      headerName: translation("vehicle.list.fuelCapacity.label"),
      minWidth: 150,

    },
    {
      field: "insuranceCarrier",
      headerName: translation("vehicle.list.insuranceCarrier.label"),
      minWidth: 150,

    },
    {
      field: "insuranceExpiration",
      headerName: translation("vehicle.list.insuranceExpiration.label"),
      minWidth: 200,

      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params.row.insuranceExpiration
              ? moment(params.row.insuranceExpiration).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];
  const onRowClick = (params: GridRowParams) => {
    navigateToVehicleDetail(params.row.id);
  };
  ///////////////////annualInspection Date ///////////////////////////////////////
  useEffect(() => {
    if (annualInspectionStart != null && annualInspectionEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["vehicleSafety.annualInspectionDate"] = {
        value:
          annualInspectionStart.toISOString() +
          "," +
          annualInspectionEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [annualInspectionStart, annualInspectionEnd]);

  /////////////////// DOT Date ///////////////////////////////////////

  useEffect(() => {
    if (dotStart != null && dotEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["vehicleSafety.dot_Date"] = {
        value: dotStart.toISOString() + "," + dotEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [dotStart, dotEnd]);

  /////////////////// lastServiceDate Date ///////////////////////////////////////

  useEffect(() => {
    if (lastServiceDateStart != null && lastServiceDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["vehicleSafety.lastServiceDate"] = {
        value:
          lastServiceDateStart.toISOString() +
          "," +
          lastServiceDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [lastServiceDateStart, lastServiceDateEnd]);

  /////////////////// nextServiceDate Date ///////////////////////////////////////

  useEffect(() => {
    if (nextServiceDateStart != null && nextServiceDateEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["vehicleSafety.nextServiceDate"] = {
        value:
          nextServiceDateStart.toISOString() +
          "," +
          nextServiceDateEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [nextServiceDateStart, nextServiceDateEnd]);

  /////////////////// monthlyMaintenance Date ///////////////////////////////////////

  useEffect(() => {
    if (monthlyMaintenanceStart != null && monthlyMaintenanceEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["vehicleSafety.monthlyMaintenance"] = {
        value:
          monthlyMaintenanceStart.toISOString() +
          "," +
          monthlyMaintenanceEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [monthlyMaintenanceStart, monthlyMaintenanceEnd]);

  useEffect(() => {
    if (deliveryStart != null && deliveryEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["deliveryDateTime"] = {
        value: deliveryStart.toISOString() + "," + deliveryEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [deliveryStart, deliveryEnd]);

  /*  useEffect(() => {
    // if (pageExists(rowsState?.page + 1) == false)
    if (loadSearchedData.length > 0) {
      setAllLoadManagementData({
        ...allLoadManagementData?.allLoadManagement,
        allLoadManagement,
      });
    } else {
      setAllLoadManagementData({});
    }
  }, [allLoadManagement]); */
  useEffect(() => {
    {
      Object.keys(filter).length !== 0 && getVehicleListWithFilter();
    }
  }, [rowsState.pageSize, rowsState.page]);

  const getVehicleListWithFilter = async () => {
    setLoading(true);

    await dispatch(
      getSearchTablesDataWithFilters(
        "vehicle",
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
        filter
      )
    ).then((response:any) => {
      setVehicleSearchedData(response?.data);
      setdisplayTable(true);
    });
    setLoading(false);
  };

  const handleChangeAutoComplete = async (
    event: any,
    newValue: any,
    setData: any,
    table: string,
    column: string
  ) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getSearchTablesData(table, column, event)).then((res:any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setData(res?.data);
        }
      });
    }
  };

  const navigateToVehicleDetail = (id: number) => {
    navigate(
      `/vehicle/vehicles/details/${id}?entity=${featureVehicles}&referenceId=${id}`
    );
  };

  const submitSearch = async () => {
    getVehicleListWithFilter();
  };

  console.log(filter, "filter");
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("search.form.search.vehicle")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              className={classes.formButtonDiscard}
              onClick={() => {
                delayForClear(true);
              }}
            >
              {translation("search.form.clear")}
            </Button>

            <Button
              variant="contained"
              // type="submit"
              onClick={submitSearch}
              className={classes.formButtonSave}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                translation("search.form.search")
              )}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
        {/* //1st row */}

        <CustomFormGrid
          container
          direction="row"
          style={{ paddingTop: "30px" }}
        >
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="vehicleID"
              size="small"
              fullWidth
              value={filter["vehicleID"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["vehicleID"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["vehicleID"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.vehicleID.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="VIN"
              size="small"
              fullWidth
              value={filter["VIN"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["VIN"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["VIN"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.VIN.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="model"
              size="small"
              fullWidth
              value={filter["model"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["model"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["model"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.model.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="tagNumber"
              size="small"
              fullWidth
              value={filter["tagNumber"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["tagNumber"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["tagNumber"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.tagNumber.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="type"
              label={translation("vehicle.form.type.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                console.log(event.target.value, "newValue");

                if (event.target.value != null) {
                  temp["type"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["type"];
                }
                setFilter(temp);
              }}
              value={filter["type"]?.value}
            >
              {allTractorTypes &&
                allTractorTypes.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="color"
              label={translation("vehicle.form.color.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                console.log(event.target.value, "newValue");

                if (event.target.value != null) {
                  temp["color"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["color"];
                }
                setFilter(temp);
              }}
              value={filter["color"]?.value}
            >
              {allTractorColors &&
                allTractorColors.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              id="state"
              size="small"
              fullWidth
              value={filter["state"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["state"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["state"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={"State"}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          {/*  <CustomFormGrid item sm={12} xs={12} md={6} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={addedByData ? addedByData : "No Data"}
              value={addedByData?.id}
              getOptionLabel={(option) =>
                option?.firstName && option?.lastName
                  ? option?.firstName + " " + option?.lastName
                  : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                console.log("newValue", newValue);
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["addedBy"];
                } else if (newValue?.id) {
                  temp["addedBy"] = { value: newValue?.id, filter: "equals" };
                }
                setFilter(temp);

              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setAddedByData,
                          "user",
                          "firstName"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={translation("Added By")}
                  error={false}

                />
              )}
            />
          </CustomFormGrid> */}
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              fullWidth
              options={terminalData ? terminalData : "No Data"}
              // value={terminalData?.id}
              getOptionLabel={(option) =>
                option?.title ? option?.title : "No Data Found"
              }
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                if (newValue == null) {
                  delete temp["terminal"];
                } else if (newValue?.id) {
                  temp["terminal"] = { value: newValue?.id, filter: "equals" };
                }

                setFilter(temp);
                // field.onChange(newValue);
                // handleSettlementCodeChange(event, newValue)}
              }}
              renderInput={(params: any) => (
                <CustomTextField
                  {...params}
                  id="address"
                  size="small"
                  onChange={(event: any, newValue: any | null) => {
                    clearTimeout(timer);
                    timer = setTimeout(
                      () =>
                        handleChangeAutoComplete(
                          event.target.value,
                          newValue,
                          setTerminalData,
                          "terminal",
                          "title"
                        ),
                      500
                    );
                  }}
                  // error={Boolean(errors?.accountType)}
                  label={translation("owner.homeTerminal.label")}
                  error={false}
                  /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="status"
              label={translation("common.status.label")}
              select
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["status"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["status"];
                }
                setFilter(temp);
              }}
              value={filter["status"]?.value}
            >
              {statusTypes &&
                statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <CustomTextField
              size="small"
              fullWidth
              id="make"
              label={translation("vehicle.form.make.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                console.log(event.target.value, "newValue");

                if (event.target.value != null) {
                  temp["make"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["make"];
                }
                setFilter(temp);
              }}
              value={filter["make"]?.value}
            >
              {allTractorMake &&
                allTractorMake.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
            </CustomTextField>

            {/* //2st row */}

            {/* <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Delivery Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="reviewDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDeliveryStart(newValue);
                  }}
                  value={deliveryStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="reviewDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={2}>
            <Controller
              name="reviewDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDeliveryEnd(newValue);
                  }}
                  value={deliveryEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="reviewDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid> */}
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <CustomTextField
              id="model"
              size="small"
              fullWidth
              value={filter["model"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["model"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["model"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.list.model.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <CustomTextField
              id="insuranceCarrier"
              size="small"
              fullWidth
              value={filter["insuranceCarrier"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["insuranceCarrier"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["insuranceCarrier"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.insuranceCarrier.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={6}>
            <CustomTextField
              id="fuelCapacity"
              size="small"
              fullWidth
              value={filter["fuelCapacity"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["fuelCapacity"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["fuelCapacity"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.fuelCapacity.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Annual Inspection Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.annualInspectionDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setAnnualInspectionStart(newValue);
                  }}
                  value={annualInspectionStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.annualInspectionDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.annualInspectionDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setAnnualInspectionEnd(newValue);
                  }}
                  value={annualInspectionEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.annualInspectionDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  DOT start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>DOT Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.dot_Date"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDOTStart(newValue);
                  }}
                  value={dotStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.dot_Date"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.dot_Date"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDOTEnd(newValue);
                  }}
                  value={dotEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.dot_Date"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  lastServiceDate start here  ///////////////////////////////////////////////////////} */}

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Last Service Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.lastServiceDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setLastServiceDateStart(newValue);
                  }}
                  value={lastServiceDateStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.lastServiceDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.lastServiceDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setLastServiceDateEnd(newValue);
                  }}
                  value={lastServiceDateEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.lastServiceDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          {/* /////////////////////////////////////////////////////////////////  monthlyMaintenance start here  ///////////////////////////////////////////////////////} */}
          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Monthly Maintenance Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.monthlyMaintenance"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setMonthlyMaintenanceStart(newValue);
                  }}
                  value={monthlyMaintenanceStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params:any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.monthlyMaintenance"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="vehicleSafety.monthlyMaintenance"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setMonthlyMaintenanceEnd(newValue);
                  }}
                  value={monthlyMaintenanceEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="vehicleSafety.monthlyMaintenance"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>
        {vehicleSearchedData?.data?.length > 0 && loading ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "30px" }}
          >
            <CircularProgress />
          </CustomFormGrid>
        ) : vehicleSearchedData?.data?.length > 0 ? (
          <>
            <CustomFormGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "30px" }}
            >
              <Typography className={classes.loadFormHeadings}>
                {translation("common.searchedVehicle.message")}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                title={translation("vehicle.list.title")}
                rows={vehicleSearchedData?.data}
                columns={columns}
                loading={loading}
                createPermission={createPermission > -1}
                rowsState={rowsState}
                // onRowClick={onRowClick}
                setRowsState={setRowsState}
                pagination={vehicleSearchedData.pagination}
                data={vehicleSearchedData?.data}
                setData={setVehicleSearchedData}
                add={true}
                hideBackBar={true}
              />
            </CustomFormGrid>
          </>
        ) : displayTable === true ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "50px" }}
          >
            <Typography variant="h5">No search result found</Typography>
          </CustomFormGrid>
        ) : (
          ""
        )}
      </CustomFormGrid>
    </LocalizationProvider>
  );
};

export default LoadSearchForm;
