import React, { useEffect, useState } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Autocomplete, Box, Button } from "@mui/material";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLoadAddresses } from "src/redux/reducers/loadAddress";
import { selectStates, selectCity, selectCities } from "src/redux/reducers/shared";
import { getStates, getCityByZipCode, getCitiesByState } from "src/redux/actions/shared";
import { getAllLoadAddresses } from "src/redux/actions/loadAddress";
import { useStyles } from "src/styles/formStyles";
import { phone } from "phone";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { useForm } from "react-hook-form";
import { LoadAddress } from "src/interfaces/loadManagement";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { City, State } from "src/interfaces/shared";
import CustomDateTimePicker from "../customElements/customDatetimePicker";


// import usStates from "src/utils/dropdowns/usStates";

const companyAutoCompleteFilter = createFilterOptions<CompanyAutoCompleteProps>();

interface LoadAddressInfoProps {
  done(error: any | null, data: LoadAddress): any;
  stepId?: number,
  data?: LoadAddress | null
}

interface CompanyAutoCompleteProps {
  inputValue?: string;
  company: string;
}

const LoadAddressInfo = ({
  done,
  stepId = 0,
  data = null
}: LoadAddressInfoProps): JSX.Element => {

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const loadAddresses = useAppSelector(selectLoadAddresses);
  const usStates = useAppSelector(selectStates);
  const usCities = useAppSelector(selectCities);
  let city = useAppSelector(selectCity);
  const [translation] = useTranslation("translation");
  const isMobile = useMediaQuery("(max-width:650px)");

  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(`${key}`, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
  };

  useEffect(() => {
    dispatch(getAllLoadAddresses());
    dispatch(getStates());


  }, [])

  const onSubmit = (data: LoadAddress) => {
    console.log("Load Address: ============", data)
    done(null, data);
  }

  const handleZipChange = async (zipCode: string) => {

    if (zipCode.length >= 5) {
      console.log("Loading city ==========")
      const foundCity = await dispatch(getCityByZipCode(zipCode));
      city = foundCity.data
      if (city) {
        console.log("Filtering state ==========")
        const selectedState = usStates.filter(e => e.abbreviation == city?.state);
        if (selectedState && selectedState.length > 0) {
          console.log("Filtering cities ==========")
          setValue(`state`, selectedState[0]);
          await dispatch(getCitiesByState(selectedState[0].abbreviation));
          setValue(`city`, city);
        }
      }
    }
  }

  const handleStateChange = async (stateObj: State) => {
    await dispatch(getCitiesByState(stateObj.abbreviation));
    console.log(usCities, usStates)

  }

  const handleCityChange = async (cityObj: City) => {
    if (cityObj && cityObj.zip_code) setValue('zip', cityObj.zip_code);
  }

  const handleCompanyChange = (data: LoadAddress) => {
    setValue('zip', data?.zip || "");
    setValue('address', data?.address || "");

    if (data.state) {
      if (typeof data.state == "string") {
        const parsed = JSON.parse(data.state);
        setValue('state', parsed || "");
      }

      if (typeof data.city == "string") {
        const parsed = JSON.parse(data.city);
        setValue('city', parsed || "");
      }
    }
  }


  return (
    <React.Fragment>

      <form id={`load-form-${stepId}`} noValidate onSubmit={handleSubmit(onSubmit)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomFormGrid container spacing={2}>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name={`company`}
                control={control}
                defaultValue={data && data.company ? data.company : null}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="load-address-company-ac"
                    value={value}
                    fullWidth
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange({
                          company: newValue
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        onChange({
                          company: newValue.inputValue,
                        });
                      } else {
                        handleCompanyChange(newValue);
                        onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = companyAutoCompleteFilter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.company);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          company: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={loadAddresses}
                    getOptionLabel={(option) => {
                      // // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.company || "";
                    }}

                    renderOption={(props, option) => <li {...props}>{option.company}</li>}
                    freeSolo
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        id="load-address-company-txt"
                        size="small"
                        required
                        label={"Company Name"}
                        error={Boolean(errors.company)}
                        onChange={onChange}
                        helperText={
                          errors &&
                          errors.company &&
                          "Company name is required"
                        }
                      />
                    )}
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name={`address`}
                control={control}
                defaultValue={data && data.address ? data.address : ""}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="address"
                    required
                    label={translation("common.address.label")}
                    error={Boolean(errors.address)}
                    helperText={
                      errors &&
                      errors.address &&
                      translation("common.address.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name={`zip`}
                control={control}
                defaultValue={data && data.zip ? data.zip : ""}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, ...restFields } }) => (
                  <CustomTextField
                    {...restFields}
                    size="small"
                    fullWidth
                    id="zip"
                    required
                    onChange={(event: any) => {
                      onChange(event.target.value)
                      handleZipChange(event.target.value);
                    }}
                    type="number"
                    inputProps={{ min: 0 }}
                    label={translation("common.zip.label")}
                    error={Boolean(errors.zip)}
                    helperText={
                      errors &&
                      errors.zip &&
                      translation("common.zip.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>

            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name={`state`}
                control={control}
                defaultValue={data && data.state ? data.state : ""}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="state"
                    fullWidth
                    options={usStates || []}
                    onChange={(event, inputValue) => {
                      onChange(inputValue);
                      handleStateChange(inputValue);
                    }}
                    value={value}
                    getOptionLabel={(option) => {
                      return option ? `${option.abbreviation} - ${option.name}` : '';
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        id="txt-state"
                        required
                        size="small"
                        label={`State`}
                        onChange={onChange}
                        error={Boolean(errors?.state)}
                        helperText={
                          errors &&
                          errors.state &&
                          "State is required"
                        }
                      />
                    )}
                  />
                )}
              />


            </CustomFormGrid>

            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name={`city`}
                control={control}
                defaultValue={data && data.city ? data.city : ""}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="city"
                    fullWidth
                    options={usCities || []}
                    onChange={(event, inputValue) => {
                      onChange(inputValue);
                      handleCityChange(inputValue);
                    }}
                    value={value}
                    getOptionLabel={(option) => {
                      return option ? `${option.city} (${option.zip_code})` : '';
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        id="txt-city"
                        size="small"
                        label={`City`}
                        required
                        onChange={onChange}
                        error={Boolean(errors?.city)}
                        helperText={
                          errors &&
                          errors.city &&
                          "City is required"
                        }
                      />
                    )}
                  />
                )}
              />
            </CustomFormGrid>

            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name={`email`}
                control={control}
                defaultValue={data && data.email ? data.email : ""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="email"
                    label={translation("common.email.label")}
                  />
                )}
              />
            </CustomFormGrid>

            <CustomFormGrid
              item
              sm={6}
              xs={12}
              md={6}
              lg={6}
              className={classes.phoneField}
            >
              <Controller
                name={`phoneNumber`}
                control={control}
                defaultValue={data && data.phoneNumber ? data.phoneNumber : ""}
                rules={{
                  validate: {
                    isValid: () => {
                      if (watch(`phoneNumber`)) {
                        return !phone("+" + watch(`phoneNumber`)).isValid ? "Enter valid phone number" : true
                      } else {
                        return true;
                      }
                    },
                  }
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    label={translation("common.phone.label")}
                    placeholder={translation("common.phone.label")}
                    value={value}
                    error={Boolean(errors.phoneNumber)}
                    onChange={onChange}
                    country="us"
                    isValid={(value: any, country: any) => {
                      return errors.phoneNumber
                        ? validatePhoneNumber(value, country.iso2)
                        : true;
                    }}
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid
              item
              sm={6}
              xs={12}
              md={6}
              lg={6}
              className={classes.phoneField}
            >
              <Controller
                name={`faxPhoneNumber`}
                control={control}
                defaultValue={data && data.faxPhoneNumber ? data.faxPhoneNumber : ""}
                rules={{
                  validate: {
                    isValid: () => {
                      if (watch(`faxPhoneNumber`)) {
                        console.log(watch(`faxPhoneNumber`))
                        return !phone("+" + watch(`faxPhoneNumber`)).isValid ? "Enter valid phone number" : true
                      } else {
                        return true;
                      }
                    },
                  }
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    label={translation("common.faxPhoneNumber.label")}
                    placeholder={translation("common.faxPhoneNumber.label")}
                    value={value}
                    error={Boolean(errors.faxPhoneNumber)}
                    onChange={onChange}
                    country="us"
                    isValid={(value: any, country: any) => {
                      return errors.faxPhoneNumber
                        ? validatePhoneNumber(value, country.iso2)
                        : true;
                    }}
                  />
                )}
              />
            </CustomFormGrid>

            <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
              <Controller
                name={`directionNotes`}
                control={control}
                defaultValue={data && data.directionNotes ? data.directionNotes : ""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                    id="directionNotes"
                    label={translation("load.instructions.label")}
                    error={Boolean(errors?.directionNotes)}
                    helperText={
                      errors?.directionNotes &&
                      translation("load.instructions.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
          </CustomFormGrid>
        </LocalizationProvider>
      </form>
    </React.Fragment>
  );
};
export default LoadAddressInfo;
