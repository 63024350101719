import React from "react";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomField = React.forwardRef<any, any>((props, ref) => {
  return <TextField ref={ref} {...props} />;
});

CustomField.displayName = "CustomField";

const CustomTextField = styled(CustomField)(({ theme }) => ({
  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "rgba(0, 0, 0, 0.54)",
    opacity: "1",
    fontFamily: "Roboto",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.12)" : "#dee3e9"
    }`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${
      theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.12) " : "#f8f9fb "
    }`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "rgba(0, 0, 0, 0.54)",
    opacity: "1",
  },
}));

export default CustomTextField;
