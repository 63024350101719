import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toNumber } from "lodash";
import DataTable from "src/components/dataTable";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import StatusBadge from "src/components/common/StatusBadge";
import { Clipboard } from "ts-react-feather-icons";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { InputLabel } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { featureOwners, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { dateTimeFormat } from "src/utils/constants";
import { statusTypes } from "src/utils/dropdowns/common";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import {useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { Autocomplete } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { selectTerminals } from "src/redux/reducers/terminal";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectLookups } from "src/redux/reducers/lookup";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { LookupProps } from "src/interfaces/lookup";
import { featureTrailers} from "src/utils/enums";
import { getAllTrailers } from "src/redux/actions/trailer";
import { LoadStopProps } from "src/interfaces/loadManagement";
import CustomTextField from "src/components/customElements/customTextField";
import { getSearchTablesData,getSearchTablesDataWithFilters } from "src/redux/actions/search";
interface LoadSummaryProps {
  pageLoading: boolean;
  delayForClear: any;
}
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
const TrailerSearchForm = ({
  pageLoading,
  delayForClear,
}: LoadSummaryProps): JSX.Element => {


  const params = useParams();
  const boxTheme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();


  const [filter, setFilter] = useState<any>({});
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [pickupStart, setPickupStart] = React.useState<any | null>(null);
  const [pickupEnd, setPickupEnd] = React.useState<any | null>(null);
  const [addedByData, setAddedByData] = React.useState<any | null>([]);
  const [terminalData, setTerminalData] = React.useState<any | null>([]);
  const [loading, setLoading] = useState(false);
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const [annualInspectionStart, setAnnualInspectionStart] = React.useState<
  any | null
>(null);
const [annualInspectionEnd, setAnnualInspectionEnd] = React.useState<
  any | null
>(null);

const [monthlyMaintenanceStart, setMonthlyMaintenanceStart] = React.useState<
any | null
>(null);
const [monthlyMaintenanceEnd, setMonthlyMaintenanceEnd] = React.useState<
any | null
>(null);

const [dotStart, setDotStart] = React.useState<
any | null
>(null);
const [dotEnd, setDotEnd] = React.useState<
any | null
>(null);

const [lastServiceStart, setLastServiceStart] = React.useState<
any | null
>(null);
const [lastServiceEnd, setLastServiceEnd] = React.useState<
any | null
>(null);

const [nextServiceStart, setNextServiceStart] = React.useState<
any | null
>(null);
const [nextServiceEnd, setNextServiceEnd] = React.useState<
any | null
>(null);

  const user = useAppSelector(selectUser);
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);
  const loadData = useAppSelector(selectLoadManagement);
  const pickupDateTimeRef = React.useRef<any>();
  const pickupDateTimeEndRef = React.useRef<any>();
  const [deliveryStart, setDeliveryStart] = React.useState<any | null>(null);
  const [deliveryEnd, setDeliveryEnd] = React.useState<any | null>(null);
  const [trailerSearchedData, setTrailerSearchedData] = useState<any>({});
  const [displayTable, setdisplayTable] = useState<boolean>(false);


  const selectPickupDateTimeRef = () => {
    if (pickupDateTimeRef?.current) {
      pickupDateTimeRef?.current?.click();
    }
  };
  const selectPickupDateEndTimeRef = () => {
    if (pickupDateTimeEndRef?.current) {
      pickupDateTimeEndRef?.current?.click();
    }
  };
  // const [, forceUpdate] = React.useState();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  let timer: any;

  const allTrailerTypes: Array<LookupProps> =
  allLookups && allLookups?.data && allLookups?.data["trailer-type"]?.lookups
    ? allLookups?.data["trailer-type"]?.lookups
    : [];

const allTrailerMake: Array<LookupProps> =
  allLookups && allLookups?.data && allLookups?.data["trailer-make"]?.lookups
    ? allLookups?.data["trailer-make"]?.lookups
    : [];


  const allUserPermissions = useAppSelector(selectUserPermissions);
  const ownerPermissions =
    allUserPermissions && allUserPermissions[featureOwners];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : ownerPermissions?.findIndex((item) => item === update);
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 175,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        // <GridActionsCellItem
        //   icon={
        //     <Tooltip
        //       title={
        //         (params.row?.trailerSafety[0]?.monthlyMaintenance != null &&
        //           new Date(params?.row?.trailerSafety[0]?.monthlyMaintenance) <
        //             new Date()) ||
        //         (params?.row?.trailerSafety[0]?.annualInspectionDate != null &&
        //           new Date(
        //             params?.row?.trailerSafety[0]?.annualInspectionDate
        //           ) < new Date())
        //           ? "Action Required"
        //           : "Edit"
        //       }
        //     >
        //       <Edit />
        //     </Tooltip>
        //   }
        //   label="Edit"
        //   key="edit"
        //   onClick={() => {
        //     navigate(`/trailer/trailers/${params.row.id}`);
        //   }}
        //   className={
        //     (params.row?.trailerSafety[0]?.monthlyMaintenance != null &&
        //       new Date(params?.row?.trailerSafety[0]?.monthlyMaintenance) <
        //         new Date()) ||
        //     (params?.row?.trailerSafety[0]?.annualInspectionDate != null &&
        //       new Date(params?.row?.trailerSafety[0]?.annualInspectionDate) <
        //         new Date())
        //       ? classes.editIconError
        //       : classes.edit
        //   }
        // />,
       /*  <GridActionsCellItem
          icon={<UploadFileIcon />}
          label="documents"
          key="documents"
          onClick={() => {
            navigate(
              `/trailer/trailers/documents/${params.row.id}?entity=${featureTrailers}&referenceId=${params.row.id}`
            );
          }}
        />, */
        // <GridActionsCellItem
        //   icon={<Clipboard />}
        //   label="details"
        //   key="details"
        //   onClick={() => {
        //     navigateToTrailerDetail(params.row.id);
        //   }}
        // />,
      ],
    },
    {
      field: "trailerId",
      headerName: translation("common.id.label"),
      minWidth: 100,

    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,

      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "model",
      headerName: translation("vehicle.list.model.label"),
      minWidth: 200,

    },
    {
      field: "VIN",
      headerName: translation("vehicle.list.VIN.label"),
      minWidth: 200,

    },
    {
      field: "tagNumber",
      headerName: translation("vehicle.list.tagNumber.label"),
      minWidth: 200,
      filterable: false,
    },
    {
      field: "tagState",
      headerName: translation("vehicle.list.tagState.label"),
      minWidth: 200,
      flex: 1,

    },
  ];

  useEffect(() => {
    if (annualInspectionStart != null && annualInspectionEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["trailerSafety.annualInspectionDate"] = {
        value:
          annualInspectionStart.toISOString() +
          "," +
          annualInspectionEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [annualInspectionStart, annualInspectionEnd]);
  useEffect(() => {
    if (monthlyMaintenanceStart != null && monthlyMaintenanceEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["trailerSafety.monthlyMaintenance"] = {
        value: monthlyMaintenanceStart.toISOString() + "," + monthlyMaintenanceEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [monthlyMaintenanceStart, monthlyMaintenanceEnd]);

  useEffect(() => {
    if (dotStart != null && dotEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["trailerSafety.dot_Date"] = {
        value: dotStart.toISOString() + "," + dotEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [dotStart, dotEnd]);

  useEffect(() => {
    if (lastServiceStart != null && lastServiceEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["trailerSafety.lastServiceDate"] = {
        value: lastServiceStart.toISOString() + "," + lastServiceEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [lastServiceStart, lastServiceEnd]);

  useEffect(() => {
    if (nextServiceStart != null && nextServiceEnd != null) {
      const temp = JSON.parse(JSON.stringify(filter));
      temp["trailerSafety.nextServiceDate"] = {
        value: nextServiceStart.toISOString() + "," + nextServiceEnd.toISOString(),
        filter: "between",
      };
      setFilter(temp);
    }
  }, [nextServiceStart, nextServiceEnd]);


    useEffect(() => {
       { Object.keys(filter).length !== 0 &&
        getTrailersListWithFilter()}
  }, [rowsState.pageSize, rowsState.page]);


  const getTrailersListWithFilter = async () => {
    setLoading(true);
      await dispatch(
        getSearchTablesDataWithFilters(
          "trailer",
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ).then((response:any) => {
        setTrailerSearchedData(response?.data);
        setdisplayTable(true);

      });
    setLoading(false);
  };
  const handleChangeAutoComplete = async (
    event: any,
    newValue: any,
    setData: any,
    table: string,
    column: string
  ) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getSearchTablesData(table, column, event)).then((res:any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setData(res?.data);
        }
      });
    }
  };
  const onRowClick = (params: GridRowParams) => {
    navigateToTrailerDetail(params.row.id);
  };
  const navigateToTrailerDetail = (id: number) => {
    navigate(
      `/trailer/trailers/details/${id}?entity=${featureTrailers}&referenceId=${id}`
    );
  };

  const submitSearch = async () => {
    setSearchLoading(true);
    await getTrailersListWithFilter();
    setSearchLoading(false);
  };
console.log(filter,"******")
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("search.form.search.trailer")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              className={classes.formButtonDiscard}
              onClick={() => {
                delayForClear(true);
              }}
            >
              {translation("search.form.clear")}
            </Button>

            <Button
              variant="contained"
              // type="submit"
              onClick={submitSearch}
              className={classes.formButtonSave}
            >
              {searchLoading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : (
                translation("search.form.search")
              )}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
        {/* //1st row */}

        <CustomFormGrid container style={{ paddingTop: "30px" }}>
        <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              id="trailerId"
              size="small"
              fullWidth
              value={filter["trailerId"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["trailerId"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["trailerId"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.trailerId.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="status"
              label={translation("common.status.label")}
              select
              onChange={(newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["status"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["status"];
                }
                setFilter(temp);
              }}
              value={filter["status"]?.value}
            >
              {statusTypes &&
                statusTypes.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
            </CustomTextField>
          </CustomFormGrid>


            <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="make"
              label={translation("vehicle.form.make.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                console.log(event.target.value,"newValue")

                if (event.target.value != null) {
                  temp["make"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["make"];
                }
                setFilter(temp);
              }}
              value={filter["make"]?.value}
            >
              {allTrailerMake &&
                allTrailerMake.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
           </CustomTextField>
            </CustomFormGrid>
            <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
            <CustomTextField
              size="small"
              fullWidth
              id="type"
              label={translation("vehicle.form.type.label")}
              select
              onChange={(event: any, newValue: any | null) => {
                const temp = JSON.parse(JSON.stringify(filter));
                console.log(event.target.value,"newValue")

                if (event.target.value != null) {
                  temp["type"] = {
                    value: event.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["type"];
                }
                setFilter(temp);
              }}
              value={filter["type"]?.value}
            >
              {allTrailerTypes &&
                allTrailerTypes.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
            </CustomTextField>

           </CustomFormGrid>
           <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
           <CustomTextField
              id="VIN"
              size="small"
              fullWidth
              value={filter["VIN"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["VIN"] = {
                    value: newValue.target.value,
                    filter: "contains",
                  };
                } else {
                  delete temp["VIN"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.VIN.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />

           </CustomFormGrid>
           <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
           <CustomTextField
              id="length"
              size="small"
              fullWidth
              value={filter["length"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["length"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["length"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.length.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />

           </CustomFormGrid>
           <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
           <CustomTextField
              id="width"
              size="small"
              fullWidth
              value={filter["width"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["width"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["width"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.width.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />

           </CustomFormGrid>

           <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
           <CustomTextField
              id="deckHeight"
              size="small"
              fullWidth
              value={filter["deckHeight"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["deckHeight"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["deckHeight"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.deckHeight.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />

           </CustomFormGrid>
           <CustomFormGrid sm={12} xs={12} md={6} lg={4}>
           <CustomTextField
              id="weight"
              size="small"
              fullWidth
              value={filter["weight"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["weight"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["weight"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("vehicle.form.weight.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
           </CustomFormGrid>
           <CustomFormGrid sm={12} xs={12} md={6} lg={6}>
           <CustomTextField
              id="tagNumber"
              size="small"
              fullWidth
              value={filter["tagNumber"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["tagNumber"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["tagNumber"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("trailer.form.tagNumber.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
           </CustomFormGrid>
           <CustomFormGrid sm={12} xs={12} md={6} lg={6}>
           <CustomTextField
              id="tagState"
              size="small"
              fullWidth
              value={filter["tagState"]?.value}
              onChange={(newValue: any | null) => {
                console.log("newValue", newValue.target.value);
                const temp = JSON.parse(JSON.stringify(filter));

                if (newValue.target.value?.length > 0) {
                  temp["tagState"] = {
                    value: newValue.target.value,
                    filter: "equals",
                  };
                } else {
                  delete temp["tagState"];
                }
                setFilter(temp);
              }}
              // error={Boolean(errors?.accountType)}
              label={translation("trailer.form.tagState.label")}
              error={false}
              /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
            />
           </CustomFormGrid>

           <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Annual Inspection Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.annualInspectionDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setAnnualInspectionStart(newValue);
                  }}
                  value={annualInspectionStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.annualInspectionDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.annualInspectionDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setAnnualInspectionEnd(newValue);
                  }}
                  value={annualInspectionEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.annualInspectionDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Monthly Maintenance Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.monthlyMaintenance"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setMonthlyMaintenanceStart(newValue);
                  }}
                  value={monthlyMaintenanceStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.monthlyMaintenance"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.monthlyMaintenance"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setMonthlyMaintenanceEnd(newValue);
                  }}
                  value={monthlyMaintenanceEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.monthlyMaintenance"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>DOT Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.dot_Date"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDotStart(newValue);
                  }}
                  value={dotStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.dot_Date"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.dot_Date"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setDotEnd(newValue);
                  }}
                  value={dotEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.dot_Date"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Last Service Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.lastServiceDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setLastServiceStart(newValue);
                  }}
                  value={lastServiceStart}

                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.lastServiceDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.lastServiceDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setLastServiceEnd(newValue);
                  }}
                  value={lastServiceEnd}

                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.lastServiceDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={12}
            xs={12}
            md={6}
            lg={2}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <InputLabel>Next Service Date</InputLabel>{" "}
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.nextServiceDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setNextServiceStart(newValue);
                  }}
                  value={nextServiceStart}
                  label={translation("from")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params: any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.nextServiceDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={4} lg={2}>
            <Controller
              name="trailerSafety.nextServiceDate"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  onChange={(newValue: any | null) => {
                    setNextServiceEnd(newValue);
                  }}
                  value={nextServiceEnd}
                  label={translation("to")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params:any) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      fullWidth
                      id="trailerSafety.nextServiceDate"
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>


        </CustomFormGrid>



        {trailerSearchedData?.data?.length > 0 && loading ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "30px" }}
          >
            <CircularProgress />
          </CustomFormGrid>
        ) : trailerSearchedData?.data?.length > 0 ? (
          <>
            <CustomFormGrid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "30px" }}
            >
              <Typography className={classes.loadFormHeadings}>
                {translation("search.form.search.trailer.result")}
              </Typography>
            </CustomFormGrid>

            <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
              <DataTable
                title={translation("owner.list.title")}
                rows={trailerSearchedData?.data}
                columns={columns}
                loading={loading}
                createPermission={createPermission > -1}
                rowsState={rowsState}
                // onRowClick={onRowClick}
                setRowsState={setRowsState}
                pagination={trailerSearchedData.pagination}
                data={trailerSearchedData?.data}
                setData={setTrailerSearchedData}
                add={true}
                hideBackBar={true}
              />
            </CustomFormGrid>
          </>
        ) : displayTable === true ? (
          <CustomFormGrid
            container
            justifyContent="center"
            style={{ paddingTop: "50px" }}
          >
            <Typography variant="h5">No search result found</Typography>
          </CustomFormGrid>
        ) : (
          ""
        )}
      </CustomFormGrid>
    </LocalizationProvider>
  );
};

export default TrailerSearchForm;
