import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, IconButton, Autocomplete } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import CustomTextField from "src/components/customElements/customTextField";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadLineItemProps } from "src/interfaces/loadManagement";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { selectLineItems } from "src/redux/reducers/lineItems";
import { getAllLineItems } from "src/redux/actions/lineItems";

import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { useFieldArray } from "react-hook-form";

interface LineItemProps {
  errors?: any;
  control: any;
  setValue?: any;
  watch?: any;
  data?: Array<LoadLineItemProps>
  onLineItemChange(): any;
}
const LineItem = ({
  errors,
  control,
  setValue,
  watch,
  data = [],
  onLineItemChange
}: LineItemProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const lineItems = useAppSelector(selectLineItems);

  const dispatch = useAppDispatch();

  const { fields, append, remove } = useFieldArray(
    {
      control,
      name: "loadLineItem"
    }
  );

  const handleAddLineItem = (data?: LoadLineItemProps | null) => {
    append({
      lineItem: data && data.lineItem ? data.lineItem : "",
      quantity: data && data.quantity ? data.quantity : "0",
      rate: data && data.rate ? data.rate : "0",
      extra: data && data.extra ? data.extra : "0"
    })
  }

  useEffect(() => {
    dispatch(getAllLineItems());
    remove();
    if (data && Array.isArray(data) && data.length > 0) {
      data.forEach((e: LoadLineItemProps) => {
        handleAddLineItem(e);
      })
    } else {
      handleAddLineItem(null);
    }
  }, [])


  const handleBillItemChange = () => {
    onLineItemChange()
  }

  const handleQuantityValueChange = () => {
    onLineItemChange()
  }

  const handleRateValueChange = () => {
    onLineItemChange()
  }

  const handleExtraValueChange = () => {
    onLineItemChange()
  }


  return (
    <CustomFormGrid container>
      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
        <Controller
          name="combineLineItems"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              label={translation("load.form.combineLineItems.label").toString()}
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              }
            />
          )}
        />
      </CustomFormGrid>

      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => { handleAddLineItem() }}>
            Add Line Item
          </Button>
        </Box>
      </CustomFormGrid>

      <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
        {/* <form noValidate onSubmit={handleSubmit(onSubmit)}> */}
        {
          fields.map((item: any, i) => {
            return (
              <Box key={i} component="div" sx={{ m: 1, p: 2, border: '1px dashed grey' }}>
                <CustomFormGrid container>
                  <CustomFormGrid item sm={12} xs={12} md={6} lg={5}>
                    <Controller
                      name={`loadLineItem[${i}].lineItem`}
                      control={control}
                      defaultValue={item?.lineItem || ""}
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value}
                          fullWidth
                          onChange={(event, newValue) => {
                            onChange(newValue);
                            handleBillItemChange()
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={lineItems}
                          getOptionLabel={(option) => {
                            return option.title || "";
                          }}

                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              size="small"
                              label={"Bill Item"}
                              onChange={onChange}
                              error={Boolean(errors && errors?.loadLineItem && errors?.loadLineItem[i]?.lineItem)}
                              helperText={
                                errors && 
                                errors?.loadLineItem &&
                                errors?.loadLineItem[i]?.lineItem && "Line Item is required"
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={6} lg={2}>
                    <Controller
                      name={`loadLineItem[${i}].quantity`}
                      control={control}
                      defaultValue={item?.quantity || 0}
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange, ...restFields } }) => (
                        <CustomTextField
                          {...restFields}
                          size="small"
                          inputProps={{ min: 0 }}
                          fullWidth
                          type="number"
                          id="quantity"
                          onChange={(e: any) => {
                            onChange(e.target.value);
                            handleQuantityValueChange()
                          }}
                          label={translation("load.form.qty.label")}
                          error={Boolean(errors && errors?.loadLineItem && errors?.loadLineItem[i]?.quantity)}
                          helperText={
                            errors && 
                            errors?.loadLineItem &&
                            errors?.loadLineItem[i]?.quantity && translation("load.form.qty.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={6} lg={2}>
                    <Controller
                      name={`loadLineItem[${i}].rate`}
                      control={control}
                      defaultValue={item?.rate || 0}
                      rules={{
                        required: true
                      }}
                      render={({ field: { onChange, ...restFields } }) => (
                        <CustomTextField
                          {...restFields}
                          size="small"
                          fullWidth
                          type="number"
                          onChange={(e: any) => {
                            onChange(e.target.value)
                            handleRateValueChange()
                          }}
                          inputProps={{ min: 0 }}
                          label={translation("load.form.rate.label")}
                          error={Boolean(errors && errors?.loadLineItem && errors?.loadLineItem[i]?.rate)}
                          helperText={
                            errors && 
                            errors?.loadLineItem &&
                            errors?.loadLineItem[i]?.rate && translation("load.form.rate.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={6} lg={2}>
                    <Controller
                      name={`loadLineItem[${i}].extra`}
                      control={control}
                      defaultValue={item?.extra || "0"}
                      render={({ field: { onChange, ...restFields } }) => (
                        <CustomTextField
                          {...restFields}
                          size="small"
                          inputProps={{ min: 0 }}
                          fullWidth
                          label={"Extra"}
                          onChange={(e: any) => {
                            onChange(e.target.value);
                            handleExtraValueChange()
                          }}
                          type="number"
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={2} xs={2} md={2} lg={1}>
                    <CustomFormGrid container justifyContent="end">
                      <IconButton onClick={() => { remove(i) }}>
                        <DeleteIcon style={{ color: "#FC0505" }} />
                      </IconButton>
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              </Box>
            )
          })
        }
        {/* </form> */}
      </CustomFormGrid>
    </CustomFormGrid>
  );
};

export default LineItem;
