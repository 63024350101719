import React, { useEffect, useState } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { Typography, Fab, Grid, IconButton, Tooltip } from "@mui/material";
import { Add, ListAlt } from "@mui/icons-material";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllAvailableTrailers } from "src/redux/actions/trailer";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { VehicleTrailerProps } from "src/interfaces/vehicle";
import { useStyles } from "src/styles/formStyles";
import { sanitizeEntity } from "src/utils/common";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "src/components/common/confirmation";
import {
  addVehicleTrailer,
  updateVehicleTrailer,
} from "src/redux/actions/vehicle_trailer";
import { useParams } from "react-router-dom";
import { selectVehicle } from "src/redux/reducers/vehicle";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import TrailerVehicleLinkModal from "src/components/vehicle/trailerLinkingModal";
import { selectLinkedTrailer } from "src/redux/reducers/vehicle";
import LogsModal from "src/components/common/linkLogs";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import AssignedLinkingCard from 'src/components/common/linkingCards/assignedTrailerCard';
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function TrailerInfo() {
  const [translation] = useTranslation("translation");
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const params = useParams();
  const vehicle = useAppSelector(selectVehicle);
  const linkedTrailer = useAppSelector(selectLinkedTrailer);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [trailer, setTrailer] = useState<any | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const getTrailerList = async () => {
    setLoading(true);
    const filterObject: any = {};
    filterObject.items = [
      {
        columnField: "status",
        operatorValue: "equals",
        value: "active",
      },
    ];
    await dispatch(getAllAvailableTrailers(0,1, filterObject));
    setLoading(false);
  };
  useEffect(() => {
    getTrailerList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleConfirmDelete = async () => {
    if (params?.vehicleId) {
      setLoading(true);
      if (trailer && trailer.id) {
        const object = {
          trailer: trailer.id,
          vehicle: parseInt(params?.vehicleId),
        };

        await dispatch(updateVehicleTrailer(object));
      }
      handleCloseDeleteModal();
      setLoading(false);
      getTrailerList();
    }
  };
  const handleTrailerDelete = () => {
    setTrailer(linkedTrailer);
    handleOpenDeleteModal();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*VehicleTrailerProps  */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: VehicleTrailerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (params?.vehicleId) {
      objToSubmit.vehicle = parseInt(params?.vehicleId)
        ? parseInt(params?.vehicleId)
        : null;
    }
    objToSubmit.trailer = objToSubmit.trailer ? objToSubmit.trailer : null;
    if (params?.vehicleId) {
      const vehicleTrailerLink = await dispatch(addVehicleTrailer(objToSubmit));
      if (vehicleTrailerLink.success && vehicleTrailerLink.data) {
        handleClose();
        getTrailerList();
      }
    }

    setLoading(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };

  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "trailer.trailerId",
      headerName: translation("vehicle.form.trailerId.label"),

      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.trailer === "object" &&
            params?.row?.trailer?.trailerId
              ? params?.row?.trailer?.trailerId
              : ""}
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.created.at"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {" "}
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
     {/*  <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>
            {!linkedTrailer && (
              <Tooltip
                title={
                  <Typography>
                    {translation("vehicle.trailer.assign")}
                  </Typography>
                }
              >
                <Fab
                  color="secondary"
                  sx={{
                    mr: 1,
                    ml: 1,
                  }}
                  size="small"
                  onClick={handleClickOpen}
                >
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      {linkedTrailer ? (
        <Grid container alignItems="flex-end">
          <Grid item xs={12} md={6} lg={2}>
            <Grid container alignItems="center">
              <IconButton
                onClick={() => {
                  handleTrailerDelete();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="h3" className={classes.detailText}>
                {translation("vehicle.form.trailerId.label")} :
              </Typography>

              <Typography variant="h4" className={classes.detailText}>
                {linkedTrailer?.trailerId}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="h3" className={classes.detailText}>
                {translation("vehicle.form.model.label")} :
              </Typography>

              <Typography variant="h4" className={classes.detailText}>
                {linkedTrailer?.model}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Typography variant="h3" className={classes.detailText}>
                {translation("vehicle.form.VIN.label")} :
              </Typography>

              <Typography variant="h4" className={classes.detailText}>
                {linkedTrailer?.VIN}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="h3" className={classes.detailText}>
            {translation("vehicle.trailer.message")}
          </Typography>
        </Grid>
      )}
 */}
      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedTrailer}
          headerName={"Assigned Trailer"}
          isDriver={false}
          handleClickOpen={handleClickOpen}
          handleDelete={handleTrailerDelete}
          data={linkedTrailer} />
        </CustomFormGrid>
      <TrailerVehicleLinkModal
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}

          message={translation("vehicle.delete.message", {
            name:
              typeof linkedTrailer === "object"
                ? `${linkedTrailer?.trailerId} ${linkedTrailer?.model}`
                : "",
          })}

          title={translation("vehicle.delete.title")}
        />
      )}

      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={vehicle?.vehicleTrailers ? vehicle?.vehicleTrailers : []}
              columns={columns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              localPagination={true}
            />
          }
          title={translation("trailer.assignment.logs")}
        />
      )}
    </>
  );
}
