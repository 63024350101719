import React, { useState } from "react";
import { Typography, Button, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomCheckbox from "src/components/customElements/customCheckbox";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { selectGroupedSecurityGroup } from "src/redux/reducers/securityGroups";
import { useNavigate } from "react-router-dom";
import SecurityGroupDetail from "src/components/common/addCommonDialog";

interface PermissionsInfoProps {
  control: any;
  errors: { [key: string]: any };
  updatePermission: (data: Array<number>) => any;
  handleSelectSecurityGroup: (e: any) => void;
  loadingPermission: boolean;
  securityGroupArray: Array<number>;
  parentExpandAll?: boolean;
}

const PermissionsInfo = ({
  updatePermission,
  handleSelectSecurityGroup,
  loadingPermission,
  securityGroupArray,
}: PermissionsInfoProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [translation] = useTranslation<string>("translation");
  const allSecurityGroups = useAppSelector(selectGroupedSecurityGroup);
  const params = useParams();
  const [securityGroupModal, setSecurityGroupModal] = useState<boolean>(false);
  const [selectedSecurityGroup, setSelectedSecurityGroup] = useState<any>(null);
  const handleOpenSecurityGroupModal = (securityGroup: any) => {
    setSelectedSecurityGroup(securityGroup);
    setSecurityGroupModal(true);
  };
  const handleCloseSecurityGroupModal = () => {
    setSecurityGroupModal(false);
    setSelectedSecurityGroup(null);
  };

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: "0px 5px" }}
        >
          {allSecurityGroups &&
            allSecurityGroups.map((item, index) => {
              return (
                <CustomFormGrid item xs={12} sm={6} md={4} key={index}>
                  <Typography
                    variant="h4"
                    sx={{
                      padding: "5px",
                      textTransform: "uppercase",
                    }}
                  >
                    <CustomCheckbox
                      value={item?.id}
                      onChange={handleSelectSecurityGroup}
                      checked={securityGroupArray.includes(item?.id)}
                    />
                    {item?.name}
                    <IconButton
                      onClick={() => handleOpenSecurityGroupModal(item)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Typography>
                </CustomFormGrid>
              );
            })}
        </CustomFormGrid>
        {/*   <CustomFormGrid container justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            className={classes.formButton}
            onClick={() => updatePermission(securityGroupArray)}
          >
            {loadingPermission ? (
              <CircularProgress
                style={{
                  color: "#fff",
                  height: "20px",
                  width: "20px",
                }}
              />
            ) : params?.userId ? (
              translation("common.form.update")
            ) : (
              translation("common.form.add")
            )}
          </Button>
          <Button
            color="error"
            variant="contained"
            className={classes.formButton}
            onClick={() => navigate(-1)}
          >
            {translation("common.btn.cancel")}
          </Button>
        </CustomFormGrid> */}

        {selectedSecurityGroup && (
          <SecurityGroupDetail
            handleClose={handleCloseSecurityGroupModal}
            open={securityGroupModal}
            title={
              selectedSecurityGroup?.name +
              " " +
              `${translation("common.form.permissions")}`
            }
            ComponentToRender={
              selectedSecurityGroup?.securityGroupPermissions &&
              Object.keys(selectedSecurityGroup?.securityGroupPermissions)
                .length === 0 ? (
                <Typography
                  variant="h4"
                  sx={{
                    padding: "30px",
                    textAlign: "center",
                  }}
                >
                  {translation("common.form.noPermission")}
                </Typography>
              ) : (
                selectedSecurityGroup?.securityGroupPermissions &&
                Object.keys(
                  selectedSecurityGroup?.securityGroupPermissions
                ).map((element, index) => (
                  <CustomFormGrid container key={index}>
                    <CustomFormGrid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="h3"
                        sx={{
                          padding: "5px",
                          textTransform: "capitalize",
                        }}
                      >
                        {element}
                      </Typography>
                    </CustomFormGrid>

                    {selectedSecurityGroup?.securityGroupPermissions[element] &&
                      selectedSecurityGroup?.securityGroupPermissions[
                        element
                      ].map((permission: any, index: number) => {
                        return (
                          typeof permission === "object" && (
                            <CustomFormGrid
                              item
                              xs={12}
                              sm={6}
                              md={2}
                              key={index}
                            >
                              <FormControlLabel
                                sx={{ padding: "5px" }}
                                control={
                                  <CustomCheckbox
                                    value={permission?.id}
                                    disabled
                                    checked={securityGroupArray.includes(
                                      selectedSecurityGroup?.id
                                    )}
                                  />
                                }
                                label={permission.action}
                              />
                            </CustomFormGrid>
                          )
                        );
                      })}
                  </CustomFormGrid>
                ))
              )
            }
          />
        )}
      </CustomFormGrid>
    </div>
  );
};

export default PermissionsInfo;
