import * as React from "react";
import {
  DataGrid,
  GridOverlay,
  GridRowParams,
  GridToolbar,
  //  GridToolbar,
} from "@mui/x-data-grid";
import { CircularProgress, Box, Typography, Fab, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

import { selectVehicle } from "src/redux/reducers/vehicle";
import { useAppSelector } from "src/hooks/reduxHooks";
import { useTranslation } from "react-i18next";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface DataTableProps {
  rows: any;
  columns: Array<any>;
  loading: boolean;
  title?: string;
  onClickAdd?: () => any;
  onClickSearch?: () => any;

  ComponentToRender?: React.ReactNode;
  createPermission?: boolean;
  onRowClick?: (params: GridRowParams) => void;
  setRowsState?: (data: any) => any;
  rowsState?: any;
  pagination?: any;
  data?: any;
  setData?: (data: any) => any;
  localPagination?: boolean;
  add?: boolean;
  setFilter?: (data: any) => any;
  search?: string;
  hideBackBar?: boolean;
}

const DataTable = ({
  rows,
  columns,
  title,
  onClickAdd,
  ComponentToRender,
  createPermission,
  onRowClick,
  rowsState,
  setRowsState,
  pagination,
  setData,
  setFilter,
  // data,
  add,
  // loading,
  localPagination,
  search,
  onClickSearch,
  hideBackBar,
}: DataTableProps): JSX.Element => {
  const vehicle = useAppSelector(selectVehicle);
  const [translation] = useTranslation("translation");
  const [height, setHeight] = React.useState<number>(window.innerHeight - 180);
  const navigate = useNavigate();
  React.useEffect(() => {
    setHeight(window.innerHeight - 180);
  }, [window.innerHeight]);

  const CustomNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>{translation("common.noRecord.message")}</div>
      </GridOverlay>
    );
  };

  function CustomLoadingOverlay() {
    return (
      <GridOverlay>
        <CircularProgress />
      </GridOverlay>
    );
  }
  const onFilterChange = React.useCallback((filterModel) => {
    setFilter && filterModel.items.length > 0 && setFilter(filterModel);
  }, []);
  // /*   const loadServerRows = (
  //   page: number,
  //   pageSize: number,
  //   allRows: Array<any>
  // ) =>
  //   new Promise<Array<any>>((resolve) => {
  //     setTimeout(() => {
  //       resolve(allRows.slice(page * pageSize, (page + 1) * pageSize));
  //     }, Math.random() * 200 + 100); // simulate network latency
  //   }); */

  React.useEffect(() => {
    if (rows) {
      let active = true;

      (async () => {
        // setRowsState && setRowsState((prev: any) => ({ ...prev, loading: true }));
        setTimeout(() => {}, Math.random() * 200 + 100);
        // const newRows = await loadServerRows(
        //   rowsState.page,
        //   rowsState.pageSize,
        //   rows
        // );

        if (!active) {
          return;
        }

        setRowsState &&
          setRowsState((prev: any) => ({
            ...prev,
            // loading: false,
            rows: rows,
          }));
      })();

      return () => {
        active = false;
      };
    } else {
      setRowsState &&
        setRowsState((prev: any) => ({
          ...prev,
          // loading: false,
          rows: {},
        }));
    }
  }, [rowsState?.page, rowsState?.pageSize, rows]);

  return (
    <div style={{ width: "100%" }}>
      <>
        {ComponentToRender
          ? ComponentToRender
          : hideBackBar != true && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <Box flexGrow={1} display="flex" alignItems="center">
                  {add === true && (
                    <Button>
                      <ArrowBack onClick={() => navigate(-1)} />
                    </Button>
                  )}
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "0",
                      textTransform: "capitalize",
                    }}
                    gutterBottom
                  >
                    {title}
                  </Typography>
                </Box>
                <Box>
                  {createPermission &&
                    !vehicle?.vehicleTrailers?.isActive &&
                    add === true && (
                      <Fab
                        color="secondary"
                        sx={{
                          mr: 1,
                          mb: {
                            xs: 1,
                            sm: 0,
                            lg: 0,
                          },
                        }}
                        onClick={onClickAdd}
                      >
                        <Add />
                      </Fab>
                    )}
                </Box>
                <Box>
                  {search && search.length > 0 === true && (
                    <Fab
                      color="secondary"
                      sx={{
                        mr: 1,
                        mb: {
                          xs: 1,
                          sm: 0,
                          lg: 0,
                        },
                      }}
                      onClick={onClickSearch}
                    >
                      <SearchIcon />
                    </Fab>
                  )}
                </Box>
              </Box>
            )}
        <Box
          sx={{
            overflow: "auto",
            mt: add === true ? 3 : 1,
          }}
        >
          <div
            style={{
              height: add === true ? height : "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            {localPagination == true ? (
              <DataGrid
                pagination={false}
                {...rowsState}
                columns={[...columns]}
                onRowClick={onRowClick}
                rowsPerPageOptions={[5, 10, 50, 100]}
                onPageSizeChange={(pageSize) => {
                  setData && setData([]);
                  setRowsState &&
                    setRowsState((prev: any) => ({
                      ...prev,
                      pageSize,
                      page: 0,
                      row: [],
                    }));
                }}
                onPageChange={(page) =>
                  setRowsState &&
                  setRowsState((prev: any) => ({ ...prev, page }))
                }
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            ) : add === false ? (
              <DataGrid
                style={{
                  height: rows?.length === 1 ? "190px" : "280px",
                }}
                pagination={false}
                {...rowsState}
                columns={[...columns]}
                onRowClick={onRowClick}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            ) : (
              <DataGrid
                //rows={rows}
                // filterModel={{
                //   items: [
                //     {
                //       id: 1,
                //       columnField: "status",
                //       operatorValue: ">",
                //       value: "4",
                //     },
                //     {
                //       id: 2,
                //       columnField: "email",
                //       operatorValue: "is",
                //       value: "true",
                //     },
                //   ],
                // }}
                rowCount={pagination?.total}
                paginationMode="server"
                pagination
                onPageChange={(page) =>
                  setRowsState &&
                  setRowsState((prev: any) => ({ ...prev, page }))
                }
                //   filterMode="server"
                //  onFilterModelChange={onFilterChange}
                // pageSize={pageSize}
                {...rowsState}
                columns={[...columns]}
                onPageSizeChange={(pageSize) => {
                  setData && setData([]);
                  setRowsState &&
                    setRowsState((prev: any) => ({
                      ...prev,
                      pageSize,
                      page: 0,
                      row: [],
                    }));
                }}
                rowsPerPageOptions={[5, 10, 50, 100]}
                onRowClick={onRowClick}
                components={
                  setFilter
                    ? {
                        NoRowsOverlay: CustomNoRowsOverlay,
                        // Toolbar: GridToolbar,
                      }
                    : {
                        NoRowsOverlay: CustomNoRowsOverlay,
                      }
                }
                LoadingOverlay={CustomLoadingOverlay}
                // initialState={{
                //   filter: {
                //     filterModel: {
                //       items: [
                //         {
                //           columnField: "status",
                //           operatorValue: "contains",
                //           value: "abc",
                //         },
                //       ],
                //     },
                //   },
                // }}
              />
            )}
          </div>
        </Box>
      </>
    </div>
  );
};
export default DataTable;
