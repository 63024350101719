import React, { useEffect, useState } from "react";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { OwnerVehicleProps } from "src/interfaces/owner";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";
import { useStyles } from "src/styles/formStyles";
import ConfirmationDialog from "src/components/common/confirmation";
import DriverOwnerDialogBox from "src/components/common/ownerAddDialog";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid, IconButton, Card } from "@mui/material";
import { selectLinkedOwner } from "src/redux/reducers/vehicle";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import { getAllOwners } from "src/redux/actions/owner";
import { selectVehicle } from "src/redux/reducers/vehicle";
import { getVehicleById } from "src/redux/actions/vehicle";
import {
  addOwnerVehicle,
  updateOwnerVehicle,
} from "src/redux/actions/owner_vehicle";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import AssignedLinkingCard from 'src/components/common/linkingCards/assignedOwner';
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

const VehicleOwner = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const vehicle = useAppSelector(selectVehicle);
  const linkedOwner = useAppSelector(selectLinkedOwner);
  const [translation] = useTranslation("translation");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const owner =
    linkedOwner && typeof linkedOwner?.owner === "object"
      ? linkedOwner?.owner
      : null;

  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const getOwnerList = async () => {
    setLoading(true);
    await dispatch(getAllOwners(0, 1));
    setLoading(false);
  };
  const getSingleVehicle = async (id: number) => {
    setLoading(true);
    await dispatch(getVehicleById(id));
    setLoading(false);
  };
  useEffect(() => {
    getOwnerList();
  }, []);
  useEffect(() => {
    if (params?.vehicleId) {
      getSingleVehicle(parseInt(params?.vehicleId));
    }
  }, [params?.vehicleId]);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleOwnerDelete = () => {
    handleOpenDeleteModal();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (params?.vehicleId) {
      setLoading(true);
      if (linkedOwner) {
        const object = {
          owner:
            typeof linkedOwner?.owner === "object"
              ? linkedOwner?.owner?.id
                ? linkedOwner?.owner?.id
                : ""
              : linkedOwner?.owner,

          vehicle: parseInt(params?.vehicleId),
        };
        await dispatch(updateOwnerVehicle(object));
      }

      handleCloseDeleteModal();
      setLoading(false);
      getOwnerList();
    }
  };
  /* OwnerTrailerProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: OwnerVehicleProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (params?.vehicleId) {
      objToSubmit.vehicle = parseInt(params?.vehicleId)
        ? parseInt(params?.vehicleId)
        : null;
    }
    objToSubmit.owner = objToSubmit.owner ? objToSubmit.owner : null;
    if (params?.vehicleId) {
      const VehicleOwnerLink = await dispatch(addOwnerVehicle(objToSubmit));
      if (VehicleOwnerLink.success && VehicleOwnerLink.data.id) {
        setTimeout(
          () => navigate(`/vehicle/vehicles/details/${params?.vehicleId}`),
          500
        );

      }
    }
    handleClose();
    getOwnerList();
    setLoading(false);
  };
  const columns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 200,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 100,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "comment",
      headerName: translation("common.comment.label"),
      minWidth: 200,
    },
    {
      field: "createdAt",
      headerName: translation("common.created.at"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.createdAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: translation("common.date.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  return (
    <>
   {/*    <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>
            {(linkedOwner === null || linkedOwner === undefined ||
              Object.keys(linkedOwner).length === 0) && (
              <Tooltip
                title={
                  <Typography>{translation("driver.owner.assign")}</Typography>
                }
              >
                <Fab
                  color="secondary"
                  sx={{
                    mr: 1,
                    ml: 1,
                  }}
                  size="small"
                  onClick={handleClickOpen}
                >
                  <Add />
                </Fab>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      {linkedOwner != null && Object.keys(linkedOwner).length != 0 ? (
        <Card>
          <CustomFormGrid container>
            <CustomFormGrid container >
              <CustomFormGrid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
              //  className={classes.deleteIconButton}
              >
                <IconButton
                  onClick={() => {
                    handleOwnerDelete();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </CustomFormGrid>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={3}>
                <Typography className={classes.detailHeadingText}>
                  {translation("owner.form.id.label")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={3}>
                <Typography className={classes.detailHeadingText}>
                  {translation("owner.form.name.label")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={3}>
                <Typography className={classes.detailHeadingText}>
                  {translation("common.list.assignedOn")}
                </Typography>
              </CustomFormGrid>
            </CustomFormGrid>
            <CustomFormGrid container style={{paddingTop:"0px"}}>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={2}></CustomFormGrid>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={3}>
              <Typography className={classes.detailText}>
                  {owner?.id}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={3}>
              <Typography className={classes.detailText}>
                  {owner?.firstName}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item xs={3} sm={3} md={3} lg={3}>
              <Typography className={classes.detailText}>
                  {linkedOwner?.createdAt
                    ? moment(linkedOwner?.createdAt).format(dateFormat)
                    : ""}
                </Typography>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomFormGrid>

        </Card>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              marginBottom: "0",
              textTransform: "capitalize",
              mr: 1,
              ml: 1,
            }}
          >
            {translation("driver.owner.message")}
          </Typography>
        </Grid>
      )}
 */}
         <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedOwner}
          headerName={"Assigned Owner"}
          isDriver={false}
          handleClickOpen={handleClickOpen}
          handleDelete={handleOwnerDelete}
          data={ owner} />
      </CustomFormGrid>

      {/*   <DataTable
        title={""}
        rows={linkedOwner?.owner}
        columns={columns}
        loading={loading}
        onClickAdd={handleClickOpen}
        rowsState={rowsState}
        setRowsState={setRowsState}
        add={true}
      />
 */}


      <DriverOwnerDialogBox
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        ownerTitle={translation("vehicleOwner.dialogbox.title")}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof linkedOwner?.owner === "object"
                ? `${linkedOwner?.owner?.firstName} ${linkedOwner?.owner?.lastName}`
                : "",
          })}
          title={translation("vehicleOwner.delete.title")}
          loading={loading}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={vehicle?.ownerVehicle}
              columns={columns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              localPagination={true}
            />
          }
          title={translation("owner.assignment.logs")}
        />
      )}
    </>
  );
};
export default VehicleOwner;
