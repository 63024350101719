import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllLoadManagements } from "src/redux/actions/loadManagement";
import { selectLoadManagements } from "src/redux/reducers/loadManagement";
import { useTranslation } from "react-i18next";
import { create, update, featureLoad } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import moment from "moment";
import { dateFormat, dateTimeFormat } from "src/utils/constants";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toNumber } from "lodash";
import { CircularProgress } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddLoadStopInfoModel from "src/components/load/stopOffFormModel";
import { getAllCustomers } from "src/redux/actions/customer";
import { getAllCommodities } from "src/redux/actions/commodity";
import { getAllTerminals } from "src/redux/actions/terminal";
import { getAllUsers } from "src/redux/actions/users";
import { getAllLineItems } from "src/redux/actions/lineItems";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { getAllAvailableDrivers } from "src/redux/actions/driver";
import { getAllAvailableTrailers } from "src/redux/actions/trailer";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Clipboard } from "ts-react-feather-icons";

import { getAllAvailableVehicles } from "src/redux/actions/vehicle";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function ListItems() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const allLoadManagement = useAppSelector(selectLoadManagements);
  const [allLoadManagementData, setAllLoadManagementData] = useState<any>({});
  const [translation] = useTranslation("translation");
  const [filter, setFilter] = useState<any>({});
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const user = useAppSelector(selectUser);
  const planned = location.pathname.includes("planned");
  const inProgress = location.pathname.includes("inProgress");
  const [openStopOffFormModal, setOpenStopOffFormModal] = useState<any>(null);
  const [selectedLoad, setSelectedLoad] = useState<any>(null);

  const allUserPermissions = useAppSelector(selectUserPermissions);
  const loadManagementPermissions =
    allUserPermissions && allUserPermissions[featureLoad];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : loadManagementPermissions.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : loadManagementPermissions.findIndex((item) => item === update);

  useEffect(() => {
    dispatch(getAllLookupsByCategory("all", 0, 1));

    dispatch(getAllCustomers(0, 1));
    dispatch(getAllCommodities(0, 1));
    dispatch(getAllTerminals(0, 1));
    dispatch(getAllUsers(0, 1));
    dispatch(getAllAvailableDrivers());
    dispatch(getAllAvailableTrailers());

    dispatch(getAllAvailableVehicles());
  }, []);
  const getLoadManagementList = async () => {
    setLoading(true);
    await dispatch(
      getAllLoadManagements(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };
  const getLoadManagementListListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllLoadManagements(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          false,
          filter
        )
      ));
  };
  useEffect(() => {
    const filterObject: any = {};
    if (planned === true) {
      filterObject.items = [
        {
          columnField: "loadStatus",
          operatorValue: "equals",
          value: "planned",
        },
      ];
      setFilter(filterObject);
    } else if (inProgress === true) {
      filterObject.items = [
        {
          columnField: "loadStatus",
          operatorValue: "equals",
          value: "inProgress",
        },
      ];
      setFilter(filterObject);
    }
  }, []);

  useEffect(() => {
    if (planned === false && inProgress === false) {
      if (Object.keys(filter).length !== 0) {
        getLoadManagementListListWithFilter();
      } else getLoadManagementList();
    } else if (planned === true || inProgress === true) {
      if (Object.keys(filter).length !== 0) {
        getLoadManagementListListWithFilter();
      }
    }
  }, [rowsState.pageSize, rowsState.page]);
  useEffect(() => {
    getLoadManagementListListWithFilter();
  }, [filter]);

  useEffect(() => {
    console.log("LLLLLLLLLLl", allLoadManagement);
    // if (pageExists(rowsState?.page + 1) == false)
    if (allLoadManagement?.length > 0) {
      setAllLoadManagementData({
        ...allLoadManagementData?.allLoadManagement,
        allLoadManagement,
      });
    } else {
      setAllLoadManagementData({});
    }
  }, [allLoadManagement]);

  const addLoadManagment = () => {
    navigate("/load-management/load-management/create");
  };
  const searchLoadManagment = () => {
    navigate("/load-management/load-management/search");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals"].includes(value)
  );
  const handleOpenStopOffFormModal = (index: any) => {
    setOpenStopOffFormModal && setOpenStopOffFormModal(index);
  };
  const handleCloseStopOffFormModal = () => {
    setOpenStopOffFormModal && setOpenStopOffFormModal(null);
  };
  const columns: GridColDef[] | any = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      headerClassName: 'tms-table--header',
      minWidth: 40,
      filterOperators,
    },
    {
      field: "loadStatus",
      headerName: "Status",
      headerClassName: 'tms-table--header',
      minWidth: 100,
      flex: 1,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.loadStatus}</span>;
      },
    },
    {
      field: "shipper",
      headerName: "Shipper",
      headerClassName: 'tms-table--header',
      minWidth: 180,
      flex: 1,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.shippingInformation?.company}</span>;
      },
    },
    {
      field: "consignee",
      headerName: "Consignee",
      headerClassName: 'tms-table--header',
      minWidth: 180,
      flex: 1,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.consigneeInformation?.company}</span>;
      },
    },
    {
      field: "billOfLading",
      headerName: "BOL",
      headerClassName: 'tms-table--header',
      minWidth: 100,
      flex: 1,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <span>{params.row?.billOfLading}</span>;
      },
    },
    {
      field: "pickupDateTime",
      headerName: translation("load.form.pickupDateTime.label"),
      headerClassName: 'tms-table--header',
      minWidth: 130,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.pickupDateTime
              ? moment(params.row.pickupDateTime).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "deliveryDateTime",
      headerName: translation("load.form.deliveryDateTime.label"),
      headerClassName: 'tms-table--header',
      minWidth: 130,
      filterable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.deliveryDateTime
              ? moment(params.row.deliveryDateTime).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 110,
      headerName: translation("common.actions.label"),
      headerClassName: 'tms-table--header',
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          key="edit"
          disabled
          onClick={() => {
            navigate(`/load-management/load-management/${params.row.id}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="Details"
          key="details"
          disabled
          onClick={() => {
            navigate(
              `/load-management/load-management/details/${params.row.id}`
            );
          }}
        />,
      ],
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      headerClassName: 'tms-table--header',
      minWidth: 130,
      flex: 1,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.createdAt
              ? moment(params.row.createdAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    
  ];

  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <>
      <CustomFormGrid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ paddingTop: "20px" }}
      >
        {" "}
        <DataTable
          title={"Loads"}
          rows={allLoadManagementData?.allLoadManagement}
          columns={columns}
          loading={loading}
          onClickAdd={addLoadManagment}
          onClickSearch={searchLoadManagment}
          createPermission={createPermission > -1}
          rowsState={rowsState}
          // onRowClick={onRowClick}
          setRowsState={setRowsState}
          pagination={allLoadManagement.pagination}
          data={allLoadManagementData?.allLoadManagement}
          setData={setAllLoadManagementData}
          add={true}
          setFilter={setFilter}
          search={"load"}
        />{" "}
        {openStopOffFormModal !== null &&
          selectedLoad &&
          openStopOffFormModal == selectedLoad?.id && (
            <AddLoadStopInfoModel
              loadId={selectedLoad?.id}
              handleClose={handleCloseStopOffFormModal}
              open={openStopOffFormModal}
              title="StopOff Detail"
              item={selectedLoad?.loadStops[0]}
              freightBill={selectedLoad?.freightBill}
              buttonText={"Update"}
              setOpenStopOffFormModal={setOpenStopOffFormModal}
              dispatchTabOpen={true}
              disabled={false}
            />
          )}
      </CustomFormGrid>
    </>
  );
}
