import * as React from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Autocomplete, Typography, Divider } from "@mui/material";
import { selectCustomers } from "src/redux/reducers/customer";
import { useAppSelector } from "src/hooks/reduxHooks";
import { getAllCustomers } from "src/redux/actions/customer";
import { InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { getMilageCalculations } from "src/redux/actions/loadManagement";

import {
  LoadStopProps,
  LoadStopShipperInformation,
} from "src/interfaces/loadManagement";
import { CustomerProps } from "src/interfaces/customer";
import "react-phone-input-2/lib/style.css";
import { useStyles } from "src/styles/formStyles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Edit } from "@mui/icons-material";
import { Add } from "@mui/icons-material";
import AddShipperDialog from "src/components/load/dispatch/editLoadStopShipperModel";
import EditConsigneeDialog from "src/components/load/dispatch/editLoadStopConsigneeModel";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomerForm from "src/containers/customers/form";
import AddCustomerDialog from "src/components/common/addCommonDialog";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { MobileDateTimePicker } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import EventIcon from "@mui/icons-material/Event";
import Tooltip from "@mui/material/Tooltip";
interface StopOffsProps {
  control: any;
  errors: { [key: string]: any };
  buttonText: string;
  watch: (key: string) => any;
  item: LoadStopProps;
  setValue: any;
  handleLoadStopChange: (value: any, key: keyof LoadStopProps) => void;
  handleLoadStopChangeMilage: (
    value: any,
    key: keyof LoadStopProps,
    value1: any,
    key1: keyof LoadStopProps
  ) => void;

  handleLoadStopChangeForShipper: (
    value: string,
    key: keyof LoadStopShipperInformation,
    shipper: boolean
  ) => void;
  handleSelectCustomer: (
    event: any,
    newValue: CustomerProps | null,
    shipper: boolean
  ) => any;

  // handleAddLoadStop: (index?: number) => void;
  showAllFields?: boolean;
  openConsigneeModel?: boolean;
  openTerminalModal?: boolean;
  showCustomerAllFields?: boolean;
  disabled: boolean;
  setOpenConsineeModel?: (index: any) => void;
  setOpenTerminalModal?: (index: any) => void;
  setShowCustomerAllFields?: (index: any) => void;
}
const StopOffs = ({
  control,
  errors,
  handleLoadStopChange,
  handleLoadStopChangeMilage,
  item,
  disabled,
  setOpenConsineeModel,
  setOpenTerminalModal,
  setShowCustomerAllFields,
}: //handleAddLoadStop,
StopOffsProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [translation] = useTranslation("translation");
  const allCustomers = useAppSelector(selectCustomers);
  const [totalDispatchPay, setTotalDispatchPay] = React.useState<number>(0);
  const [totalfreightPay, setTotalfreightPay] = React.useState<number>(0);
  const [totalLoadPay, setTotalLoadPay] = React.useState<number>(0);
  const isMobile = useMediaQuery("(max-width:650px)");
  const [openAddCustomerModal, setOpenAddCustomerModal] =
    React.useState<boolean>(false);
  const [showEditAddressForms, setShowEditAddressForms] =
    React.useState<boolean>(false);
  const [address, setAddress] = React.useState<any>("");

  const arrivalDateRef = React.useRef<any>();
  const departureDateRef = React.useRef<any>();
  const [dispatchAddress, setDispatchAddress] = React.useState<any>([]);
  const [shipperAddress, setShipperAddress] = React.useState<any>({});
  const [consigneeAddress, setConsigneeAddress] = React.useState<any>({});

  /*   const onChangePhoneInput = (
    value: string,
    country: string,
    key: string,
    index: number,
    shipper: boolean,
    fieldName: string
  ) => {
    const phoneNumber = formattedPhoneNumber(value, country);
    setValue(`stops[${index}].${key}Value`, value);
    setValue(`stops[${index}].${key}Country`, country);
    // @ts-ignore: Unreachable code error
    handleLoadStopChangeForShipper(phoneNumber, index, fieldName, shipper);
    if (errors && errors?.stops) {
      delete errors?.stops[index][key];
    }
  }; */
  const dispatchInformation = [
    // {
    //   orderNumber: 0,
    //   type: "TT",
    //   include: true,
    //   arrival: "",
    //   departure: "",
    //   address: "123 Street Milwaukee, WI 53220",
    // },
    // {
    //   orderNumber: 1,
    //   type: "SH",
    //   include: true,
    //   arrival: "",
    //   departure: "",
    //   address: "123 Street Milwaukee, WI 53220",
    // },
    // {
    //   orderNumber: 2,
    //   type: "SO",
    //   include: false,
    //   arrival: "",
    //   departure: "",
    //   address: "PO BOX 339 La Canada, CA 91012",
    // },
    // {
    //   orderNumber: 3,
    //   type: "CN",
    //   include: false,
    //   arrival: "",
    //   departure: "",
    //   address: "123 Street Indianapolis, IN 46201",
    // },
    {
      id: 4,
      type: "SH",
      include: true,
      arrival: null,
      departure: null,
      orderNumber: 1,
      createdAt: "2022-05-12T09:26:22.345Z",
      updatedAt: "2022-05-12T09:26:22.345Z",
      address: {
        id: 521,
        company: "Global soft test test test test test test test",
        contact: null,
        phoneNumber: "+923366191142",
        faxPhoneNumber: null,
        email: "sanailyas64353@gmail.com",
        directionNotes: null,
        address: "9889 Bellaire Blvd #113",
        hourStart: "2022-04-25T22:44:00.724Z",
        hourEnd: "2022-04-26T11:05:00.058Z",
        zip: "77036",
        city: "Houston",
        state: "Texas",
        createdAt: "2022-05-12T09:26:21.836Z",
        updatedAt: "2022-05-12T09:26:21.836Z",
      },
    },
  ];
  React.useEffect(() => {
    const copy = JSON.parse(JSON.stringify(item?.dispatchInformations));
    copy.sort((a: any, b: any) => (a.orderNumber > b.orderNumber ? 1 : -1));

    setDispatchAddress(copy);
    item?.dispatchInformations?.map((itemData: any) => {
      if (itemData?.type == "SH") {
        setShipperAddress(JSON.parse(JSON.stringify(itemData)));
      } else if (itemData?.type == "CN") {
        setConsigneeAddress(JSON.parse(JSON.stringify(itemData)));
      }
    });
  }, []);

  React.useEffect(() => {
    getMilage();
  }, [dispatchAddress]);
  // React.useEffect(() => {
  //   if (dispatchAddress?.length > 0) {
  //     console.log("InUseEffectFordispatchAddress", dispatchAddress);
  //     handleLoadStopChange(dispatchAddress, "dispatchInformations");
  //   }
  // }, [dispatchAddress]);
  const getMilage = async () => {
    // if (dispatchAddress?.length > 0) {
    //   console.log("InUseEffectFordispatchAddress", dispatchAddress);
    //   handleLoadStopChange(dispatchAddress, "dispatchInformations");
    // }
    const markers = JSON.parse(JSON.stringify(dispatchAddress));

    const start = markers.find((x: any) => x.include == true);
    const end = markers.reverse().find((x: any) => x.include == true);
    await dispatch(
      getMilageCalculations(start?.address?.zip, end?.address?.zip)
    ).then((res: any) => {
      if (res.data > -1) {
        // setTimeout(() => handleLoadStopChange(res.data, "fuelMiles"), 300);
        handleLoadStopChangeMilage(
          res.data,
          "moveMiles",
          dispatchAddress,
          "dispatchInformations"
        );
      }

      // @ts-ignore: Unreachable code error
    });
  };

  function CheckOriginAddressChanges() {
    // @ts-ignore: Unreachable code error
    const SHType = dispatchAddress.find((x: any) => x.type == "SH");
    const DOType = dispatchAddress.find((x: any) => x.type == "DO");

    if (DOType && Object.keys(DOType).length !== 0) {
      let markers = JSON.parse(JSON.stringify(dispatchAddress));
      if (
        shipperAddress?.address?.address == DOType?.address?.address &&
        shipperAddress?.address?.zip == DOType?.address?.zip &&
        shipperAddress?.address?.city == DOType?.address?.city &&
        shipperAddress?.address?.state == DOType?.address?.state
      ) {
        markers = markers.filter((item: any) => item.type !== "DO");
      } else {
        const foundIndex = dispatchAddress.findIndex(
          (x: any) => x.type == DOType.type
        );
        markers[foundIndex] = Object.assign(
          {},
          { ...shipperAddress, type: "DO" }
        );
      }

      setDispatchAddress(markers);
    } else if (SHType && Object.keys(SHType).length !== 0) {
      if (
        shipperAddress?.address?.address !== SHType?.address?.address ||
        shipperAddress?.address?.zip !== SHType?.address?.zip ||
        shipperAddress?.address?.city !== SHType?.address?.city ||
        shipperAddress?.address?.state !== SHType?.address?.state
      ) {
        let foundIndex = 0;
        const markers = JSON.parse(JSON.stringify(dispatchAddress));
        const findFirstIncludeIndex = markers.find(
          (x: any) => x.include == true
        );
        foundIndex = findFirstIncludeIndex?.orderNumber;
        const tempDO = {
          orderNumber: foundIndex,
          type: "DO",
          include: true,
          arrival: null,
          departure: null,
          address: shipperAddress?.address,
        };
        let tempOrderNumber = foundIndex + 1;
        markers.map((item: any, index: any) => {
          if (item?.orderNumber > foundIndex) {
            markers[index] = { ...item, orderNumber: tempOrderNumber };
            tempOrderNumber++;
          }
        });
        markers.splice(foundIndex, 0, tempDO);

        markers.sort((a: any, b: any) =>
          a.orderNumber > b.orderNumber ? 1 : -1
        );
        setDispatchAddress(markers);
      }
    }
  }
  function CheckConsigneeAddressChanges() {
    // @ts-ignore: Unreachable code error
    const CNType = dispatchAddress.find((x: any) => x.type == "CN");
    const DDType = dispatchAddress.find((x: any) => x.type == "DD");
    if (DDType && Object.keys(DDType).length !== 0) {
      let markers = JSON.parse(JSON.stringify(dispatchAddress));

      if (
        consigneeAddress?.address?.address == DDType?.address?.address &&
        consigneeAddress?.address?.city == DDType?.address?.city &&
        consigneeAddress?.address?.zip == DDType?.address?.zip &&
        consigneeAddress?.address?.state == DDType?.address?.state
      ) {
        markers = markers.filter((item: any) => item.type !== "DD");
      } else {
        const foundIndex = dispatchAddress.findIndex(
          (x: any) => x.type == DDType.type
        );
        markers[foundIndex] = Object.assign(
          {},
          { ...consigneeAddress, type: "DD" }
        );
      }

      setDispatchAddress(markers);
    } else if (CNType && Object.keys(CNType).length !== 0) {
      if (
        consigneeAddress?.address?.address !== CNType?.address?.address ||
        consigneeAddress?.address?.city !== CNType?.address?.city ||
        consigneeAddress?.address?.zip !== CNType?.address?.zip ||
        consigneeAddress?.address?.state !== CNType?.address?.state
      ) {
        let foundIndex = 0;
        const markers = JSON.parse(JSON.stringify(dispatchAddress));
        const reverseArray = JSON.parse(
          JSON.stringify(dispatchAddress)
        ).reverse();
        const findFirstIncludeIndex = reverseArray.find(
          (x: any) => x.include == true
        );
        foundIndex = findFirstIncludeIndex?.orderNumber + 1;
        const tempDO = {
          orderNumber: foundIndex,
          type: "DD",
          include: true,
          arrival: null,
          departure: null,
          address: consigneeAddress?.address,
        };
        let tempOrderNumber = foundIndex;

        markers.map((item: any, index: any) => {
          if (item?.orderNumber > foundIndex) {
            markers[index] = { ...item, orderNumber: tempOrderNumber };
            tempOrderNumber++;
          }
        });
        markers.splice(foundIndex, 0, tempDO);

        markers.sort((a: any, b: any) =>
          a.orderNumber > b.orderNumber ? 1 : -1
        );

        setDispatchAddress(markers);
      }
    }
  }
  function filterDO(value: any) {
    return value.type !== "DO";
  }

  const handleShipperAddressChange = (value: any, key: any) => {
    const newItem = JSON.parse(JSON.stringify(shipperAddress));

    newItem["address"][key] = value;

    setShipperAddress(newItem);
  };
  const handleConsigneeAddressChange = (value: any, key: any) => {
    const newItem = Object.assign({}, consigneeAddress);
    newItem["address"][key] = value;

    setConsigneeAddress(newItem);
  };

  const handleDispatchAddressChange = (
    value: number | null | boolean | string,
    key: any,
    index: any
  ) => {
    let newItem = JSON.parse(JSON.stringify(dispatchAddress));

    if (
      key == "include" &&
      value == false &&
      (newItem[index]["type"] == "DO" || newItem[index]["type"] == "DD")
    ) {
      newItem = newItem.filter(
        (item: any) => item.type !== newItem[index]["type"]
      );
      setDispatchAddress(newItem);

      return;
    }
    /*  if (key == "companyDriver") {
      setVehicle({});
      setTrailer({});
      newItem["vehicle"] = null;
      newItem["trailer"] = null;
      newItem["driver"] = null;
    } */
    // @ts-ignore: Unreachable code error

    newItem[index][key] = value;
    setDispatchAddress(newItem);
  };

  const handleAddressChange = (e: any) => {
    const defaultObject = {
      index: 4,
      type: "OD",
      //  include: false,
      arrival: "10/10/2020",
      departure: "10/10/2020",
      address: e,
    };

    const tempData = Object.assign({}, defaultObject);
    const _newAddressArr: Array<any> = [
      ...dispatchAddress,
      {
        ...tempData,
      },
    ];
  };

  const selectArrivalDate = () => {
    if (arrivalDateRef?.current) {
      arrivalDateRef?.current?.click();
    }
  };
  const selectDepartureDate = () => {
    if (departureDateRef?.current) {
      departureDateRef?.current?.click();
    }
  };

  React.useEffect(() => {
    if (item.dispatchPay == "flatRate") {
      setTotalLoadPay(totalDispatchPay);
    } else if (item.dispatchPay == "percentOfFreight") {
      setTotalLoadPay(totalfreightPay);
    }
  }, [totalfreightPay, totalDispatchPay]);

  /*   const handleOpenTerminalModal = () => {
    setOpenTerminalModal(true);
    setShowCustomerAllFields(true);
  }; */

  const handleCloseEditShipper = () => {
    setOpenTerminalModal && setOpenTerminalModal(false);
    setShowCustomerAllFields && setShowCustomerAllFields(false);
  };

  const handleOpenEditShipper = () => {
    setOpenTerminalModal && setOpenTerminalModal(true);
    setShowCustomerAllFields && setShowCustomerAllFields(true);
  };

  const handleOpenEditConsignee = () => {
    setOpenConsineeModel && setOpenConsineeModel(true);
    setShowCustomerAllFields && setShowCustomerAllFields(true);
  };
  const handleCloseConsineeModal = () => {
    setOpenConsineeModel && setOpenConsineeModel(false);
  };

  const handleOpenAddCustomerModal = () => {
    setOpenAddCustomerModal(true);
  };
  const handleCloseAddCustomerModal = () => {
    setOpenAddCustomerModal(false);
    dispatch(getAllCustomers(0, 1));
    // setShowCustomerAllFields(true);
  };

  return (
    <CustomFormGrid
      container
      spacing={isMobile ? 0 : 2}
      style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CustomFormGrid container>
          <CustomFormGrid item sm={10} xs={10} md={10} lg={10}>
            <Typography className={classes.loadFormHeadings}>
              {translation("load.loadStop.dispatch")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid
            item
            sm={2}
            xs={2}
            md={2}
            lg={2}
            onClick={() => setShowEditAddressForms(true)}
          >
            <Typography className={classes.loadFormHeadingsEdit}>
              {translation("load.loadStop.edit")}
            </Typography>
          </CustomFormGrid>
          <CustomFormGrid container className={classes.dispatchAddressTable}>
            <CustomFormGrid
              container
              className={classes.dispatchAddressTableHead}
              style={{ paddingLeft: "10px" }}
            >
              <CustomFormGrid item sm={2} xs={2} md={2} lg={1}>
                <Typography className={classes.dispatchAddressTableHeadings}>
                  Inc
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item sm={2} xs={2} md={2} lg={1}>
                <Typography className={classes.dispatchAddressTableHeadings}>
                  Type
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item sm={2} xs={2} md={2} lg={4}>
                <Typography className={classes.dispatchAddressTableHeadings}>
                  Address
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item sm={2} xs={2} md={2} lg={3}>
                <Typography className={classes.dispatchAddressTableHeadings}>
                  Arrival
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid item sm={2} xs={2} md={2} lg={3}>
                <Typography className={classes.dispatchAddressTableHeadings}>
                  Departure
                </Typography>
              </CustomFormGrid>
            </CustomFormGrid>

            {dispatchAddress &&
              dispatchAddress?.length > 0 &&
              dispatchAddress?.map((addressItem: any, index: any) => (
                <CustomFormGrid
                  key={index}
                  container
                  style={{ paddingLeft: "0px" }}
                >
                  <CustomFormGrid item sm={2} xs={2} md={2} lg={1}>
                    <CustomFormGrid
                      item
                      xs={12}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        padding: "0px 2px",
                      }}
                    >
                      {/* <Controller
                      name="include"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => ( */}
                      <Checkbox
                        // {...field}
                        checked={addressItem?.include}
                        disabled={disabled}
                        // onChange={(e) => field.onChange(e.target.checked)}
                        onClick={(e) => {
                          // field.onChange(e.target.checked);
                          handleDispatchAddressChange(
                            addressItem?.include ? false : true,
                            "include",
                            index
                          );
                          // handleCheckBoxChange(item?.index);
                        }}
                      />
                      {/* )} */}
                      {/* /> */}
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <CustomFormGrid item sm={1} xs={1} md={1} lg={1}>
                    <Typography style={{ paddingTop: "10px" }}>
                      {addressItem?.type}
                    </Typography>
                  </CustomFormGrid>
                  <CustomFormGrid item sm={4} xs={4} md={4} lg={4}>
                    <Typography style={{ paddingTop: "10px" }}>
                      {addressItem?.address?.address}
                      {addressItem?.address?.city &&
                      addressItem?.address?.address
                        ? ","
                        : ""}
                      {addressItem?.address?.city
                        ? " " + addressItem?.address?.city
                        : ""}
                      {addressItem?.address?.state && addressItem?.address?.city
                        ? ","
                        : ""}
                      {addressItem?.address?.state
                        ? " " + addressItem?.address?.state
                        : ""}
                      {addressItem?.address?.zip && addressItem?.address?.state
                        ? ","
                        : ""}
                      {addressItem?.address?.zip
                        ? " " + addressItem?.address?.zip
                        : ""}

                      {/*    {addressItem?.address?.address} */}
                    </Typography>
                  </CustomFormGrid>
                  <CustomFormGrid item sm={3} xs={3} md={3} lg={3}>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="arrival"
                        control={control}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field }) => (
                          <MobileDateTimePicker
                            {...field}
                            label="Arrival Date"
                            // disablePast
                            inputFormat="MM/dd/yyyy hh:mm a"
                            // value={item.arrivalDate}
                            value={addressItem?.arrival}
                            onChange={(e: any) => {

                              handleDispatchAddressChange(e, "arrival", index);
                            }}
                            disabled={addressItem.include ? false : true}
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                {...field}
                                size="small"
                                id="arrival"
                                fullWidth
                                error={Boolean(errors?.shipperDepartureDate)}
                                inputRef={arrivalDateRef}
                                helperText={
                                  errors?.shipperDepartureDate &&
                                  translation(
                                    "load.form.shipperDepartureDate.message"
                                  )
                                }
                                // required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        // onClick={selectArrivalDate}
                                      >
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                  <CustomFormGrid item sm={3} xs={3} md={3} lg={3}>
                    <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                      <Controller
                        name="Departure"
                        control={control}
                        defaultValue={""}
                        // rules={{ required: true }}
                        render={({ field }) => (
                          <MobileDateTimePicker
                            {...field}
                            label="Departure Date"
                            // disablePast
                            disabled={addressItem.include ? false : true}
                            value={addressItem?.departure}
                            inputFormat="MM/dd/yyyy hh:mm a"
                            onChange={(e: any) => {
                              handleDispatchAddressChange(
                                e,
                                "departure",
                                index
                              );
                            }}
                            //    value={item.arrivalDate}
                            /*    onChange={(e: any) => {
                        handleLoadStopChange(e, "arrivalDate");
                      }} */
                            renderInput={(params: any) => (
                              <CustomTextField
                                {...params}
                                {...field}
                                size="small"
                                id="Departure"
                                fullWidth
                                error={Boolean(errors?.shipperDepartureDate)}
                                inputRef={departureDateRef}
                                helperText={
                                  errors?.shipperDepartureDate &&
                                  translation(
                                    "load.form.shipperDepartureDate.message"
                                  )
                                }
                                // required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        edge="end"
                                        // onClick={selectDepartureDate}
                                      >
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </CustomFormGrid>
                  </CustomFormGrid>
                </CustomFormGrid>
              ))}
          </CustomFormGrid>
        </CustomFormGrid>
        {showEditAddressForms === true && (
          <CustomFormGrid
            container
            justifyContent="center"
            className={classes.dispatchAddressTable}
          >
            <CustomFormGrid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{ margin: "0px", padding: "0px" }}
            >
              <CustomFormGrid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.dispatchAddressTableHead}
              >
                <Typography
                  className={classes.dispatchAddressTableHeadingsOrigin}
                >
                  {translation("common.origin.Information.label")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid
                container
                style={{ paddingTop: "10px", paddingLeft: "10px" }}
              >
                <CustomFormGrid sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="shipperAddress"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        id="address"
                        onBlur={() => CheckOriginAddressChanges()}
                        onChange={(e: any) => {
                          handleShipperAddressChange(e.target.value, "address");
                        }}
                        value={shipperAddress?.address?.address}
                        label={translation("common.address.label")}
                        error={Boolean(errors?.address)}
                        helperText={
                          errors?.address &&
                          translation("common.address.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>

                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="ShipperCity"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        id="city"
                        onBlur={() => CheckOriginAddressChanges()}
                        onChange={(e: any) => {
                          handleShipperAddressChange(e.target.value, "city");
                        }}
                        value={shipperAddress?.address?.city}
                        label={translation("common.city.label")}
                        error={Boolean(errors?.city)}
                        helperText={
                          errors?.city && translation("common.city.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>
                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="ShipperState"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        onBlur={() => CheckOriginAddressChanges()}
                        onChange={(e: any) => {
                          handleShipperAddressChange(e.target.value, "state");
                        }}
                        value={shipperAddress?.address?.state}
                        id="state"
                        label={translation("common.state.label")}
                        error={Boolean(errors?.state)}
                        helperText={
                          errors?.state && translation("common.state.message")
                        }
                        required
                      ></CustomTextField>
                    )}
                  />
                </CustomFormGrid>
                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="ShipperZip"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        id="zip"
                        onBlur={() => CheckOriginAddressChanges()}
                        onChange={(e: any) => {
                          handleShipperAddressChange(e.target.value, "zip");
                        }}
                        value={shipperAddress?.address?.zip}
                        /*  onChange={(e: any) => {
                      handleChangeZip(e.target.value);
                    }} */
                        type="number"
                        inputProps={{ min: 0 }}
                        label={translation("common.zip.label")}
                        error={Boolean(errors?.zip)}
                        helperText={
                          errors?.zip && translation("common.zip.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
            <CustomFormGrid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              style={{ margin: "0px", padding: "0px" }}
            >
              <CustomFormGrid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.dispatchAddressTableHead}
              >
                <Typography className={classes.dispatchAddressTableHeadings}>
                  {translation("common.destination.Information.label")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid
                container
                style={{ paddingTop: "8px" /* ,paddingLeft:"10px" */ }}
              >
                <CustomFormGrid sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="address"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        onBlur={() => CheckConsigneeAddressChanges()}
                        onChange={(e: any) => {
                          handleConsigneeAddressChange(
                            e.target.value,
                            "address"
                          );
                        }}
                        disabled={disabled}
                        value={consigneeAddress?.address?.address}
                        id="address"
                        label={translation("common.address.label")}
                        error={Boolean(errors?.address)}
                        helperText={
                          errors?.address &&
                          translation("common.address.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>

                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        onBlur={() => CheckConsigneeAddressChanges()}
                        onChange={(e: any) => {
                          handleConsigneeAddressChange(e.target.value, "city");
                        }}
                        disabled={disabled}
                        value={consigneeAddress?.address?.city}
                        id="city"
                        label={translation("common.city.label")}
                        error={Boolean(errors?.city)}
                        helperText={
                          errors?.city && translation("common.city.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>
                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        onBlur={() => CheckConsigneeAddressChanges()}
                        onChange={(e: any) => {
                          handleConsigneeAddressChange(e.target.value, "state");
                        }}
                        disabled={disabled}
                        value={consigneeAddress?.address?.state}
                        id="state"
                        label={translation("common.state.label")}
                        error={Boolean(errors?.state)}
                        helperText={
                          errors?.state && translation("common.state.message")
                        }
                        required
                      ></CustomTextField>
                    )}
                  />
                </CustomFormGrid>
                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="zip"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        id="zip"
                        onBlur={() => CheckConsigneeAddressChanges()}
                        onChange={(e: any) => {
                          handleConsigneeAddressChange(e.target.value, "zip");
                        }}
                        value={consigneeAddress?.address?.zip}
                        /*  onChange={(e: any) => {
                      handleChangeZip(e.target.value);
                    }} */
                        type="number"
                        inputProps={{ min: 0 }}
                        label={translation("common.zip.label")}
                        error={Boolean(errors?.zip)}
                        helperText={
                          errors?.zip && translation("common.zip.message")
                        }
                        required
                      />
                    )}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomFormGrid>
        )}

        <CustomFormGrid
          container
          justifyContent="center"
          className={classes.dispatchAddressTable}
        >
          <CustomFormGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.dispatchAddressTableHead}
          >
            <Typography className={classes.dispatchAddressTableHeadingsOrigin}>
              {translation("load.loadStop.miles")}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid
            container
            style={{ paddingTop: "8px" /* ,paddingLeft:"10px" */ }}
          >
            <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
              <CustomFormGrid
                container
                style={{ paddingTop: "5px", paddingLeft: "10px" }}
              >
                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="moveMiles"
                    control={control}
                    defaultValue={""}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        type="number"
                        value={item.moveMiles ? item.moveMiles : 0}
                        id="moveMiles"
                        label={translation("load.form.moveMiles.label")}
                        error={Boolean(errors?.weight)}
                        onChange={(e: any) =>
                          handleLoadStopChange(e.target.value, "moveMiles")
                        }
                        required
                        helperText={
                          errors?.weight &&
                          translation("load.form.moveMiles.message")
                        }
                      />
                    )}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>

            <CustomFormGrid item sm={6} xs={6} md={6} lg={6}>
              <CustomFormGrid
                container
                style={{ paddingTop: "5px", paddingLeft: "10px" }}
              >
                <CustomFormGrid item sm={11} xs={11} md={11} lg={11}>
                  <Controller
                    name="fuelMiles"
                    control={control}
                    defaultValue={""}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <CustomTextField
                        {...field}
                        size="small"
                        fullWidth
                        disabled={disabled}
                        type="number"
                        value={item.fuelMiles ? item.fuelMiles : 0}
                        id="fuelMiles"
                        label={translation("load.form.fuelMiles.label")}
                        error={Boolean(errors?.weight)}
                        onChange={(e: any) =>
                          handleLoadStopChange(e.target.value, "fuelMiles")
                        }
                        required
                        helperText={
                          errors?.weight &&
                          translation("load.form.fuelMiles.message")
                        }
                      />
                    )}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomFormGrid>
        </CustomFormGrid>
      </LocalizationProvider>
    </CustomFormGrid>
  );
};

export default StopOffs;
