import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TrailerProps } from "src/interfaces/trailer";
import { OwnerTrailerProps } from "src/interfaces/owner";
import { VehicleProps } from "src/interfaces/vehicle";

export interface TrailerState {
  trailersList: any;
  availableTrailersList: Array<TrailerProps>;
  singleTrailer: TrailerProps | null;
  linkedOwner: OwnerTrailerProps | null;
  linkedVehicle: VehicleProps | null;
}

const initialState: TrailerState = {
  trailersList: {},
  availableTrailersList: [],
  singleTrailer: null,
  linkedOwner: null,
  linkedVehicle: null,
};

export const TrailersSlice = createSlice({
  name: "Trailers",
  initialState,

  reducers: {
    setAllTrailers: (state, action: PayloadAction<Array<TrailerProps>>) => {
      state.trailersList = action.payload;
    },
    setAllAvailableTrailers: (
      state,
      action: PayloadAction<Array<TrailerProps>>
    ) => {
      state.availableTrailersList = action.payload;
    },
    setSingleTrailer: (state, action: PayloadAction<TrailerProps>) => {
      state.singleTrailer = action.payload;
      state.linkedOwner =
        action.payload.ownerTrailerLink && action.payload.ownerTrailerLink[0];
      state.linkedVehicle = action.payload.vehicle;
    },
    resetTrailer: (state) => {
      state.singleTrailer = null;
    },

    addLinkedVehicle: (state, action: PayloadAction<VehicleProps>) => {
      state.linkedVehicle = action.payload;
    },
    removeLinkedVehicle: (state) => {
      state.linkedVehicle = null;
    },
    resetTrailerState: (state) => {
      state.singleTrailer = null;
      state.trailersList = [];
      state.linkedOwner = null;
    },
    addLinkedOwner: (state, action: PayloadAction<OwnerTrailerProps>) => {
      state.linkedOwner = action.payload;
    },
    removeLinkedOwner: (state) => {
      state.linkedOwner = null;
      state.linkedVehicle = null;
    },
  },
});
export const {
  setAllTrailers,
  setAllAvailableTrailers,
  setSingleTrailer,
  resetTrailer,
  resetTrailerState,
  addLinkedOwner,
  removeLinkedOwner,
  removeLinkedVehicle,
  addLinkedVehicle,
} = TrailersSlice.actions;
export const selectTrailers = (state: RootState): any =>
  state.trailer.trailersList;
export const selectAvailableTrailers = (
  state: RootState
): Array<TrailerProps> => state.trailer.availableTrailersList;
export const selectTrailer = (state: RootState): TrailerProps | null =>
  state.trailer.singleTrailer;
export const selectLinkedVehicle = (state: RootState): VehicleProps | null =>
  state.trailer.linkedVehicle;
export default TrailersSlice.reducer;
export const selectLinkedOwner = (state: RootState): OwnerTrailerProps | null =>
  state.trailer.linkedOwner;
