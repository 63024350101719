import React from "react";
import { Box, Typography, InputLabel } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailRegEx } from "src/utils/constants";
import CustomSwitch from "../customElements/customSwitch";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
import { Autocomplete } from "@mui/material";
import { useAppDispatch } from "src/hooks/reduxHooks";
import {
  getZipData,
  getAddressData,
  getAddressDataDetail,
} from "src/redux/actions/loadManagement";
interface BillingAddressProps {
  loading: boolean;
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
  disabled: boolean;
  setValue: any;
}
const BillingAddress = ({
  control,
  errors,
  watch,
  disabled,
  setValue,
}: BillingAddressProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [translation] = useTranslation("translation");
  const [addressOption, setAddressOption] = React.useState<any | null>([]);
  let timer: any;
  const handleChangeAddress = async (event: any, newValue: any) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getAddressData(event)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setAddressOption(res?.data);
        }
      });
    }
  };
  const handleChangeZip = async (event: any) => {
    setValue("billingZip", event);
    if (event != null && Object.keys(event).length != 0 && event?.length == 5) {
      await dispatch(getZipData(event)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          setValue("billingState", res?.data?.state);
          setValue("billingCity", res?.data?.city);
        }
      });
    }
  };

  const handleChangeAddressDetail = async (newValue: any) => {
    if (newValue && Object.keys(newValue).length != 0) {
      await dispatch(getAddressDataDetail(newValue)).then((res: any) => {

        if (res?.data != null && Object.keys(res?.data).length != 0) {
          delete errors["addressBilling"];
          delete errors["billingCity"];
          delete errors["billingZip"];
          delete errors["billingState"];
          setValue && setValue("billingState", res?.data?.state ? res?.data?.state : "");
          setValue && setValue("billingCity", res?.data?.city ? res?.data?.city : "");
          setValue && setValue("billingZip", res?.data?.zip ? res?.data?.zip : null);
          setValue && setValue("addressBilling", res?.data?.address ? res?.data?.address : null);


          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          // setAddressOption(res?.data);
        }
      });
    }
  };
  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Controller
            name="isSameBillingAddress"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("common.sameAsAbove.label")}
                </InputLabel>
                <CustomSwitch
                  disabled={disabled}
                  checked={
                    watch("isSameBillingAddress")
                      ? watch("isSameBillingAddress")
                      : false
                  }
                  {...field}
                  id="isSameBillingAddress "
                />
              </>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6}
          xs={12}
          md={6}
          lg={6}>
          <Controller
            name="billingName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                disabled={disabled}
                size="small"
                fullWidth
                id="billingName"
                label={translation("common.billing_name.label")}
                error={Boolean(errors?.billingName)}
                helperText={
                  errors?.billingName &&
                  translation("common.billing_name.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={6}
          xs={12}
          md={6}
          lg={6}>
          <Controller
            name="billingEmail"
            control={control}
            defaultValue={""}
            rules={{ pattern: emailRegEx, required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                disabled={disabled}
                size="small"
                fullWidth
                id="billingEmail"
                label={translation("common.email.label")}
                error={Boolean(errors?.billingEmail)}
                helperText={
                  errors?.billingEmail && translation("common.billingEmail.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="billingPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              //  required: true,
              validate: {
                isValid: () =>
                  watch("billingPhoneNumberValue")
                    ? phone(watch("billingPhoneNumberValue"), {
                      country: watch("billingPhoneNumberCountry"),
                    })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                disabled={disabled}
                label={translation("common.phone.label")}
                placeholder={translation("common.phone.label")}
                value={watch("billingPhoneNumber")}
                error={Boolean(errors["billingPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "billingPhoneNumber"
                  );
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["billingPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="billingAltPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("billingAltPhoneNumberValue")
                    ? phone(watch("billingAltPhoneNumberValue"), {
                      country: watch("billingAltPhoneNumberCountry"),
                    })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                disabled={disabled}
                label={translation("common.altPhoneNumber.label")}
                placeholder={translation("common.altPhoneNumber.label")}
                value={watch("billingAltPhoneNumber")}
                error={Boolean(errors["billingAltPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "billingAltPhoneNumber"
                  );
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["billingAltPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="billingFaxPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("billingFaxPhoneNumberValue")
                    ? phone(watch("billingFaxPhoneNumberValue"), {
                      country: watch("billingFaxPhoneNumberCountry"),
                    })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                disabled={disabled}
                label={translation("common.faxPhoneNumber.label")}
                placeholder={translation("common.faxPhoneNumber.label")}
                value={watch("billingFaxPhoneNumber")}
                error={Boolean(errors["billingFaxPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "billingFaxPhoneNumber"
                  );
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["billingFaxPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid container>

          <Box flexGrow={1}>
            <Typography className={classes.sectionTitle}>
              {translation("common.address.label")}
            </Typography>
          </Box>

          <CustomFormGrid container>
            <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
              <Controller
                name="address"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="combo-box-demo"
                    fullWidth
                    options={addressOption ? addressOption : "No Data"}
                    value={addressOption?.description}
                    getOptionLabel={(option) =>
                      option?.description ? option?.description : "No Data Found"
                    }
                    onChange={(event: any, newValue: any | null) => {
                      handleChangeAddressDetail(newValue?.place_id);
                      // field.onChange(newValue);
                      // handleSettlementCodeChange(event, newValue)}
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        id="address"
                        size="small"
                        required
                        onChange={(event: any, newValue: any | null) => {
                          clearTimeout(timer);
                          field.onChange(newValue);
                          timer = setTimeout(
                            () => handleChangeAddress(event.target.value, newValue),
                            500
                          );
                        }}
                        // error={Boolean(errors?.accountType)}
                        label={translation("Search Address")}
                        error={false}
                      /*   helperText={
                          errors === true &&
                          translation("common.billingAddress.message")
                        } */
                      />
                    )}
                  />
                )}
              />{" "}
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name="addressBilling"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    disabled={disabled}
                    size="small"
                    fullWidth
                    id="addressBilling"
                    label={translation("common.address.label")}
                    error={Boolean(errors?.addressBilling)}
                    helperText={
                      errors?.addressBilling &&
                      translation("common.billingAddress.message")
                    }

                    required
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name="billingCity"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    disabled={disabled}
                    size="small"
                    fullWidth
                    id="billingCity"
                    label={translation("common.city.label")}
                    error={Boolean(errors?.billingCity)}
                    helperText={
                      errors?.billingCity && translation("common.billingCity.message")
                    }
                    required
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name="billingState"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    disabled={disabled}
                    size="small"
                    fullWidth
                    id="billingState"
                    label={translation("common.state.label")}
                    error={Boolean(errors?.billingState)}
                    helperText={
                      errors?.billingState &&
                      translation("common.billingState.message")
                    }
                    required
                  ></CustomTextField>
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={6} lg={6}>
              <Controller
                name="billingZip"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    disabled={disabled}
                    size="small"
                    fullWidth
                    id="billingZip"
                    onChange={(e: any) => {
                      handleChangeZip(e.target.value);
                    }}
                    type="number"
                    inputProps={{ min: 0 }}
                    label={translation("common.zip.label")}
                    error={Boolean(errors?.billingZip)}
                    helperText={
                      errors?.billingZip && translation("common.billingZip.message")
                    }
                    required
                  />
                )}
              />
            </CustomFormGrid>


          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};
export default BillingAddress;
