import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { SettlementCodesProps } from "src/interfaces/settlementCodes";


export interface SettlementCodesState {
    SettlementCodesList: any;
  singleSettlementCode: SettlementCodesProps | null;
}

const initialState: SettlementCodesState = {
    SettlementCodesList: {},
    singleSettlementCode: null,
};

export const SettlementCodesSlice = createSlice({
  name: "settlementCodes",
  initialState,

  reducers: {
    setAllSettlementCodes: (
      state,
      action: PayloadAction<Array<SettlementCodesProps>>
    ) => {
      state.SettlementCodesList = action.payload;
    },
    setSingleSettlementCode: (state, action: PayloadAction<SettlementCodesProps>) => {
      state.singleSettlementCode = action.payload;
    },
    resetSettlementCode: (state) => {
      state.singleSettlementCode = null;
    },
    resetSettlementCodeState: (state) => {
      state.singleSettlementCode = null;
      state.SettlementCodesList = [];
    },
  },
});
export const {
    setAllSettlementCodes,
    setSingleSettlementCode,
    resetSettlementCode,
    resetSettlementCodeState,
} = SettlementCodesSlice.actions;
export const selectSettlementCodes = (state: RootState):any =>
  state.settlementCode.SettlementCodesList;
export const selectSettlementCode = (state: RootState): SettlementCodesProps | null =>
  state.settlementCode.singleSettlementCode;
export default SettlementCodesSlice.reducer;
