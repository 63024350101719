import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CustomGrid from "src/components/customElements/customFormGrid";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

interface FromTitleProps {
  title: any;
}

const formHeader = ({ title }: FromTitleProps): JSX.Element => {
  return (
    <Box>
      <CustomGrid>
        <Paper sx={{ bgcolor: "#000000" }}>
          <Typography
            style={{   color: "#fff",
            fontSize: "16px",
          fontWeight: 600,}}
            variant="h6"
            component="h4"
            sx={{ p: 1, pl: 1 }}
          >
            {title}
          </Typography>
        </Paper>
      </CustomGrid>
    </Box>
  );
};

export default formHeader;
