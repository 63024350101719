import React from "react";
import { Navigate } from "react-router-dom";
import Owners from "../pages/owners";
import OwnersForm from "../pages/owners/ownerForm";
import OwnersReadForm from "../pages/owners/ownerReadForm";
import Documents from "../pages/documents";
import { UserPermissions } from "src/interfaces/user";
import OwnerSearch from "../containers/owner/ownerSearch";

import { admin, featureOwners, create, update, find } from "src/utils/enums";
export const ownerRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const ownerPermission =
    allUserPermissions && allUserPermissions[featureOwners];
  const createPermission =
    role === admin ? 0 : ownerPermission?.findIndex((item) => item === create);
  const findPermission =
    role === admin ? 0 : ownerPermission?.findIndex((item) => item === find);
  const updatePermission =
    role === admin ? 0 : ownerPermission?.findIndex((item) => item === update);

  return [
    {
      path: "/drivers/owners",
      exact: true,
      element:
        role === admin || ownerPermission?.length > 0 ? (
          <Owners />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/drivers/owners/create",
      exact: true,
      element: createPermission > -1 ? <OwnersForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/owners/:ownerId",
      exact: true,
      element: updatePermission > -1 ? <OwnersForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/owners/documents/:ownerId",
      exact: true,
      element: updatePermission > -1 ? <Documents /> : <Navigate to="/404" />,
    },
    // {
    //   path: "/drivers/owners/details/:ownerId",
    //   exact: true,
    //   element: findPermission > -1 ? <OwnersForm /> : <Navigate to="/404" />,
    // },
    {
      path: "/drivers/owners/details/:ownerId",
      exact: true,
      element:
        findPermission > -1 ? <OwnersReadForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/owners/search",
      exact: true,
      element: findPermission > -1 ? <OwnerSearch /> : <Navigate to="/404" />,
    },
  ];
};
