import React, { useState } from "react";
import Button from "@mui/material/Button";
import {TextField, Card, CardContent} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useStyles } from "./styles";
import CustomTextField from "src/components/customElements/customTextField";
import logo from "src/assets/img/tms_logo.png";

interface ResetPassCompProps {
  loading: boolean;
 /*  onSubmit: (data: ResetPasswordProps) => any; */
  onSubmit: (data: any) => any;
}

const ResetPassword = ({
  loading,
  onSubmit,
}: ResetPassCompProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();


  return (
    <div className="auth-container">
      <div className="auth-align-middle auth-main">
        <div className="auth-box">
          <div className="top">
            <img src={logo} alt="" />
          </div>
          <Card sx={{ borderRadius: "5px", m: 0 }}>
            <CardContent>
              <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                Please provide your new password
              </Typography>

              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="password"
                      size="small"
                      label={"Password"}
                      type={"password"}
                      required
                    />
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="confirmPassword"
                      size="small"
                      label={"Confirm Password"}
                      type={"password"}
                      required
                    />
                  )}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  id="login"
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>

              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
    // <Grid container component={Paper} className={classes.root}>
    //   <Grid item xs={false} sm={4} md={7} className={classes.image} />
    //   <Grid item xs={12} sm={8} md={5} component="main">
    //     <div className={classes.paper}>
    //       <Avatar className={classes.avatar}>
    //         <LockOutlinedIcon />
    //       </Avatar>
    //       <Typography component="h1" variant="h1">
    //         {translation("resetPassword.title")}
    //       </Typography>
    //       <form
    //         className={classes.form}
    //         noValidate
    //         onSubmit={handleSubmit(onSubmit)}
    //       >
    //         <Controller
    //           name="password"
    //           control={control}
    //           defaultValue={""}
    //           rules={{ required: true }}
    //           render={({ field }) => (
    //             <TextField
    //               {...field}
    //               variant="outlined"
    //               margin="normal"
    //               fullWidth
    //               size="small"
    //               id="password"
    //               type={showPassword ? "text" : "password"}
    //               label={translation("resetPassword.password.label")}
    //               error={Boolean(errors?.password)}
    //               helperText={
    //                 errors?.password &&
    //                 translation("resetPassword.password.message")
    //               }
    //               required
    //               InputProps={{
    //                 endAdornment: (
    //                   <InputAdornment position="end">
    //                     <IconButton
    //                       onClick={handleClickShowPassword}
    //                       edge="end"
    //                     >
    //                       {showPassword ? <VisibilityOff /> : <Visibility />}
    //                     </IconButton>
    //                   </InputAdornment>
    //                 ),
    //               }}
    //             />
    //           )}
    //         />
    //         <Controller
    //           name="confirmPassword"
    //           control={control}
    //           defaultValue={""}
    //           rules={{
    //             required: true,
    //             validate: {
    //               passwordMatch: (value) =>
    //                 value === getValues().password || "Password Must Match",
    //             },
    //           }}
    //           render={({ field }) => (
    //             <TextField
    //               {...field}
    //               variant="outlined"
    //               margin="normal"
    //               fullWidth
    //               size="small"
    //               type={showConfirmPassword ? "text" : "password"}
    //               id="confirmPassword"
    //               label={translation("resetPassword.confirmPassword.label")}
    //               error={Boolean(errors?.confirmPassword)}
    //               helperText={
    //                 errors?.confirmPassword &&
    //                 translation("resetPassword.confirmPassword.message")
    //               }
    //               required
    //               InputProps={{
    //                 endAdornment: (
    //                   <InputAdornment position="end">
    //                     <IconButton
    //                       onClick={handleClickShowConfirmPassword}
    //                       edge="end"
    //                     >
    //                       {showConfirmPassword ? (
    //                         <VisibilityOff />
    //                       ) : (
    //                         <Visibility />
    //                       )}
    //                     </IconButton>
    //                   </InputAdornment>
    //                 ),
    //               }}
    //             />
    //           )}
    //         />

    //         <Button
    //           type="submit"
    //           fullWidth
    //           variant="contained"
    //           color="secondary"
    //           disabled={loading}
    //           id="resetPassword"
    //         >
    //           {loading ? (
    //             <CircularProgress
    //               style={{ color: "#fff", height: "20px", width: "20px" }}
    //             />
    //           ) : (
    //             translation("resetPassword.submit")
    //           )}
    //         </Button>
    //       </form>
    //     </div>
    //   </Grid>
    // </Grid>
  );
};
export default ResetPassword;
