import React from "react";
import {

  Typography,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CustomGrid from "src/components/customElements/customFormGrid";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";



interface DialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  ComponentToRender?: React.ReactNode;
}

export default function AddCommonDialouge({
  open,
  handleClose,
  title,
  ComponentToRender,
}: DialogProps): JSX.Element {
  return (
      <div>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
       <DialogTitle id="alert-dialog-title">
          <CustomGrid
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ textTransform: "uppercase" }}>{title}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomGrid>
        </DialogTitle>
        <DialogContent>{ComponentToRender}</DialogContent>
              </Dialog>
              </div>
              );
            }
