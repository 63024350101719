import React , { useState, useEffect }  from "react";
import { Grid} from "@mui/material";
import { getDashboardData } from "src/redux/actions/settlement";
import { useAppDispatch } from "src/hooks/reduxHooks";
import Dashboard from "src/components/billing/dashboard";

const BillingDashboard = () => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);


  const getData = async () => {
    setLoading(true)
    await dispatch(getDashboardData());
    setLoading(false)
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Grid container component="main" style={{paddingTop:"20px"}}>
      <Dashboard loading={loading} />

    </Grid>
  );
};

export default BillingDashboard;
