import React, { useEffect } from "react";
import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Typography } from "@mui/material";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface NotificationDropdownOpts {
    isOpened: boolean;
    anchorEl: null | HTMLElement
    onClose(): any;
}

const NotificationDropdown = (props: NotificationDropdownOpts) => {

    const handleClose = () => {
        props.onClose();
    };

    return (<React.Fragment>
        <Menu
            anchorEl={props.anchorEl}
            id="notification-menu"
            open={props.isOpened}
            onClose={handleClose}
            onClick={handleClose}
            sx={{
                "& .MuiMenu-paper": {
                    left: "auto !important",
                    right: "11.7rem !important",
                    top: "42px !important",
                },
                "& .MuiList-padding": {
                    p: "0px !important",
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,

                    '& .MuiAvatar-root': {
                        width: 64,
                        height: 64,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 30,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
        >

            <Paper sx={{ width: 320, maxWidth: '100%' }}>
                <MenuList sx={{ height: "100% !important" }}>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <SentimentSatisfiedAltIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={"Welcome to CLOUD TMS."}></ListItemText>
                        {/* <Typography variant="body2" color="text.secondary">
                            ⌘X
                        </Typography> */}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <PriorityHighIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Profile Incomplete"}
                            secondary={
                                <React.Fragment>
                                    <Typography noWrap={true} gutterBottom>
                                        Please complete your profile to explore all features of CLOUD TMS.
                                    </Typography>
                                </React.Fragment>
                            }></ListItemText>
                    </MenuItem>
                </MenuList>
            </Paper>

        </Menu>
    </React.Fragment>);
}

export default NotificationDropdown;