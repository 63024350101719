import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CreateOwnerContainer from "src/containers/owner/form";
const Owners = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Owners" description="Porto Owners" />
      <CreateOwnerContainer />
    </Grid>
  );
};

export default Owners;
