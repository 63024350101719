import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import LoadManagementContainer from "src/containers/loadManagement";
const LoadManagement = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Loads" description="Porto Load Management" />
      <LoadManagementContainer />
    </Grid>
  );
};

export default LoadManagement;
