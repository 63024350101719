import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { OwnerProps, OwnerTrailerProps } from "src/interfaces/owner";
import { DriverOwnerProps } from "src/interfaces/driver";
import { OwnerVehicleProps } from "src/interfaces/owner";
export interface OwnerState {
  ownersList: any;
  singleOwner: OwnerProps | null;
  availableOwnerList: Array<OwnerProps>;
  linkedDrivers: Array<DriverOwnerProps>;
  linkedTrailer: Array<OwnerTrailerProps>;
  linkedVehicle: any;
}

const initialState: OwnerState = {
  ownersList: {},
  singleOwner: null,
  availableOwnerList: [],
  linkedDrivers: [],
  linkedTrailer: [],
  linkedVehicle: [],
};

export const ownersSlice = createSlice({
  name: "owners",
  initialState,

  reducers: {
    setAllOwners: (state, action: PayloadAction<Array<OwnerProps>>) => {
      state.ownersList = action.payload;
    },
    setAllAvailableOwners: (
      state,
      action: PayloadAction<Array<OwnerProps>>
    ) => {
      state.availableOwnerList = action.payload;
    },
    setSingleOwner: (state, action: PayloadAction<OwnerProps>) => {
      state.singleOwner = action.payload;
      state.linkedDrivers = action.payload.ownerDriverLink ;
      state.linkedTrailer = action.payload.ownerTrailerLink;
      state.linkedVehicle = action.payload.ownerVehicleLink;
    },
    resetOwner: (state) => {
      state.singleOwner = null;
    },
    resetOwnerState: (state) => {
      state.singleOwner = null;
      state.ownersList = [];
    },
    updateOwnerList: (state, action: PayloadAction<OwnerProps>) => {
      state.ownersList = [...state.ownersList, action.payload];
    },

    addLinkedDriver: (state, action: PayloadAction<DriverOwnerProps>) => {
      state.linkedDrivers = [...state.linkedDrivers, action.payload];
    },

    removeLinkedDriver: (state, action: PayloadAction<number>) => {

      state.linkedDrivers = state.linkedDrivers.filter(
        (item: any) =>
          item !== null &&
          item.driver !== null &&
          typeof item.driver === "object" &&
          item.driver.id !== action.payload
      );
    },
    addLinkedVehicle: (state, action: PayloadAction<OwnerVehicleProps>) => {
      state.linkedVehicle = [...state.linkedVehicle, action.payload];
    },
    removeLinkedVehicle: (state, action: PayloadAction<number>) => {
      state.linkedVehicle = state.linkedVehicle.filter(
        (item: any) =>
          item !== null &&
          item.vehicle !== null &&
          typeof item.vehicle === "object" &&
          item.vehicle.id !== action.payload
      );
    },
    addLinkedTrailer: (state, action: PayloadAction<OwnerTrailerProps>) => {
      state.linkedTrailer = [...state.linkedTrailer, action.payload];
    },

    removeLinkedTrailer: (state, action: PayloadAction<number>) => {
      console.log({
        state,
        trailerList: state.linkedTrailer,
        trailerLists: [...state.linkedTrailer],
        payload: action.payload,
      });

      state.linkedTrailer = state.linkedTrailer.filter(
        (item) =>
          item !== null &&
          item.trailer !== null &&
          typeof item.trailer === "object" &&
          item.trailer.id !== action.payload
      );
    },
  },
});

export const {
  setAllOwners,
  setSingleOwner,
  resetOwner,
  resetOwnerState,
  updateOwnerList,
  setAllAvailableOwners,
  addLinkedDriver,
  removeLinkedDriver,
  addLinkedTrailer,
  removeLinkedTrailer,
  removeLinkedVehicle,
  addLinkedVehicle,
} = ownersSlice.actions;
export const selectOwners = (state: RootState): any => state.owners.ownersList;
export const selectOwner = (state: RootState): OwnerProps | null =>
  state.owners.singleOwner;
export const selectAllAvailableOwners = (state: RootState): Array<OwnerProps> =>
  state.owners.availableOwnerList;
export const selectLinkedDrivers = (state: RootState): any =>
  state.owners.linkedDrivers;
export const selectLinkedTrailers = (
  state: RootState
): Array<OwnerTrailerProps> => state.owners.linkedTrailer;
export const selectLinkedVehicle = (state: RootState): any =>
  state.owners.linkedVehicle;
export default ownersSlice.reducer;
