import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DocumentTypeProps } from "src/interfaces/documentType";

export interface DriverState {
  documentTypesList: any;
  singleDocumentType: DocumentTypeProps | null;
}

const initialState: DriverState = {
  documentTypesList: {},
  singleDocumentType: null,
};

export const documentTypeSlice = createSlice({
  name: "documentType",
  initialState,

  reducers: {
    setAllDocumentTypes: (
      state,
      action: PayloadAction<Array<DocumentTypeProps>>
    ) => {
      state.documentTypesList = action.payload;
    },
    setSingleDocumentType: (
      state,
      action: PayloadAction<DocumentTypeProps>
    ) => {
      state.singleDocumentType = action.payload;
    },
    resetDocumentType: (state) => {
      state.singleDocumentType = null;
    },
    resetDocumentTypeState: (state) => {
      state.singleDocumentType = null;
      state.documentTypesList = [];
    },
  },
});
export const {
  setAllDocumentTypes,
  setSingleDocumentType,
  resetDocumentType,
  resetDocumentTypeState,
} = documentTypeSlice.actions;
export const selectDocumentTypes = (state: RootState): any =>
  state.documentType.documentTypesList;
export const selectDocumentType = (
  state: RootState
): DocumentTypeProps | null => state.documentType.singleDocumentType;
export default documentTypeSlice.reducer;
