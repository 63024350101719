import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SettlementProps } from "src/interfaces/settlement";

export interface SettlementState {
  SettlementList: any;
  singleSettlement: SettlementProps | null;
  ownerList: any;
  terminalList: any;
  driverList: any;
  singleSettlementInfo: any;
  allAccountsWithId: any;
  allAccounts: any;
  printQueueList : any
  dashboard: any;
  completedSettlements: any;
  mainDashboard: any;
}

const initialState: SettlementState = {
  SettlementList: {},
  singleSettlement: null,
  ownerList: [],
  terminalList: [],
  driverList: [],
  singleSettlementInfo: {},
  allAccountsWithId: [],
  allAccounts: [],
  printQueueList: {},
  dashboard: {},
  completedSettlements: {},
  mainDashboard: [],
};

export const SettlementSlice = createSlice({
  name: "settlement",
  initialState,

  reducers: {
    setDashboard: (
      state,
      action: PayloadAction<Array<SettlementProps>>
    ) => {
      state.dashboard = action.payload;
    },
    setMainDashboard:  (
      state,
      action: PayloadAction<Array<any>>
    ) => {
      state.mainDashboard = action.payload;
    },
    setAllSettlement: (
      state,
      action: PayloadAction<Array<SettlementProps>>
    ) => {
      state.SettlementList = action.payload;
    },
    setSingleSettlement: (state, action: PayloadAction<SettlementProps>) => {
      state.singleSettlement = action.payload;
    },
    setSingleSettlementGeneralInfo: (
      state,
      action: PayloadAction<SettlementProps>
    ) => {
      state.singleSettlementInfo = action.payload;
    },

    resetSettlement: (state) => {
      state.singleSettlement = null;
    },
    resetSettlementState: (state) => {
      state.singleSettlement = null;
      state.SettlementList = [];
    },
    setAllOwners: (state, action: PayloadAction<any>) => {
      state.ownerList = action.payload;
    },
    setAllTerminals: (state, action: PayloadAction<any>) => {
      state.terminalList = action.payload;
    },
    setAllDrivers: (state, action: PayloadAction<any>) => {
      state.driverList = action.payload;
    },
    setAllAccountsWithId: (state, action: PayloadAction<any>) => {
      state.allAccountsWithId = action.payload;
    },
    setAllAccounts: (state, action: PayloadAction<any>) => {
      state.allAccounts = action.payload;
    },
    setPrintQueueList: (state, action: PayloadAction<any>) => {
      state.printQueueList = action.payload;
    },
    setCompletedSettlementList : (state, action: PayloadAction<any>) => {
      state.completedSettlements = action.payload;
    },
  },
});
export const {
  setDashboard,
  setAllSettlement,
  setPrintQueueList,
  setMainDashboard,
  setCompletedSettlementList,
  setSingleSettlement,
  resetSettlement,
  resetSettlementState,
  setAllOwners,
  setAllTerminals,
  setAllDrivers,
  setAllAccountsWithId,
  setAllAccounts,
  setSingleSettlementGeneralInfo,
} = SettlementSlice.actions;
export const selectSingleSettlementInfo = (state: RootState): any =>
  state.settlement.singleSettlementInfo;
export const selectSettlements = (state: RootState): any =>
  state.settlement.SettlementList;
export const selectSettlement = (state: RootState): SettlementProps | null =>
  state.settlement.singleSettlement;
export const selectOwner = (state: RootState): any =>
  state.settlement.ownerList;
export const selectTerminal = (state: RootState): any =>
  state.settlement.terminalList;
export const selectDriver = (state: RootState): any =>
  state.settlement.driverList;
export const selectAllAccountsWithId = (state: RootState): any =>
  state.settlement.allAccountsWithId;
export const selectAllAccount = (state: RootState): any =>
  state.settlement.allAccounts;
export const selectDashboard = (state: RootState): any =>
  state.settlement.dashboard;
  export const selectMainDashboard = (state: RootState): any =>
  state.settlement.mainDashboard;
export const selectPrintQueueList = (state: RootState): any =>
  state.settlement.printQueueList;
export const selectCompletedSettlementList = (state: RootState): any =>
  state.settlement.completedSettlements;

export default SettlementSlice.reducer;
