import React from "react";
import { Card, CardContent, Typography, Box, Divider } from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useTranslation } from "react-i18next";
import { useStyles } from "src/styles/formStyles";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectDashboard } from "src/redux/reducers/settlement";

const PendingSettlements = () => {
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const dashboardData = useAppSelector(selectDashboard);

  return (
    <CustomFormGrid item xs={12} lg={12}>
      <Card
        // variant="outlined"
        sx={{
          paddingBottom: "50px",
          margin: "0px",
        }}
      >
        <CardContent
          sx={{
            paddingBottom: "16px !important",
          }}
        >
          <Box
            sx={{
              display: {
                sm: "flex",
                xs: "block",
              },
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0",
                  fontWeight: 600,
                }}
                gutterBottom
              >
                {translation("billing.dashboard.pendingSettlement")}
              </Typography>
            </Box>
          </Box>

          {/* table container */}
          <CustomFormGrid
            xs={12}
            lg={12}
            style={{
              paddingLeft: "20px",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          >
            <CustomFormGrid container>
              <CustomFormGrid xs={3} lg={2}>
                <Typography className={classes.billingDashboardHeadings}>
                  {translation("billing.dashboard.pendingSettlement")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={2} lg={1}>
                <Typography className={classes.billingDashboardHeadingsNumber}>
                  #
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardHeadings}>
                  {translation("billing.dashboard.positiveSubTotals")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardHeadings}>
                  {translation("billing.dashboard.negativeSubTotals")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardHeadings}>
                  {translation("billing.dashboard.pendingCarrier")}
                </Typography>
              </CustomFormGrid>
            </CustomFormGrid>
            <Divider />

            <CustomFormGrid container>
              <CustomFormGrid xs={3} lg={2}>
                <Typography className={classes.billingTableText}>
                  {translation("billing.dashboard.terminal")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid
                xs={2}
                lg={1}
                style={{
                  textAlign: "center",
                }}
              >
                <Typography className={classes.billingTableText}>
                  {dashboardData?.terminalCount
                    ? dashboardData?.terminalCount
                    : "0"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.positiveSetTotalTerminal
                    ? "$" + dashboardData?.positiveSetTotalTerminal
                    : "$0.00"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.negativeSetTotalTerminal
                    ? "$" + dashboardData?.negativeSetTotalTerminal
                    : "$0.00"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>-</Typography>
              </CustomFormGrid>
            </CustomFormGrid>
            <Divider />

            <CustomFormGrid container>
              <CustomFormGrid xs={3} lg={2}>
                <Typography className={classes.billingTableText}>
                  {translation("billing.dashboard.companyDriver")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={2} lg={1}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.companyDriverCount
                    ? dashboardData?.companyDriverCount
                    : "0"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.positiveSetTotalDriver
                    ? "$" + dashboardData?.positiveSetTotalDriver
                    : "$0.00"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.negativeSetTotalDriver
                    ? "$" + dashboardData?.negativeSetTotalDriver
                    : "$0.00"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>-</Typography>
              </CustomFormGrid>
            </CustomFormGrid>

            <Divider />

            <CustomFormGrid container>
              <CustomFormGrid xs={3} lg={2}>
                <Typography className={classes.billingTableText}>
                  {translation("billing.dashboard.ownerOperator")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={2} lg={1}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.ownerOperatorCount
                    ? dashboardData?.ownerOperatorCount
                    : "0"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.positiveSetTotalOwner
                    ? "$" + dashboardData?.positiveSetTotalOwner
                    : "$0.00"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  {dashboardData?.negativeSetTotalOwner
                    ? "$" + dashboardData?.negativeSetTotalOwner
                    : "$0.00"}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>-</Typography>
              </CustomFormGrid>
            </CustomFormGrid>
            <Divider />

            <CustomFormGrid container>
              <CustomFormGrid xs={3} lg={2}>
                <Typography className={classes.billingTableText}>
                  {translation("billing.dashboard.vendors")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={2} lg={1}>
                <Typography className={classes.billingTableText}>-</Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  $0.00
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>
                  $(0.00)
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingTableText}>-</Typography>
              </CustomFormGrid>
            </CustomFormGrid>
            <Divider />

            <CustomFormGrid container>
              <CustomFormGrid xs={3} lg={2}>
                <Typography className={classes.billingDashboardHeadings}>
                  {translation("billing.dashboard.total")}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={2} lg={1}>
                <Typography className={classes.billingDashboardHeadingsNumber}>
                  {dashboardData?.companyDriverCount +
                    dashboardData?.ownerOperatorCount +
                    dashboardData?.terminalCount}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardHeadings}>
                  {"$" +
                    (dashboardData?.positiveSetTotalDriver +
                      dashboardData?.positiveSetTotalOwner +
                      dashboardData?.positiveSetTotalTerminal)}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardHeadings}>
                  {"$" +
                    (dashboardData?.negativeSetTotalDriver +
                      dashboardData?.negativeSetTotalOwner +
                      dashboardData?.negativeSetTotalTerminal)}
                </Typography>
              </CustomFormGrid>
              <CustomFormGrid xs={3} lg={3}>
                <Typography className={classes.billingDashboardHeadings}>
                  -
                </Typography>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomFormGrid>
        </CardContent>
      </Card>
    </CustomFormGrid>
  );
};

export default PendingSettlements;
