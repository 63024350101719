import React from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailRegEx } from "src/utils/constants";
import { useStyles } from "src/styles/formStyles";
import { phone } from "phone";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "src/components/common/phoneInput";
import { validatePhoneNumber, formattedPhoneNumber } from "src/utils/common";
interface ContactInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled: boolean;
  watch: any;
  setValue: any;
}
const ContactInfo = ({
  control,
  errors,
  disabled,
  setValue,
  watch,
}: ContactInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const onChangePhoneInput = (value: string, country: string, key: string) => {
    setValue(key, formattedPhoneNumber(value, country));
    setValue(`${key}Value`, value);
    setValue(`${key}Country`, country);
    delete errors[key];
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="email"
            control={control}
            defaultValue={""}
            rules={{ required: true, pattern: emailRegEx }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="email"
                label={translation("common.email.label")}
                disabled={disabled}
                error={Boolean(errors?.email)}
                helperText={
                  errors?.email && translation("common.email.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="cellPhoneNumber"
            control={control}
            defaultValue={""}
            rules={{
              //  required: true,
              validate: {
                isValid: () =>
                  watch("cellPhoneNumberValue")
                    ? phone(watch("cellPhoneNumberValue"), {
                        country: watch("cellPhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.phone.label")}
                placeholder={translation("common.phone.label")}
                value={watch("cellPhoneNumber")}
                disabled={disabled}
                error={Boolean(errors["cellPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "cellPhoneNumber"
                  );
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["cellPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="homePhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("homePhoneNumberValue")
                    ? phone(watch("homePhoneNumberValue"), {
                        country: watch("homePhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.altPhoneNumber.label")}
                placeholder={translation("common.altPhoneNumber.label")}
                value={watch("homePhoneNumber")}
                disabled={disabled}
                error={Boolean(errors["homePhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "homePhoneNumber"
                  );
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["homePhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="faxPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("faxPhoneNumberValue")
                    ? phone(watch("faxPhoneNumberValue"), {
                        country: watch("faxPhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.faxPhoneNumber.label")}
                placeholder={translation("common.faxPhoneNumber.label")}
                value={watch("faxPhoneNumber")}
                disabled={disabled}
                error={Boolean(errors["faxPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(value, data.countryCode, "faxPhoneNumber");
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["faxPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={6}
          xs={12}
          md={6}
          lg={6}
          className={classes.phoneField}
        >
          <Controller
            name="pagerPhoneNumber"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isValid: () =>
                  watch("pagerPhoneNumberValue")
                    ? phone(watch("pagerPhoneNumberValue"), {
                        country: watch("pagerPhoneNumberCountry"),
                      })?.phoneNumber !== null || "Enter Valid Phone Number"
                    : true,
              },
            }}
            render={() => (
              <PhoneInput
                label={translation("common.pagerPhoneNumber.label")}
                placeholder={translation("common.pagerPhoneNumber.label")}
                value={watch("pagerPhoneNumber")}
                disabled={disabled}
                error={Boolean(errors["pagerPhoneNumber"])}
                onChange={(value: string, data: any) => {
                  onChangePhoneInput(
                    value,
                    data.countryCode,
                    "pagerPhoneNumber"
                  );
                }}
                country="us"
                isValid={(value: any, country: any) => {
                  return errors["pagerPhoneNumber"]
                    ? validatePhoneNumber(value, country.iso2)
                    : true;
                }}
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default ContactInfo;
