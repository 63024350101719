import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { DatePicker } from "@mui/lab";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { statusTypes } from "src/utils/dropdowns/common";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { selectOwners } from "src/redux/reducers/owner";
import { selectTerminals } from "src/redux/reducers/terminal";
import CustomSwitch from "../customElements/customSwitch";
import { InputLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useStyles } from "src/styles/formStyles";
import { useLocation } from "react-router-dom";
import AddCommonDialog from "src/components/common/addCommonDialog";
import OwnerFormContainer from "src/containers/owner/form";
import TerminalFormContainer from "src/containers/terminals/form";
import { GridAddIcon } from "@mui/x-data-grid";
import AddLookupField from "src/components/common/addLookupFeild";
import { featureVehicles } from "src/utils/enums";

interface VehicleInfoProps {
  control: any;
  errors: { [key: string]: any };
  /* onSubmit: (data: VehicleProps) => any; */
  // onSubmit: (data: any) => any;
  disabled: boolean;
  watch: any;
}
const VehicleInfo = ({
  //  onSubmit,
  disabled,
  control,
  errors,
  watch,
}: VehicleInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  const classes = useStyles();
  const location = useLocation();

  const allLookups = useAppSelector(selectLookups);
  const allOwners = useAppSelector(selectOwners);
  const terminals = useAppSelector(selectTerminals);
  const linkingAddress = location.pathname.includes("details");
  const [openOwnerModal, setOpenOwnerModal] = useState<boolean>(false);
  const [openTerminalModal, setOpenTerminalModal] = useState<boolean>(false);

  const allTractorTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["vehicle-type"]?.lookups
      ? allLookups?.data["vehicle-type"]?.lookups
      : [];

  const allTractorMake: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["vehicle-make"]?.lookups
      ? allLookups?.data["vehicle-make"]?.lookups
      : [];
  const allTractorColors: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["vehicle-color"]?.lookups
      ? allLookups?.data["vehicle-color"]?.lookups
      : [];

  const handleOpenOwnerModal = () => {
    setOpenOwnerModal(true);
  };
  const handleCloseOwnerModal = () => {
    setOpenOwnerModal(false);
  };
  const handleOpenTerminalModal = () => {
    setOpenTerminalModal(true);
  };
  const handleCloseTerminalModal = () => {
    setOpenTerminalModal(false);
  };

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid container>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="vehicleID"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  id="vehicleID"
                  label={translation("vehicle.form.vehicleID.label")}
                  error={Boolean(errors?.vehicleID)}
                  helperText={
                    errors?.vehicleID &&
                    translation("vehicle.form.vehicleID.message")
                  }
                  required
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="status"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  fullWidth
                  size="small"
                  id="status"
                  disabled={disabled}
                  label={translation("vehicle.form.status.label")}
                  error={Boolean(errors?.status)}
                  select
                  helperText={
                    errors?.status && translation("vehicle.form.status.message")
                  }
                  required
                >
                  {statusTypes.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <>
            <CustomFormGrid
              item
              sm={6}
              xs={12}
              md={3}
              lg={3}
              display="flex"
              justifyContent="space-between"
            >
              <CustomFormGrid
                sx={{
                  width: "100%",
                  padding: "0px",
                }}
              >
                <Controller
                  name="terminal"
                  control={control}
                  defaultValue={""}
                  //  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      size="small"
                      fullWidth
                      disabled={disabled}
                      id="terminal"
                      display={watch("category")}
                      label={translation("driver.form.homeTerminal.label")}
                      error={Boolean(errors?.terminal)}
                      select
                      //    required
                      helperText={
                        errors?.terminal &&
                        translation("driver.form.homeTerminal.message")
                      }
                    >
                      {terminals?.map((item: any, index: any) => (
                        <MenuItem value={item.id} key={index}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  )}
                />
              </CustomFormGrid>
              {linkingAddress ? (
                <CustomFormGrid>
                  <GridAddIcon
                    className={classes.disableGridAddButton}
                  ></GridAddIcon>
                </CustomFormGrid>
              ) : (
                <CustomFormGrid>
                  <GridAddIcon
                    className={classes.formAddOwnerButton}
                    onClick={() => handleOpenTerminalModal()}
                  ></GridAddIcon>
                </CustomFormGrid>
              )}
            </CustomFormGrid>

            <CustomFormGrid
              item
              sm={6}
              xs={12}
              md={3}
              lg={3}
              display="flex"
              justifyContent="space-between"
            >
              <CustomFormGrid
                sx={{
                  width: "100%",
                  padding: "0px",
                }}
              >
                <Controller
                  name="owner"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      fullWidth
                      size="small"
                      id="owner"
                      disabled={disabled}
                      select
                      label={translation("vehicle.form.owner.label")}
                      error={Boolean(errors?.owner)}
                      helperText={
                        errors?.owner &&
                        translation("vehicle.form.owner.message")
                      }
                      required
                    >
                      {allOwners?.data?.map((item: any, index: any) => (
                        <MenuItem value={item.id} key={index}>
                          {item.firstName} {item.lastName}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  )}
                />
              </CustomFormGrid>
              {linkingAddress ? (
                <CustomFormGrid>
                  <GridAddIcon
                    className={classes.disableGridAddButton}
                  ></GridAddIcon>
                </CustomFormGrid>
              ) : (
                <CustomFormGrid>
                  <GridAddIcon
                    className={classes.formAddOwnerButton}
                    onClick={() => handleOpenOwnerModal()}
                  ></GridAddIcon>
                </CustomFormGrid>
              )}
            </CustomFormGrid>

            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="ownerSince"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    disabled={disabled}
                    label={translation("vehicle.form.ownerSince.label")}
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        {...field}
                        size="small"
                        id="ownerSince"
                        fullWidth
                        error={Boolean(errors?.ownerSince)}
                        helperText={
                          errors?.ownerSince &&
                          translation("vehicle.form.ownerSince.message")
                        }
                        required
                      />
                    )}
                  />
                )}
              />
            </CustomFormGrid>
          </>

          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="year"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  views={["year"]}
                  disabled={disabled}
                  label={translation("vehicle.form.year.label")}
                  inputFormat="yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      id="year"
                      fullWidth
                      error={Boolean(errors?.year)}
                      helperText={
                        errors?.year && translation("vehicle.form.year.message")
                      }
                      required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid
            item
            sm={6}
            xs={12}
            md={3}
            lg={3}
            style={{ margin: "0px", padding: "0px" }}
          >
            <AddLookupField
              name={"make"}
              control={control}
              rules={{ required: true }}
              id="make"
              label={translation("vehicle.form.make.label")}
              errors={Boolean(errors?.make)}
              lookupsArray={allTractorMake}
              linkingAddress={linkingAddress}
              helperText={
                errors?.make && translation("vehicle.form.make.message")
              }
              fieldRequired={true}
              disabled={disabled}
              featureKey="vehicle-make"
              lookupFeatureEnum={featureVehicles}
            />
          </CustomFormGrid>

          {/* <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="make"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  select
                  id="make"
                  label={translation("vehicle.form.make.label")}
                  error={Boolean(errors?.make)}
                  helperText={
                    errors?.make && translation("vehicle.form.make.message")
                  }
                  required
                >
                  {allTractorMake.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>*/}
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="model"
              control={control}
              defaultValue={""}
              // rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="model"
                  label={translation("vehicle.form.model.label")}
                  error={Boolean(errors?.model)}
                  helperText={
                    errors?.model && translation("vehicle.form.model.message")
                  }
                  //   required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          {/*   <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="type"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  select
                  id="type"
                  label={translation("vehicle.form.type.label")}
                  error={Boolean(errors?.type)}
                  helperText={
                    errors?.type && translation("vehicle.form.type.message")
                  }
                >
                  {allTractorTypes.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid> */}

          <CustomFormGrid
            item
            sm={6}
            xs={12}
            md={3}
            lg={3}
            style={{ margin: "0px", padding: "0px" }}
          >
            <AddLookupField
              name={"type"}
              control={control}
              rules={{ required: false }}
              id="type"
              label={translation("vehicle.form.type.label")}
              errors={Boolean(errors?.type)}
              lookupsArray={allTractorTypes}
              linkingAddress={linkingAddress}
              helperText={
                errors?.type && translation("vehicle.form.type.message")
              }
              fieldRequired={false}
              disabled={disabled}
              featureKey="vehicle-type"
              lookupFeatureEnum={featureVehicles}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="VIN"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="VIN"
                  label={translation("vehicle.form.VIN.label")}
                  error={Boolean(errors?.VIN)}
                  helperText={
                    errors?.VIN && translation("vehicle.form.VIN.message")
                  }
                  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid
            item
            sm={6}
            xs={12}
            md={3}
            lg={3}
            style={{ margin: "0px", padding: "0px" }}
          >
            <AddLookupField
              name={"color"}
              control={control}
              rules={{ required: false }}
              id="color"
              label={translation("vehicle.form.color.label")}
              errors={Boolean(errors?.color)}
              lookupsArray={allTractorColors}
              linkingAddress={linkingAddress}
              helperText={
                errors?.color && translation("vehicle.form.color.message")
              }
              fieldRequired={false}
              disabled={disabled}
              featureKey="vehicle-color"
              lookupFeatureEnum={featureVehicles}
            />
          </CustomFormGrid>

          {/*  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="color"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  disabled={disabled}
                  select
                  id="color"
                  label={translation("vehicle.form.color.label")}
                  error={Boolean(errors?.make)}
                  helperText={
                    errors?.color && translation("vehicle.form.color.message")
                  }
                >
                  {allTractorColors.map((item: any, index: any) => (
                    <MenuItem value={item.id} key={index}>
                      {item.content}
                    </MenuItem>
                  ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid> */}

          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="fuelCapacity"
              control={control}
              defaultValue={""}
              //  rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  disabled={disabled}
                  size="small"
                  fullWidth
                  type="number"
                  inputProps={{ min: 0 }}
                  id="fuelCapacity"
                  label={translation("vehicle.form.fuelCapacity.label")}
                  error={Boolean(errors?.fuelCapacity)}
                  helperText={
                    errors?.fuelCapacity &&
                    translation("vehicle.form.fuelCapacity.message")
                  }
                  // required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="tagNumber"
              control={control}
              defaultValue={""}
              //   rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  disabled={disabled}
                  size="small"
                  fullWidth
                  id="tagNumber"
                  //   type="number"
                  //   inputProps={{ min: 0 }}
                  label={translation("vehicle.form.tagNumber.label")}
                  error={Boolean(errors?.tagNumber)}
                  helperText={
                    errors?.tagNumber &&
                    translation("vehicle.form.tagNumber.message")
                  }
                  //    required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="tagState"
              control={control}
              defaultValue={""}
              //  rules={{ required: true }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  // select
                  id="tagState"
                  label={translation("vehicle.form.tagState.label")}
                  error={Boolean(errors?.tagState)}
                  helperText={
                    errors?.tagState &&
                    translation("vehicle.form.tagState.message")
                  }
                  //  required
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="tagExpiration"
              control={control}
              defaultValue={""}
              //  rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  disabled={disabled}
                  label={translation("vehicle.form.tagExpiration.label")}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      id="tagExpiration"
                      fullWidth
                      error={Boolean(errors?.tagExpiration)}
                      helperText={
                        errors?.tagExpiration &&
                        translation("vehicle.form.tagExpiration.message")
                      }
                      //     required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="insuranceCarrier"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  disabled={disabled}
                  fullWidth
                  id="insuranceCarrier"
                  label={translation("vehicle.form.insuranceCarrier.label")}
                  error={Boolean(errors?.insuranceCarrier)}
                  helperText={
                    errors?.insuranceCarrier &&
                    translation("vehicle.form.insuranceCarrier.message")
                  }
                ></CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <Controller
              name="insuranceExpiration"
              control={control}
              defaultValue={""}
              //  rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label={translation("vehicle.form.insuranceExpiration.label")}
                  inputFormat="MM/dd/yyyy"
                  disabled={disabled}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      {...field}
                      size="small"
                      id="insuranceExpiration"
                      fullWidth
                      error={Boolean(errors?.insuranceExpiration)}
                      helperText={
                        errors?.insuranceExpiration &&
                        translation("vehicle.form.insuranceExpiration.message")
                      }
                      //    required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid
            item
            sm={6}
            xs={12}
            md={4}
            lg={4}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Controller
              name="hasTrailer"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <InputLabel>
                    {translation("vehicle.form.hasTrailer.label")}
                  </InputLabel>
                  <CustomSwitch
                    disabled={disabled}
                    checked={Boolean(watch("hasTrailer"))}
                    {...field}
                    id="hasTrailer"
                  />
                </>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid
            item
            xs={12}
            lg={12}
            sm={12}
            md={12}
            style={{
              alignItems: "center",
              display: "flex",
              //    padding: "0px 2px",
              //  marginTop: "20px",
            }}
          >
            <Controller
              name="teamVehicle"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <>
                  <Checkbox
                    {...field}
                    disabled={disabled}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                  <InputLabel>
                    {translation("vehicle.form.teamVehicle.label")}
                  </InputLabel>
                </>
              )}
            />
          </CustomFormGrid>
          {/*  {linkingAddress || (
              <CustomFormGrid container justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.formButton}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : params?.vehicleId ? (
                    translation("common.form.update")
                  ) : (
                    translation("common.form.add")
                  )}
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  className={classes.formButton}
                  onClick={() => navigate(-1)}
                >
                  {translation("common.btn.cancel")}
                </Button>
              </CustomFormGrid>
            )} */}
        </CustomFormGrid>
      </CustomFormGrid>
      <AddCommonDialog
        handleClose={handleCloseOwnerModal}
        open={openOwnerModal}
        title="Add Owner"
        ComponentToRender={
          <OwnerFormContainer handleClose={handleCloseOwnerModal} />
        }
      />
      <AddCommonDialog
        handleClose={handleCloseTerminalModal}
        open={openTerminalModal}
        title="Add Termianl"
        ComponentToRender={
          <TerminalFormContainer handleClose={handleCloseTerminalModal} />
        }
      />
    </div>
  );
};

export default VehicleInfo;
