import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import DriverForm from "src/components/driver/form";
import DriverData from "src/components/driver/driverData";
import { DriverProps } from "src/interfaces/driver";
import {
  getDriverById,
  addDriver,
  updateDriver,
} from "src/redux/actions/driver";
import { getAllUsers } from "src/redux/actions/users";
import { getAllTerminals } from "src/redux/actions/terminal";
import { resetDriver } from "src/redux/reducers/driver";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { sanitizeEntity } from "src/utils/common";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { selectUser } from "src/redux/reducers/auth";
import { driver, admin } from "src/utils/enums";

const DriverFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const user = useAppSelector(selectUser);
  const getLookupsList = async () => {
    setLoading(true);
    await dispatch(getAllLookupsByCategory("all", 0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getLookupsList();
  }, []);

  const driverId =
    user?.role?.name?.toLocaleLowerCase() === driver
      ? `${user?.driver?.id}`
      : params?.driverId;

  useEffect(() => {
    dispatch(resetDriver());
    dispatch(getAllUsers(0, 1));
    dispatch(getAllTerminals(0, 1));
  }, []);
  useEffect(() => {
    if (driverId) {
      getSingleDriver(parseInt(driverId));
    }
  }, [driverId]);

  const getSingleDriver = async (id: number) => {
    setPageLoading(true);
    await dispatch(getDriverById(id));
    setPageLoading(false);
  };
  const onSubmit = async (data: DriverProps) => {
    setLoading(true);
    let objToSubmit: DriverProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.isSameBillingAddress = objToSubmit.isSameBillingAddress
      ? objToSubmit.isSameBillingAddress
      : false;
    /*  objToSubmit.billingAddress = objToSubmit.billingAddress
      ? objToSubmit.billingAddress
      : ""; */
    objToSubmit.billingCity = objToSubmit.billingCity
      ? objToSubmit.billingCity
      : "";
    if (driverId && user?.role?.name?.toLocaleLowerCase() === admin) {
      //update
      await dispatch(updateDriver(objToSubmit, parseInt(driverId)));
    } else {
      //add
      const driver = await dispatch(addDriver(objToSubmit));
      if (driver.success && driver.data.id) {
        // setTimeout(() => navigate(`/drivers/drivers/${driver.data.id}`), 500);
        setTimeout(() => navigate("/drivers/drivers"), 500);
      }
    }
    if (driverId && user?.role?.name?.toLocaleLowerCase() === driver) {
      //update
      await dispatch(updateDriver(objToSubmit, parseInt(driverId)));
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <DriverData
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};
export default DriverFormContainer;
