import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { VehicleSafetyProps } from "src/interfaces/vehicle";

export interface VehicleSafetyState {
  VehiclesSafetyList: Array<VehicleSafetyProps>;
  singleVehicleSafety: VehicleSafetyProps | null;
}

const initialState: VehicleSafetyState = {
  VehiclesSafetyList: [],
  singleVehicleSafety: null,
};

export const vehiclesSafetySlice = createSlice({
  name: "vehiclesSafety",
  initialState,

  reducers: {
    setAllVehiclesSafety: (
      state,
      action: PayloadAction<Array<VehicleSafetyProps>>
    ) => {
      state.VehiclesSafetyList = action.payload;
    },
    setSingleVehicleSafety: (
      state,
      action: PayloadAction<VehicleSafetyProps>
    ) => {
      state.singleVehicleSafety = action.payload;
    },
    resetVehicleSafety: (state) => {
      state.singleVehicleSafety = null;
    },
    resetVehicleSafetyState: (state) => {
      state.singleVehicleSafety = null;
      state.VehiclesSafetyList = [];
    },
  },
});
export const {
  setAllVehiclesSafety,
  setSingleVehicleSafety,
  resetVehicleSafety,
  resetVehicleSafetyState,
} = vehiclesSafetySlice.actions;
export const selectVehiclesSafety = (
  state: RootState
): Array<VehicleSafetyProps> => state.vehiclesSafety.VehiclesSafetyList;
export const selectVehicleSafety = (
  state: RootState
): VehicleSafetyProps | null => state.vehiclesSafety.singleVehicleSafety;
export default vehiclesSafetySlice.reducer;
