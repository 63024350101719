import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "src/components/dataTable/linkingTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { selectOwner, selectLinkedTrailers } from "src/redux/reducers/owner";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { OwnerTrailerProps } from "src/interfaces/owner";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";
import {
  addOwnerTrailer,
  deleteOwnerTrailerLink,
} from "src/redux/actions/ownerTrailer";
import ConfirmationDialog from "src/components/common/confirmation";
// import { getAllAvailableDrivers } from "src/redux/actions/driver";
import { getAllAvailableTrailers } from "src/redux/actions/trailer";
import OwnerTrailerLinkModal from "src/components/owner/ownerTrailerLinkingModal";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import AssignedLinkingCard from "src/components/common/linkingCards/assignedTrailer";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function DriverTrailer() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const reducerOwner = useAppSelector(selectOwner);
  const linkedTrailers = useAppSelector(selectLinkedTrailers);
  const [translation] = useTranslation("translation");
  const [loading, setLoading] = useState<boolean>(false);
  const [trailer, setTrailer] = useState<OwnerTrailerProps | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const getAvailableTrailerList = async () => {
    setLoading(true);
    const filterObject: any = {};
    filterObject.items = [
      {
        columnField: "status",
        operatorValue: "equals",
        value: "active",
      },
    ];
    await dispatch(getAllAvailableTrailers(0, 1, filterObject));
    setLoading(false);
  };
  useEffect(() => {
    getAvailableTrailerList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleUnlinkTrailer = (data: OwnerTrailerProps) => {
    handleOpenDeleteModal();
    setTrailer(data);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (params?.ownerId) {
      setLoading(true);
      if (trailer) {
        const object = {
          trailer:
            typeof trailer === "object"
              ? trailer.id
                ? trailer.id
                : ""
              : trailer,
          owner: parseInt(params?.ownerId),
        };
        await dispatch(deleteOwnerTrailerLink(object));
      }
      handleCloseDeleteModal();
      setLoading(false);
      getAvailableTrailerList();
    }
  };
  /* OwnerTrailerProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: OwnerTrailerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (params?.ownerId) {
      objToSubmit.owner = parseInt(params.ownerId)
        ? parseInt(params.ownerId)
        : null;
    }
    objToSubmit.trailer = objToSubmit.trailer ? objToSubmit.trailer : null;

    if (params?.ownerId) {
      const ownerTrailerLink = await dispatch(addOwnerTrailer(objToSubmit));

      if (ownerTrailerLink.success && ownerTrailerLink.data) {
        handleClose();
        getAvailableTrailerList();
      }
    }
    setLoading(false);
  };

  const logsColumns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "trailer",
      headerName: translation("common.name.label"),
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.trailer === "object" &&
            params?.row?.trailer?.trailerId
              ? `${params?.row?.trailer?.trailerId}`
              : ""}
          </div>
        );
      },
      minWidth: 300,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 100,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const assignedTrailerolumns = [
    {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="delete"
          key="delete"
          onClick={() => {
            handleUnlinkTrailer(params.row);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 200,
    },
    {
      field: "trailer",
      headerName: translation("common.name.label"),
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.trailer === "object" &&
            params?.row?.trailer?.trailerId
              ? `${params?.row?.trailer?.trailerId}`
              : ""}
          </div>
        );
      },
      minWidth: 300,
      flex: 1,
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.createdAt
              ? moment(params?.row?.createdAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/*  <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>

            <Tooltip
              title={
                <Typography>{translation("owner.trailer.assign")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleClickOpen}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid> */}

      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedTrailers}
          headerName={"Assigned Trailer"}
          handleClickOpen={handleClickOpen}
          handleDelete={handleUnlinkTrailer}
        />
      </CustomFormGrid>

      {/*  <DataTable
        title={""}
        rows={linkedTrailers}
        columns={assignedTrailerolumns}
        loading={false}
        onClickAdd={handleClickOpen}
        rowsState={rowsState}
        setRowsState={setRowsState}
        add={true}
      /> */}

      <OwnerTrailerLinkModal
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof trailer?.trailer === "object"
                ? `${trailer?.trailer?.trailerId}`
                : "",
          })}
          title={translation("ownerTrailer.delete.title")}
          loading={loading}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={
                reducerOwner?.ownerTrailer ? reducerOwner?.ownerTrailer : []
              }
              columns={logsColumns}
              loading={loading}
              onClickAdd={handleClickOpen}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              add={true}
            />
          }
          title={translation("trailer.assignment.logs")}
        />
      )}
    </>
  );
}
