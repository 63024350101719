import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
const Scrollbar = React.forwardRef<HTMLInputElement, any>(
  (props: any, ref: any) => {
    const { children, ...other }: any = props;
    return (
      <PerfectScrollbar ref={ref} {...other} >
        {children}
      </PerfectScrollbar>
    );
  }
);
Scrollbar.displayName = "Scrollbar";

export default Scrollbar;
