import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CustomerContainer from "src/containers/customers";

const Customers = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Customers" description="Porto Customers" />
      <CustomerContainer />
    </Grid>
  );
};

export default Customers;
