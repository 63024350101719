import React, { useState} from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Close } from "@mui/icons-material";
import CustomGrid from "src/components/customElements/customFormGrid";
import { useAppSelector } from "src/hooks/reduxHooks";
import CustomTextField from "src/components/customElements/customTextField";
import {
    Typography,
    Button,
    Autocomplete,
} from "@mui/material";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { selectAllAccount } from "src/redux/reducers/settlement";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DialogActions from '@mui/material/DialogActions';
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";



interface DialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function AddCommonDialouge({
  open,
  handleClose,

}: DialogProps): JSX.Element {
    const [translation] = useTranslation("translation");
    const allAccounts = useAppSelector(selectAllAccount );
    const [account, setAccount] = useState<any | null>(null);
    const [selectedId ,setSelectedId] = useState<any | null>(null);
    const classes = useStyles();
    const navigate = useNavigate();


    const handleAccountChange = (event: any, newValue: any | null) => {
        setAccount(newValue);
        setValue("account", newValue?.id);
        setSelectedId(newValue?.id)
    };

    const {
        control,
        formState: { errors },
        setValue,
      } = useForm();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth

      >
        <DialogTitle id="alert-dialog-title">
          <CustomGrid
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ textTransform: "uppercase" }}>Select Account</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomGrid>
              </DialogTitle>

              <DialogContent>
              {allAccounts &&  (<CustomFormGrid item sm={12} xs={12} md={3} lg={12}>
                      <Controller
                        name="account"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                           {...field}
                            id="combo-box-demo"
                            fullWidth
                            options={allAccounts}
                            onChange={(
                              event: any,
                              newValue: any | null
                            ) => {
                              field.onChange(newValue);
                              handleAccountChange(event, newValue)

                            }}
                            value={account}
                             getOptionLabel={(option) =>
                              option?.accountType +
                              "-" +
                              option?.accountName}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                id="account"
                                size="small"
                                label={translation(
                                  "settlement.addEarningForm.accountName.label"
                                )}
                                required
                                error={Boolean(errors?.account)}
                               // error={Boolean(errors?.accountType)}
                                helperText={
                                  errors?.account &&
                                  translation(
                                    "settlement.addEarningForm.accountName.label"
                                  ) }/>
                            )} /> )} /> </CustomFormGrid>)}
              </DialogContent>
              <DialogActions>



              <CustomFormGrid container justifyContent="center">
                    <Button
                      color="primary"
                      variant="contained"
                              type="submit"
                              disabled={selectedId === null ? true : false}
                      className={classes.formButton}
                          onClick={() => {
                            navigate(`/settlements/settlement-driver/${selectedId}`);
                      } }
                    >

                       { translation("common.form.Jump")}

                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButton}
                              onClick={() => {
                                setSelectedId(null)
                                  handleClose()
                              }}
                    >
                      {translation("common.btn.cancel")}
                    </Button>
                  </CustomFormGrid>
              </DialogActions>

      </Dialog>
    </div>
  );
}
