import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Collapsible from "src/components/collapsibleForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import UserInfo from "./userInformation";
import AddressInfo from "src/components/common/address";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
interface UserFormProps {
  control: any;
  errors: { [key: string]: any };
  loading: boolean;
  pageLoading: boolean;
  /*  onSubmit: (data: UserProps) => any; */
  onSubmit: (data: any) => any;
  handleChange: (event: any, newExpanded: boolean, panel: string) => void;
  parentExpandAll?: boolean;
  setValue: any;
  watch: any;
  handleClose?: () => any;
}

const UserInfoPanel = ({
  loading,
  control,
  errors,
  parentExpandAll,
  setValue,
  watch,
  handleClose,
}: UserFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "userInfo",
    "AddressInfo",
  ]);
  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);

  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = ["userInfo", "AddressInfo"];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };

  useEffect(() => {
    if (parentExpandAll) {
      handleExpandAllPanels();
    } else {
      handleCollapseAllPanels();
    }
  }, [parentExpandAll]);

  return (
    <>
      <CustomFormGrid container spacing={2}>
        <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
          <Card className={classes.formContainer}>
            <CardContent style={{ paddingBottom: "2px" }}>
            <Typography className={classes.loadFormHeadings}>
              {translation("users.userInfo.label")}
           </Typography>
            <UserInfo
            control={control}
            errors={errors}
            loading={loading}
            setValue={setValue}
            watch={watch}
          />
            </CardContent>
          </Card>

        </CustomFormGrid>
        <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
        <Card className={classes.formContainer}>
            <CardContent style={{ paddingBottom: "2px" }}>
            <Typography className={classes.loadFormHeadings}>
              {translation("common.address.label")}
           </Typography>
            <AddressInfo control={control} errors={errors} setValue={setValue} />
            </CardContent>
          </Card>

          </CustomFormGrid>
      </CustomFormGrid>

    </>
  );
};

export default UserInfoPanel;
