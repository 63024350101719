import { AppDispatch } from "../store";
import {
    setCityList,
    setStateList,
    setSingleState,
    setSingleCity
} from "../reducers/shared";
import { getServerData } from "../api/index";

export const getStates =
    () => async (dispatch: AppDispatch) => {

        const response: any = await dispatch(
            getServerData(`/address/states`) as any
        );

        if (response?.data?.data) {
            if (Array.isArray(response?.data?.data)) {
                dispatch(setStateList(response?.data?.data));
            }
        }
        return { success: response.success, data: response?.data?.data };
    };

export const getStateByAbbreviation =
    (abbreviation: string) => async (dispatch: AppDispatch) => {
        const response: any = await dispatch(getServerData(`/address/states/${abbreviation}`) as any);
        if (response?.data?.data) {
            dispatch(setSingleState(response?.data?.data));
        }
        return { success: response.success, data: response?.data?.data };
    };

export const getCitiesByState =
    (state: string) => async (dispatch: AppDispatch) => {
        const response = await dispatch(getServerData(`/address/cities/by-state/${state}`) as any);

        if (response?.data?.data) {
            dispatch(setCityList(response?.data?.data));
        }
        return { success: response.success, data: response?.data?.data };
    };

export const getCityByZipCode =
    (zipCode: string) => async (dispatch: AppDispatch) => {
        const response = await dispatch(getServerData(`/address/cities/by-zip-code/${zipCode}`) as any);
        if (response?.data?.data) {
            dispatch(setSingleCity(response?.data?.data));
        }
        return { success: response.success, data: response?.data?.data };
    };
