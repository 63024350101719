import React, { useEffect, useState } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import { useAppSelector, useAppDispatch } from "src/hooks/reduxHooks";
import { getAllTerminals } from "src/redux/actions/terminal";
import terminal, { selectTerminals } from "src/redux/reducers/terminal";

import { LookupProps } from "src/interfaces/lookup";
import { selectLookups } from "src/redux/reducers/lookup";
import AddLookupField from "src/components/common/addLookupFeild";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { useForm } from "react-hook-form";
import { MuiChipsInput } from "mui-chips-input";
import { LoadManagementProps } from "src/interfaces/loadManagement";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomDateTimePicker from "../customElements/customDatetimePicker";


interface PickupAndDeliveryInfoProps {
  done(error: any | null, data: LoadManagementProps): any;
  stepId?: number,
  data?: LoadManagementProps | null
}

const PickupAndDeliveryInfo = ({
  done,
  stepId,
  data
}: PickupAndDeliveryInfoProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [translation] = useTranslation("translation");
  const LoadManagement = useAppSelector(selectLoadManagement);
  const terminals = useAppSelector(selectTerminals);
  const allLookups = useAppSelector(selectLookups);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();


  const [pickupStartError, setPickupStartError] = useState(false);
  const [pickupEndError, setPickupEndError] = useState(false);
  const [deliveryStartError, setDeliveryStartError] = useState(false);
  const [deliveryEndError, setDeliveryEndError] = useState(false);
  const [serviceLevelValue, setServiceLevelValue] = useState<any>(null);
  const [deliveryLevelValue, setDeliveryLevelValue] = useState<any>(null);


  useEffect(() => {
    dispatch(getAllTerminals());
  }, [])

  useEffect(() => {
    if (terminals.length > 0) {
      if (!data?.terminal) {
        setValue("terminal", terminals[0].id)
      }
    }
  }, [terminals])

  const onSubmit = (data: LoadManagementProps) => {
    console.log("Pickup-Delivery: ==================", data);
    done(null, data);
  }

  const serviceLevels: Array<LookupProps> = allLookups?.data?.["service-level"]
    ?.lookups
    ? allLookups?.data?.["service-level"]?.lookups
    : [];


  useEffect(() => {
    if (serviceLevels.length > 0) {
      if (!data?.pickupServiceLevel) {
        const filtered = serviceLevels.filter(e => {
          return String(e.content).split(' ').join('').toLowerCase() === "flexible/fcfs";
        });
        setValue("pickupServiceLevel", filtered[0].id);
        setValue("deliveryServiceLevel", filtered[0].id);
      }
    }
  }, [serviceLevels])




  return (
    <form id={`load-form-${stepId}`} noValidate onSubmit={handleSubmit(onSubmit)}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CustomFormGrid container spacing={2}>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Controller
              name="billOfLading"
              control={control}
              defaultValue={data?.billOfLading || ""}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  id="billOfLading"
                  label={"Bill Of Lading"}
                  error={Boolean(errors?.billOfLading)}
                  helperText={
                    errors?.billOfLading &&
                    "Bill of lading required"
                  }
                >
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={8} lg={8}>
            <Controller
              name="terminal"
              control={control}
              defaultValue={data && data.terminal ? data.terminal : ""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  id="terminal"
                  select
                  label={"Terminal"}
                  error={Boolean(errors?.terminal)}
                  helperText={
                    errors?.terminal &&
                    "Terminal is required"
                  }
                >
                  {terminals &&
                    terminals.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.title}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="pickupServiceLevel"
              control={control}
              defaultValue={data && data.pickupServiceLevel ? data.pickupServiceLevel : ""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  id="pickupServiceLevel"
                  select
                  label={"Pickup Service Level"}
                  error={Boolean(errors?.pickupServiceLevel)}
                  helperText={
                    errors?.pickupServiceLevel &&
                    "Pickup Service Level is required"
                  }
                >
                  {serviceLevels &&
                    serviceLevels.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="pickupDateTime"
              control={control}
              defaultValue={data?.pickupDateTime || ""}
              rules={{ required: true }}
              render={({ field: { value, onChange, ...restFields } }) => (
                <DatePicker
                  {...restFields}
                  label={translation("load.form.pickupDateTime.label")}
                  // disablePast
                  value={value}
                  onChange={(inputValue) => (onChange(inputValue))}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      fullWidth
                      error={Boolean(errors?.pickupDateTime)}
                      helperText={
                        errors?.pickupDateTime &&
                        translation("load.form.pickupDateTime.message")
                      }
                      required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="pickupStart"
              control={control}
              defaultValue={data?.pickupStart || ""}
              render={({ field: { onChange, value } }) => (
                <CustomDateTimePicker
                  timeLabel="Pickup StartTime"
                  dateInput={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="pickupEnd"
              control={control}
              defaultValue={data?.pickupEnd || ""}
              render={({ field: { onChange, value } }) => (
                <CustomDateTimePicker
                  timeLabel="Pickup EndTime"
                  dateInput={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="deliveryServiceLevel"
              control={control}
              defaultValue={data && data.deliveryServiceLevel ? data.deliveryServiceLevel : ""}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  id="deliveryServiceLevel"
                  select
                  label={"Delivery Service Level"}
                  error={Boolean(errors?.deliveryServiceLevel)}
                  helperText={
                    errors?.deliveryServiceLevel &&
                    "Delivery Service Level is required"
                  }
                >
                  {serviceLevels &&
                    serviceLevels.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                </CustomTextField>
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="deliveryDateTime"
              control={control}
              defaultValue={data?.deliveryDateTime || ""}
              rules={{ required: true }}
              render={({ field: { onChange, ...restFields } }) => (
                <DatePicker
                  {...restFields}
                  label={translation("load.form.deliveryDateTime.label")}
                  // disablePast
                  onChange={(inputValue) => { onChange(inputValue) }}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={Boolean(errors?.deliveryDateTime)}
                      fullWidth
                      helperText={
                        errors?.deliveryDateTime &&
                        translation("load.form.deliveryDateTime.message")
                      }
                      required
                    />
                  )}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="deliveryStart"
              control={control}
              defaultValue={data?.deliveryStart || ""}
              render={({ field: { onChange, value } }) => (
                <CustomDateTimePicker
                  timeLabel="Delivery StartTime"
                  dateInput={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
            <Controller
              name="deliveryEnd"
              control={control}
              defaultValue={data?.deliveryEnd || ""}
              render={({ field: { onChange, value } }) => (
                <CustomDateTimePicker
                  timeLabel="Delivery EndTime"
                  dateInput={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </CustomFormGrid>

          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Controller
              name={`internalComments`}
              control={control}
              defaultValue={data?.internalComments}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  id="internalComments"
                  label={"Internal Comments"}
                  helperText={
                    "Does not appears on freight bill"
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Controller
              name={`externalComments`}
              control={control}
              defaultValue={data?.externalComments}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  id="externalComments"
                  label={"External Comments"}
                  helperText={
                    "Appears on freight bill"
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={4} lg={4}>
            <Controller
              name={`hotComments`}
              control={control}
              defaultValue={data?.hotComments}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  size="small"
                  fullWidth
                  multiline
                  rows={3}
                  id="hotComments"
                  label={"Hot Comments"}
                  helperText={
                    "Appears on load summary / dispatch"
                  }
                />
              )}
            />
          </CustomFormGrid>
          <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
            <Controller
              name={`dispatchStatusNotifications`}
              control={control}
              defaultValue={data?.dispatchStatusNotifications || []}
              render={({ field: { onChange, value, ...restFields }, fieldState }) => (
                <MuiChipsInput
                  {...restFields}
                  value={value}
                  fullWidth
                  onChange={(inputValue) => { onChange(inputValue) }}
                  hideClearAll
                  variant="outlined"
                  error={fieldState.invalid}
                  label='Dispatch Status Notifications'
                  helperText={"Notifications are sent when the dispatch status changes"}
                />
              )}
            />
          </CustomFormGrid>
        </CustomFormGrid>
      </LocalizationProvider>
    </form>
  );
};

export default PickupAndDeliveryInfo;
