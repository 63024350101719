import i18next from "i18next";
import { AppDispatch } from "../store";
import API from "src/utils/axiosConfig";
import {
  LoginCredProps,
  SignupCredProps,
  ForgotPasswordProps,
  ResetPasswordProps,
} from "src/interfaces/auth";
import { UserProps } from "src/interfaces/user";
import { loginSuccess, updateUserSuccess } from "../reducers/auth";
import { showNotification } from "../reducers/notifications";

import { resetAuthState } from "../reducers/auth";
import { resetTerminalState } from "../reducers/terminal";
import { resetOwnerState } from "../reducers/owner";
import { resetDriverState } from "../reducers/driver";
import { resetLookupsState } from "../reducers/lookup";
import { resetConfigState } from "../reducers/config";
import { resetSecurityGroupsState } from "../reducers/securityGroups";
import { resetUserState } from "../reducers/users";

export const login = (data: LoginCredProps) => async (dispatch: AppDispatch) => {
    try {
      const response = await API.post("/auth/login", data);
      const responseData: any = response.data;
      const user: UserProps = responseData.user;
      if (user) {
        localStorage.setItem("authToken", responseData.token);
        dispatch(loginSuccess(user));
        return { success: true, data: responseData };
      }

      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data.message
            ? _error?.response?.data.message
            : i18next.t("messages.login.failed"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const signup =
  (data: SignupCredProps) => async (dispatch: AppDispatch) => {
    try {
      const response = await API.post("/auth/signup", data);

      const responseData: any = response.data;
      const user: UserProps = responseData.user;

      if (user) {
        localStorage.setItem("authToken", responseData.token);
        dispatch(loginSuccess(user));
        return { success: true, data: responseData };
      }

      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;

      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data.message
            ? _error?.response?.data.message
            : i18next.t("messages.signup.failed"),
          severity: "error",
        })
      );

      return { success: false, data: _error?.response?.data };
    }
  };

export const forgotPassword =
  (data: ForgotPasswordProps) => async (dispatch: AppDispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const response = await API.patch("/auth/forgot", data, config);
      const responseData: any = response.data;
      if (responseData) {
        dispatch(
          showNotification({
            open: true,
            message: responseData?.message
              ? responseData.message
              : i18next.t("messages.forgot.success"),
            severity: "success",
          })
        );
        return { success: true, data: responseData };
      }
      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;
      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data.message
            ? _error?.response?.data.message
            : i18next.t("messages.forgot.failed"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const resetPassword =
  (data: ResetPasswordProps, token: string) =>
  async (dispatch: AppDispatch) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const response = await API.patch(`/auth/reset/${token}`, data, config);
      const responseData: any = response.data;
      if (responseData) {
        dispatch(
          showNotification({
            open: true,
            message: responseData?.message
              ? responseData.message
              : i18next.t("messages.reset.success"),
            severity: "success",
          })
        );
        return { success: true, data: responseData };
      }
      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;
      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data.message
            ? _error?.response?.data.message
            : i18next.t("messages.reset.failed"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };

export const updateUser =
  (data: UserProps, id: number) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("authToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await API.patch(`/user/${id}`, data, config);

      const responseData: any = response.data;

      if (responseData?.user) {
        dispatch(updateUserSuccess(responseData?.user));

        dispatch(
          showNotification({
            open: true,
            message: responseData.message
              ? responseData.message
              : i18next.t("messages.profile.success"),
            severity: "success",
          })
        );
        return { success: true, data: responseData };
      }
      return { success: false, data: null };
    } catch (error) {
      const _error: any = error;
      dispatch(
        showNotification({
          open: true,
          message: _error?.response?.data?.message
            ? _error?.response?.data?.message
            : i18next.t("messages.profile.failed"),
          severity: "error",
        })
      );
      return { success: false, data: _error?.response?.data };
    }
  };
export const logout = () => async (dispatch: AppDispatch) => {
  localStorage.removeItem("authToken");
  dispatch(resetAuthState());
  dispatch(resetTerminalState());
  dispatch(resetOwnerState());
  dispatch(resetDriverState());
  dispatch(resetLookupsState());
  dispatch(resetConfigState());
  dispatch(resetSecurityGroupsState());
  dispatch(resetUserState());
};
