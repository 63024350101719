import { combineReducers } from "redux";

import authReducer from "./auth";
import notificationReducer from "./notifications";
import userReducer from "./users";
import configReducer from "./config";
import terminalReducer from "./terminal";
import ownerReducer from "./owner";
import lookupReducer from "./lookup";
import customizerReducer from "./customizer";
import driverReducer from "./driver";
import securityGroupsReducer from "./securityGroups";
import vehicleReducer from "./vehicle";
import vehiclesSafety from "./vehicleSafety";
import customerReducer from "./customer";
import customerBillingsReducer from "./customerBilling";
import documentTypeReducer from "./documentType";
import lineItemReducer from "./lineItems";
import trailerReducer from "./trailer";
import trailerSafetyReducer from "./trailerSafety";
import commodityReducer from "./commodity";
import loadManagementReducer from "./loadManagement";
import loadAddressReducer from "./loadAddress";
import vehicleTrailerReducer from "./vehicle_trailer";
import driverVehicleReducer from "./driver_vehicle";
import documentsReducer from "./documents";
import waitingForDocumentReducer from "./billing";
import settlementCodeReducer from "./settlementCodes";
import settlementReducer from "./settlement";
import sharedReducer from "./shared";

export const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  users: userReducer,
  config: configReducer,
  terminals: terminalReducer,
  owners: ownerReducer,
  lookups: lookupReducer,
  customizer: customizerReducer,
  drivers: driverReducer,
  securityGroup: securityGroupsReducer,
  vehicles: vehicleReducer,
  vehiclesSafety: vehiclesSafety,
  customers: customerReducer,
  customerBillings: customerBillingsReducer,
  documentType: documentTypeReducer,
  lineItem: lineItemReducer,
  trailer: trailerReducer,
  trailerSafety: trailerSafetyReducer,
  commodity: commodityReducer,
  loadManagement: loadManagementReducer,
  loadAddress: loadAddressReducer,
  vehiclesTrailer: vehicleTrailerReducer,
  driverVehicle: driverVehicleReducer,
  documents: documentsReducer,
  waitingForDocument: waitingForDocumentReducer,
  settlementCode: settlementCodeReducer,
  settlement: settlementReducer,
  shared: sharedReducer
});
