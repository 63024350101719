import React from "react";
import { Navigate } from "react-router-dom";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import Drivers from "../pages/drivers";
import DriverForm from "../pages/drivers/driversForm";
import DriverReadData from "../pages/drivers/driversReadForm";
import Documents from "../pages/documents";
import DriverSearch from "../containers/drivers/driverSearch";
import { UserPermissions } from "src/interfaces/user";
import {
  admin,
  featureDrivers,
  featureLookups,
  create,
  update,
  find,
} from "src/utils/enums";

export const driverRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const driverPermission =
    allUserPermissions && allUserPermissions[featureDrivers];
  const createPermission =
    role === admin ? 0 : driverPermission?.findIndex((item) => item === create);
  const findPermission =
    role === admin ? 0 : driverPermission?.findIndex((item) => item === find);
  const updatePermission =
    role === admin ? 0 : driverPermission?.findIndex((item) => item === update);

  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);
  return [
    {
      path: "/drivers/lookups",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/drivers/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/drivers",
      exact: true,
      element:
        role === admin || driverPermission?.length > 0 ? (
          <Drivers />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/drivers/drivers/driver/:status",
      exact: true,
      element:
        role === admin || driverPermission?.length > 0 ? (
          <Drivers />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/drivers/drivers/create",
      exact: true,
      element: createPermission > -1 ? <DriverForm /> : <Navigate to="/404" />,
    },

    {
      path: "/drivers/drivers/:driverId",
      exact: true,
      element: updatePermission > -1 ? <DriverForm /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/drivers/documents/:driverId",
      exact: true,
      element: updatePermission > -1 ? <Documents /> : <Navigate to="/404" />,
    },
    // {
    //   path: "/drivers/drivers/details/:driverId",
    //   exact: true,
    //   element: findPermission > -1 ? <DriverForm /> : <Navigate to="/404" />,
    // },
    {
      path: "/drivers/drivers/details/:driverId",
      exact: true,
      element:
        findPermission > -1 ? <DriverReadData /> : <Navigate to="/404" />,
    },
    {
      path: "/drivers/drivers/search",
      exact: true,
      element: findPermission > -1 ? <DriverSearch /> : <Navigate to="/404" />,
    },
  ];
};
