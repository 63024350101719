    import React from "react";
import { Navigate } from "react-router-dom";
import SettlementCodes from "../pages/settlementCodes/index";
import SettlementCodesForm from "../pages/settlementCodes/addFom";
import { UserPermissions } from "src/interfaces/user";
import { admin, create, update,find, featureCommodity } from "src/utils/enums";
import SettlementCodesSearch from "src/containers/settlementCodes/seacrhForm";
export const settlementCodesRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const settlementPermissions =
    allUserPermissions && allUserPermissions[featureCommodity];
  const createPermission =
    role === admin
      ? 0
      : settlementPermissions?.findIndex((item) => item === create);
      const findPermission =
      role === admin ? 0 : settlementPermissions?.findIndex((item) => item === find);
  const updatePermission =
    role === admin
      ? 0
      : settlementPermissions?.findIndex((item) => item === update);

  return [
    {
      path: "/config/settlement-codes",
      exact: true,
      element:
        role === admin || settlementPermissions?.length > 0 ? (
          <SettlementCodes />
        ) : (
          <Navigate to="/404" />
        ),
    },
     {
      path: "/config/settlement-codes/create",
      exact: true,
      element:
        createPermission > -1 ? <SettlementCodesForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/settlement-codes/:id",
      exact: true,
      element:
        updatePermission > -1 ? <SettlementCodesForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/settlement-codes/search",
      exact: true,
      element:
        updatePermission > -1 ? <SettlementCodesSearch /> : <Navigate to="/404" />,
    },
  ];
};
