import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import LookupContainer from "src/containers/lookup";
const Lookups = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Lookups" description="Porto Lookups" />
      <LookupContainer />
    </Grid>
  );
};

export default Lookups;
