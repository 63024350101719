import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CreateDriverReadFormContainer from "src/containers/drivers/formRead";
const DriverForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Drivers" description="Porto Drivers" />
      <CreateDriverReadFormContainer />
    </Grid>
  );
};

export default DriverForm;
