import React, { useState ,useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import CustomGrid from "src/components/customElements/customFormGrid";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

interface FromInputProps {
  heading: any;
  input: any;
  checkColor: boolean;
}

const formInput = ({
  heading,
  input,
  checkColor,
}: FromInputProps): JSX.Element => {
  const [inputColor, setInputColor] = useState("");


  useEffect(() => {
    if (checkColor === true) {
      setInputColor("#0B79D0");

 } else {
  setInputColor("rgba(0, 0, 0, 0.87)");
   }
  }, [])


  return (

      <CustomGrid container style={{paddingLeft: "10px",paddingRight: "10px",paddingTop:"0px" ,paddingBottom:"0px", margin:"0px"}}>
        <CustomGrid xs={12} sm={12} md={12} lg={12}>
          <Typography

            variant="h6"
            component="h4"
            sx={{pl: 1 ,fontWeight:500, color:"#777E89",fontSize: "14px",lineHeight: "14px"}}
          >
            {heading}
          </Typography>
        </CustomGrid>
        <CustomGrid xs={12} sm={12} md={12} lg={12} style={{marginTop:"5px"}}>

          <Typography
            style={{ color: inputColor}}
          component="h4"
          variant="h6"
            sx={{ pl: 1 ,mb: 0, fontSize:" 16px",lineHeight: "19px"}}
          >
            {input ? input : "---"}
          </Typography>
        </CustomGrid>
      </CustomGrid>

  );
};

export default formInput;
