import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

import VehicleReadForm from "src/components/vehicle/vehicleReadForm";
import { VehicleProps } from "src/interfaces/vehicle";
import {
  getVehicleById,
  addVehicle,
  updateVehicle,
} from "src/redux/actions/vehicle";

import { resetVehicle } from "src/redux/reducers/vehicle";
import { resetVehicleSafetyState } from "src/redux/reducers/vehicleSafety";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { getAllOwners } from "src/redux/actions/owner";
import { getAllTerminals } from "src/redux/actions/terminal";
import { sanitizeEntity } from "src/utils/common";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { featureVehicles } from "src/utils/enums";

const VehicleReadFormContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const getLookupsList = async () => {
    setLoading(true);
    await dispatch(getAllLookupsByCategory(featureVehicles, 0, 1));
    setLoading(false);
  };
  useEffect(() => {
    getLookupsList();
  }, []);

  useEffect(() => {
    dispatch(resetVehicle());
    dispatch(resetVehicleSafetyState());
    dispatch(getAllOwners(0, 1));
    // dispatch(getAllVehicles(0, 1));
    dispatch(getAllTerminals(0, 1));
  }, []);
  useEffect(() => {
    if (params?.vehicleId) {
      getSingleVehicle(parseInt(params?.vehicleId));

      //  getSingleVehicleSafety(parseInt(params?.vehicleId));
    }
  }, [params?.vehicleId]);
  const getSingleVehicle = async (id: number) => {
    setPageLoading(true);

    await dispatch(getVehicleById(id));
    setPageLoading(false);
  };
  /*  const getSingleVehicleSafety = async (id: number) => {
    setPageLoading(true);
    await dispatch(getVehicleSafetyById(id));
    setPageLoading(false);
  }; */

  /* const onSafetySubmit = async (data: VehicleSafetyProps) => {
    setLoading(true);

    let objToSubmit: VehicleSafetyProps = { ...data };

    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.vehicleId) {
      //update
      objToSubmit["vehicle"] = parseInt(params?.vehicleId);
      await dispatch(
        updateVehicleSafety(objToSubmit, parseInt(params?.vehicleId))
      );
    }
    setLoading(false);
  };*/
  /*VehicleProps  */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: VehicleProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    if (params?.vehicleId) {
      //update
      await dispatch(updateVehicle(objToSubmit, parseInt(params?.vehicleId)));
    } else {
      //add
      const vehicle = await dispatch(addVehicle(objToSubmit));
      if (vehicle.success && vehicle.data.id) {
        // setTimeout(() => navigate(`/vehicle/vehicles/${vehicle.data.id}`), 500);
        setTimeout(() => navigate("/vehicle/vehicles"), 500);
      }
    }
    // if (params?.vehicleId) {
    //   //update
    //   await dispatch(updateVehicle(objToSubmit, parseInt(params?.vehicleId)));
    // } else {
    //   //add
    //   const vehicle_trailer = await dispatch(addVehicle(objToSubmit));
    //   if (vehicle_trailer.success && vehicle_trailer.data.id) {
    //     setTimeout(
    //       () =>
    //         navigate(`/vehicle/vehicles/details/${vehicle_trailer.data.id}`),
    //       500
    //     );
    //   }
    // }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <VehicleReadForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
          //  onSafetySubmit={onSafetySubmit}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleReadFormContainer;
