import React from "react";
import { Navigate } from "react-router-dom";
import { UserPermissions } from "src/interfaces/user";
import { admin, update, featureSettlements,create } from "src/utils/enums";
import Settlements from "../pages/settlements/settlementDriver";
import CompanyDriver from "../pages/settlements/companyDriver";
import OwnerSettlement from "../pages/settlements/settlementOwner";
import TerminalSettlement from "../pages/settlements/settlementTerminal";
import AddEarning from "../pages/settlements/addEarning";
import Dashboard from "../pages/settlements/dashboard";
import SettlementPrintQueue from "../pages/settlements/settlementPrintQueue";
import CompletedSettlements from "../pages/settlements/completedSettlements";

export const settlementRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const settlementPermission =
    allUserPermissions && allUserPermissions[featureSettlements];
    const createPermission =
    role === admin
      ? 0
      : settlementPermission?.findIndex((item) => item === create);

  const updatePermission =
    role === admin
      ? 0
      : settlementPermission?.findIndex((item) => item === update);
  /*  const findPermission =
    role === admin
      ? 0
      : settlementPermission?.findIndex((item) => item === find); */

  return [
    {
      path: "/settlements/dashboard",
      exact: true,
      element:
        role === admin || settlementPermission?.length > 0 ? (
          <Dashboard />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/settlements/settlement-driver",
      exact: true,
      element:
        role === admin || settlementPermission?.length > 0 ? (
          <Settlements />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/settlements/settlements/settlement-driver",
      exact: true,
      element: <CompanyDriver />,
    },
    {
      path: "/settlements/settlements/settlement-owner",
      exact: true,
      element: <CompanyDriver />,
    },
    {
      path: "/settlements/settlements/settlement-terminal",
      exact: true,
      element: <CompanyDriver />,
    },
    {
      path: "/settlements/settlement-owner",
      exact: true,
      element: <OwnerSettlement />,
    },
    {
      path: "/settlements/settlement-terminal",
      exact: true,
      element: <TerminalSettlement />,
    },
    {
      path: "/settlements/earning-deduction",
      exact: true,
      element: <AddEarning />,
    },

    {
      path: "/settlements/settlement-owner/:id",
      exact: true,
      element:
        updatePermission > -1 ? <CompanyDriver /> : <Navigate to="/404" />,
    },
    {
      path: "/settlements/settlement-terminal/:id",
      exact: true,
      element: <CompanyDriver />,
    },
    {
      path: "/settlements/settlement-driver/:id",
      exact: true,
      element: <CompanyDriver />,
    },
    {
      path: "/settlements/settlement-terminal",
      exact: true,
      element: <TerminalSettlement />,
    },
    {
      path: "/settlements/settlement-print-queue",
      exact: true,
      element: <SettlementPrintQueue />,
    },
    {
      path: "/settlements/settlement-completed",
      exact: true,
      element: <CompletedSettlements />,
    },
    {
      path: "/settlements/earning-deduction",
      exact: true,
      element: <AddEarning />,
    },
    {
      path: "/settlements/earning-deduction/create",
      exact: true,
      element: createPermission > -1 ? <AddEarning /> : <Navigate to="/404" />,
    },

    {
      path: "/settlements/earning-deduction/:id",
      exact: true,
      element: updatePermission > -1 ? <AddEarning /> : <Navigate to="/404" />,
    },
    {
      path: "/settlements/earning-deduction/:terminalId",
      exact: true,
      element: updatePermission > -1 ? <AddEarning /> : <Navigate to="/404" />,
    },
    {
      path: "/settlements/earning-deduction/:driverId",
      exact: true,
      element: updatePermission > -1 ? <AddEarning /> : <Navigate to="/404" />,
    },
  ];
};
