import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserProps, UserPermissions } from "src/interfaces/user";
import { CompanyProps } from "src/interfaces/company";

// type UserPermissions = { [key: string]: Array<string> };

export interface AuthState {
  user: UserProps | null;
  isAuthenticated: boolean;
  company: CompanyProps | null;
  allCompanies: Array<CompanyProps>;
  permissions: UserPermissions;
}

const initialState: AuthState = {
  user: null,
  company: null,
  isAuthenticated: false,
  permissions: {},
  allCompanies: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<UserProps>) => {
      state.user = action.payload;
      state.company = action.payload.tenant ? action.payload.tenant : null;
      state.isAuthenticated = true;
      state.permissions = action.payload.userPermissions
        ? action.payload.userPermissions
        : {};
    },
    updateCompanySuccess: (state, action: PayloadAction<CompanyProps>) => {
      state.company = action.payload;
      if (state.user) {
        state.user.tenant = action.payload;
      }
    },
    setAllCompanies: (state, action: PayloadAction<Array<CompanyProps>>) => {
      state.allCompanies = action.payload;
    },
    updateUserSuccess: (state, action: PayloadAction<UserProps>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.company = null;
      state.isAuthenticated = false;
    },
    resetAuthState: (state) => {
      state.user = null;
      state.company = null;
      state.isAuthenticated = false;
      state.permissions = {};
      state.allCompanies = [];
    },
  },
});

export const {
  loginSuccess,
  updateCompanySuccess,
  updateUserSuccess,
  logout,
  resetAuthState,
  setAllCompanies,
} = authSlice.actions;

export const selectUser = (state: RootState): UserProps | null =>
  state.auth.user;
export const selectCompany = (state: RootState): CompanyProps | null =>
  state?.auth?.company;
export const selectIsAuthenticated = (state: RootState): boolean =>
  state.auth.isAuthenticated;
export const selectUserPermissions = (state: RootState): UserPermissions =>
  state.auth.permissions;
export const selectCompaniesList = (state: RootState): Array<CompanyProps> =>
  state.auth.allCompanies;

export default authSlice.reducer;
