import React, { useEffect, useState } from "react";
import {
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/dataTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import { getAllTrailers } from "src/redux/actions/trailer";
import { selectTrailers } from "src/redux/reducers/trailer";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { featureTrailers, create, update } from "src/utils/enums";
import { selectUserPermissions, selectUser } from "src/redux/reducers/auth";
import { admin } from "src/utils/enums";
import { Clipboard } from "ts-react-feather-icons";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toNumber } from "lodash";
import { useStyles } from "src/styles/formStyles";
import { getGridStringOperators } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import CustomFormGrid from "src/components/customElements/customFormGrid";

interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function Trailers() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const trailers = useAppSelector(selectTrailers);
  const [translation] = useTranslation("translation");
  const [trailerData, setTrailerData] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const vehiclePermissions =
    allUserPermissions && allUserPermissions[featureTrailers];
  const createPermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === create);
  const updatePermission =
    user?.role?.name?.toLocaleLowerCase() === admin
      ? 0
      : vehiclePermissions?.findIndex((item) => item === update);

  const getTrailersList = async () => {
    setLoading(true);
    await dispatch(
      getAllTrailers(
        rowsState.pageSize,
        rowsState?.page ? toNumber(rowsState?.page + 1) : 1
      )
    );
    setLoading(false);
  };

  const getTrailersListWithFilter = async () => {
    Object.keys(filter).length !== 0 &&
      (await dispatch(
        getAllTrailers(
          rowsState.pageSize,
          rowsState?.page ? toNumber(rowsState?.page + 1) : 1,
          filter
        )
      ));
  };

  /*   function pageExists(page: any) {
    return trailerData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */

  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      getTrailersListWithFilter();
    } else {
      getTrailersList();
    }
  }, [rowsState.pageSize, rowsState.page]);

  useEffect(() => {
    getTrailersListWithFilter();
  }, [filter]);

  useEffect(() => {
    //if (pageExists(rowsState?.page + 1) == false)
    if (trailers?.data?.length > 0) {
      setTrailerData({ ...trailerData?.trailers, trailers });
    } else {
      setTrailerData({});
    }
  }, [trailers]);

  const addVehicle = () => {
    navigate("/trailer/trailers/create");
  };

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["equals", "contains"].includes(value)
  );

  const columns = [
    updatePermission > -1 && {
      field: "actions",
      type: "actions",
      minWidth: 175,
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={
            <Tooltip
              title={
                (params.row?.trailerSafety[0]?.monthlyMaintenance != null &&
                  new Date(params?.row?.trailerSafety[0]?.monthlyMaintenance) <
                    new Date()) ||
                (params?.row?.trailerSafety[0]?.annualInspectionDate != null &&
                  new Date(
                    params?.row?.trailerSafety[0]?.annualInspectionDate
                  ) < new Date())
                  ? "Action Required"
                  : "Edit"
              }
            >
              <Edit />
            </Tooltip>
          }
          label="Edit"
          key="edit"
          onClick={() => {
            navigate(`/trailer/trailers/${params.row.id}`);
          }}
          className={
            (params.row?.trailerSafety[0]?.monthlyMaintenance != null &&
              new Date(params?.row?.trailerSafety[0]?.monthlyMaintenance) <
                new Date()) ||
            (params?.row?.trailerSafety[0]?.annualInspectionDate != null &&
              new Date(params?.row?.trailerSafety[0]?.annualInspectionDate) <
                new Date())
              ? classes.editIconError
              : classes.edit
          }
        />,
        // <GridActionsCellItem
        //   icon={<UploadFileIcon />}
        //   label="documents"
        //   key="documents"
        //   onClick={() => {
        //     navigate(
        //       `/trailer/trailers/documents/${params.row.id}?entity=${featureTrailers}&referenceId=${params.row.id}`
        //     );
        //   }}
        // />,
        <GridActionsCellItem
          icon={<Clipboard />}
          label="details"
          key="details"
          onClick={() => {
            navigateToTrailerDetail(params.row.id);
          }}
        />,
      ],
    },
    {
      field: "trailerId",
      headerName: translation("common.id.label"),
      minWidth: 100,
      filterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      filterOperators,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "model",
      headerName: translation("vehicle.list.model.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "VIN",
      headerName: translation("vehicle.list.VIN.label"),
      minWidth: 200,
      filterOperators,
    },
    {
      field: "tagNumber",
      headerName: translation("vehicle.list.tagNumber.label"),
      minWidth: 200,
      filterable: false,
    },
    {
      field: "tagState",
      headerName: translation("vehicle.list.tagState.label"),
      minWidth: 200,
      flex: 1,
      filterOperators,
    },
  ];
  const onRowClick = (params: GridRowParams) => {
    navigateToTrailerDetail(params.row.id);
  };
  const navigateToTrailerDetail = (id: number) => {
    navigate(
      `/trailer/trailers/details/${id}?entity=${featureTrailers}&referenceId=${id}`
    );
  };
  const searchTrailer = () => {
    navigate("/trailer/trailers/search");
  };
  return loading ? (
    <div className={classes.loadingStyle}>
      <CircularProgress />
    </div>
  ) : (
    <CustomFormGrid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ paddingTop: "20px" }}
    >
      <DataTable
        title={translation("trailer.list.title")}
        rows={trailerData?.trailers?.data}
        columns={columns}
        loading={loading}
        onClickAdd={addVehicle}
        onClickSearch={searchTrailer}
        createPermission={createPermission > -1}
        onRowClick={onRowClick}
        rowsState={rowsState}
        setRowsState={setRowsState}
        pagination={trailers.pagination}
        data={trailerData?.trailers}
        setData={setTrailerData}
        add={true}
        setFilter={setFilter}
        search={"trailer"}
      />
    </CustomFormGrid>
  );
}
