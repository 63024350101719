import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import FullLayout from "../components/layout";
import PublicLayout from "../components/layout/publicLayout";
import Dashboard from "../pages/dashboard";
import WaitingForDocument from "../pages/billing/waitingForDocument";
import FreightBillProcessingQueue from "../pages/billing/freightBillProcessingQueue";
import FreightBillPrintQueue from "../pages/billing/freightBillPrintQueue";
import FactoringInvoices from "../pages/billing/factoringInvoices";
import BillingDashboard from "../pages/billing/billingDashboard";
import Login from "../pages/login";
import Signup from "../pages/signup";
import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import CompanySettings from "../pages/companySettings";
import ProfileSettings from "../pages/profileSettings";
import SettingsPage from "../pages/settings";
import Profile from "../pages/profile";
import Error from "../pages/page404";
import DriverForm from "../pages/drivers/driversForm";
import Documents from "../pages/documents";
import { useAppSelector } from "src/hooks/reduxHooks";
import { UserPermissions } from "src/interfaces/user";
import { pending, driver } from "src/utils/enums";
import { vehicleRoutes } from "./vehicleRoutes";
import { loadRoutes } from "./loadRoutes";
import { driverRoutes } from "./driverRoutes";
import { ownerRoutes } from "./ownerRoutes";
import { terminalRoutes } from "./terminalRoutes";
import { trailerRoutes } from "./trailerRoutes";
import { lineItemRoutes } from "./lineItemRoutes";
import { commodityRoutes } from "./commodityRoutes";
import { securityGroupRoutes } from "./securityGroupRoutes";
import { documentTypeRoutes } from "./documentTypeRoutes";
import { customerRoutes } from "./customerRoutes";
import { settlementRoutes } from "./settlementRoutes";
import { userRoutes } from "./userRoutes";
import { lookupRoutes } from "./lookupRoutes";
import { settlementCodesRoutes } from "./settlementCodeRoutes";
import { navBarRoutes } from "./navBarRoutes";
import {
  selectIsAuthenticated,
  selectCompany,
  selectUser,
  selectUserPermissions,
} from "src/redux/reducers/auth";

export interface RouteProps {
  component: React.FC<any>;
  exact: boolean;
  path: string;
}

const routes = (
  isLoggedIn: boolean,
  company: any,
  role: string,
  permissions: UserPermissions
) => [
    {
      path: "/auth",
      element: !isLoggedIn ? <PublicLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: "/auth", element: <Navigate to="/auth/login" /> },
        { path: "/auth/login", exact: true, element: <Login /> },

        { path: "/auth/login/:tenant", exact: true, element: <Login /> },
        { path: "/auth/signup", exact: true, element: <Signup /> },
        {
          path: "/auth/forgot-password",
          exact: true,
          element: <ForgotPassword />,
        },
        {
          path: "/auth/reset-password",
          exact: true,
          element: <ResetPassword />,
        },
        {
          path: "*",
          element: <Navigate to="/auth/signup" />,
        },
      ],
    },
    // {
    //   path: "/settings",
    //   element: !isLoggedIn ? <PublicLayout /> : <Navigate to="/dashboard" />,
    //   children: [
    //     {
    //       path: "/company-config",
    //       element: isLoggedIn ? (
    //         company?.status !== pending ? (
    //           <Navigate to="/" />
    //         ) : (
    //           <CompanySettings />
    //         )
    //       ) : (
    //         <Navigate to="/auth/login" />
    //       ),
    //     },
    //     {
    //       path: "/profile",

    //       element: isLoggedIn ? (
    //         company?.status !== pending ? (
    //           <Navigate to="/" />
    //         ) : (
    //           <ProfileSettings />
    //         )
    //       ) : (
    //         <Navigate to="/auth/login" />
    //       ),
    //     },
    //   ]
    // },
    {
      path: "/",
      element: isLoggedIn ? <FullLayout /> : <Navigate to="/auth/login" />,
      children:
        role === driver
          ? [
            { path: "/", element: <Navigate to="/dashboard" /> },
            { path: "/user/profile", exact: true, element: <Profile /> },
            { path: "/dashboard", exact: true, element: <Dashboard /> },
            { path: "/update-info", exact: true, element: <DriverForm /> },
            { path: "/details", exact: true, element: <DriverForm /> },
            {
              path: "/documents",
              exact: true,
              element: <Documents />,
            },
            {
              path: "*",
              element: <Navigate to="/404" />,
            },
          ]
          : [
            ...vehicleRoutes(role, permissions),
            ...loadRoutes(role, permissions),
            ...driverRoutes(role, permissions),
            ...ownerRoutes(role, permissions),
            ...terminalRoutes(role, permissions),
            ...trailerRoutes(role, permissions),
            ...lineItemRoutes(role, permissions),
            ...commodityRoutes(role, permissions),
            ...securityGroupRoutes(role, permissions),
            ...documentTypeRoutes(role, permissions),
            ...customerRoutes(role, permissions),
            ...userRoutes(role, permissions),
            ...lookupRoutes(role, permissions),
            ...settlementRoutes(role, permissions),
            ...settlementCodesRoutes(role, permissions),
            ...navBarRoutes(role, permissions),

            { path: "/", element: <Navigate to="/dashboard" /> },
            { path: "/dashboard", exact: true, element: <Dashboard /> },
            /*  {
              path: "/settlements",
              exact: true,
              element: <Settlements />,
          }, */

            //  { path: "/billing", exact: true, element: <Billing /> },
            {
              path: "/billing/waiting-for-document",
              exact: true,
              element: <WaitingForDocument />,
            },
            {
              path: "/billing/freight-bill-processing-queue",
              exact: true,
              element: <FreightBillProcessingQueue />,
            },
            {
              path: "/billing/freight-bill-print-queue",
              exact: true,
              element: <FreightBillPrintQueue />,
            },
            {
              path: "/billing/factoring-invoices",
              exact: true,
              element: <FactoringInvoices />,
            },
            {
              path: "/billing/dashboard",
              exact: true,
              element: <BillingDashboard />,
            },
            {
              path: "/settings",
              exact: true,
              element: <SettingsPage />,
            },
            { path: "/user/profile", exact: true, element: <Profile /> },

            {
              path: "*",
              element: <Navigate to="/404" />,
            },
          ],
    },
    {
      path: "/404",
      element: <Error />,
    },
  ];

const Routes = (): JSX.Element => {
  const isLoggedIn = useAppSelector(selectIsAuthenticated);
  const company = useAppSelector(selectCompany);
  const user = useAppSelector(selectUser);
  const allUserPermissions = useAppSelector(selectUserPermissions);
  const userRole = user?.role?.name
    ? user?.role?.name?.toLocaleLowerCase()
    : "";
  const routing = useRoutes(
    routes(isLoggedIn, company, userRole, allUserPermissions)
  );

  return (
    <Grid container>
      <CssBaseline />
      {routing}
    </Grid>
  );
};

export default Routes;
