import { store } from "../redux/store";
import { phone } from "phone";

export const reduxStateSupplier = () => store.getState();
export const reduxState = reduxStateSupplier();

export function typedKeys<T>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}
export const sanitizeEntity = (data: any) => {
  const object: any = { ...data };
  const allAttributes = typedKeys(object);
  for (const attribute of allAttributes) {
    if (typeof object[attribute] !== "boolean") {
      if (typeof object[attribute] === "object") {
        if (Array.isArray(object[attribute])) {
          const sanitizedArray = [];
          for (const instance of object[attribute]) {
            if (typeof instance === "object") {
              sanitizedArray.push(sanitizeEntity(instance));
              object[attribute] = [...sanitizedArray];
            }
          }
        } else {
          if (
            object[attribute] !== null &&
            !(object[attribute] instanceof Date)
          ) {
            object[attribute] = sanitizeEntity(object[attribute]);
          }
        }
      } else {
        object[attribute] = object[attribute] ? object[attribute] : null;
      }
    }
  }
  return object;
};
export const formattedPhoneNumber = (
  value: string,
  country: string
): string | null => {
  return phone(value, { country: country })?.phoneNumber;
};
export const validatePhoneNumber = (
  value: string,
  country: string
): boolean => {
  if (phone(value, { country: country })?.phoneNumber) {
    return true;
  } else {
    return false;
  }
};
