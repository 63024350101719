import React, { useEffect, useState } from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { lineHauls } from "src/utils/dropdowns/load";
import {
  Checkbox,
  MenuItem,
  Typography,
  FormControlLabel
} from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { FreightBill as FreightBillObj } from "src/interfaces/loadManagement";
import { perLoad, perMile } from "src/utils/enums";

interface FreightBillProps {
  errors?: any,
  control: any,
  setValue?: any,
  watch?: any,
  data?: FreightBillObj
}

const FreightBill = ({
  errors,
  control,
  setValue,
  watch,
  data
}: FreightBillProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();

  useEffect(() => {
    setValue("freightBill.lineHaul", perLoad);
  }, [])

  return (
    <React.Fragment>
      <Typography className={classes.loadFormHeadings}>
        Freight & Fuel Bill
      </Typography>
      <CustomFormGrid container spacing={2}>
        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="freightBill.lineHaul"
            control={control}
            defaultValue={data?.lineHaul || ""}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                inputProps={{ min: 0 }}
                fullWidth
                select
                id="lineHaul"
                label={translation("load.form.lineHaul.label")}
                error={Boolean(errors?.freightBill?.lineHaul)}
                helperText={
                  errors?.freightBill?.lineHaul && translation("load.form.lineHaul.message")
                }
              >
                {lineHauls &&
                  lineHauls.map((item, index) => (
                    <MenuItem value={item.value} key={index}>
                      {item.label}
                    </MenuItem>
                  ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="freightBill.rate"
            control={control}
            defaultValue={data?.rate}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                fullWidth
                type="number"
                id="rate"

                onKeyPress={(event: any) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
                onChange={(event: any) =>
                  event.target.value < 0
                    ? field.onChange(event.target.value = 0)
                    : field.onChange(event.target.value)
                }
                label={translation("load.form.rate.label")}
                error={Boolean(errors?.freightBill?.rate)}
                helperText={
                  errors?.freightBill?.rate && translation("load.form.rate.message")
                }
              />
            )}
          />{" "}

        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={4} lg={4}>
          <Controller
            name="freightBill.mileage"
            control={control}
            defaultValue={data?.mileage}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                inputProps={{ min: 0 }}
                fullWidth
                type="number"
                id="mileage"
                onKeyPress={(event: any) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
                onChange={(event: any) =>
                  event.target.value < 0
                    ? field.onChange(event.target.value = 0)
                    : field.onChange(event.target.value)
                }
                label={translation("load.form.calcMiles.label")}
                error={Boolean(errors?.freightBill?.mileage)}
                helperText={
                  errors?.freightBill?.mileage && translation("load.form.calcMiles.message")
                }
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>

      <CustomFormGrid container spacing={2}>
        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="freightBill.readyToBill"
            control={control}
            defaultValue={data?.readyToBill}
            render={({ field }) => (
              <FormControlLabel
                label={translation("load.form.readyToBill.label").toString()}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}

                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="freightBill.combineBilling"
            control={control}
            defaultValue={data?.combineBilling}
            render={({ field }) => (
              <FormControlLabel
                label={translation("load.form.combineBilling.label").toString()}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}

                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }

              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="freightBill.blockTerminalCommission"
            control={control}
            defaultValue={data?.blockTerminalCommission}
            render={({ field }) => (
              <FormControlLabel
                label={translation("load.form.blockTerminalCommission.label").toString()}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}

                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="freightBill.billWithoutRenditionDocuments"
            control={control}
            defaultValue={data?.billWithoutRenditionDocuments}
            render={({ field }) => (
              <FormControlLabel
                label={translation("load.form.billWithoutRendition.label").toString()}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}

                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </React.Fragment>
  );
};

export default FreightBill;
