import axios from "axios";
import { API_URL } from "./constants";

const baseURL = process.env.NODE_ENV === "development" ? API_URL : API_URL;

console.log("Base Url:", baseURL, process.env.REACT_APP_BASE_URL);

export default axios.create({
  baseURL: baseURL,
  responseType: "json",
  headers: {
    "Content-type": "application/json",
    "Accept-Language":
      localStorage.getItem("locale") !== null
        ? `${localStorage.getItem("locale")}`
        : "en",
  },
});
