import React from "react";
import { Navigate } from "react-router-dom";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import { UserPermissions } from "src/interfaces/user";

import { admin, featureLookups, create, update } from "src/utils/enums";

export const lookupRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);

  return [
    {
      path: "/config/lookups",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "config/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
  ];
};
