import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { LineItemProps } from "src/interfaces/lineItems";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLineItem } from "src/redux/reducers/lineItems";
import { Controller } from "react-hook-form";
import CustomTextField from "src/components/customElements/customTextField";
import CustomSwitch from "../customElements/customSwitch";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { typedKeys } from "src/utils/common";
import { ArrowBack } from "@mui/icons-material";

interface LineItemsFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: LineItemProps) => any; */
  onSubmit: (data: any) => any;
}
const LineItemForm = ({
  loading,
  pageLoading,
  onSubmit,
}: LineItemsFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const lineItem = useAppSelector(selectLineItem);


  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const setFormValues = (key: keyof LineItemProps) => {
    if (lineItem) {
      if (typeof lineItem[key] === "boolean") {
        setValue(key, lineItem[key]);
      } else {
        setValue(key, lineItem[key] ? lineItem[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.id && lineItem) {
      const allAttributes = typedKeys(lineItem);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
      }
    }
  }, [params?.id, lineItem]);

  return (
    <div>
      <Card variant="outlined" className={classes.formContainer}>
        <Box className={classes.formTitleContainer}>
          <Box flexGrow={1} display="flex" alignItems="center">
            <Button>
              <ArrowBack onClick={() => navigate(-1)} />
            </Button>
            <Typography className={classes.formTitle}>
              {translation("lineItem.form.title")}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent className={classes.formContentContainer}>
          {pageLoading ? (
            <CircularProgress />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
                <CustomFormGrid container>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="code"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="code"
                          label={translation("lineItem.form.code.label")}
                          error={Boolean(errors?.code)}
                          helperText={
                            errors?.code &&
                            translation("lineItem.form.code.message")
                          }
                          required
                        />
                      )}
                    />
                  </CustomFormGrid>

                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="title"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="title"
                          label={translation("lineItem.form.title.label")}
                          error={Boolean(errors?.title)}
                          helperText={
                            errors?.title &&
                            translation("lineItem.form.title.message")
                          }
                          required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="rate"
                      control={control}
                      defaultValue={""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="rate"
                          type="number"
                          inputProps={{ min: 0 }}
                          label={translation("lineItem.form.defaultRate.label")}
                          error={Boolean(errors?.rate)}
                          helperText={
                            errors?.rate &&
                            translation("lineItem.form.defaultRate.message")
                          }
                          required
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
                    <Controller
                      name="GL_account"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="GL_account"
                          label={translation(
                            "lineItem.form.quickBooksGlAccount.label"
                          )}
                          error={Boolean(errors?.GL_account)}
                          helperText={
                            errors?.GL_account &&
                            translation(
                              "lineItem.form.quickBooksGlAccount.message"
                            )
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid
                    item
                    sm={6}
                    xs={12}
                    md={3}
                    lg={3}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Controller
                      name="isSystemUseOnly"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <>
                          <InputLabel>
                            {translation("lineItem.form.systemUseOnly.label")}
                          </InputLabel>
                          <CustomSwitch
                            checked={
                              watch("isSystemUseOnly")
                                ? watch("isSystemUseOnly")
                                : false
                            }
                            {...field}
                            id="isSystemUseOnly"
                          />
                        </>
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid
                    item
                    sm={12}
                    xs={12}
                    md={9}
                    lg={9}
                  ></CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          multiline
                          rows={3}
                          id="description"
                          label={translation("lineItem.form.description.label")}
                          error={Boolean(errors?.description)}
                          helperText={
                            errors?.description &&
                            translation("lineItem.form.description.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                    <Controller
                      name="notes"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <CustomTextField
                          {...field}
                          size="small"
                          fullWidth
                          id="notes"
                          multiline
                          rows={3}
                          label={translation(
                            "lineItem.form.lineItemNotes.label"
                          )}
                          error={Boolean(errors?.notes)}
                          helperText={
                            errors?.notes &&
                            translation("lineItem.form.lineItemNotes.message")
                          }
                        />
                      )}
                    />
                  </CustomFormGrid>
                  <CustomFormGrid container justifyContent="center">
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.formButton}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : params?.id ? (
                        translation("common.form.update")
                      ) : (
                        translation("common.form.add")
                      )}
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      className={classes.formButton}
                      onClick={() => navigate(-1)}
                    >
                      {translation("common.btn.cancel")}
                    </Button>
                  </CustomFormGrid>
                </CustomFormGrid>
              </CustomForm>
            </LocalizationProvider>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default LineItemForm;
