import React from "react";
import {
  Button,
  Grid,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useStyles } from "src/styles/formStyles";

interface LookupFormProps {
    loading: boolean;
    onSubmit: (data: any) => any;
  featureKey: string;
  handleClose: () => void;
  fieldName: any;
}

const LookupForm = ({
  loading,
  onSubmit,
  handleClose,
  fieldName,
}: LookupFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
      <div>
            <CustomForm
              noValidate
              alignItems="center"
        justifyContent="center"
        style={{paddingTop:"5px"}}
           //  onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container direction="column">
                <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>

                        <CustomTextField
                        //  {...field}
                          id="feature"
                          fullWidth
                          size="small"
                          label={translation("lookup.feature.label")}
                          helperText={
                            errors?.feature &&
                            translation("lookup.feature.message")
                          }
                          defaultValue={fieldName}
                          disabled
                          required
                           error={Boolean(errors?.feature)}
                          // inputProps={{ style: { textTransform: "uppercase" } }}
                        >
                        </CustomTextField>


                </CustomFormGrid>
                <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
                  <Controller
                    name="content"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <CustomTextField
                          {...field}
                          id="content"
                          label={translation("lookup.content.label")}
                          fullWidth
                          size="small"
                          error={Boolean(errors?.content)}
                          helperText={
                            errors?.content &&
                            translation("lookup.content.message")
                          }
                          required
                        />
                      </>
                    )}
                  />
                </CustomFormGrid>
              </Grid>
              <CustomFormGrid container justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className={classes.formButton}

                >
            {loading ? (
                      <CircularProgress
                        style={{ color: "#fff", height: "20px", width: "20px" }}
                      />
                    ) :  (
                      translation("common.form.add")
                    )}


                </Button>
                <Button
                  color="error"
                  variant="contained"
                  className={classes.formButton}
                  onClick={() => handleClose()}
                >
                  {translation("common.btn.cancel")}
                </Button>
              </CustomFormGrid>
            </CustomForm>


    </div>
  );
};

export default LookupForm;
