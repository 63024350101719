import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import {
  LookupProps,
  LookupTypes,
  LookupListProps,
} from "src/interfaces/lookup";

export interface LookupState {
  lookupsList: any;
  singleLookup: LookupProps | null;
  lookupsFeature: Array<LookupTypes>;
}
interface OrderedLookupPayload {
  data: { lookups: Array<LookupProps>; count: number };
  type: string;
}
const initialState: LookupState = {
  lookupsList: {},
  lookupsFeature: [],
  singleLookup: null,
};

export const lookupsSlice = createSlice({
  name: "lookups",
  initialState,

  reducers: {
    setAllLookups: (state, action: PayloadAction<LookupListProps>) => {
      state.lookupsList = action.payload;
    },
    setOrderedLookups: (state, action: PayloadAction<OrderedLookupPayload>) => {
      if (state.lookupsList[action.payload.type]) {
        state.lookupsList[action.payload.type] = action.payload.data;
      }
    },
    setSingleLookup: (state, action: PayloadAction<LookupProps>) => {
      state.singleLookup = action.payload;
    },
    resetLookup: (state) => {
      state.singleLookup = null;
    },
    setAllLookupsFeatures: (
      state,
      action: PayloadAction<Array<LookupTypes>>
    ) => {
      state.lookupsFeature = action.payload;
    },
    resetLookupsState: (state) => {
      state.lookupsFeature = [];
      state.singleLookup = null;
      state.lookupsList = {};
    },
  },
});
export const {
  setAllLookups,
  setSingleLookup,
  resetLookup,
  setAllLookupsFeatures,
  resetLookupsState,
  setOrderedLookups,
} = lookupsSlice.actions;
export const selectLookups = (state: RootState): any =>
  state.lookups.lookupsList;
export const selectLookup = (state: RootState): LookupProps | null =>
  state.lookups.singleLookup;
export const selectLookupsFeatures = (state: RootState): Array<LookupTypes> =>
  state.lookups.lookupsFeature;

export default lookupsSlice.reducer;
