import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DocumentProps } from "src/interfaces/documents";

export interface DriverState {
  documentsList: any;
  progress: number;
}

const initialState: DriverState = {
  documentsList: {},
  progress: 0,
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,

  reducers: {
    setAllDocuments: (state, action: PayloadAction<Array<DocumentProps>>) => {
      state.documentsList = action.payload;
    },
    appendDocumentsList: (state, action: PayloadAction<DocumentProps>) => {
      state.documentsList = [...state.documentsList, action.payload];
      state.progress = 0;
    },
    filterDocumentsList: (state, action: PayloadAction<number>) => {
      const _list = state.documentsList.filter(
        (item: any) => item.id !== action.payload
      );
      state.documentsList = [..._list];
    },
    updateDocumentsList: (
      state,
      action: PayloadAction<{ data: DocumentProps; id: number }>
    ) => {
      const _list = [...state.documentsList];
      const index = _list.findIndex((item) => item.id === action.payload.id);
      if (index > -1) {
        state.documentsList[index] = { ...action.payload.data };
      }
    },
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    resetUploadProgress: (state) => {
      state.progress = 0;
    },

    resetDocumentsState: (state) => {
      state.documentsList = [];
      state.progress = 0;
    },
  },
});
export const {
  setAllDocuments,
  updateDocumentsList,
  appendDocumentsList,
  setUploadProgress,
  filterDocumentsList,
  resetUploadProgress,
} = documentsSlice.actions;

export const selectDocuments = (state: RootState): any =>
  state.documents.documentsList;
export const selectUploadProgress = (state: RootState): number =>
  state.documents.progress;

export default documentsSlice.reducer;
