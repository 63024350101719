import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import {
  SecurityGroupProps,
  PermissionProps,
} from "src/interfaces/securityGroups";

export interface SecurityGroupState {
  securityGroupsList: any;
  groupedSecurityGroups: Array<any>;
  permissionsList: Array<PermissionProps>;
  singleSecurityGroup: SecurityGroupProps | null;
}

const initialState: SecurityGroupState = {
  securityGroupsList: {},
  permissionsList: [],
  singleSecurityGroup: null,
  groupedSecurityGroups: [],
};

export const securityGroupsSlice = createSlice({
  name: "securityGroups",
  initialState,

  reducers: {
    setAllSecurityGroups: (state, action: any) => {
      let securityGroupObj: any = {};
      const securityGroupArray: any = [];
      let permissionsArray: any = [];
      for (const securityGroup of action.payload.data) {
        securityGroupObj = {};
        permissionsArray = [];

        for (const permission of securityGroup.securityGroupPermissions) {
          if (typeof permission === "object") {
            if (securityGroupObj[permission.permission.featureName]) {
              permissionsArray =
                securityGroupObj[permission.permission.featureName];
            } else {
              permissionsArray = [];
            }

            permissionsArray.push({
              ...permission.permission,
            });
            securityGroupObj[permission.permission.featureName] = [
              ...permissionsArray,
            ];
          }
        }
        securityGroupArray.push({
          name: securityGroup.name,
          id: securityGroup.id,
          securityGroupPermissions: securityGroupObj,
        });
      }

      state.securityGroupsList = action.payload;
      state.groupedSecurityGroups = securityGroupArray;
    },
    setAllPermissions: (
      state,
      action: PayloadAction<Array<PermissionProps>>
    ) => {
      state.permissionsList = action.payload;
    },
    setSingleSecurityGroup: (
      state,
      action: PayloadAction<SecurityGroupProps>
    ) => {
      state.singleSecurityGroup = action.payload;
    },
    resetSecurityGroup: (state) => {
      state.singleSecurityGroup = null;
    },
    resetSecurityGroupsState: (state) => {
      state.singleSecurityGroup = null;
      state.securityGroupsList = {};
      state.permissionsList = [];
    },
  },
});

export const {
  setAllSecurityGroups,
  setSingleSecurityGroup,
  resetSecurityGroup,
  setAllPermissions,
  resetSecurityGroupsState,
} = securityGroupsSlice.actions;

export const selectSecurityGroups = (state: RootState): any =>
  state.securityGroup.securityGroupsList;

export const selectAllPermissions = (
  state: RootState
): Array<PermissionProps> => state.securityGroup.permissionsList;

export const selectSecurityGroup = (
  state: RootState
): SecurityGroupProps | null => state.securityGroup.singleSecurityGroup;

export const selectGroupedSecurityGroup = (state: RootState): Array<any> =>
  state.securityGroup.groupedSecurityGroups;

export default securityGroupsSlice.reducer;
