import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Typography } from "@mui/material";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";

interface PhoneInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string, data: any) => void;
  isValid?: (value: any, country: any) => string | boolean;
  error?: boolean;
  disabled?: boolean;
  label?: any;
  country: string;
}
const CommonPhoneInput = ({
  placeholder,
  value,
  onChange,
  isValid,
  error,
  disabled,
  label,
  country,
}: PhoneInputProps): JSX.Element => {
  const classes = useStyles();
  const [translation] = useTranslation<string>("translation");

  return (
    <>
      <PhoneInput
        placeholder={placeholder}
        inputClass={classes.phoneInputContainer}
        dropdownClass={classes.phoneDropDownContainer}
        searchClass={classes.searchClass}
        buttonClass={classes.buttonClass}
        disabled={disabled}
        specialLabel={label}
        country={country ? country : ""}
        value={value ? value : ""}
        onChange={(value: string, data: any) => {
          onChange(value, data);
        }}
        isValid={
          isValid
            ? (value: any, country: any) => {
                return isValid(value, country);
              }
            : undefined
        }
      />
      {error && (
        <Typography className={classes.error}>
          {translation("common.phone.message")}
        </Typography>
      )}
    </>
  );
};

export default CommonPhoneInput;
