import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import OwnerForm from "src/components/owner/form";
import OwnerReadForm from "src/components/owner/ownerReadForm";
import { OwnerProps } from "src/interfaces/owner";
import { getOwnerById, addOwner, updateOwner } from "src/redux/actions/owner";
import { getAllTerminals } from "src/redux/actions/terminal";
import { resetOwner } from "src/redux/reducers/owner";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { sanitizeEntity } from "src/utils/common";
interface OwnerFormProps {
  handleClose?: () => any;
}
const OwnerFormContainer = ({ handleClose }: OwnerFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const url = location.pathname.includes("owners");

  useEffect(() => {
    dispatch(resetOwner());
  }, []);

  useEffect(() => {
    if (params?.ownerId) {
      getSingleOwner(parseInt(params?.ownerId));
    }
  }, [params?.ownerId]);

  useEffect(() => {
    dispatch(getAllTerminals(0, 1));
  }, []);

  const getSingleOwner = async (id: number) => {
    setPageLoading(true);
    await dispatch(getOwnerById(id));
    setPageLoading(false);
  };
  /* OwnerProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: OwnerProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    if (params?.ownerId) {
      //update
      await dispatch(updateOwner(objToSubmit, parseInt(params?.ownerId)));
    } else {
      //add
      const owner = await dispatch(addOwner(objToSubmit));
      if (url && owner.success && owner.data.id) {
        // setTimeout(() => navigate(`/drivers/owners/${owner.data.id}`), 500);
        setTimeout(() => navigate("/drivers/owners"), 500);
      } else if (owner.success && owner.data.id && handleClose) {
        handleClose();
      }
    }

    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <OwnerReadForm
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
          // handleClose={handleClose}
          // url={url}
        />
      </Grid>
    </Grid>
  );
};

export default OwnerFormContainer;
