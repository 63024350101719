import React, { useEffect } from "react";
import { Button, InputLabel } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { emailRegEx } from "src/utils/constants";
import { DatePicker } from "@mui/lab";
import CustomSwitch from "src/components/customElements/customSwitch";
import { CustomerBillingProps } from "src/interfaces/customer";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import { useForm } from "react-hook-form";
import { selectCustomerBilling } from "src/redux/reducers/customerBilling";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { typedKeys } from "src/utils/common";
import { phoneRegEx } from "src/utils/constants";
import { featureCustomers } from "src/utils/enums";
import AddLookupField from "src/components/common/addLookupFeild";

interface CustomerBillingInfoProps {
  loading: boolean;
 /*  onCustomerBillingSubmit: (data: CustomerBillingProps) => any; */
  onCustomerBillingSubmit: (data: any) => any;
  parentExpandAll?: boolean;
}
const CustomerBilling = ({
  loading,
  onCustomerBillingSubmit,
}: CustomerBillingInfoProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [translation] = useTranslation<string>("translation");
  const customerBilling = useAppSelector(selectCustomerBilling);
  const allLookups = useAppSelector(selectLookups);
  const customerCreditStatus: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["credit-status"]?.lookups
      ? allLookups?.data["credit-status"]?.lookups
      : [];
  const customerCreditRating: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["credit-rating"]?.lookups
      ? allLookups?.data["credit-rating"]?.lookups
      : [];
  const customerNetTerms: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["net-terms"]?.lookups
      ? allLookups?.data["net-terms"]?.lookups
      : [];
  const params = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const setFormValues = (key: keyof CustomerBillingProps) => {
    if (customerBilling) {
      if (typeof customerBilling[key] === "boolean") {
        setValue(key, customerBilling[key]);
      } else {
        setValue(key, customerBilling[key] ? customerBilling[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.customerId && customerBilling) {
      const allAttributes = typedKeys(customerBilling);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
        if (attribute === "creditStatus") {
          if (typeof customerBilling?.creditStatus === "object") {
            setValue(
              "creditStatus",
              customerBilling?.creditStatus?.id
                ? customerBilling?.creditStatus?.id
                : ""
            );
          } else {
            setValue(
              "creditStatus",
              customerBilling?.creditStatus ? customerBilling?.creditStatus : ""
            );
          }
        } else if (attribute === "creditRating") {
          if (typeof customerBilling?.creditRating === "object") {
            setValue(
              "creditRating",
              customerBilling?.creditRating?.id
                ? customerBilling?.creditRating?.id
                : ""
            );
          } else {
            setValue(
              "creditRating",
              customerBilling?.creditRating ? customerBilling?.creditRating : ""
            );
          }
        } else if (attribute === "netTerms") {
          if (typeof customerBilling?.netTerms === "object") {
            setValue(
              "netTerms",
              customerBilling?.netTerms?.id ? customerBilling?.netTerms?.id : ""
            );
          } else {
            setValue(
              "netTerms",
              customerBilling?.netTerms ? customerBilling?.netTerms : ""
            );
          }
        } else {
          setFormValues(attribute);
        }
      }
    }
  }, [params?.id, customerBilling]);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CustomForm noValidate onSubmit={handleSubmit(onCustomerBillingSubmit)}>
          <CustomFormGrid container>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="federalId"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="federalId"
                    label={translation("customer.form.federalID.label")}
                    error={Boolean(errors?.federalId)}
                    helperText={
                      errors?.federalId &&
                      translation("customer.form.federalID.message")
                    }
                  ></CustomTextField>
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="SIC_Code"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="SIC_Code"
                    label={translation("customer.form.SICcode.label")}
                    error={Boolean(errors?.SIC_Code)}
                    helperText={
                      errors?.SIC_Code &&
                      translation("customer.form.SICcode.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="MC_Code"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="MC_Code"
                    label={translation("customer.form.MCCode.label")}
                    error={Boolean(errors?.MC_Code)}
                    helperText={
                      errors?.MC_Code &&
                      translation("customer.form.MCCode.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="DUNS_Number"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    type="number"
                    id="DUNS_Number"
                    label={translation("customer.form.dunsNumber.label")}
                    error={Boolean(errors?.DUNS_Number)}
                    helperText={
                      errors?.DUNS_Number &&
                      translation("customer.form.dunsNumber.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="DUNS_Exp_Date"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={translation(
                      "customer.form.dunsExpirationDate.label"
                    )}
                    disablePast
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params: any) => (
                      <CustomTextField
                        {...params}
                        {...field}
                        size="small"
                        fullWidth
                        id="DUNS_Exp_Date"
                        error={Boolean(errors?.DUNS_Exp_Date)}
                        helperText={
                          errors?.DUNS_Exp_Date &&
                          translation(
                            "customer.form.dunsExpirationDate.message"
                          )
                        }
                      />
                    )}
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="faxPhoneNumber"
                control={control}
                defaultValue={""}
                rules={{ pattern: phoneRegEx }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="faxPhoneNumber"
                    label={translation("customer.form.billingFaxNumber.label")}
                    error={Boolean(errors?.faxPhoneNumber)}
                    helperText={
                      errors?.faxPhoneNumber &&
                      translation("customer.form.billingFaxNumber.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="email"
                control={control}
                defaultValue={""}
                rules={{ pattern: emailRegEx}}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="email"
                    label={translation("common.email.label")}
                    error={Boolean(errors?.email)}
                    helperText={
                      errors?.email && translation("common.email.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            {/* <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="creditStatus"
                control={control}
                defaultValue={""}
                rules={{required: true}}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="creditStatus"
                    label={translation("customer.form.creditStatus.label")}
                    error={Boolean(errors?.creditStatus)}
                    helperText={
                      errors?.creditStatus &&
                      translation("customer.form.creditStatus.message")
                    }
                    select
                    required
                  >
                    {customerCreditStatus.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              />
            </CustomFormGrid> */}
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <AddLookupField
              name={"creditStatus"}
              control={control}
              rules={{ required: false }}
              id="creditStatus"
              label={translation("customer.form.creditStatus.label")}
              errors={Boolean(errors?.creditStatus)}
              lookupsArray={customerCreditStatus}
             // linkingAddress={linkingAddress}
              helperText={
                errors?.make && translation("customer.form.creditStatus.message")
              }
              fieldRequired={true}
             // disabled={disabled}
              featureKey="credit-status"
              lookupFeatureEnum={featureCustomers}
            />
            </CustomFormGrid>
          {/*   <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="creditRating"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    select
                    id="creditRating"
                    label={translation("customer.form.creditRating.label")}
                    error={Boolean(errors?.creditRating)}
                    helperText={
                      errors?.creditRating &&
                      translation("customer.form.creditRating.message")
                    }
                  >
                    {customerCreditRating.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              />
            </CustomFormGrid> */}
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <AddLookupField
              name={"creditRating"}
              control={control}
              rules={{ required: false }}
              id="creditRating"
              label={translation("customer.form.creditRating.label")}
              errors={Boolean(errors?.creditRating)}
              lookupsArray={customerCreditRating}
             // linkingAddress={linkingAddress}
              helperText={
                errors?.make && translation("customer.form.creditRating.message")
              }
              fieldRequired={false}
             // disabled={disabled}
              featureKey="credit-rating"
              lookupFeatureEnum={featureCustomers}
            />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="creditLimit"
                control={control}
                defaultValue={""}
             //   rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="creditLimit"
                    label={translation("customer.form.creditLimit.label")}
                    error={Boolean(errors?.creditLimit)}
                    helperText={
                      errors?.creditLimit &&
                      translation("customer.form.creditLimit.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="creditWarning"
                control={control}
                defaultValue={""}
                // rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="creditWarning"
                    label={translation("customer.form.creditWarning.label")}
                    error={Boolean(errors?.creditWarning)}
                    helperText={
                      errors?.creditWarning &&
                      translation("customer.form.creditWarning.message")
                    }
                  />
                )}
              />
            </CustomFormGrid>
            <CustomFormGrid
              item
              sm={6}
              xs={12}
              md={3}
              lg={3}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Controller
                name="enforceCreditLimit"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <>
                    <InputLabel>
                      {translation("customer.form.enforceCreditLimit.label")}
                    </InputLabel>
                    <CustomSwitch
                      checked={
                        watch("enforceCreditLimit")
                          ? watch("enforceCreditLimit")
                          : false
                      }
                      {...field}
                      id="enforceCreditLimit"
                    />
                  </>
                )}
              />
            </CustomFormGrid>
         {/*    <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
              <Controller
                name="netTerms"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    id="netTerms"
                    label={translation("customer.form.netTerms.label")}
                    error={Boolean(errors?.netTerms)}
                    helperText={
                      errors?.netTerms &&
                      translation("customer.form.netTerms.message")
                    }
                    select
                  >
                    {customerNetTerms.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                )}
              />
            </CustomFormGrid> */}
            <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
            <AddLookupField
              name={"netTerms"}
              control={control}
              rules={{ required: false }}
              id="netTerms"
              label={translation("customer.form.netTerms.label")}
              errors={Boolean(errors?.netTerms)}
              lookupsArray={customerNetTerms}
             // linkingAddress={linkingAddress}
              helperText={
                errors?.make && translation("customer.form.netTerms.message")
              }
              fieldRequired={false}
             // disabled={disabled}
              featureKey="net-terms"
              lookupFeatureEnum={featureCustomers}
            />
            </CustomFormGrid>
          </CustomFormGrid>
          <CustomFormGrid container justifyContent="center">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />
              ) : params?.id ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={() => navigate(-1)}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomForm>
      </LocalizationProvider>
    </div>
  );
};

export default CustomerBilling;
