import { AppDispatch } from "../store";
import { removeLinkedTrailer, addLinkedTrailer } from "../reducers/vehicle";
import { removeLinkedVehicle, addLinkedVehicle } from "../reducers/trailer";
import { VehicleTrailerProps } from "src/interfaces/vehicle";
import { postServerData, patchServerData } from "../api/index";

export const addVehicleTrailer =
  (data: VehicleTrailerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/vehicle-trailer`, data));
    if (Array.isArray(response?.data?.data)) {
      dispatch(addLinkedTrailer(response?.data?.data[0]?.trailer));
      dispatch(addLinkedVehicle(response?.data?.data[0]?.vehicle));
      // dispatch(setSingleVehicleTrailer(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const updateVehicleTrailer =
  (data: VehicleTrailerProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/vehicle-trailer/unlink`, data)
    );

    if (response?.data?.data) {
      dispatch(removeLinkedTrailer());
      dispatch(removeLinkedVehicle());
    }
    return { success: response.success, data: response?.data };
  };
