import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Grid, Card, CardContent, Typography, InputAdornment, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { emailRegEx } from "src/utils/constants";
import { selectCompaniesList } from "src/redux/reducers/auth";
import { useAppSelector } from "src/hooks/reduxHooks";
import CustomTextField from "src/components/customElements/customTextField";
import { CompanyProps } from "src/interfaces/company";
import logo from "src/assets/img/tms_logo.png";

interface LoginProps {
  loading: boolean;
  companyData: any;
  /*  onSubmit: (data: LoginCredProps) => any; */
  onSubmit: (data: any) => any;
}

const LogIn = ({ loading, onSubmit, companyData }: LoginProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-container">
      <div className="auth-align-middle auth-main">
        <div className="auth-box">
          <div className="top">
            <img src={logo} alt="" />
          </div>
          <Card sx={{ borderRadius: "5px", m: 0 }}>
            <CardContent>
              <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                Login to your account
              </Typography>

              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <Controller
                  name="identifier"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true, pattern: emailRegEx }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      size="small"
                      label={translation("login.email.label")}
                      error={Boolean(errors?.identifier)}
                      helperText={
                        errors?.identifier && translation("login.email.message")
                      }
                      required
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="password"
                      size="small"
                      label={translation("login.password.label")}
                      type={showPassword ? "text" : "password"}
                      error={Boolean(errors?.password)}
                      helperText={
                        errors?.password && translation("login.password.message")
                      }
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  id="login"
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", height: "20px", width: "20px" }}
                    />
                  ) : (
                    translation("login.submit")
                  )}
                </Button>
                <Grid
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ textAlign: "center", mt: 2 }}
                >
                  <Grid item >
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <LockIcon fontSize="inherit" sx={{ mr: 1 }} />
                      <Link to="/auth/forgot-password" state={{ companyData }}>Forgot Password?</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Don&apos;t have an account?&nbsp;
                      <Link to="/auth/signup">Register</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
    // <Grid container component={Paper} className={classes.root}>
    //   <Grid item xs={false} sm={4} md={7} className={classes.image} />
    //   <Grid item xs={12} sm={8} md={5} component="main">
    //     <div className={classes.paper}>
    //       <img
    //         src={
    //           companyData?.logoURL
    //             ? companyData?.logoURL.includes("porto-files-upload")
    //               ? companyData?.logoURL
    //               : `${process.env.REACT_APP_BASE_URL}/${companyData?.logoURL}`
    //             : logo
    //         }
    //         alt="porto"
    //         width={150}
    //         height={115}
    //         style={{ objectFit: "contain", borderRadius: "20px" }}
    //       />

    //       <Typography component="h1" variant="h1">
    //         {translation("login.title")}
    //       </Typography>

    //     </div>
    //   </Grid>
    // </Grid>
  );
};
export default LogIn;
