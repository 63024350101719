import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import DocumentsContainer from "src/containers/documents";
const Documents = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Documents" description="Porto Documents" />
      <DocumentsContainer />
    </Grid>
  );
};

export default Documents;
