import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { OwnerProps, billingInformationProps } from "src/interfaces/owner";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectOwner } from "src/redux/reducers/owner";
import StatusBadge from "src/components/common/StatusBadge";
import { typedKeys } from "src/utils/common";
import Collapsible from "src/components/collapsibleForm";
import CustomForm from "src/components/customElements/customForm";
import AddressInfo from "src/components/common/address";
import BillingAddress from "src/components/common/billings";
import BillingInformation from "./billingInformation";
import ContactInfo from "./contactInfo";
import SettlementInfo from "./settlementInfo";
import PersonalInformation from "./personalInfo";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useStyles } from "src/styles/formStyles";
import OwnerDriver from "src/containers/owner/driverLinking";
import OwnerTrailer from "src/containers/owner/trailerLinking";
import OwnerDocuments from "src/containers/documents";
import { ArrowBack } from "@mui/icons-material";
import { selectUser } from "src/redux/reducers/auth";
import OwnerVehicle from "src/containers/owner/vehicleLinking";
interface OwnerFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: OwnerProps) => any; */
  onSubmit: (data: any) => any;
  handleClose?: () => any;
  url: any;
}

const OwnerForm = ({
  loading,
  pageLoading,
  handleClose,
  onSubmit,
  url,
}: OwnerFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const params = useParams();
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  const owner = useAppSelector(selectOwner);
  const user = useAppSelector(selectUser);
  const linkingAddress = location.pathname.includes("details");
  const [disabled, setDisabled] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const setFormValues = (key: keyof OwnerProps) => {
    if (owner) {
      if (typeof owner[key] === "boolean") {
        setValue(key, owner[key]);
      } else {
        setValue(key, owner[key] ? owner[key] : "");
      }
    }
  };

  const handleCancel = () => {
    if (params?.ownerId) {
      () => navigate(-1);
    } else if (handleClose) {
      handleClose();
    }
  };
  useEffect(() => {
    if (!params?.ownerId) {
      setValue(
        "terminal",
        user?.tenant?.defaultTerminal ? user?.tenant?.defaultTerminal?.id : ""
      );
    }
  }, []);

  const setBillingInfoValues = (
    object: billingInformationProps,
    name: keyof OwnerProps
  ) => {
    if (object != null && Object.keys(object).length != 0) {
      const allAttributes = typedKeys(object);
      for (const attribute of allAttributes) {
        if (typeof object[attribute] === "boolean") {
          setValue(`${name}.${attribute}`, object[attribute]);
        } else {
          setValue(
            `${name}.${attribute}`,
            object[attribute] ? object[attribute] : ""
          );
        }
      }
    }
  };
  useEffect(() => {
    if (linkingAddress) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if (params?.ownerId && owner) {
      const allAttributes = typedKeys(owner);
      for (const attribute of allAttributes) {
        if (attribute === "billingInformation") {
          setBillingInfoValues(owner?.billingInformation, attribute);
        } else if (attribute === "billingAddress") {
          setValue(
            "billingName",
            owner?.billingAddress?.billingName
              ? owner?.billingAddress?.billingName
              : ""
          );
          setValue(
            "billingEmail",
            owner?.billingAddress?.billingEmail
              ? owner?.billingAddress?.billingEmail
              : ""
          );
          setValue(
            "billingPhoneNumber",
            owner?.billingAddress?.billingPhoneNumber
              ? owner?.billingAddress?.billingPhoneNumber
              : ""
          );
          setValue(
            "billingAltPhoneNumber",
            owner?.billingAddress?.billingAltPhoneNumber
              ? owner?.billingAddress?.billingAltPhoneNumber
              : ""
          );
          setValue(
            "billingFaxPhoneNumber",
            owner?.billingAddress?.billingFaxPhoneNumber
              ? owner?.billingAddress?.billingFaxPhoneNumber
              : ""
          );
          setValue(
            "addressBilling",
            owner?.billingAddress?.addressBilling
              ? owner?.billingAddress?.addressBilling
              : ""
          );
          setValue(
            "billingCity",
            owner?.billingAddress?.billingCity
              ? owner?.billingAddress?.billingCity
              : ""
          );
          setValue(
            "billingZip",
            owner?.billingAddress?.billingZip
              ? owner?.billingAddress?.billingZip
              : ""
          );
          setValue(
            "billingState",
            owner?.billingAddress?.billingState
              ? owner?.billingAddress?.billingState
              : ""
          );
        } else if (attribute === "terminal") {
          if (owner?.terminal && typeof owner?.terminal === "object") {
            setValue("terminal", owner?.terminal.id ? owner?.terminal.id : "");
          } else {
            setValue("terminal", owner?.terminal ? owner?.terminal : "");
          }
        } else {
          setFormValues(attribute);
        }
      }
    }
  }, [params?.ownerId, owner]);
  useEffect(() => {
    if (watch("isSameBillingAddress")) {
      setValue(
        "billingName",
        watch("firstName") && watch("lastName")
          ? watch("firstName") + " " + watch("lastName")
          : ""
      );
      setValue("billingEmail", watch("email") ? watch("email") : "");
      setValue("billingPhoneNumber", watch("phone") ? watch("phone") : "");
      setValue(
        "billingAltPhoneNumber",
        watch("altPhone") ? watch("altPhone") : ""
      );
      setValue(
        "billingFaxPhoneNumber",
        watch("faxPhone") ? watch("faxPhone") : ""
      );
      setValue("addressBilling", watch("address") ? watch("address") : "");
      setValue("billingCity", watch("city") ? watch("city") : "");
      setValue("billingState", watch("state") ? watch("state") : "");
      setValue("billingZip", watch("zip") ? watch("zip") : "");
      delete errors["billingName"];
      delete errors["billingEmail"];
      delete errors["addressBilling"];
      delete errors["billingCity"];
      delete errors["billingZip"];
      delete errors["billingState"];
      delete errors["billingPhoneNumber"];
      delete errors["billingAltPhoneNumber"];
      delete errors["billingFaxPhoneNumber"];
    } else {
      setValue(
        "billingName",
        owner?.billingAddress?.billingName
          ? owner?.billingAddress?.billingName
          : ""
      );
      setValue(
        "billingEmail",
        owner?.billingAddress?.billingEmail
          ? owner?.billingAddress?.billingEmail
          : ""
      );
      setValue(
        "billingPhoneNumber",
        owner?.billingAddress?.billingPhoneNumber
          ? owner?.billingAddress?.billingPhoneNumber
          : ""
      );
      setValue(
        "billingAltPhoneNumber",
        owner?.billingAddress?.billingAltPhoneNumber
          ? owner?.billingAddress?.billingAltPhoneNumber
          : ""
      );
      setValue(
        "billingFaxPhoneNumber",
        owner?.billingAddress?.billingFaxPhoneNumber
          ? owner?.billingAddress?.billingFaxPhoneNumber
          : ""
      );
      setValue(
        "addressBilling",
        owner?.billingAddress?.addressBilling
          ? owner?.billingAddress?.addressBilling
          : ""
      );
      setValue(
        "billingCity",
        owner?.billingAddress?.billingCity
          ? owner?.billingAddress?.billingCity
          : ""
      );
      setValue(
        "billingZip",
        owner?.billingAddress?.billingZip
          ? owner?.billingAddress?.billingZip
          : ""
      );
      setValue(
        "billingState",
        owner?.billingAddress?.billingState
          ? owner?.billingAddress?.billingState
          : ""
      );
    }
  }, [watch("isSameBillingAddress"), owner]);

  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "contactInfoPanel",
    "personalInfoPanel",
    "addressInfoPanel",
    "settlementInfoPanel",
    "BillingAddress",
    "BillingInformation",
    "ownerDriver",
    "ownerTrailer",
    "ownerVehicle",
    "ownerDocuments",
  ]);
  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);
  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = [
      "contactInfoPanel",
      "personalInfoPanel",
      "addressInfoPanel",
      "settlementInfoPanel",
      "BillingAddress",
      "BillingInformation",
      "ownerDriver",
      "ownerTrailer",
      "ownerVehicle",
      "ownerDocuments",
      "errors",
    ];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("owner.form.title1")}
              </Typography>
              {owner?.status && <StatusBadge label={owner?.status} />}
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              className={classes.formButton}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    height: "20px",
                    width: "20px",
                  }}
                />
              ) : params?.ownerId ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>

            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={handleCancel}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>

      {pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
        <CustomFormGrid>
          <CustomForm noValidate onSubmit={handleSubmit(onSubmit)}>
            <CustomFormGrid container>
              {/*****************Column 1 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.personalInformation.label")}
                      </Typography>
                      <PersonalInformation
                        control={control}
                        errors={errors}
                        disabled={disabled}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.contactInformation.label")}
                      </Typography>
                      <ContactInfo
                        control={control}
                        errors={errors}
                        disabled={disabled}
                        watch={watch}
                        setValue={setValue}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>

                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.billing.label")}
                      </Typography>
                      <BillingAddress
                        control={control}
                        errors={errors}
                        loading={loading}
                        watch={watch}
                        disabled={disabled}
                        setValue={setValue}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>

              {/*****************Column 2 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.address.label")}
                      </Typography>
                      <AddressInfo
                        control={control}
                        errors={errors}
                        disabled={disabled}
                        setValue={setValue}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("owner.settlementInformation.label")}
                      </Typography>
                      <SettlementInfo
                        control={control}
                        errors={errors}
                        disabled={disabled}
                        linkingAddress={linkingAddress}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.billingInfo.label")}
                      </Typography>
                      <BillingInformation
                        control={control}
                        errors={errors}
                        disabled={disabled}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomForm>

          {/*   <CustomFormGrid
                sx={{ padding: "0px", margin: "0px" }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                {linkingAddress && (
                  <Collapsible
                    title={translation("owner.documents.title")}
                    handleChange={handleChange}
                    expanded={expandedPanels.includes("ownerDocuments")}
                    id="ownerDocuments"
                    ComponentToRender={<OwnerDocuments />}
                  />
                )}
              </CustomFormGrid> */}
        {/*   <CustomFormGrid item xs={6} sm={12} md={12} lg={12}>
            {linkingAddress && (
              <Collapsible
                title={translation("ownerDriver.list.title")}
                handleChange={handleChange}
                expanded={expandedPanels.includes("ownerDriver")}
                id="ownerDriver"
                ComponentToRender={<OwnerDriver />}
              />
            )}
          </CustomFormGrid>
          <CustomFormGrid item xs={6} sm={12} md={12} lg={12}>
            {linkingAddress && (
              <Collapsible
                title={translation("ownerTrailer.list.title")}
                handleChange={handleChange}
                expanded={expandedPanels.includes("ownerTrailer")}
                id="ownerTrailer"
                ComponentToRender={<OwnerTrailer />}
              />
            )}
          </CustomFormGrid>
          <CustomFormGrid item xs={6} sm={12} md={12} lg={12}>
            {linkingAddress && (
              <Collapsible
                title={translation("ownerVehicle.list.title")}
                handleChange={handleChange}
                expanded={expandedPanels.includes("ownerVehicle")}
                id="ownerVehicle"
                ComponentToRender={<OwnerVehicle />}
              />
            )}
          </CustomFormGrid> */}
        </CustomFormGrid>
      )}
    </div>
  );
};

export default OwnerForm;
