import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { TerminalProps } from "src/interfaces/terminal";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectTerminal } from "src/redux/reducers/terminal";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import CustomForm from "src/components/customElements/customForm";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { typedKeys } from "src/utils/common";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import Collapsible from "src/components/collapsibleForm";
import ContactInfo from "./contactInfo";
import TerminalInfo from "./terminalInfo";
import CommissionInfo from "./commissionInfo";
import AddressInfo from "src/components/common/address";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { commissionPrograms } from "src/utils/dropdowns/terminals";
import { useStyles } from "src/styles/formStyles";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
interface TerminalFormProps {
  loading: boolean;
  pageLoading: boolean;
  onSubmit: (data: any) => any;
  handleClose?: () => any;
  url: any;
}

const TerminalForm = ({
  loading,
  pageLoading,
  onSubmit,
  handleClose,
  url,
}: TerminalFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const terminal = useAppSelector(selectTerminal);
  const allLookups = useAppSelector(selectLookups);

  const terminalTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["terminal-type"]?.lookups
      ? allLookups?.data["terminal-type"]?.lookups
      : [];

  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);
  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "contactInfoPanel",
    "commissionInfoPanel",
    "addressInfoPanel",
    "terminalInfoPanel",
    "errors",
  ]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const setFormValues = (key: keyof TerminalProps) => {
    if (terminal) {
      if (typeof terminal[key] === "boolean") {
        setValue(key, terminal[key]);
      } else {
        setValue(key, terminal[key] ? terminal[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.terminalId && terminal) {
      const allAttributes = typedKeys(terminal);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
        if (attribute === "commissionProgram") {
          if (
            typeof terminal?.commissionProgram === "object" &&
            terminal?.commissionProgram !== null
          ) {
            setValue(
              "commissionProgram",
              terminal?.commissionProgram.id
                ? terminal?.commissionProgram.id
                : ""
            );
          } else if (typeof terminal?.commissionProgram === "number") {
            setValue(
              "commissionProgram",
              terminal?.commissionProgram ? terminal?.commissionProgram : ""
            );
          }
        } else if (attribute === "type") {
          if (typeof terminal?.type === "object" && terminal?.type !== null) {
            setValue("type", terminal?.type.id ? terminal?.type.id : "");
          } else if (typeof terminal?.type === "number") {
            setValue("type", terminal?.type ? terminal?.type : "");
          }
        }
      }
    } else {
      if (commissionPrograms.length > 0) {
        setValue("commissionProgram", commissionPrograms[0].value);
      }
    }
  }, [params?.terminalId, terminal]);

  const handleChange = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = [
      "contactInfoPanel",
      "commissionInfoPanel",
      "addressInfoPanel",
      "terminalInfoPanel",
      "errors",
    ];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };
  const handleCancel = () => {
    if (params?.terminalId) {
      () => navigate(-1);
    } else if (handleClose) {
      handleClose();
    }
  };
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
              {translation("terminals.form.terminalInformation.label")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.formButton}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />
              ) : params?.terminalId ? (
                translation("terminals.form.update")
              ) : (
                translation("terminals.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={handleCancel}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>

      {pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomForm noValidate>
            <CustomFormGrid container>
              {/*****************Column 1 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation(
                          "terminals.form.terminalInformation.label"
                        )}
                      </Typography>
                      <TerminalInfo
                        control={control}
                        errors={errors}
                        terminalTypes={terminalTypes}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("terminals.commissionInformation.label")}
                      </Typography>
                      <CommissionInfo
                        control={control}
                        errors={errors}
                        watch={watch}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                </CustomFormGrid>

                  {/*****************Column 2 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.address.label")}
                      </Typography>
                      <AddressInfo
                        control={control}
                        errors={errors}
                        setValue={setValue}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.contactInformation.label")}
                      </Typography>
                      <ContactInfo
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomForm>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default TerminalForm;
