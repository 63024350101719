import React from "react";
import { MenuItem, InputLabel } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomSwitch from "src/components/customElements/customSwitch";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { entities, types } from "src/utils/dropdowns/documentTypes";

interface TypeInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
}
const TypeInfo = ({ control, errors, watch }: TypeInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="name"
            control={control}
            defaultValue={""}
          //  rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                margin="normal"
                fullWidth
                id="name"
                label={translation("documentType.form.name.label")}
                error={Boolean(errors?.name)}
                helperText={
                  errors?.name && translation("documentType.form.name.message")
                }
              //  required
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="size"
            control={control}
            defaultValue={""}
           // rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                margin="normal"
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="size"
                label={translation("documentType.form.size.label")}
                error={Boolean(errors?.size)}
                helperText={
                  errors?.size && translation("documentType.form.size.message")
                }
             //   required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="type"
            control={control}
            defaultValue={""}
          //  rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
                margin="normal"
                id="type"
                label={translation("documentType.form.type.label")}
                error={Boolean(errors?.type)}
                select
                helperText={
                  errors?.type && translation("documentType.form.type.message")
                }
             //   required
              >
                {types.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={3} lg={3}>
          <Controller
            name="entity"
            control={control}
            defaultValue={""}
          //  rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                fullWidth
                size="small"
                margin="normal"
                id="entity"
                label={translation("documentType.form.entity.label")}
                error={Boolean(errors?.entity)}
                select
                helperText={
                  errors?.entity &&
                  translation("documentType.form.entity.message")
                }
             //   required
              >
                {entities.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Controller
            name="isRequired"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("documentType.form.isRequired.title")}
                </InputLabel>
                <CustomSwitch
                  checked={watch("isRequired") ? watch("isRequired") : false}
                  {...field}
                  id="isRequired"
                />
              </>
            )}
          ></Controller>
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Controller
            name="hasExpiry"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("documentType.form.hasExpiry.title")}
                </InputLabel>
                <CustomSwitch
                  checked={watch("hasExpiry") ? watch("hasExpiry") : false}
                  {...field}
                  id="hasExpiry"
                />
              </>
            )}
          ></Controller>
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Controller
            name="editAbleAfterApprove"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("documentType.form.editAbleAfterApprove.title")}
                </InputLabel>
                <CustomSwitch
                  checked={
                    watch("editAbleAfterApprove")
                      ? watch("editAbleAfterApprove")
                      : false
                  }
                  {...field}
                  id="editAbleAfterApprove"
                />
              </>
            )}
          ></Controller>
        </CustomFormGrid>
        <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={3}
          lg={3}
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Controller
            name="maintainHistory"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <>
                <InputLabel>
                  {translation("documentType.form.maintainHistory.title")}
                </InputLabel>
                <CustomSwitch
                  checked={
                    watch("maintainHistory") ? watch("maintainHistory") : false
                  }
                  {...field}
                  id="maintainHistory"
                />
              </>
            )}
          ></Controller>
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default TypeInfo;
