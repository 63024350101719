import React, { useEffect } from "react";
import { Button } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import CustomForm from "src/components/customElements/customForm";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  ListItem,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { CustomerProps } from "src/interfaces/customer";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectCustomer } from "src/redux/reducers/customer";
import { selectTerminals } from "src/redux/reducers/terminal";
import Collapsible from "src/components/collapsibleForm";
import PersonalInformation from "./personalInfo";
import ContactInfo from "./contactInfo";
import AddressInfo from "src/components/common/address";
import { useStyles } from "src/styles/formStyles";
import AdditionalInfo from "./additionalInfo";
import { typedKeys } from "src/utils/common";
import { selectUser } from "src/redux/reducers/auth";
import { ArrowBack } from "@mui/icons-material";

interface CustomerFormProps {
  loading: boolean;
  pageLoading: boolean;
  /* onSubmit: (data: CustomerProps) => any; */
  onSubmit: (data: any) => any;
  parentExpandAll?: boolean;
  handleClose?: () => any;
}
const CustomerForm = ({
  loading,
  pageLoading,
  onSubmit,
  parentExpandAll,
  handleClose,
}: CustomerFormProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const customer = useAppSelector(selectCustomer);
  const terminals = useAppSelector(selectTerminals);
  const user = useAppSelector(selectUser);

  const [expandedPanels, setExpandedPanels] = React.useState<Array<string>>([
    "personalInfoPanel",
    "contactInfoPanel",
    "addressInfoPanel",
    "additionalInfoPanel",
  ]);
  const [expandAllPanels, setExpandAllPanels] = React.useState<boolean>(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (!params?.customerId) {
      setValue(
        "terminal",
        user?.tenant?.defaultTerminal ? user?.tenant?.defaultTerminal?.id : ""
      );
    }
  }, []);

  useEffect(() => {
    const allErrors = Object.keys(errors);

    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);

  const setFormValues = (key: keyof CustomerProps) => {
    if (customer) {
      if (typeof customer[key] === "boolean") {
        setValue(key, customer[key]);
      } else {
        setValue(key, customer[key] ? customer[key] : "");
      }
    }
  };

  useEffect(() => {
    if (params?.customerId && customer) {
      const allAttributes = typedKeys(customer);
      for (const attribute of allAttributes) {
        setFormValues(attribute);
        if (attribute === "customerType") {
          if (
            customer?.customerType &&
            typeof customer?.customerType === "object"
          ) {
            setValue(
              "customerType",
              customer?.customerType?.id ? customer?.customerType?.id : ""
            );
          } else {
            setValue(
              "customerType",
              customer?.customerType ? customer?.customerType : ""
            );
          }
        }
        if (attribute === "terminal") {
          if (typeof customer?.terminal === "object") {
            setValue(
              "terminal",
              customer?.terminal?.id ? customer?.terminal?.id : ""
            );
          } else {
            setValue("terminal", customer?.terminal ? customer?.terminal : "");
          }
        }
      }
    }
  }, [params?.customerId, customer, terminals]);

  useEffect(() => {
    const allErrors = Object.keys(errors);
    if (allErrors.length > 0 && !expandAllPanels) {
      handleExpandAllPanels();
    }
  }, [Object.keys(errors)]);
  const handleCancel = () => {
    if (params?.customerId) {
      () => navigate(-1);
    } else if (handleClose) {
      handleClose();
    }
  };
  const handleExpandPanel = (
    event: React.SyntheticEvent,
    newExpanded: boolean,
    panel: string
  ) => {
    const _expandedPanels = [...expandedPanels];
    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleExpandAllPanels = () => {
    setExpandAllPanels(true);
    const _expandedPanels = [
      "personalInfoPanel",
      "contactInfoPanel",
      "addressInfoPanel",
      "additionalInfoPanel",
      "errors",
    ];
    setExpandedPanels([..._expandedPanels]);
  };
  const handleCollapseAllPanels = () => {
    setExpandAllPanels(false);
    setExpandedPanels([]);
  };

  useEffect(() => {
    if (parentExpandAll) {
      handleExpandAllPanels();
    } else {
      handleCollapseAllPanels();
    }
  }, [parentExpandAll]);

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item xs={12} sm={12} md={4} lg={4}>
          <Box className={classes.loadFormTitleContainer}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <Button>
                <ArrowBack onClick={() => navigate(-1)} />
              </Button>
              <Typography variant="h6" className={classes.loadFormMainHeadings}>
                {translation("customer.form.title")}
              </Typography>
            </Box>
          </Box>
        </CustomFormGrid>

        <CustomFormGrid item xs={12} sm={12} md={8} lg={8}>
          <CustomFormGrid
            container
            className={classes.buttonContainer}
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.formButton}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />
              ) : params?.customerId ? (
                translation("common.form.update")
              ) : (
                translation("common.form.add")
              )}
            </Button>
            <Button
              color="error"
              variant="contained"
              className={classes.formButton}
              onClick={handleCancel}
            >
              {translation("common.btn.cancel")}
            </Button>
          </CustomFormGrid>
        </CustomFormGrid>
      </CustomFormGrid>
      {pageLoading ? (
        <CustomFormGrid container justifyContent="center">
          <CircularProgress />
        </CustomFormGrid>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomForm
            noValidate
          // onSubmit={stopPropagate(handleSubmit(onSubmit))}
          >
            <CustomFormGrid container>
              {/*****************Column 1 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.personalInformation.label")}
                      </Typography>
                      <PersonalInformation
                        control={control}
                        // expandAllPanels={expandAllPanels}
                        errors={errors}
                        watch={watch}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.additionalInformation.label")}
                      </Typography>
                      <AdditionalInfo
                        control={control}
                        errors={errors}
                        watch={watch}
                      // expandAllPanels={expandAllPanels}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>

              {/*****************Column 2 ****************/}
              <CustomFormGrid item xs={12} sm={12} lg={6} md={6}>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.address.label")}
                      </Typography>
                      <AddressInfo
                        control={control}
                        errors={errors}
                        setValue={setValue}

                      // expandAllPanels={expandAllPanels}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
                <CustomFormGrid item xs={12} sm={12} md={12} lg={12}>
                  <Card className={classes.formContainer}>
                    <CardContent style={{ paddingBottom: "2px" }}>
                      <Typography className={classes.loadFormHeadings}>
                        {translation("common.contactInformation.label")}
                      </Typography>
                      <ContactInfo
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                      // expandAllPanels={expandAllPanels}
                      />
                    </CardContent>
                  </Card>
                </CustomFormGrid>
              </CustomFormGrid>
            </CustomFormGrid>
          </CustomForm>
        </LocalizationProvider>
      )}
    </div>
  );
};

export default CustomerForm;
