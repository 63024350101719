import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { LineItemProps } from "src/interfaces/lineItems";

export interface LineItemState {
  LineItemsList: Array<LineItemProps>;
  singleLineItem: LineItemProps | null;
}

const initialState: LineItemState = {
  LineItemsList: [],
  singleLineItem: null,
};

export const lineItemsSlice = createSlice({
  name: "lineItem",
  initialState,

  reducers: {
    setAllLineItems: (state, action: PayloadAction<Array<LineItemProps>>) => {
      state.LineItemsList = action.payload;
    },
    setSingleLineItem: (state, action: PayloadAction<LineItemProps>) => {
      state.singleLineItem = action.payload;
    },
    resetLineItem: (state) => {
      state.singleLineItem = null;
    },
    resetLineItemState: (state) => {
      state.singleLineItem = null;
      state.LineItemsList = [];
    },
  },
});
export const {
  setAllLineItems,
  setSingleLineItem,
  resetLineItem,
  resetLineItemState,
} = lineItemsSlice.actions;
export const selectLineItems = (state: RootState): any =>
  state.lineItem.LineItemsList;
export const selectLineItem = (state: RootState): LineItemProps | null =>
  state.lineItem.singleLineItem;
export default lineItemsSlice.reducer;
