import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "src/styles/formStyles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAllLookupsByCategory } from "src/redux/actions/lookup";
import { featureSecurity } from "src/utils/enums";
import SearchForm from "src/components/searchForms/securityGroupSearchForm";

const loadSearch = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const boxTheme = useTheme();
  const isBoxSmall = useMediaQuery(boxTheme.breakpoints.down("md"));
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const delayForClear = async () => {
    setPageLoading(true);

    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  };

  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        {pageLoading ? (
          <div className={classes.loadingStyle}>
            <CircularProgress />
          </div>
        ) : (
          <SearchForm pageLoading={pageLoading} delayForClear={delayForClear} />
        )}
      </Grid>
    </Grid>
  );
};

export default loadSearch;
