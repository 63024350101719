import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "src/components/dataTable/linkingTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { selectVehicle, selectLinkedDrivers } from "src/redux/reducers/vehicle";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { DriverVehicleProps } from "src/interfaces/driver";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";
import {
  addDriverVehicle,
  deleteDriverVehicleLink,
} from "src/redux/actions/driver_vehicle";
import ConfirmationDialog from "src/components/common/confirmation";
import { getAllAvailableDrivers } from "src/redux/actions/driver";
import DriverVehicleLinkModal from "src/components/vehicle/linkingModal";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import AssignedLinkingCard from 'src/components/common/linkingCards/assignedDriver';
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}
export default function DriverVehicle() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [driver, setDriver] = useState<DriverVehicleProps | null>(null);
  const reducerVehicle = useAppSelector(selectVehicle);
  const linkedDrivers = useAppSelector(selectLinkedDrivers);
  const [translation] = useTranslation("translation");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const getAvailableDriversList = async () => {
    setLoading(true);
    await dispatch(getAllAvailableDrivers());
    setLoading(false);
  };

  /*  function pageExists(page: any) {
    return linkedDriverData.some(function (el: any) {
      return el?.pagination?.page === page;
    });
  } */
  useEffect(() => {
    getAvailableDriversList();
  }, []);

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleUnlinkDriver = (data: DriverVehicleProps) => {
    handleOpenDeleteModal();
    setDriver(data);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (params?.vehicleId) {
      setLoading(true);
      if (driver) {
        const object = {
          driver:
            typeof driver === "object"
              ? driver?.id
                ? driver?.id
                : ""
              : driver,
          vehicle: parseInt(params?.vehicleId),
        };
        await dispatch(deleteDriverVehicleLink(object));
      }
      handleCloseDeleteModal();
      setLoading(false);
      getAvailableDriversList();
    }
  };
  /* DriverVehicleProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: DriverVehicleProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);
    objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
    if (params?.vehicleId) {
      objToSubmit.vehicle = parseInt(params.vehicleId)
        ? parseInt(params.vehicleId)
        : null;
    }
    objToSubmit.driver = objToSubmit.driver ? objToSubmit.driver : null;

    if (params?.vehicleId) {
      const driverVehicleLink = await dispatch(addDriverVehicle(objToSubmit));

      if (driverVehicleLink.success && driverVehicleLink.data) {
        handleClose();
        getAvailableDriversList();
      }
    }
    setLoading(false);
  };

  const logsColumns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "driver",
      headerName: translation("common.name.label"),
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.driver === "object" &&
            params?.row?.driver?.firstName
              ? `${params?.row?.driver?.firstName}  ${params?.row?.driver?.lastName}`
              : ""}
          </div>
        );
      },
      minWidth: 300,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 100,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const assignedDriversColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="delete"
          key="delete"
          onClick={() => {
            handleUnlinkDriver(params.row);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 200,
    },
    {
      field: "driver",
      headerName: translation("common.name.label"),
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {typeof params?.row?.driver === "object" &&
            params?.row?.driver?.firstName
              ? `${params?.row?.driver?.firstName}  ${params?.row?.driver?.lastName}`
              : ""}
          </div>
        );
      },
      minWidth: 300,
      flex: 1,
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.createdAt
              ? moment(params?.row?.createdAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>

            <Tooltip
              title={
                <Typography>{translation("driver.vehicle.assign")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleClickOpen}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid> */}

      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedDrivers}
          headerName={"Assigned Driver"}
          handleClickOpen={handleClickOpen}
          handleDelete={handleUnlinkDriver}
           />
      </CustomFormGrid>

    {/*   <DataTable
        title={"Assigned Drivers"}
        rows={linkedDrivers}
        columns={assignedDriversColumns}
        loading={false}
        onClickAdd={handleClickOpen}
        add={true}
        rowsState={rowsState}
        setRowsState={setRowsState}
        // pagination={lookups.pagination}
        // data={lookupData}
        // setData={setLookupData}
        localPagination={true}
      /> */}

      <DriverVehicleLinkModal
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof driver?.driver === "object"
                ? `${driver?.driver?.firstName} ${driver?.driver?.lastName}`
                : "",
          })}
          title={translation("common.delete.title")}
          loading={loading}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={
                reducerVehicle?.driverVehicle
                  ? reducerVehicle?.driverVehicle
                  : []
              }
              columns={logsColumns}
              loading={loading}
              onClickAdd={handleClickOpen}
              add={true}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              // pagination={lookups.pagination}
              // data={lookupData}
              // setData={setLookupData}
              localPagination={true}
            />
          }
          title={translation("vehicle.assignment.logs")}
        />
      )}
    </>
  );
}
