import React from "react";
import { Navigate } from "react-router-dom";
import LineItems from "../pages/lineItems";
import LineItemsForm from "../pages/lineItems/lineItemsForm";
import LineItemsSearch from "src/containers/lineItems/lineItemSearch";
import { UserPermissions } from "src/interfaces/user";
import { admin, create, update, featureLineItems } from "src/utils/enums";

export const lineItemRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const lineItemPermissions =
    allUserPermissions && allUserPermissions[featureLineItems];
  const createPermission =
    role === admin
      ? 0
      : lineItemPermissions?.findIndex((item) => item === create);

  const updatePermission =
    role === admin
      ? 0
      : lineItemPermissions?.findIndex((item) => item === update);

  return [
    {
      path: "/config/line-item",
      exact: true,
      element:
        role === admin || lineItemPermissions?.length > 0 ? (
          <LineItems />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/config/line-item/create",
      exact: true,
      element:
        createPermission > -1 ? <LineItemsForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/line-item/:id",
      exact: true,
      element:
        updatePermission > -1 ? <LineItemsForm /> : <Navigate to="/404" />,
    },
    {
      path: "/config/line-item/search",
      exact: true,
      element:
        updatePermission > -1 ? <LineItemsSearch /> : <Navigate to="/404" />,
    },
  ];
};
