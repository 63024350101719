import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import englishTranslation from "./translations/en.json";
import frenchTranslation from "./translations/fr.json";
import AppRouter from "./routes";
import { useAppSelector, useAppDispatch } from "./hooks/reduxHooks";
import { store, persistor } from "./redux/store";
import { NotificationContext } from "./utils/context";
import { NotificationProps } from "./interfaces/notification";
import Notification from "./components/notification";
import {
  selectNotification,
  resetNotification,
} from "./redux/reducers/notifications";
import {  selectLocale } from "./redux/reducers/customizer";
import "react-perfect-scrollbar/dist/css/styles.css";
import {CustomTheme} from './theme';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  fallbackLng: "en",
  resources: {
    en: {
      translation: englishTranslation,
    },
    fr: {
      translation: frenchTranslation,
    },
  },
});

const AppWrapper = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLocale);
  const reduxNotification = useAppSelector(selectNotification);

  const [notification, setNotification] = React.useState<NotificationProps>({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    if (reduxNotification.open) {
      setNotification(reduxNotification);
      setTimeout(() => dispatch(resetNotification()), 2000);
    }
  }, [reduxNotification]);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const handleClose = () => {
    setNotification({ open: false, message: "", severity: "" });
  };

  return (
    <ThemeProvider theme={CustomTheme}>
        <NotificationContext.Provider value={setNotification}>
          <I18nextProvider i18n={i18next}>
            <AppRouter />
            {notification.severity && (
              <Notification
                open={notification.open}
                message={notification.message}
                severity={notification.severity}
                handleClose={handleClose}
              />
            )}
          </I18nextProvider>
        </NotificationContext.Provider>
    </ThemeProvider>
  );
};

const App = (): JSX.Element => {
  // const theme = baseTheme;

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <HelmetProvider>
            <AppWrapper />
          </HelmetProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
