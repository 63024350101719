import React from "react";
import { Navigate } from "react-router-dom";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import Documents from "../pages/documents";
import Trailers from "../pages/trailer";
import TrailerForm from "../pages/trailer/form";
import TrailerReadForm from "../pages/trailer/formReadTrailer";
import { UserPermissions } from "src/interfaces/user";
import TrailerSearch from "../containers/trailer/trailerSearch";

import {
  admin,
  featureLookups,
  create,
  update,
  find,
  featureTrailers,
} from "src/utils/enums";

export const trailerRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const trailerPermission =
    allUserPermissions && allUserPermissions[featureTrailers];
  const createPermission =
    role === admin
      ? 0
      : trailerPermission?.findIndex((item) => item === create);
  const findPermission =
    role === admin ? 0 : trailerPermission?.findIndex((item) => item === find);
  const updatePermission =
    role === admin
      ? 0
      : trailerPermission?.findIndex((item) => item === update);

  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);
  return [
    {
      path: "/trailer/lookups/",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/trailer/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/trailer/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/trailer/trailers",
      exact: true,
      element:
        role === admin || trailerPermission?.length > 0 ? (
          <Trailers />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/trailer/trailers/create",
      exact: true,
      element: createPermission > -1 ? <TrailerForm /> : <Navigate to="/404" />,
    },
    {
      path: "/trailer/trailers/:trailerId",
      exact: true,
      element: updatePermission > -1 ? <TrailerForm /> : <Navigate to="/404" />,
    },
    // {
    //   path: "/trailer/trailers/details/:trailerId",
    //   exact: true,
    //   element: findPermission > -1 ? <TrailerForm /> : <Navigate to="/404" />,
    // },
    {
      path: "/trailer/trailers/details/:trailerId",
      exact: true,
      element:
        findPermission > -1 ? <TrailerReadForm /> : <Navigate to="/404" />,
    },
    {
      path: "/trailer/trailers/documents/:trailerId",
      exact: true,
      element: updatePermission > -1 ? <Documents /> : <Navigate to="/404" />,
    },
    {
      path: "/trailer/trailers/search",
      exact: true,
      element: findPermission > -1 ? <TrailerSearch /> : <Navigate to="/404" />,
    },
  ];
};
