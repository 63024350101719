import { AppDispatch } from "../store";
import {
  setAllSettlementCodes,
  setSingleSettlementCode,
} from "../reducers/settlementCodes";
import { SettlementCodesProps } from "src/interfaces/settlementCodes";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllSettlementCodes =
  (limit?: number, page?: number, filter?: any) =>
  async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/settlement-code`, )
    );

    if (response?.data?.data) {
      if (Array.isArray(response?.data?.data?.data)) {
        dispatch(setAllSettlementCodes(response?.data?.data));
      }
    }
    return { success: response.success, data: response?.data?.data };
  };

export const getSettlementCodeById =
  (id: number) => async (dispatch: AppDispatch) => {
    const response: any = await dispatch(
      getServerData(`/settlement-code/${id}`)
    );

    if (response?.data?.data) {
      dispatch(setSingleSettlementCode(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };
export const addSettlementCode =
  (data: SettlementCodesProps) => async (dispatch: AppDispatch) => {
    const response = await dispatch(postServerData(`/settlement-code`, data));

    if (response?.data?.data) {
      dispatch(setSingleSettlementCode(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
  };

export const updateSettlementCode =
  (data: SettlementCodesProps, id: number) => async (dispatch: AppDispatch) => {
    const response = await dispatch(
      patchServerData(`/settlement-code/${id}`, data)
    );

    if (response?.data?.data?.settlementCode) {
      dispatch(setSingleSettlementCode(response?.data?.data?.settlementCode));
    }
    return {
      success: response.success,
      data: response?.data?.data?.settlementCode,
    };
  };
