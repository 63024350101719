import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import ReadUserContainer from "src/containers/users/readFormContainer";
const ReadUsers = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Users" description="Porto System Users" />
      <ReadUserContainer />
    </Grid>
  );
};

export default ReadUsers;
