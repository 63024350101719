import { AppDispatch } from "../store";
import {
  setAllSecurityGroups,
  setSingleSecurityGroup,
  setAllPermissions,
} from "../reducers/securityGroups";
import { SecurityGroupProps } from "src/interfaces/securityGroups";
import { getServerData, postServerData, patchServerData } from "../api/index";

export const getAllSecurityGroups = (limit?: number, page?: number, filter?:any) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(
    getServerData(`/security-group`) as any
  );
  if (response?.data?.data) {
    if (Array.isArray(response?.data?.data?.data)) {
      dispatch(setAllSecurityGroups(response?.data?.data));
    }
  }
  return { success: response.success, data: response?.data?.data };
};

export const getSecurityGroupById = (id: number) => async (
  dispatch: AppDispatch
) => {
  const response: any = await dispatch(getServerData(`/security-group/${id}`) as any);
  if (response?.data?.data) {
    dispatch(setSingleSecurityGroup(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};

export const addSecurityGroup = (data: SecurityGroupProps) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(postServerData(`/security-group`, data) as any);

  if (response?.data?.data) {
    dispatch(setSingleSecurityGroup(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
export const updateSecurityGroup = (
  data: SecurityGroupProps,
  id: number
) => async (dispatch: AppDispatch) => {
  const response = await dispatch(
    patchServerData(`/security-group/${id}`, data) as any
  );
  if (response?.data?.data?.securityGroup) {
    dispatch(setSingleSecurityGroup(response?.data?.data?.securityGroup));
  }
  return {
    success: response.success,
    data: response?.data?.data?.securityGroup,
  };
};

export const getAllPermissions = () => async (dispatch: AppDispatch) => {
  const response: any = await dispatch(getServerData(`/permission`) as any);
  if (response?.data?.data) {
    dispatch(setAllPermissions(response?.data?.data));
  }
  return { success: response.success, data: response?.data?.data };
};
