import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider } from "@mui/material";

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  loading: boolean;
  data?: any;
  updateLoadState: () => void;

  // ComponentToRender?: React.ReactNode;
}

export default function AddCommonDialouge({
  open,
  handleClose,
  title,
  loading,
  data,
  updateLoadState,
}: // ComponentToRender,
DialogProps): JSX.Element {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "red" }}>
          {title}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are You sure you want to push loadId :{data && data.id} with missing
            documents ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={updateLoadState}>
            {loading ? (
              <CircularProgress style={{ height: "20px", width: "20px" }} />
            ) : (
              "Yes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
