import React from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectLookups } from "src/redux/reducers/lookup";
import { LookupProps } from "src/interfaces/lookup";
import { featureCustomers } from "src/utils/enums";
import AddLookupField from "src/components/common/addLookupFeild";

interface PersonalInfoProps {
  control: any;
  errors: { [key: string]: any };
  watch: (key: string) => any;
}
const PersonalInfo = ({ control, errors }: PersonalInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");
  const allLookups = useAppSelector(selectLookups);
  const allCustomerTypes: Array<LookupProps> =
    allLookups && allLookups?.data && allLookups?.data["customer-type"]?.lookups
      ? allLookups?.data["customer-type"]?.lookups
      : [];
  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <AddLookupField
            name={"customerType"}
            control={control}
            rules={{ required: true }}
            id="customerType"
            label={translation("customer.form.type.label")}
            errors={Boolean(errors?.customerType)}
            lookupsArray={allCustomerTypes}
            helperText={
              errors?.customerType &&
              translation("customer.form.type.message")
            }
            fieldRequired={true}
            featureKey="customer-type"
            lookupFeatureEnum={featureCustomers}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="code"
            control={control}
            defaultValue={""}
            //  rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="code"
                label={translation("customer.form.code.label")}
                error={Boolean(errors?.code)}
                helperText={
                  errors?.code && translation("customer.form.code.message")
                }
              //   required
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="companyName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                id="companyName"
                label={translation("customer.form.companyName.label")}
                error={Boolean(errors?.companyName)}
                helperText={
                  errors?.companyName &&
                  translation("customer.form.companyName.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};

export default PersonalInfo;
