import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { CustomerBillingProps } from "src/interfaces/customer";

export interface CustomerState {
  CustomerBillingsList: Array<CustomerBillingProps>;
  singleCustomerBilling: CustomerBillingProps | null;
}

const initialState: CustomerState = {
  CustomerBillingsList: [],
  singleCustomerBilling: null,
};

export const customerBillingsSlice = createSlice({
  name: "customerBillings",
  initialState,

  reducers: {
    setAllCustomersBillings: (
      state,
      action: PayloadAction<Array<CustomerBillingProps>>
    ) => {
      state.CustomerBillingsList = action.payload;
    },
    setSingleCustomerBilling: (
      state,
      action: PayloadAction<CustomerBillingProps>
    ) => {
      state.singleCustomerBilling = action.payload;
    },
    resetCustomerBilling: (state) => {
      state.singleCustomerBilling = null;
    },
    resetCustomerBillingState: (state) => {
      state.singleCustomerBilling = null;
      state.CustomerBillingsList = [];
    },
  },
});
export const {
  setAllCustomersBillings,
  setSingleCustomerBilling,
  resetCustomerBilling,
  resetCustomerBillingState,
} = customerBillingsSlice.actions;
export const selectCustomersBillings = (
  state: RootState
): Array<CustomerBillingProps> => state.customerBillings.CustomerBillingsList;
export const selectCustomerBilling = (
  state: RootState
): CustomerBillingProps | null => state.customerBillings.singleCustomerBilling;
export default customerBillingsSlice.reducer;
