import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import CreateCommodityContainerContainer from "src/containers/commodity/form";
const CommodityContainerForm = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Commodity" description="Porto Commodity" />
      <CreateCommodityContainerContainer />
    </Grid>
  );
};

export default CommodityContainerForm;
