import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import SettlementCodesFrom from "src/components/settlementCodes/form";
import { SettlementCodesProps } from "src/interfaces/settlementCodes";
import {
  getSettlementCodeById,
  addSettlementCode,
  updateSettlementCode,
} from "src/redux/actions/settlementCodes";

import { resetCommodity } from "src/redux/reducers/commodity";
import { useAppDispatch } from "src/hooks/reduxHooks";

const SettlementCodesFromContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    dispatch(resetCommodity());
  }, []);
  useEffect(() => {
    if (params?.id) {
      getSingleSettlementCode(parseInt(params?.id));
    }
  }, [params?.id]);
  const getSingleSettlementCode = async (id: number) => {
    setPageLoading(true);
    await dispatch(getSettlementCodeById(id));
    setPageLoading(false);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const objToSubmit: SettlementCodesProps = { ...data };
    objToSubmit.code = objToSubmit.code ? objToSubmit.code : null;
    objToSubmit.type = objToSubmit.type ? objToSubmit.type : null;
    objToSubmit.title = objToSubmit.title ? objToSubmit.title : null;
    objToSubmit.externalDescription = objToSubmit.externalDescription
      ? objToSubmit.externalDescription
      : null;
      objToSubmit.internalDescription = objToSubmit.internalDescription
      ? objToSubmit.internalDescription
      : null;

    objToSubmit.defaultAmount = objToSubmit.defaultAmount
      ? objToSubmit.defaultAmount
      : null;
    objToSubmit.availableForDispatch = objToSubmit.availableForDispatch
      ? objToSubmit.availableForDispatch
      : false;
    objToSubmit.category = objToSubmit.category ? objToSubmit.category : null;
    objToSubmit.excludeFromLoadPayCalculation = objToSubmit.excludeFromLoadPayCalculation ? objToSubmit.excludeFromLoadPayCalculation : false;
    objToSubmit.requireCaponRecurring = objToSubmit.requireCaponRecurring ? objToSubmit.requireCaponRecurring : false;
    objToSubmit.systemUseOnly = objToSubmit.systemUseOnly ? objToSubmit.systemUseOnly : false;
    objToSubmit.templateYTD = objToSubmit.templateYTD ? objToSubmit.templateYTD : false;

    if (params?.id) {
      //update
      await dispatch(updateSettlementCode(objToSubmit, parseInt(params?.id)));
    } else {
      //add
      const settlementCode = await dispatch(addSettlementCode(objToSubmit));
      if (settlementCode.success && settlementCode.data.id) {
        setTimeout(
      //    () => navigate(`/config/settlement-codes/${settlementCode.data.id}`),
          () => navigate("/config/settlement-codes"),
          500
        );
      }
    }
    setLoading(false);
  };
  return (
    <Grid container spacing={0}>
      <Grid item lg={12} md={12} xs={12}>
        <SettlementCodesFrom
          loading={loading}
          pageLoading={pageLoading}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default SettlementCodesFromContainer;
