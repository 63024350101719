import { AppDispatch } from "../store";
import {
    setAllLoadAddress,
    setSingleLoadAddress,
} from "../reducers/loadAddress";
import { LoadAddress } from "src/interfaces/loadManagement";
import { getServerData, postServerData, patchServerData } from "../api/index";
import { resetLoadManagementState } from "src/redux/reducers/loadManagement";

export const getAllLoadAddresses = (
    limit?: number,
    page?: number,
    directDispatch?: boolean,
    filter?: any
) => async (dispatch: AppDispatch) => {

    const { success, data: response } = await dispatch(getServerData(`/load-address`) as any);

    if (success) {
        if (Array.isArray(response?.data)) {
            dispatch(setAllLoadAddress(response?.data));
        }
    }

    return { success: success, data: response?.data };
};

export const getLoadAddressById = (id: number) => async (
    dispatch: AppDispatch
) => {
    const response: any = await dispatch(getServerData(`/load-address/${id}`) as any);
    if (response?.data?.data) {
        dispatch(setSingleLoadAddress(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
};

export const addLoadAddress = (data: LoadAddress) => async (
    dispatch: AppDispatch
) => {
    const response = await dispatch(postServerData(`/load-address`, data) as any);

    if (response?.data?.data) {
        // dispatch(setSingleLoadManagement(response?.data?.data));
    }
    return { success: response.success, data: response?.data?.data };
};

export const updateLoadAddress = (data: any, id: number) => async (
    dispatch: AppDispatch
) => {
    const response = await dispatch(patchServerData(`/load-address/${id}`, data) as any);
    if (response?.data?.data?.load) {
        dispatch(setSingleLoadAddress(response?.data?.data?.load));
    }
    return { success: response.success, data: response?.data?.data };
};


export const resetLoadAddress = () => async (dispatch: AppDispatch) => {
    dispatch(resetLoadManagementState());
};
