import React from "react";
import { Grid } from "@mui/material";
import Head from "src/components/head";
import SettlementDrivers from "src/containers/settlement/settlementDriver"
const Settlements = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Settlements" description="Porto Settlements" />
      <SettlementDrivers/>
    </Grid>
  );
};

export default Settlements;
