import React from "react";
import { Helmet } from "react-helmet-async";

interface HeadProps {
  title?: string;
  description?: string;
}

const Head = ({ title, description }: HeadProps): JSX.Element => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Porto {title && ` | ${title}`}</title>
      <meta
        name="description"
        content={
          description ? description : "Porto: The Transport Management System"
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      <link rel="icon" href="favicon_io/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="favicon_io/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="favicon_io/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="favicon_io/favicon-16x16.png" />
      <link rel="manifest" href="favicon_io/site.webmanifest" />
    </Helmet>
  );
};
export default Head;
