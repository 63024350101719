import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { selectDriver } from "src/redux/reducers/driver";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import ModelInput from "src/components/common/InformationModels/inputModel";
import { Close } from "@mui/icons-material";
import { useStyles } from "src/styles/formStyles";
import CustomGrid from "src/components/customElements/customFormGrid";
import StatusBadge from "src/components/common/StatusBadge";
import { dateFormat } from "src/utils/constants";
import moment from "moment";
import { getLoadManagementById } from "src/redux/actions/loadManagement";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { getTerminalById } from "src/redux/actions/terminal";
import { selectTerminal } from "src/redux/reducers/terminal";

import {
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  Button,
  IconButton,
  ListItem,
} from "@mui/material";
import { getDriverById } from "src/redux/actions/driver";

interface DialogProps {
  open: boolean;
  handleClose: () => void;

  loadId: number;
}

export default function ConfirmationDialog({
  open,
  handleClose,
  loadId,
}: DialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [translation] = useTranslation("translation");
  const [pageLoading, setPageLoading] = useState(false);

  const terminalData = useAppSelector(selectTerminal);
  const loadData = useAppSelector(selectLoadManagement);
  const addedByData = loadData != null && loadData?.addedBy;


  const getSingleLineItem = async (id: number) => {
    setPageLoading(true);
    await dispatch(getLoadManagementById(id));
    setPageLoading(false);
  };

  useEffect(() => {
    if (loadId) {
      getSingleLineItem(loadId);
    }
  }, [loadId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomFormGrid container className={classes.modelContainer}>
          <CustomFormGrid item xs={11} sm={11} md={11} lg={11}>
            <Typography variant="h6" className={classes.loadFormMainHeadings}>
              {"Order Taker"}
            </Typography>
          </CustomFormGrid>

          <CustomFormGrid item xs={1} sm={1} md={1} lg={1}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </CustomFormGrid>
        </CustomFormGrid>

        <DialogContent sx={{ paddingBottom: "20px",paddingTop:"0px", margin: "0px" }}>
          {pageLoading ? (
            <CustomFormGrid container justifyContent="center" style={{height:"100px"}}>
              <CircularProgress />
            </CustomFormGrid>
          ) : (
            <>
              <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid
                  item
                  xs={5.5}
                  sm={5.5}
                  md={5.5}
                  lg={5.5}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={"ID"}
                    input={loadData?.id ? loadData?.id : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.name.label")}
                    input={
                      addedByData?.firstName ? addedByData?.firstName : "---"
                    }
                    checkColor={false}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider variant="middle" />

              <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid
                  item
                  xs={5.5}
                  sm={5.5}
                  md={5.5}
                  lg={5.5}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.address.label")}
                    input={addedByData?.address ? addedByData?.address : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("companySettings.remarks.label")}
                    input={addedByData?.remarks ? addedByData?.remark : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider variant="middle" />

              <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid
                  item
                  xs={3.5}
                  sm={3.5}
                  md={3.5}
                  lg={3.5}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.city.label")}
                    input={addedByData?.city ? addedByData?.city : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid
                  item
                  xs={3.5}
                  sm={3.5}
                  md={3.5}
                  lg={3.5}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.state.label")}
                    input={addedByData?.state ? addedByData?.state : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.zip.label")}
                    input={addedByData?.zip ? addedByData?.zip : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider variant="middle" />

              <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid
                  item
                  xs={5.5}
                  sm={5.5}
                  md={5.5}
                  lg={5.5}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.status.label")}
                    input={addedByData?.status ? addedByData?.status : "---"}
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.phone.label")}
                    input={
                      addedByData?.phoneNumber
                        ? addedByData?.phoneNumber
                        : "---"
                    }
                    checkColor={false}
                  />
                </CustomFormGrid>
              </CustomFormGrid>
              <Divider variant="middle" />

              <CustomFormGrid container className={classes.fieldModel}>
                <CustomFormGrid
                  item
                  xs={5.5}
                  sm={5.5}
                  md={5.5}
                  lg={5.5}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.created.at")}
                    input={
                      addedByData?.createdAt
                        ? moment(addedByData?.createdAt).format(dateFormat)
                        : "---"
                    }
                    checkColor={false}
                  />
                </CustomFormGrid>
                <div className={classes.vlModel}></div>
                <CustomFormGrid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className={classes.fieldModel}
                >
                  <ModelInput
                    heading={translation("common.form.update")}
                    input={
                      addedByData?.updatedAt
                        ? moment(addedByData?.updatedAt).format(dateFormat)
                        : "---"
                    }
                    checkColor={false}
                  />
                </CustomFormGrid>
              </CustomFormGrid>

            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
