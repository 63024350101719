import React, { useEffect, useState } from "react";
import { GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "src/components/dataTable/linkingTable";
import { useAppDispatch, useAppSelector } from "src/hooks/reduxHooks";
import StatusBadge from "src/components/common/StatusBadge";
import { useTranslation } from "react-i18next";
import { selectOwner, selectLinkedVehicle } from "src/redux/reducers/owner";
import moment from "moment";
import { dateFormat } from "src/utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { OwnerVehicleProps } from "src/interfaces/owner";
import { sanitizeEntity } from "src/utils/common";
import { useParams } from "react-router-dom";

import {
  addOwnerVehicle,
  updateOwnerVehicle,
} from "src/redux/actions/owner_vehicle";
import ConfirmationDialog from "src/components/common/confirmation";
// import { getAllAvailableDrivers } from "src/redux/actions/driver";
import OwnerVehicleLinkModal from "src/components/owner/ownerVehicleLinkingModel";
import { Add, ListAlt } from "@mui/icons-material";
import { Typography, Fab, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LogsModal from "src/components/common/linkLogs";
import { getAllAvailableVehicles } from "src/redux/actions/vehicle";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import AssignedLinkingCard from "src/components/common/linkingCards/assignedVehicleTable";
interface RowsState {
  page: number;
  pageSize: number;
  rows: Array<any>;
  loading: boolean;
}

export default function OwnerVehicle() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const reducerOwner = useAppSelector(selectOwner);
  const linkedVehicles = useAppSelector(selectLinkedVehicle);
  const [translation] = useTranslation("translation");
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<any | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });
  const [rowsStateModal, setRowsStateModal] = React.useState<RowsState>({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const getAvailableVehicleList = async () => {
    setLoading(true);
    const filterObject: any = {};
    filterObject.items = [
      {
        columnField: "status",
        operatorValue: "equals",
        value: "active",
      },
    ];
    await dispatch(getAllAvailableVehicles(0, 1, filterObject));
    setLoading(false);
  };
  useEffect(() => {
    getAvailableVehicleList();
  }, []);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenLogsModal = () => {
    setOpenLogsModal(true);
  };
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };
  const handleUnlinkVehicle = (data: any) => {
    setVehicle(data);
    handleOpenDeleteModal();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (params?.ownerId) {
      setLoading(true);
      if (vehicle && vehicle?.id) {
        const object = {
          vehicle:
            typeof vehicle === "object"
              ? vehicle?.id
                ? vehicle?.id
                : ""
              : vehicle,
          owner: parseInt(params?.ownerId),
        };
        await dispatch(updateOwnerVehicle(object));
      }
      handleCloseDeleteModal();
      setLoading(false);
      getAvailableVehicleList();
    }
  };

  /* OwnerTrailerProps */
  const onSubmit = async (data: any) => {
    setLoading(true);
    let objToSubmit: OwnerVehicleProps = { ...data };
    objToSubmit = sanitizeEntity(objToSubmit);

    if (params?.ownerId) {
      objToSubmit.comments = objToSubmit.comments ? objToSubmit.comments : "";
      objToSubmit.owner = parseInt(params.ownerId)
        ? parseInt(params.ownerId)
        : null;
      objToSubmit.vehicle = objToSubmit.vehicle ? objToSubmit.vehicle : null;
      const ownerVehicleLink = await dispatch(addOwnerVehicle(objToSubmit));

      if (ownerVehicleLink.success && ownerVehicleLink.data) {
        handleClose();
        getAvailableVehicleList();
      }
    }
    setLoading(false);
  };

  const logsColumns = [
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 100,
    },
    {
      field: "comment",
      headerName: translation("common.comment.label"),
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.comment ? params?.row?.comment : ""}</div>;
      },
      minWidth: 300,
    },
    {
      field: "status",
      headerName: translation("common.status.label"),
      minWidth: 100,
      renderCell: (params: GridValueGetterParams) => {
        return <StatusBadge label={params.row.status} />;
      },
    },
    {
      field: "date",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.date
              ? moment(params?.row?.date).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "assignedBy",
      headerName: translation("common.assignedBy.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.user ? (
              <span>
                {params?.row?.user?.firstName} {params?.row?.user?.lastName}
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const assignedVehicleColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: translation("common.actions.label"),
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="delete"
          key="delete"
          onClick={() => {
            handleUnlinkVehicle(params.row);
          }}
        />,
      ],
    },
    {
      field: "id",
      headerName: translation("common.id.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.id ? params?.row?.id : ""}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: translation("common.assignedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.createdAt
              ? moment(params?.row?.createdAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: translation("common.updatedDate.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <div>
            {params?.row?.updatedAt
              ? moment(params?.row?.updatedAt).format(dateFormat)
              : ""}
          </div>
        );
      },
    },
    {
      field: "color",
      headerName: translation("vehicle.form.color.label"),
      minWidth: 200,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.color ? params?.row?.color : ""}</div>;
      },
    },
    {
      field: "model",
      headerName: translation("vehicle.form.model.label"),
      minWidth: 100,
      renderCell: (params: GridValueGetterParams) => {
        return <div>{params?.row?.model ? params?.row?.model : ""}</div>;
      },
    },
  ];

  return (
    <>
      {/*   <Grid container alignItems="flex-end">
        <Grid item xs={12} md={6} lg={6}>
          <Grid container alignItems="center"></Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Tooltip
              title={
                <Typography>{translation("common.logs.label")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleOpenLogsModal}
              >
                <ListAlt />
              </Fab>
            </Tooltip>

            <Tooltip
              title={
                <Typography>{translation("owner.vehicle.assign")}</Typography>
              }
            >
              <Fab
                color="secondary"
                sx={{
                  mr: 1,
                  ml: 1,
                }}
                size="small"
                onClick={handleClickOpen}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
 */}
      <CustomFormGrid item xs={12} ms={12} sm={12} lg={12}>
        <AssignedLinkingCard
          handleOpenLogsModal={handleOpenLogsModal}
          linkedData={linkedVehicles}
          headerName={"Assigned Vehicle"}
          handleClickOpen={handleClickOpen}
          handleDelete={handleUnlinkVehicle}
        />
      </CustomFormGrid>

      {/*  <DataTable
        title={""}
        rows={linkedVehicles}
        columns={assignedVehicleColumns}
        loading={false}
        onClickAdd={handleClickOpen}
        rowsState={rowsState}
        setRowsState={setRowsState}
        add={true}
      /> */}

      <OwnerVehicleLinkModal
        open={open}
        fullWidth={true}
        handleClose={handleClose}
        onSubmit={onSubmit}
        loading={loading}
      />

      {openDeleteModal && (
        <ConfirmationDialog
          handleClose={handleCloseDeleteModal}
          open={openDeleteModal}
          handleConfirm={handleConfirmDelete}
          message={translation("common.unlink.message", {
            name:
              typeof vehicle?.vehicle === "object"
                ? `${vehicle?.vehicle?.id}`
                : "",
          })}
          title={translation("ownerVehicle.delete.title")}
          loading={loading}
        />
      )}
      {openLogsModal && (
        <LogsModal
          handleClose={handleCloseLogsModal}
          open={openLogsModal}
          ComponentToRender={
            <DataTable
              title={""}
              rows={reducerOwner?.ownerVehicle}
              columns={logsColumns}
              loading={loading}
              onClickAdd={handleClickOpen}
              rowsState={rowsStateModal}
              setRowsState={setRowsStateModal}
              add={true}
            />
          }
          title={translation("vehicle.assignment.logs")}
        />
      )}
    </>
  );
}
