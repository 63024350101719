import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Divider,
    Box,
    Typography,
    Button,
    Grid,
    CircularProgress
} from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emailRegEx } from "src/utils/constants";
import { UserProps } from "src/interfaces/user";
import { useAppSelector } from "src/hooks/reduxHooks";
import { selectUser } from "src/redux/reducers/auth";
import { typedKeys } from "src/utils/common";
import { useAppDispatch } from "src/hooks/reduxHooks";
import { useStyles } from "src/styles/formStyles";
import "react-phone-input-2/lib/style.css";


interface AccountDataProps {
    loading: boolean;
    /* onSubmit: (data: UserProps) => any; */
    onSubmit: (data: any) => any;
}

const AccountData = ({ loading, onSubmit }: AccountDataProps): JSX.Element => {
    const [translation] = useTranslation("translation");
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const user = useAppSelector(selectUser);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm();

    const setFormValues = (key: keyof UserProps) => {
        if (user) {

            if (typeof user[key] === "boolean") {
                setValue(key, user[key]);
            } else {
                setValue(key, user[key] ? user[key] : "");
            }
        }
    };

    useEffect(() => {
        if (user) {
            const allAttributes = typedKeys(user);
            for (const attribute of allAttributes) {
                setFormValues(attribute);
            }
        }
    }, [user]);


    return (
        <div>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: '10px'
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                            }}
                        >
                            Account Data
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "10px",
                    }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container>
                            <Grid
                                item
                                sx={{
                                    padding: "5px",
                                }}
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true, pattern: emailRegEx }}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            disabled
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            id="email"
                                            label={translation("common.email.label")}
                                            error={Boolean(errors?.email)}
                                            helperText={
                                                errors?.email && translation("common.email.message")
                                            }
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    padding: "5px",
                                }}
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            id="password"
                                            label={"Password"}
                                            type={"password"}
                                            required

                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    padding: "5px",
                                }}
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <CustomTextField
                                            {...field}
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            fullWidth
                                            id="confirmPassword"
                                            label={"Confirm Password"}
                                            type={"password"}
                                            required
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                        <Grid container justifyContent="end">
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                {loading ? (
                                    <CircularProgress
                                        style={{ color: "#fff", height: "20px", width: "20px" }}
                                    />
                                ) : (
                                    "Update"
                                )}
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default AccountData;
