import React from "react";
import { Grid} from "@mui/material";
import Head from "src/components/head";
import FactoringInvoicesContainer from "src/containers/billing/factoringInvoices"
const FactoringInvoices = () => {
  return (
    <Grid container component="main" justifyContent="center">
      <Head title="Billing" description="Porto Billing" />
      <FactoringInvoicesContainer/>
    </Grid>
  );
};

export default FactoringInvoices;
