import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useStyles } from "src/styles/formStyles";
import AddLookupDialog from "src/components/common/addLookupModel";
import AddLookupContainer from "src/containers/lookup/addLookup";
import { GridAddIcon } from "@mui/x-data-grid";
import { LookupProps } from "src/interfaces/lookup";
import { Autocomplete } from "@mui/material";
import { selectLoadManagement } from "src/redux/reducers/loadManagement";
import { useAppSelector } from "src/hooks/reduxHooks";

interface VehicleInfoProps {
    name: string;
    control: any;
    rules: any;
    id: string;
    label: any;
    lookupsArray: any;
    linkingAddress?: boolean;
    helperText: any;
    disabled?: boolean;
    featureKey: string;
    errors: any;
  lookupFeatureEnum: any;
  fieldRequired: boolean;
  loadfield?: boolean;
  hanldeSelectValue?: (event: any, newValue: LookupProps) => any;
  stateValue?: any;
  data?: any;

}
const VehicleInfo = ({
  //  onSubmit,
  name,
  control,
  rules,
  id,
  label,
  lookupsArray,
  linkingAddress,
  helperText,
  disabled,
  featureKey,
  errors,
  stateValue,
  fieldRequired,
  lookupFeatureEnum,
  hanldeSelectValue,
  loadfield,
  data,
}: VehicleInfoProps): JSX.Element => {
  const classes = useStyles();
  const LoadManagement = useAppSelector(selectLoadManagement);

  const [openLookupModal, setOpenLookupModal] = useState<boolean>(false);

  const handleOpenLookupModal = () => {
    setOpenLookupModal(true);
  };
  const handleCloseLookupModal = () => {
    setOpenLookupModal(false);
  };


  return (
    <div>
      {loadfield && loadfield === true ? (
          <CustomFormGrid
          item
          sm={12}
          xs={12}
          md={12}
          lg={12}
          display="flex"
         // justifyContent="space-between"
        >
        {/*   <CustomFormGrid
            sx={{
              width: "100%",
                  padding: "0px",
                      margin:"0px",

            }}
          >
            <Controller
              name={name}
              control={control}
              defaultValue={""}
              rules={rules}
              render={({ field }) => (
                <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                select
                id={id}
                label={label}
                error={errors}
                  onChange={(e: any ,newValue: LookupProps) => {
                    hanldeSelectValue?.(e.target.value, newValue);
                  }}
                helperText={
                    helperText
                }
                  required={fieldRequired === true ? true : false}
              >
                {lookupsArray && lookupsArray?.map((item: any, index: any) => (
                  <MenuItem value={item.id} key={index}>
                    {item.content}
                  </MenuItem>
                ))}
              </CustomTextField>
              )}
            />
          </CustomFormGrid> */}
            <CustomFormGrid
            sx={{
              width: "100%",
                  padding: "0px",
                      margin:"0px",

            }}
          >
          <Controller
             name={name}
             control={control}
             defaultValue={""}
             rules={rules}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="combo-box-demo"
                fullWidth
                options={lookupsArray ? lookupsArray : "No Data"}
                value={stateValue ? stateValue : data ? data :""}
                getOptionLabel={(option) =>
                  option?.content ? option?.content : ""
                }
                onChange={(e: any ,newValue: LookupProps) => {
                  hanldeSelectValue?.(e.target.value, newValue);
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    id="address"
                    size="small"
                    required
                    // error={Boolean(errors?.accountType)}
                    label={label}
                    error={errors}
                  /*   helperText={
                      errors?.address && translation("common.address.message")
                    } */
                  />
                )}
              />
            )}
          />{" "}
        </CustomFormGrid>
          {linkingAddress ? (
            <CustomFormGrid>
        <GridAddIcon

                className={classes.disableGridAddButton}
              ></GridAddIcon>
            </CustomFormGrid>
          ) : (
            <CustomFormGrid>
              <GridAddIcon
                className={classes.formAddLookUpButton}
                onClick={() => handleOpenLookupModal()}
              ></GridAddIcon>
            </CustomFormGrid>
          )}
        </CustomFormGrid>
          ) : (<CustomFormGrid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              display="flex"
             // justifyContent="space-between"
            >
              <CustomFormGrid
                sx={{
                  width: "100%",
                      padding: "0px",
                          margin:"0px",

                }}
              >
                <Controller
                  name={name}
                  control={control}
                  defaultValue={""}
                  rules={rules}
                  render={({ field }) => (
                    <CustomTextField
                    {...field}
                    size="small"
                    fullWidth
                    disabled={disabled}
                    select
                    id={id}
                    label={label}
                    error={errors}
                    helperText={
                        helperText
                    }
                      required={fieldRequired === true ? true : false}
                  >
                    {lookupsArray && lookupsArray?.map((item: any, index: any) => (
                      <MenuItem value={item.id} key={index}>
                        {item.content}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  )}
                />
              </CustomFormGrid>
              {linkingAddress ? (
                <CustomFormGrid>
            <GridAddIcon

                    className={classes.disableGridAddButton}
                  ></GridAddIcon>
                </CustomFormGrid>
              ) : (
                <CustomFormGrid>
                  <GridAddIcon
                    className={classes.formAddLookUpButton}
                    onClick={() => handleOpenLookupModal()}
                  ></GridAddIcon>
                </CustomFormGrid>
              )}
            </CustomFormGrid>)}

        <AddLookupDialog
        handleClose={handleCloseLookupModal}
        open={openLookupModal}
        title="Add Lookup"
        ComponentToRender={
          <AddLookupContainer handleClose={handleCloseLookupModal} featureKey={featureKey} lookupFeatureEnum={lookupFeatureEnum} fieldName={label} />
        }
      />
    </div>
  );
};

export default VehicleInfo;
