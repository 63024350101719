import React from "react";
import { DatePicker } from "@mui/lab";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface SafetyInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled: boolean;
  trailer: any;
}
const SafetyInfo = ({
  disabled,
  errors,
  control,
  trailer,
}: SafetyInfoProps): JSX.Element => {
  const [translation] = useTranslation<string>("translation");

  return (
    <div>
      <CustomFormGrid container>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="trailerSafety.monthlyMaintenance"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("vehicle.form.monthlyMaintenance.label")}
                inputFormat="MM/dd/yyyy"
                disabled={disabled}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="trailerSafety.monthlyMaintenance"
                    fullWidth
                    error={
                      Boolean(errors?.monthlyMaintenance) ||
                      (trailer?.trailerSafety?.monthlyMaintenance != null &&
                        new Date(trailer?.trailerSafety?.monthlyMaintenance) <
                          new Date())
                    }
                    helperText={
                      errors?.monthlyMaintenance &&
                      translation("vehicle.form.monthlyMaintenance.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="trailerSafety.annualInspectionLocation"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                disabled={disabled}
                fullWidth
                id="annualInspectionLocation"
                label={translation(
                  "vehicle.form.annualInspectionLocation.label"
                )}
                error={Boolean(errors?.annualInspectionLocation)}
                helperText={
                  errors?.annualInspectionLocation &&
                  translation("vehicle.form.lastInspectionLocation.message")
                }
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="trailerSafety.annualInspectionDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("vehicle.form.annualInspectionDate.label")}
                inputFormat="MM/dd/yyyy"
                disabled={disabled}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="annualInspectionDate"
                    fullWidth
                    error={
                      Boolean(errors?.annualInspectionDate) ||
                      (trailer?.trailerSafety?.annualInspectionDate != null &&
                        new Date(trailer?.trailerSafety?.annualInspectionDate) <
                          new Date())
                    }
                    helperText={
                      errors?.annualInspectionDate &&
                      translation("vehicle.form.annualInspectionDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="trailerSafety.dot_Date"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("vehicle.form.dotDate.label")}
                inputFormat="MM/dd/yyyy"
                disabled={disabled}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="dot_Date"
                    fullWidth
                    error={Boolean(errors?.dot_Date)}
                    helperText={
                      errors?.dot_Date &&
                      translation("vehicle.form.dotDate.label.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="trailerSafety.lastServiceDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("vehicle.form.lastServiceDate.label")}
                inputFormat="MM/dd/yyyy"
                disabled={disabled}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="lastServiceDate"
                    fullWidth
                    error={Boolean(errors?.lastServiceDate)}
                    helperText={
                      errors?.lastServiceDate &&
                      translation("vehicle.form.lastServiceDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={6} xs={12} md={3} lg={3}>
          <Controller
            name="trailerSafety.nextServiceDate"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={translation("vehicle.form.nextServiceDate.label")}
                inputFormat="MM/dd/yyyy"
                disabled={disabled}
                renderInput={(params: any) => (
                  <CustomTextField
                    {...params}
                    {...field}
                    size="small"
                    id="nextServiceDate"
                    fullWidth
                    error={Boolean(errors?.nextServiceDate)}
                    helperText={
                      errors?.nextServiceDate &&
                      translation("vehicle.form.nextServiceDate.message")
                    }
                  />
                )}
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="trailerSafety.comment"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                disabled={disabled}
                fullWidth
                id="comment"
                multiline
                rows={3}
                label={translation("vehicle.form.comment.label")}
                error={Boolean(errors?.comment)}
                helperText={
                  errors?.comment && translation("vehicle.form.comment.message")
                }
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>
        {/*   {!linkingAddress && (
            <CustomFormGrid container justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.formButton}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", height: "20px", width: "20px" }}
                  />
                ) : params?.trailerId ? (
                  translation("common.form.update")
                ) : (
                  translation("common.form.add")
                )}
              </Button>
              <Button
                color="error"
                variant="contained"
                className={classes.formButton}
                onClick={() => navigate(-1)}
              >
                {translation("common.btn.cancel")}
              </Button>
            </CustomFormGrid>
          )} */}
      </CustomFormGrid>
    </div>
  );
};

export default SafetyInfo;
