import React from "react";
import CustomTextField from "src/components/customElements/customTextField";
import CustomFormGrid from "src/components/customElements/customFormGrid";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import { useAppDispatch } from "src/hooks/reduxHooks";
import {
  getZipData,
  getAddressData,
  getAddressDataDetail,
} from "src/redux/actions/loadManagement";
interface AddressInfoProps {
  control: any;
  errors: { [key: string]: any };
  disabled?: boolean;
  setValue?: any;
}
const AddressInfo = ({
  control,
  errors,
  disabled,
  setValue,
}: AddressInfoProps): JSX.Element => {
  const [translation] = useTranslation("translation");
  const dispatch = useAppDispatch();
  let timer: any;

  const [addressOption, setAddressOption] = React.useState<any | null>([]);

  const handleChangeAddress = async (event: any, newValue: any) => {
    if (event != null && Object.keys(event).length != 0) {
      await dispatch(getAddressData(event)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          setAddressOption(res?.data);
        }
      });
    }
  };
  const handleChangeZip = async (event: any) => {
    setValue("zip", event);
    if (event != null && Object.keys(event).length != 0 && event?.length == 5) {
      await dispatch(getZipData(event)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          setValue("state", res?.data?.state);
          setValue("city", res?.data?.city);
        }
      });
    }
  };
  const handleChangeAddressDetail = async (newValue: any) => {
    if (newValue && Object.keys(newValue).length != 0) {
      await dispatch(getAddressDataDetail(newValue)).then((res: any) => {
        if (res?.data != null && Object.keys(res?.data).length != 0) {
          delete errors["state"];
          delete errors["city"];
          delete errors["zip"];
          delete errors["address"];
          setValue && setValue("state", res?.data?.state ? res?.data?.state : "");
          setValue && setValue("city", res?.data?.city ? res?.data?.city : "");
          setValue && setValue("zip", res?.data?.zip ? res?.data?.zip : null);
          setValue && setValue("address", res?.data?.address ? res?.data?.address : null);

          /*  setValue("state", res?.data?.state);
          setValue("city", res?.data?.city); */
          // setAddressOption(res?.data);
        }
      });
    }
  };


  return (
    <div>
      <CustomFormGrid container>
        {/* <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="addressSearch"
            defaultValue={""}
            control={control}
            // rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="combo-box-demo"
                fullWidth
                options={addressOption ? addressOption : "No Data"}
                value={addressOption?.description}
                getOptionLabel={(option) =>
                  option?.description ? option?.description : "No Data Found"
                }
                onChange={(event: any, newValue: any | null) => {
                  handleChangeAddressDetail(newValue?.place_id);
                  // field.onChange(newValue);
                  // handleSettlementCodeChange(event, newValue)}
                }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    id="addressSearch"
                    size="small"
                    required
                    onChange={(event: any, newValue: any | null) => {
                      clearTimeout(timer);
                      field.onChange(newValue);
                      timer = setTimeout(
                        () => handleChangeAddress(event.target.value, newValue),
                        500
                      );
                    }}
                    // error={Boolean(errors?.address)}
                    // label={translation("Search Address")}
                    // helperText={
                    //   errors?.address && translation("common.address.message")
                    // } 
                  />
                )}
              />
            )}
          />{" "}
        </CustomFormGrid> */}
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="address"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="address"
                label={translation("common.address.label")}
                error={Boolean(errors?.address)}
                helperText={
                  errors?.address && translation("common.address.message")
                }
                required
              />
            )}
          />
        </CustomFormGrid>

        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="city"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="city"
                label={translation("common.city.label")}
                error={Boolean(errors?.city)}
                helperText={errors?.city && translation("common.city.message")}
                required
              />
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="state"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="state"
                label={translation("common.state.label")}
                error={Boolean(errors?.state)}
                helperText={
                  errors?.state && translation("common.state.message")
                }
                required
              ></CustomTextField>
            )}
          />
        </CustomFormGrid>
        <CustomFormGrid item sm={12} xs={12} md={12} lg={12}>
          <Controller
            name="zip"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                size="small"
                fullWidth
                disabled={disabled}
                id="zip"
                onChange={(e: any) => {
                  handleChangeZip(e.target.value);
                }}
                type="number"
                inputProps={{ min: 0 }}
                label={translation("common.zip.label")}
                error={Boolean(errors?.zip)}
                helperText={errors?.zip && translation("common.zip.message")}
                required
              />
            )}
          />
        </CustomFormGrid>
      </CustomFormGrid>
    </div>
  );
};
export default AddressInfo;
