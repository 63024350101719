import React from "react";
import { Navigate } from "react-router-dom";
import Terminals from "../pages/terminals";
import TerminalForm from "../pages/terminals/form";
import TerminalReadForm from "../pages/terminals/readForm";
import Lookup from "../pages/lookup";
import LookupForm from "../pages/lookup/lookpFrom";
import { UserPermissions } from "src/interfaces/user";
import TerminalSearch from "../containers/terminals/terminalSearch";

import {
  admin,
  featureTerminals,
  featureLookups,
  create,
  find,
  update,
} from "src/utils/enums";

export const terminalRoutes = (
  role: string,
  allUserPermissions: UserPermissions
) => {
  const terminalPermissions =
    allUserPermissions && allUserPermissions[featureTerminals];
  const createPermission =
    role === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === create);

  const findPermission =
    role === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === find);

  const updatePermission =
    role === admin
      ? 0
      : terminalPermissions?.findIndex((item) => item === update);

  const lookupPermissions =
    allUserPermissions && allUserPermissions[featureLookups];
  const lookupCreatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === create);
  const lookupUpdatePermission =
    role === admin
      ? 0
      : lookupPermissions?.findIndex((item) => item === update);
  return [
    {
      path: "/terminals/lookups",
      exact: true,
      element:
        role === admin || lookupPermissions?.length > 0 ? (
          <Lookup />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/terminals/lookups/create",
      exact: true,
      element:
        lookupCreatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/terminals/lookups/:id",
      exact: true,
      element:
        lookupUpdatePermission > -1 ? <LookupForm /> : <Navigate to="/404" />,
    },
    {
      path: "/terminals/terminals",
      exact: true,
      element:
        role === admin || terminalPermissions?.length > 0 ? (
          <Terminals />
        ) : (
          <Navigate to="/404" />
        ),
    },
    {
      path: "/terminals/terminals/create",
      exact: true,
      element:
        createPermission > -1 ? <TerminalForm /> : <Navigate to="/404" />,
    },
    {
      path: "/terminals/terminals/:terminalId",
      exact: true,
      element:
        updatePermission > -1 ? <TerminalForm /> : <Navigate to="/404" />,
    },
    {
      path: "/terminals/terminals/details/:terminalId",
      exact: true,
      element:
        findPermission > -1 ? <TerminalReadForm /> : <Navigate to="/404" />,
    },
    {
      path: "/terminals/terminals/search",
      exact: true,
      element: findPermission > -1 ? <TerminalSearch /> : <Navigate to="/404" />,
    },
  ];
};
